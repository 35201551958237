import { Typography } from '@mui/material';
import React from 'react';
import { TEST_CONSTANTS } from '../constants/testConstants';
import { headerStyle } from '../style/headerStyle';

const Highlighter = ({
  children,
  highlight,
  fontSize,
}: {
  children: string;
  highlight: string;
  fontSize?: string;
}) => {
  if (
    !highlight ||
    children === undefined ||
    !children?.toString()?.trim().length
  )
    return <>{children}</>;
  const regexp = new RegExp(highlight.toLowerCase(), 'i');

  const matches = children.toString().match(regexp);

  let parts: Array<string | JSX.Element> = children
    .toString()
    // @ts-ignore
    .split(new RegExp(`${highlight.replace()}`, 'i'));

  for (let i = 0; i < parts.length; i++) {
    if (i !== parts.length - 1 && matches?.length) {
      let match = matches[i];
      // While the next part is an empty string, merge the corresponding match with the current
      // match into a single <span/> to avoid consequent spans with nothing between them.
      while (parts[i + 1] === '') {
        if (matches[++i] !== undefined) match += matches[++i];
      }

      parts[i] = (
        <React.Fragment key={i}>
          {parts[i]}
          <Typography
            component='span'
            sx={[
              headerStyle.hightlightedSearchText,
              { typography: fontSize ?? 'subtitle1' },
            ]}
          >
            {match}
          </Typography>
        </React.Fragment>
      );
    }
  }

  return (
    <span data-testid={TEST_CONSTANTS.GET_HIGHLIGHTED_SEARCHED_TEXT}>
      {parts}
    </span>
  );
};

export default Highlighter;
