import { useEffect } from 'react';

import { useAppDispatch } from '../app/hooks';
import localStorageHelper from '../helpers/localStorageHelper';
import { setAuthToken } from '../store/actions/authActions';
import { clearUserInfo, setUserInfo } from '../store/actions/userActions';
import { setChatAuth } from '../store/slices/chatSlice';
import { ChatUser } from '../types/chatTypes';

// This hook simply retrieves some local storage information and sends them to Redux.
// this could also be done by implementing redux-persist
export const useLocalStorageLoader = (fromLogin = false): void => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    // If `fromLogin` is true, then we don't need to set the user info since it will be
    // done on the login action. - is this flag used anywhere?
    if (fromLogin) return;
    const userId = localStorageHelper.getUser();
    const userInfo = localStorageHelper.getUserInfo();

    if (userId && userInfo) dispatch(setUserInfo(userId, userInfo));

    const token = localStorageHelper.getAuthToken();
    if (token) dispatch(setAuthToken(token));

    //store the auth info in chat
    dispatch(
      setChatAuth({
        user: {
          userId,
          name: [userInfo?.firstName, userInfo?.lastName]
            .filter(Boolean)
            .join(' '),
        } as ChatUser,
      })
    );

    return () => {
      dispatch(clearUserInfo());
    };
  }, [dispatch, fromLogin]);
};
