import {
  Box,
  CircularProgress,
  Drawer,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import {
  getEfaxContactHistoryAction,
  sendEfaxAction,
  toggleSendEfaxDrawer,
} from '../../../../store/actions/dashboardActions/efaxActions';
import { efaxStyle } from '../../../../style/dashboardStyles/efaxStyle';
import { ContactPhoneOutlined, Close } from '@mui/icons-material';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { getEnvMobileConfig } from '../../../../helpers/profileHelper';
import { v4 as uuidv4 } from 'uuid';
import { deleteDocumentAction } from '../../../../store/actions/dashboardActions/documentActions';
import { Thumbnail, UploadedDocument } from '../../../../types/documentsTypes';
import { ContactHistory, SendFaxDetails } from '../../../../types/efaxTypes';
import MaskedInput from '../../../../components/MaskedInput';
import UploadDocuments from '../../documents/uploadDocuments/UploadDocuments';
import FlatButton from '../../../../components/FlatButton';
import localStorageHelper from 'helpers/localStorageHelper';
import { ClaimsByJwtToken } from 'types/Misc';
import { getClaimsViaJwtToken } from 'helpers/getClaimsViaJwtTokenHelper';
import { rolesFromClaimsByJwtToken } from 'constants/userConstants';

const SendEfaxDrawer = () => {
  const dispatch = useAppDispatch();
  const { mobileMaskFormat } = getEnvMobileConfig();
  const { isSendingEfax, isGettingEfaxContactHistory } = useAppSelector(
    ({ generalLoaderReducer }: Record<string, any>) =>
      generalLoaderReducer ?? true
  );

  const { isSendEfaxDrawerOpen, userPracticeDetails, contactHistory } =
    useAppSelector((state) => state.efaxReducer);
  const { userInfo } = useAppSelector((state) => ({
    userInfo: state.userReducer.userInfo,
  }));

  const initialSendFaxFormData = {
    recipientFaxNumber: '',
    recipientPracticeName: '',
    senderTelephoneNumber: userPracticeDetails?.practicePhone || '',
  };

  const [sendFaxFormData, setSendFaxFormData] = useState(
    initialSendFaxFormData
  );

  const [selectedFaxContact, setSelectedFaxContact] = useState<
    ContactHistory | undefined
  >(undefined);

  const customRolesMenuAnchor = useRef<HTMLDivElement>(null);
  const [isShowContacts, setIsShowContacts] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState<Thumbnail[]>([]);
  const [totalFilesSize, setTotalFilesSize] = useState<number>(0);
  const [uploadedFiles, setUploadedFiles] = useState<UploadedDocument[]>([]);
  const [fileSelectedForDeletion, setFileSelectedForDeletion] = useState<
    string | null
  >(null);
  const [isFormSubmittedOnce, setIsFormSubmittedOnce] = useState(false);
  const authToken = localStorageHelper.getAuthToken();
  const isValidFaxNumber =
    /^\+\d{11}$/.test(sendFaxFormData.recipientFaxNumber) ||
    /^\d{11}$/.test(sendFaxFormData.recipientFaxNumber);

  const isValidFormData =
    !!sendFaxFormData.recipientFaxNumber &&
    !!sendFaxFormData.recipientPracticeName &&
    !!sendFaxFormData.senderTelephoneNumber;

  const onChangeFaxFormData = (
    formKey:
      | 'recipientFaxNumber'
      | 'recipientPracticeName'
      | 'senderTelephoneNumber'
  ) => {
    return (
      e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent
    ): void => {
      const fieldValue = e.target.value;

      if (
        formKey === 'recipientFaxNumber' &&
        !!selectedFaxContact?.EFaxNumber
      ) {
        setSelectedFaxContact(undefined);
      }

      setSendFaxFormData((prevState) => ({
        ...prevState,
        [formKey]: fieldValue,
      }));
    };
  };

  const onClickDeleteFile = (fileName: string) => {
    deleteDocumentAction(fileName, setSelectedFiles, setUploadedFiles);
    setFileSelectedForDeletion(fileName);
  };

  const resetStates = () => {
    setIsShowContacts(false);
    setIsFormSubmittedOnce(false);
    setSelectedFaxContact(undefined);
    setSelectedFiles([]);
    setTotalFilesSize(0);
    setUploadedFiles([]);
    setFileSelectedForDeletion(null);
    setSendFaxFormData(initialSendFaxFormData);
  };

  const generateFormData = (): SendFaxDetails => {
    const attachmentUrls = uploadedFiles.map((file) => file.attachmentUrl);
    const fileNames = uploadedFiles.map((file) => file.fileName);
    const uploadedAttachmentUrlsCsv = attachmentUrls.join(',');
    const uploadedFileNamesCsv = fileNames.join(',');

    const formData = {
      Id: uuidv4(),
      RecipientPracticeId: 'string',
      RecipientFaxNumber: sendFaxFormData.recipientFaxNumber,
      RecipientPracticeName:
        sendFaxFormData.recipientPracticeName ??
        selectedFaxContact?.PracticeName,
      SenderPracticeId: userPracticeDetails?.practiceId,
      SenderFaxNumber: userPracticeDetails?.eFaxNumber,
      SenderTelephoneNumber: userPracticeDetails.practicePhone,
      SenderPracticeName: userPracticeDetails.practiceName,
      SenderEmail: userInfo?.email,
      SenderName: `${userInfo?.firstName} ${userInfo?.lastName}`,
      FileNamesCsv: uploadedFileNamesCsv,
      AttachmentUrlsCsv: uploadedAttachmentUrlsCsv,
      CreatedOn: new Date().toISOString(),
    };

    return formData as SendFaxDetails;
  };

  const handleSendFax = () => {
    setIsFormSubmittedOnce(true);

    if (isValidFaxNumber) {
      const apiData = generateFormData();
      const isFromSavedContacts = !!selectedFaxContact?.EFaxNumber;

      dispatch(sendEfaxAction(apiData as SendFaxDetails, !isFromSavedContacts));
    }
  };

  const handleToggleSendFaxDrawer = () => {
    dispatch(toggleSendEfaxDrawer());
    resetStates();
  };

  function renderContactHistoryList(): ReturnType<typeof MenuItem>[] {
    if (!!contactHistory?.length) {
      return contactHistory.map((faxContact) => (
        <MenuItem
          key={faxContact.EFaxNumber}
          value={faxContact.EFaxNumber}
          onClick={() => {
            setSelectedFaxContact(faxContact);
            setIsShowContacts(false);
            setSendFaxFormData((prevState) => ({
              ...prevState,
              recipientFaxNumber: faxContact.EFaxNumber,
              recipientPracticeName: faxContact.PracticeName,
            }));
          }}
        >
          <Box style={{ display: 'flex', flexDirection: 'column' }}>
            <Typography component='span' variant='subtitle2' fontWeight={600}>
              {faxContact.PracticeName}
            </Typography>
            <Typography component='span' variant='subtitle2' fontWeight={400}>
              {faxContact.EFaxNumber}
            </Typography>
          </Box>
        </MenuItem>
      ));
    } else {
      if (isGettingEfaxContactHistory) {
        return [
          <MenuItem>
            <Box
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '0.5rem',
              }}
            >
              <CircularProgress size={'1rem'} />
              <Typography component='span' variant='subtitle2' fontWeight={400}>
                Loading contacts...
              </Typography>
            </Box>
          </MenuItem>,
        ];
      } else {
        return [
          <MenuItem>
            <Typography component='span' variant='subtitle2' fontWeight={400}>
              No available contacts.
            </Typography>
          </MenuItem>,
        ];
      }
    }
  }

  useEffect(() => {
    resetStates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSendEfaxDrawerOpen]);

  useEffect(() => {
    if (!!userPracticeDetails?.practicePhone) {
      setSendFaxFormData({
        ...sendFaxFormData,
        senderTelephoneNumber: userPracticeDetails?.practicePhone,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userPracticeDetails?.practicePhone]);

  useEffect(() => {
    if (!!sendFaxFormData.recipientFaxNumber && !!contactHistory?.length) {
      const matchedPractice = contactHistory?.find(
        (practice) => practice.EFaxNumber === sendFaxFormData.recipientFaxNumber
      );

      if (!!matchedPractice) {
        setSelectedFaxContact(matchedPractice);
      }
    }
  }, [sendFaxFormData.recipientFaxNumber, contactHistory]);

  useEffect(() => {
    if (authToken) {
      const claims: ClaimsByJwtToken = getClaimsViaJwtToken(authToken);
      if (
        isSendEfaxDrawerOpen &&
        claims?.role?.includes(rolesFromClaimsByJwtToken.efax)
      ) {
        dispatch(getEfaxContactHistoryAction());
      }
    }
  }, [dispatch, isSendEfaxDrawerOpen, authToken]);

  return (
    <Drawer
      anchor={'right'}
      open={isSendEfaxDrawerOpen}
      onClose={handleToggleSendFaxDrawer}
      PaperProps={{
        sx: efaxStyle.sendEfaxDrawerContainer,
      }}
    >
      <Box sx={efaxStyle.sendEfaxDrawerContainer.contentWrapper}>
        <Box>
          <Box sx={efaxStyle.sendEfaxDrawerContainer.contentWrapper.header}>
            <Typography
              variant='h5'
              sx={efaxStyle.sendEfaxDrawerContainer.contentWrapper.title}
            >
              Send eFax
            </Typography>
            <IconButton onClick={handleToggleSendFaxDrawer}>
              <Close />
            </IconButton>
          </Box>
          <TableContainer
            component={Box}
            border={0}
            style={{ paddingTop: '12px' }}
          >
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell width={'14%'}>
                    <Typography variant='body2' fontWeight={600}>
                      From:
                    </Typography>
                  </TableCell>
                  <TableCell width={'43%'}>
                    <Typography variant='body2' fontWeight={600}>
                      Practice Name
                    </Typography>
                    {userPracticeDetails?.practiceName}
                  </TableCell>
                  <TableCell width={'43%'}>
                    <Typography variant='body2' fontWeight={600}>
                      Sender Name
                    </Typography>
                    {`${userInfo?.firstName} ${userInfo?.lastName}`}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell />
                  <TableCell
                    width={'43%'}
                    style={{
                      paddingRight: 5,
                      paddingTop: '30px',
                    }}
                  >
                    <MaskedInput
                      value={sendFaxFormData.senderTelephoneNumber as string}
                      mask={mobileMaskFormat}
                      label={'SENDING PRACTICE PHONE'}
                      isLabelShrinked
                      isRequired
                      isDisabled={isSendingEfax}
                      onChange={onChangeFaxFormData('senderTelephoneNumber')}
                    />
                  </TableCell>
                  <TableCell />
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{
                      paddingTop: '30px',
                    }}
                  >
                    <Typography variant='body2' fontWeight={600}>
                      Recipient:
                    </Typography>
                  </TableCell>
                  <TableCell
                    style={{
                      paddingRight: 5,
                      paddingTop: '30px',
                    }}
                  >
                    <TextField
                      ref={customRolesMenuAnchor}
                      fullWidth
                      label='FAX NUMBER'
                      required
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={!isValidFaxNumber && isFormSubmittedOnce}
                      InputProps={{
                        autoComplete: 'off',
                        endAdornment: (
                          <InputAdornment position='end'>
                            <IconButton
                              edge='end'
                              onClick={() => {
                                setIsShowContacts(true);
                              }}
                              disabled={isSendingEfax}
                            >
                              <ContactPhoneOutlined
                                color={isSendingEfax ? 'disabled' : 'primary'}
                              />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      disabled={isSendingEfax}
                      sx={
                        isValidFaxNumber
                          ? efaxStyle.sendEfaxDrawerContainer.contentWrapper
                              .correctField
                          : {}
                      }
                      onChange={onChangeFaxFormData('recipientFaxNumber')}
                      value={sendFaxFormData.recipientFaxNumber}
                      helperText={
                        isValidFaxNumber
                          ? 'Select a practice from your network or enter a fax number.'
                          : isFormSubmittedOnce &&
                            'Please enter a valid fax number.'
                      }
                    />
                    {isShowContacts && (
                      <Menu
                        anchorEl={customRolesMenuAnchor.current}
                        open={isShowContacts}
                        onClose={() => setIsShowContacts(false)}
                        PaperProps={{
                          style: {
                            minWidth: '16.5rem',
                            maxHeight: '27.18rem',
                          },
                        }}
                      >
                        {renderContactHistoryList()}
                      </Menu>
                    )}
                  </TableCell>
                  <TableCell
                    style={{
                      paddingTop: '30px',
                    }}
                  >
                    <TextField
                      fullWidth
                      label='OFFICE NAME'
                      required
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={onChangeFaxFormData('recipientPracticeName')}
                      value={sendFaxFormData.recipientPracticeName}
                      disabled={isSendingEfax}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Box
            sx={
              efaxStyle.sendEfaxDrawerContainer.contentWrapper
                .uploadDocumentsWrapper
            }
          >
            <UploadDocuments
              blobType='Public'
              selectedFiles={selectedFiles}
              setSelectedFiles={setSelectedFiles}
              totalFilesSize={totalFilesSize}
              setTotalFilesSize={setTotalFilesSize}
              setUploadedFiles={setUploadedFiles}
              onClickDeleteFile={isSendingEfax ? undefined : onClickDeleteFile}
              fileSelectedForDeletion={fileSelectedForDeletion}
              dragAndDropMessage={
                <span>
                  Drag & Drop, or click upload to select the files <br />
                  you would like to include in the Fax
                </span>
              }
              containerStyle={
                efaxStyle.sendEfaxDrawerContainer.contentWrapper
                  .uploadDocumentsWrapper.uploadBox
              }
              disclaimerMessage={'* Upload up to 2 attachments'}
              shouldHideDrapDropBox={selectedFiles.length >= 2 || isSendingEfax}
            />
          </Box>
        </Box>
        <Box
          sx={
            efaxStyle.sendEfaxDrawerContainer.contentWrapper.actionBtnsWrapper
          }
        >
          <FlatButton variant='outlined' onClick={handleToggleSendFaxDrawer}>
            {isSendingEfax ? 'Close' : 'Cancel'}
          </FlatButton>
          <FlatButton
            variant='contained'
            onClick={handleSendFax}
            disabled={
              !selectedFiles.length || !isValidFormData || isSendingEfax
            }
            isLoading={isSendingEfax}
          >
            Send Fax
          </FlatButton>
        </Box>
      </Box>
    </Drawer>
  );
};

export default SendEfaxDrawer;
