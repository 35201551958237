import { useState, useLayoutEffect } from 'react';

function usePageVisible() {
  const [pageVisible, setPageVisible] = useState(!document.hidden);

  useLayoutEffect(() => {
    const handleVisibility = () => {
      setPageVisible(!document.hidden);
    };
    document.addEventListener('visibilitychange', handleVisibility);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibility);
    };
  }, []);

  return pageVisible;
}

export default usePageVisible;
