import { ChatMessage } from '../types/chatTypes';

export const failedMessagesIndexedDbName = 'drtalk-database';
let dbVersion = 1;

let db: IDBDatabase;

let request: IDBOpenDBRequest;
let store = 'failedMessagesChannelObjectStore';

export const initIndexedDB = (): Promise<boolean> => {
  return new Promise((resolve) => {
    // open the connection
    request = indexedDB.open(failedMessagesIndexedDbName);

    request.onupgradeneeded = (event) => {
      db = (event.target as IDBOpenDBRequest).result;
      db.createObjectStore(store, { keyPath: 'id' });

      // no need to resolve here
    };

    request.onsuccess = (event) => {
      db = (event.target as IDBRequest).result;
      dbVersion = db.version;
      resolve(true);
    };

    request.onerror = () => {
      resolve(false);
    };
  });
};
export const indexedDbFailedMessagesChannelsHelper = {
  saveFailedMessages: (message: ChatMessage): Promise<ChatMessage> => {
    return new Promise((resolve) => {
      request = indexedDB.open(failedMessagesIndexedDbName, dbVersion);

      request.onsuccess = (event) => {
        db = (event?.target as IDBRequest).result;
        const tx = db.transaction(store, 'readwrite');
        const addedStore = tx.objectStore(store);

        // add failed message
        addedStore.add(message);
        resolve(message);
      };
    });
  },

  getFailedChatMessages: () => {
    return new Promise((resolve) => {
      request = indexedDB.open(failedMessagesIndexedDbName);

      request.onsuccess = async (event) => {
        db = (event?.target as IDBRequest).result;
        const tx = db.transaction(store, 'readonly');

        // create an object store on the transaction
        const addedStore = tx.objectStore(store);
        const res = addedStore.getAll();

        res.onsuccess = () => {
          resolve(res.result);
        };
      };
    });
  },

  deleteFailedChatMessage: (id: string) => {
    return new Promise(() => {
      request = indexedDB.open(failedMessagesIndexedDbName);

      request.onsuccess = async (event) => {
        db = (event?.target as IDBRequest).result;
        const tx = db.transaction(store, 'readonly');

        // create an object store on the transaction
        const addedStore = tx.objectStore(store);
        addedStore.delete(id);
      };
    });
  },

  deleteAllFailedChatMessages: () =>
    indexedDB.deleteDatabase(failedMessagesIndexedDbName),
};
