export const FIRST_AVAILABLE_DOCTOR_OPTION = 'firstAvailable';
export const FIRST_AVAILABLE_OPTION_LABEL = 'First Available';

export const REFERRAL_STEPS = {
  PRACTICE_DETAILS: 1,
  REFERRAL_DOCUMENT: 2,
  PATIENT_DETAILS: 3,
};

export const PDF_VIEWER_SERVICE_URL =
  'https://drtalkstoragemanagerpreprod.azurewebsites.net/pdfviewer';

export const REFERRAL_PRACTICE_SELECTION_ERRORS = {
  REQUIRED_PRACTICE: 'Please select a practice',
  REQUIRED_DOCTOR: 'Please select a doctor',
};

export const REFERRAL_PATIENT_DETAILS_ERRORS = {
  REQUIRED_FIRST_NAME: 'Please enter a first name.',
  REQUIRED_LAST_NAME: 'Please enter a last name.',
  REQUIRED_EXISTING_PATIENT: 'Please select a patient.',
  INVALID_DOB: 'Please enter a valid date.',
  INVALID_DOB_FUTURE: 'Please enter a valid date of birth in the past.',
  INVALID_EMAIL: 'Please enter a valid email address.',
};

export const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
