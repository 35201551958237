import { Button, Dialog, DialogContent, Typography } from '@mui/material';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as SessionTimeoutImage } from '../../src/assets/images/sessionTimeout.svg';
import { logout } from '../helpers/authHelper';
import localStorageHelper from '../helpers/localStorageHelper';
import { navigateToLogin } from '../helpers/navigateToLoginHelper';
import { setAuthToken } from '../store/actions/authActions';
import { commonClasses } from '../style/commonClasses';

const SessionExpiredDialog = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const warningTimeOut = 3_600_000;
  const warningTimeInterval = 120_000;
  const loggingOutTimeInterval = 30_000;
  let warningTimer = useRef<NodeJS.Timeout | null>(null);
  let logoutTimer = useRef<NodeJS.Timeout | null>(null);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [isTabActive, setIsTabActive] = useState(true);
  const [isLogout, setIsLogout] = useState<boolean>(false);

  const events = ['mousemove', 'mousedown', 'click', 'scroll', 'keypress'];
  const eventHandler = () => {
    localStorageHelper.setLastInteractionTime(moment().toString());

    setIsDialogOpen(false);
  };

  const handleLogout = () => {
    removeEvents();
    clearInterval(warningTimer.current!);
    warningTimer.current = null;

    setIsLogout(true);
    dispatch(setAuthToken(null));
    logout(() => navigate(navigateToLogin()));
  };
  const startTimer = () => {
    warningTimer.current = setInterval(() => {
      let lastInteractionTime = localStorageHelper.getLastInteractionTime();
      const diff: any = moment().diff(moment(lastInteractionTime));

      if (isLogout && !isDialogOpen) {
        clearTimeout(warningTimer.current!);
        warningTimer.current = null;

        dispatch(setAuthToken(null));
      } else {
        if (diff < warningTimeOut) {
          setIsDialogOpen(false);
        } else {
          setIsDialogOpen(true);
          removeEvents();
        }
      }
    }, warningTimeInterval);
  };
  const addEvents = () => {
    events.forEach((eventName) => {
      window.addEventListener(eventName, eventHandler);
    });
  };

  const removeEvents = () => {
    events.forEach((eventName) => {
      window.removeEventListener(eventName, eventHandler);
    });
  };

  const onClickContinue = () => {
    clearTimeout(logoutTimer.current!);
    logoutTimer.current = null;
    setIsDialogOpen(false);
  };

  // to check if the browser tab is active or not
  useEffect(() => {
    const handleVisibilityChange = () => {
      setIsTabActive(!document.hidden);
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    if (!isDialogOpen) {
      localStorageHelper.setLastInteractionTime(moment().toString());
      startTimer();
      addEvents();
    }
    return () => {
      removeEvents();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDialogOpen]);

  useEffect(() => {
    if (isDialogOpen && isTabActive) {
      logoutTimer.current = setTimeout(handleLogout, loggingOutTimeInterval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDialogOpen, isTabActive]);

  return (
    <Dialog
      disableEscapeKeyDown
      sx={commonClasses.sessionTimeoutDialog}
      aria-labelledby='session timeout dialog'
      aria-describedby='session timeout dialog'
      open={isDialogOpen}
    >
      <DialogContent>
        <SessionTimeoutImage />
        <Typography mt={2} variant='h5' fontWeight={400}>
          Your session has expired
        </Typography>
        <Typography mt={2} mb={2} variant='subtitle2' fontWeight={400}>
          Please press continue. You will be logged out in 30 seconds.
        </Typography>
        <Button variant='contained' color='primary' onClick={onClickContinue}>
          Continue
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default SessionExpiredDialog;
