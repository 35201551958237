import { TableDetailsAttachment } from '../../types/documentsTypes';
import wordLogo from '../../assets/images/doc.png';
import pdfLogo from '../../assets/images/pdf.png';
import txtLogo from '../../assets/images/txt-file.png';
import excelLogo from '../../assets/images/xls.png';
import imageLogo from '../../assets/images/image.png';

export const imageSrc = (attachment: TableDetailsAttachment) => {
  switch (attachment?.fileName?.split('.')?.pop()?.toLowerCase()) {
    case 'doc':
    case 'docx':
      return wordLogo;

    case 'pdf':
      return pdfLogo;

    case 'xls':
    case 'xlsx':
    case 'xlsm':
    case 'xlsb':
    case 'xltx':
    case 'xltm':
    case 'xlt':
    case 'xml':
    case 'xla':
    case 'xlr':
    case 'xlam':
    case 'csv':
      return excelLogo;

    case 'txt':
      return txtLogo;

    default:
      return imageLogo;
  }
};
