import { colorPalette } from '../../theme/colorPalette';

export const efaxStyle = {
  mainContainer: {
    display: 'flex',
    flex: 1,
    height: 1,
    flexDirection: 'column',
    p: '1rem',
    bgcolor: 'background.default',
    borderRadius: '.5rem',
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    mb: '2rem',
  },
  tabLabel: {
    display: 'flex',
    alignItems: 'center',
    gap: '.5rem',

    '.MuiSvgIcon-root': {
      typography: 'h6',
      fontWeight: 400,
    },
  },
  filterContainer: {
    display: 'flex',
    gap: '.75rem',

    '*': { flexGrow: 1 },
  },
  filterAutocomplete: {
    maxWidth: '18rem',

    '.MuiInputLabel-root': {
      typography: 'subtitle2',
      fontWeight: 400,
      lineHeight: '1.4375em',
    },

    '.MuiAutocomplete-input': {
      typography: 'subtitle2',
      fontWeight: 400,
    },
  },
  efaxTableFirstColumnDetails: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: '.25rem',
    typography: 'subtitle2',
    fontWeight: 400,
  },
  efaxTabsContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: '1rem',
    height: 'calc(100% - 8.5rem)',
    minHeight: '10rem',
    mt: '1rem',
  },
  efaxTableContainer: {
    overflowY: 'auto',
    backgroundColor: 'background.default',
  },
  efaxTable: {
    tableLayout: 'fixed',

    '& .MuiTableCell-sizeSmall': {
      padding: '0 1rem',
    },
  },
  tableHeader: {
    th: {
      zIndex: 3,
    },
  },
  processButton: {
    minHeight: 'unset',
    height: '2rem',
    flexShrink: 0,
    typography: 'subtitle2',
    fontWeight: 500,
    boxShadow: 'none',
  },
  faxStatusColumn: {
    display: 'flex',
    alignItems: 'center',
    gap: '.5rem',
  },
  faxActionColumn: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '.5rem',
  },
  efaxTableRecipientName: {
    color: 'primary.main',
    fontWeight: 700,
    textDecoration: 'underline',
  },
  efaxTableRecipientNameTagContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '.5rem',
  },
  noEfaxTableImage: {
    p: '5vh 0',
    borderBottom: 'none',
    color: colorPalette.surfaceColor[1000],
  },
  sendEfaxDrawerContainer: {
    width: '697px',
    padding: '32px',
    contentWrapper: {
      display: 'flex',
      flexFlow: 'column',
      height: '100%',
      justifyContent: 'space-between',

      header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',

        '& h5': {
          margin: 0,
        },
      },

      title: {
        fontWeight: 400,
        marginBottom: '0.5rem',
      },

      actionBtnsWrapper: {
        display: 'flex',
        justifyContent: 'flex-end',

        button: {
          marginLeft: '0.8rem',
        },
      },

      uploadDocumentsWrapper: {
        marginTop: '1rem',
        background: colorPalette.surfaceColor[200],
        borderRadius: '4px',

        uploadBox: {
          maxWidth: '21.375rem',
          margin: 'auto',
        },
      },

      '.MuiTableContainer-root': {
        marginTop: '0.8rem',
        height: '16.25rem',
        overflow: 'hidden',
      },

      correctField: {
        '.MuiFormHelperText-root': {
          color: colorPalette.textSecondary,
        },
      },

      '.MuiTableCell-root': {
        border: 0,
        padding: '0',
      },
    },

    practiceRecipient: {
      '.MuiInputBase-root': {
        padding: 0,
        paddingRight: '1rem',
      },
    },
  },
  faxPreviewDrawerContainer: {
    width: '1236px',
    padding: '32px',
    contentWrapper: {
      display: 'flex',
      flexFlow: 'column',
      height: '100%',
      justifyContent: 'space-between',

      header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',

        '& h5': {
          margin: 0,
        },
      },

      title: {
        fontWeight: 400,
        marginBottom: '0.5rem',
      },

      actionBtnsWrapper: {
        display: 'flex',
        justifyContent: 'flex-end',

        button: {
          marginLeft: '0.8rem',
        },
      },

      uploadDocumentsWrapper: {
        marginTop: '2rem',
        background: colorPalette.surfaceColor[200],
        borderRadius: '4px',

        uploadBox: {
          maxWidth: '342px',
          margin: 'auto',
        },
      },

      '.MuiTableCell-root': {
        border: 0,
        padding: '0',
        paddingTop: '30px',
      },
    },

    filePreviewContainer: {
      width: '100%',
      height: 'calc(100vh - 14rem)',
      marginTop: '0.75rem',
      display: 'flex',
      justifyContent: 'center',

      '.MuiCircularProgress-root': {
        marginTop: '5rem',
      },
    },
  },
  deliverFailedMsg: {
    margin: 'auto',
    marginTop: '10rem',
    width: '50%',
    textAlign: 'center',

    errorIcon: {
      height: '7rem',
      width: '7rem',
      marginBottom: '1.6rem',
    },

    highlightMsg: {
      fontWeight: '600',
      fontSize: '1.7rem',
      marginBottom: '1rem',
    },

    secondaryMsg: {
      fontSize: '1.1rem',
      marginBottom: '1rem',
    },
  },
};
