/* eslint-disable no-param-reassign */
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import localStorageHelper from '../../helpers/localStorageHelper';
import { memoRenewToken } from '../../helpers/authHelper';

const instance = axios.create({
  timeout: 60000,
  params: {}, // added to add params later in the config
});

// This handles token renewal even before the access token expires.
instance.interceptors.request.use(
  async (request) => {
    await memoRenewToken();
    return request;
  },
  (error: AxiosError) => {
    return Promise.reject(error);
  }
);

// This handles token renewal on failed requests.
instance.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response?.status === 401 && !error.config?.sent) {
      error.config.sent = true;

      // Retry the failed request with the updated access token.
      const accessToken = await memoRenewToken();
      const user = localStorageHelper.getUser();

      if (accessToken && user) {
        error.config.headers = {
          ...error.config.headers,
          authorization: accessToken,
          USER: user,
        };
      }

      return instance.request(error.config);
    }

    return Promise.reject(error);
  }
);

// Adds the required request headers for all API calls
instance.interceptors.request.use(
  async (request: any) => {
    const token = localStorageHelper.getAuthToken();
    const user = localStorageHelper.getUser();
    if (token && user) {
      request.headers.common.authorization = `Bearer ${token}`;
      request.headers.common.USER = user;
    }

    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const ApiService = {
  request(config = {}) {
    return instance.request(config);
  },
  get(url: string, config: AxiosRequestConfig = {}) {
    return instance.get(url, config);
  },
  post<TData, TConfig>(
    url: string,
    data?: TData,
    config?: AxiosRequestConfig<TConfig> | undefined
  ) {
    return instance.post(url, data, config as any); // need to work on this
  },
  put<TData, TConfig>(
    url: string,
    data?: TData,
    config?: AxiosRequestConfig<TConfig> | undefined
  ) {
    return instance.put(url, data, config as any);
  },
  patch<TData>(url: string, data?: TData) {
    return instance.patch(url, data);
  },
  delete(url: string, config = {}) {
    return instance.delete(url, config);
  },
};

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const resType = error?.request?.responseType;
    const statusCode = error?.response?.status ?? 0;
    if (resType === 'blob') {
      const err = await error?.response?.data?.text();
      return Promise.reject(JSON.parse(err));
    }
    switch (statusCode) {
      case 404:
        alert('Error 404 - Page not found');
        // window.location.href = "/forbidden-access";
        return false;

      default:
        break;
    }
    return Promise.reject(error);
  }
);

export default ApiService;
