import {
  Box,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { efaxStyle } from '../../../../style/dashboardStyles/efaxStyle';
import { EfaxTableDetailsType } from '../../../../types/efaxTypes';
import EfaxTableRows from './EfaxTableRows';
import OpenFaxDrawer from './OpenFaxDrawer';
import NoEfaxMessage from './NoEfaxMessage';
import localStorageHelper from '../../../../helpers/localStorageHelper';
import Pagination from '../../../../components/Pagination';
import { EFAX_REDUX_CONSTANTS } from '../../../../store/reduxConstants/efaxReduxConstants';

const EfaxTableDetails = () => {
  const {
    paginatedList,
    selectedTab,
    receivedEfaxList,
    sentEfaxList,
    receivedEfaxStatusFilter,
    appliedFilters,
    contactHistory,
  } = useAppSelector((state) => state.efaxReducer);
  const dispatch = useAppDispatch();
  const { searchedText } = useAppSelector(
    ({ headerReducer }) => headerReducer ?? {}
  );

  const { isGettingEfaxMessages, isSendingEfax } = useAppSelector(
    ({ generalLoaderReducer }) => generalLoaderReducer ?? true
  );

  const isReceivedTabSelected = selectedTab === 0;

  // Will generate list based on selected tab, applied filters and keyword
  const displayedList: EfaxTableDetailsType[] = useMemo(() => {
    let targetList: EfaxTableDetailsType[] =
      selectedTab === 0 ? receivedEfaxList : sentEfaxList;

    if (selectedTab === 0 && receivedEfaxStatusFilter) {
      if (receivedEfaxStatusFilter === 'Unprocessed') {
        targetList = targetList.filter(
          (data) => data.documentStatus === 'NotProcessed'
        );
      } else if (receivedEfaxStatusFilter === 'Processed') {
        targetList = targetList.filter(
          (data) => data.documentStatus === 'Processed'
        );
      }
    }

    const hasAppliedFilters =
      !!appliedFilters.sender || !!appliedFilters.recipient;

    if (hasAppliedFilters) {
      if (!!appliedFilters.sender) {
        targetList = targetList.filter((fax) => {
          const senderPracticeContactName = contactHistory?.find(
            (contact) => contact.EFaxNumber === fax.senderFaxNumber
          )?.PracticeName;

          return (
            fax.senderPracticeName === appliedFilters.sender ||
            senderPracticeContactName === appliedFilters.sender
          );
        });
      }

      if (!!appliedFilters.recipient) {
        targetList = targetList.filter((fax) => {
          const recipientPracticeContactName = contactHistory?.find(
            (contact) => contact.EFaxNumber === fax.recipientFaxNumber
          )?.PracticeName;

          return (
            fax.recipientPracticeName === appliedFilters.recipient ||
            recipientPracticeContactName === appliedFilters.recipient
          );
        });
      }
    }

    if (!!searchedText) {
      const keyword = searchedText?.toLowerCase();

      targetList = targetList.filter((fax) => {
        return (
          fax.recipientPracticeName?.toLowerCase().includes(keyword) ||
          fax.senderPracticeName?.toLowerCase().includes(keyword)
        );
      });
    }

    // will filter all the archived faxes by the logged practice user
    targetList = targetList.filter((item) => {
      const userPracticeId = localStorageHelper.getPracticeId();
      const isFaxReceivedByUser = item.recipientPracticeId === userPracticeId;
      const isFaxSentByUser = item.senderPracticeId === userPracticeId;

      if (isFaxReceivedByUser) {
        return !item.hasReceiverPracticeArchived;
      }

      if (isFaxSentByUser) {
        return !item.hasSenderPracticeArchived;
      }

      return item;
    });

    return targetList;
  }, [
    selectedTab,
    receivedEfaxList,
    sentEfaxList,
    receivedEfaxStatusFilter,
    appliedFilters,
    searchedText,
    contactHistory,
  ]);

  const setPaginatedList = (list: EfaxTableDetailsType[]) => {
    dispatch({
      type: EFAX_REDUX_CONSTANTS.UPDATE_PAGINATED_LIST,
      payload: list,
    });
  };

  return (
    <Box sx={efaxStyle.efaxTabsContainer}>
      <TableContainer
        component={Paper}
        sx={[
          efaxStyle.efaxTableContainer,
          { height: paginatedList?.length ? 'calc(100% - 3.5rem)' : '100%' },
        ]}
      >
        <Table stickyHeader sx={efaxStyle.efaxTable}>
          <TableHead sx={efaxStyle.tableHeader}>
            <TableRow>
              <TableCell component='th' scope='row'>
                SENDER
              </TableCell>
              <TableCell component='th' scope='row'>
                RECIPIENT
              </TableCell>
              {!isReceivedTabSelected && (
                <TableCell component='th' scope='row' width={'20%'}>
                  FAX
                </TableCell>
              )}
              <TableCell
                component='th'
                scope='row'
                width={isReceivedTabSelected ? '25%' : undefined}
              >
                PREVIEW
              </TableCell>
              {isReceivedTabSelected && (
                <TableCell component='th' scope='row' width={'15%'}>
                  STATUS
                </TableCell>
              )}
              <TableCell
                component='th'
                scope='row'
                align='center'
                width={'10%'}
              >
                ACTIONS
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isGettingEfaxMessages || (selectedTab === 1 && isSendingEfax) ? (
              <TableRow>
                <TableCell
                  colSpan={5}
                  align='center'
                  sx={efaxStyle.noEfaxTableImage}
                >
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : !!paginatedList?.length ? (
              <EfaxTableRows displayedList={paginatedList} />
            ) : (
              <NoEfaxMessage />
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        setPaginatedList={setPaginatedList}
        list={displayedList}
        total={displayedList.length}
      />
      <OpenFaxDrawer />
    </Box>
  );
};

export default EfaxTableDetails;
