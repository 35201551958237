import { Box, CircularProgress, List, Typography } from '@mui/material';
import { generalErrorHandler } from 'helpers/generalErrorHandlerHelper';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { TEST_CONSTANTS } from '../../../../constants/testConstants';
import {
  useAcceptPracticeInviteMutation,
  useGetInvitationListQuery,
  useIgnorePracticeInviteMutation,
} from '../../../../services/myNetworkService';
import { updateInvitationCount } from '../../../../store/slices/notificationSlice';
import { commonClasses } from '../../../../style/commonClasses';
import { myNetworkStyle } from '../../../../style/dashboardStyles/myNetworkStyle';
import { InvitationListResponseType } from '../../../../types/myNetworkTypes';
import PracticeItem, { PracticeItemActionButtonType } from '../PracticeItem';

const InvitationList = () => {
  const dispatch = useAppDispatch();

  const { practiceId, invitationList } = useAppSelector((state) => ({
    practiceId: state.userReducer?.userInfo?.practiceId ?? '',
    invitationList: state.myNetworkReducer.invitationList,
  }));
  const { isLoading: isListFetching } = useGetInvitationListQuery(practiceId, {
    skip: !practiceId,
  });
  const [ignorePracticeInvite, { isLoading: isIgnorePracticeLoaderActive }] =
    useIgnorePracticeInviteMutation();

  const {
    invitationList: { updateStamp },
  } = useAppSelector((state) => state.notificationReducer ?? {});
  const [acceptPracticeInvite, { isLoading: isAcceptPracticeLoaderActive }] =
    useAcceptPracticeInviteMutation();
  const onClickAccept = async (id: string) => {
    await acceptPracticeInvite(id);

    dispatch(updateInvitationCount());
  };

  const onClickDecline = async (id: string) => {
    await ignorePracticeInvite(id)
      .unwrap()
      .then(() => dispatch(updateInvitationCount()))
      .catch((e) => generalErrorHandler(e));
  };

  const actionButtons: PracticeItemActionButtonType[] = [
    {
      title: 'Accept',
      variant: 'outlined',
      onClick: onClickAccept,
      testId: TEST_CONSTANTS.INVITATION_LIST_PRACTICE_ITEM_ACCEPT_BUTTON,
      isLoaderActive: isAcceptPracticeLoaderActive,
    },
    {
      title: 'Decline',
      variant: 'text',
      onClick: onClickDecline,
      testId: TEST_CONSTANTS.INVITATION_LIST_PRACTICE_ITEM_IGNORE_BUTTON,
      isLoaderActive: isIgnorePracticeLoaderActive,
    },
  ];

  return invitationList?.length ? (
    <Box sx={{ maxHeight: 'calc(50% - 1rem)' }}>
      <Box sx={myNetworkStyle.practiceHeaderContainer}>
        <Box sx={myNetworkStyle.headers}>
          <Typography variant='h6' sx={{ fontWeight: 500 }}>
            Invitations
          </Typography>
          <Typography variant='body2'>
            Practices inviting you to connect
          </Typography>
        </Box>
      </Box>
      <Box sx={[myNetworkStyle.listContainer, { p: 0 }]}>
        {isListFetching ? (
          <Box sx={commonClasses.loaderContainer}>
            <CircularProgress />
          </Box>
        ) : (
          <List sx={myNetworkStyle.invitationListContainer}>
            {invitationList?.map(
              (invitation: InvitationListResponseType, index: number) => (
                <React.Fragment key={uuidv4()}>
                  <PracticeItem
                    practiceItem={{
                      id: invitation.id,
                      userName: invitation.fromUserName,
                      profilePicture: invitation.fromUserProfilePicture,
                      practiceType: invitation.fromPracticeType,
                      address: invitation.fromPracticeAddress,
                      practiceName: invitation.fromPracticeName,
                      message: invitation.message,
                    }}
                    index={index}
                    actionButtons={actionButtons}
                    isForInvitationList={true}
                  />
                </React.Fragment>
              )
            )}
          </List>
        )}
      </Box>
    </Box>
  ) : (
    <></>
  );
};

export default InvitationList;
