import { Container } from '@mui/material';

import { profileStyle } from '../../style/profileStyle';
import { ProfileTabs } from './ProfileTabs';
import { useAppSelector } from '../../app/hooks';
import { LoaderOverlay } from '../../components/LoaderOverlay';

const Profile = () => {
  const { getPracticeRolesLoader, userInfo } = useAppSelector((state) => ({
    getPracticeRolesLoader:
      state.generalLoaderReducer?.getPracticeRoleList ?? false,
    userInfo: state.userReducer.userInfo,
  }));

  if (
    !userInfo?.firstName &&
    !userInfo?.lastName &&
    !userInfo?.email &&
    !userInfo?.role &&
    !userInfo?.mobile
  )
    return null;

  return (
    <Container data-testid='profile-container' sx={profileStyle.page}>
      <ProfileTabs />
      {getPracticeRolesLoader && <LoaderOverlay />}
    </Container>
  );
};

export default Profile;
