import { Group } from '@mui/icons-material';
import { Avatar, Box } from '@mui/material';
import { TEST_CONSTANTS } from 'constants/testConstants';
import { chatStyle } from 'style/dashboardStyles/chatStyle';
import { ChatChannel } from 'types/chatTypes';
import { ProfileImageAvatar } from 'components/ProfileImageAvatar';

const ChatAvatar = ({ chat }: { chat?: ChatChannel }) => {
  const displayedImage =
    chat?.recipients?.[0]?.profileUrl || chat?.coverImageUrl || '';

  const hasMultipleRecipients =
    !!chat?.recipients?.length && chat?.recipients?.length > 1;

  const isGroupChat = chat?.isGroupChat || hasMultipleRecipients;

  return isGroupChat ? (
    <Avatar sx={chatStyle.chatAvatar} data-testid={TEST_CONSTANTS.CHAT_AVATAR}>
      <Group />
    </Avatar>
  ) : (
    <Box height='40px' width='40px'>
      <ProfileImageAvatar
        src={displayedImage}
        alt={`${chat?.recipients?.[0]?.name ?? ''}-profileImg`}
        data-testid={TEST_CONSTANTS.CHAT_AVATAR}
      />
    </Box>
  );
};

export default ChatAvatar;
