import { colorPalette } from '../../theme/colorPalette';

export const myOfficeStyle = {
  mainContainer: {
    display: 'flex',
    gap: '1rem',
    flex: 1,
    height: 1,
    flexDirection: 'column',
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: colorPalette.surfaceColor[800],
  },
  teamMemberContainer: {
    height: 'calc(100% - 3rem)',
    p: '1rem',
    mt: '.5rem',
    bgcolor: 'background.default',
    borderRadius: '.5rem',
    overflowY: 'auto',
  },
  addMemberModalHeader: {
    display: 'flex',
    alignItems: 'end',
    justifyContent: 'space-between',
    pb: '1rem',
  },
  addMemberModalHeaderIcon: {
    flex: 1,
    ml: '2.5rem',
    color: 'primary.dark',
    textAlign: 'center',
  },
  addMemberTitle: {
    typography: 'h5',
    fontWeight: 400,
    textAlign: 'center',
  },
  addMemberSubtitle: {
    typography: 'subtitle2',
    fontWeight: 400,
    color: colorPalette.surfaceColor[700],
    textAlign: 'center',
    mt: '1rem',
  },
  addMemberActionButtonContainer: {
    justifyContent: 'center',
    p: '.25rem 1rem 2rem 1rem',
    button: {
      width: '9.375rem',
      typography: 'subtitle2',
      fontWeight: 500,
      boxShadow: 'none',
    },
  },
  addMemberDialogContent: {
    '&.MuiDialogContent-root': {
      pt: '.5rem',
      width: '30rem',
    },
  },
  teamMemberInviteAvatar: {
    height: '3.5rem',
    width: '3.5rem',
    bgcolor: 'primary.main',
    color: 'primary.light',
  },
};
