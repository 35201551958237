import 'cropperjs/dist/cropper.css';

import {
  ChangeEvent,
  type FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
  ReactNode,
} from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slider,
  Typography,
} from '@mui/material';
import { Close, Rotate90DegreesCwOutlined } from '@mui/icons-material';
import ReactCropper, { type ReactCropperElement } from 'react-cropper';

import { profileStyle } from '../../style/profileStyle';
import FlatButton from '../../components/FlatButton';
import { ProfilePictureActionButton } from './ProfilePictureActionButton';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  useDeleteFileMutation,
  useUploadImageMutation,
} from 'services/fileManagerService';
import { v4 as uuidv4 } from 'uuid';
import { generalErrorHandler } from 'helpers/generalErrorHandlerHelper';
import { usePostProfileImgMutation } from 'services/personService';
import {
  UpdateProfileImageSuccess,
  UserApiActions,
  UserInfo,
} from 'store/reduxConstants/userReduxConstants';
import { showSnackbarAction } from 'store/actions/snackbarAction';
import useGetUserImgFromServer from '../../hooks/useGetUserImgFromServer';
import { getRoundedCanvas } from '../../helpers/profileHelper';

export type ProfilePictureCreatorProps = {
  isOpen?: boolean;
  onClose?: () => void;
};

const DEFAULT_SCALE_RATIO = 1;
const DEFAULT_ROTATE_DEG = 0;

export const ProfilePictureCreator: FunctionComponent<
  ProfilePictureCreatorProps
> = ({ isOpen = false, onClose }) => {
  const dispatch = useAppDispatch();
  const { userInfo } = useAppSelector((state) => ({
    id: state.userReducer.id,
    userInfo: state.userReducer.userInfo,
  }));
  const { isProfileImageUpdateLoaderActive } = useAppSelector(
    ({ generalLoaderReducer }: Record<string, any>) =>
      generalLoaderReducer ?? true
  );

  const [scaleRatio, setScaleRatio] = useState(DEFAULT_SCALE_RATIO);
  const [rotateDeg, setRotateDeg] = useState(DEFAULT_ROTATE_DEG);
  const [photo, setPhoto] = useState<string | undefined>(undefined);
  const profileImgBlobUrl = useGetUserImgFromServer(setPhoto);
  const fileSelectRef = useRef<HTMLInputElement>(null);
  const cropperRef = useRef<ReactCropperElement>(null);
  const hasNoUpdate =
    rotateDeg === DEFAULT_ROTATE_DEG &&
    scaleRatio === DEFAULT_SCALE_RATIO &&
    photo === profileImgBlobUrl;

  useEffect(() => {
    if (!cropperRef.current) return;
    // Using `rotateTo` on ReactCropper didn't work, so we'll access the Cropper instance instead.
    // `zoomTo` works, but for some reason, this one doesn't.
    cropperRef.current.cropper.rotateTo(rotateDeg);
  }, [rotateDeg]);

  useEffect(() => {
    // Reset when photo has changed
    setScaleRatio(DEFAULT_SCALE_RATIO);
    setRotateDeg(DEFAULT_ROTATE_DEG);
  }, [photo]);

  const handleClose = useCallback(() => {
    // will reset photo configs to default
    setPhoto(profileImgBlobUrl);
    setScaleRatio(DEFAULT_SCALE_RATIO);
    setRotateDeg(DEFAULT_ROTATE_DEG);

    if (onClose) onClose();
  }, [profileImgBlobUrl, onClose]);

  const [deleteFileMutation] = useDeleteFileMutation();
  const [uploadImageMutation] = useUploadImageMutation();
  const [postProfileImg] = usePostProfileImgMutation();
  const [isUpdatingPhoto, setIsUpdatingPhoto] = useState<boolean>(false);

  const onClickDone = async () => {
    const isRemovedProfileImg =
      (!photo || !cropperRef.current) && !!userInfo?.profileImage;

    // if no change has been done -> will close modal
    if (hasNoUpdate) {
      onClose && onClose();
      return;
    } else {
      setIsUpdatingPhoto(true);

      const handleCompleteUpdate = () => {
        setIsUpdatingPhoto(false);
        onClose && onClose();
      };

      const handleImageUpdateSuccess = (newProfileImage: string) => {
        showSnackbarAction({
          message: 'Your profile picture has been updated',
          severity: 'success',
          statusCode: 200,
          open: true,
        });

        dispatch<UpdateProfileImageSuccess>({
          type: UserApiActions.UPDATE_PROFILE_IMG_SUCCESS,
          payload: { profileImage: newProfileImage } as Pick<
            UserInfo,
            'profileImage'
          >,
        });

        handleCompleteUpdate();
      };

      if (isRemovedProfileImg && !!userInfo?.profileImage) {
        await deleteFileMutation({
          fileName: userInfo?.profileImage,
          blobType: 'Public',
        })
          .unwrap()
          .then(async () => {
            const newImageUrl = '';

            await postProfileImg({
              imgUrl: newImageUrl,
            })
              .unwrap()
              .then(() => {
                handleImageUpdateSuccess(newImageUrl);
              })
              .catch((e) => generalErrorHandler(e));

            handleCompleteUpdate();
          })
          .catch((e) => generalErrorHandler(e));
      } else if (!!cropperRef.current) {
        const croppedCanvas = cropperRef.current.cropper.getCroppedCanvas();
        const roundedCanvas = getRoundedCanvas(croppedCanvas);

        if (!roundedCanvas) return;
        const dataURL = roundedCanvas.toDataURL();

        // will upload the selected image first to the file manager,
        // then update the Person data in the server
        const handleUploadProfileImageToServer = async () => {
          await uploadImageMutation({
            dataURL,
            fileName: `profileImg-${uuidv4()}.png`,
            blobType: 'Public',
          })
            .unwrap()
            .then(async (newImg) => {
              if (!!newImg.url) {
                const lastSlashIndex = newImg.url.lastIndexOf('/');
                const newImgFileName =
                  lastSlashIndex !== -1
                    ? newImg.url.substring(lastSlashIndex + 1)
                    : newImg.url;

                await postProfileImg({
                  imgUrl: newImgFileName,
                })
                  .unwrap()
                  .then(() => {
                    handleImageUpdateSuccess(newImgFileName);
                  })
                  .catch((e) => generalErrorHandler(e));
              }
            })
            .catch((e) => generalErrorHandler(e));
        };

        // if has existing profile image,
        // will delete the image first before uploading the new selected image
        if (!!userInfo?.profileImage) {
          await deleteFileMutation({
            fileName: userInfo?.profileImage,
            blobType: 'Public',
          })
            .unwrap()
            .then(() => handleUploadProfileImageToServer())
            .catch((e) => generalErrorHandler(e));
        } else {
          // if no existing profile image,
          // will directly proceed to uploading the new selected image
          handleUploadProfileImageToServer();
        }
      }
    }
  };

  function handleSelectPhoto(): void {
    if (!fileSelectRef.current) return;
    fileSelectRef.current.click();
  }

  const renderImageViewer = () => {
    function onDeletePhoto(): void {
      setPhoto('');
      setScaleRatio(0);
      setRotateDeg(0);
      if (cropperRef.current) {
        cropperRef.current.cropper.reset();
      }
    }

    function onChangePhoto(e: ChangeEvent<HTMLInputElement>): void {
      if (!e.target.files?.length) return;
      const image = e.target.files[0];
      const dataURL = URL.createObjectURL(image);
      setPhoto(dataURL);
    }

    function handleScale(_: Event, value: number | number[]) {
      const scale = parseFloat(`${value}`);

      cropperRef?.current?.cropper?.scaleX(scale);
      cropperRef?.current?.cropper?.scaleY(scale);

      setScaleRatio(scale);
    }

    let renderedElement: ReactNode = (
      <Button onClick={handleSelectPhoto}>Select a photo</Button>
    );

    if (!!photo) {
      renderedElement = (
        <>
          <input
            accept='image/jpeg, image/png, image/jpg'
            type='file'
            style={{ display: 'none' }}
            ref={fileSelectRef}
            onChange={onChangePhoto}
          />
          <Box sx={profileStyle.profilePictureCreator.content.viewer.container}>
            <ReactCropper
              initialAspectRatio={1}
              aspectRatio={1}
              responsive={true}
              autoCropArea={1}
              center={false}
              className='react-cropper'
              cropBoxResizable={false}
              dragMode='move'
              highlight={false}
              ref={cropperRef}
              rotatable
              src={photo}
              toggleDragModeOnDblclick={false}
              viewMode={1}
              zoomOnWheel={false}
              checkCrossOrigin={false}
              checkOrientation={false}
              scalable
            />
            {!isProfileImageUpdateLoaderActive && (
              <Typography
                sx={
                  profileStyle.profilePictureCreator.content.viewer.instruction
                }
                variant='body2'
              >
                Drag to Reposition Photo
              </Typography>
            )}
            <ProfilePictureActionButton
              variant='outlined'
              sx={
                profileStyle.profilePictureCreator.content.viewer.changeButton
              }
              onClick={handleSelectPhoto}
              disabled={isProfileImageUpdateLoaderActive}
            >
              Change
            </ProfilePictureActionButton>
            <ProfilePictureActionButton
              variant='outlined'
              sx={
                profileStyle.profilePictureCreator.content.viewer.deleteButton
              }
              onClick={onDeletePhoto}
              disabled={isProfileImageUpdateLoaderActive}
            >
              Delete
            </ProfilePictureActionButton>
          </Box>
          <Box sx={profileStyle.profilePictureCreator.content.tools.container}>
            <Box
              sx={profileStyle.profilePictureCreator.content.tools.sizeSlider}
            >
              <Typography variant='body2'>Size</Typography>
              <Slider
                min={1}
                max={2}
                step={0.1}
                defaultValue={scaleRatio || DEFAULT_SCALE_RATIO}
                value={scaleRatio}
                onChange={handleScale}
                disabled={isProfileImageUpdateLoaderActive}
              />
            </Box>
            <FlatButton
              sx={profileStyle.profilePictureCreator.content.tools.rotateButton}
              disabled={isProfileImageUpdateLoaderActive}
              onClick={() =>
                setRotateDeg((prevState) => (prevState + 90) % 360)
              }
            >
              <Rotate90DegreesCwOutlined />
            </FlatButton>
          </Box>
        </>
      );
    }

    return (
      <>
        <input
          accept='image/jpeg, image/png, image/jpg'
          type='file'
          style={{ display: 'none' }}
          ref={fileSelectRef}
          onChange={onChangePhoto}
        />
        {renderedElement}
      </>
    );
  };

  return (
    <>
      <Dialog
        open={isOpen}
        PaperProps={{ sx: profileStyle.profilePictureCreator.container }}
      >
        <DialogTitle
          component='div'
          sx={profileStyle.profilePictureCreator.title.container}
        >
          <Typography
            variant='h6'
            sx={profileStyle.profilePictureCreator.title.text}
          >
            Profile Photo
          </Typography>
          <FlatButton
            onClick={handleClose}
            sx={profileStyle.profilePictureCreator.title.closeButton}
            disabled={isProfileImageUpdateLoaderActive}
          >
            <Close />
          </FlatButton>
        </DialogTitle>
        <DialogContent
          sx={profileStyle.profilePictureCreator.content.container}
        >
          {renderImageViewer()}
        </DialogContent>
        <DialogActions
          sx={profileStyle.profilePictureCreator.actions.container}
        >
          <FlatButton onClick={handleClose}>Cancel</FlatButton>
          <FlatButton variant='contained' onClick={onClickDone}>
            Done
          </FlatButton>
        </DialogActions>
      </Dialog>
      {/* Profile Image Update Loading Indicator */}
      <Dialog
        open={isUpdatingPhoto}
        maxWidth='xs'
        keepMounted
        sx={{
          zIndex: 9999,
        }}
      >
        <DialogContent>
          <Box p='1rem' textAlign='center'>
            <Typography component='div' variant='h5' textAlign='center'>
              Updating profile photo
            </Typography>
            <Box mb='1rem' mt='1.5rem'>
              <CircularProgress />
            </Box>
            <Typography component='div' variant='body1' textAlign='center'>
              This process may take a few minutes.
            </Typography>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};
