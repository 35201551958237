import { createApi } from '@reduxjs/toolkit/query/react';
import { USER_URLS } from '../constants/urlConstants';
import {
  startUserWorkStatusHub,
  hubActions as userWorkStatusHubActions,
  stopUserWorkStatusHub,
} from '../hubs/userWorkStatusHub';
import { RootState } from '../store/store';
import baseQueryWithReauth from './serviceQuery';

const userWorkStatusService = createApi({
  reducerPath: 'userWorkStatusApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (build) => ({
    connectToUserWorkStatusHub: build.mutation({
      //can use queryFn here to bypass baseQuery and the REST API
      queryFn: (token: string | void, { getState, dispatch }) => {
        const t = token ?? (getState() as RootState).authReducer?.token;
        return new Promise((resolve) => {
          startUserWorkStatusHub(USER_URLS.USER_WORK_STATUS_HUB, t, dispatch)
            .then((connected) => resolve({ data: { connected } }))
            .finally(() => resolve({ data: { connected: false } }));
        });
      },
    }),
    changeUserWorkStatus: build.mutation({
      //can use queryFn here to bypass baseQuery and the REST API
      queryFn: (status, { getState }) => {
        return new Promise((resolve) => {
          userWorkStatusHubActions
            .changeStatus(status)
            .finally(() => resolve({ data: { status } }));
        });
      },
    }),
    echoUserWorkStatus: build.mutation({
      //can use queryFn here to bypass baseQuery and the REST API
      queryFn: (successCallback: () => void) => {
        return new Promise((resolve) => {
          userWorkStatusHubActions.echoStatus().then(() => successCallback());
          // .finally(() => resolve({ data }));
        });
      },
    }),
    disconnectHub: build.mutation({
      //can use queryFn here to bypass baseQuery and the REST API
      queryFn: (arg: void) =>
        new Promise((resolve, reject) => {
          stopUserWorkStatusHub()
            .then((connected) => resolve({ data: { connected } }))
            .finally(() => resolve({ data: { connected: false } }));
        }),
    }),
    resetService: build.mutation({
      //reset will disconnect the hub and reset the store through the slice
      queryFn: (arg: void) =>
        new Promise((resolve, reject) => {
          stopUserWorkStatusHub().finally(() => resolve({ data: {} }));
        }),
      //close all system notifications?
      async onQueryStarted(arg, { dispatch }) {
        //clear the cache
        dispatch(userWorkStatusService.util.resetApiState());
      },
    }),
  }),
});

export const {
  useConnectToUserWorkStatusHubMutation,
  useChangeUserWorkStatusMutation,
  useEchoUserWorkStatusMutation,
  useDisconnectHubMutation,
  useResetServiceMutation,
} = userWorkStatusService;

export default userWorkStatusService;
