import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import _ from 'lodash';
import moment from 'moment';

import chatService, { MarkChannel } from '../../services/chatService';
import notificationService from '../../services/notificationService';
import { ChatMessage, MessageType } from '../../types/chatTypes';
import { DocumentsTableDetailsType } from '../../types/documentsTypes';
import { ContactHistory, EfaxTableDetailsType } from '../../types/efaxTypes';
import { InvitationListResponseType } from '../../types/myNetworkTypes';
import {
  NotificationPermission,
  NotificationType,
  SystemNotification,
} from '../../types/notificationTypes';
import localStorageHelper from '../../helpers/localStorageHelper';

export type NewEfaxDocumentsListType = EfaxTableDetailsType & {
  receivedTime?: number;
};

type notificationStateType = {
  hub: { connected: boolean };
  userId?: string; //store this so notifications can be screened
  permission: string;
  totalCount: number;
  channelList: {
    updateCountStamp?: number;
    updateStamp?: { id: string; timestamp: number };
    count?: number;
  };
  invitationList: {
    updateCountStamp?: number;
    updateStamp?: { id: string; timestamp: number };
    count?: number;
  };
  documentList: {
    updateCountStamp?: number;
    updateStamp?: { id: string; timestamp: number };
    count?: number;
  };
  rejectedDocumentList: {
    updateCountStamp?: number;
    updateStamp?: { id: string; timestamp: number };
    count?: number;
  };
  acceptedDocumentList: {
    updateCountStamp?: number;
    updateStamp?: { id: string; timestamp: number };
    count?: number;
  };
  notificationList: SystemNotification[];
  newEfaxDocumentsList: NewEfaxDocumentsListType[];
};

const initialNotificationState: notificationStateType = {
  hub: { connected: false },
  permission: NotificationPermission.none,
  totalCount: 0,
  channelList: {
    count: 0,
    updateCountStamp: moment().unix(),
  },
  invitationList: {
    count: 0,
    updateCountStamp: moment().unix(),
  },
  documentList: {
    count: 0,
    updateCountStamp: moment().unix(),
  },
  rejectedDocumentList: {
    count: 0,
    updateCountStamp: moment().unix(),
  },
  acceptedDocumentList: {
    count: 0,
    updateCountStamp: moment().unix(),
  },
  notificationList: [],
  newEfaxDocumentsList: [],
};

// use createSlice instead of boilerplate constants
// also allows mutating of state directly

const newMessageReducer = (
  state: notificationStateType,
  {
    payload: { message },
  }: {
    payload: {
      message: ChatMessage;
    };
  }
) => {
  //mark the channel list as updated
  state.channelList.updateCountStamp = moment().unix();
  state.channelList.updateStamp = {
    id: message.channelId,
    timestamp: moment().unix(),
  };
  //create a new message notification
  if (message.user?.userId !== state.userId) {
    let body = '';
    switch (message.messageType) {
      case MessageType.attachment:
        body = message.metaDictionary?.attachmentName ?? '📎';
        break;
      case MessageType.gif:
        body =
          message.metaDictionary?.giphyThumbnailUri?.split('/').pop() ?? 'gif';
        break;
      default:
        body = message.messageText ?? '';
    }
    state.notificationList.push({
      id: message.id,
      title: message.user?.name ?? 'New Message',
      body,
      type: NotificationType.chat,
    } as SystemNotification);
  }
};

const newDocumentReducer = (
  state: notificationStateType,
  {
    payload: { document },
  }: {
    payload: {
      document: DocumentsTableDetailsType;
    };
  }
) => {
  //mark the document list as updated
  state.documentList.updateCountStamp = moment().unix();
  state.documentList.updateStamp = {
    id: document.id,
    timestamp: moment().unix(),
  };
  //create a new document notification
  if (document.fromUserId !== state.userId) {
    state.notificationList.push({
      id: document.id,
      title:
        document.fromPracticeName ??
        document.fromUserDisplayName ??
        'Documents',
      body: document.isReferral ? 'New Referral' : 'New document received',
      type: NotificationType.document,
    } as SystemNotification);
  }
};

const newRejectedDocumentReducer = (
  state: notificationStateType,
  {
    payload: { document },
  }: {
    payload: {
      document: DocumentsTableDetailsType;
    };
  }
) => {
  state.rejectedDocumentList.updateCountStamp = moment().unix();
  state.rejectedDocumentList.updateStamp = {
    id: document.id,
    timestamp: moment().unix(),
  };
};

const newAcceptedDocumentReducer = (
  state: notificationStateType,
  {
    payload: { document },
  }: {
    payload: {
      document: DocumentsTableDetailsType;
    };
  }
) => {
  state.acceptedDocumentList.updateCountStamp = moment().unix();
  state.acceptedDocumentList.updateStamp = {
    id: document.id,
    timestamp: moment().unix(),
  };
};

const newOpenedDocumentReducer = (
  state: notificationStateType,
  {
    payload: { document },
  }: {
    payload: {
      document: DocumentsTableDetailsType;
    };
  }
) => {
  state.documentList.updateCountStamp = moment().unix();
  state.documentList.updateStamp = {
    id: document.id,
    timestamp: moment().unix(),
  };
};

const newInvitationReducer = (
  state: notificationStateType,
  {
    payload: { invitation },
  }: {
    payload: {
      invitation: InvitationListResponseType;
    };
  }
) => {
  //mark the invitation list as updated
  state.invitationList.updateCountStamp = moment().unix();
  state.invitationList.updateStamp = {
    id: invitation.id,
    timestamp: moment().unix(),
  };
  //create a new document notification
  if (invitation.fromUserId !== state.userId) {
    state.notificationList.push({
      id: invitation.id,
      title:
        invitation.fromPracticeName ?? invitation.fromUserName ?? 'My Network',
      body: 'New connection request',
      type: NotificationType.network,
    } as SystemNotification);
  }
};

const newFaxReducer = (
  state: notificationStateType,
  {
    payload: { newFax, contactHistory },
  }: {
    payload: {
      newFax: EfaxTableDetailsType;
      contactHistory: ContactHistory[];
    };
  }
) => {
  const userPracticeId = localStorageHelper.getPracticeId();
  const isNewFaxSentByUser = newFax.senderPracticeId === userPracticeId;
  const isNewFaxReceivedByUser = newFax.recipientPracticeId === userPracticeId;
  const existingNewFaxList = state.newEfaxDocumentsList;
  const isExisting = existingNewFaxList?.some(
    (oldFax) =>
      oldFax.id === newFax.id && oldFax.eFaxStatus === newFax.eFaxStatus
  );

  if (!isExisting && newFax.eFaxStatus !== 'processing') {
    let body = '';

    if (isNewFaxReceivedByUser) {
      if (newFax.direction === 'outbound') {
        state.newEfaxDocumentsList.unshift({
          ...newFax,
          receivedTime: moment().unix(),
        });

        const isFromHistory = contactHistory?.some(
          (contact) => contact.EFaxNumber === newFax.senderFaxNumber
        );

        if (isFromHistory) {
          body = `e-Fax received from ${newFax.senderPracticeName}`;
        } else {
          body = `e-Fax received from ${newFax.senderFaxNumber}`;
        }
      }
    }

    if (isNewFaxSentByUser) {
      const isFromHistory = contactHistory?.some(
        (contact) => contact.EFaxNumber === newFax.recipientFaxNumber
      );

      if (newFax.eFaxStatus === 'failed') {
        if (isFromHistory) {
          body = `e-Fax delivery to ${newFax.recipientPracticeName} failed`;
        } else {
          body = `e-Fax delivery to ${newFax.recipientFaxNumber} failed`;
        }
      } else if (newFax.eFaxStatus === 'success') {
        if (isFromHistory) {
          body = `e-Fax successfully sent to ${newFax.recipientPracticeName}`;
        } else {
          body = `e-Fax successfully sent to ${newFax.recipientFaxNumber}`;
        }
      }
    }

    state.notificationList.push({
      id: newFax.id,
      title: 'New Web',
      body,
      type: NotificationType.efax,
    } as SystemNotification);
  }
};

const removeNewFaxReducer = (
  state: notificationStateType,
  {
    payload: { faxId },
  }: {
    payload: { faxId: string };
  }
) => {
  const newList = state.newEfaxDocumentsList.filter(
    (efax) => efax.id !== faxId
  );

  state.newEfaxDocumentsList = newList;
};

const markChannelReducer = (
  state: notificationStateType,
  {
    payload: { timestamp, mark, channelId },
  }: { payload: { timestamp: number; mark: MarkChannel; channelId: string } }
) => {
  switch (mark) {
    case MarkChannel.read:
      state.channelList.updateCountStamp = timestamp ?? moment().unix();
      break;
  }
};

const updateChannelCountReducer = (state: notificationStateType) => {
  state.channelList.updateCountStamp = moment().unix();
};

const updateDocumentCountReducer = (state: notificationStateType) => {
  state.documentList.updateCountStamp = moment().unix();
};

const updateInvitationCountReducer = (state: notificationStateType) => {
  state.invitationList.updateCountStamp = moment().unix();
};

const updateTotalCount = (state: notificationStateType) => {
  state.totalCount =
    (state.channelList.count ?? 0) +
    (state.documentList.count ?? 0) +
    (state.invitationList.count ?? 0);
};

const setPermissionReducer = (
  state: notificationStateType,
  { payload: permission }: { payload: string }
) => {
  state.permission = permission;
};
const setUserIdReducer = (
  state: notificationStateType,
  { payload: userId }: { payload: string }
) => {
  state.userId = userId;
};

const setNotificationsGeneratedReducer = (
  state: notificationStateType,
  { payload: ids }: { payload: string[] | undefined }
) => {
  //if ids is set then will only set generated for those
  //otherwise will set all
  state.notificationList.forEach((n) => {
    if ((ids ?? [n.id]).includes(n.id)) n.generated ??= moment().valueOf();
  });
};

const removeNotificationsReducer = (
  state: notificationStateType,
  { payload: ids }: { payload: string[] | undefined }
) => {
  //if ids is set then will only remove these
  //otherwise will remove all
  _.remove(state.notificationList ?? [], (n) => (ids ?? [n.id]).includes(n.id));
};

const notificationSlice = createSlice({
  name: 'notification',
  initialState: initialNotificationState,
  reducers: {
    newMessage: newMessageReducer,
    newDocument: newDocumentReducer,
    newRejectedDocument: newRejectedDocumentReducer,
    newAcceptedDocument: newAcceptedDocumentReducer,
    newInvitation: newInvitationReducer,
    newOpenedDocument: newOpenedDocumentReducer,
    newFax: newFaxReducer,
    removeNewFax: removeNewFaxReducer,
    updateChannelCount: updateChannelCountReducer,
    updateDocumentCount: updateDocumentCountReducer,
    updateInvitationCount: updateInvitationCountReducer,
    setPermission: setPermissionReducer,
    setUserId: setUserIdReducer,
    setNotificationsGenerated: setNotificationsGeneratedReducer,
    removeNotifications: removeNotificationsReducer,
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      notificationService.endpoints.getUnreadMessagesCount.matchFulfilled,
      (state, { payload: { count } }) => {
        state.channelList.count = count;
        updateTotalCount(state);
      }
    );
    builder.addMatcher(
      notificationService.endpoints.getUnprocessedFilesCount.matchFulfilled,
      (state, { payload: { count } }) => {
        state.documentList.count = count;
        updateTotalCount(state);
      }
    );
    builder.addMatcher(
      notificationService.endpoints.getPracticeInvitesCount.matchFulfilled,
      (state, { payload: { count } }) => {
        state.invitationList.count = count;
        updateTotalCount(state);
      }
    );
    builder.addMatcher(
      isAnyOf(
        notificationService.endpoints.connectHub.matchFulfilled,
        notificationService.endpoints.disconnectHub.matchFulfilled
      ),
      (state, { payload: { connected } }) => {
        state.hub.connected = connected;
      }
    );
    builder.addMatcher(
      notificationService.endpoints.resetService.matchFulfilled,
      () => initialNotificationState
    );
    builder.addMatcher(
      chatService.endpoints.markChannel.matchFulfilled,
      (
        state,
        {
          payload: { timestamp },
          meta: {
            arg: {
              originalArgs: { mark, channelId },
            },
          },
        }
      ) => {
        markChannelReducer(state, { payload: { timestamp, mark, channelId } });
      }
    );
  },
});

export const {
  newMessage,
  newDocument,
  newRejectedDocument,
  newAcceptedDocument,
  newOpenedDocument,
  newInvitation,
  newFax,
  removeNewFax,
  updateChannelCount,
  updateDocumentCount,
  updateInvitationCount,
  setPermission,
  setUserId,
  setNotificationsGenerated,
  removeNotifications,
} = notificationSlice.actions;

export default notificationSlice.reducer;
