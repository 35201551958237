import { Navigate } from 'react-router-dom';

import { ROUTE_CONSTANTS_VARIABLE } from '../constants/routeConstants';
import localStorageHelper from '../helpers/localStorageHelper';
import { useLocalStorageLoader } from '../hooks/useLocalStorageLoader';
import { useForceLogout } from '../hooks/useForceLogout';
import { IS_INTRO_SCREEN_VISIBLE } from '../constants/urlConstants';

type ProtectedRouteType = {
  children: JSX.Element;
};

const ProtectedRoute = (props: ProtectedRouteType) => {
  const { children } = props;
  const token = localStorageHelper.getAuthToken();

  useLocalStorageLoader();
  useForceLogout();

  return token ? (
    children
  ) : (
    <Navigate
      to={
        IS_INTRO_SCREEN_VISIBLE
          ? ROUTE_CONSTANTS_VARIABLE.INTRODUCTION_SCREEN
          : ROUTE_CONSTANTS_VARIABLE.LOGIN
      }
    />
  );
};

export default ProtectedRoute;
