import { HeaderReducer } from '../../types/headerTypes';
import {
  HeaderReducerActions,
  HEADER_REDUX_CONSTANTS,
} from '../reduxConstants/headerReduxConstants';

const initialHeaderReducer: HeaderReducer = {
  searchedText: '',
};

export const headerReducer = (
  state = initialHeaderReducer,
  action: HeaderReducerActions
): HeaderReducer => {
  switch (action.type) {
    case HEADER_REDUX_CONSTANTS.SET_HEADER_SEARCH_TEXT:
      return {
        ...state,
        searchedText: action.payload,
      };

    default:
      return state;
  }
};
