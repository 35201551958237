import AddIcon from '@mui/icons-material/Add';
import { Button, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { TEST_CONSTANTS } from '../../../../constants/testConstants';
import { myNetworkStyle } from '../../../../style/dashboardStyles/myNetworkStyle';
import { headerStyle } from '../../../../style/headerStyle';

const NoSearchResults = ({
  setIsInvitePracticeDialogOpen,
  setIsAddMemberDialogOpen,
}: {
  setIsInvitePracticeDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsAddMemberDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  return (
    <Box
      sx={headerStyle.noSearchResultContainer}
      data-testid={TEST_CONSTANTS.HEADER_SEARCH_BAR_NO_SEARCH_RESULTS_CONTAINER}
    >
      <Typography
        data-testid={TEST_CONSTANTS.HEADER_SEARCH_BAR_NO_SEARCH_TEXT}
        component='div'
        variant='subtitle2'
        textAlign='center'
      >
        We couldn't find anyone on
        <br /> drtalk matching to that
      </Typography>
      <Typography component='div' variant='subtitle2'>
        Would you like to invite them to join?
      </Typography>
      <Button
        variant='contained'
        color='primary'
        sx={myNetworkStyle.addButton}
        startIcon={<AddIcon />}
        data-testid={TEST_CONSTANTS.HEADER_SEARCH_BAR_ADD_PRACTICE_BUTTON}
        onMouseDown={() => setIsInvitePracticeDialogOpen(true)}
      >
        Invite Practice
      </Button>
      <Button
        variant='contained'
        color='primary'
        sx={myNetworkStyle.addButton}
        startIcon={<AddIcon />}
        onMouseDown={() => setIsAddMemberDialogOpen(true)}
      >
        Add Member
      </Button>
    </Box>
  );
};

export default NoSearchResults;
