/* eslint-disable no-octal */
import _ from 'lodash';
import moment from 'moment';
import {
  DOCUMENT_STATUS_DATABASE_VALUES,
  DOCUMENT_TYPES_FILTER,
} from '../../../constants/documentsConstants';
import { documentApplyFilter } from '../../../helpers/documents/documentApplyFilterHelper';
import documentListSortByDate from '../../../helpers/documents/documentListSortByDate';
import localStorageHelper from '../../../helpers/localStorageHelper';
import {
  AddContactFormData,
  DocumentPatientFilter,
  DocumentReducer,
  DocumentsTableDetailsType,
  DocumentStatus,
  DocumentTableFilters,
  Patient,
  PracticeDirectoryPractice,
  SendDocumentFormDataType,
  SendDocumentRecordStatus,
  SortedPracticeDirectory,
} from '../../../types/documentsTypes';
import { AutocompleteOption } from '../../../types/Misc';
import {
  DocumentActionTypes,
  DOCUMENT_REDUX_CONSTANTS,
} from '../../reduxConstants/documentsReduxConstants';

const initialFilters: DocumentTableFilters = {
  patient: null,
  sender: null,
  practice: null,
  documentType: DOCUMENT_TYPES_FILTER.ALL,
};

export const initialSendDocumentFormData: SendDocumentFormDataType = {
  recipients: [],
  patient: {
    firstName: '',
    lastName: '',
    dateOfBirth: '',
  },
  message: '',
  attachments: [],
};

export const initialNewRecipientFormData: AddContactFormData = {
  PracticeName: '',
  Email: '',
};

export const initialDocumentReducer: DocumentReducer = {
  allDocumentList: [],
  documentList: [],
  paginatedList: [],
  filteredAllDocList: [],
  practiceDirectoryRecipients: [],
  practiceDirectoryPractices: [],
  sortedPracticeDirectory: [],
  selectedTab: 0,
  connectedPractices: [],
  patientFilterOptions: [],
  senderOptions: [],
  practiceOptions: [],
  connectedPracticesOptions: [],
  patientOptions: [],
  appliedFilters: initialFilters,
  receivedDocumentStatusFilter: 'Unprocessed',
  sendDocumentStep: 1,
  sendDocumentFormData: initialSendDocumentFormData,
  // data that user adds in add contacts dialog, while adding recipient details
  recipientsData: [],
  sendDocumentStatusList: [],
  addressBookContacts: [],
  newRecipientFormData: initialNewRecipientFormData,
};

export const documentReducer = (
  state = initialDocumentReducer,
  action: DocumentActionTypes
): DocumentReducer => {
  switch (action.type) {
    case DOCUMENT_REDUX_CONSTANTS.ON_CHANGE_SELECTED_TAB: {
      const { selectedTab, guestToken } = action.payload;
      // eliminate filters which has null value or 'All'(which means no need of filtering out)
      let updatedFilters = { ...state.appliedFilters };
      for (const key in state.appliedFilters) {
        if (
          updatedFilters[key as keyof DocumentTableFilters] === null ||
          updatedFilters[key as keyof DocumentTableFilters] ===
            DOCUMENT_TYPES_FILTER.ALL
        ) {
          delete updatedFilters[key as keyof DocumentTableFilters];
        }
      }

      let filteredAllDocList = state.filteredAllDocList;
      let list = state.documentList;
      if (guestToken) {
        list = state.allDocumentList.filter((record) =>
          action.payload.selectedTab === 0
            ? !record.guestSenderEmailAddress
            : !!record.guestSenderEmailAddress?.length
        );
      } else {
        // filter helper used for filtering out records for selected tab, pass only selected filters
        const { updatedFilteredAllDocList, updatedDocumentList } =
          documentApplyFilter(
            selectedTab,
            updatedFilters,
            state.allDocumentList ?? [],
            state.receivedDocumentStatusFilter
          );
        filteredAllDocList = updatedFilteredAllDocList;
        list = updatedDocumentList;
      }

      return {
        ...state,
        selectedTab,
        documentList: documentListSortByDate([...list]),
        filteredAllDocList: filteredAllDocList,
      };
    }

    case DOCUMENT_REDUX_CONSTANTS.GET_DOCUMENT_LIST:
      // eliminate filters which has null value or 'All'(which means no need of filtering out)
      let updatedFilters = { ...state.appliedFilters };

      for (const key in state.appliedFilters) {
        if (
          updatedFilters[key as keyof DocumentTableFilters] === null ||
          updatedFilters[key as keyof DocumentTableFilters] ===
            DOCUMENT_TYPES_FILTER.ALL
        ) {
          delete updatedFilters[key as keyof DocumentTableFilters];
        }
      }

      // filter helper used for filtering out records for selected tab, pas only selected filters
      const { updatedFilteredAllDocList, updatedDocumentList } =
        documentApplyFilter(
          state.selectedTab,
          updatedFilters,
          action.payload ?? [],
          state.receivedDocumentStatusFilter
        );

      return {
        ...state,
        allDocumentList: action.payload,
        filteredAllDocList: updatedFilteredAllDocList,
        documentList: documentListSortByDate(updatedDocumentList),
      };

    // setting document table filter dropdowns options
    case DOCUMENT_REDUX_CONSTANTS.SET_DOCUMENT_TABLE_FILTER_OPTIONS: {
      const tabData: DocumentsTableDetailsType[] = state.allDocumentList?.length
        ? state.allDocumentList.filter((record: DocumentsTableDetailsType) =>
            state.selectedTab === 0
              ? record.toPracticeId === localStorageHelper.getPracticeId()
              : record.toPracticeId !== localStorageHelper.getPracticeId()
          )
        : [];
      const updatedpatientFilterOptions = _.uniqBy(
        tabData,
        function (document: DocumentsTableDetailsType) {
          if (document?.patientTag)
            return document?.patientTag?.toLocaleLowerCase();
        }
      )
        ?.map((document: DocumentsTableDetailsType) => {
          const { patientTag, isReferral, patientDateOfBirth } = document;

          return {
            patientTag,
            isReferral,
            patientDateOfBirth: patientDateOfBirth
              ? moment(patientDateOfBirth).format('MM/DD/YYYY')
              : '',
          };
        })
        ?.filter(
          (patient: DocumentPatientFilter) => patient.patientTag !== null
        );

      const updatedSenderOptions = _.uniqBy(
        tabData,
        function (document: DocumentsTableDetailsType) {
          return document?.fromUserDisplayName?.toLocaleLowerCase();
        }
      )
        ?.map(
          (document: DocumentsTableDetailsType) => document?.fromUserDisplayName
        )
        ?.filter((sender: string) => sender !== null);

      const updatedPracticeOptions = _.uniqBy(
        tabData,
        function (document: DocumentsTableDetailsType) {
          return state.selectedTab === 0
            ? document?.fromPracticeId?.toLocaleLowerCase()
            : document?.toPracticeId?.toLocaleLowerCase();
        }
      )
        ?.map((document: DocumentsTableDetailsType) => ({
          label:
            state.selectedTab === 0
              ? document?.fromPracticeName
              : document?.toPracticeName,
          value:
            state.selectedTab === 0
              ? document?.fromPracticeId
              : document?.toPracticeId,
        }))
        .filter(
          (record: AutocompleteOption) =>
            record.label !== null && record.value !== null
        );

      return {
        ...state,
        patientFilterOptions: updatedpatientFilterOptions,
        senderOptions: updatedSenderOptions,
        practiceOptions: updatedPracticeOptions,
      };
    }

    case DOCUMENT_REDUX_CONSTANTS.UPDATE_PAGINATED_LIST: {
      return { ...state, paginatedList: action.payload };
    }

    // connected practices for send documents dialog practice dropdown options
    case DOCUMENT_REDUX_CONSTANTS.GET_CONNECTED_PRACTICES: {
      const connectedPracticesOptions = _.orderBy(
        action.payload.map((practice) => ({
          label: practice.name,
          value: practice.id,
        })),
        [
          (practice: { label: string; value: string }) =>
            practice.label.toLowerCase(),
        ]
      );

      return {
        ...state,
        connectedPractices: action.payload,
        connectedPracticesOptions,
      };
    }

    // patient dropdown list for send document dialog Patient field
    case DOCUMENT_REDUX_CONSTANTS.GET_PATIENT_LIST: {
      const patientOptions = action.payload?.length
        ? action.payload?.map((patient: Patient) => ({
            label: `${patient.firstName} ${patient.lastName}`,
            value: patient.id,
            birthDate: patient.dateOfBirth ?? undefined,
          }))
        : [];
      return {
        ...state,
        patientOptions,
      };
    }

    case DOCUMENT_REDUX_CONSTANTS.DELETE_DOCUMENT_TABLE_ROW: {
      let updatedDocumentList = state.documentList.filter(
        (record: DocumentsTableDetailsType) => record.id !== action.payload.id
      );

      const updatedAllDocumentList = state.allDocumentList.filter(
        (record: DocumentsTableDetailsType) => record.id !== action.payload.id
      );

      const updatedFilteredAllDocumentList = state.filteredAllDocList.filter(
        (record: DocumentsTableDetailsType) => record.id !== action.payload.id
      );

      updatedDocumentList = documentListSortByDate(updatedDocumentList);
      return {
        ...state,
        documentList: [...updatedDocumentList],
        allDocumentList: [...updatedAllDocumentList],
        filteredAllDocList: [...updatedFilteredAllDocumentList],
      };
    }

    // for selected tab - 'Received' - document status filter - by default it will filter 'Unprocessed' records
    case DOCUMENT_REDUX_CONSTANTS.ON_RECEIVED_DOCUMENT_STATUS_CHANGE: {
      let updatedFilters = state.appliedFilters;
      // eliminate filters which has null value or 'All'(which means no need of filtering out)
      for (const key in updatedFilters) {
        if (
          updatedFilters[key as keyof DocumentTableFilters] === null ||
          updatedFilters[key as keyof DocumentTableFilters] ===
            DOCUMENT_TYPES_FILTER.ALL
        ) {
          delete updatedFilters[key as keyof DocumentTableFilters];
        }
      }

      // filter helper used for filtering out records for selected tab, pas only selected filters
      const { updatedFilteredAllDocList, updatedDocumentList } =
        documentApplyFilter(
          state.selectedTab,
          updatedFilters,
          state.allDocumentList,
          action.payload
        );
      return {
        ...state,
        filteredAllDocList: updatedFilteredAllDocList,
        documentList: documentListSortByDate(updatedDocumentList),
        receivedDocumentStatusFilter: action.payload,
      };
    }

    case DOCUMENT_REDUX_CONSTANTS.ON_CHANGE_OR_APPLY_DOCUMENT_TABLE_FILTER: {
      const { name, value } = action.payload;
      const updatedFilters: DocumentTableFilters = {
        ...state.appliedFilters,
        [name]: value,
      };

      // eliminate filters which has null value or 'All'(which means no need of filtering out)
      for (const key in updatedFilters) {
        if (
          updatedFilters[key as keyof DocumentTableFilters] === null ||
          updatedFilters[key as keyof DocumentTableFilters] ===
            DOCUMENT_TYPES_FILTER.ALL
        ) {
          delete updatedFilters[key as keyof DocumentTableFilters];
        }
      }

      // filter helper used for filtering out records for selected tab, pas only selected filters
      const { updatedFilteredAllDocList, updatedDocumentList } =
        documentApplyFilter(
          state.selectedTab,
          updatedFilters,
          state.allDocumentList,
          state.receivedDocumentStatusFilter
        );

      return {
        ...state,
        appliedFilters: {
          ...state.appliedFilters,
          [name]: value,
        },
        filteredAllDocList: documentListSortByDate(updatedFilteredAllDocList),
        documentList: documentListSortByDate(updatedDocumentList),
      };
    }

    case DOCUMENT_REDUX_CONSTANTS.ON_CHANGE_DOCUMENT_TYPE:
    case DOCUMENT_REDUX_CONSTANTS.UPDATE_RECORD_IN_DOCUMENT_LIST: {
      const updatedAllDocumentList = state.allDocumentList.map(
        (record: DocumentsTableDetailsType) =>
          record.id === action.payload.id ? action.payload : record
      );

      // eliminate filters which has null value or 'All'(which means no need of filtering out)
      let updatedFilters = { ...state.appliedFilters };
      for (const key in state.appliedFilters) {
        if (
          updatedFilters[key as keyof DocumentTableFilters] === null ||
          updatedFilters[key as keyof DocumentTableFilters] ===
            DOCUMENT_TYPES_FILTER.ALL
        ) {
          delete updatedFilters[key as keyof DocumentTableFilters];
        }
      }

      let { updatedFilteredAllDocList, updatedDocumentList } =
        documentApplyFilter(
          state.selectedTab,
          updatedFilters,
          updatedAllDocumentList,
          state.receivedDocumentStatusFilter
        );

      updatedDocumentList = documentListSortByDate(updatedDocumentList);

      return {
        ...state,
        documentList: [...updatedDocumentList],
        allDocumentList: [...updatedAllDocumentList],
        filteredAllDocList: [...updatedFilteredAllDocList],
      };
    }

    case DOCUMENT_REDUX_CONSTANTS.ON_PROCESS_DOCUMENT_RECORD: {
      const isProcessed: DocumentStatus =
        DOCUMENT_STATUS_DATABASE_VALUES.PROCESSED;
      const updatedAllDocumentList = state.allDocumentList.map(
        (record: DocumentsTableDetailsType) =>
          record.id === action.payload
            ? { ...record, documentStatus: isProcessed }
            : record
      );

      // eliminate filters which has null value or 'All'(which means no need of filtering out)
      let updatedFilters = { ...state.appliedFilters };
      for (const key in state.appliedFilters) {
        if (
          updatedFilters[key as keyof DocumentTableFilters] === null ||
          updatedFilters[key as keyof DocumentTableFilters] ===
            DOCUMENT_TYPES_FILTER.ALL
        ) {
          delete updatedFilters[key as keyof DocumentTableFilters];
        }
      }

      let { updatedFilteredAllDocList, updatedDocumentList } =
        documentApplyFilter(
          state.selectedTab,
          updatedFilters,
          updatedAllDocumentList,
          state.receivedDocumentStatusFilter
        );

      updatedDocumentList = documentListSortByDate(updatedDocumentList);
      return {
        ...state,
        allDocumentList: updatedAllDocumentList,
        filteredAllDocList: updatedFilteredAllDocList,
        documentList: updatedDocumentList,
      };
    }

    case DOCUMENT_REDUX_CONSTANTS.UPDATE_DOCUMENT_LIST: {
      const updatedAllDocumentList = [...state.allDocumentList, action.payload];
      let updatedDocumentList = updatedAllDocumentList.filter(
        (record: DocumentsTableDetailsType) =>
          state.selectedTab === 0
            ? record.toPracticeId === localStorageHelper.getPracticeId()
            : record.toPracticeId !== localStorageHelper.getPracticeId()
      );

      updatedDocumentList = documentListSortByDate(updatedDocumentList);
      return {
        ...state,
        documentList: updatedDocumentList,
        allDocumentList: updatedAllDocumentList,
        appliedFilters: initialFilters,
      };
    }

    // on header searchbar search, also reset local filter dropdowns
    case DOCUMENT_REDUX_CONSTANTS.ON_DOCUMENT_HEADER_SEARCH: {
      let updatedDocumentList = [];
      if (state.allDocumentList?.length && action.payload?.length) {
        for (let i = 0; i < state.allDocumentList.length; i++) {
          const docRecord = state.allDocumentList[i];
          let searchRecord;
          for (let j = 0; j < action.payload.length; j++) {
            searchRecord = action.payload[j];
            if (docRecord.id === searchRecord.id) {
              updatedDocumentList.push(searchRecord);
            }
          }
        }
      }
      updatedDocumentList = updatedDocumentList?.filter(
        (record: DocumentsTableDetailsType) =>
          state.selectedTab === 0
            ? record.toPracticeId === localStorageHelper.getPracticeId()
            : record.toPracticeId !== localStorageHelper.getPracticeId()
      );
      updatedDocumentList = documentListSortByDate(updatedDocumentList);

      return {
        ...state,
        documentList: updatedDocumentList,
        appliedFilters: initialFilters,
      };
    }

    case DOCUMENT_REDUX_CONSTANTS.RESET_SELECTED_TAB_DOCUMENT_LIST: {
      let updatedDocumentList = state.allDocumentList?.length
        ? state.allDocumentList.filter((record: DocumentsTableDetailsType) =>
            state.selectedTab === 0
              ? record.toPracticeId === localStorageHelper.getPracticeId()
              : record.toPracticeId !== localStorageHelper.getPracticeId()
          )
        : [];
      updatedDocumentList = documentListSortByDate(updatedDocumentList);
      return {
        ...state,
        documentList: updatedDocumentList,
      };
    }

    case DOCUMENT_REDUX_CONSTANTS.SET_SEND_DOCUMENT_FORMDATA: {
      return {
        ...state,
        sendDocumentFormData: action.payload,
      };
    }

    case DOCUMENT_REDUX_CONSTANTS.ON_CHANGE_RECIPIENT_DATA: {
      // Check if recipient with the same email already exists
      const updatedRecipientData = state.recipientsData.map((recipient) =>
        recipient.Email === action.payload.Email ? action.payload : recipient
      );
      // If recipient does not exist, push new recipient
      if (
        !updatedRecipientData.some(
          (recipient) => recipient.Email === action.payload.Email
        )
      ) {
        updatedRecipientData.push(action.payload);
      }

      return {
        ...state,
        recipientsData: updatedRecipientData,
      };
    }

    case DOCUMENT_REDUX_CONSTANTS.CLEAR_RECIPIENT_LIST: {
      return {
        ...state,
        sendDocumentFormData: initialSendDocumentFormData,
      };
    }

    case DOCUMENT_REDUX_CONSTANTS.DELETE_RECIPIENT_DATA: {
      return {
        ...state,
        recipientsData: [],
      };
    }

    case DOCUMENT_REDUX_CONSTANTS.ON_ACCEPT_PENDING_DOCUMENT: {
      const updatedAllDocumentList = state.allDocumentList.map(
        (record: DocumentsTableDetailsType) =>
          record.id === action.payload
            ? { ...record, approved: true, guestReferralStatus: 1 }
            : record
      );

      const updatedFilteredAllDocList = state.filteredAllDocList.map(
        (record: DocumentsTableDetailsType) =>
          record.id === action.payload
            ? { ...record, approved: true, guestReferralStatus: 1 }
            : record
      );

      let updatedDocumentList = state.documentList?.length
        ? state.documentList.map((record: DocumentsTableDetailsType) =>
            record.id === action.payload
              ? { ...record, approved: true, guestReferralStatus: 1 }
              : record
          )
        : [];

      updatedDocumentList = documentListSortByDate(updatedDocumentList);
      return {
        ...state,
        allDocumentList: updatedAllDocumentList,
        filteredAllDocList: updatedFilteredAllDocList,
        documentList: updatedDocumentList,
      };
    }

    case DOCUMENT_REDUX_CONSTANTS.ON_REJECT_PENDING_DOCUMENT: {
      const updatedAllDocumentList = state.allDocumentList.filter(
        (record: DocumentsTableDetailsType) => record.id !== action.payload
      );

      const updatedFilteredAllDocList = state.filteredAllDocList.filter(
        (record: DocumentsTableDetailsType) => record.id !== action.payload
      );

      let updatedDocumentList = state.documentList?.length
        ? state.documentList.filter(
            (record: DocumentsTableDetailsType) => record.id !== action.payload
          )
        : [];

      updatedDocumentList = documentListSortByDate(updatedDocumentList);
      return {
        ...state,
        allDocumentList: updatedAllDocumentList,
        filteredAllDocList: updatedFilteredAllDocList,
        documentList: updatedDocumentList,
      };
    }

    case DOCUMENT_REDUX_CONSTANTS.GET_DOCUMENT_LIST_BY_TOKEN: {
      const recordWithToken = action.payload.data.find(
        (record: DocumentsTableDetailsType) =>
          record.secureEmailToken === action.payload.guestToken
      );

      const isRecordSent =
        recordWithToken && recordWithToken?.guestSenderEmailAddress;

      const updatedDocumentList = action.payload.data.filter((record) =>
        isRecordSent
          ? record.guestSenderEmailAddress?.length
          : !record.guestSenderEmailAddress
      );

      return {
        ...state,
        allDocumentList: action.payload.data,
        documentList: documentListSortByDate(updatedDocumentList),
        selectedTab: isRecordSent ? 1 : 0,
      };
    }

    case DOCUMENT_REDUX_CONSTANTS.UPDATE_SEND_DOCUMENT_STATUS_LIST: {
      let updatedSendDocumentStatusList: SendDocumentRecordStatus[] =
        state.sendDocumentStatusList;
      const isSamePractice = (recipient: SendDocumentRecordStatus) =>
        recipient.toPracticeId.trim().length
          ? recipient.toPracticeId === action.payload.toPracticeId
          : recipient.name === action.payload.name;
      updatedSendDocumentStatusList = updatedSendDocumentStatusList.map(
        (recipient: SendDocumentRecordStatus) =>
          isSamePractice(recipient) ? { ...action.payload } : { ...recipient }
      );

      // if it's a new record, push the record into the list
      if (
        !updatedSendDocumentStatusList.some((recipient) =>
          isSamePractice(recipient)
        )
      ) {
        updatedSendDocumentStatusList.push(action.payload);
      }
      return {
        ...state,
        sendDocumentStatusList: [...updatedSendDocumentStatusList],
      };
    }

    case DOCUMENT_REDUX_CONSTANTS.RESET_SEND_DOCUMENT_STATUS_LIST: {
      return {
        ...state,
        sendDocumentStatusList: initialDocumentReducer.sendDocumentStatusList,
      };
    }

    case DOCUMENT_REDUX_CONSTANTS.SET_ADDRESS_BOOK_CONTACTS: {
      return {
        ...state,
        addressBookContacts: action.payload,
      };
    }

    case DOCUMENT_REDUX_CONSTANTS.SET_NEW_RECIPIENT_FORM_DATA: {
      return {
        ...state,
        newRecipientFormData: action.payload,
      };
    }

    case DOCUMENT_REDUX_CONSTANTS.GET_PRACTICE_DIRECTORY_DATA: {
      const { practices, recipients } = action.payload;
      const updatedPracticeDirectoryPractices: PracticeDirectoryPractice[] =
        practices?.map((practice: PracticeDirectoryPractice) => ({
          ...practice,
          DoctorsNames: practice.DoctorsNames.filter(
            (doctorName: string | null) => doctorName !== null
          ),
        }));

      let updatedSortedPracticeDirectory: SortedPracticeDirectory[] = [
        ...updatedPracticeDirectoryPractices,
        ...recipients,
      ];

      updatedSortedPracticeDirectory = _.sortBy(
        updatedSortedPracticeDirectory,
        (obj) => {
          const key =
            ('PracticeName' in obj && obj.PracticeName.toLowerCase()) ||
            ('Name' in obj && obj.Name.toLowerCase());
          return key;
        }
      );
      return {
        ...state,
        practiceDirectoryRecipients: action.payload.recipients,
        practiceDirectoryPractices: updatedPracticeDirectoryPractices,
        sortedPracticeDirectory: updatedSortedPracticeDirectory,
      };
    }

    case DOCUMENT_REDUX_CONSTANTS.SET_SEND_DOCUMENT_DIALOG_STEP: {
      return {
        ...state,
        sendDocumentStep: action.payload,
      };
    }

    case DOCUMENT_REDUX_CONSTANTS.ON_ADD_NEW_RECIPIENT_TO_FORM_DATA: {
      const isSelectedRecipient = state.sendDocumentFormData.recipients.some(
        (recipient) => recipient.email === action.payload.email
      );

      let updatedSendDocumentFormDataRecipients =
        state.sendDocumentFormData.recipients;

      // if the email address of the new contact is currently in the selected recipients,
      // will update the recipient data w/ the data of the new added contact
      if (isSelectedRecipient) {
        updatedSendDocumentFormDataRecipients =
          state.sendDocumentFormData.recipients.map((recipient) => {
            if (recipient.email === action.payload.email) {
              return {
                id: action.payload.id,
                email: action.payload.email,
                name: action.payload.practiceName,
                type: action.payload.type,
                isConnectedPractice: false,
              };
            } else {
              return recipient;
            }
          });
      }

      return {
        ...state,
        sendDocumentFormData: {
          ...state.sendDocumentFormData,
          recipients: updatedSendDocumentFormDataRecipients,
        },
      };
    }

    case DOCUMENT_REDUX_CONSTANTS.ON_UPDATE_PRACTICE_RECIPIENT: {
      const updatedPracticeDirectoryRecipients =
        state.practiceDirectoryRecipients.map((recipient) => {
          if (recipient.id === action.payload.id) {
            return {
              ...recipient,
              Email: action.payload.newEmail,
              PracticeName: action.payload.newName,
            };
          } else {
            return recipient;
          }
        });

      const updatedSortedPracticeDirectory: SortedPracticeDirectory[] =
        state.sortedPracticeDirectory.map((record) => {
          if (record.id === action.payload.id) {
            return {
              ...record,
              Email: action.payload.newEmail,
              PracticeName: action.payload.newName,
            };
          } else {
            return record;
          }
        });

      const isSelectedRecipient = state.sendDocumentFormData.recipients.some(
        (recipient) => recipient.id === action.payload.id
      );

      let updatedSendDocumentFormDataRecipients =
        state.sendDocumentFormData.recipients;

      // if the updated contact is currently selected as recipient,
      // will update the recipient data w/ the data of the updated contact data
      if (isSelectedRecipient) {
        updatedSendDocumentFormDataRecipients =
          state.sendDocumentFormData.recipients.map((recipient) => {
            if (recipient.id === action.payload.id) {
              return {
                ...recipient,
                email: action.payload.newEmail,
                name: action.payload.newName,
              };
            } else {
              return recipient;
            }
          });
      }

      return {
        ...state,
        practiceDirectoryRecipients: updatedPracticeDirectoryRecipients,
        sortedPracticeDirectory: updatedSortedPracticeDirectory,
        sendDocumentFormData: {
          ...state.sendDocumentFormData,
          recipients: updatedSendDocumentFormDataRecipients,
        },
      };
    }

    case DOCUMENT_REDUX_CONSTANTS.ON_DELETE_PRACTICE_RECIPIENT: {
      const updatedPracticeDirectoryRecipients =
        state.practiceDirectoryRecipients.filter(
          (recipient) => recipient.id !== action.payload
        );

      const updatedSortedPracticeDirectory: SortedPracticeDirectory[] =
        state.sortedPracticeDirectory.filter(
          (record) => record.id !== action.payload
        );

      const isSelectedRecipient = state.sendDocumentFormData.recipients.some(
        (recipient) => recipient.id === action.payload
      );

      let updatedSendDocumentFormDataRecipients =
        state.sendDocumentFormData.recipients;

      // if deleted recipient is previously selected,
      // will update the sendDocumentFormData.recipient object to convert the recipient into a new recipient object
      if (isSelectedRecipient) {
        updatedSendDocumentFormDataRecipients =
          state.sendDocumentFormData.recipients.map((recipient) => {
            if (recipient.id === action.payload) {
              return {
                ...recipient,
                id: recipient.email!,
                name: recipient.email!,
                type: undefined,
                isConnectedPractice: false,
                isNewEmailRecipient: true,
              };
            } else {
              return recipient;
            }
          });
      }

      return {
        ...state,
        practiceDirectoryRecipients: updatedPracticeDirectoryRecipients,
        sortedPracticeDirectory: updatedSortedPracticeDirectory,
        sendDocumentFormData: {
          ...state.sendDocumentFormData,
          recipients: updatedSendDocumentFormDataRecipients,
        },
      };
    }

    default:
      return state;
  }
};
