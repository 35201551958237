import { Box, TextField, Typography } from '@mui/material';
import { ChangeEvent } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { TEST_CONSTANTS } from '../../../../constants/testConstants';
import { setSendDocumentFormDataAction } from '../../../../store/actions/dashboardActions/documentActions';
import { RootState } from '../../../../store/store';
import { documentStyle } from '../../../../style/dashboardStyles/documentStyle';
import {
  DocumentReducer,
  SendDocumentErrors
} from '../../../../types/documentsTypes';
import SendDocumentPatientDropdown from './SendDocumentPatientDropdown';

type SendDocumentsDialogLeft = {
  isFormSubmittedOnce: boolean;
  errors: SendDocumentErrors;
};

const SendDocumentsDialogLeftPart = ({
  isFormSubmittedOnce,
  errors,
}: SendDocumentsDialogLeft) => {
  const dispatch = useAppDispatch();

  const { sendDocumentFormData } = useAppSelector(
    (state: RootState) => state.documentReducer as DocumentReducer
  );

  const { isSendDocumentsLoaderActive } = useAppSelector(
    ({ generalLoaderReducer }: Record<string, any>) =>
      generalLoaderReducer ?? true
  );

  return (
    <form noValidate>
      <Box
        data-testid={TEST_CONSTANTS.SEND_DOCUMENTS_DIALOG_LEFT_PART}
        sx={documentStyle.sendDocumentsDialog.body.leftPart}
      >
        <SendDocumentPatientDropdown
          errors={isFormSubmittedOnce ? errors : {}}
        />
        <Box
          sx={{
            marginTop: '2rem',
          }}
        >
          <Typography variant='h6' fontWeight={500}>
            Message
          </Typography>
          <Typography variant='body2'>
            This message will accompany the document
          </Typography>
        </Box>
        <TextField
          data-testid={
            TEST_CONSTANTS.SEND_DOCUMENTS_DIALOG_LEFT_PART_MESSAGE_FIELD
          }
          multiline
          rows={6}
          variant='outlined'
          label='Message'
          placeholder='Message'
          disabled={isSendDocumentsLoaderActive}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            dispatch(
              setSendDocumentFormDataAction({
                ...sendDocumentFormData,
                message: event.target.value,
              })
            )
          }
          value={sendDocumentFormData.message}
        />
      </Box>
    </form>
  );
};

export default SendDocumentsDialogLeftPart;
