import { Global } from '@emotion/react';
import { DeleteOutlineRounded } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  IconButton,
  Typography,
} from '@mui/material';
import { useAppSelector } from 'app/hooks';
import { ReactComponent as NoDataImage } from 'assets/images/drTalkPracticesEmptyState.svg';
import moment from 'moment';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TEST_CONSTANTS } from '../../../../constants/testConstants';
import { uppercaseInitials } from '../../../../helpers/getFirstTwoLetters';
import {
  useGetConnectedPracticeQuery,
  useGetPendingConnectionInvitesQuery,
  useGetRegistrationPendingInvitesQuery,
} from '../../../../services/myNetworkService';
import { commonClasses } from '../../../../style/commonClasses';
import { myNetworkStyle } from '../../../../style/dashboardStyles/myNetworkStyle';
import CreateInviteDialog from './CreateInviteDialog';
import DeleteSentInviteDialog from './DeleteSentInviteDialog';

const InvitePracticeButton = () => {
  const [isInvitePracticeDialogOpen, setIsInvitePracticeDialogOpen] =
    useState<boolean>(false);

  return (
    <>
      <Button
        variant='contained'
        color='primary'
        data-testid={TEST_CONSTANTS.MY_NETWORK_INVITE_PRACTICE_BUTTON}
        sx={myNetworkStyle.addButton}
        startIcon={<AddIcon />}
        onMouseDown={() => setIsInvitePracticeDialogOpen(true)}
      >
        Invite Practice
      </Button>
      {isInvitePracticeDialogOpen && (
        <CreateInviteDialog
          isInvitePracticeDialogOpen={isInvitePracticeDialogOpen}
          setIsInvitePracticeDialogOpen={setIsInvitePracticeDialogOpen}
        />
      )}
      <Global
        styles={{
          '.MuiButtonBase-root.MuiButton-root': {
            boxShadow: 'none',
          },
        }}
      />
    </>
  );
};

const PracticeList = () => {
  const navigate = useNavigate();

  const [recordForDeletion, setRecordForDeletion] = useState<Record<
    string,
    string
  > | null>(null);
  const onClickDeleteInvite = (invite: Record<string, string>) => {
    setRecordForDeletion(invite);
  };
  const {
    connectedPracticeList,
    pendingConnectionInvites,
    pendingRegistrationInvites,
  } = useAppSelector((state) => state.myNetworkReducer);
  const onClickViewConnectionArrow = (practice: Record<string, string>) => {
    navigate(practice.id);
  };
  const { isFetching: isConnectedPracticeListFetching } =
    useGetConnectedPracticeQuery();
  const { isFetching: isPendingConnectionInvitesFetching } =
    useGetPendingConnectionInvitesQuery();
  const { isFetching: isPendingRegistrationInvitesFetching } =
    useGetRegistrationPendingInvitesQuery();

  return (
    <>
      <Box sx={myNetworkStyle.practicesContainer}>
        <Box sx={myNetworkStyle.practiceHeaderContainer}>
          <Box sx={myNetworkStyle.headers}>
            <Typography variant='h6' sx={{ fontWeight: 500 }}>
              Practices
            </Typography>
            <Typography variant='body2'>Practices in your network</Typography>
          </Box>
          <InvitePracticeButton />
        </Box>

        <Box
          data-testid={TEST_CONSTANTS.MY_NETWORK_PRACTICE_LIST_CONTAINER}
          sx={myNetworkStyle.listContainer}
        >
          {isConnectedPracticeListFetching ||
          isPendingConnectionInvitesFetching ||
          isPendingRegistrationInvitesFetching ? (
            <Box
              sx={commonClasses.loaderContainer}
              data-testid={TEST_CONSTANTS.MY_NETWORK_PRACTICE_LIST_LOADER}
            >
              <CircularProgress />
            </Box>
          ) : pendingConnectionInvites?.length ||
            pendingRegistrationInvites?.length ||
            connectedPracticeList?.length ? (
            <>
              {pendingConnectionInvites?.length >= 1 &&
                pendingConnectionInvites.map(
                  (invite: Record<string, any>, index: number) => (
                    <Box key={invite?.id} sx={commonClasses.practiceCard.card}>
                      <Box sx={commonClasses.practiceCard.practiceCardDetails}>
                        <Avatar
                          sx={[
                            myNetworkStyle.practiceAvatar,
                            {
                              bgcolor: invite.bgcolor,
                            },
                          ]}
                        >
                          {uppercaseInitials(invite?.toPracticeName)}
                        </Avatar>

                        <Box>
                          <Box sx={commonClasses.cardTitle}>
                            {invite?.invitationMode === 'Organization'
                              ? invite?.toEmail
                              : invite?.toPracticeName}
                          </Box>
                          <Box sx={commonClasses.cardSubtitle}>
                            {invite?.toPracticeType}
                            <Box sx={commonClasses.practiceCard.bulletPoint} />
                            <Box>{invite?.toPracticeAddress}</Box>
                          </Box>
                        </Box>
                      </Box>
                      <Box sx={myNetworkStyle.practiceItemSuffix}>
                        <Box sx={commonClasses.cardTag}>pending</Box>
                        <IconButton
                          data-testid={`${TEST_CONSTANTS.MY_NETWORK_DELETE_ICON_BUTTON}-${index}`}
                          onClick={() => onClickDeleteInvite(invite)}
                        >
                          <DeleteOutlineRounded color='error' />
                        </IconButton>
                      </Box>
                    </Box>
                  )
                )}
              {pendingRegistrationInvites?.length >= 1 &&
                pendingRegistrationInvites.map(
                  (invite: Record<string, any>, index: number) => (
                    <Box key={invite?.id} sx={commonClasses.practiceCard.card}>
                      <Box sx={commonClasses.practiceCard.practiceCardDetails}>
                        <Avatar sx={myNetworkStyle.practicePersonAvatar} />

                        <Box>
                          <Box sx={commonClasses.cardTitle}>
                            {invite?.toEmail}
                          </Box>
                          <Box sx={commonClasses.cardSubtitle}>
                            Invited:
                            {moment(invite?.invitedDate).format(
                              'MM/DD/YYYY - HH:mm A'
                            )}
                          </Box>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '1.5rem',
                        }}
                      >
                        <Box sx={commonClasses.cardTag}>pending</Box>
                        <IconButton onClick={() => onClickDeleteInvite(invite)}>
                          <DeleteOutlineRounded color='error' />
                        </IconButton>
                      </Box>
                    </Box>
                  )
                )}
              {!!connectedPracticeList?.length &&
                connectedPracticeList?.map(
                  (practice: Record<string, any>, index: number) => (
                    <Box
                      key={practice?.id}
                      sx={[
                        commonClasses.practiceCard.card,
                        myNetworkStyle.connectedPracticeList,
                      ]}
                      data-testid={`${TEST_CONSTANTS.PRACTICE_LIST_VIEW_CONNECTION_BUTTON}-${index}`}
                      onClick={() => onClickViewConnectionArrow(practice)}
                    >
                      <Box sx={commonClasses.practiceCard.practiceCardDetails}>
                        <Avatar
                          sx={[
                            myNetworkStyle.practiceAvatar,
                            {
                              bgcolor: practice.bgcolor,
                            },
                          ]}
                        >
                          {uppercaseInitials(practice?.name)}
                        </Avatar>
                        <Box>
                          <Box sx={commonClasses.cardTitle}>
                            {practice?.name}
                          </Box>
                          <Box sx={commonClasses.cardSubtitle}>
                            {practice?.organizationType}
                            <Box sx={myNetworkStyle.addressBulletPoint} />
                            <Box>
                              {practice?.address[0]?.fullAddress ??
                                `${practice.address[0]?.city}, ${practice.address[0]?.state}, ${practice.address[0]?.zip}`}
                            </Box>
                          </Box>
                        </Box>
                      </Box>

                      <ArrowForwardIosIcon
                        sx={myNetworkStyle.myNetworkPracticeItemChevron}
                      />
                    </Box>
                  )
                )}
            </>
          ) : (
            <Box sx={commonClasses.centeredContent}>
              <NoDataImage />
              <Typography
                variant='subtitle2'
                sx={myNetworkStyle.noResultsTitle}
              >
                No connected practices or invites
              </Typography>
              <Typography variant='subtitle2' sx={myNetworkStyle.noResultsText}>
                Use the search bar above to look for
                <br />
                people and practices nearby.
              </Typography>
            </Box>
          )}
        </Box>
      </Box>

      {recordForDeletion && (
        <DeleteSentInviteDialog
          setRecordForDeletion={setRecordForDeletion}
          recordForDeletion={recordForDeletion}
        />
      )}

      <Global
        styles={{
          '.MuiButtonBase-root.MuiButton-root': {
            boxShadow: 'none',
          },
        }}
      />
    </>
  );
};

export default PracticeList;
