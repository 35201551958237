import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import LockOutlined from '@mui/icons-material/LockOutlined';
import { Box, Card, CardContent, IconButton, Tooltip } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import { v4 as uuidv4 } from 'uuid';
import brokenImageLogo from '../../../../assets/images/broken-image.png';
import { extensionsNotForPreview as notForPreviewExtensions } from '../../../../constants/documentsConstants';
import { TEST_CONSTANTS } from '../../../../constants/testConstants';
import { imageSrc } from '../../../../helpers/documents/documentsTableThumbnailImageSrcHelper';
import localStorageHelper from '../../../../helpers/localStorageHelper';
import {
  downloadAttachmentAction,
  downloadDocumentByGuestTokenAction,
} from '../../../../store/actions/dashboardActions/documentActions';
import { commonClasses } from '../../../../style/commonClasses';
import { documentStyle } from '../../../../style/dashboardStyles/documentStyle';
import { TableDetailsAttachment } from '../../../../types/documentsTypes';
import { useParams } from 'react-router-dom';

const DocumentTableThumbnailCarousel = ({
  attachments,
  index,
  onClickShowPreview,
  isShowOverlay = false,
  isDisableTooltip = false,
  customOverlayText,
  shouldSecurePreview = false,
  isDisablePreview = false,
}: {
  attachments: TableDetailsAttachment[];
  index: number;
  onClickShowPreview?: (
    attachments?: TableDetailsAttachment[],
    attachmentIndex?: number
  ) => void;
  isShowOverlay?: boolean;
  isDisableTooltip?: boolean;
  customOverlayText?: string;
  shouldSecurePreview?: boolean;
  isDisablePreview?: boolean;
}) => {
  const extensionsNotForPreview = isDisablePreview
    ? []
    : notForPreviewExtensions;

  const token = localStorageHelper.getAuthToken();
  const { token: guestToken } = useParams();
  const onClickDownloadFile = (attachment: TableDetailsAttachment) => {
    if (token) {
      downloadAttachmentAction(
        attachment.attachmentUrl.substring(
          attachment.attachmentUrl.lastIndexOf('/') + 1
        ),
        attachment.fileName
      );
    } else {
      downloadDocumentByGuestTokenAction(
        guestToken!,
        attachment.attachmentUrl.substring(
          attachment.attachmentUrl.lastIndexOf('/') + 1
        ),
        attachment.fileName
      );
    }
  };

  return (
    <Box sx={documentStyle.documentTableThumbnailCarouselContainer}>
      {isShowOverlay && (
        <Box sx={documentStyle.sendingOverlay}>
          <Box sx={commonClasses.transparentTag}>
            {customOverlayText || 'SENDING...'}
          </Box>
        </Box>
      )}
      <ScrollMenu
        LeftArrow={() => LeftArrow({ attachments })}
        RightArrow={() => RightArrow({ attachments })}
      >
        {!!attachments?.length ? (
          attachments.map(
            (attachment: TableDetailsAttachment, attachmentIndex: number) => {
              const renderThumbnails = () => (
                <Box
                  key={uuidv4()}
                  sx={[
                    documentStyle.documentTableContainer.thumbnailContainer,
                    {
                      '&:hover img': {
                        opacity: extensionsNotForPreview.includes(
                          attachment?.fileName?.split('.')?.pop()!
                        )
                          ? 0.5
                          : 0.8,
                      },
                    },
                  ]}
                >
                  {shouldSecurePreview ? (
                    <Card sx={documentStyle.securedImgCard.card}>
                      <CardContent>
                        <Box sx={documentStyle.securedImgCard.overlay}>
                          <LockOutlined
                            sx={documentStyle.securedImgCard.icon}
                          />
                        </Box>
                      </CardContent>
                    </Card>
                  ) : (
                    <>
                      <Box
                        component='img'
                        data-testid={`${TEST_CONSTANTS.DOCUMENTS_TABLE_ATTACHMENT_THUMBNAIL}-${index}`}
                        alt={attachment.fileName}
                        sx={documentStyle.docTableThumbnail}
                        height={85}
                        src={imageSrc(attachment)}
                        onError={(
                          event: React.SyntheticEvent<HTMLImageElement, Event>
                        ) => {
                          event.currentTarget.onerror = null; // prevents looping
                          event.currentTarget.src = brokenImageLogo;
                          event.currentTarget.style.padding = '.5rem';
                        }}
                        onClick={() =>
                          extensionsNotForPreview.includes(
                            attachment?.fileName
                              ?.split('.')
                              ?.pop()!
                              .toLowerCase()
                          )
                            ? onClickDownloadFile(attachment)
                            : onClickShowPreview &&
                              onClickShowPreview(attachments, attachmentIndex)
                        }
                      />

                      {extensionsNotForPreview.includes(
                        attachment?.fileName?.split('.')?.pop()!
                      ) && (
                        <CloudDownloadIcon
                          sx={
                            documentStyle.documentTableContainer
                              .thumbnailContainer.cloudIcon
                          }
                        />
                      )}
                    </>
                  )}
                </Box>
              );

              return !isDisableTooltip ? (
                <Tooltip
                  key={uuidv4()}
                  sx={{
                    bgcolor: extensionsNotForPreview.includes(
                      attachment?.fileName?.split('.')?.pop()!
                    )
                      ? 'primary.light'
                      : '',
                    display: 'none',
                  }}
                  title={
                    extensionsNotForPreview.includes(
                      attachment?.fileName?.split('.')?.pop()!
                    )
                      ? `Click to download this file - ${attachment.fileName}`
                      : attachment.fileName ?? ''
                  }
                >
                  {renderThumbnails()}
                </Tooltip>
              ) : (
                renderThumbnails()
              );
            }
          )
        ) : (
          <></>
        )}
      </ScrollMenu>
    </Box>
  );
};

function LeftArrow({ attachments }: { attachments: TableDetailsAttachment[] }) {
  const { isFirstItemVisible, scrollPrev, visibleElements, initComplete } =
    useContext(VisibilityContext);

  const [disabled, setDisabled] = useState(
    !initComplete || (initComplete && isFirstItemVisible)
  );
  const [isArrowsRequired, setIsArrowsRequired] = useState<boolean>(false);
  useEffect(() => {
    if (visibleElements.length) {
      setDisabled(isFirstItemVisible);
      setIsArrowsRequired(visibleElements.length < attachments.length);
    }
  }, [isFirstItemVisible, visibleElements, attachments]);

  return isArrowsRequired ? (
    <IconButton
      sx={documentStyle.documentThumbnailSliderArrows}
      disabled={disabled}
      onClick={() => scrollPrev()}
    >
      <ArrowBackIosIcon />
    </IconButton>
  ) : (
    <></>
  );
}

function RightArrow({
  attachments,
}: {
  attachments: TableDetailsAttachment[];
}) {
  const { isLastItemVisible, scrollNext, visibleElements } =
    useContext(VisibilityContext);
  const [isArrowsRequired, setIsArrowsRequired] = useState<boolean>(false);
  const [disabled, setDisabled] = useState(
    !visibleElements.length && isLastItemVisible
  );

  useEffect(() => {
    if (visibleElements.length) {
      setDisabled(isLastItemVisible);
      setIsArrowsRequired(visibleElements.length < attachments.length);
    }
  }, [isLastItemVisible, visibleElements, attachments]);

  return isArrowsRequired ? (
    <IconButton
      sx={documentStyle.documentThumbnailSliderArrows}
      disabled={disabled}
      onClick={() => scrollNext()}
    >
      <ArrowForwardIosIcon />
    </IconButton>
  ) : (
    <></>
  );
}

export default DocumentTableThumbnailCarousel;
