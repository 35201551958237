import { Box, Button, Typography } from '@mui/material';
import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import SessionExpiredDialog from '../../components/SessionExpiredDialog';
import { DOCUMENT_STATUS_DATABASE_VALUES } from '../../constants/documentsConstants';
import {
  leftNavigationMenuConstants,
  LEFT_NAVIGATION_WIDTH_CONSTANTS,
} from '../../constants/leftNavigationMenuConstants';
import { ROUTE_CONSTANTS_VARIABLE } from '../../constants/routeConstants';
import { TEST_CONSTANTS } from '../../constants/testConstants';
import localStorageHelper from '../../helpers/localStorageHelper';
import { navigateToLogin } from '../../helpers/navigateToLoginHelper';
import {
  MarkChannel,
  useMarkChannelMutation,
} from '../../services/chatService';
import {
  getPracticeInvitesCount,
  getUnprocessedFilesCount,
  getUnreadMessagesCount,
  useConnectHubMutation,
} from '../../services/notificationService';
import {
  getEfaxContactHistoryAction,
  getEfaxMessagesAction,
  getUserPracticeDetailsAction,
} from '../../store/actions/dashboardActions/efaxActions';
import { useConnectToUserWorkStatusHubMutation } from '../../services/userWorkStatusService';
import { dashboardStyle } from '../../style/dashboardStyles/dashboardStyle';
import DashboardHeader from './dashboardHeader/DashboardHeader';
import LeftNavigation from './LeftNavigation';
import NotificationHandler from './NotificationHandler';
import { getClaimsViaJwtToken } from 'helpers/getClaimsViaJwtTokenHelper';
import { ClaimsByJwtToken } from 'types/Misc';
import { rolesFromClaimsByJwtToken } from 'constants/userConstants';
import UpgradeToV3 from 'components/UpgradeToV3';
import { ReactComponent as StarsIcon } from '../../../src/assets/images/upgrade_to_v3/stars.svg';

const Dashboard = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const authToken = localStorageHelper.getAuthToken();

  const isLeftNavigationDrawerOpen =
    localStorageHelper.getLeftNavigationDrawerStatus() ? true : false;
  const [isLeftNavigationOpen, setIsLeftNavigationOpen] = useState<boolean>(
    isLeftNavigationDrawerOpen
  );

  //could put these in a constants file
  const FAVICON = '/favicon.ico';
  const FAVICON_DOT = '/favicon_dot.ico';
  const APP_TITLE = process.env.REACT_APP_NAME ?? 'drtalk';

  const isOnboardingCompleted = localStorageHelper.getOnboardingStatus();
  const isAutoGeneratedPassword =
    localStorageHelper.getAutoGeneratedPasswordStatus();
  const isUserPendingApproval = localStorageHelper.getUserIsPendingApproval();
  const isOrganizationPendingApproval =
    localStorageHelper.getOrganizationApprovalStatus();
  const token = localStorageHelper.getAuthToken();

  const dispatch = useAppDispatch();

  const {
    hub: { connected },
    totalCount,
    channelList: {
      updateCountStamp: updateUnreadMessagesCountTimestamp,
      updateStamp: updateChannelStamp,
    },
    invitationList: {
      updateCountStamp: updatePendingInvitationsCountTimestamp,
    },
    documentList: { updateCountStamp: updateUnprocessedDocumentsTimestamp },
  } = useAppSelector((state) => state.notificationReducer ?? {});
  const { userInfo } = useAppSelector((state) => state.userReducer);

  const {
    hub: { connected: userWorkStatusHubConnected },
  } = useAppSelector((state) => state.userWorkStatusReducer ?? {});

  const [markChannel] = useMarkChannelMutation();
  const [connectHub] = useConnectHubMutation();
  const [connectToUserWorkStatusHub] = useConnectToUserWorkStatusHubMutation();

  useEffect(() => {
    if (token) {
      if (isUserPendingApproval || isOrganizationPendingApproval) {
        navigate(ROUTE_CONSTANTS_VARIABLE.ONBOARDING_PENDING_APPROVAL);
      } else {
        if (isOnboardingCompleted) {
          if (isAutoGeneratedPassword) {
            navigate(ROUTE_CONSTANTS_VARIABLE.SET_PASSWORD);
          }
        } else {
          navigate(ROUTE_CONSTANTS_VARIABLE.ONBOARDING);
        }
      }
    } else {
      navigate(navigateToLogin());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isOnboardingCompleted,
    isAutoGeneratedPassword,
    isUserPendingApproval,
    isOrganizationPendingApproval,
    token,
  ]);

  useEffect(() => {
    const lastSegment = location.pathname.slice(
      location.pathname.lastIndexOf('/'),
      location.pathname.length
    );
    if (lastSegment === ROUTE_CONSTANTS_VARIABLE.DASHBOARD) {
      navigate(leftNavigationMenuConstants[0].route);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    //connect to the notification hub
    if (!connected && !!token) connectHub(token);
  }, [connected, connectHub, token]);

  useEffect(() => {
    //connect to the userWorkStatus hub
    if (!userWorkStatusHubConnected && !!token) {
      connectToUserWorkStatusHub(token);
    }
  }, [userWorkStatusHubConnected, connectToUserWorkStatusHub, token]);

  const throttleGetCount = useMemo(
    () =>
      _.throttle(async (timestamp: number) => {
        const dispatchedPromise = dispatch(
          getUnreadMessagesCount.initiate({ timestamp })
        );
        try {
          await dispatchedPromise;
        } catch (e) {}
        dispatchedPromise.unsubscribe();
      }, 1000),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    if (connected && updateUnreadMessagesCountTimestamp)
      throttleGetCount(updateUnreadMessagesCountTimestamp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateUnreadMessagesCountTimestamp, connected]);

  useEffect(() => {
    const getCount = async (timestamp: number) => {
      const dispatchedPromise = dispatch(
        getUnprocessedFilesCount.initiate({ timestamp })
      );
      try {
        await dispatchedPromise;
      } catch (e) {}
      dispatchedPromise.unsubscribe();
    };

    if (connected && updateUnprocessedDocumentsTimestamp)
      getCount(updateUnprocessedDocumentsTimestamp);
  }, [updateUnprocessedDocumentsTimestamp, connected, dispatch]);

  useEffect(() => {
    const getCount = async (timestamp: number) => {
      const dispatchedPromise = dispatch(
        getPracticeInvitesCount.initiate({ timestamp })
      );
      try {
        await dispatchedPromise;
      } catch (e) {}
      dispatchedPromise.unsubscribe();
    };

    if (connected && updatePendingInvitationsCountTimestamp)
      getCount(updatePendingInvitationsCountTimestamp);
  }, [updatePendingInvitationsCountTimestamp, connected, dispatch]);

  const throttleMarkChannelDelivered = useMemo(
    () =>
      _.throttle((mark: MarkChannel, channelId: string) => {
        markChannel({
          mark,
          channelId,
        });
      }, 1000),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  useEffect(() => {
    //send delivered receipt for an updated channel
    if (updateChannelStamp?.id) {
      throttleMarkChannelDelivered(
        MarkChannel.delivered,
        updateChannelStamp.id
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateChannelStamp, markChannel]);

  const setIcon = (icon: string) => {
    var link = document.querySelector("link[rel~='icon']") as HTMLLinkElement;
    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.head.appendChild(link);
    }
    link.href = icon;
  };

  const { userPracticeDetails, receivedEfaxList } = useAppSelector(
    (state) => state.efaxReducer
  );

  const { isGettingEfaxMessages } = useAppSelector(
    ({ generalLoaderReducer }) => generalLoaderReducer ?? true
  );

  const notProcessedFaxes =
    receivedEfaxList?.filter(
      (fax) =>
        fax.documentStatus === DOCUMENT_STATUS_DATABASE_VALUES.NOT_PROCESSED
    ) ?? [];

  const efaxNotificationCount = isGettingEfaxMessages
    ? 0
    : notProcessedFaxes.length;

  useEffect(() => {
    const totalNotificationsCount = efaxNotificationCount + totalCount;

    if (totalNotificationsCount > 0) {
      document.title = `(${totalNotificationsCount}) Notification${
        totalNotificationsCount === 1 ? '' : 's'
      } | ${APP_TITLE}`;
      setIcon(FAVICON_DOT);

      // for getting/ updating PWA notification badges
      // @ts-ignore
      if (navigator.setAppBadge) {
        // @ts-ignore
        navigator.setAppBadge(totalCount);
      }
    } else {
      document.title = APP_TITLE;
      setIcon(FAVICON);

      // for getting/ updating PWA notification badges
      // @ts-ignore
      if (navigator.clearAppBadge) {
        // @ts-ignore
        navigator.clearAppBadge();
      }
    }

    return () => {
      document.title = APP_TITLE;
      setIcon(FAVICON);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalCount, efaxNotificationCount]);

  // will be used to get practice fax no.
  // and used to determine if user will have access to the e-Fax feature
  useEffect(() => {
    if (userInfo?.practiceId) {
      dispatch(getUserPracticeDetailsAction(userInfo.practiceId));
      if (authToken) {
        const claims: ClaimsByJwtToken = getClaimsViaJwtToken(authToken);

        if (claims?.role?.includes(rolesFromClaimsByJwtToken.efax))
          dispatch(getEfaxContactHistoryAction());
      }
    }
  }, [dispatch, userInfo, authToken]);

  useEffect(() => {
    // do not allow page to fetch e-fax data if practice has no e-fax no.
    // fetch here instead of efax page to count no. of unprocessed fax
    // TODO: update once api endpoint for getting unprocessed fax counts is ready
    if (authToken) {
      const claims: ClaimsByJwtToken = getClaimsViaJwtToken(authToken);
      if (
        !!userPracticeDetails.eFaxNumber &&
        claims?.role?.includes(rolesFromClaimsByJwtToken.efax)
      ) {
        dispatch(getEfaxMessagesAction());
      }
    }
  }, [dispatch, userPracticeDetails, authToken]);

  const switchNow = () => {
    document.location.href = `${process.env.REACT_APP_URL_V3}`;
  };

  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(true);

  const upgradeToV3waiting = localStorage.getItem('upgradeToV3') ?? false;

  return (
    <Box
      data-testid={TEST_CONSTANTS.DASHBOARD_CONTAINER}
      sx={dashboardStyle.dashboardContainer}
    >
      <Box
        sx={{
          bgcolor: '#DB2652',
          color: 'white',
          display: 'flex',
          flexDirection: 'row',
          gap: '8px',
          alignItems: 'center',
          justifyContent: 'center',
          height: '41px',
        }}
      >
        <svg
          width='16'
          height='17'
          viewBox='0 0 16 17'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <g clip-path='url(#clip0_12395_12891)'>
            <path
              d='M8 0.5C6.41775 0.5 4.87104 0.969192 3.55544 1.84824C2.23985 2.72729 1.21447 3.97672 0.608967 5.43853C0.00346629 6.90034 -0.15496 8.50887 0.153721 10.0607C0.462403 11.6126 1.22433 13.038 2.34315 14.1569C3.46197 15.2757 4.88743 16.0376 6.43928 16.3463C7.99113 16.655 9.59966 16.4965 11.0615 15.891C12.5233 15.2855 13.7727 14.2602 14.6518 12.9446C15.5308 11.629 16 10.0823 16 8.5C15.9977 6.37897 15.1541 4.34547 13.6543 2.84568C12.1545 1.34589 10.121 0.502294 8 0.5ZM8 15.1667C6.68146 15.1667 5.39253 14.7757 4.2962 14.0431C3.19987 13.3106 2.34539 12.2694 1.84081 11.0512C1.33622 9.83305 1.2042 8.49261 1.46144 7.1994C1.71867 5.90619 2.35361 4.71831 3.28596 3.78596C4.21831 2.85361 5.4062 2.21867 6.6994 1.96143C7.99261 1.7042 9.33305 1.83622 10.5512 2.3408C11.7694 2.84539 12.8106 3.69987 13.5431 4.7962C14.2757 5.89253 14.6667 7.18146 14.6667 8.5C14.6647 10.2675 13.9617 11.9621 12.7119 13.2119C11.4621 14.4617 9.76752 15.1647 8 15.1667Z'
              fill='white'
            />
            <path
              d='M8.00016 3.83301C7.82335 3.83301 7.65378 3.90325 7.52876 4.02827C7.40373 4.15329 7.3335 4.32286 7.3335 4.49967V9.83301C7.3335 10.0098 7.40373 10.1794 7.52876 10.3044C7.65378 10.4294 7.82335 10.4997 8.00016 10.4997C8.17697 10.4997 8.34654 10.4294 8.47157 10.3044C8.59659 10.1794 8.66683 10.0098 8.66683 9.83301V4.49967C8.66683 4.32286 8.59659 4.15329 8.47157 4.02827C8.34654 3.90325 8.17697 3.83301 8.00016 3.83301Z'
              fill='white'
            />
            <path
              d='M8.66683 12.4997C8.66683 12.1315 8.36835 11.833 8.00016 11.833C7.63197 11.833 7.3335 12.1315 7.3335 12.4997C7.3335 12.8679 7.63197 13.1663 8.00016 13.1663C8.36835 13.1663 8.66683 12.8679 8.66683 12.4997Z'
              fill='white'
            />
          </g>
          <defs>
            <clipPath id='clip0_12395_12891'>
              <rect
                width='16'
                height='16'
                fill='white'
                transform='translate(0 0.5)'
              />
            </clipPath>
          </defs>
        </svg>

        <Typography
          variant='body2'
          sx={{ fontSize: '12px', lineHeight: '16.8px' }}
        >
          In 1 day, the desktop app will no longer be accessible. We're
          upgrading drTalk to a new, better web-based platform.&nbsp;
          <a
            style={{
              color: 'white',
              fontWeight: 'bold',
              textDecoration: 'none',
              cursor: 'pointer',
            }}
            onClick={switchNow}
          >
            Try new experience now
          </a>
        </Typography>
      </Box>

      <NotificationHandler />
      <Box
        data-testid={TEST_CONSTANTS.DASHBOARD_CONTAINER_CONTENT}
        sx={dashboardStyle.dashboardContainerContent}
      >
        <LeftNavigation
          open={isLeftNavigationOpen}
          onOpenChanged={setIsLeftNavigationOpen}
        />
        <Box
          sx={[
            dashboardStyle.rightContainer,
            {
              marginLeft: isLeftNavigationOpen
                ? LEFT_NAVIGATION_WIDTH_CONSTANTS.EXPANDED_WIDTH
                : LEFT_NAVIGATION_WIDTH_CONSTANTS.MINIMIZED_WIDTH,
            },
          ]}
        >
          <DashboardHeader />
          <Box sx={dashboardStyle.outletRender}>
            <Outlet />
            <UpgradeToV3
              isDialogOpen={isDialogOpen}
              setIsDialogOpen={setIsDialogOpen}
            />

            {token && <SessionExpiredDialog />}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default Dashboard;
