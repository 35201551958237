import { createApi } from '@reduxjs/toolkit/query/react';
import { DASHBOARD_URLS, PUBLIC_FILE_URLS } from '../constants/urlConstants';
import { transformImgUrlToFile } from 'utilities/transformImgUrlToFile';
import baseQueryWithReauth from './serviceQuery';

/**
 * File Management API
 */
const fileManagerService = createApi({
  reducerPath: 'fileManagerApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (build) => ({
    downloadDocument: build.query<
      ArrayBuffer,
      {
        fileName: string;
        blobType?: string;
      }
    >({
      query: ({ fileName, blobType = 'Private' }) => ({
        url: `${DASHBOARD_URLS.DOCUMENTS_URL.DOWNLOAD_DOCUMENT}/${fileName}/?blobType=${blobType}`,
        method: 'GET',
        responseHandler: async (response) => {
          if (response.ok) {
            return response.arrayBuffer();
          }
        },
      }),
    }),
    guestDownloadDocument: build.query<
      ArrayBuffer,
      {
        fileName: string;
        blobType?: string;
        captchaToken?: string;
      }
    >({
      query: ({ fileName, captchaToken, blobType = 'Private' }) => ({
        url: `${DASHBOARD_URLS.DOCUMENTS_URL.GUEST_WEBSITE_REFERRAL_DOWNLOAD_CUSTOM_FORM}/${fileName}?blobType=${blobType}&captchaToken=${captchaToken}`,

        method: 'GET',
        responseHandler: async (response) => {
          if (response.ok) {
            return response.arrayBuffer();
          }
        },
      }),
    }),
    downloadImageAsBlob: build.query<
      {
        objectURL: string;
      },
      {
        fileName: string;
        blobType?: 'Public' | 'Private';
      }
    >({
      query: ({ fileName, blobType = 'Private' }) => ({
        url: `${DASHBOARD_URLS.DOCUMENTS_URL.DOWNLOAD_DOCUMENT}/${fileName}?blobType=${blobType}`,
        method: 'GET',
        responseHandler: async (response) => {
          return { objectURL: URL.createObjectURL(await response.blob()) };
        },
      }),
    }),
    uploadDocument: build.mutation<
      { url: string },
      { formData: FormData; blobType?: string }
    >({
      query: ({ formData, blobType = 'Private' }) => ({
        url: `${DASHBOARD_URLS.DOCUMENTS_URL.UPLOAD_DOCUMENT}/${blobType}`,
        method: 'POST',
        body: formData,
      }),
    }),
    uploadImage: build.mutation<
      { url: string },
      { dataURL: string; fileName: string; blobType?: 'Public' | 'Private' }
    >({
      query: ({ dataURL, fileName, blobType = 'Private' }) => {
        const transformedFile = transformImgUrlToFile(dataURL, fileName);
        const formData: FormData = new FormData();
        const updatedFile = new File([transformedFile!], fileName, {
          type: transformedFile.type,
        });

        formData.append('file', updatedFile);

        return {
          url: `${DASHBOARD_URLS.DOCUMENTS_URL.UPLOAD_DOCUMENT}/${blobType}`,
          method: 'POST',
          body: formData,
        };
      },
    }),
    guestUploadDocument: build.mutation<
      { url: string },
      { formData: FormData; captchaToken: string }
    >({
      query: ({ formData, captchaToken }) => ({
        url: `${DASHBOARD_URLS.DOCUMENTS_URL.GUEST_WEBSITE_REFERRAL_UPLOAD_CUSTOM_FORM}?captchaToken=${captchaToken}`,
        method: 'POST',
        body: formData,
      }),
    }),
    deleteFile: build.mutation<
      void,
      { fileName: string; blobType?: 'Public' | 'Private' }
    >({
      query: ({ fileName, blobType = 'Private' }) => ({
        url: `${PUBLIC_FILE_URLS.DELETE_PUBLIC_FILE_URL}`,
        method: 'POST',
        body: {
          fileName,
          blobType,
        },
      }),
    }),
  }),
});

export const {
  useDownloadDocumentQuery,
  useGuestDownloadDocumentQuery,
  useDownloadImageAsBlobQuery,
  useUploadDocumentMutation,
  useUploadImageMutation,
  useGuestUploadDocumentMutation,
  useDeleteFileMutation,
} = fileManagerService;

export const { downloadDocument } = fileManagerService.endpoints;

export default fileManagerService;
