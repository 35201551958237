import { Global } from '@emotion/react';
import { MenuOpenRounded } from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';
import { Box, Button, Drawer, Typography, Tooltip } from '@mui/material';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { ReactComponent as DrTalkLogo } from '../../assets/images/drTalkDashboardLogo.svg';
import { ReactComponent as DrTalkTextLogo } from '../../assets/images/drTalkTextLogo.svg';
import NoEfaxNumberDialog from '../../components/NoEfaxNumberDialog';
import Nudge from '../../components/Nudge';
import { DOCUMENT_STATUS_DATABASE_VALUES } from '../../constants/documentsConstants';
import {
  leftNavigationMenuConstants,
  LeftNavigationMenuConstantType,
  LEFT_NAVIGATION_WIDTH_CONSTANTS,
} from '../../constants/leftNavigationMenuConstants';
import { TEST_CONSTANTS } from '../../constants/testConstants';
import localStorageHelper from '../../helpers/localStorageHelper';
import { getDocumentsTableDetailsAction } from '../../store/actions/dashboardActions/documentActions';
import { PAGINATION_REDUX_CONSTANTS } from '../../store/reduxConstants/paginationReduxConstants';
import { leftNavigationStyle } from '../../style/dashboardStyles/leftNavigationStyle';

type LeftNavigationPropsType = {
  open: boolean;
  onOpenChanged: React.Dispatch<React.SetStateAction<boolean>>;
};

const LeftNavigation = (props: LeftNavigationPropsType) => {
  const { open, onOpenChanged } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const token = localStorageHelper.getAuthToken();
  const {
    channelList: { count: unreadMessageCount },
    invitationList: { count: pendingInvitationsCount },
    documentList: {
      count: unprocessedDocumentsCount,
      updateStamp: latestDocumentListTimeStamp,
    },
    rejectedDocumentList: { updateStamp: latestRejectedDocumentListTimeStamp },
    acceptedDocumentList: { updateStamp: latestAcceptedDocumentListTimeStamp },
  } = useAppSelector((state) => state.notificationReducer ?? {});

  const { userPracticeDetails, receivedEfaxList } = useAppSelector(
    (state) => state.efaxReducer
  );

  const { isGettingEfaxMessages } = useAppSelector(
    ({ generalLoaderReducer }) => generalLoaderReducer ?? true
  );

  const [isReferralNudgeVisible, setIsReferralNudgeVisible] =
    useState<boolean>(false);

  const [isNoEfaxDialogOpen, setIsNoEfaxDialogOpen] = useState(false);

  const onTabChange = (menu: LeftNavigationMenuConstantType) => {
    // reset pagination page, when module is changed
    dispatch({
      type: PAGINATION_REDUX_CONSTANTS.RESET_CURRENT_PAGE,
    });
    // will show a modal warning if user's practice do not have e-Fax no.
    if (token) {
      if (menu.name === 'e-Fax') {
        if (!userPracticeDetails?.eFaxNumber) {
          setIsNoEfaxDialogOpen(true);

          return;
        }
      }

      navigate(menu.route);
    }
  };

  useEffect(() => {
    if (open) {
      localStorageHelper.saveLeftNavigationDrawerStatus(true.toString());
    } else {
      localStorageHelper.clearLeftNavigationDrawerStatus();
    }
  }, [open]);

  //refresh the document list when a new document is received - latestDocumentListTimeStamp?.timestamp
  //refresh the document list when a document has been rejected by other side - latestRejectedDocumentListTimeStamp?.timestamp
  //refresh the document list when a document has been accepted by other side - latestAcceptedDocumentListTimeStamp?.timestamp
  useEffect(() => {
    if (token) dispatch(getDocumentsTableDetailsAction());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    latestDocumentListTimeStamp?.timestamp,
    token,
    latestRejectedDocumentListTimeStamp?.timestamp,
    latestAcceptedDocumentListTimeStamp?.timestamp,
  ]);

  useEffect(() => {
    if (token)
      dispatch(getDocumentsTableDetailsAction(setIsReferralNudgeVisible));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return (
    <Box
      data-testid={TEST_CONSTANTS.NAVIGATION_CONTAINER}
      sx={[
        leftNavigationStyle.leftNavigationContainer,
        {
          width: open
            ? LEFT_NAVIGATION_WIDTH_CONSTANTS.EXPANDED_WIDTH
            : LEFT_NAVIGATION_WIDTH_CONSTANTS.MINIMIZED_WIDTH,
        },
      ]}
    >
      <Drawer
        data-testid={TEST_CONSTANTS.LEFT_NAVIGATION_DRAWER_CONTAINER}
        variant='permanent'
        open={open}
        onClose={() => onOpenChanged(false)}
        PaperProps={{
          sx: {
            position: 'relative',
            borderRight: 'none',
            transition: 'all 500ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
          },
        }}
      >
        <Box sx={leftNavigationStyle.leftNavigationDrawer}>
          <Box sx={leftNavigationStyle.drTalkLogoContainer}>
            <Box sx={leftNavigationStyle.drTalkLogo}>
              <DrTalkLogo data-testid={TEST_CONSTANTS.DR_TALK_LOGO} />
            </Box>
            <Box
              sx={[
                leftNavigationStyle.drTalkTextLogo,
                { opacity: open ? 1 : 0 },
              ]}
            >
              <DrTalkTextLogo />
            </Box>
          </Box>

          <Button
            variant='text'
            sx={leftNavigationStyle.expandIcon}
            data-testid={TEST_CONSTANTS.LEFT_NAVIGATION_EXPAND_BUTTON}
            onClick={() => onOpenChanged((e: boolean) => !e)}
          >
            {open ? <MenuOpenRounded /> : <MenuIcon />}
          </Button>
          {leftNavigationMenuConstants.map((menu) => {
            let count;
            switch (menu.name) {
              case 'Chat':
                count = unreadMessageCount;
                break;
              case 'My Network':
                count = pendingInvitationsCount;
                break;
              case 'Documents':
                count = unprocessedDocumentsCount;
                break;
              case 'e-Fax':
                const notProcessedFaxes =
                  receivedEfaxList?.filter(
                    (fax) =>
                      fax.documentStatus ===
                      DOCUMENT_STATUS_DATABASE_VALUES.NOT_PROCESSED
                  ) ?? [];

                count = isGettingEfaxMessages ? 0 : notProcessedFaxes.length;
                break;
              default:
                count = 0;
            }
            return (
              <Tooltip
                key={menu.name}
                title={menu.name}
                placement='right'
                enterDelay={open ? 10_000 : 0}
              >
                <Button
                  data-testid={menu.testConstant}
                  className={
                    location.pathname.split('/')[2] === menu.route
                      ? 'active'
                      : ''
                  }
                  disabled={!(token || menu.name === 'Documents')}
                  sx={[
                    leftNavigationStyle.menu,
                    {
                      width: open ? 1 : 'inherit',
                    },
                    location.pathname.split('/')[2] === menu.route ||
                    (!token && menu.name === 'Documents')
                      ? leftNavigationStyle.activeTab
                      : {},
                  ]}
                  onClick={() => onTabChange(menu)}
                  key={menu.name}
                >
                  <Box sx={leftNavigationStyle.menuIconNameContainer}>
                    <Box sx={leftNavigationStyle.menuIcon}>
                      <menu.icon />

                      {(count ?? 0) > 0 && (
                        <Box
                          sx={[
                            leftNavigationStyle.menuNotificationBadge,
                            {
                              position: 'absolute',
                              height: '1rem',
                              minWidth: '1rem',
                              // width: '1rem',
                              typography: 'overline',
                              opacity: open ? 0 : 1,
                              transition:
                                'all 400ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                            },
                          ]}
                        >
                          {count}
                        </Box>
                      )}
                    </Box>
                    <Box
                      sx={[
                        leftNavigationStyle.menuName,
                        {
                          opacity: open ? 1 : 0,
                        },
                      ]}
                    >
                      {menu.name}
                    </Box>
                  </Box>
                  {(count ?? 0) > 0 && (
                    <Typography
                      component='div'
                      sx={[
                        leftNavigationStyle.menuNotificationBadge,
                        {
                          height: '1.5rem',
                          minHeight: '1.5rem',
                          minWidth: '1.5rem',
                          typography: 'subtitle2',
                          opacity: open ? 1 : 0,
                          transition:
                            'all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                        },
                      ]}
                    >
                      {count}
                    </Typography>
                  )}
                </Button>
              </Tooltip>
            );
          })}
        </Box>
      </Drawer>
      <NoEfaxNumberDialog
        isOpen={isNoEfaxDialogOpen}
        onToggleDialog={() => setIsNoEfaxDialogOpen(!isNoEfaxDialogOpen)}
      />
      <Global
        styles={{
          '.MuiDrawer-paper': {
            width: open ? '312px' : '88px',
            overflowY: 'visible',
            overflowX: 'hidden',
          },
        }}
      />
      {isReferralNudgeVisible && (
        <Nudge
          nudgeContainerClass={{
            ...leftNavigationStyle.referralNudgeContainerClass,
            left: open ? '12rem' : '5rem',
          }}
          nudgeClass={leftNavigationStyle.referralNudgeClass}
          title='Refer a patient to your specialist'
          content={
            <>
              Click on{' '}
              <b>
                <i>‘Refer a patient’</i>
              </b>{' '}
              to start referring
            </>
          }
          primaryButton='Remind me later'
          primaryButtonClick={() => setIsReferralNudgeVisible(false)}
        />
      )}
    </Box>
  );
};

export default LeftNavigation;
