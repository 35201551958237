import {
  Alert,
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography
} from '@mui/material';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
  FIRST_AVAILABLE_DOCTOR_OPTION,
  FIRST_AVAILABLE_OPTION_LABEL,
  REFERRAL_PRACTICE_SELECTION_ERRORS,
  REFERRAL_STEPS
} from 'constants/referralConstants';
import { REFERRAL_PAGE_TEST_CONSTANTS } from 'constants/testConstants';
import _ from 'lodash';
import { SyntheticEvent, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGetConnectedReferralPracticesQuery } from 'services/practiceService';
import {
  goToNextStep,
  setDoctorSearchKeyword,
  setPracticeSearchKeyword,
  setSelectedDoctorId,
  setSelectedPractice
} from 'store/slices/referralSlice';
import { commonClasses } from 'style/commonClasses';
import {
  commonReferralStyle,
  practiceSelectionStepStyle
} from 'style/dashboardStyles/referralStyle';
import { ReferralPractice } from 'types/referralTypes/referralTypes';
import ReferralStepItem from '../ReferralStepItem/ReferralStepItem';
import DoctorListItem from './DoctorListItem';
import PracticeListItem from './PracticeListItem';

export type SelectPracticeFieldErrors = {
  practice?: string;
  doctor?: string;
};

const PracticeSelectionStep = () => {
  const { token: guestToken } = useParams();
  const dispatch = useAppDispatch();
  const {
    selectedPractice,
    selectedDoctorId,
    doctorSearchKeyword,
    practiceSearchKeyword,
    practiceByToken,
  } = useAppSelector((state) => state.referralReducer);

  const selectedDoctor = guestToken
    ? practiceByToken.teamMembers.find(
        (person) => person.id === selectedDoctorId
      )
    : selectedPractice?.MyTeam.find(
        (person) => person.personID === selectedDoctorId
      );

  const { data: connectedPractices, isFetching: isFetchingConnectedPractices } =
    useGetConnectedReferralPracticesQuery(undefined, {
      refetchOnMountOrArgChange: true,
    });

  const [isShowFreeSpecialistErrorMsg, setIsShowFreeSpecialistErrorMsg] =
    useState<boolean>(false);
  const [fieldErrors, setFieldErrors] = useState<SelectPracticeFieldErrors>({
    practice: '',
    doctor: '',
  });

  const displayedPracticeList = useMemo(() => {
    const keyword = practiceSearchKeyword.toLowerCase();
    let practiceList = connectedPractices as ReferralPractice[];

    if (!!keyword) {
      practiceList =
        connectedPractices?.filter((practice) =>
          practice.Name.toLowerCase().includes(keyword)
        ) ?? [];
    }

    // will not display practices without doctors
    return practiceList?.filter((practice) => {
      return practice?.MyTeam?.some((person) => person.role === 'Doctor');
    });
  }, [practiceSearchKeyword, connectedPractices]);

  const displayedDoctorsList: string[] = useMemo(() => {
    let doctorsList: string[] =
      /**
       * NOTE:
       *
       * Requested b.e guys to include this filtering int the api side.
       * Should remove below filtering once backend has completed the changes.
       */
      selectedPractice?.MyTeam?.filter(
        (person) => person.role === 'Doctor'
      ).map((doctor) => doctor.personID) ?? [];

    doctorsList?.unshift(FIRST_AVAILABLE_DOCTOR_OPTION);

    // will remove "First Available" in search results when users searches
    if (!!doctorSearchKeyword) {
      doctorsList = doctorsList?.filter(
        (doctor) => doctor !== FIRST_AVAILABLE_DOCTOR_OPTION
      );
    }

    return doctorsList;
  }, [doctorSearchKeyword, selectedPractice]);

  const disabledStates = {
    practiceField: isFetchingConnectedPractices || !connectedPractices?.length,
    doctorField:
      !connectedPractices?.length ||
      !selectedPractice ||
      !displayedDoctorsList?.length,
    nextButton:
      !selectedPractice || !selectedDoctorId || isShowFreeSpecialistErrorMsg,
  };

  const handleChangePractice = (
    _e: SyntheticEvent,
    value: ReferralPractice
  ) => {
    dispatch(setSelectedPractice(value));

    if (isShowFreeSpecialistErrorMsg) {
      setIsShowFreeSpecialistErrorMsg(false);
    }
  };

  const getDoctorOptionLabel = (option: string) => {
    if (!!option) {
      if (option === FIRST_AVAILABLE_DOCTOR_OPTION) {
        return FIRST_AVAILABLE_OPTION_LABEL;
      } else {
        const selectedDoctor = selectedPractice?.MyTeam.find(
          (person) => person.personID === option
        );
        return `Dr. ${selectedDoctor?.firstName} ${selectedDoctor?.lastName}`;
      }
    } else {
      return '';
    }
  };

  /**
   * Validation checker for fields under Step 1 - practice selection panel
   * Returns a boolean value, 'true' if has error, 'false' if not
   */
  const checkFieldErrors = (): boolean => {
    const { REQUIRED_DOCTOR, REQUIRED_PRACTICE } =
      REFERRAL_PRACTICE_SELECTION_ERRORS;

    let practiceFieldError = '';
    let doctorFieldError = '';

    if (!selectedPractice?.id) {
      practiceFieldError = REQUIRED_PRACTICE;
    }

    if (!selectedDoctorId) {
      doctorFieldError = REQUIRED_DOCTOR;
    }

    const generatedErrors = {
      practice: practiceFieldError,
      doctor: doctorFieldError,
    };

    setFieldErrors(generatedErrors);

    return _.some(generatedErrors, (value) => !_.isEmpty(value));
  };

  /**
   * Checks first if values of input fields are valid.
   * If has field errors,
   * will display error message on invalid fields.
   *
   * If no field errors,
   * Will check if selected practice has custom referral form,
   *
   * If has custom referral form,
   * will proceed to Step 2 - referral document panel.
   *
   * If has no custom referral form,
   * will display error message and disable the 'Next' button
   */
  const handleClickNextBtn = () => {
    const hasErrors = checkFieldErrors();

    if (hasErrors) {
      return;
    } else {
      if (!selectedPractice?.CustomReferralFormUrl) {
        setIsShowFreeSpecialistErrorMsg(true);
      } else {
        dispatch(goToNextStep());
      }
    }
  };

  const isFirstAvailableSelected =
    selectedDoctorId === FIRST_AVAILABLE_DOCTOR_OPTION;

  const doctorName = isFirstAvailableSelected
    ? `${FIRST_AVAILABLE_OPTION_LABEL} Doctor`
    : `Dr. ${selectedDoctor?.firstName} ${selectedDoctor?.lastName}`;

  return (
    <ReferralStepItem
      stepNumber={REFERRAL_STEPS.PRACTICE_DETAILS}
      title={guestToken ? practiceByToken.name : 'Select a practice'}
      titleOnComplete={
        <span>
          Refer to: {selectedPractice?.Name} &#x2022; {doctorName}
        </span>
      }
    >
      {isFetchingConnectedPractices ? (
        <Box
          data-testid={
            REFERRAL_PAGE_TEST_CONSTANTS.REFERRAL_PRACTICE_SELECTION_LOADING
          }
          p='0.5rem'
          display='flex'
          alignItems='center'
          justifyContent='center'
          gap='1em'
        >
          <CircularProgress size='2rem' />
          <Box>Fetching practices, please wait...</Box>
        </Box>
      ) : (
        <Box
          data-testid={
            REFERRAL_PAGE_TEST_CONSTANTS.REFERRAL_PRACTICE_SELECTION_FORM_CONTAINER
          }
          sx={practiceSelectionStepStyle.container}
        >
          <Typography sx={practiceSelectionStepStyle.title}>
            Select a practice
          </Typography>
          <Typography sx={practiceSelectionStepStyle.subtitle}>
            Select a practice from your network
          </Typography>
          {!!connectedPractices?.length ? (
            <>
              {/* Practice Selection Field */}
              <Box sx={practiceSelectionStepStyle.autoCompleteContainer}>
                <Autocomplete
                  data-testid={
                    REFERRAL_PAGE_TEST_CONSTANTS.REFERRAL_PRACTICE_SPECIALISTS_SELECT_BOX
                  }
                  disableClearable
                  fullWidth
                  clearOnBlur
                  sx={practiceSelectionStepStyle.autoComplete}
                  disabled={disabledStates.practiceField}
                  value={selectedPractice as ReferralPractice}
                  onChange={handleChangePractice}
                  options={displayedPracticeList ?? []}
                  filterOptions={(x) => x}
                  getOptionLabel={(option: ReferralPractice) => option?.Name}
                  onBlur={() => dispatch(setPracticeSearchKeyword(''))}
                  renderOption={(props, option) => (
                    <PracticeListItem props={props} option={option} />
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label='Select connected practice'
                      required={!!connectedPractices?.length}
                      sx={fieldErrors?.practice ? commonClasses.fieldError : {}}
                      helperText={fieldErrors?.practice}
                      onChange={(event) =>
                        dispatch(setPracticeSearchKeyword(event.target.value))
                      }
                    />
                  )}
                />
              </Box>
              {/* Doctor Selection Field */}
              <Box sx={practiceSelectionStepStyle.autoCompleteContainer}>
                <Autocomplete
                  data-testid={
                    REFERRAL_PAGE_TEST_CONSTANTS.REFERRAL_PRACTICE_DOCTORS_SELECT_BOX
                  }
                  disableClearable
                  fullWidth
                  clearOnBlur
                  sx={[
                    practiceSelectionStepStyle.autoComplete,
                    { mt: '1.5rem' },
                  ]}
                  disabled={disabledStates.doctorField}
                  value={selectedDoctorId}
                  onChange={(_e, value) => dispatch(setSelectedDoctorId(value))}
                  options={displayedDoctorsList}
                  getOptionLabel={getDoctorOptionLabel}
                  onBlur={() => dispatch(setDoctorSearchKeyword(''))}
                  renderOption={(props, option) => (
                    <DoctorListItem props={props} option={option} />
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      helperText={fieldErrors?.doctor}
                      sx={fieldErrors?.doctor ? commonClasses.fieldError : {}}
                      label='Select a doctor'
                      required={!!displayedDoctorsList.length}
                      onChange={(event) =>
                        dispatch(setDoctorSearchKeyword(event.target.value))
                      }
                    />
                  )}
                />
              </Box>
            </>
          ) : (
            <Typography
              sx={practiceSelectionStepStyle.noPracticesMsg}
              data-testid={
                REFERRAL_PAGE_TEST_CONSTANTS.REFERRAL_PRACTICE_NO_PRACTICE_MSG
              }
            >
              There are no practices available in your network with custom
              referral.
            </Typography>
          )}
          {isShowFreeSpecialistErrorMsg && (
            <Alert
              data-testid={
                REFERRAL_PAGE_TEST_CONSTANTS.REFERRAL_SELECT_PRACTICE_FREE_SPECIALIST_ERROR_MESSAGE
              }
              severity='error'
              sx={practiceSelectionStepStyle.freeSpecialistErrMsg}
            >
              This specialist has not set up their digital referral form.
              Contact your specialist to request a digital referral.
            </Alert>
          )}
          <Button
            data-testid={
              REFERRAL_PAGE_TEST_CONSTANTS.REFERRAL_SELECT_PRACTICE_NEXT_BUTTON
            }
            sx={commonReferralStyle.nextButton}
            disabled={disabledStates.nextButton}
            onClick={handleClickNextBtn}
          >
            Next
          </Button>
        </Box>
      )}
    </ReferralStepItem>
  );
};

export default PracticeSelectionStep;
