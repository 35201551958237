import moment from 'moment';
import { sendDocumentsDialogValidationMessages } from '../../../../../constants/validationMessageConstants';
import {
  SendDocumentErrors,
  SendDocumentFormDataType,
} from '../../../../../types/documentsTypes';

// check if date is valid and in US date format
function isDateValid(dateString: string): boolean {
  const date = moment(dateString, moment.ISO_8601, true);
  return date.isValid() && date.isSameOrAfter('01/01/1900');
}

export const sendDocumentsValidation = (
  formData: SendDocumentFormDataType
): SendDocumentErrors => {
  if (!formData) return {};

  const { recipients, patient, attachments } = formData;
  const {
    PRACTICE_ERROR,
    PATIENT_FIRST_NAME_ERROR,
    PATIENT_LAST_NAME_ERROR,
    DATE_OF_BIRTH_INVALID_ERROR,
    DATE_OF_BIRTH_REQUIRED_ERROR,
    IS_ANY_DOCUMENTS_UPLOADED,
  } = sendDocumentsDialogValidationMessages;
  let newErrors: SendDocumentErrors = {};

  if (recipients.length === 0) {
    newErrors.recipients = PRACTICE_ERROR;
  }

  if (!patient.firstName) {
    newErrors.patientFirstName = PATIENT_FIRST_NAME_ERROR;
  }

  if (!patient.lastName) {
    newErrors.patientLastName = PATIENT_LAST_NAME_ERROR;
  }

  if (!patient.dateOfBirth) {
    newErrors.patientDateOfBirth = DATE_OF_BIRTH_REQUIRED_ERROR;
  } else {
    const currentDate = moment();
    const isFutureDate = moment(patient.dateOfBirth).isAfter(currentDate);

    if (!(!isFutureDate && isDateValid(patient.dateOfBirth))) {
      newErrors.patientDateOfBirth = DATE_OF_BIRTH_INVALID_ERROR;
    }
  }

  if (!attachments.length) {
    newErrors.attachments = IS_ANY_DOCUMENTS_UPLOADED;
  }

  return newErrors;
};
