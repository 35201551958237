export const sendDocumentsDialogsStyle = {
  stepOneDialogContentContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    p: 0,
    mt: '1rem',
    overflowY: 'hidden',
  },
  avatar: { typography: 'subtitle1' },
  selectRecipientsDialogContainer: {
    '.MuiPaper-root.MuiDialog-paper': {
      maxHeight: '50rem',
      height: 0.85,
      maxWidth: 'unset',
      width: '62rem',
      bgcolor: 'background.default',
      overflowY: 'hidden',
    },
  },
  recipientContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    p: '1rem 2.5rem',
    background: '#fff',
  },
  recipientActionMenuBtn: {
    width: '2rem',
    height: '2rem',
  },
  practiceDirectoryContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    flex: 1,
    p: '1rem 2.5rem 1rem 2.5rem',
    bgcolor: 'background.paper',
    overflowY: 'auto',
    title: {
      display: 'flex',
      alignItems: 'center',
      width: 'fit-content',
      gap: 1,
      p: '.5rem 1rem',
      fontSize: 14,
      typography: 'caption',
      color: 'primary.main',
      borderBottom: '.1rem solid',
      borderColor: 'primary.main',

      svg: {
        fontSize: 20,
      },
    },
    actionButtonsContainer: {
      display: 'flex',
      gap: '.5rem',
      button: { minHeight: 'unset', height: '2rem', typography: 'body2' },
    },
    addButton: {
      height: '2rem',
      width: '2rem',
      bgcolor: 'primary.main',
      color: 'background.default',
      svg: { fontSize: 16 },
      '&:hover': {
        backgroundColor: 'primary.dark',
      },
    },
    record: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      gap: '1rem',
      typography: 'body2',
    },
    recordContent: {
      display: 'flex',
      alignItems: 'center',
      gap: '1rem',
    },
    practiceDirectory: {
      display: 'flex',
      flexDirection: 'column',
      gap: '1rem',
      pr: '.5rem',
      overflowY: 'auto',
    },
    loaderContainer: { display: 'flex', justifyContent: 'center', p: '2rem' },
  },
  recipientDirectorySearchContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
  },
  practiceDirectorySearch: {
    flex: 1,
    borderRadius: '2rem',
    '& fieldset': { border: 'none' },

    bgcolor: 'background.default',
  },
  practiceDirectorySearchIcon: {
    mr: '.5rem',
  },
  submitButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
    p: '.5rem 0 1.5rem',
  },
};
