import {
  EMAIL_ADDRESS_REGEX,
  SPECIAL_CHARACTER_REGEX,
  US_ZIP_REGEX,
} from '../constants/regexConstants';
import {
  MAX_PASSWORD_LENGTH,
  MIN_PASSWORD_LENGTH,
} from '../constants/authConstants';

// required field validate
export const requireValidate = (errorMessage: string, value: string | null) => {
  if (
    value?.toString()?.trim()?.length &&
    value !== undefined &&
    value !== null
  ) {
    return { status: true, message: '' };
  }
  return {
    status: false,
    message: errorMessage,
  };
};

// check if field value is empty
export const isEmpty = (val: any): boolean => {
  let isValEmpty = true;
  if (!(val && val?.toString().trim().length)) {
    isValEmpty = false;
  }
  return isValEmpty;
};

// check if email id is valid
export const isValidateEmail = (email: any) => {
  return String(email).toLowerCase().match(EMAIL_ADDRESS_REGEX);
};

// check if emaill id value is not blank and valid
export const isEmailValid = (fieldName: string, val: string) => {
  if (!isEmpty(val)) {
    return {
      status: false,
      message: `Please enter your ${fieldName} address.`,
    };
  } else if (!isValidateEmail(val)) {
    return {
      status: false,
      message: `Please enter a valid ${fieldName} address.`,
    };
  }
  return { status: true, message: '' };
};

// check if zip code is valid
export const isZipCodeValid = (fieldName: string, val: string) => {
  if (!(isEmpty(val) && US_ZIP_REGEX.test(val))) {
    return { status: false, message: `Invalid ${fieldName}` };
  }
  return { status: true, message: '' };
};

export type PasswordCriteria =
  | 'length'
  | 'uppercase'
  | 'lowercase'
  | 'number'
  | 'special';

function hasUppercase(str: string): boolean {
  return str.toLowerCase() !== str;
}

function hasLowercase(str: string): boolean {
  return str.toUpperCase() !== str;
}

export function passwordValidation(
  password: string
): Record<PasswordCriteria, boolean> {
  let length = true;
  let uppercase = true;
  let lowercase = true;
  let number = true;
  let special = true;

  if (
    password.length < MIN_PASSWORD_LENGTH ||
    password.length > MAX_PASSWORD_LENGTH
  )
    length = false;

  if (!hasUppercase(password)) uppercase = false;

  if (!hasLowercase(password)) lowercase = false;

  if (!/\d+/g.test(password)) number = false;

  if (!SPECIAL_CHARACTER_REGEX.test(password)) special = false;

  return { length, lowercase, number, special, uppercase };
}
