import { Box, Button, Tab, Tabs } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { documentTabs } from '../../../constants/documentsConstants';

import { Global } from '@emotion/react';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import MoveToInboxOutlinedIcon from '@mui/icons-material/MoveToInboxOutlined';
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { ROUTE_CONSTANTS_VARIABLE } from '../../../constants/routeConstants';
import { TEST_CONSTANTS } from '../../../constants/testConstants';
import localStorageHelper from '../../../helpers/localStorageHelper';
import {
  addPatientAction,
  onChangeDocumentsTabAction,
  sendDocumentsAction,
  setSendDocumentDialogStepAction,
  setSendDocumentFormDataAction,
} from '../../../store/actions/dashboardActions/documentActions';
import { resetSelectedPageAction } from '../../../store/actions/paginationAction';
import { initialSendDocumentFormData } from '../../../store/reducers/dashboardReducers/documentsReducer';
import { RootState } from '../../../store/store';
import { documentStyle } from '../../../style/dashboardStyles/documentStyle';
import {
  AddPatientRequestData,
  DocumentReducer,
} from '../../../types/documentsTypes';
import DocumentFilters from './documentFilters/DocumentFilters';
import DocumentsTableDetails from './documentTableDetails/DocumentsTableDetails';
import GuestUserDocumentsAskForSignUp from './GuestUserDocumentsAskForSignUp';
import SendDocumentsDialog from './sendDocumentsDialog/SendDocumentsDialog';
import SendDocumentsStatus from './sendDocumentsDialog/SendDocumentsStatus';

const Documents = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { token: guestToken } = useParams();
  const token = localStorageHelper.getAuthToken();
  const [isSendDocumentsDialogOpen, setIsSendDocumentsDialogOpen] =
    useState<boolean>(false);
  const [isSentTabVisible, setIsSentTabVisible] = useState(true);
  const {
    selectedTab,
    sendDocumentFormData,
    sendDocumentStatusList,
    allDocumentList,
    documentList,
  } = useAppSelector(
    (state: RootState) => state.documentReducer as DocumentReducer
  );

  const onSendDocumentBtnClick = (
    setIsFormSubmittedOnce: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    // will save patient if a newly added patient
    const { dateOfBirth, firstName, lastName } = sendDocumentFormData.patient;

    if (!!firstName && !!lastName && !!dateOfBirth) {
      const newPatientData: AddPatientRequestData = {
        firstName,
        lastName,
        dateOfBirth,
      };

      dispatch(sendDocumentsAction(sendDocumentFormData)).then(() => {
        addPatientAction(newPatientData);
        setIsFormSubmittedOnce(false);
        setIsSendDocumentsDialogOpen(false);

        if (selectedTab !== 1) {
          dispatch(onChangeDocumentsTabAction(1));
        }

        dispatch(setSendDocumentFormDataAction(initialSendDocumentFormData));
        dispatch(setSendDocumentDialogStepAction(1));
      });
    }
  };

  useEffect(() => {
    if (guestToken) {
      if (
        (!!!allDocumentList.length ||
          documentList.length === allDocumentList.length) &&
        selectedTab === 0
      ) {
        setIsSentTabVisible(false);
      } else {
        setIsSentTabVisible(true);
      }
    }
  }, [guestToken, selectedTab, allDocumentList, documentList]);

  return (
    <Box
      sx={[documentStyle.mainContainer, { p: token ? '1rem' : '1rem 1rem 0' }]}
      data-testid={TEST_CONSTANTS.DOCUMENTS_CONTAINER}
    >
      <Box sx={documentStyle.headerContainer}>
        <Tabs
          value={selectedTab}
          onChange={(_event: React.SyntheticEvent, newValue: 0 | 1) => {
            dispatch(resetSelectedPageAction());
            dispatch(onChangeDocumentsTabAction(newValue, guestToken));
          }}
        >
          <Tab
            label={
              <Box sx={documentStyle.tabLabel}>
                <MoveToInboxOutlinedIcon />
                {documentTabs.received.name}
              </Box>
            }
            value={documentTabs.received.value}
          />

          {isSentTabVisible && (
            <Tab
              label={
                <Box sx={documentStyle.tabLabel}>
                  <SendRoundedIcon />
                  {documentTabs.sent.name}
                </Box>
              }
              value={documentTabs.sent.value}
            />
          )}
        </Tabs>

        {token && (
          <Box>
            <Button
              variant='contained'
              startIcon={<AccountCircleOutlinedIcon />}
              onClick={() => navigate(ROUTE_CONSTANTS_VARIABLE.REFERRAL)}
            >
              Refer a patient
            </Button>
            <Button
              sx={{ ml: '.5rem' }}
              variant='contained'
              startIcon={<SendRoundedIcon />}
              onClick={() => setIsSendDocumentsDialogOpen(true)}
            >
              Send documents
            </Button>
          </Box>
        )}
      </Box>

      <DocumentFilters />

      <Box
        sx={[
          documentStyle.documentsTabsContainer,
          {
            minHeight: token ? '10rem' : 'unset',
            overflow: token ? 'unset' : 'auto',
          },
        ]}
      >
        <DocumentsTableDetails />
        {!token && <GuestUserDocumentsAskForSignUp />}
      </Box>

      <Global
        styles={{
          '.MuiButtonBase-root.MuiButton-root': {
            boxShadow: 'none',
          },
        }}
      />
      {isSendDocumentsDialogOpen && !!!sendDocumentStatusList.length && (
        <SendDocumentsDialog
          isSendDocumentsDialogOpen={isSendDocumentsDialogOpen}
          setIsSendDocumentsDialogOpen={setIsSendDocumentsDialogOpen}
          onSendDocument={onSendDocumentBtnClick}
        />
      )}
      {!!sendDocumentStatusList.length && (
        <SendDocumentsStatus isDialogOpen={!!sendDocumentStatusList.length} />
      )}
    </Box>
  );
};

export default Documents;
