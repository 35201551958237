import { colorPalette } from '../../theme/colorPalette';

export const chatStyle = {
  chatContainer: {
    display: 'flex',
    flex: 1,
    height: 1,
    flexDirection: 'row',
    gap: '1rem',
    backgroundColor: 'background.paper',
  },
  chatListItemSecondaryText: {
    display: 'flex',
    alignItems: 'center',
    gap: '.5rem',
    typography: 'caption',

    '& > span': {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
  },
  chatListPanel: {
    display: 'flex',
    height: 1,
    flexDirection: 'column',
    gap: '1rem',
    width: '24rem',
  },
  chatNewChatButtonText: {
    fontSize: '0.875rem',
    lineHeight: '1',
    letterSpacing: '0.078rem',
  },
  chatListPanelDefaultText: {
    fontSize: '0.875rem',
    mt: '1rem',
  },
  chatListPanelErrorText: {
    fontSize: '0.875rem',
    color: 'red',
    mt: '1rem',
  },
  chatListPanelLoader: {
    height: '2.5rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  chatListPanelInfiniteLoader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  chatListPanelList: {
    width: '100%',
    marginTop: '0',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
  },
  chatListItem: {
    borderRadius: '0.5rem',
    height: '3.5rem',
    margin: '0.75rem 0',
    padding: '0.5rem',
    paddingRight: '4rem',
  },
  recepientListItem: {
    padding: '0.5rem',
    margin: 0,
  },
  chatListItemCompact: {
    margin: '0',
  },
  chatListItemLarge: {
    height: '4.5rem',
    '& .MuiAvatar-root': {
      height: '3rem',
      width: '3rem',
    },
  },
  chatListItemText: {
    width: '30rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '& mark': { background: 'none', fontWeight: '700' },
  },
  chatListItemTextHighlight: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '& mark': { fontWeight: '700' },
  },
  chatListItemSecondary: {
    right: '0.5rem',
  },
  chatListItemSecondaryContainer: {
    height: '1rem',
    display: 'flex',
    justifyContent: 'right',
  },
  chatListItemSecondaryNotification: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0.125rem 0.3rem',
    borderRadius: '0.5rem',
    fontSize: '0.6875rem',
    color: 'background.default',
    bgcolor: 'error.main',
  },
  chatListItemHasUnread: { fontWeight: 600 },
  chatListItemPending: { opacity: 0.5 },
  chatListItemSelector: {
    width: '1.5rem',
    height: '1.5rem',
  },

  chatAvatar: { backgroundColor: 'primary.main', color: 'primary.light' },

  chatPanel: {
    display: 'flex',
    height: 1,
    flex: 1,
    borderRadius: '0.75rem',
  },
  chatPanelHeader: {
    height: '5rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    p: '1rem',
    backgroundColor: 'white',
    borderRadius: '0.75rem 0.75rem 0 0',
  },
  chatSelectUserBox: {
    height: '5rem',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    p: '1rem 0.5rem',
    backgroundColor: 'white',
    borderRadius: '0.75rem 0.75rem 0 0',
    '& li': { width: '100%' },
  },
  chatSelectUserBoxSearch: {
    '& .MuiButtonBase-root.MuiAutocomplete-clearIndicator': {
      mr: '.5rem',
      width: '1.75rem',
    },
  },
  chatSelectUserBoxSearchDropdown: {
    m: '0 -0.5rem 0 -0.5rem',
    borderRadius: '.75rem',
    padding: '0.75rem',
    boxShadow: '2px 2px 6px rgba(0, 0, 0, 0.25)',

    '& .MuiAutocomplete-listbox': {
      padding: '0',

      '& li': {
        '&.Mui-focused': {
          backgroundColor: colorPalette.primaryLight,
        },
      },
    },
  },
  chatSelectUserBoxTextField: {
    flex: 1,
    height: '3.5rem',
    bgcolor: 'background.default',
    borderRadius: '1.875rem',
    border: 'none',
    input: {
      height: '3.5rem',
      boxSizing: 'border-box',
    },
    svg: {
      width: '3.375rem',
      color: 'text.secondary',
    },
    '& input::placeholder': {
      typography: 'body2',
      color: 'text.secondary',
      opacity: 1,
    },
  },

  chatPanelDefaultImage: {
    display: 'flex',
    height: 1,
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'white',
    borderRadius: '0.75rem',
  },
  chatPanelDefaultNewChatImage: {
    display: 'flex',
    height: 1,
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'white',
    borderRadius: '0 0 0.75rem 0.75rem',
  },
  chatMessageListContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    flexGrow: '1',
    overflow: 'auto',
    backgroundColor: 'white',
    borderRadius: '0 0 0.75rem 0.75rem',
  },
  chatMessageList: {
    display: 'flex',
    flexDirection: 'column-reverse',
    width: '100%',
    overflow: 'auto',
  },
  chatMessageSelectorContainer: {
    display: 'flex',
    flexDirection: 'row',
    flex: '1',
    justifyContent: 'center',
    alignItems: 'center',
  },
  chatMessageSelector: {
    margin: '0 1rem 2rem 2rem',
    width: '1.5rem',
    height: '1.5rem',
  },
  chatMessageContainer: {
    display: 'flex',
    flex: '1',
    padding: '0.5rem 1rem 1rem',
    alignItems: 'flex-start',
    cursor: 'default',
    minHeight: '3rem',
    contentVisibility: 'auto',
  },
  chatMessageContainerUser: {
    alignItems: 'flex-end',
  },
  chatMessageMessage: {
    display: 'flex',
    flexShrink: '1',
    flexDirection: 'column',
    padding: '1rem',
    backgroundColor: '#EBE9F3',
    borderRadius: '0.75rem',
    color: colorPalette.surfaceColor[1000],
  },
  chatMessageMessageUser: {
    backgroundColor: colorPalette.primaryMain,
    color: colorPalette.surfaceColor[10],
  },
  chatMessageAttachment: {
    boxShadow: 'none',
    '& .MuiCardMedia-root': {
      objectFit: 'contain',
      minHeight: '13rem',
      maxHeight: '13rem',
      maxWidth: '40rem',
      cursor: 'pointer',
      width: 'auto',
    },
    '& .MuiCardActions-root': {
      button: { mr: '-0.5rem', color: 'inherit' },
      '& > div': { flexGrow: '1' },
      '& > svg': { marginRight: '0.5rem' },
    },
    image: {
      maxHeight: '13rem',
      maxWidth: '40rem',
      cursor: 'pointer',
    },
    loader: {
      display: 'flex',
      flex: '1',
      justifyContent: 'center',
      p: '4.5rem 1.5rem',
      color: `${colorPalette.surfaceColor[400]}`,
      opacity: 0.5,
    },
  },
  chatMessageMessagePending: {
    opacity: 0.5,
  },
  chatMessageMessageFailed: {
    opacity: 1,
    color: colorPalette.surfaceColor[1000],
    bgcolor: colorPalette.errorLight,
  },
  chatMessageCaptionStyle: {
    padding: '0.5rem 0.5rem 0rem',
    color: `${colorPalette.surfaceColor[500]}`,
  },
  chatMessageBox: {
    display: 'flex',
    alignItems: 'flex-end',
    flexShrink: '0',
    height: '5.5rem',
  },
  chatMessageBoxTextContainer: {
    position: 'relative',
    flex: 1,
    height: '3.5rem',
  },
  chatDropFileHereText: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    typography: 'subtitle1',
    color: 'primary.main',
    zIndex: 1,
  },
  chatMessageBoxText: {
    height: '3.5rem',
    backgroundColor: 'white',
    borderRadius: '3vmin',
    border: 'none',
    pl: '0.5rem',
    color: `${colorPalette.surfaceColor[900]}`,
  },
  chatMessageBoxActions: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    maxWidth: '20rem',
    ml: '1rem',
    button: { ml: '-0.5rem' },
    span: { mr: '0.2rem' },
  },
  chatMessageBoxButton: {
    height: '3.5rem',
    width: '3.5rem',
    minWidth: '3.5rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '1.25rem',
    p: '0',
    ml: '1rem',
  },
  chatMessageBoxPopupMenu: {
    padding: '0.25rem',
    borderRadius: '0.75rem',
    '> ul': { pt: 0 },
    '*': {
      fontFamily: 'Inter',
      fontSize: '1rem',
      fontWeight: '400 !important',
    },
    '& .EmojiPickerReact': { borderStyle: 'none !important' },
  },
  chatForwardingBox: {
    display: 'flex',
    height: '5.5rem',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flexShrink: '0',
    pt: '1.0rem',
    button: {
      flexGrow: '0',
      padding: '0.6rem',
      boxShadow: 'none',
      fontSize: 14,
      fontWeight: 500,
      lineHeight: '1.25rem',
      minWidth: '9rem',
      ml: '1rem',
      '&:disabled': {
        opacity: '0.5',
        color: 'white',
        backgroundColor: 'primary.main',
      },
    },
  },
  chatForwardingBoxText: {
    fontSize: 14,
    fontWeight: 400,
    color: 'primary.main',
  },
  chatForwardSelectUserTextField: {
    display: 'flex',
    flex: 1,
    height: '2.5rem',
    bgcolor: 'background.default',
    borderRadius: '0.5rem',
    border: '1px solid rgba(0, 0, 0, 0.24)',
    // pl: '0.5rem',
    input: {
      height: '2.5rem',
      boxSizing: 'border-box',
      mr: '0.5rem',
    },
    svg: {
      width: '1.5rem',
      color: 'text.secondary',
      mr: '0.5rem',
    },
    '& .MuiInputBase-root': {
      pl: '0.5rem',
    },
    '& input::placeholder': {
      typography: 'body2',
      color: 'text.secondary',
      opacity: 1,
    },
    '& .MuiCircularProgress-root': {
      width: 'auto !important',
      height: 'auto !important',
      svg: { m: 0 },
    },
  },
  chatForwardUserList: {
    width: '100%',
    height: '22rem',
    marginTop: '1.5rem',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
  },

  chatMessageContextMenu: {
    padding: '0.5rem',
    borderRadius: '0.75rem',
  },
  chatMessageContextMenuItem: {
    margin: '0',
    padding: '0.5rem',
    borderRadius: '0.25rem',
  },

  chatDialog: {
    '.MuiPaper-root.MuiDialog-paper': {
      maxWidth: '27.5rem',
      boxShadow: 'none',
      backgroundColor: 'white',
    },
  },
  chatDialogFull: {
    '.MuiBackdrop-root': { backgroundColor: 'rgba(0,0,0,0.8)' },
    '.MuiPaper-root.MuiDialog-paper': {
      boxShadow: 'none',
      backgroundColor: 'transparent',
    },
    '& .MuiToolbar-root': {
      backgroundColor: 'none',
      padding: '0 1rem',
      button: { color: 'white' },
      hr: { border: '1px solid white', opacity: '0.5', margin: '1rem 0' },
    },
    '& .MuiDialogContent-root': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      img: { maxWidth: '90%', maxHeight: '90%' },
    },
    header: {
      display: 'flex',
      flexDirection: 'row',
      backgroundColor: 'black',
      color: 'white',
    },
    Caption: {
      display: 'flex',
      flex: '1',
      justifyContent: 'center',
      pl: '1rem',
    },
  },
  chatDialogHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: 'error.main',
  },
  chatDialogHeaderIcon: {
    flex: 1,
    ml: '2.5rem',
    color: 'primary.dark',
    textAlign: 'center',
  },
  chatDialogTitle: {
    fontWeight: 400,
    textAlign: 'center',
    fontSize: '1.5rem',
    lineHeight: '1.5rem',
    letterSpacing: '0.011rem',
    mt: '.5rem',
  },
  chatDialogSubtitle: {
    fontWeight: 400,
    textAlign: 'center',
    color: colorPalette.surfaceColor[700],
    mt: '1rem',
  },
  chatDialogContent: {},
  chatDialogActions: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    mt: '0.25rem',
    p: '.5rem 2rem 1.5rem',
    button: {
      flexGrow: '1',
      padding: '0.6rem',
      boxShadow: 'none',
      fontSize: 14,
      fontWeight: 500,
      lineHeight: '1.25rem',
    },
    div: { ml: '0 !important' },
  },
  chatMessageListSnackbar: {
    position: 'relative',
    '& .MuiPaper-root': {
      borderRadius: '0.5rem',
    },
  },
  retryButton: {
    display: 'flex',
    alignItems: 'center',
    gap: '.25rem',
    minHeight: '1rem',
    mt: '.25rem',
    typography: 'caption',
    color: 'error.main',
    cursor: 'pointer',
    '.MuiSvgIcon-root': {
      fontSize: '.75rem',
    },
  },
  failedToSendText: {
    display: 'flex',
    alignItems: 'center',
    gap: '.5rem',
    mb: '.25rem',
    color: 'error.main',
    typography: 'caption',
    '.MuiSvgIcon-root': {
      fontSize: '1rem',
    },
  },
  noMoreDrTalkUser: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '4.5rem',
    typography: 'subtitle2',
    fontWeight: 400,
    bgcolor: colorPalette.surfaceColor[200],
    zIndex: 2,
  },
  gifIcon: {
    display: 'flex',
    height: '20px',
    p: '0 .2rem',
    borderRadius: '.25rem',
    bgcolor: 'primary.dark',
    svg: { height: '20px', fontSize: '20px', color: 'background.default' },
  },
  fileNotSupportedDialogContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '1.5rem',
  },
  fileNotSupportedDialogActionButton: {
    width: '6rem',
    minHeight: '1.5rem',
    mt: '1rem',
    p: '.25rem 1rem',
    borderRadius: '.25rem',
  },
};
