import { CloseRounded } from '@mui/icons-material';
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import { SyntheticEvent, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import FlatButton from '../../../components/FlatButton';
import { TEST_CONSTANTS } from '../../../constants/testConstants';
import {
  forwardDocumentsAction,
  getConnectedPracticesAction,
} from '../../../store/actions/dashboardActions/documentActions';
import { commonClasses } from '../../../style/commonClasses';
import { documentStyle } from '../../../style/dashboardStyles/documentStyle';
import { PracticeTeamMember } from '../../../types/documentsTypes';
import { AutocompleteOption } from '../../../types/Misc';

type ForwardDocumentDialogProps = {
  handleActionMenuClose: () => void;
  forwardDocRecordId: string | null;
  setForwardDocRecordId: React.Dispatch<React.SetStateAction<string | null>>;
};

type Form = {
  practice: AutocompleteOption | null;
  user: AutocompleteOption | null;
};

type FormError = {
  practice: string | null;
  user: string | null;
};

const initialForm = { practice: null, user: null };

const ForwardDocumentDialog = (props: ForwardDocumentDialogProps) => {
  const { handleActionMenuClose, forwardDocRecordId, setForwardDocRecordId } =
    props;
  const dispatch = useAppDispatch();
  const { isForwardDocumentsLoaderActive } = useAppSelector(
    ({ generalLoaderReducer }: Record<string, any>) =>
      generalLoaderReducer ?? true
  );
  const { connectedPractices, connectedPracticesOptions } = useAppSelector(
    ({ documentReducer }) => documentReducer ?? {}
  );
  const [form, setForm] = useState<Form>(initialForm);
  const [error, setError] = useState<FormError>(initialForm);
  const [userOptions, setUserOptions] = useState<AutocompleteOption[]>([]);
  const [isFormSubmittedOnce, setIsFormSubmittedOnce] =
    useState<boolean>(false);

  const onChange = (name: string, value: AutocompleteOption | null) => {
    setForm({ ...form, [name]: value });
    if (name === 'practice') {
      if (value === null) {
        setUserOptions([]);
      } else {
        const practice = connectedPractices.filter(
          (practice: any) =>
            practice.id === value.value && practice?.isConnected
        );
        if (practice[0].myTeam?.length)
          setUserOptions(
            practice[0]?.myTeam?.map((member: PracticeTeamMember) => ({
              label: `${member.firstName} ${member.lastName}`,
              value: member.personID,
            }))
          );
      }
    }

    if (isFormSubmittedOnce) {
      setError({
        ...error,
        [name]: value === null ? `Please select ${name}` : null,
      });
    }
  };

  const onSuccessfullyForwardDocument = () => {
    handleActionMenuClose();
    setForwardDocRecordId(null);
  };

  const onClickForward = () => {
    if (!isFormSubmittedOnce) {
      setIsFormSubmittedOnce(true);
    }

    if (form.practice !== null && form.user !== null) {
      dispatch(
        forwardDocumentsAction(
          {
            toUserId: form.user.value,
            toPracticeId: form.practice.value,
            sharedDocumentId: forwardDocRecordId!,
          },
          onSuccessfullyForwardDocument
        )
      );
    } else {
      setError({
        practice: form.practice === null ? 'Please select practice' : null,
        user:
          form.practice !== null && form.user === null
            ? 'Please select user'
            : null,
      });
    }
  };

  useEffect(() => {
    dispatch(getConnectedPracticesAction());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dialog
      data-testid={TEST_CONSTANTS.DOCUMENT_TABLE_FORWARD_DIALOG}
      sx={documentStyle.forwardDocDialog}
      aria-labelledby='forward document'
      aria-describedby='forward document'
      open={!!forwardDocRecordId}
      onClose={() => setForwardDocRecordId(null)}
    >
      <DialogTitle
        display='flex'
        alignItems='center'
        sx={documentStyle.forwardDocDialog.title}
      >
        <Typography component='div' variant='h5' flex={1} fontWeight={400}>
          Forward
        </Typography>
        <IconButton onClick={() => setForwardDocRecordId(null)}>
          <CloseRounded />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <form noValidate>
          <Autocomplete
            fullWidth
            data-testid={
              TEST_CONSTANTS.DOCUMENT_FORWARD_DIALOG_PRACTICE_AUTOCOMPLETE
            }
            onChange={(
              _event: SyntheticEvent,
              value: string | AutocompleteOption | null
            ) => {
              return typeof value !== 'string' && onChange('practice', value);
            }}
            sx={[
              error.practice ? commonClasses.fieldError : {},
              { mt: '1rem' },
            ]}
            options={connectedPracticesOptions}
            value={form.practice}
            renderInput={(params) => (
              <TextField
                {...params}
                label='Practice'
                required
                placeholder='- Select a practice -'
                helperText={error.practice}
              />
            )}
          />

          <Autocomplete
            fullWidth
            data-testid={
              TEST_CONSTANTS.DOCUMENT_FORWARD_DIALOG_USER_AUTOCOMPLETE
            }
            onChange={(
              _event: SyntheticEvent,
              value: AutocompleteOption | null
            ) => typeof value !== 'string' && onChange('user', value)}
            disabled={!form.practice}
            sx={[
              error.user ? commonClasses.fieldError : {},
              documentStyle.forwardDocDialog.userAutocomplete,
            ]}
            value={form.user!}
            options={userOptions}
            renderInput={(params) => (
              <TextField
                {...params}
                label='User'
                required
                placeholder='- Select a user -'
                helperText={error.user}
              />
            )}
          />
        </form>
      </DialogContent>
      <DialogActions sx={documentStyle.docTypeChangeDialog.actions}>
        <Button variant='text' onClick={() => setForwardDocRecordId(null)}>
          Cancel
        </Button>
        <FlatButton
          variant='contained'
          sx={documentStyle.docTypeChangeDialog.actions.changeButton}
          onClick={onClickForward}
          isLoading={isForwardDocumentsLoaderActive}
        >
          Send
        </FlatButton>
      </DialogActions>
    </Dialog>
  );
};

export default ForwardDocumentDialog;
