import { colorPalette } from 'theme/colorPalette';

/**
 * Common styles used for components under <ReferralPage />
 */
export const commonReferralStyle = {
  nextButton: {
    alignSelf: 'center',
    backgroundColor: colorPalette.primary[500],
    color: colorPalette.backgroundDefault,
    width: 116,
    height: 40,
    margin: 'auto',
    display: 'block',
    marginTop: '1.5rem',

    '&:hover': {
      backgroundColor: colorPalette.primaryDark,
    },

    '&:disabled': {
      opacity: '0.5',
      color: 'white',
      backgroundColor: 'primary.main',
    },
  },
  listItem: {
    flex: 1,
    alignItems: 'center',

    '.MuiListItemSecondaryAction-root': {
      position: 'unset',
      transform: 'unset',
    },

    '&:hover': {
      backgroundColor: colorPalette.listItemBackground + ' !important',
      borderRadius: '10px',
    },
  },
  listItemAvatar: {
    height: '2.5rem',
    width: '2.5rem',
    flexShrink: 0,
    color: 'primary.light',
    bgcolor: 'primary.main',
  },
  listItemSecondaryText: {
    fontSize: '.85rem',
    color: colorPalette.surfaceColor[1000],
    lineHeight: '1rem',
    fontStyle: 'inter',
  },
  listItemsDivider: {
    margin: '0.5rem 0',
  },
};

/**
 * Styles used specifically for <ReferralPage /> component
 */
export const referralPageStyle = {
  root: {
    container: {
      backgroundColor: 'background.default',
      borderRadius: '0.5rem',
      padding: '1rem',
      height: '100%',
      overflowY: 'auto',
      width: '100%',
    },
  },
  header: {
    container: {
      marginBottom: '1.5rem',
    },
    title: {
      fontWeight: 400,
      marginBottom: '0.5rem',
    },
  },
  steps: {
    container: {
      borderRadius: '0.5rem',
      overflow: 'hidden',
      border: `1px solid ${colorPalette.surfaceColor[200]}`,
    },
  },
};

/**
 * Styles used specifically for <ReferralStepItem /> component
 */
export const referralStepItemStyle = {
  root: {
    container: {
      '&.Mui-expanded': {
        margin: '0',
      },
    },
  },
  header: {
    stepUnOpened: {
      cursor: 'default !important',
    },
    labelUnopened: {
      color: colorPalette.surfaceColor[600],
    },
    container: {
      height: '3.5rem',
      cursor: 'default !important',
      padding: '0.5rem 1rem',

      '&.Mui-expanded': {
        minHeight: '0',
      },
    },
    editButton: {
      boxShadow: 'none',

      '& > div': {
        fontWeight: 500,
        letterSpacing: '0.078125rem',
        textTransform: 'none',
      },
    },
    stepNumber: {
      container: {
        backgroundColor: colorPalette.primary[400],
        fontSize: '0.6875rem',
        marginRight: '0.5rem',
        width: '1.5rem',
        height: '1.5rem',
        color: colorPalette.backgroundDefault,
      },
      checkIcon: {
        width: '0.6875rem',
        height: '0.6875rem',
      },
    },
    label: {
      fontSize: '0.875rem',
    },
  },
  content: {
    container: {
      backgroundColor: 'background.default',
    },
  },
};

/**
 * Styles used specifically for <NewPatientDetailsField /> component
 */
export const newPatientDetailsFieldStyle = {
  root: {
    container: {
      display: 'flex',
      gap: '.75rem',
      alignItems: 'center',

      '*': { flexGrow: 1 },
    },
  },
  searchButton: {
    flexGrow: 'unset',
  },
  cancelButton: {
    width: '1.25rem',
    height: '1.25rem',
    background: colorPalette.error,
    color: '#fff',

    '&:hover': {
      background: colorPalette.error,
      opacity: 0.75,
    },

    '& > svg': {
      width: '1rem',
      height: '1rem',
    },
  },
};

/**
 * Styles used specifically for <PatientDetailsStep /> component
 */
export const patientDetailsStepStyle = {
  container: {
    padding: '0.5rem',
  },
  title: {
    color: colorPalette.surfaceColor[1000],
    fontWeight: 400,
    typography: 'h5',
  },
  subtitle: {
    color: colorPalette.surfaceColor[1000],
    fontWeight: 400,
    fontSize: '1.0rem',
    marginTop: '0.5rem',
    paddingBottom: '1.5rem',
  },
  divider: {
    marginTop: '2rem',
    marginBottom: '2rem',
  },
  subheading: {
    fontWeight: 'bold',
    textAlign: 'left',
  },
  dragAndDropBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: colorPalette.surfaceColor[200],
    mt: '2rem',
  },
  dragAndDropContainer: {
    height: '12.5rem',
    width: '16.313rem',
    alignSelf: 'center',
    alignContent: 'center',
    justifyContent: 'center',
  },
  commentTextBox: {
    mt: '3rem',
    display: 'flex',
  },
  commentTextBoxlimitationMsg: {
    color: colorPalette.textSecondary,
    paddingTop: '4px',
    paddingLeft: '4px',
    textAlign: 'left',
  },
  sendCopyToPatientTextBox: {
    mt: '3rem',
    width: '60%',
    display: 'flex',
  },
  actionBtnContainer: {
    textAlign: 'center',
    marginTop: '4.5rem',
  },
  cancelButton: {
    alignSelf: 'center',
    border: 1,
    borderColor: colorPalette.primary[500],
    width: '6.5rem',
    height: '1.5rem',
    elevation: 0,
    shadow: 'none',
  },
  sendReferralButton: {
    alignSelf: 'center',
    backgroundColor: colorPalette.primary[500],
    color: colorPalette.backgroundDefault,
    width: '9.5rem',
    height: '1.5rem',
    marginLeft: '1rem',

    '&:hover': {
      backgroundColor: colorPalette.primaryDark,
    },
  },
};

/**
 * Styles used specifically for <PatientListItem /> component
 */
export const patientListItemStyle = {
  patientItemLabel: {
    fontSize: '0.875rem',
    fontWeight: '400',
    font: 'Inter',
  },
};

/**
 * Styles used specifically for <PatientSelectBox /> component
 */
export const patientSelectBoxStyle = {
  patientDetailsSelectBox: {
    width: '458px',
    display: 'flex',
  },
  addNewPatientDropdownOption: {
    color: colorPalette.primaryMain,
    fontWeight: 700,
    fontSize: '0.75rem',
    marginTop: '0.5rem',
    alignSelf: 'center',
    textAlign: 'center',
  },
};

/**
 * Styles used specifically for <PracticeSelectionStepStyle /> component
 */
export const practiceSelectionStepStyle = {
  container: {
    flexShrink: '0',
    padding: '1.5rem',
    alignItems: 'center',
    textAlign: 'center',
    alignContent: 'center',
    justifyContent: 'center',
  },
  title: {
    color: colorPalette.surfaceColor[1000],
    textAlign: 'center',
    fontWeight: 400,
    typography: 'h5',
  },
  subtitle: {
    color: colorPalette.surfaceColor[1000],
    textAlign: 'center',
    fontWeight: 400,
    fontSize: '1rem',
    marginTop: '0.5rem',
    marginBottom: '1.75rem',
    typography: 'body1',
  },
  autoCompleteContainer: {
    alignSelf: 'center',
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
  },
  autoComplete: {
    width: '60%',
    display: 'flex',
  },
  noPracticesMsg: {
    color: colorPalette.error,
    fontWeight: '600',
    margin: '2.75rem 0',
  },
  freeSpecialistErrMsg: {
    width: '60%',
    mt: '1.5rem',
    textAlign: 'left',
    mx: 'auto',
  },
  fieldContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '3rem',
    width: 1,
    p: '3rem 3rem 2rem',
    field: {
      width: '60%',
    },
  },
};

/* guest user website referral */
export const guestReferralPageStyle = {
  mainContainer: {
    display: 'flex',
    gap: '1rem',
    height: '100vh',
    p: '1rem',
    bgcolor: 'background.paper',
  },
  fieldContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '3rem',
    width: 1,
    p: '2rem 3rem 0rem',
    field: {
      width: '60%',
    },
  },
  drTalkLogoContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '.5rem',
  },
  sideBar: {
    width: '16rem',
  },
};
