export const authConstants = {
  authToken: 'authToken',
  authTokenExpiration: 'authTokenExpiration',
  user: 'USER',
  practiceId: 'practiceId',
  practiceInviteCode: 'practiceInviteCode',
  isOnboardingProcessCompleted: 'isOnboardingProcessCompleted',
  isAutoGeneratedPassword: 'isAutoGeneratedPassword', // this bool to check if user has changed the temp password or not
  refreshToken: 'refreshToken',
  refreshTokenExpiration: 'refreshTokenExpiration',
  userStatus: 'userStatus',
  lastInteractionTime: 'lastInteractionTime', // to check if the session is active or not
  isUserPendingApproval: 'isUserPendingApproval', // this bool is to check if user's onboarding request is pending or not(for the existing practice)
  isOrganizationPendingApproval: 'isOrganizationPendingApproval', // to check if new created practice/ organization(while onboarding) has got approval from the super admin or not
  isGuestUserVisitFirstTime: 'isGuestUserVisitFirstTime', // to check if guest user is visiting the page first time
  isReferralNudgeVisible: 'isReferralNudgeVisible', // if registered user should see nudge gor sending referrals
};

export const isLeftNavigationDrawerOpen = 'isLeftNavigationDrawerOpen';

export const MIN_PASSWORD_LENGTH = 8;
export const MAX_PASSWORD_LENGTH = 20;

export const onboardingPersonalInfoFormError = {
  role: 'Please select a role',
  mobile: 'Please enter a valid mobile number',
};
