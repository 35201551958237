import { createApi } from '@reduxjs/toolkit/query/react';
import { NOTIFICATION_URLS } from '../constants/urlConstants';
import { hubActions, startHub, stopHub } from '../hubs/notificationHub';
import { RootState } from '../store/store';
import baseQueryWithReauth from './serviceQuery';

//using RTK query rather than Redux boilerplate to enable caching etc.,
//also handles multiple request collisions out of the box

const notificationService = createApi({
  reducerPath: 'notificationApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (build) => ({
    connectHub: build.mutation({
      //can use queryFn here to bypass baseQuery and the REST API
      queryFn: (token: string | void, { getState, dispatch }) => {
        const t = token ?? (getState() as RootState).authReducer?.token;
        return new Promise((resolve) => {
          startHub(
            NOTIFICATION_URLS.HUB_URL,
            t,
            dispatch,
            getState as () => RootState
          )
            .then((connected) => resolve({ data: { connected } }))
            .finally(() => resolve({ data: { connected: false } }));
        });
      },
    }),
    userTyping: build.mutation({
      //can use queryFn here to bypass baseQuery and the REST API
      queryFn: (
        { fromUserId, fromUserDisplayName, channelId, toUserIds },
        { getState }
      ) => {
        return new Promise((resolve) => {
          hubActions
            .userTyping(fromUserId, fromUserDisplayName, channelId, toUserIds)
            .finally(() =>
              resolve({ data: { userId: fromUserId, channelId } })
            );
        });
      },
    }),
    getUnreadMessagesCount: build.query<
      { count: number },
      { timestamp: number }
    >({
      query: ({ timestamp }) => ({
        url: NOTIFICATION_URLS.UNREAD_MESSAGES_COUNT_URL,
        params: { timestamp },
      }),
    }),
    getUnprocessedFilesCount: build.query<
      { count: number },
      { timestamp: number }
    >({
      query: ({ timestamp }) => ({
        url: NOTIFICATION_URLS.UNPROCESSED_DOCUMENTS_COUNT_URL,
        params: { timestamp },
      }),
      transformResponse: ({ UnprocessedCount }) => ({
        count: UnprocessedCount,
      }),
    }),
    getPracticeInvitesCount: build.query<
      { count: number },
      { timestamp: number }
    >({
      query: ({ timestamp }) => ({
        url: NOTIFICATION_URLS.PRACTICE_INVITES_COUNT_URL,
        params: { timestamp },
      }),
      transformResponse: ({ PendingCount }) => ({
        count: PendingCount,
      }),
    }),
    disconnectHub: build.mutation({
      //can use queryFn here to bypass baseQuery and the REST API
      queryFn: (arg: void) =>
        new Promise((resolve, reject) => {
          stopHub()
            .then((connected) => resolve({ data: { connected } }))
            .finally(() => resolve({ data: { connected: false } }));
        }),
    }),
    resetService: build.mutation({
      //reset will disconnect the hub and reset the store through the slice
      queryFn: (arg: void) =>
        new Promise((resolve, reject) => {
          stopHub().finally(() => resolve({ data: {} }));
        }),
      //close all system notifications?
      async onQueryStarted(arg, { dispatch }) {
        //clear the cache
        dispatch(notificationService.util.resetApiState());
      },
    }),
  }),
});

export const {
  useConnectHubMutation,
  useDisconnectHubMutation,
  useResetServiceMutation,
  useUserTypingMutation,
} = notificationService;

export const {
  getUnreadMessagesCount,
  getUnprocessedFilesCount,
  getPracticeInvitesCount,
} = notificationService.endpoints;

export default notificationService;
