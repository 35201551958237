import { CloseRounded } from '@mui/icons-material';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Typography,
} from '@mui/material';
import { generalErrorHandler } from 'helpers/generalErrorHandlerHelper';
import moment from 'moment';
import FlatButton from '../../../../components/FlatButton';
import { TEST_CONSTANTS } from '../../../../constants/testConstants';
import { convertDateToDays } from '../../../../helpers/convertDateToDaysHelper';
import {
  useDeleteRegistationInviteMutation,
  useDeleteSentInviteMutation,
} from '../../../../services/myNetworkService';
import { showSnackbarAction } from '../../../../store/actions/snackbarAction';
import { commonClasses } from '../../../../style/commonClasses';
import { myNetworkStyle } from '../../../../style/dashboardStyles/myNetworkStyle';
import { colorPalette } from '../../../../theme/colorPalette';

type DeleteSentInviteDialogPropType = {
  setRecordForDeletion: React.Dispatch<
    React.SetStateAction<Record<string, string> | null>
  >;
  recordForDeletion: Record<string, string>;
};

const DeleteSentInviteDialog = ({
  setRecordForDeletion,
  recordForDeletion,
}: DeleteSentInviteDialogPropType) => {
  const [deleteSentInvite, { isLoading: isDeleteSentInviteLoading }] =
    useDeleteSentInviteMutation();
  const [deleteRegistationInvite, { isLoading: isDeleteLoaderActive }] =
    useDeleteRegistationInviteMutation();
  const onSuccessfullyDeletion = () => {
    setRecordForDeletion(null);
  };

  const onClickDeleteRecord = async () => {
    if (recordForDeletion?.invitationMode === 'Organization') {
      await deleteRegistationInvite(recordForDeletion?.id)
        .unwrap()
        .then((response) => {
          onSuccessfullyDeletion();
          if (response?.statusCode === 200) {
            showSnackbarAction({
              open: true,
              statusCode: 200,
              message: response.data.Message,
              severity: 'success',
            });
          }
        })
        .catch((e) => generalErrorHandler(e));
    } else {
      await deleteSentInvite(recordForDeletion?.id)
        .unwrap()
        .then(() => onSuccessfullyDeletion());
    }
  };

  return (
    <Dialog
      open={!!recordForDeletion}
      data-testid={TEST_CONSTANTS.MY_NETWORK_DELETE_INVITATION_DIALOG}
      onClose={() => setRecordForDeletion(null)}
      aria-labelledby='delete invitation'
      aria-describedby='delete invitation'
    >
      <DialogContent>
        <Box sx={commonClasses.closeIconWithoutHeader}>
          <IconButton
            onClick={() => setRecordForDeletion(null)}
            data-testid={TEST_CONSTANTS.MY_NETWORK_DELETE_DIALOG_CLOSE_BUTTON}
          >
            <CloseRounded />
          </IconButton>
        </Box>

        <Typography color={colorPalette.surfaceColor[800]}>
          <Box sx={myNetworkStyle.recordSelectedForDeletionPracticeName}>
            {recordForDeletion?.invitationMode === 'Organization'
              ? recordForDeletion?.toEmail
              : recordForDeletion?.toPracticeName}
          </Box>
          <Box sx={myNetworkStyle.recordSelectedForDeletionPracticeType}>
            {recordForDeletion?.toPracticeType}
          </Box>
        </Typography>

        <Box sx={myNetworkStyle.recordSelectedForDeletionPracticeAddress}>
          {recordForDeletion.invitationMode === 'Organization' ? (
            `Invited: ${moment(recordForDeletion?.invitedDate).format(
              'MM/DD/YYYY - HH:mm A'
            )}`
          ) : (
            <>
              <PlaceOutlinedIcon />
              {recordForDeletion?.toPracticeAddress}
            </>
          )}
        </Box>

        <Box sx={myNetworkStyle.deleteInviteDurationButtonContainer}>
          <Box>
            Pending request (sent{' '}
            {convertDateToDays(recordForDeletion?.invitedDate)})
          </Box>
          <FlatButton
            data-testid={TEST_CONSTANTS.DELETE_SENT_INVITE_DIALOG_DELETE_BUTTON}
            variant='text'
            color='error'
            onClick={onClickDeleteRecord}
            isLoading={isDeleteLoaderActive || isDeleteSentInviteLoading}
          >
            Delete
          </FlatButton>
        </Box>
      </DialogContent>
      <DialogActions sx={myNetworkStyle.recordDeletionAction}>
        <Button variant='contained' onClick={() => setRecordForDeletion(null)}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteSentInviteDialog;
