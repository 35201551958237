import type { AxiosResponse } from 'axios';

import type { AppDispatch } from '../store';
import ApiService from '../../services/apiServices/apiService';
import { BASE_URL } from '../../constants/urlConstants';
import {
  GetRolesError,
  GetRolesSuccess,
  PracticeApiActions,
} from '../reduxConstants/practiceReduxConstants';
import {
  startGeneralLoaderOnRequest,
  stopGeneralLoaderOnSuccessOrFail,
} from './generalLoaderAction';

export const getPracticeRoleList =
  (practiceId: string) => async (dispatch: AppDispatch) => {
    try {
      startGeneralLoaderOnRequest('getPracticeRoleList');

      const { data }: AxiosResponse<readonly string[]> = await ApiService.get(
        `/practices/v1/${practiceId}/roles`,
        {
          baseURL: BASE_URL,
        }
      );

      if (!Array.isArray(data)) {
        dispatch<GetRolesError>({ type: PracticeApiActions.GET_ROLES_ERROR });
        return;
      }

      dispatch<GetRolesSuccess>({
        type: PracticeApiActions.GET_ROLES_SUCCESS,
        payload: { id: practiceId, roles: data },
      });
    } catch (error: unknown) {
      dispatch<GetRolesError>({ type: PracticeApiActions.GET_ROLES_ERROR });
    } finally {
      stopGeneralLoaderOnSuccessOrFail('getPracticeRoleList');
    }
  };
