import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from '@mui/material';
import { useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import {
  addContactsAction,
  getPracticeDirectoryAction,
  onUpdatePracticeRecipient,
} from '../../../../store/actions/dashboardActions/documentActions';
import { PracticeDirectoryRecipient } from '../../../../types/documentsTypes';
import FlatButton from '../../../../components/FlatButton';
import { documentStyle } from '../../../../style/dashboardStyles/documentStyle';
import { isEmailValid } from '../../../../helpers/validationHelper';
import { addContactsValidation } from './helpers/addContactsValidation';
import { showSnackbarAction } from '../../../../store/actions/snackbarAction';
import AddContactForm from './AddContactForm';

type AddEditRecipientDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  targetRecipient?: PracticeDirectoryRecipient;
};

const AddEditRecipientDialog = ({
  isOpen,
  onClose,
  targetRecipient,
}: AddEditRecipientDialogProps) => {
  const dispatch = useAppDispatch();
  const isEdit = !!targetRecipient?.id;

  const { newRecipientFormData, sortedPracticeDirectory } = useAppSelector(
    (state) => state.documentReducer
  );

  const { userInfo } = useAppSelector((state) => state.userReducer);

  const { isUpdatingPracticeRecipient, isAddContactLoaderActive } =
    useAppSelector(
      ({ generalLoaderReducer }: Record<string, any>) =>
        generalLoaderReducer ?? true
    );

  const [isFormSubmittedOnce, setIsFormSubmittedOnce] =
    useState<boolean>(false);

  const recipientFormData = !!targetRecipient
    ? {
        name: newRecipientFormData.PracticeName,
        email: newRecipientFormData.Email,
      }
    : undefined;

  const isDetailsUpdated =
    targetRecipient?.PracticeName !== newRecipientFormData.PracticeName ||
    targetRecipient?.Email !== newRecipientFormData.Email;

  const formValidationErrors = useMemo(
    () => addContactsValidation(newRecipientFormData, true),
    [newRecipientFormData]
  );

  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        onClose();
        setIsFormSubmittedOnce(false);
      }}
      sx={{
        '.MuiPaper-root.MuiDialog-paper': {
          width: '28rem',
          background: '#fff',
          paddingBottom: '1rem',
        },
      }}
    >
      <DialogContent
        style={{
          paddingTop: '2.5rem',
          paddingBottom: '2rem',
        }}
      >
        <Box>
          <Typography variant='h6' fontWeight={400} marginBottom={'0.25rem'}>
            {isEdit ? 'Edit' : 'Add new'} practice recipient
          </Typography>
          <Typography variant='body2'>
            Please enter recipient details that you want to add
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem',
            paddingTop: '2rem',
          }}
        >
          <AddContactForm
            isFormSubmittedOnce={isFormSubmittedOnce}
            isNewContactAddition={true}
            formData={recipientFormData}
          />
        </Box>
      </DialogContent>
      <DialogActions
        sx={
          documentStyle.sendDocumentsDialog.body.rightPart.actionbuttonContainer
        }
      >
        <FlatButton
          variant='outlined'
          onClick={() => {
            onClose();
            setIsFormSubmittedOnce(false);
          }}
        >
          Cancel
        </FlatButton>
        <FlatButton
          sx={{
            width: '157px !important',
          }}
          variant='contained'
          onClick={() => {
            setIsFormSubmittedOnce(true);

            const isEmailUsedByExistingContact = sortedPracticeDirectory.some(
              (practice) =>
                isDetailsUpdated &&
                'Email' in practice &&
                practice.Email !== targetRecipient?.Email &&
                practice.Email === newRecipientFormData.Email
            );

            if (isEmailUsedByExistingContact) {
              showSnackbarAction({
                open: true,
                statusCode: 400,
                message:
                  'This email is already saved in the directory. Please enter a different email.',
                severity: 'error',
              });
            } else if (userInfo?.email === newRecipientFormData.Email) {
              showSnackbarAction({
                open: true,
                statusCode: 400,
                message: `This email is currently registered as your account's email. Please enter a different email.`,
                severity: 'error',
              });
            } else {
              if (
                !formValidationErrors.Email &&
                !formValidationErrors.PracticeName
              ) {
                if (isEdit) {
                  dispatch(
                    onUpdatePracticeRecipient({
                      id: targetRecipient?.id ?? '',
                      newName: newRecipientFormData.PracticeName,
                      newEmail: newRecipientFormData.Email,
                    })
                  ).then(() => {
                    setIsFormSubmittedOnce(false);
                    onClose();
                  });
                } else {
                  const isEmailSyntaxValid = isEmailValid(
                    'email',
                    newRecipientFormData.Email
                  ).status;
                  const isFormDataValid =
                    !!newRecipientFormData.PracticeName &&
                    !!newRecipientFormData.Email &&
                    isEmailSyntaxValid;

                  if (isFormDataValid) {
                    dispatch(addContactsAction([newRecipientFormData])).then(
                      () => {
                        onClose();
                        dispatch(getPracticeDirectoryAction());
                        setIsFormSubmittedOnce(false);
                      }
                    );
                  }
                }
              }
            }
          }}
          disabled={isEdit && !isDetailsUpdated}
          isLoading={isUpdatingPracticeRecipient || isAddContactLoaderActive}
        >
          {isEdit ? 'Apply change' : 'Add to directory'}
        </FlatButton>
      </DialogActions>
    </Dialog>
  );
};

export default AddEditRecipientDialog;
