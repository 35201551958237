import {
  type ComponentProps,
  type FunctionComponent,
  type ReactElement,
  useMemo,
} from 'react';
import { Box, Typography } from '@mui/material';

import { passwordValidationMessages } from '../constants/validationMessageConstants';
import { passwordValidation } from '../helpers/validationHelper';
import { profileStyle } from '../style/profileStyle';

type NewPasswordCriteriaProps = {
  password: string;
} & ComponentProps<typeof Box>;

export const NewPasswordCriteria: FunctionComponent<
  NewPasswordCriteriaProps
> = ({ password, ...other }) => {
  const { length, lowercase, number, special, uppercase } = useMemo(
    () => passwordValidation(password),
    [password]
  );

  if (length && special && lowercase && uppercase && number) return null;

  function generateMessage(
    message: string,
    isValid: boolean
  ): ReactElement<HTMLLIElement> {
    const className = isValid ? 'new-password-success' : void 0;
    return (
      <li className={className}>
        <Typography variant='caption'>{message}</Typography>
      </li>
    );
  }

  return (
    <Box sx={profileStyle.newPasswordCriteria} {...other}>
      <Typography variant='caption'>
        {passwordValidationMessages.INVALID_PASSWORD}
      </Typography>
      <ul>
        {generateMessage(
          passwordValidationMessages.PASSWORD_CONSTRAINT_LENGTH,
          length
        )}
        {generateMessage(
          passwordValidationMessages.PASSWORD_CONSTRAINT_UPPERCASE,
          uppercase
        )}
        {generateMessage(
          passwordValidationMessages.PASSWORD_CONSTRAINT_LOWERCASE,
          lowercase
        )}
        {generateMessage(
          passwordValidationMessages.PASSWORD_CONSTRAINT_NUMBER,
          number
        )}
        {generateMessage(
          passwordValidationMessages.PASSWORD_CONSTRAINT_SPECIAL,
          special
        )}
      </ul>
    </Box>
  );
};
