import { AUTH_URLS } from '../../constants/urlConstants';
import { SetNewPasswordForm } from '../../types/authTypes';
import ApiService from './apiService';

const AuthApiService = {
  changePassword: (
    email: string,
    currentPassword: string,
    newPassword: string
  ) =>
    ApiService.post(AUTH_URLS.CHANGE_PASSWORD_URL, {
      currentPassword,
      email,
      password: newPassword,
    }),
  loginUser: (data: { email: string; password: string }) =>
    ApiService.post(AUTH_URLS.LOGIN_URL, data),
  logoutUser: () => ApiService.delete(AUTH_URLS.LOGOUT_URL),
  verifyToken: (email: string) =>
    ApiService.post(AUTH_URLS.VERIFY_TOKEN, { email }),
  setNewPassword: (form: SetNewPasswordForm) =>
    ApiService.post(AUTH_URLS.SET_NEW_PASSWORD, form),
  validateEmail: (email: string, captchaToken: string) =>
    ApiService.get(
      `${AUTH_URLS.VALIDATE_EMAIL}?email=${email}&captchaToken=${captchaToken}`
    ),
};

export default AuthApiService;
