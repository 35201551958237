import { ONBOARDING_URLS } from '../../constants/urlConstants';
import { OnboardingPersonalInfoRequestData } from '../../types/onboardingTypes';
import ApiService from './apiService';

const onboardingApiService = {
  onSearchAddress: (data: Record<string, string>) =>
    ApiService.post(ONBOARDING_URLS.SEARCH_ADDRESS_URL, data),
  onboardingFormSubmit: (data: {
    practiceId?: string;
    practiceName?: string;
    fullAddress?: string;
    practiceType?: string;
    city?: string;
    state: string;
    zip?: string;
    doctorName?: string;
    contactName?: string;
    connectToPracticeIds?: string[];
  }) => ApiService.post(ONBOARDING_URLS.ONBOARDING_FORM_SUBMIT, data),
  onboardingPersonalInfo: (data: OnboardingPersonalInfoRequestData) =>
    ApiService.patch(ONBOARDING_URLS.ONBOARDING_PERSONAL_INFO_FORM, data),
  onboardingGetAllStates: () =>
    ApiService.get(ONBOARDING_URLS.ONBOARDING_GET_ALL_STATES),
  onboardingGetOrganizationsBasedOnSelectedState: (state: string) =>
    ApiService.get(
      `${ONBOARDING_URLS.ONBOARDING_GET_ORGANIZATIONS_BASED_ON_SELECTED_STATE}/${state}`
    ),
  onboardingGetSpecialistsBasedOnSelectedState: (params: { state: string }) =>
    ApiService.get(
      ONBOARDING_URLS.ONBOARDING_GET_SPECIALISTS_BASED_ON_SELECTED_STATE,
      {
        params,
      }
    ),
  getPracticeTypes: () => ApiService.get(ONBOARDING_URLS.GET_PRACTICE_TYPES),
};

export default onboardingApiService;
