import {
  PaginationActionTypes,
  PAGINATION_REDUX_CONSTANTS,
} from '../reduxConstants/paginationReduxConstants';

export const initialPaginationReducer = {
  currentPage: 1,
  itemsPerPage: 10,
};

export type PaginationReducer = { currentPage: number; itemsPerPage: number };

export const paginationReducer = (
  state = initialPaginationReducer,
  action: PaginationActionTypes
): PaginationReducer => {
  switch (action.type) {
    case PAGINATION_REDUX_CONSTANTS.UPDATE_CURRENT_PAGE:
      return { ...state, currentPage: action.payload };

    case PAGINATION_REDUX_CONSTANTS.RESET_CURRENT_PAGE:
      return { ...state, currentPage: 1 };

    case PAGINATION_REDUX_CONSTANTS.UPDATE_ITEMS_PER_PAGE:
      return { ...state, itemsPerPage: action.payload };

    default:
      return state;
  }
};
