export const onboardingStyle = {
  addressContainer: {
    position: 'relative',
  },
  addressLookupDropdown: {
    bgcolor: 'background.default',
  },
  enterAddressManuallyLink: {
    display: 'block',
    width: '100%',
    typography: 'subtitle2',
    fontWeight: 600,
    color: 'primary.main',
    textAlign: 'center',
    cursor: 'pointer',
  },
  manualAddressPopUp: {
    boxShadow: 24,

    '& .MuiPaper-root': {
      width: 427,
      borderRadius: '1.5rem',
    },
  },
  manualAddressPopUpTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    p: '2rem 2rem 0 2rem',
  },
  closePopUpIcon: {
    cursor: 'pointer',
  },
  manualAddressPopUpContent: {
    '&.MuiDialogContent-root': {
      p: '2rem',
    },
    '& > *:not(:last-child)': {
      marginBottom: '2rem',
    },
  },
  manualAddressPopUpAction: {
    p: '0 2rem 2rem',
  },
  openStreetMapContributorsLink: {
    position: 'absolute',
    right: 0,
    top: '3.5rem',
    marginTop: '0.375rem',
    typography: 'overline',
    fontWeight: 'medium',
  },
  manualAddressContentContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: 1,
    p: '0 1em 1em',
    textALign: 'center',
  },
  orTextContainer: {
    m: '1.5rem 0',
    fontSize: '0.875rem',
    backgroundColor: 'background.default',
    borderBottom: '1px solid',
    borderColor: 'text.secondary',
    lineHeight: '0.1em',
    textAlign: 'center',

    '& > span': {
      padding: '0.875rem',
      backgroundColor: 'background.default',
    },
  },
  practiceDropdownAddNewOption: {
    p: '.25rem',

    textAlign: 'center',
    color: 'primary.main',
    cursor: 'pointer',
    a: {
      minHeight: 'fit-content',
      p: '.25rem 1rem',
      fontWeight: 600,
      boxShadow: 'none',
    },
  },
  backToHomepageDialog: {
    title: {
      typography: 'h4',
      fontWeight: 400,
    },
    content: {
      '> *:not(:last-child)': {
        mb: '2rem',
      },
    },
    button: {
      mt: '0.5rem',
    },
  },
  personalInfoFormButtonsContainer: {
    display: 'flex',
    gap: '1rem',
    width: 1,
    mt: 'auto',
    button: {
      flex: 1,
      height: '3.5rem',
    },
  },
};
