const nudgeStyle = {
  nudgeContainer: {
    position: 'fixed',
    zIndex: 1201,
  },
  nudge: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    gap: '.5rem',
    p: '1.5rem 2rem',
    width: '26rem',
    minHeight: '10rem',
    background: '#4e35b0',
    color: '#FFFFFF',
    borderRadius: '1rem',

    '&:after': {
      content: '""',
      position: 'absolute',
      width: 0,
      borderStyle: 'solid',
      borderColor: 'transparent #4e35b0',
      zIndex: 1,
    },
  },
  nudgeActionButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '1rem',
    width: 1,
    button: {
      mt: 1,
      minHeight: '2rem',
      minWidth: '7rem',
      typography: 'body2',
    },
  },
};

export default nudgeStyle;
