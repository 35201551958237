import React from 'react';
import { requireValidate } from '../../../helpers/validationHelper';
import { OnboardingFormErrorType } from './OnboardingNewPractice';
import { AddressType } from '../../../store/reduxConstants/onboardingReduxConstants';

export const isOnboardingFormValid = (
  record: {
    practiceName?: string;
    fullAddress?: AddressType | string | null;
    practiceType?: string | null;
  },
  error: OnboardingFormErrorType,
  setError: React.Dispatch<React.SetStateAction<OnboardingFormErrorType>>
) => {
  let updatedErrors: OnboardingFormErrorType = error;
  const { practiceName, fullAddress, practiceType } = record;
  let isFormValid: boolean = true;
  for (const key in record) {
    switch (key) {
      case 'practiceName':
        const practiceNameValidation = requireValidate(
          'Please enter a practice name',
          practiceName!
        );
        if (practiceNameValidation.status) {
          delete updatedErrors[key];
        } else {
          isFormValid = false;
          updatedErrors[key] = practiceNameValidation.message;
        }
        break;

      case 'fullAddress':
        const fullAddressValidation = requireValidate(
          'Please select an address or enter manually',
          typeof fullAddress == 'string'
            ? fullAddress
            : fullAddress?.fullAddress ?? null
        );
        if (fullAddressValidation.status) {
          delete updatedErrors[key];
        } else {
          isFormValid = false;
          updatedErrors[key] = fullAddressValidation.message;
        }
        break;

      case 'practiceType':
        const practiceTypeValidation = requireValidate(
          'Please make a selection',
          practiceType!
        );
        if (practiceTypeValidation.status) {
          delete updatedErrors[key];
        } else {
          isFormValid = false;
          updatedErrors[key] = practiceTypeValidation.message;
        }
        break;
    }
  }

  setError(updatedErrors);
  return isFormValid;
};
