import type { AxiosResponse } from 'axios';
import React from 'react';
import { generalErrorHandler } from '../../helpers/generalErrorHandlerHelper';
import localStorageHelper from '../../helpers/localStorageHelper';
import onboardingApiService from '../../services/apiServices/onboardingApiService';
import { AutocompleteOption } from '../../types/Misc';
import {
  OnboardingOrganizationsResponse,
  OnboardingPersonalInfoForm,
  OnboardingPersonalInfoRequestData,
} from '../../types/onboardingTypes';
import {
  OnboardingFormSubmit,
  OnUpdateSpecialistsList,
  ONBOARDING_REDUX_CONSTANTS,
  OnSearchAddress,
  type OnboardingDoneResponse,
  SpecialistType,
  AddressLookup,
} from '../reduxConstants/onboardingReduxConstants';
import { AppDispatch, store } from '../store';
import {
  startGeneralLoaderOnRequest,
  stopGeneralLoaderOnSuccessOrFail,
} from './generalLoaderAction';
import { setUserInfo } from './userActions';
import { UserInfo } from '../reduxConstants/userReduxConstants';

export const onSearchAddressAction = (params: Record<string, any>) => {
  return async (dispatch: AppDispatch) => {
    try {
      startGeneralLoaderOnRequest(
        'isOnboardingFormAddressLookUpSearchLoaderActive'
      );
      const response = await onboardingApiService.onSearchAddress(params);

      if (response.status === 200) {
        const formattedData = response.data.map((data: AddressLookup) => {
          const { City, FormattedAddress, State, Zip } = data;

          return {
            city: City,
            state: State,
            zip: Zip,
            fullAddress: FormattedAddress,
          };
        });

        dispatch<OnSearchAddress>({
          type: ONBOARDING_REDUX_CONSTANTS.ON_SEARCH_ADDRESS,
          payload: formattedData,
        });
      }
    } catch (e: any) {
      generalErrorHandler(e);
    } finally {
      stopGeneralLoaderOnSuccessOrFail(
        'isOnboardingFormAddressLookUpSearchLoaderActive'
      );
    }
  };
};

export const onEmptySearchAddressValue = () => {
  return (dispatch: AppDispatch) => {
    dispatch<OnSearchAddress>({
      type: ONBOARDING_REDUX_CONSTANTS.ON_SEARCH_ADDRESS,
      payload: [],
    });
  };
};

// for getting the list of the US states, right now, it's not being used any where.
export const getAllStatesAction = async (
  setStates: React.Dispatch<React.SetStateAction<string[]>>
) => {
  try {
    const response = await onboardingApiService.onboardingGetAllStates();

    if (response.status === 200) {
      setStates(response.data);
    }
  } catch (e: any) {
    generalErrorHandler(e);
  }
};

export const getOrganizationsBasedOnSelectedStateAction = async (
  state: string,
  setPracticeList: React.Dispatch<React.SetStateAction<AutocompleteOption[]>>
) => {
  try {
    startGeneralLoaderOnRequest(
      'isGetOrganizationsBasedOnSelectedStateLoaderActive'
    );
    const response =
      await onboardingApiService.onboardingGetOrganizationsBasedOnSelectedState(
        state
      );
    if (response?.status === 200) {
      const practiceList = response.data?.length
        ? response.data?.map((practice: OnboardingOrganizationsResponse) => ({
            label: practice.name,
            value: practice.id,
          }))
        : [];
      setPracticeList(practiceList);
    }
  } catch (e: any) {
    generalErrorHandler(e);
  } finally {
    stopGeneralLoaderOnSuccessOrFail(
      'isGetOrganizationsBasedOnSelectedStateLoaderActive'
    );
  }
};

export const getSpecialistsBasedOnSelectedStateAction = (state: string) => {
  return async (dispatch: AppDispatch) => {
    try {
      startGeneralLoaderOnRequest(
        'isGetSpecialistsBasedOnSelectedStateLoaderActive'
      );

      const response =
        await onboardingApiService.onboardingGetSpecialistsBasedOnSelectedState(
          {
            state: state,
          }
        );

      if (response?.status === 200) {
        const fetchedSpecialists = response.data.data as SpecialistType[];

        dispatch<OnUpdateSpecialistsList>({
          type: ONBOARDING_REDUX_CONSTANTS.ON_UPDATE_SPECIALISTS_LIST,
          payload: fetchedSpecialists?.filter(
            (specialist) => specialist.status === 'Active'
          ),
        });
      }
    } catch (e: any) {
      generalErrorHandler(e);
    } finally {
      stopGeneralLoaderOnSuccessOrFail(
        'isGetSpecialistsBasedOnSelectedStateLoaderActive'
      );
    }
  };
};

export const onboardingFormSubmitAction = (
  data: {
    practiceId?: string;
    practiceName?: string;
    fullAddress?: string;
    practiceType?: string;
    city?: string;
    state: string;
    zip?: string;
    doctorName?: string;
    contactName?: string;
    connectToPracticeIds?: string[];
  },
  isExistingPractice: boolean,
  callback: () => void
) => {
  return async (dispatch: AppDispatch) => {
    try {
      startGeneralLoaderOnRequest('isOnboardingFormSubmissionLoaderActive');
      const response: AxiosResponse<OnboardingDoneResponse> =
        await onboardingApiService.onboardingFormSubmit(data);

      if (response?.status === 200) {
        const { id, firstName, lastName, organizations } = response.data;
        const userInfoReducer = store.getState().userReducer.userInfo;
        const practiceId = organizations.length ? organizations[0].orgID : '';
        if (!isExistingPractice) {
          dispatch<OnboardingFormSubmit>({
            type: ONBOARDING_REDUX_CONSTANTS.ONBOARDING_FORM_SUBMIT,
            payload: {
              id,
              firstName,
              lastName,
              organizations,
            },
          });
          dispatch(
            setUserInfo(id, {
              email: userInfoReducer?.email,
              firstName,
              lastName,
              practiceId,
              practiceName: data.practiceName!,
              practiceType: data.practiceType!,
              profileImage: null,
              role: null,
              mobile: '',
            } as UserInfo)
          );
          // Let's just use whatever the user entered on the onboarding form
          localStorageHelper.updateUserInfo({
            practiceName: data.practiceName,
            practiceType: data.practiceType,
            practiceId,
          });
        }
        callback();
      }
    } catch (e: any) {
      generalErrorHandler(e);
    } finally {
      stopGeneralLoaderOnSuccessOrFail(
        'isOnboardingFormSubmissionLoaderActive'
      );
    }
  };
};

export const submitOnboardingPersonalInfoFormAction = async (
  data: OnboardingPersonalInfoForm,
  callbackFunc: () => void
) => {
  try {
    startGeneralLoaderOnRequest(
      'isOnboardingPersonalInfoFormSubmissionLoaderActive'
    );
    const finalData: OnboardingPersonalInfoRequestData = {
      mobileNumberUpdate: {
        mobileNumber: data.mobile,
      },
      roleUpdate: {
        role: data.role!,
      },
    };
    const { status } = await onboardingApiService.onboardingPersonalInfo(
      finalData
    );
    if (status === 200) {
      callbackFunc();
    }
  } catch (e: any) {
    generalErrorHandler(e);
  } finally {
    stopGeneralLoaderOnSuccessOrFail(
      'isOnboardingPersonalInfoFormSubmissionLoaderActive'
    );
  }
};

export const getPracticeTypesAction = async (
  setPracticeTypes: React.Dispatch<React.SetStateAction<string[]>>
) => {
  try {
    const { status, data } = await onboardingApiService.getPracticeTypes();
    if (status === 200) {
      const preparedData = data.Categories.map((category: any) =>
        category.Types.map((type: any) => type.Name)
      );

      setPracticeTypes(preparedData[0]);
    }
  } catch (e: any) {
    generalErrorHandler(e);
  }
};
