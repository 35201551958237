import {
  AddContactFormData,
  DocumentsTableDetailsType,
  GetRecipientsResponseData,
  Patient,
  PracticeDirectoryPractice,
  PracticeDirectoryRecipient,
  PracticeOptionUI,
  ReceivedDocumentStatusFilter,
  SendDocumentFormDataType,
  SendDocumentRecordStatus,
} from '../../types/documentsTypes';
import type { Action } from '../../types/Redux';

export const DOCUMENT_REDUX_CONSTANTS = {
  GET_DOCUMENT_LIST: 'GET_DOCUMENT_LIST',
  SET_DOCUMENT_TABLE_FILTER_OPTIONS: 'SET_DOCUMENT_TABLE_FILTER_OPTIONS',
  UPDATE_DOCUMENT_LIST: 'UPDATE_DOCUMENT_LIST',
  UPDATE_PAGINATED_LIST: 'UPDATE_PAGINATED_LIST',
  ON_CHANGE_SELECTED_TAB: 'ON_CHANGE_SELECTED_TAB',
  GET_CONNECTED_PRACTICES: 'GET_CONNECTED_PRACTICES',
  GET_PATIENT_LIST: 'GET_PATIENT_LIST',
  DELETE_DOCUMENT_TABLE_ROW: 'DELETE_DOCUMENT_TABLE_ROW',
  ON_CHANGE_OR_APPLY_DOCUMENT_TABLE_FILTER:
    'ON_CHANGE_OR_APPLY_DOCUMENT_TABLE_FILTER',
  ON_RECEIVED_DOCUMENT_STATUS_CHANGE: 'ON_RECEIVED_DOCUMENT_STATUS_CHANGE',
  ON_CHANGE_DOCUMENT_TYPE: 'ON_CHANGE_DOCUMENT_TYPE',
  ON_PROCESS_DOCUMENT_RECORD: 'ON_PROCESS_DOCUMENT_RECORD',
  ON_DOCUMENT_HEADER_SEARCH: 'ON_DOCUMENT_HEADER_SEARCH',
  RESET_SELECTED_TAB_DOCUMENT_LIST: 'RESET_SELECTED_TAB_DOCUMENT_LIST',
  SET_SEND_DOCUMENT_FORMDATA: 'SET_SEND_DOCUMENT_FORMDATA',
  ON_CHANGE_RECIPIENT_DATA: 'ON_CHANGE_RECIPIENT_DATA',
  CLEAR_RECIPIENT_LIST: 'CLEAR_RECIPIENT_LIST',
  DELETE_RECIPIENT_DATA: 'DELETE_RECIPIENT_DATA',
  ON_ACCEPT_PENDING_DOCUMENT: 'ON_ACCEPT_PENDING_DOCUMENT',
  ON_REJECT_PENDING_DOCUMENT: 'ON_REJECT_PENDING_DOCUMENT',
  ON_REMOVE_REJECTED_DOCUMENT: 'ON_REMOVE_REJECTED_DOCUMENT',
  ON_UPDATE_ACCEPTED_DOCUMENT: 'ON_UPDATE_ACCEPTED_DOCUMENT',
  SET_ADDRESS_BOOK_CONTACTS: 'SET_ADDRESS_BOOK_CONTACTS',
  SET_NEW_RECIPIENT_FORM_DATA: 'SET_NEW_RECIPIENT_FORM_DATA',
  // for guest user
  GET_DOCUMENT_LIST_BY_TOKEN: 'GET_DOCUMENT_LIST_BY_TOKEN',
  UPDATE_SEND_DOCUMENT_STATUS_LIST: 'UPDATE_SEND_DOCUMENT_STATUS_LIST',
  RESET_SEND_DOCUMENT_STATUS_LIST: 'RESET_SEND_DOCUMENT_STATUS_LIST',
  GET_PRACTICE_DIRECTORY_DATA: 'GET_PRACTICE_DIRECTORY_DATA',
  SET_SEND_DOCUMENT_DIALOG_STEP: 'SET_SEND_DOCUMENT_DIALOG_STEP',
  ON_ADD_NEW_RECIPIENT_TO_FORM_DATA: 'ON_ADD_NEW_RECIPIENT_TO_FORM_DATA',
  ON_UPDATE_PRACTICE_RECIPIENT: 'ON_UPDATE_PRACTICE_RECIPIENT',
  ON_DELETE_PRACTICE_RECIPIENT: 'ON_DELETE_PRACTICE_RECIPIENT',
  UPDATE_RECORD_IN_DOCUMENT_LIST: 'UPDATE_RECORD_IN_DOCUMENT_LIST',
} as const;

export type GetDocumentListAction = Action<
  typeof DOCUMENT_REDUX_CONSTANTS.GET_DOCUMENT_LIST,
  DocumentsTableDetailsType[]
>;

export type GetConnectedPracticesAction = Action<
  typeof DOCUMENT_REDUX_CONSTANTS.GET_CONNECTED_PRACTICES,
  Record<string, any>[]
>;

export type GetPatientListAction = Action<
  typeof DOCUMENT_REDUX_CONSTANTS.GET_PATIENT_LIST,
  Patient[]
>;

export type UpdateDocumentListAction = Action<
  typeof DOCUMENT_REDUX_CONSTANTS.UPDATE_DOCUMENT_LIST,
  DocumentsTableDetailsType
>;

export type DeleteDocumentTableRecordAction = Action<
  typeof DOCUMENT_REDUX_CONSTANTS.DELETE_DOCUMENT_TABLE_ROW,
  DocumentsTableDetailsType
>;

export type OnChangeDocumentsTabAction = Action<
  typeof DOCUMENT_REDUX_CONSTANTS.ON_CHANGE_SELECTED_TAB,
  { selectedTab: 0 | 1; guestToken: string | undefined }
>;

export type SetDocumentTableFilterOptions = Action<
  typeof DOCUMENT_REDUX_CONSTANTS.SET_DOCUMENT_TABLE_FILTER_OPTIONS
>;

export type OnChangeDocumentTableFilterAction = Action<
  typeof DOCUMENT_REDUX_CONSTANTS.ON_CHANGE_OR_APPLY_DOCUMENT_TABLE_FILTER,
  { name: string; value: string | null }
>;

export type OnReceivedDocumentStatusChangeAction = Action<
  typeof DOCUMENT_REDUX_CONSTANTS.ON_RECEIVED_DOCUMENT_STATUS_CHANGE,
  ReceivedDocumentStatusFilter
>;

export type OnChangeDocumentTypeAction = Action<
  typeof DOCUMENT_REDUX_CONSTANTS.ON_CHANGE_DOCUMENT_TYPE,
  DocumentsTableDetailsType
>;

export type OnDocumentRecordProcessedAction = Action<
  typeof DOCUMENT_REDUX_CONSTANTS.ON_PROCESS_DOCUMENT_RECORD,
  string
>;

export type OnDocumentHeaderSearchAction = Action<
  typeof DOCUMENT_REDUX_CONSTANTS.ON_DOCUMENT_HEADER_SEARCH,
  DocumentsTableDetailsType[]
>;

export type ResetSelectedTabDocumentList = Action<
  typeof DOCUMENT_REDUX_CONSTANTS.RESET_SELECTED_TAB_DOCUMENT_LIST
>;

export type SetSendDocumentFormDataAction = Action<
  typeof DOCUMENT_REDUX_CONSTANTS.SET_SEND_DOCUMENT_FORMDATA,
  SendDocumentFormDataType
>;

export type OnChangeRecipientDataAction = Action<
  typeof DOCUMENT_REDUX_CONSTANTS.ON_CHANGE_RECIPIENT_DATA,
  AddContactFormData
>;

export type GetDocumentsListByTokenAction = Action<
  typeof DOCUMENT_REDUX_CONSTANTS.GET_DOCUMENT_LIST_BY_TOKEN,
  { data: DocumentsTableDetailsType[]; guestToken: string }
>;

export type ClearRecipientsListAction = Action<
  typeof DOCUMENT_REDUX_CONSTANTS.CLEAR_RECIPIENT_LIST
>;

export type DeleteRecipientDataAction = Action<
  typeof DOCUMENT_REDUX_CONSTANTS.DELETE_RECIPIENT_DATA
>;

export type OnAcceptPendingDocumentAction = Action<
  typeof DOCUMENT_REDUX_CONSTANTS.ON_ACCEPT_PENDING_DOCUMENT,
  string
>;

export type OnRejectPendingDocumentAction = Action<
  typeof DOCUMENT_REDUX_CONSTANTS.ON_REJECT_PENDING_DOCUMENT,
  string
>;

export type OnRemoveRejectedDocumentAction = Action<
  typeof DOCUMENT_REDUX_CONSTANTS.ON_REMOVE_REJECTED_DOCUMENT,
  string
>;

export type OnUpdateAcceptedDocumentAction = Action<
  typeof DOCUMENT_REDUX_CONSTANTS.ON_UPDATE_ACCEPTED_DOCUMENT,
  string
>;

export type SendDocumentStatusListAction = Action<
  typeof DOCUMENT_REDUX_CONSTANTS.UPDATE_SEND_DOCUMENT_STATUS_LIST,
  SendDocumentRecordStatus
>;

export type ResetSendDocumentStatusListAction = Action<
  typeof DOCUMENT_REDUX_CONSTANTS.RESET_SEND_DOCUMENT_STATUS_LIST
>;

export type SetAddressBookContactsAction = Action<
  typeof DOCUMENT_REDUX_CONSTANTS.SET_ADDRESS_BOOK_CONTACTS,
  PracticeOptionUI[]
>;

export type SetNewRecipientFormDataAction = Action<
  typeof DOCUMENT_REDUX_CONSTANTS.SET_NEW_RECIPIENT_FORM_DATA,
  AddContactFormData
>;

export type UpdatePaginatedListAction = Action<
  typeof DOCUMENT_REDUX_CONSTANTS.UPDATE_PAGINATED_LIST,
  DocumentsTableDetailsType[]
>;

export type GetPracticeDirectoryDataAction = Action<
  typeof DOCUMENT_REDUX_CONSTANTS.GET_PRACTICE_DIRECTORY_DATA,
  {
    recipients: PracticeDirectoryRecipient[];
    practices: PracticeDirectoryPractice[];
  }
>;

export type SetSendDocumentDialogStepAction = Action<
  typeof DOCUMENT_REDUX_CONSTANTS.SET_SEND_DOCUMENT_DIALOG_STEP,
  1 | 2
>;

export type OnUpdatePracticeRecipient = Action<
  typeof DOCUMENT_REDUX_CONSTANTS.ON_UPDATE_PRACTICE_RECIPIENT,
  {
    id: string;
    newName: string;
    newEmail: string;
  }
>;

export type OnAddNewRecipientToFormData = Action<
  typeof DOCUMENT_REDUX_CONSTANTS.ON_ADD_NEW_RECIPIENT_TO_FORM_DATA,
  GetRecipientsResponseData
>;

export type OnDeletePracticeRecipient = Action<
  typeof DOCUMENT_REDUX_CONSTANTS.ON_DELETE_PRACTICE_RECIPIENT,
  string
>;

export type OnUpdateRecordInDocumentListAction = Action<
  typeof DOCUMENT_REDUX_CONSTANTS.UPDATE_RECORD_IN_DOCUMENT_LIST,
  DocumentsTableDetailsType
>;

export type DocumentActionTypes =
  | GetDocumentListAction
  | SetDocumentTableFilterOptions
  | GetConnectedPracticesAction
  | GetPatientListAction
  | UpdateDocumentListAction
  | UpdatePaginatedListAction
  | DeleteDocumentTableRecordAction
  | OnChangeDocumentsTabAction
  | OnChangeDocumentTableFilterAction
  | OnReceivedDocumentStatusChangeAction
  | OnChangeDocumentTypeAction
  | OnDocumentRecordProcessedAction
  | OnDocumentHeaderSearchAction
  | ResetSelectedTabDocumentList
  | SetSendDocumentFormDataAction
  | OnChangeRecipientDataAction
  | ClearRecipientsListAction
  | DeleteRecipientDataAction
  | GetDocumentsListByTokenAction
  | DeleteRecipientDataAction
  | OnAcceptPendingDocumentAction
  | OnRejectPendingDocumentAction
  | OnRemoveRejectedDocumentAction
  | OnUpdateAcceptedDocumentAction
  | SendDocumentStatusListAction
  | ResetSendDocumentStatusListAction
  | SetAddressBookContactsAction
  | SetNewRecipientFormDataAction
  | GetPracticeDirectoryDataAction
  | SetSendDocumentDialogStepAction
  | OnUpdatePracticeRecipient
  | OnAddNewRecipientToFormData
  | OnDeletePracticeRecipient
  | OnUpdateRecordInDocumentListAction;
