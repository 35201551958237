import type { AxiosResponse } from 'axios';

import { USER_URLS } from '../../constants/urlConstants';
import localStorageHelper from '../../helpers/localStorageHelper';
import ApiService from '../../services/apiServices/apiService';
import type {
  SyncUserInfo,
  UpdatePersonalInfo,
} from '../../types/apiResponses/UserApiResponses';
import { isErrorMessageResponse } from '../../utilities/typeGuards';
import * as userTypes from '../reduxConstants/userReduxConstants';
import { AppDispatch, store } from '../store';
import {
  startGeneralLoaderOnRequest,
  stopGeneralLoaderOnSuccessOrFail,
} from './generalLoaderAction';
import { showSnackbarAction } from './snackbarAction';

// Local actions
export const setUserInfo = (
  id: string,
  userInfo: userTypes.UserInfo
): userTypes.SetUserInfo => ({
  type: userTypes.UserLocalActions.SET_USER_INFO,
  payload: { id, userInfo },
});

export const clearUserInfo = (): userTypes.ClearUserInfo => ({
  type: userTypes.UserLocalActions.CLEAR_USER_INFO,
});

// API actions
export const syncUserById = (id: string) => async (dispatch: AppDispatch) => {
  try {
    const { data }: AxiosResponse<ReadonlyArray<SyncUserInfo>> =
      await ApiService.get(`${USER_URLS.SYNC_USER_BY_ID}/${id}`);

    if (Array.isArray(data) && data.length) {
      const {
        email,
        firstname,
        lastname,
        organizationType,
        practiceID,
        practiceName,
      } = data[0];
      dispatch<userTypes.SyncUserByIdSuccess>({
        type: userTypes.UserApiActions.SYNC_USER_BY_ID_SUCCESS,
        payload: {
          email,
          firstName: firstname,
          lastName: lastname,
          practiceId: practiceID,
          practiceName,
          practiceType: organizationType,
          profileImage: null,
          role: organizationType,
          mobile: '',
        },
      });
    }
  } catch (error: unknown) {
    dispatch<userTypes.SyncUserByIdError>({
      type: userTypes.UserApiActions.SYNC_USER_BY_ID_ERROR,
    });
  }
};

export const updatePersonalInfo =
  (personId: string, updatedUserInfo: userTypes.PersonalInformationForm) =>
  async (dispatch: AppDispatch) => {
    try {
      startGeneralLoaderOnRequest('isUpdatePersonalInfoLoaderActive');

      // The API returns a JSON with `Message` if there is an error
      const { status }: AxiosResponse<UpdatePersonalInfo> =
        await ApiService.patch(
          `${USER_URLS.UPDATE_PERSONAL_INFO}/${personId}`,
          updatedUserInfo
        );

      localStorageHelper.updateUserInfo(updatedUserInfo);

      dispatch<userTypes.UpdatePersonalInfoSuccess>({
        type: userTypes.UserApiActions.UPDATE_PERSONAL_INFO_SUCCESS,
        payload: updatedUserInfo,
      });

      showSnackbarAction({
        message: 'Your information has been updated',
        severity: 'success',
        statusCode: status,
        open: true,
      });
    } catch (error) {
      const isAxiosError = isErrorMessageResponse(error);
      const errorMessage = isAxiosError
        ? error.response.data?.Message
        : 'Error updating personal information';
      const statusCode = isAxiosError ? error.response.status : 500;

      showSnackbarAction({
        message: errorMessage,
        statusCode,
        severity: 'error',
        open: true,
      });
      dispatch<userTypes.UpdatePersonalInfoError>({
        type: userTypes.UserApiActions.UPDATE_PERSONAL_INFO_ERROR,
      });
    } finally {
      stopGeneralLoaderOnSuccessOrFail('isUpdatePersonalInfoLoaderActive');
    }
  };

export const updateNotificationSettings = () => async () => {
  try {
    startGeneralLoaderOnRequest('isUpdatingNotificationSettings');

    const userInfoReducer = store.getState().userReducer.userInfo;
    const prevUserInfo = localStorageHelper.getUserInfo();

    const response = await ApiService.post(
      USER_URLS.UPDATE_NOTIFICATION_SETTINGS,
      {
        isSmsNotificationTurnedOn: userInfoReducer?.isSmsNotificationTurnedOn,
      }
    );

    localStorageHelper.updateUserInfo({
      ...prevUserInfo,
      isSmsNotificationTurnedOn: userInfoReducer?.isSmsNotificationTurnedOn,
    });

    showSnackbarAction({
      message: 'Notification preferences has been updated',
      severity: 'success',
      statusCode: response?.status,
      open: true,
    });
  } catch (error) {
    const isAxiosError = isErrorMessageResponse(error);
    const errorMessage = isAxiosError
      ? error.response.data?.Message
      : 'Error updating notification settings';
    const statusCode = isAxiosError ? error.response.status : 500;

    showSnackbarAction({
      message: errorMessage,
      statusCode,
      severity: 'error',
      open: true,
    });
  } finally {
    stopGeneralLoaderOnSuccessOrFail('isUpdatingNotificationSettings');
  }
};
