import {
  AddContactErrors,
  AddContactFormData,
} from '../../../../../types/documentsTypes';
import { isEmailValid } from '../../../../../helpers/validationHelper';

export const addContactsValidation = (
  formData: AddContactFormData,
  isNewContactAddition: boolean
): AddContactErrors => {
  if (!formData) return {};

  const { PracticeName, Email } = formData;
  let newErrors: AddContactErrors = {};

  if (!!!PracticeName.toString().trim().length) {
    newErrors.PracticeName = 'Please enter business name';
  }

  if (isNewContactAddition) {
    if (!!!Email.toString().trim().length) {
      newErrors.Email = 'Please enter business email';
    } else {
      const isEmailSyntaxValid = isEmailValid('email', Email).status;

      if (!isEmailSyntaxValid) {
        newErrors.Email = 'Please enter a valid business email';
      }
    }
  }

  return newErrors;
};
