import { colorPalette } from '../../theme/colorPalette';

export const myNetworkStyle = {
  myNetworkContainer: {
    display: 'flex',
    gap: '1rem',
    flex: 1,
    height: 1,
    flexDirection: 'row',
  },
  headers: {
    color: colorPalette.surfaceColor[800],
  },
  practicesContainer: {
    flex: 3,
  },
  invitePracticeButton: {
    typography: 'subtitle2',
    fontWeight: 500,
  },
  addButton: {
    minWidth: '10rem',
    typography: 'subtitle2',
    fontWeight: 500,
  },
  myNetworkRightContainer: {
    display: 'flex',
    flexFlow: 'column nowrap',
    gap: '2rem',
    flex: 2,
  },
  practiceHeaderContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '.5rem',
    marginBottom: '.5rem',
    button: {
      minHeight: 'unset',
      height: '2.25rem',
      width: '11rem',
    },
  },
  listContainer: {
    width: 1,
    height: 'calc(100% - 3.15rem)',
    p: '.5rem 1rem',
    bgcolor: 'background.default',
    borderRadius: '.5rem',
    overflowY: 'auto',
  },
  connectedPracticeList: {
    cursor: 'pointer',
    '&:hover': { bgcolor: colorPalette.surfaceColor[100] },
  },
  practiceCard: {
    display: 'flex',
    alignItems: 'center',
    gap: '.5rem',
    width: 1,
    p: '.5rem 0',
    borderBottom: '1px solid',
    borderColor: colorPalette.surfaceColor[200],
    '&:last-of-type': {
      borderBottom: 'none',
    },
  },
  practiceCardDetails: {
    display: 'flex',
    gap: '.5rem',
    alignItems: 'center',
    flex: 1,
    color: 'text.primary',
  },
  practiceListItem: {
    alignItems: 'flex-start',
    gap: '.5rem',
    pl: 0,
    pr: 0,
    '&:not(:last-of-type)': {
      borderBottom: `.0625rem solid ${colorPalette.surfaceColor[200]}`,
    },
    '& > .MuiListItemText-root': { mt: 0 },
    button: {
      minHeight: '2rem',
      minWidth: '5rem',
      typography: 'subtitle2',
      fontWeight: 500,
      lineHeight: '1rem',
      '&:last-of-type': {
        mt: '.25rem',
      },
    },
  },
  practiceCardActionButton: {
    minWidth: 'unset',
    boxShadow: 'none',
  },
  bulletPoint: {
    height: '.25rem',
    width: '.25rem',
    minHeight: '.25rem',
    minWidth: '.25rem',
    marginTop: '.438rem',
    borderRadius: '50%',
    bgcolor: colorPalette.surfaceColor[300],
  },
  deleteInviteDurationButtonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    p: '.5rem',
    mt: '1.25rem',
    bgcolor: 'primary.light',
    typography: 'subtitle2',
    fontWeight: 400,
    color: 'primary.main',
    borderRadius: '.25rem',
    '.MuiButton-root': {
      minHeight: 'unset',
      p: 0,
      typography: 'subtitle2',
      fontWeight: 400,
      boxShadow: 'none',
    },
  },
  recordSelectedForDeletionPracticeName: {
    typography: 'h6',
    fontWeight: 500,
    mt: '1.25rem',
  },
  recordSelectedForDeletionPracticeType: {
    typography: 'subtitle2',
    fontWeight: 500,
  },
  recordSelectedForDeletionPracticeAddress: {
    display: 'flex',
    alignItems: 'center',
    gap: '.5rem',
    mt: '.875rem',
    typography: 'caption',
  },
  recordDeletionAction: {
    justifyContent: 'center',
    pb: '2rem',
  },
  invitePracticeTitle: {
    typography: 'h5',
    fontWeight: 400,
    textAlign: 'center',
  },
  invitePracticeSubtitle: {
    typography: 'subtitle2',
    fontWeight: 400,
    color: colorPalette.surfaceColor[700],
    textAlign: 'center',
    mt: '1rem',
  },
  createInviteOptionalMessage: {
    mt: '3rem',
  },
  createInviteDialogActions: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    p: '.5rem 2rem 2rem',
    svg: {
      transform: 'rotate(-45deg)',
    },
  },
  copyLink: {
    display: 'flex',
    alignItems: 'center',
    gap: '.25rem',
    typography: '1rem',
    fontWeight: 600,
    color: 'primary.main',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  invitePracticeSendButton: {
    minWidth: '10rem',
    minHeight: '2.75rem',
    p: '.5rem 1.5rem',
  },
  practicItemContainer: {
    display: 'flex',
    justiyContent: 'space-between',
  },
  practiceItemButtonContainer: {
    display: 'flex',
    flexFlow: 'column',
  },
  practiceItemSeondaryData: {
    display: 'flex',
    gap: '.5rem',
    color: colorPalette.surfaceColor[800],
  },
  invitationListContainer: { p: '.5rem 1rem', height: 1 },
  lineClampedMessage: {
    mt: '.05rem',
    color: 'text.primary',
    overflow: 'hidden',
  },
  recommendedListContainer: {
    display: 'flex',
    flexFlow: 'column',
    flex: 1,
    overflowY: 'auto',
  },
  myNetworkPracticeItemChevron: {
    mr: '.25rem',
    typography: 'subtitle1',
    fontWeight: 400,
    color: 'primary.main',
  },
  addressBulletPoint: {
    height: '.25rem',
    width: '.25rem',
    minHeight: '.25rem',
    minWidth: '.25rem',
    marginTop: '.438rem',
    borderRadius: '50%',
    bgcolor: colorPalette.surfaceColor[300],
  },
  practicePersonAvatar: {
    flexShrink: 0,
    height: '3rem',
    width: '3rem',
  },
  practiceAvatar: {
    flexShrink: 0,
    height: '3rem',
    width: '3rem',
    fontWeight: 700,
    color: 'primary.main',
  },
  invitePracticeModalHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    mb: '.5rem',
  },
  invitePracticeModalHeaderIcon: {
    flex: 1,
    ml: '2.5rem',
    color: 'primary.dark',
    textAlign: 'center',
  },
  inviteDialog: {
    '.MuiPaper-root.MuiDialog-paper': {
      width: '27.5rem',
    },
  },
  createInviteDialogContent: {
    '&.MuiDialogContent-root': {
      pt: '.5rem',
    },
    input: {
      typography: 'subtitle2',
      fontWeight: 400,
    },
    textarea: {
      typography: 'subtitle2',
      fontWeight: 400,
    },
  },
  recommendedPracticeInfoContainer: {
    display: 'flex',
    gap: '.5rem',
    m: '1.5rem 0',
    p: '.5rem',
    typography: 'subtitle2',
    color: 'primary.main',
    fontWeight: 400,
    bgcolor: 'primary.light',
    borderRadius: '.25rem',
  },
  recommendedPracticeInviteDialogInviteButton: {
    width: '4.68rem',
  },
  practiceItemSuffix: {
    display: 'flex',
    alignItems: 'center',
    gap: '1.5rem',
  },
  noResultsTitle: {
    fontWeight: 600,
    fontSize: '1.3rem',
    lineHeight: '2.5rem',
  },
  noResultsText: {
    fontWeight: 400,
    fontSize: '1rem',
  },
  recommendedListWrapper: {
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100%',
    p: '.5rem 1rem',
  },
};
