import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useAppDispatch } from '../app/hooks';
import { logout } from '../helpers/authHelper';
import localStorageHelper from '../helpers/localStorageHelper';
import { navigateToLogin } from '../helpers/navigateToLoginHelper';
import { useResetServiceMutation as useResetChatServiceMutation } from '../services/chatService';
import { useResetServiceMutation } from '../services/notificationService';
import { setAuthToken } from '../store/actions/authActions';

// This hook watches every route change if the local storage still contains
// relevant data. If not, forcibly kill the session.
export const useForceLogout = (): void => {
  const location = useLocation();
  const navigate = useNavigate();
  const [resetNotificationService] = useResetServiceMutation();
  const [resetChatService] = useResetChatServiceMutation();

  const dispatch = useAppDispatch();

  useEffect(() => {
    const hasOnboarded = localStorageHelper.getOnboardingStatus() === 'true';
    const authToken = localStorageHelper.getAuthToken();
    const userId = localStorageHelper.getUser();
    const userInfo = localStorageHelper.getUserInfo();

    // Skip check if the user has not completed onboarding
    if (!hasOnboarded) return;

    if (!userId || !userInfo || !authToken) {
      resetNotificationService();
      resetChatService();
      dispatch(setAuthToken(null));
      logout(() => {
        navigate(navigateToLogin());
      });
    }
  }, [
    navigate,
    location,
    dispatch,
    resetNotificationService,
    resetChatService,
  ]);
};
