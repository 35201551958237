import ClearIcon from '@mui/icons-material/Clear';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import { Box, CircularProgress, IconButton, Typography } from '@mui/material';
import { generalErrorHandler } from 'helpers/generalErrorHandlerHelper';
import { useCallback, useMemo, useRef, useState } from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useParams } from 'react-router-dom';
import { useGuestUploadDocumentMutation } from 'services/fileManagerService';
import { v4 as uuidv4 } from 'uuid';
import { useAppSelector } from '../../../../app/hooks';
import wordLogo from '../../../../assets/images/doc.png';
import pdfLogo from '../../../../assets/images/pdf.png';
import txtLogo from '../../../../assets/images/txt-file.png';
import excelLogo from '../../../../assets/images/xls.png';
import FlatButton from '../../../../components/FlatButton';
import {
  allowedFileExtensions,
  imageFileExtensions,
  maxDocsUploadSize,
} from '../../../../constants/documentsConstants';
import { TEST_CONSTANTS } from '../../../../constants/testConstants';
import { generateThumbnailFromFormData } from '../../../../helpers/ThumbnailGenerator';
import {
  uploadDocumentAction,
  uploadImageDocumentAction,
} from '../../../../store/actions/dashboardActions/documentActions';
import { showSnackbarAction } from '../../../../store/actions/snackbarAction';
import { documentStyle } from '../../../../style/dashboardStyles/documentStyle';
import {
  RejectedFile,
  Thumbnail,
  UploadDocumentsProps,
  UploadedDocument,
} from '../../../../types/documentsTypes';

const UploadDocuments = (props: UploadDocumentsProps) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { token: guestToken } = useParams();
  const isWebsiteReferral = useMemo(() => guestToken, [guestToken]);
  const [guestUploadDocumentMutation, { isLoading: isDocumentUploading }] =
    useGuestUploadDocumentMutation();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const {
    selectedFiles,
    setSelectedFiles,
    totalFilesSize,
    setTotalFilesSize,
    setUploadedFiles,
    onClickDeleteFile,
    fileSelectedForDeletion,
    blobType,
  } = props;
  const { isDeleteFileLoaderActive, isUploadDocumentLoaderActive } =
    useAppSelector(
      ({ generalLoaderReducer }: Record<string, any>) =>
        generalLoaderReducer ?? true
    );
  const [rejectedFiles, setRejectedFile] = useState<RejectedFile[]>([]);

  const handleSelectDocument = () => {
    if (!fileInputRef.current) return;
    fileInputRef.current.click();
  };

  const onDrop = useCallback(
    (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      setRejectedFile([]);
      let updatedTotalFilesSize: number = totalFilesSize;
      for (let file = 0; file < acceptedFiles?.length; file++) {
        updatedTotalFilesSize += acceptedFiles?.[file]?.size;
      }
      if (fileRejections?.length) {
        setRejectedFile(fileRejections);
      }

      let files: Thumbnail[] = [];
      if (acceptedFiles?.length < 4) {
        if (updatedTotalFilesSize <= maxDocsUploadSize) {
          acceptedFiles.forEach(async (file: File) => {
            const reader = new FileReader();

            reader.onabort = () =>
              showSnackbarAction({
                open: true,
                statusCode: 400,
                message: 'file reading was aborted',
                severity: 'error',
              });
            reader.onerror = () =>
              showSnackbarAction({
                open: true,
                statusCode: 400,
                message: 'file reading has failed',
                severity: 'error',
              });
            reader.onload = () => {
              // const binaryStr = reader.result;
            };

            const fileExtension = file.name.split('.').pop();

            const formData = new FormData();

            const fileName = `${uuidv4()}.${fileExtension}`;
            const updatedFile = new File([file], fileName, { type: file.type });
            formData.append('file', updatedFile);
            formData.append('fileName', fileName);

            // create form data for thumbnail
            const thumbnailName = `${
              fileName.split('.')[0]
            }-thumbnail.${fileExtension}`;

            const updatedThumbnailFile = new File([file], thumbnailName, {
              type: file.type,
            });
            const thumbnailFormData = new FormData();
            thumbnailFormData.append('file', updatedThumbnailFile);
            thumbnailFormData.append('fileName', thumbnailName);
            setTotalFilesSize(
              (prevTotalFilesSize) => prevTotalFilesSize + file?.size
            );
            const onSuccessfullyUploadingDocument = (
              dataUrl: string,
              dataThumbnailUrl?: string
            ) => {
              setUploadedFiles((prevUploadedFiles: UploadedDocument[]) => [
                ...prevUploadedFiles,
                {
                  attachmentUrl: dataUrl,
                  fileName: file.name,
                  thumbnailUrl: dataThumbnailUrl,
                },
              ]);

              files = [
                ...files!,
                Object.assign(updatedFile, { originalName: file.name }),
              ];
              setSelectedFiles((prevFiles: Thumbnail[]) => [
                ...prevFiles,
                Object.assign(updatedFile, { originalName: file.name }),
              ]);
            };
            const isImage = imageFileExtensions.includes(
              fileExtension!.toLowerCase()
            );
            if (isWebsiteReferral) {
              if (!executeRecaptcha) {
                return;
              } else {
                const formActionName = () => {
                  let string = `upload_document_practiceReferralDocument_${fileName}_${Math.random()}.pdf`;
                  return string.replace(/[^A-Za-z/]/g, '');
                };
                const token = await executeRecaptcha(formActionName());
                await guestUploadDocumentMutation({
                  formData,
                  captchaToken: token,
                })
                  .unwrap()
                  .then((res) => {
                    onSuccessfullyUploadingDocument(res.url);
                  })
                  .catch((e) => {
                    generalErrorHandler(e);
                  });
              }
            } else {
              if (isImage) {
                // thumbnail form data prepared for upload image request body
                const thumbnailFormDataToUpload =
                  await generateThumbnailFromFormData(thumbnailFormData);

                await uploadImageDocumentAction(
                  formData,
                  thumbnailFormDataToUpload,
                  onSuccessfullyUploadingDocument,
                  blobType
                );
              } else {
                await uploadDocumentAction(
                  formData,
                  onSuccessfullyUploadingDocument,
                  blobType
                );
              }
            }
          });
        } else {
          showSnackbarAction({
            open: true,
            statusCode: 400,
            message: 'File size cannot be greater than 20 MB',
            severity: 'error',
          });
        }
      } else {
        showSnackbarAction({
          open: true,
          statusCode: 400,
          message: 'You can only upload a maximum of 3 files simultaneously',
          severity: 'error',
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [executeRecaptcha, isWebsiteReferral, selectedFiles, totalFilesSize]
  );

  const thumbs = selectedFiles?.map((file: Thumbnail) => {
    const imageSrc = () => {
      switch (file?.name.split('.').pop()?.toLowerCase()) {
        case 'doc':
        case 'docx':
          return wordLogo;

        case 'pdf':
          return pdfLogo;

        case 'xls':
        case 'xlsx':
        case 'xlsm':
        case 'xlsb':
        case 'xltx':
        case 'xltm':
        case 'xlt':
        case 'xml':
        case 'xla':
        case 'xlr':
        case 'xlam':
        case 'csv':
          return excelLogo;

        case 'txt':
          return txtLogo;

        default:
          return URL.createObjectURL(file);
      }
    };

    return (
      <Box
        key={file.name}
        data-testid={TEST_CONSTANTS.UPLOAD_DOCUMENT_THUMBNAIL}
        sx={documentStyle.thumbNailDetailsContainer}
      >
        <Box sx={documentStyle.thumbNail}>
          <img height={85} alt={file?.name} src={imageSrc()} />
          {isDeleteFileLoaderActive && fileSelectedForDeletion === file?.name && (
            <Box sx={documentStyle.thumbNailLoader}>
              <CircularProgress size='1.5rem' sx={{ color: '#ffffff' }} />
            </Box>
          )}
          {!!onClickDeleteFile && (
            <IconButton
              data-testid={TEST_CONSTANTS.UPLOAD_DOCUMENT_DELETE_BUTTON}
              sx={documentStyle.deleteFileIcon}
              onClick={() => onClickDeleteFile(file?.name)}
            >
              <ClearIcon />
            </IconButton>
          )}
        </Box>
        <Box sx={documentStyle.thumbNailFileName}>
          <Box sx={documentStyle.thumbNailFileName.name}>
            {file.originalName.substring(0, file.originalName.lastIndexOf('.'))}
          </Box>
          .{file?.originalName.split('.').pop()}
        </Box>
      </Box>
    );
  });

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'image/*': ['.png', 'jpg', 'jpeg'],
      'application/pdf': ['pdf'],
      'application/msword': ['doc', 'docx'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        ['doc', 'docx'],
      'text/csv': ['csv'],
      'text/plain': ['txt'],
      'application/csv': ['csv1'],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
        'xlsx',
      ],
      'application/vnd.ms-excel': ['xls'],
    },
  });

  return (
    <>
      {selectedFiles?.length ? (
        <Box sx={documentStyle.thumbNailsContainer}>
          <Box data-testid={TEST_CONSTANTS.UPLOAD_DOCUMENT_THUMBNAIL_CONTAINER}>
            {thumbs}
            {(isUploadDocumentLoaderActive || isDocumentUploading) && (
              <Box sx={documentStyle.uploadInProgressContainer}>
                <UploadFileIcon />
                <Typography variant='overline'>File uploading..</Typography>
              </Box>
            )}
          </Box>
        </Box>
      ) : (
        (isUploadDocumentLoaderActive || isDocumentUploading) && (
          <Box sx={[documentStyle.uploadInProgressContainer, { ml: '1rem' }]}>
            <UploadFileIcon />
            <Typography variant='overline'>File uploading..</Typography>
          </Box>
        )
      )}
      {!!rejectedFiles?.length && (
        <Box sx={documentStyle.sendDocumentsRejectedFiles}>
          <Typography variant='caption' fontWeight={500} color='error.main'>
            Rejected file(s):{' '}
          </Typography>
          {rejectedFiles?.map((record: RejectedFile) => (
            <Box>{record.file.name}</Box>
          ))}
          <>
            <Typography sx={documentStyle.documentTableAllowedExtensionHeader}>
              Please try with the following file types:{' '}
            </Typography>
            <Box>
              {allowedFileExtensions.map((type: string, index: number) => (
                <>
                  {index !== allowedFileExtensions.length - 1 && '.'}
                  {type}
                  {index !== allowedFileExtensions.length - 1 && ', '}
                </>
              ))}
            </Box>
          </>
        </Box>
      )}
      {((!isUploadDocumentLoaderActive && !props.shouldHideDrapDropBox) ||
        isDocumentUploading) && (
        <Box
          data-testid={TEST_CONSTANTS.UPLOAD_DOCUMENT_DROP_BOX}
          sx={[
            documentStyle.sendDocumentsDialog.body.rightPart.tabContent,
            { p: selectedFiles?.length ? '.5rem' : '2rem' },
          ]}
        >
          <Box
            sx={[
              documentStyle.sendDocumentsDialog.body.rightPart.uploadContainer,
              props.containerStyle ?? null,
            ]}
            onClick={handleSelectDocument}
            {...getRootProps()}
          >
            <input
              data-testid={TEST_CONSTANTS.UPLOAD_DOCUMENT_INPUT}
              type='file'
              multiple
              style={{ display: 'none' }}
              ref={fileInputRef}
              {...getInputProps()}
            />
            <UploadFileOutlinedIcon fontSize='large' />
            <Typography
              component='div'
              variant='subtitle2'
              fontWeight={400}
              align='center'
            >
              {props.dragAndDropMessage ??
                'Select or Drag & Drop to Upload Documents From Your Local Machine'}
            </Typography>
            <FlatButton>
              <Typography variant='subtitle2' fontWeight={400}>
                {!!selectedFiles.length
                  ? props.customAdditionalFileMessage ??
                    'Add additional documents'
                  : 'Upload'}
              </Typography>
            </FlatButton>
          </Box>

          <Typography
            variant='caption'
            align='center'
            data-testid={TEST_CONSTANTS.UPLOAD_DOCUMENTS_MAX_LIMIT_TEXT}
            sx={documentStyle.sendDocumentsDialog.body.rightPart.disclaimer}
          >
            {props.disclaimerMessage ??
              '* Size of all documents should not exceed 20mb'}
          </Typography>
        </Box>
      )}
    </>
  );
};

export default UploadDocuments;
