import { CloseRounded } from '@mui/icons-material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import FlatButton from '../../../../components/FlatButton';
import { TEST_CONSTANTS } from '../../../../constants/testConstants';
import {
  deleteDocumentsTableRowAction,
  removeDocumentTableRecordAction,
} from '../../../../store/actions/dashboardActions/documentActions';
import { documentStyle } from '../../../../style/dashboardStyles/documentStyle';
import { DocumentsTableDetailsType } from '../../../../types/documentsTypes';

const DeleteDocumentConfirmationDialog = ({
  isDeleteDiaogOpen,
  setIsDeleteDiaogOpen,
  selectedRecord,
  handleActionMenuClose,
}: {
  isDeleteDiaogOpen: boolean;
  setIsDeleteDiaogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedRecord: DocumentsTableDetailsType;
  handleActionMenuClose: () => void;
}) => {
  const dispatch = useAppDispatch();
  const { selectedTab } = useAppSelector(
    ({ documentReducer }: Record<string, any>) => documentReducer ?? {}
  );
  const { isDeleteDocumentRowLoaderActive } = useAppSelector(
    ({ generalLoaderReducer }: Record<string, any>) =>
      generalLoaderReducer ?? true
  );
  const onSuccessfullyDeletion = () => {
    setIsDeleteDiaogOpen(false);
    dispatch(removeDocumentTableRecordAction(selectedRecord));
    handleActionMenuClose();
  };
  const onClickConfirmDeleteRow = () => {
    deleteDocumentsTableRowAction(
      {
        id: selectedRecord.id,
        officeFileType: selectedTab === 0 ? 1 : 0,
        isReferral: selectedRecord.isReferral,
      },
      onSuccessfullyDeletion
    );
  };
  return (
    <Dialog
      data-testid={TEST_CONSTANTS.DOCUMENT_TABLE_DELETE_CONFIRMATION_DIALOG}
      aria-labelledby='delete documents'
      aria-describedby='delete documents'
      open={isDeleteDiaogOpen}
      onClose={() => setIsDeleteDiaogOpen(false)}
    >
      <DialogTitle sx={{ display: 'flex', p: '1rem' }}>
        <Box sx={documentStyle.deleteConfirmationDialog.headerIcon}>
          <DeleteOutlineOutlinedIcon color='error' />
        </Box>

        <IconButton onClick={() => setIsDeleteDiaogOpen(false)}>
          <CloseRounded />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography component='div' variant='h5' textAlign='center'>
          Delete this item
        </Typography>
        <Typography
          component='div'
          variant='body1'
          textAlign='center'
          marginTop='1rem'
        >
          Are you sure you want to delete this item?
        </Typography>
      </DialogContent>
      <DialogActions sx={documentStyle.deleteConfirmationDialog.actions}>
        <Button variant='text' onClick={() => setIsDeleteDiaogOpen(false)}>
          Cancel
        </Button>
        <FlatButton
          variant='contained'
          sx={documentStyle.deleteConfirmationDialog.actions.deleteButton}
          onClick={onClickConfirmDeleteRow}
          isLoading={isDeleteDocumentRowLoaderActive}
        >
          Yes, delete it
        </FlatButton>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteDocumentConfirmationDialog;
