import {
  PracticeActionTypes,
  PracticeApiActions,
  PracticeReducerState,
} from '../reduxConstants/practiceReduxConstants';

export const initialPracticeReducer: PracticeReducerState = {
  practiceIds: [],
  practiceRoles: {},
};

export const practiceReducer = (
  state = initialPracticeReducer,
  action: PracticeActionTypes
): PracticeReducerState => {
  switch (action.type) {
    case PracticeApiActions.GET_ROLES_SUCCESS: {
      const { id, roles } = action.payload;
      return {
        ...state,
        practiceIds: state.practiceIds.includes(id)
          ? state.practiceIds
          : [...state.practiceIds, id],
        practiceRoles: {
          ...state.practiceRoles,
          [id]: roles,
        },
      };
    }
    default:
      return state;
  }
};
