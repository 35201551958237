import React, { ChangeEvent } from 'react';
import { TextField } from '@mui/material';
import { PatternFormat } from 'react-number-format';

export interface MaskedInputProps {
  onChange?: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  value: string;
  mask: string;
  label?: string;
  placeHolder?: string;
  helperText?: string;
  isDisabled?: boolean;
  isLabelShrinked?: boolean;
  isRequired?: boolean;
}

const MaskedInput: React.FC<MaskedInputProps> = ({
  onChange,
  value,
  mask,
  label = '',
  placeHolder = '',
  helperText = '',
  isDisabled = false,
  isLabelShrinked = false,
  isRequired = false,
}) => {
  const muiFieldProps = {
    disabled: isDisabled,
    fullWidth: true,
    helperText,
    label,
    placeholder: placeHolder,
    required: isRequired,
    InputLabelProps: {
      shrink: isLabelShrinked,
    },
  };

  return (
    <PatternFormat
      data-testid='masked-input'
      format={mask}
      patternChar='9'
      inputMode='numeric'
      value={value}
      onChange={onChange}
      customInput={TextField}
      {...muiFieldProps}
    />
  );
};

export default MaskedInput;
