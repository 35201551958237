import { styled } from '@mui/system';
import { AddCommentOutlined } from '@mui/icons-material';

// flips the standard addcomment icon

const NewChatIcon = styled(AddCommentOutlined)({
  transform: 'scale(-1, 1)',
});

export default NewChatIcon;
