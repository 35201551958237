export const commonAuthStyle = {
  authPage: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100%',
    width: '100%',
    backgroundColor: 'background.paper',
  },
  authContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minWidth: '34.375rem',
    width: '34.375rem',
    minHeight: '37.5rem',
    p: '2rem',
    backgroundColor: 'background.default',
    borderRadius: '1.5rem',

    '& > *:not(:last-child):not(.backButton, .passwordField, .emailField)': {
      marginBottom: '2rem',
    },

    '& > .emailField': {
      marginBottom: '2rem',
    },

    '& > button': {
      height: '3.5rem',
      width: '100%',
    },
  },
  forgotPasswordLink: {
    marginTop: '0.5rem',
    width: 1,
  },
  authBackButton: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    marginBottom: '0.65rem',
    fontSize: 'body1',
    fontWeight: 500,
    color: 'primary.main',
    cursor: 'pointer',
  },
  authTitleSubtitleContainer: {
    width: '100%',
  },
  authTitle: {
    typography: 'h6',
    marginBottom: '0.25rem',
    fontWeight: 500,
  },
  submitButton: {
    maxWidth: 0.75,
    mt: 'auto',
  },
  setNewPasswordTextField: {
    '.MuiFormHelperText-root': {
      position: 'unset',
    },
  },
};
