import {
  AddressType,
  ONBOARDING_REDUX_CONSTANTS,
  OnboardingActionTypes,
  OnboardingDoneResponse,
  SpecialistType,
} from '../reduxConstants/onboardingReduxConstants';

type OnboardingFormType = {
  practiceName: string;
  fullAddress: string;
  practiceType: string | null;
  city?: string;
  state?: string;
  zip?: string;
};

export type AddressErrorType = {
  practiceName?: string;
  fullAddress?: string;
  practiceType?: string;
};

type OnboardingReducerType = {
  searchAddressResults: AddressType[];
  onboardingForm: OnboardingFormType;
  error?: AddressErrorType;
  onBoardedPersonData: Omit<OnboardingDoneResponse, 'hasFinishedSetup'>;
  stateSpecialists: SpecialistType[];
};

const initialOnboardingState: OnboardingReducerType = {
  searchAddressResults: [],
  stateSpecialists: [],
  onboardingForm: {
    practiceName: '',
    fullAddress: '',
    practiceType: '',
    city: '',
    state: '',
    zip: '',
  },
  error: {
    practiceName: '',
    fullAddress: '',
    practiceType: '',
  },
  onBoardedPersonData: {
    id: '',
    firstName: '',
    lastName: '',
    organizations: [],
  },
};

export const onboardingReducer = (
  state = initialOnboardingState,
  action: OnboardingActionTypes
): OnboardingReducerType => {
  switch (action.type) {
    case ONBOARDING_REDUX_CONSTANTS.ON_SEARCH_ADDRESS:
      return {
        ...state,
        searchAddressResults: action?.payload,
      };

    case ONBOARDING_REDUX_CONSTANTS.ONBOARDING_FORM_SUBMIT:
      return {
        ...state,
        onBoardedPersonData: action.payload,
      };

    case ONBOARDING_REDUX_CONSTANTS.ON_UPDATE_SPECIALISTS_LIST:
      return {
        ...state,
        stateSpecialists: action.payload,
      };

    default:
      return state;
  }
};
