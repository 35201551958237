import { Global } from '@emotion/react';
import { CallOutlined, PlaceOutlined } from '@mui/icons-material';
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Typography,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetPracticeDetailsQuery } from 'services/myNetworkService';
import { useAppSelector } from '../../../app/hooks';
import { TEST_CONSTANTS } from '../../../constants/testConstants';
import { uppercaseInitials } from '../../../helpers/getFirstTwoLetters';
import { commonClasses } from '../../../style/commonClasses';
import { viewConnectionStyle } from '../../../style/viewConnectionStyle';
import { ChatUser } from '../../../types/chatTypes';
import { joinStrings } from '../../../utilities/joinStrings';

type PracticeAddressType = {
  fullAddress: string;
  city: string;
  state: string;
  zip: string;
};

export type PracticeTeamMemberType = {
  id: string;
  displayName: string;
  firstName: string;
  lastName: string;
  profileImage: string;
  role: string;
};

export type ViewConnectionPracticeType = {
  Name: string;
  practiceType: string;
  addresses?: PracticeAddressType[];
  telephoneNumber?: string;
  teamMembers: PracticeTeamMemberType[];
};

const memberToChatUser = (
  member?: PracticeTeamMemberType,
  practice?: ViewConnectionPracticeType
) => {
  return {
    userId: member?.id,
    profileUrl: member?.profileImage,
    name:
      member?.displayName ?? joinStrings([member?.firstName, member?.lastName]),
    role: member?.role,
    practiceName: practice?.Name,
  } as ChatUser;
};

const ViewConnection = () => {
  const navigate = useNavigate();

  const { practiceId } = useParams();
  const { selectedPracticeDetails: practice } = useAppSelector(
    (state) => state.myNetworkReducer
  );

  const { isLoading: isGetPracticeDetailsLoaderActive } =
    useGetPracticeDetailsQuery({ practiceId }, { skip: !practiceId });

  return isGetPracticeDetailsLoaderActive ? (
    <Box
      sx={[
        commonClasses.loaderContainer,
        { flex: 1, pt: '3rem', alignItems: 'flex-start' },
      ]}
    >
      <CircularProgress />
    </Box>
  ) : (
    <Box
      sx={viewConnectionStyle.viewConnectionContainer}
      data-testid={TEST_CONSTANTS.VIEW_CONNECTION_CONTAINER}
    >
      <Box sx={viewConnectionStyle.commonRoundedContainer}>
        <Box sx={viewConnectionStyle.viewConnnectionsPracticeDetailsContainer}>
          <Box>
            <Box sx={viewConnectionStyle.viewConnectionsPracticeName}>
              {practice?.Name}
            </Box>
            <Box sx={viewConnectionStyle.viewConnectionsPracticeType}>
              {practice?.practiceType}
            </Box>
          </Box>

          {/* need to work on this part later on */}
          {/* <Box sx={viewConnectionStyle.viewConnectionsButtonContainer}>
            <Button variant='contained' startIcon={<AccountCircleOutlined />}>
              Refer a patient
            </Button>
            <Button variant='contained' startIcon={<SendOutlined />}>
              Send documents
            </Button>
          </Box> */}
        </Box>
        <Box sx={viewConnectionStyle.viewConnectionLocationContactContainer}>
          <Typography component='span' variant='caption'>
            <PlaceOutlined />{' '}
            {practice?.addresses?.[0]?.fullAddress
              ? practice?.addresses?.[0]?.fullAddress
              : `${practice?.addresses?.[0]?.city}, ${practice?.addresses?.[0]?.state}, ${practice?.addresses?.[0]?.zip}`}
          </Typography>

          {practice?.telephoneNumber && (
            <Typography component='span' variant='caption'>
              <CallOutlined /> {practice?.telephoneNumber}
            </Typography>
          )}
        </Box>
      </Box>
      <Box
        sx={[
          viewConnectionStyle.commonRoundedContainer,
          { p: '.5rem 1rem', overflowY: 'auto' },
        ]}
      >
        {practice?.teamMembers?.length ? (
          practice?.teamMembers?.map((member: PracticeTeamMemberType) => (
            <Box
              key={member?.id}
              sx={viewConnectionStyle.viewConnectionsMemberCard}
            >
              <Box
                sx={
                  viewConnectionStyle.viewConnectionMemberCardDetailsContainer
                }
              >
                <Avatar
                  alt={`${member?.firstName} ${member?.lastName}`}
                  src={member?.profileImage}
                  sx={viewConnectionStyle.viewConnectionMemberAvtar}
                >
                  {uppercaseInitials(
                    `${member?.firstName} ${member?.lastName}`
                  )}
                </Avatar>
                <Box>
                  <Box sx={commonClasses.cardTitle}>
                    {member?.firstName} {member?.lastName}
                  </Box>
                  <Box sx={commonClasses.cardSubtitle}>{member?.role}</Box>
                </Box>
              </Box>
              <Button
                variant='outlined'
                sx={commonClasses.commonListChatButton}
                onClick={() => {
                  navigate('/dashboard/chat', {
                    state: { user: memberToChatUser(member, practice) },
                  });
                }}
              >
                Chat
              </Button>
            </Box>
          ))
        ) : (
          <Box sx={commonClasses.noRecordText}>No team members</Box>
        )}
      </Box>
      <Global
        styles={{
          '.MuiButtonBase-root.MuiButton-root': {
            boxShadow: 'none',
          },
        }}
      />
    </Box>
  );
};

export default ViewConnection;
