export function convertDateToDays(dateString: string): string {
  const currentDate = new Date();
  const parsedDate = new Date(dateString);
  const parsedDateCopy = new Date(parsedDate);

  // Set hours, minutes, seconds, and milliseconds to 0 for comparison
  parsedDateCopy.setUTCHours(0, 0, 0, 0);
  currentDate.setUTCHours(0, 0, 0, 0);

  const dayDifference = Math.floor(
    (currentDate.getTime() - parsedDateCopy.getTime()) / (24 * 60 * 60 * 1000)
  );

  if (dayDifference === 0) {
    return 'today';
  } else if (dayDifference === 1) {
    return 'yesterday';
  } else {
    return `${dayDifference} days ago`;
  }
}
