import { css, styled, SxProps, Theme } from '@mui/system';
import { Button, CircularProgress } from '@mui/material';
import { ButtonProps as MuiButtonProps } from '@mui/material/Button';
import { ReactNode } from 'react';

type StyledButtonProps = {
  shadow?: string;
};

const StyledButton = styled(Button)<StyledButtonProps>(
  (props) => css`
    ${props.shadow === 'false' && `box-shadow: none;`}

    & .MuiCircularProgress-root {
      color: #ffffff;
    }
  `
);

interface FlatButtonProps extends MuiButtonProps {
  isLoading?: boolean;
  sx?: SxProps<Theme>;
  children?: ReactNode;
  hasShadow?: boolean;
  customLoadingElement?: ReactNode;
}

const FlatButton: React.FC<FlatButtonProps> = ({
  isLoading = false,
  children,
  hasShadow = false,
  customLoadingElement,
  ...rest
}) => {
  return (
    <StyledButton
      shadow={hasShadow.toString()}
      disabled={isLoading}
      data-testid='flatButton'
      {...rest}
    >
      {isLoading
        ? customLoadingElement ?? <CircularProgress size='1rem' />
        : children}
    </StyledButton>
  );
};

export default FlatButton;
