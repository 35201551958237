import { Box, CircularProgress, Typography } from '@mui/material';
import { useAppSelector } from 'app/hooks';
import React, { useState } from 'react';
import {
  useGetRecommendedListQuery,
  useHideRecommendedPracticeMutation,
} from 'services/myNetworkService';
import { v4 as uuidv4 } from 'uuid';
import { ReactComponent as NoDataImage } from '../../../../../src/assets/images/drTalkRecommendedPracticesEmptyState.svg';
import { TEST_CONSTANTS } from '../../../../constants/testConstants';
import { commonClasses } from '../../../../style/commonClasses';
import { myNetworkStyle } from '../../../../style/dashboardStyles/myNetworkStyle';
import {
  InvitePracticePopUpData,
  RecommendedListResponseType,
} from '../../../../types/myNetworkTypes';
import PracticeItem, { PracticeItemActionButtonType } from '../PracticeItem';
import InviteRecommendedPracticeDialog from './InviteRecommendedPracticeDialog';

const RecommendedList = () => {
  const { recommendedList } = useAppSelector((state) => state.myNetworkReducer);
  const [invitationSentToPractices, setInvitationSentToPractices] = useState<
    string[]
  >([]);
  const [hideRecommendedPractice, { isLoading: isHidePracticeLoaderActive }] =
    useHideRecommendedPracticeMutation();

  const [recordSelectedToInvite, setRecordSelectedToInvite] =
    useState<InvitePracticePopUpData | null>(null);
  const { isFetching: isRecommendedListLoaderActive } =
    useGetRecommendedListQuery();

  const onClickInvite = (id: string) => {
    const selectedPractice = recommendedList?.filter(
      (practice: RecommendedListResponseType) => practice.PracticeId === id
    )[0];
    const { PracticeId, PracticeName, PracticeType } = selectedPractice!;
    setRecordSelectedToInvite({ PracticeId, PracticeName, PracticeType });
  };

  const onClickDecline = async (id: string) => {
    await hideRecommendedPractice(id);
  };

  const actionButtons: PracticeItemActionButtonType[] = [
    {
      title: 'Invite',
      variant: 'outlined',
      onClick: onClickInvite,
      testId: TEST_CONSTANTS.INVITATION_LIST_PRACTICE_ITEM_ACCEPT_BUTTON,
      isLoaderActive: false,
    },
    {
      title: 'Hide',
      variant: 'text',
      onClick: onClickDecline,
      testId: TEST_CONSTANTS.INVITATION_LIST_PRACTICE_ITEM_IGNORE_BUTTON,
      isLoaderActive: isHidePracticeLoaderActive,
    },
  ];

  return (
    <Box sx={myNetworkStyle.recommendedListContainer}>
      <Box sx={myNetworkStyle.practiceHeaderContainer}>
        <Box sx={myNetworkStyle.headers}>
          <Typography variant='h6' sx={{ fontWeight: 500 }}>
            Recommended
          </Typography>
          <Typography variant='body2'>Practices near you</Typography>
        </Box>
      </Box>
      <Box
        sx={[myNetworkStyle.listContainer, { flex: 1, height: 'unset', p: 0 }]}
      >
        {isRecommendedListLoaderActive ? (
          <Box sx={commonClasses.loaderContainer}>
            <CircularProgress />
          </Box>
        ) : (
          <Box
            sx={[
              myNetworkStyle.recommendedListWrapper,
              { display: recommendedList?.length ? 'block' : 'flex' },
            ]}
          >
            {recommendedList?.length ? (
              recommendedList?.map(
                (practice: RecommendedListResponseType, index: number) => (
                  <React.Fragment key={uuidv4()}>
                    <PracticeItem
                      practiceItem={{
                        id: practice.PracticeId,
                        userName: practice.PracticeName,
                        profilePicture: '',
                        practiceType: practice.PracticeType,
                        address: `${practice.PracticeCity} ${practice.PracticeState} ${practice.PracticeZip}`,
                        practiceName: practice.PracticeName,
                      }}
                      index={index}
                      invitationSentToPractices={invitationSentToPractices}
                      actionButtons={actionButtons}
                    />
                  </React.Fragment>
                )
              )
            ) : (
              <Box sx={commonClasses.centeredContent}>
                <NoDataImage />
                <Typography
                  variant='subtitle2'
                  sx={myNetworkStyle.noResultsTitle}
                >
                  We can't find any practices near you
                </Typography>
                <Typography
                  variant='subtitle2'
                  sx={myNetworkStyle.noResultsText}
                >
                  Use the search bar above to look for
                  <br />
                  people and practices nearby.
                </Typography>
              </Box>
            )}
          </Box>
        )}
      </Box>
      {recordSelectedToInvite && (
        <InviteRecommendedPracticeDialog
          recordSelectedToInvite={recordSelectedToInvite}
          setRecordSelectedToInvite={setRecordSelectedToInvite}
          invitationSentToPractices={invitationSentToPractices}
          setInvitationSentToPractices={setInvitationSentToPractices}
        />
      )}
    </Box>
  );
};

export default RecommendedList;
