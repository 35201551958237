import {
  ErrorOutlineRounded,
  FileDownloadOutlined,
  ZoomIn,
} from '@mui/icons-material';
import RefreshIcon from '@mui/icons-material/Refresh';
import ReportProblemRoundedIcon from '@mui/icons-material/ReportProblemRounded';
import {
  Box,
  Card,
  CardActions,
  CardMedia,
  CircularProgress,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import _ from 'lodash';
import { ReactNode, SyntheticEvent, useEffect, useState } from 'react';
import Moment from 'react-moment';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import FlatButton from '../../../../components/FlatButton';
import TodayTime from '../../../../components/TodayTime';
import {
  getAttachmentFile,
  useGetAttachmentFileQuery,
} from '../../../../services/chatService';
import { chatStyle } from '../../../../style/dashboardStyles/chatStyle';
import {
  ChatChannel,
  ChatMessage,
  ChatMessageAttachment,
  ChatMessageAttachmentFile,
} from '../../../../types/chatTypes';
import AttachmentIcon from './AttachmentIcon';

const ChatMessageListItem = ({
  chat,
  message,
  onClick,
  onRightClick,
  onDownload,
  onView,
  onRetrySendMessage,
}: {
  chat?: ChatChannel;
  message: ChatMessage;
  onClick?: (message: ChatMessage) => void;
  onRightClick?: (event: SyntheticEvent, message: ChatMessage) => void;
  onDownload?: (attachment: ChatMessageAttachment) => void;
  onView?: (attachment: ChatMessageAttachment) => void;
  onRetrySendMessage?: (message: ChatMessage, file?: File) => void;
}) => {
  const [caption, setCaption] = useState<string | ReactNode>('');
  const [messageStyle, setMessageStyle] = useState({});

  const thumbnail: ChatMessageAttachmentFile = useAppSelector(
    (state) =>
      state.chatReducer?.fileList?.[
        message?.attachment?.thumbnail?.fileName ?? ' '
      ]
  );
  const file: ChatMessageAttachmentFile = useAppSelector(
    (state) =>
      state.chatReducer?.fileList?.[message?.attachment?.file?.fileName ?? ' ']
  );
  const dispatch = useAppDispatch();

  useGetAttachmentFileQuery(
    {
      file: message?.attachment?.thumbnail ?? ({} as ChatMessageAttachmentFile),
    },
    {
      skip: !message.attachment?.thumbnail?.fileName || !!thumbnail?.objectURL,
    }
  );

  const downloadAttachmentFile = async (file: ChatMessageAttachmentFile) => {
    const _file = { ...file };
    //return if already loaded or there is no fileName
    if (_.isEmpty(file?.fileName) || !_.isEmpty(file?.objectURL)) return _file;

    const dispatchedPromise = dispatch(getAttachmentFile.initiate({ file }));
    const result = await dispatchedPromise;
    if (result.error) _file.error = result.error.toString();
    if (result.data) _file.objectURL = result.data.objectURL;
    dispatchedPromise.unsubscribe();

    return _file;
  };

  const handleOnDownload = async () => {
    if (
      !onDownload ||
      !message.attachment ||
      !message.attachment.file?.fileName
    )
      return;
    const _file =
      file ?? (await downloadAttachmentFile(message.attachment.file));
    if (_file?.objectURL) onDownload({ ...message.attachment, file: _file });
  };

  const handleOnView = async () => {
    if (!onView || !message.attachment || !message.attachment.file?.fileName)
      return;
    //this will open the dialog while waiting
    onView({ ...message.attachment, ...(file && { file }) });
    const _file =
      file ?? (await downloadAttachmentFile(message.attachment.file));
    if (_file) onView({ ...message.attachment, file: _file });
  };

  useEffect(() => {
    let caption;
    if (message.fromUser) {
      caption = '';
      if (!(message.pending || message.failed)) {
        caption = '✓ Sent';
        //✔✓✓✔✔️✓
        //check delivered status
        if ((chat?.lastDeliveredAt ?? 0) > message.createdAt)
          caption = '✓✓ Delivered';
        //check read status
        if ((chat?.lastReadAt ?? 0) > message.createdAt) {
          caption = (
            <>
              {'Read '}
              <TodayTime>{message.createdTimeUtc}</TodayTime>
            </>
          );
        }
      }
    } else {
      caption = (
        <>
          {chat?.isGroupChat && message.user?.name}
          {' • '}
          <TodayTime>{message.createdTimeUtc}</TodayTime>
        </>
      );
    }
    setCaption(caption);

    const messageStyle = {
      ...chatStyle.chatMessageMessage,
      ...(message.fromUser && chatStyle.chatMessageMessageUser),
      ...(message?.pending && chatStyle.chatMessageMessagePending),
      ...(message?.failed && chatStyle.chatMessageMessageFailed),
      ...(onClick && { cursor: 'pointer' }),
    };
    setMessageStyle(messageStyle);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message, chat]);

  return message.hide ? (
    <></>
  ) : (
    <Stack
      sx={{
        ...chatStyle.chatMessageContainer,
        ...(message.fromUser && chatStyle.chatMessageContainerUser),
      }}
    >
      {message.attachment && (
        <Card
          sx={[messageStyle, chatStyle.chatMessageAttachment]}
          onClick={() => onClick && onClick(message)}
          onContextMenu={(e: SyntheticEvent) =>
            onRightClick && onRightClick(e, message)
          }
        >
          {message.attachment.isImage &&
            (thumbnail?.objectURL ? (
              <CardMedia
                component='img'
                image={thumbnail.objectURL}
                alt={message.attachment.name}
                onClick={() => handleOnView()}
              />
            ) : (
              <Box sx={chatStyle.chatMessageAttachment.loader}>
                {thumbnail?.loading ? (
                  <CircularProgress size={'4rem'} color={'inherit'} />
                ) : (
                  <ErrorOutlineRounded fontSize='large' />
                )}
              </Box>
            ))}
          <CardActions disableSpacing>
            {!message.attachment?.isImage && (
              <AttachmentIcon fontSize={'small'} />
            )}
            <Stack>
              <Typography variant='body2' noWrap fontWeight={'700'}>
                {message.attachment?.name}
              </Typography>
              <Typography variant='caption' noWrap>
                {message.attachment?.timestamp && (
                  <Moment local unix locale='en_US' format='L LT'>
                    {message.attachment.timestamp}
                  </Moment>
                )}
              </Typography>
            </Stack>
            {!message.pending &&
              onDownload &&
              message.attachment?.file?.fileName && (
                <IconButton
                  aria-label='download'
                  onClick={() => handleOnDownload()}
                  disabled={file?.loading}
                >
                  {file?.loading && !thumbnail?.loading ? (
                    <CircularProgress size={'1.25rem'} color={'inherit'} />
                  ) : (
                    <FileDownloadOutlined fontSize={'small'} />
                  )}
                </IconButton>
              )}
            {!message.pending &&
              message.attachment?.isImage &&
              onView &&
              message.attachment?.file?.fileName && (
                <IconButton aria-label='view' onClick={() => handleOnView()}>
                  <ZoomIn fontSize={'small'} />
                </IconButton>
              )}
          </CardActions>
        </Card>
      )}
      {message.messageText && !!!message?.metaDictionary?.giphyThumbnailUri && (
        <>
          <Typography
            variant='body2'
            sx={messageStyle}
            onClick={() => onClick && onClick(message)}
            onContextMenu={(e: SyntheticEvent) =>
              onRightClick && onRightClick(e, message)
            }
          >
            {message?.failed && (
              <Box sx={chatStyle.failedToSendText}>
                <ReportProblemRoundedIcon />
                <Box>Failed to send</Box>
              </Box>
            )}
            {message.messageText}
          </Typography>
          {message?.failed && (
            <FlatButton
              variant='text'
              sx={chatStyle.retryButton}
              onClick={() => onRetrySendMessage && onRetrySendMessage(message)}
            >
              <RefreshIcon /> Retry
            </FlatButton>
          )}
        </>
      )}

      {/* for showing GIFs */}
      {!!message?.metaDictionary?.giphyThumbnailUri && (
        <Card
          sx={[messageStyle, chatStyle.chatMessageAttachment]}
          onContextMenu={(e: SyntheticEvent) =>
            onRightClick && onRightClick(e, message)
          }
        >
          <Box
            component='img'
            src={message.messageText}
            alt='Loading...'
            maxHeight={250}
          />
        </Card>
      )}
      <Typography
        variant='caption'
        noWrap
        sx={chatStyle.chatMessageCaptionStyle}
      >
        {caption}
      </Typography>
    </Stack>
  );
};
export default ChatMessageListItem;
