import { Box } from '@mui/material';
import { useMemo, type ComponentProps } from 'react';

import { headerStyle } from '../style/headerStyle';
import { USER_STATUS } from '../constants/userConstants';
import { TEST_CONSTANTS } from 'constants/testConstants';
import { colorPalette } from 'theme/colorPalette';

export type StatusIndicatorProps = {
  status?: string;
} & ComponentProps<typeof Box>;

export const StatusIndicator = ({
  status = USER_STATUS.OFFLINE,
  sx,
  ...props
}: StatusIndicatorProps) => {
  const userStatusColor = useMemo(() => {
    switch (status) {
      case USER_STATUS.AVAILABLE: {
        return 'success.main';
      }
      case USER_STATUS.BUSY: {
        return 'error.main';
      }
      default: {
        return colorPalette.surfaceColor[400];
      }
    }
  }, [status]);

  return (
    <Box
      data-testid={TEST_CONSTANTS.PROFILE_IMG_AVATAR_STATUS}
      sx={{
        ...headerStyle.userStatusIndicator,
        ...sx,
        bgcolor: userStatusColor, // declared at the bottom, because this should not be overridden
      }}
      {...props}
    />
  );
};
