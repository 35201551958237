export const guestUserDocumentsAskForSignUpStyle = {
  mainContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: 1,
    pt: '4rem',
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '1rem',
    width: 0.5,
    button: {
      width: 'fit-content',
    },
  },
  mainTitle: {
    textAlign: 'center',
  },
  subTitle: {
    width: '27rem',
    textAlign: 'center',
  },
  signUpBubbleContainer: {
    bottom: '5rem',
    left: '2rem',
  },
  signUpBubble: {
    '&:after': {
      borderWidth: '12px 12px 12px 0',
      top: '24%',
      left: '-12px',
      marginTop: '-12px',
    },
  },
};
