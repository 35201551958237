import { colorPalette } from '../theme/colorPalette';

export const headerStyle = {
  headerContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: 1,
    flexShrink: '0',
  },
  headerBackButton: {
    color: colorPalette.surfaceColor[600],
    '& > *': {
      fontWeight: 400,
    },
  },
  headerSearchBarContainer: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
  },
  arrowAndSearchbarVerticalLine: {
    width: '.063rem',
    height: '2rem',
    m: '0 1.25rem 0 .25rem',
    bgcolor: colorPalette.surfaceColor[300],
  },
  headerSearchbar: {
    '& .MuiButtonBase-root.MuiAutocomplete-clearIndicator': {
      mr: '.5rem',
      width: '1.75rem',
    },
  },
  headerSearchDropdown: {
    mt: '.5rem',

    borderRadius: '.75rem',
    '.MuiAutocomplete-listbox': {},
    '.MuiAutocomplete-listbox .MuiAutocomplete-option': {
      m: '0 .5rem',
      width: 'calc(100% - 1rem)',
      p: '.5rem',
      overflowX: 'hidden',
      '&.Mui-focused': {
        backgroundColor: 'primary.light',
        borderRadius: '.5rem',
        cursor: 'default',
      },
      '&[aria-selected = true]': {
        borderRadius: '.5rem',
      },
    },
  },
  hightlightedSearchText: {
    fontWeight: 700,
    backgroundColor: 'yellow',
  },
  noSearchResultContainer: {
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'center',
    gap: '1rem',
    p: '1rem 0',
  },
  noSearchResultsText: {
    color: 'primary.main',
  },
  headerProfileMenuButton: {
    cursor: 'pointer',
    borderRadius: '50%',
    '&:hover': {
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    },
  },
  headerSearchTextField: {
    flex: 1,
    height: '3.5rem',
    bgcolor: 'background.default',
    borderRadius: '1.875rem',
    border: 'none',
    input: {
      height: '3.5rem',
      boxSizing: 'border-box',
    },
    svg: {
      width: '3.375rem',
      color: 'text.secondary',
    },
    '& input::placeholder': {
      typography: 'body2',
      color: 'text.secondary',
      opacity: 1,
    },
  },
  searchResultAvatar: {
    height: '2.5rem',
    width: '2.5rem',
    flexShrink: 0,
    color: 'primary.light',
    bgcolor: 'primary.main',
  },
  headerSearchResultCard: {
    display: 'flex',
    alignItems: 'center',
    color: '#212121',
  },
  searchResultItem: {
    flex: 1,
    alignItems: 'center',

    '.MuiListItemSecondaryAction-root': {
      position: 'unset',
      transform: 'unset',
    },
  },
  headerNotificationContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0 1rem',
    minHeight: '2.5rem',
    minWidth: '2.5rem',
    height: '2.5rem',
    width: '2.5rem',
  },
  headerNotificationButton: {
    '& .MuiBadge-badge': {
      right: '30%',
      top: '25%',
      bgcolor: 'error.main',
    },
  },
  headerNotificationMenu: {
    padding: '0 0.5rem 0.5rem',
    borderRadius: '0.75rem',
    width: '24.5rem',
    maxHeight: '40rem',
    '& .MuiList-root': {
      padding: 0,
    },
  },
  headerNotificationMenuTitle: {
    padding: '1rem 0 0.5rem 0.5rem',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'sticky',
    top: 0,
    backgroundColor: 'white',
    zIndex: 2,
  },
  headerNotificationMenuItem: {
    margin: '0',
    padding: '1rem 0.5rem',
    borderRadius: '0.75rem',
    '& .MuiListItemText-root': { pr: '1rem' },
    '& .MuiBadge-badge': {
      height: '1rem',
      minWidth: '1rem',
      borderRadius: '3vmin',
      p: '0 0.25rem',
      fontSize: '0.6rem',
      color: '#fff',
    },
    '& .MuiBadge-dot': {
      height: '0.5rem',
      minWidth: '0.5rem',
    },
    '& .MuiTypography-body2': { whiteSpace: 'normal' },
    '& .MuiTypography-caption': {
      padding: '1rem 0 0 0',
      color: `${colorPalette.surfaceColor[500]}`,
    },
  },
  optionActionButton: {
    position: 'unset',
    minHeight: '2rem',
    ml: '1rem',
    p: '0 1rem',
    typography: 'caption',
  },
  notificationBadge: {
    position: 'absolute',
    top: '.4rem',
    right: '.25rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '1rem',
    width: '1rem',
    typography: 'overline',
    color: 'background.default',
    bgcolor: 'error.main',
    borderRadius: '50%',
  },
  profileMenu: {
    marginTop: '.625rem',
    '& .MuiPaper-root': {
      borderRadius: '.75rem',
    },
    '.MuiMenu-list': {
      p: '0',
      bgcolor: 'background.default',
    },
  },
  profileContainer: {
    height: '2.5rem',
    width: '2.5rem',
    flexShrink: '0',
  },
  userStatusIndicator: {
    height: '.75rem',
    minWidth: '.75rem',
    border: '1px solid',
    borderColor: 'background.default',
    borderRadius: '50%',
  },
  profilePictureUserIndicator: {
    position: 'absolute',
    right: '-.1rem',
    bottom: '.025rem',
  },
  profileMenuContainer: {
    width: '18.75rem',
    bgcolor: 'background.default',
    borderRadius: '.75rem',

    li: {
      p: '1rem',
      borderTop: '1px solid',
      borderColor: 'background.paper',
    },

    '& > *': {
      width: 1,
      borderRadius: '.75rem',
    },
  },

  userInfoContainer: {
    display: 'flex',
    p: '1.125rem 1rem .625rem',
  },
  userInfo: {
    marginLeft: '1rem',
  },
  userName: {
    typography: 'body2',
  },
  userStatusButton: {
    display: 'flex',
    alignItems: 'center',
    width: 'fit-content',
    margin: '0 1rem',
    p: '.25rem .5rem',
    typography: 'caption',
    borderRadius: '.25rem',
    cursor: 'pointer',
    '&:hover': {
      bgcolor: 'background.paper',
    },
  },
  userStatusMenuContainer: {
    width: '17.813rem',
    '& > *': {
      borderRadius: '.75rem',
    },
    '.MuiMenu-list': {
      bgcolor: 'background.default',
      li: {
        p: '.565rem 1rem',
      },
    },
  },
  userStatusMenu: {
    flexShrink: 0,
    typography: 'body2',
    pl: '.75rem',
  },
};
