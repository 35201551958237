import { useEffect } from 'react';
import { useAppSelector } from '../app/hooks';
import { useDownloadImageAsBlobQuery } from 'services/fileManagerService';

// custom hook for getting the latest user avatar from file server
const useGetUserImgFromServer = (
  setPhotoCallback?: (imgUrl: string | undefined) => void
) => {
  const { userInfo } = useAppSelector((state) => ({
    id: state.userReducer.id,
    userInfo: state.userReducer.userInfo,
  }));

  const { data: profileImgBlob } = useDownloadImageAsBlobQuery(
    {
      fileName: userInfo?.profileImage ?? '',
      blobType: 'Public',
    },
    {
      skip: !userInfo?.profileImage,
    }
  );

  useEffect(() => {
    setPhotoCallback &&
      setPhotoCallback(
        !!userInfo?.profileImage ? profileImgBlob?.objectURL : undefined
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileImgBlob?.objectURL, userInfo?.profileImage]);

  return !userInfo?.profileImage
    ? undefined
    : profileImgBlob?.objectURL ?? userInfo?.profileImage;
};

export default useGetUserImgFromServer;
