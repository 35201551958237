import { ThemeProvider } from '@mui/material';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import UpdateAppBanner from './components/UpdateAppBanner';
import SnackbarHelper from './helpers/snackbarHelper';
import CustomRoutes from './routes/CustomRoutes';
import { store } from './store/store';
import { theme } from './theme/theme';

function App() {
  // useEffect(() => {
  //   window.location.replace(String(process.env.REACT_APP_URL_V3));
  // });
  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <UpdateAppBanner />
        <SnackbarHelper />
        <Router>
          <CustomRoutes />
        </Router>
      </Provider>
    </ThemeProvider>
  );
}

export default App;
