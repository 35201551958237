import { Box, Typography } from '@mui/material';
import nudgeStyle from '../style/nudgeStyle';
import { NudgeProps } from '../types/Misc';
import FlatButton from './FlatButton';

const Nudge = (props: NudgeProps) => {
  const {
    nudgeContainerClass,
    nudgeClass,
    title,
    content,
    primaryButton,
    secondaryButton,
    primaryButtonClick,
    secondaryButtonClick,
  } = props;
  return (
    <Box
      sx={[
        nudgeStyle.nudgeContainer,
        // You cannot spread `sx` directly because `SxProps` (typeof sx) can be an array.
        ...(Array.isArray(nudgeContainerClass)
          ? nudgeContainerClass
          : [nudgeContainerClass]),
      ]}
    >
      <Box
        sx={[
          nudgeStyle.nudge,
          ...(Array.isArray(nudgeClass) ? nudgeClass : [nudgeClass]),
        ]}
      >
        <Typography variant='h6' fontWeight={600}>
          {title}
        </Typography>
        <Typography variant='body2' fontWeight={400}>
          {content}
        </Typography>
        <Box sx={nudgeStyle.nudgeActionButtonContainer}>
          {!!secondaryButton && (
            <FlatButton
              variant='outlined'
              color='secondary'
              onClick={secondaryButtonClick}
            >
              {secondaryButton}
            </FlatButton>
          )}
          <FlatButton
            variant='contained'
            color='secondary'
            onClick={primaryButtonClick}
          >
            {primaryButton}
          </FlatButton>
        </Box>
      </Box>
    </Box>
  );
};

export default Nudge;
