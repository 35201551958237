import { CloseRounded, SendOutlined } from '@mui/icons-material';
import ContactMailOutlinedIcon from '@mui/icons-material/ContactMailOutlined';
import DomainAddOutlinedIcon from '@mui/icons-material/DomainAddOutlined';
import LinkOutlinedIcon from '@mui/icons-material/LinkOutlined';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  TextField
} from '@mui/material';
import { ChangeEvent, useState } from 'react';
import { useAppSelector } from '../../../../app/hooks';
import FlatButton from '../../../../components/FlatButton';
import { invitationMode } from '../../../../constants/invitationModeEnum';
import { TEST_CONSTANTS } from '../../../../constants/testConstants';
import { DASHBOARD_URLS } from '../../../../constants/urlConstants';
import localStorageHelper from '../../../../helpers/localStorageHelper';
import { isEmailValid } from '../../../../helpers/validationHelper';
import { useInvitePracticeMutation } from '../../../../services/myNetworkService';
import { showSnackbarAction } from '../../../../store/actions/snackbarAction';
import { commonClasses } from '../../../../style/commonClasses';
import { myNetworkStyle } from '../../../../style/dashboardStyles/myNetworkStyle';

type CreateInviteDialogPropType = {
  isInvitePracticeDialogOpen: boolean;
  setIsInvitePracticeDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
};
const emailErrorMessage = 'Email address is invalid';
export type InvitePracticeFormDataType = {
  email: {
    value: string;
    error: string | null;
  };
  optionalMessage: {
    value: string;
  };
};
const CreateInviteDialog = ({
  isInvitePracticeDialogOpen,
  setIsInvitePracticeDialogOpen,
}: CreateInviteDialogPropType) => {
  const [isFormSubmittedOnce, setIsFormSubmittedOnce] =
    useState<boolean>(false);

  const { userInfo } = useAppSelector((state) => ({
    id: state.userReducer.id,
    userInfo: state.userReducer.userInfo,
  }));

  const [invitePractice, { isLoading: isInvitePracticeLoaderActive }] =
    useInvitePracticeMutation();

  const initialInvitePracticeFormData = {
    email: {
      value: '',
      error: null,
    },
    optionalMessage: {
      value: `Hi there, it’s ${userInfo?.firstName} ${userInfo?.lastName}${
        userInfo?.practiceName?.trim()?.length
          ? ` from ${userInfo.practiceName}`
          : ''
      }. I’d like to invite you to drtalk. It will help us securely discuss our shared patients, and makes day to day tasks in the office a whole lot easier.`,
    },
  };

  const [invitePracticeFormData, setInvitePracticeFormData] =
    useState<InvitePracticeFormDataType>(initialInvitePracticeFormData);

  const copyLinkToClipboard = () => {
    navigator.clipboard.writeText(
      `${
        DASHBOARD_URLS.MY_NETWORK_URL.INVITE_PRACTICE_COPY_LINK
      }/${localStorageHelper.getPracticeInviteCode()}`
    );
    showSnackbarAction({
      open: true,
      statusCode: 200,
      message: 'Link copied to clipboard',
      severity: 'success',
    });
  };

  const onEmailAddressChange = (event: ChangeEvent<HTMLInputElement>) => {
    const isEmailAddressValid = isEmailValid('email', event.target.value);

    setInvitePracticeFormData({
      ...invitePracticeFormData,
      email: {
        ...invitePracticeFormData?.email,
        value: event.target.value,
      },
    });
    if (isFormSubmittedOnce) {
      setInvitePracticeFormData({
        ...invitePracticeFormData,
        email: {
          value: event.target.value,
          error: isEmailAddressValid.status ? null : emailErrorMessage,
        },
      });
    }
  };

  const onClickSendInvite = async () => {
    const isEmailAddressValid = isEmailValid(
      'email',
      invitePracticeFormData?.email?.value
    );

    if (!isFormSubmittedOnce) {
      setIsFormSubmittedOnce(true);
    }
    if (isEmailAddressValid.status) {
      const data = {
        toEmail: invitePracticeFormData.email.value,
        invitationMode: invitationMode.organization,
        message: invitePracticeFormData.optionalMessage.value,
      };
      // invite practice
      await invitePractice(data)
        .unwrap()
        .then(() => {
          setIsInvitePracticeDialogOpen(false);
          showSnackbarAction({
            open: true,
            statusCode: 200,
            message: 'Invitation sent successfully',
            severity: 'success',
          });
        })
        .catch((e) => {
          showSnackbarAction({
            open: true,
            statusCode: e?.status,
            message:
              e?.status === 400
                ? e?.data?.Message ??
                  'This user is already on drtalk. Would you like to connect?'
                : e?.ExceptionMessage ?? e?.Message,
            severity: 'error',
          });
        })
        .finally(() =>
          setInvitePracticeFormData({
            ...invitePracticeFormData,
            email: {
              ...invitePracticeFormData.email,
              error: null,
            },
          })
        );
    } else {
      setInvitePracticeFormData({
        ...invitePracticeFormData,
        email: {
          ...invitePracticeFormData.email,
          error: isEmailAddressValid.status ? null : emailErrorMessage,
        },
      });
    }
  };

  return (
    <Dialog
      sx={myNetworkStyle.inviteDialog}
      data-testid={TEST_CONSTANTS.MY_NETWORK_SEND_INVITE_DIALOG}
      open={isInvitePracticeDialogOpen}
      onClose={() => setIsInvitePracticeDialogOpen(false)}
      aria-labelledby='invite practice'
      aria-describedby='invite practice'
    >
      <DialogTitle sx={{ p: '1rem 1.5rem' }}>
        <Box sx={myNetworkStyle.invitePracticeModalHeader}>
          <DomainAddOutlinedIcon
            sx={myNetworkStyle.invitePracticeModalHeaderIcon}
          />
          <IconButton
            data-testid={TEST_CONSTANTS.SEND_INVITE_CLOSE_BUTTON}
            onClick={() => setIsInvitePracticeDialogOpen(false)}
          >
            <CloseRounded />
          </IconButton>
        </Box>
        <Box sx={myNetworkStyle.invitePracticeTitle}>Invite a Practice</Box>
        <Box sx={myNetworkStyle.invitePracticeSubtitle}>
          Invite a practice to your network
        </Box>
      </DialogTitle>
      <DialogContent sx={myNetworkStyle.createInviteDialogContent}>
        <form noValidate>
          <TextField
            fullWidth
            required
            type='email'
            sx={
              invitePracticeFormData?.email?.error
                ? commonClasses.fieldError
                : {}
            }
            inputProps={{
              'data-testid':
                TEST_CONSTANTS.MY_NETWORK_CREATE_INVITE_EMAIL_FIELD,
            }}
            autoComplete='off'
            variant='outlined'
            label='Email'
            value={invitePracticeFormData?.email?.value}
            onChange={onEmailAddressChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <ContactMailOutlinedIcon color='primary' />
                </InputAdornment>
              ),
            }}
            helperText={invitePracticeFormData?.email?.error}
          />

          <TextField
            fullWidth
            multiline
            rows={4}
            variant='outlined'
            value={invitePracticeFormData?.optionalMessage?.value}
            label='Optional message'
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              setInvitePracticeFormData({
                ...invitePracticeFormData,
                optionalMessage: { value: event.target.value },
              })
            }
            sx={myNetworkStyle.createInviteOptionalMessage}
          />
        </form>
      </DialogContent>
      <DialogActions sx={myNetworkStyle.createInviteDialogActions}>
        <Box sx={myNetworkStyle.copyLink} onClick={copyLinkToClipboard}>
          <LinkOutlinedIcon color='primary' />
          Copy link
        </Box>
        <FlatButton
          variant='contained'
          data-testid={
            TEST_CONSTANTS.MY_NETWORK_INVITE_DIALOG_SEND_INVITE_BUTTON
          }
          sx={myNetworkStyle.invitePracticeSendButton}
          startIcon={
            !isInvitePracticeLoaderActive && (
              <SendOutlined sx={{ transform: 'rotate(-45deg)' }} />
            )
          }
          onClick={onClickSendInvite}
          isLoading={isInvitePracticeLoaderActive}
        >
          Send invite
        </FlatButton>
      </DialogActions>
    </Dialog>
  );
};

export default CreateInviteDialog;
