import Moment from 'react-moment';

const TodayTime = ({
  children,
  style,
  unix = true,
}: {
  children?: string | number | Date | undefined;
  style?: Record<string, string>;
  unix?: boolean;
}) => {
  //unix = false will default to msec instead of sec
  const otherFormat = 'MMM D';
  return (
    <Moment
      calendar={{
        lastDay: otherFormat,
        sameDay: 'h:mma',
        nextDay: otherFormat,
        lastWeek: otherFormat,
        nextWeek: otherFormat,
        sameElse: otherFormat,
      }}
      style={style ?? { fontSize: '0.75rem' }}
      utc={typeof children !== 'number'}
      local
      unix={unix}
    >
      {children}
    </Moment>
  );
};

export default TodayTime;
