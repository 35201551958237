import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  Typography,
} from '@mui/material';

const DeleteRecipientProgressDialog = ({ isOpen }: { isOpen: boolean }) => {
  return (
    <Dialog open={!!isOpen} maxWidth='xs' keepMounted>
      <DialogContent>
        <Box p='1rem' textAlign='center'>
          <Typography component='div' variant='h5' textAlign='center'>
            Processing your request
          </Typography>
          <Box mb='1rem' mt='1.5rem'>
            <CircularProgress />
          </Box>
          <Typography component='div' variant='body1' textAlign='center'>
            This process may take a few minutes.
          </Typography>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default DeleteRecipientProgressDialog;
