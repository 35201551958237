export const EMAIL_ADDRESS_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const NON_NUMERIC_REGEX = /[^0-9]/g;
export const NUMBER_REGEX = /^\d+$/;
export const SPECIAL_CHARACTER_REGEX = /[^A-Za-z 0-9]/;
export const PASSWORD_REGEX =
  /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
export const ALPHA_NUMERIC_REGEX = /^[A-Za-z0-9]+$/;

export const audConverter = (number: number) => {
  const numberToAUD = new Intl.NumberFormat('en-AU', {
    style: 'currency',
    currency: 'AUD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });
  return numberToAUD.format(number);
};
export const ONLY_NUMBER_HYPHEN_REGEX = /^[0-9-]+$/;
export const ALPHA_REGEX = /^[a-zA-Z\s]*$/;
export const US_ZIP_REGEX = /^[0-9]{5}(?:-[0-9]{4})?$/;
export const FLOAT_NUMBER_REGEX =
  /^(0*[1-9][0-9]*(\.[0-9]+)?|0+\.[0-9]*[1-9][0-9]*)$/;
export const FILE_EXTENSION_REGEX = /\.(\w+)(?:\?.*)?$/;
