import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { Box, Button, Dialog, DialogContent, Typography } from '@mui/material';
import React from 'react';
import { chatStyle } from 'style/dashboardStyles/chatStyle';

const FileNotSupportedDialog = ({
  selectedFileNotSupported,
  setSelectedFileNotSupported,
}: {
  selectedFileNotSupported: string | null;
  setSelectedFileNotSupported: React.Dispatch<
    React.SetStateAction<string | null>
  >;
}) => {
  return (
    <Dialog
      open={!!selectedFileNotSupported}
      onClose={() => setSelectedFileNotSupported(null)}
    >
      <DialogContent sx={chatStyle.fileNotSupportedDialogContainer}>
        <ErrorOutlineOutlinedIcon color='error' fontSize='large' />
        <Typography variant='h6'>Warning</Typography>
        <Box textAlign='center'>
          <Typography variant='subtitle2'>
            drtalk does not support this file type
          </Typography>
          <Typography variant='body2' sx={{ fontStyle: 'italic' }}>
            {selectedFileNotSupported}
          </Typography>
        </Box>
        <Box textAlign='center'>
          <Typography variant='subtitle2'>
            Please try with one of the following file types:
          </Typography>
          <Typography variant='body2' sx={{ fontStyle: 'italic' }}>
            .jpg, .jpeg, .gif, .tif, .png, .pdf, .doc, .docx, .xls, .xlsx, .ppt,
            .pptx
          </Typography>
        </Box>

        <Button
          variant='contained'
          color='primary'
          sx={chatStyle.fileNotSupportedDialogActionButton}
          onClick={() => setSelectedFileNotSupported(null)}
        >
          OK
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default FileNotSupportedDialog;
