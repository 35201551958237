import { colorPalette } from '../theme/colorPalette';

export const profileStyle = {
  about: {
    mainContainer: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      paddingTop: '1.875rem',
    },
    checkContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: '1.5rem',
      padding: '0 1rem',
      height: '1.5rem',
      width: '100%',
    },
    checkedText: {
      display: 'flex',
      alignItems: 'center',
      '& > svg': {
        marginRight: '0.5rem',
      },
    },
    description: {
      letterSpacing: 'initial',
      marginTop: '1.5rem',
    },
    footer: {
      color: 'rgba(0, 0, 0, 0.35)',
      letterSpacing: '0.00625rem',
      marginTop: '1.5rem',
    },
  },
  account: {
    container: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      paddingTop: '1.875rem',
    },
    dollarIcon: {
      width: '3.25rem',
      height: '3.25rem',
      backgroundColor: 'primary.light',
      marginBottom: '0.75rem',
      '> .MuiSvgIcon-root': {
        fill: 'primary.dark',
      },
    },
    message: {
      textAlign: 'center',
      width: '17.125rem',
      marginBottom: '2rem',
      '> .MuiTypography-h6': {
        marginBottom: '0.625rem',
      },
      '> .MuiTypography-body2': {
        lineHeight: '1.5rem',
        letterSpacing: '0.00625rem',
      },
    },
    settingsButton: {
      fontSize: '0.875rem',
      letterSpacing: '0.078125rem',
      lineHeight: '1rem',
      width: '15.3125rem',
    },
  },
  form: {
    container: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    textFieldContainer: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      '.MuiTextField-root': {
        width: '100%',
        margin: '1rem 0',
        '&:first-of-type': {
          marginTop: '0',
        },
      },
      '.new-password.MuiTextField-root > p.MuiFormHelperText-root': {
        position: 'static',
        marginBottom: '-1rem',
      },
      '.new-password.invalid-password': {
        marginBottom: '0.5rem',
      },
    },
    updateButton: {
      fontWeight: '500',
      height: '2.5rem',
      margin: '1rem 0 0 0',
      width: '10rem',
    },
  },
  modal: {
    container: {
      backgroundColor: 'background.default',
      borderRadius: '1.5rem',
      flexShrink: 0,
      padding: '1.75rem 2rem 2rem',
      width: '42rem',
      height: 'auto',
    },
    content: {
      height: '100%',
      paddingTop: '1.5rem',
    },
  },
  newPasswordCriteria: {
    'ul > .new-password-success': {
      color: colorPalette.success,
    },
  },
  page: {
    alignItems: 'center',
    backgroundColor: 'background.paper',
    display: 'flex',
    flexFlow: 'column nowrap',
    height: '100%',
    overflowY: 'auto',
    paddingTop: '0.5rem',
    paddingBottom: '1.5rem',
    '&.MuiContainer-root': {
      maxWidth: 'none',
    },
  },
  profilePicture: {
    avatar: {
      height: '100%',
      width: '100%',
    },
    container: {
      height: '100%',
      position: 'relative',
    },
    editIcon: {
      backgroundColor: 'background.default',
      border: `1px solid ${colorPalette.primaryMain}`,
      borderRadius: '50%',
      height: '3.25rem',
      position: 'absolute',
      right: '0',
      bottom: '4.875rem',
      width: '3.25rem',
      '&:hover': {
        backgroundColor: 'background.paper',
      },
    },
  },
  profilePictureCreator: {
    container: {
      height: '37.25rem',
      width: '41.0625rem',
      maxWidth: '41.0625rem',
    },
    content: {
      container: {
        overflow: 'hidden',
        '.react-cropper': {
          width: '37.0625rem',
          height: '22.5rem',
        },
        '.cropper-bg': {
          background: '#ffffff',
        },
        '.cropper-dashed': {
          borderStyle: 'solid',
          // These styles are to prevent the guide lines from going beyond the circle
          '&.dashed-h': {
            left: '8px',
            width: 'calc(100% - 16px)',
          },
          '&.dashed-v': {
            top: '8px',
            height: 'calc(100% - 16px)',
          },
        },
        '.cropper-modal': {
          opacity: '0.7',
        },
        '.cropper-view-box, .cropper-face': {
          borderRadius: '50%',
          border: '2px solid #ffffff',
        },
        '.cropper-view-box': {
          outline: '0',
        },
      },
      viewer: {
        container: {
          position: 'relative',
          backgroundColor: 'rgba(0, 0, 0, 0.7)',
        },
        instruction: {
          position: 'absolute',
          bottom: '1.6875rem',
          left: '50%',
          transform: 'translateX(-50%)',
          color: 'rgba(255, 255, 255, 0.6)',
          userSelect: 'none',
        },
        changeButton: {
          position: 'absolute',
          right: '1.5rem',
          top: '1.5rem',
        },
        deleteButton: {
          position: 'absolute',
          top: '4rem',
          right: '1.5rem',
        },
      },
      tools: {
        container: {
          display: 'flex',
          alignItems: 'center',
          marginTop: '1.25rem',
        },
        sizeSlider: {
          display: 'flex',
          alignItems: 'center',
          flex: 1,
          '> .MuiTypography-root': {
            marginRight: '2rem',
          },
          '& .MuiSlider-track': {
            height: '0.38rem',
          },
        },
        rotateButton: {
          color: '#48454f',
          minWidth: 0,
          marginLeft: '1.75rem',
          height: '1.3125rem',
          width: '1.3125rem',
        },
      },
    },
    testDialog: {
      content: {
        height: '25rem',
        width: '25rem',
        margin: 'auto',
      },
      croppedImage: {
        width: '100%',
      },
    },
    title: {
      closeButton: {
        minWidth: 'auto',
      },
      container: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between',
      },
      text: {
        fontWeight: 500,
      },
    },
    actions: {
      container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '2rem',
        '> button:not(:last-of-type)': {
          marginRight: '1.5rem',
        },
      },
    },
  },
  tab: {
    textTransform: 'initial',
  },
  tabsContainer: {
    borderBottom: 1,
    borderColor: 'divider',
    display: 'flex',
    '& .MuiTabs-flexContainer': {
      justifyContent: 'space-between',
    },
  },
  userInfo: {
    container: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      marginBottom: '1.5rem',
      paddingTop: '0.125rem',
      '& > h6': {
        marginBottom: '0.5rem',
      },
    },
    profilePicture: {
      marginBottom: '2.125rem',
      height: '7.5rem',
      width: '7.5rem',
    },
  },
  preferences: {
    container: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    toggleContainer: {
      display: 'flex',
      alignItems: 'start',
      flexDirection: 'column',
      width: '50%',
    },
    switchContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
    },
    descriptionText: {
      width: '75%',
    },
  },
};
