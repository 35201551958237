import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ClearIcon from '@mui/icons-material/Clear';
import {
  Box,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  TextField,
  type SelectChangeEvent,
} from '@mui/material';
import _ from 'lodash';
import {
  ReactNode,
  useEffect,
  useMemo,
  useRef,
  useState,
  type ChangeEvent,
} from 'react';

import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import {
  MAX_CUSTOM_ROLE_CHAR,
  MOBILE_INPUT_LABEL,
  MOBILE_INPUT_PLACEHOLDER,
  ROLE_OTHER,
} from '../../../constants/profileConstants';
import {
  EMAIL_ADDRESS_REGEX,
  NON_NUMERIC_REGEX,
} from '../../../constants/regexConstants';
import MaskedInput from '../../../components/MaskedInput';
import { personalInfoValidationMessages } from '../../../constants/validationMessageConstants';
import FlatButton from '../../../components/FlatButton';
import { getPracticeRoleList } from '../../../store/actions/practiceActions';
import { updatePersonalInfo } from '../../../store/actions/userActions';
import {
  personalInformationFields,
  type PersonalInformationFields,
  type PersonalInformationForm,
} from '../../../store/reduxConstants/userReduxConstants';
import { profileStyle } from '../../../style/profileStyle';
import {
  getEnvMobileConfig,
  removeCountryCode,
} from '../../../helpers/profileHelper';

export const PersonalInformation = () => {
  const dispatch = useAppDispatch();
  const { mobileMaskFormat } = getEnvMobileConfig();

  const { isUpdatePersonalInfoLoaderActive } = useAppSelector(
    ({ generalLoaderReducer }: Record<string, any>) =>
      generalLoaderReducer ?? true
  );

  const { practiceIds, practiceRoles, userId, userInfo, userPracticeId } =
    useAppSelector((state) => ({
      practiceIds: state.practiceReducer.practiceIds,
      practiceRoles: state.practiceReducer.practiceRoles,
      userId: state.userReducer.id,
      userInfo: state.userReducer.userInfo,
      userPracticeId: state.userReducer.userInfo?.practiceId ?? '',
      userMobile: state.userReducer.userInfo?.mobile ?? '',
    }));

  const [submissionStatus, setSubmissionStatus] = useState<
    Record<'canSubmit' | 'hasClickedSubmit', boolean>
  >({
    canSubmit: false,
    hasClickedSubmit: false,
  });

  const customRolesMenuAnchor = useRef<HTMLDivElement>(null);
  const customRoleInputRef = useRef<HTMLElement>(null);
  const [isCustomRole, setIsCustomRole] = useState<boolean>(false);
  const [isCustomRolesMenuOpen, setIsCustomRolesMenuOpen] =
    useState<boolean>(false);

  const [formFields, setFormFields] = useState<PersonalInformationForm>({
    firstName: userInfo?.firstName ?? '',
    lastName: userInfo?.lastName ?? '',
    email: userInfo?.email ?? '',
    role: userInfo?.role ?? '',
    mobile: removeCountryCode(userInfo?.mobile ?? '') || '',
  });

  const [validationMessageKeys, setValidationMessageKeys] = useState<
    Record<
      PersonalInformationFields,
      keyof typeof personalInfoValidationMessages | null
    >
  >({
    firstName: null,
    lastName: null,
    email: null,
    role: null,
    mobile: null,
  });

  const roleList = useMemo<readonly string[]>(() => {
    if (!practiceIds.includes(userPracticeId)) return [];

    return isCustomRole
      ? practiceRoles[userPracticeId]
      : [...practiceRoles[userPracticeId], ROLE_OTHER];
  }, [practiceIds, practiceRoles, userPracticeId, isCustomRole]);

  useEffect(() => {
    if (practiceIds.includes(userPracticeId)) return;

    // Get practice information if it does not exist
    dispatch(getPracticeRoleList(userPracticeId));
  }, [dispatch, practiceIds, userPracticeId]);

  useEffect(() => {
    function isFormComplete(): boolean {
      for (const field of personalInformationFields) {
        if (
          (field !== 'mobile' && formFields[field] === '') ||
          validationMessageKeys[field] !== null
        )
          return false;
      }

      return true;
    }

    const canSubmit = isFormComplete();
    setSubmissionStatus((prevState) => ({ ...prevState, canSubmit }));
  }, [formFields, validationMessageKeys]);

  function getValidationKey<
    K extends keyof PersonalInformationForm,
    V extends PersonalInformationForm[K]
  >(key: K, value: V): keyof typeof personalInfoValidationMessages | null {
    const isValueEmpty = value === '';

    if (key === 'firstName') {
      if (isValueEmpty) return 'EMPTY_FIRST_NAME';
    }

    if (key === 'lastName') {
      if (isValueEmpty) return 'EMPTY_LAST_NAME';
    }

    if (key === 'email') {
      if (isValueEmpty) return 'EMPTY_EMAIL';
      if (!EMAIL_ADDRESS_REGEX.test(value)) return 'INVALID_EMAIL';
    }

    if (key === 'role') {
      if (isValueEmpty) return 'EMPTY_ROLE';
    }

    if (key === 'mobile' && !isValueEmpty) {
      const enteredValueLength = value.replace(NON_NUMERIC_REGEX, '').length;
      const expectedLength = mobileMaskFormat.replace(
        NON_NUMERIC_REGEX,
        ''
      ).length;

      if (enteredValueLength !== expectedLength) {
        return 'INVALID_MOBILE';
      }
    }

    return null;
  }

  useEffect(() => {
    const { email, role, lastName, firstName, mobile } = formFields;

    setValidationMessageKeys({
      email: getValidationKey('email', email),
      firstName: getValidationKey('firstName', firstName),
      lastName: getValidationKey('lastName', lastName),
      role: getValidationKey('role', role),
      mobile: getValidationKey('mobile', mobile),
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formFields]);

  function onChangeState(formKey: PersonalInformationFields) {
    return (
      e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent
    ): void => {
      const fieldValue = e.target.value;
      setFormFields((prevState) => ({
        ...prevState,
        [formKey]: fieldValue,
      }));
    };
  }

  function handleRoleChange(selectedRole: string) {
    if (selectedRole === ROLE_OTHER) {
      setFormFields((prevState) => ({
        ...prevState,
        role: '',
      }));

      setIsCustomRole(true);

      return;
    }

    setFormFields((prevState) => ({
      ...prevState,
      role: selectedRole,
    }));
  }

  function onDeleteCustomRole(): void {
    handleRoleChange('');
  }

  function handleToggleCustomRoleMenu(): void {
    setIsCustomRolesMenuOpen(!isCustomRolesMenuOpen);
  }

  function renderRoleList(): ReturnType<typeof MenuItem>[] {
    return roleList.map((role: string) => (
      <MenuItem
        key={role}
        value={role}
        onClick={
          isCustomRole
            ? () => {
                setFormFields((prevState) => ({
                  ...prevState,
                  role: role,
                }));
                setIsCustomRole(false);
                handleToggleCustomRoleMenu();
              }
            : undefined
        }
      >
        {role}
      </MenuItem>
    ));
  }

  function renderCustomRoleInputAdornment(): ReactNode {
    return (
      <InputAdornment position='end'>
        {isCustomRole ? (
          <IconButton
            onClick={
              _.isEmpty(formFields.role)
                ? handleToggleCustomRoleMenu
                : onDeleteCustomRole
            }
            disableRipple
          >
            {!_.isEmpty(formFields.role) ? (
              <ClearIcon />
            ) : isCustomRolesMenuOpen ? (
              <ArrowDropUpIcon />
            ) : (
              <ArrowDropDownIcon />
            )}
          </IconButton>
        ) : null}
      </InputAdornment>
    );
  }

  function initialiseSubmit(): void {
    // Start displaying error messages after clicking submit
    setSubmissionStatus((prevState) => ({
      ...prevState,
      hasClickedSubmit: true,
    }));
  }

  function onSubmitForm(): void {
    if (!submissionStatus.hasClickedSubmit) initialiseSubmit();
    // Do nothing if validation failed
    if (!submissionStatus.canSubmit) return;
    dispatch(updatePersonalInfo(userId, formFields));
  }

  function getErrorMessage(formKey: keyof PersonalInformationForm): string {
    const validationKey = validationMessageKeys[formKey];
    if (!userInfo?.role && formKey === 'role' && !!validationKey) {
      return personalInfoValidationMessages[validationKey];
    }

    if (!submissionStatus.hasClickedSubmit || !validationKey) return '';
    return personalInfoValidationMessages[validationKey];
  }

  useEffect(() => {
    if (isCustomRole && _.isEmpty(formFields.role)) {
      customRoleInputRef.current?.focus();
    }
  }, [isCustomRole, formFields.role]);

  return (
    <Box sx={profileStyle.form.container}>
      <Box sx={profileStyle.form.textFieldContainer}>
        <TextField
          helperText={getErrorMessage('firstName')}
          label='First name'
          onChange={onChangeState('firstName')}
          value={formFields.firstName}
        />
        <TextField
          helperText={getErrorMessage('lastName')}
          label='Last name'
          onChange={onChangeState('lastName')}
          value={formFields.lastName}
        />
        <TextField
          helperText={getErrorMessage('email')}
          label='Email'
          onChange={onChangeState('email')}
          value={formFields.email}
        />
        <TextField
          helperText={getErrorMessage('role')}
          label='Role'
          value={formFields.role}
          ref={customRolesMenuAnchor}
          inputRef={customRoleInputRef}
          onChange={(event) => handleRoleChange(event.target.value)}
          select={!isCustomRole}
          placeholder={isCustomRole ? ROLE_OTHER : ''}
          inputProps={{ maxLength: MAX_CUSTOM_ROLE_CHAR }}
          InputLabelProps={{
            shrink: (!isCustomRole && !!formFields.role) || isCustomRole,
          }}
          InputProps={{
            style: {
              paddingRight: 0,
            },
            endAdornment: renderCustomRoleInputAdornment(),
          }}
        >
          {renderRoleList()}
        </TextField>
        {isCustomRole && (
          <Menu
            anchorEl={customRolesMenuAnchor.current}
            open={isCustomRolesMenuOpen}
            onClose={handleToggleCustomRoleMenu}
            PaperProps={{
              style: {
                minWidth: '600px',
              },
            }}
          >
            {renderRoleList()}
          </Menu>
        )}
        <MaskedInput
          onChange={onChangeState('mobile')}
          value={formFields.mobile}
          mask={mobileMaskFormat}
          label={MOBILE_INPUT_LABEL}
          helperText={getErrorMessage('mobile')}
          placeHolder={MOBILE_INPUT_PLACEHOLDER}
          isLabelShrinked={!!formFields.mobile}
        />
      </Box>
      <FlatButton
        color='primary'
        sx={profileStyle.form.updateButton}
        onClick={onSubmitForm}
        variant='contained'
        isLoading={isUpdatePersonalInfoLoaderActive}
        data-testid='personalInfo-update-profile-btn'
      >
        Update profile
      </FlatButton>
    </Box>
  );
};
