import { generalErrorHandler } from '../../helpers/generalErrorHandlerHelper';
import { headerApiService } from '../../services/apiServices/headerApiService';
import { DOCUMENT_REDUX_CONSTANTS } from '../reduxConstants/documentsReduxConstants';
import { HEADER_REDUX_CONSTANTS } from '../reduxConstants/headerReduxConstants';
import { AppDispatch } from '../store';
import {
  startGeneralLoaderOnRequest,
  stopGeneralLoaderOnSuccessOrFail
} from './generalLoaderAction';

export const getMyNetworkSearchResultsAction = (
  searchTerm: string | null,
  setSearchResults: React.Dispatch<React.SetStateAction<Record<string, any>>>
) => {
  return async () => {
    try {
      startGeneralLoaderOnRequest('isHeaderSearchResultsLoaderActive');
      const response = await headerApiService.getMyNetworkSearchResults({
        searchTerm,
      });
      if (response?.status === 200) {
        let updatedData = response?.data?.data?.length
          ? response?.data?.data?.map((item: Record<string, any>) => {
              return {
                ...item,
                recordType: item?.IsPracticeResult ? 'Practice' : 'People',
              };
            })
          : [];
        updatedData.sort(
          (prev: Record<string, any>, curr: Record<string, any>) =>
            prev?.recordType === 'Practice' ? -1 : 1
        );

        setSearchResults(updatedData);
      }
    } catch (e: any) {
      generalErrorHandler(e);
    } finally {
      stopGeneralLoaderOnSuccessOrFail('isHeaderSearchResultsLoaderActive');
    }
  };
};

export const getDocumentSearchResultsAction = (searchText: string | null) => {
  return async (dispatch: AppDispatch) => {
    try {
      startGeneralLoaderOnRequest('isDocumentTableDetailsLoaderActive');
      const response = await headerApiService.getDocumentsSearchResults({
        searchText,
      });
      if (response?.status === 200) {
        dispatch({
          type: DOCUMENT_REDUX_CONSTANTS.ON_DOCUMENT_HEADER_SEARCH,
          payload: response.data,
        });
        dispatch({
          type: HEADER_REDUX_CONSTANTS.SET_HEADER_SEARCH_TEXT,
          payload: searchText,
        });
      }
    } catch (e: any) {
      generalErrorHandler(e);
    } finally {
      stopGeneralLoaderOnSuccessOrFail('isDocumentTableDetailsLoaderActive');
    }
  };
};

export const setHeaderSearchTextAction = (searchedText: string) => {
  return (dispatch: AppDispatch) => {
    dispatch({
      type: HEADER_REDUX_CONSTANTS.SET_HEADER_SEARCH_TEXT,
      payload: searchedText,
    });
  };
};
