import React from 'react';
import { downloadFile } from '../../../helpers/downloadHelper';
import { generalErrorHandler } from '../../../helpers/generalErrorHandlerHelper';
import { dashboardApiServices } from '../../../services/apiServices/dashboardApiServices';
import {
  EfaxTableDetailsType,
  PracticeDetails,
  ReceivedEfaxStatusFilter,
  SendFaxDetails,
} from '../../../types/efaxTypes';
import { AutocompleteOption } from '../../../types/Misc';
import { EFAX_REDUX_CONSTANTS } from '../../reduxConstants/efaxReduxConstants';
import { AppDispatch } from '../../store';
import {
  startGeneralLoaderOnRequest,
  stopGeneralLoaderOnSuccessOrFail,
} from '../generalLoaderAction';
import { setHeaderSearchTextAction } from '../headerActions';
import { showSnackbarAction } from '../snackbarAction';

export const getUserPracticeDetailsAction = (id: string) => {
  return async (dispatch: AppDispatch) => {
    try {
      startGeneralLoaderOnRequest('isGettingUserPracticeDetails');

      const response =
        await dashboardApiServices.myOffice.getOranizationDetails(id);

      if (response?.status === 200) {
        const practiceDetails: PracticeDetails = {
          practiceId: response?.data?.id,
          practiceName: response?.data?.Name,
          practicePhone: response?.data?.telephoneNumber,
          eFaxNumber: response?.data?.eFaxNumber,
        };

        dispatch({
          type: EFAX_REDUX_CONSTANTS.SET_USER_PRACTICE_DETAILS,
          payload: practiceDetails,
        });
      }
    } catch (e: any) {
      generalErrorHandler(e);
    } finally {
      stopGeneralLoaderOnSuccessOrFail('isGettingUserPracticeDetails');
    }
  };
};

export const sendEfaxAction = (
  faxDetails: SendFaxDetails,
  shouldSaveContact: boolean
) => {
  return async (dispatch: AppDispatch) => {
    try {
      startGeneralLoaderOnRequest('isSendingEfax');

      const response = await dashboardApiServices.efax.efaxTable.sendEfax(
        faxDetails
      );

      if (response?.status === 200) {
        const sendFaxResponseData = response?.data
          ?.data as EfaxTableDetailsType;

        if (sendFaxResponseData?.eFaxStatus !== 'failed') {
          // will call api and save recipient if not in contact address book
          if (shouldSaveContact) {
            await dashboardApiServices.efax.saveContact({
              PracticeId: sendFaxResponseData.recipientPracticeId,
              PracticeName: sendFaxResponseData.recipientPracticeName,
              EFaxNumber: sendFaxResponseData.recipientFaxNumber,
              SenderPracticeId: sendFaxResponseData.senderPracticeId,
            });
          }
        }

        dispatch({
          type: EFAX_REDUX_CONSTANTS.ON_CHANGE_SELECTED_TAB,
          payload: 1,
        });
      }
    } catch (e: any) {
      generalErrorHandler(e);
    } finally {
      dispatch({
        type: EFAX_REDUX_CONSTANTS.SET_IS_SEND_EFAX_DRAWER_OPEN,
      });
      stopGeneralLoaderOnSuccessOrFail('isSendingEfax');
    }
  };
};

export const getEfaxMessagesAction = () => {
  return async (dispatch: AppDispatch) => {
    try {
      startGeneralLoaderOnRequest('isGettingEfaxMessages');

      const response =
        await dashboardApiServices.efax.efaxTable.getEfaxMessages(0);

      if (response?.status === 200) {
        dispatch({
          type: EFAX_REDUX_CONSTANTS.GET_EFAX_LIST,
          payload: response.data?.data,
        });
      }
    } catch (e: any) {
      generalErrorHandler(e);
    } finally {
      stopGeneralLoaderOnSuccessOrFail('isGettingEfaxMessages');
    }
  };
};

export const getEfaxContactHistoryAction = () => {
  return async (dispatch: AppDispatch) => {
    try {
      startGeneralLoaderOnRequest('isGettingEfaxContactHistory');

      const response = await dashboardApiServices.efax.getEfaxContactHistory();

      if (response?.status === 200) {
        dispatch({
          type: EFAX_REDUX_CONSTANTS.SET_EFAX_CONTACT_HISTORY,
          payload: response.data?.data,
        });
      }
    } catch (e: any) {
      generalErrorHandler(e);
    } finally {
      stopGeneralLoaderOnSuccessOrFail('isGettingEfaxContactHistory');
    }
  };
};

export const toggleSendEfaxDrawer = () => {
  return (dispatch: AppDispatch) => {
    dispatch({
      type: EFAX_REDUX_CONSTANTS.SET_IS_SEND_EFAX_DRAWER_OPEN,
    });
  };
};

export const toggleOpenEfaxDrawer = (targetFax?: EfaxTableDetailsType) => {
  return (dispatch: AppDispatch) => {
    dispatch({
      type: EFAX_REDUX_CONSTANTS.TOGGLE_OPEN_EFAX_DRAWER,
      payload: targetFax,
    });
  };
};

export const onChangeEfaxTabAction = (selectedTab: 0 | 1) => {
  return (dispatch: AppDispatch) => {
    dispatch({
      type: EFAX_REDUX_CONSTANTS.ON_CHANGE_SELECTED_TAB,
      payload: selectedTab,
    });
    dispatch({
      type: EFAX_REDUX_CONSTANTS.SET_EFAX_TABLE_FILTER_OPTIONS,
    });
  };
};

export const downloadEfaxAction = async (fileName: string) => {
  try {
    const response =
      await dashboardApiServices.document.documentsTable.actions.downloadAttachment(
        fileName,
        {
          blobType: '0',
        }
      );
    if (response?.status === 200) {
      downloadFile(response, fileName);
    }
  } catch (e: any) {
    showSnackbarAction({
      open: true,
      statusCode: 404,
      message:
        e?.status === 404
          ? 'Document not found'
          : e?.ExceptionMessage ?? e?.Message,
      severity: 'error',
    });
  }
};

export const onEfaxTableFilterChangeAction = (filterData: {
  name: string;
  value: string | AutocompleteOption | ReceivedEfaxStatusFilter | null;
}) => {
  return (dispatch: AppDispatch) => {
    dispatch({
      type: EFAX_REDUX_CONSTANTS.ON_CHANGE_OR_APPLY_EFAX_TABLE_FILTER,
      payload: filterData,
    });
    dispatch(setHeaderSearchTextAction(''));
  };
};

// received efax status filter change
export const onEfaxStatusFilterChangeAction = (
  value: ReceivedEfaxStatusFilter
) => {
  return (dispatch: AppDispatch) => {
    dispatch({
      type: EFAX_REDUX_CONSTANTS.ON_RECEIVED_EFAX_STATUS_CHANGE,
      payload: value,
    });
    dispatch(setHeaderSearchTextAction(''));
  };
};

export const processEfaxAction = (
  faxId: string,
  setProcessRecordIndex: React.Dispatch<React.SetStateAction<string | null>>
) => {
  return async (dispatch: AppDispatch) => {
    try {
      startGeneralLoaderOnRequest('isEfaxProcessLoaderActive');
      const response = await dashboardApiServices.efax.efaxTable.processEfax(
        faxId
      );

      if (response?.status === 200) {
        dispatch({
          type: EFAX_REDUX_CONSTANTS.ON_PROCESS_EFAX,
          payload: faxId,
        });
      }
    } catch (e: any) {
      generalErrorHandler(e);
    } finally {
      setProcessRecordIndex(null);
      stopGeneralLoaderOnSuccessOrFail('isEfaxProcessLoaderActive');
    }
  };
};

export const resetEfaxFiltersAction = () => {
  return (dispatch: AppDispatch) => {
    dispatch({
      type: EFAX_REDUX_CONSTANTS.RESET_EFAX_FILTERS,
    });
  };
};
