import { createApi } from '@reduxjs/toolkit/query/react';
import { DASHBOARD_URLS } from 'constants/urlConstants';
import { Patient } from 'types/referralTypes/referralTypes';
import { AddNewPatientRequest } from 'types/referralTypes/addNewPatientRequest';
import baseQueryWithReauth from './serviceQuery';

/**
 * Patient API
 */
const patientService = createApi({
  reducerPath: 'patientApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (build) => ({
    getPatients: build.query<Patient[], void>({
      query: () => ({
        url: DASHBOARD_URLS.MY_NETWORK_URL.GET_RELATED_PATIENTS,
        method: 'GET',
      }),
      transformResponse: (data: Patient[]) => {
        let list: Patient[] = data ?? [];

        if (list.length > 1) {
          list.sort((a, b) => a.firstName.localeCompare(b.firstName));
        }

        return list;
      },
    }),
    addNewPatient: build.mutation<Patient, AddNewPatientRequest>({
      query: ({ firstName, lastName, dateOfBirth }) => ({
        url: DASHBOARD_URLS.MY_NETWORK_URL.ADD_NEW_PATIENT,
        method: 'POST',
        body: {
          firstName,
          lastName,
          dateOfBirth,
        },
      }),
    }),
    guestAddNewPatient: build.mutation<
      Patient,
      {
        data: AddNewPatientRequest;
        captchaToken: string | undefined;
        token: string | undefined;
      }
    >({
      query: ({ data, captchaToken, token }) => {
        const { firstName, lastName, dateOfBirth, organizationId, email } =
          data;
        return {
          url: `${DASHBOARD_URLS.DOCUMENTS_URL.GUEST_WEBSITE_REFERRAL_ADD_PATIENT}?token=${token}`,
          method: 'POST',
          body: {
            firstName,
            lastName,
            dateOfBirth,
            organizationId,
            captchaToken,
            email,
          },
        };
      },
    }),
  }),
});

export const {
  useGetPatientsQuery,
  useAddNewPatientMutation,
  useGuestAddNewPatientMutation,
} = patientService;

export default patientService;
