import {
  Avatar,
  Box,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from '@mui/material';
import { useRef, useState } from 'react';
import LinesEllipsis from 'react-lines-ellipsis';
import { v4 as uuidv4 } from 'uuid';
import FlatButton from '../../../components/FlatButton';
import { uppercaseInitials } from '../../../helpers/getFirstTwoLetters';
import { commonClasses } from '../../../style/commonClasses';
import { myNetworkStyle } from '../../../style/dashboardStyles/myNetworkStyle';
import { initialCircleColorArray } from '../../../theme/colorPalette';

export type PracticeItemActionButtonType = {
  title: string;
  variant: 'text' | 'outlined' | 'contained' | undefined;
  testId: string;
  onClick: (id: string) => void;
  isLoaderActive: boolean;
};

type PracticeItemType = {
  practiceItem: {
    id: string;
    userName: string;
    profilePicture: string;
    practiceType: string;
    address: string;
    practiceName: string;
    message?: string;
  };
  index: number;
  actionButtons: PracticeItemActionButtonType[];
  invitationSentToPractices?: string[];
  isForInvitationList?: boolean;
};

const PracticeItem = (props: PracticeItemType) => {
  const messageRef = useRef<Array<HTMLDivElement | null>>([]);
  const {
    practiceItem,
    index,
    actionButtons,
    invitationSentToPractices,
    isForInvitationList,
  } = props;
  const [clickedButtonId, setClickedButtonId] = useState<string | null>(null);
  const {
    id,
    userName,
    profilePicture,
    practiceType,
    address,
    practiceName,
    message,
  } = practiceItem;

  const onClickActionButton = (
    id: string,
    buttonClick: (id: string) => void
  ) => {
    buttonClick(id);
    setClickedButtonId(id);
  };

  return (
    <ListItem sx={commonClasses.practiceCard.practiceListItem}>
      <ListItemAvatar>
        <Avatar
          sizes='0.25rem'
          alt={userName}
          src={profilePicture}
          sx={[
            myNetworkStyle.practiceAvatar,
            {
              bgcolor:
                initialCircleColorArray[
                  Math.floor(Math.random() * initialCircleColorArray.length)
                ],
            },
          ]}
        >
          {!!practiceName && uppercaseInitials(practiceName)}
        </Avatar>
      </ListItemAvatar>

      <ListItemText
        secondary={
          <>
            <Box sx={myNetworkStyle.practicItemContainer}>
              <Box sx={{ flex: 1 }}>
                <Box sx={commonClasses.cardTitle}>{practiceName}</Box>
                {isForInvitationList ? (
                  userName
                ) : (
                  <Box sx={myNetworkStyle.practiceItemSeondaryData}>
                    {practiceType}{' '}
                    <Box sx={commonClasses.practiceCard.bulletPoint} />
                    {address}
                  </Box>
                )}
              </Box>
              <Box
                ref={(el: HTMLDivElement) => (messageRef.current[index] = el)}
                sx={myNetworkStyle.practiceItemButtonContainer}
              >
                {invitationSentToPractices?.includes(id) ? (
                  <Box sx={commonClasses.cardTag}>Request Sent</Box>
                ) : (
                  actionButtons.map((button: PracticeItemActionButtonType) => (
                    <FlatButton
                      key={uuidv4()}
                      variant={button.variant}
                      data-testid={`${button.testId}-${id}`}
                      onClick={() => onClickActionButton(id, button.onClick)}
                      isLoading={
                        button.isLoaderActive && clickedButtonId === id
                      }
                    >
                      {button.title}
                    </FlatButton>
                  ))
                )}
              </Box>
            </Box>
            {message && (
              <Box sx={myNetworkStyle.lineClampedMessage}>
                <LinesEllipsis id={uuidv4()} text={message} maxLine={2} />
              </Box>
            )}
          </>
        }
      />
    </ListItem>
  );
};

export default PracticeItem;
