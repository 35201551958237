import { SystemStyleObject, Theme } from '@mui/system';
import { Moment } from 'moment';
import React from 'react';
import { FileError } from 'react-dropzone';
import { AutocompleteOption } from './Misc';

export type DocumentTab = {
  name: string;
  value: 0 | 1;
};

export type DocumentTabs = {
  received: DocumentTab;
  sent: DocumentTab;
};

export type SendDocumentsDialogProps = {
  isSendDocumentsDialogOpen: boolean;
  setIsSendDocumentsDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onSendDocument: (
    setIsFormSubmittedOnce: React.Dispatch<React.SetStateAction<boolean>>
  ) => void;
};

export type PracticeOptionUI = {
  id: string;
  name: string;
  category?: string;
  orgType?: string;
  email?: string;
  isConnectedPractice: boolean;
  isNewEmailRecipient?: boolean;
  searchedEmailPracticeName?: string;
  type?: string;
};

export type PatientOptionUI = {
  label: string;
  value: string;
  birthDate?: string;
};

export type SendDocumentFormDataType = {
  recipients: PracticeOptionUI[];
  patient: {
    dateOfBirth: string;
    firstName: string;
    lastName: string;
  };
  message?: string;
  attachments: {
    attachmentUrl: string;
    fileName: string;
  }[];
};

export type DocumentReducer = {
  allDocumentList: DocumentsTableDetailsType[];
  documentList: DocumentsTableDetailsType[];
  paginatedList: DocumentsTableDetailsType[];
  filteredAllDocList: DocumentsTableDetailsType[];
  selectedTab: 0 | 1;
  patientFilterOptions: DocumentPatientFilter[];
  senderOptions: string[];
  connectedPractices: any;
  practiceOptions: AutocompleteOption[];
  connectedPracticesOptions: any;
  patientOptions: PatientOptionUI[];
  appliedFilters: DocumentTableFilters;
  receivedDocumentStatusFilter: ReceivedDocumentStatusFilter;
  sendDocumentFormData: SendDocumentFormDataType;
  sendDocumentStep: 1 | 2;
  recipientsData: AddContactFormData[];
  sendDocumentStatusList: SendDocumentRecordStatus[];
  addressBookContacts: PracticeOptionUI[];
  newRecipientFormData: AddContactFormData;
  practiceDirectoryRecipients: PracticeDirectoryRecipient[];
  practiceDirectoryPractices: PracticeDirectoryPractice[];
  sortedPracticeDirectory: SortedPracticeDirectory[];
};

// to display in send documents step one
export type SortedPracticeDirectory =
  | PracticeDirectoryRecipient
  | PracticeDirectoryPractice;

export type PracticeDirectoryRecipient = {
  id: string;
  PracticeName: string;
  Email: string;
};

export type PracticeDirectoryPractice = {
  id: string;
  Name: string;
  MyNetwork: {
    name: string | null;
    orgID: string;
    profileImage: string | null;
  }[];
  DoctorsNames: string[];
};

export type Patient = {
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  organizationId: string;
  type: string;
  id: string;
  _ts: number;
};

export type NewPatient = {
  firstName: string;
  lastName: string;
  dateOfBirth: Moment | null;
} | null;

export type SendDocumentsForm = {
  practice: AutocompleteOption | null;
  patient: string | null;
  documentType: 'Document' | 'Referral' | null;
  message?: string;
};

export type FileConfig = {
  headers: {
    'content-type': string;
  };
};

// upload document component props type
export type UploadDocumentsProps = {
  selectedFiles: Thumbnail[];
  setSelectedFiles: React.Dispatch<React.SetStateAction<Thumbnail[]>>;
  totalFilesSize: number;
  setTotalFilesSize: React.Dispatch<React.SetStateAction<number>>;
  setUploadedFiles: React.Dispatch<React.SetStateAction<UploadedDocument[]>>;
  fileSelectedForDeletion?: string | null;
  onClickDeleteFile?: (fileName: string) => void;
  containerStyle?: SystemStyleObject<Theme>;
  dragAndDropMessage?: React.ReactNode;
  disclaimerMessage?: string;
  shouldHideDrapDropBox?: boolean;
  customAdditionalFileMessage?: string;
  blobType?: string;
};

export type UploadedDocument = {
  fileName: string;
  attachmentUrl: string;
};

export type AddContactErrors = {
  PracticeName?: string | null;
  Email?: string | null;
};

export type SendDocumentErrors = {
  recipients?: string | null;
  patientFirstName?: string | null;
  patientLastName?: string | null;
  patientDateOfBirth?: string | null;
  attachments?: string | null;
};

export type SearchedPracticeNetworkType = {
  name: string | null;
  orgID: string;
  profileImage: string;
};

export type SearchedPracticeDetailsType = {
  Category: string;
  MyNetwork: SearchedPracticeNetworkType[];
  Name: string;
  OrganizationType: string;
  id: string;
};

export type SearchedPracticeRecipientsType = {
  id: string;
  Email: string;
  FirstName: string;
  LastName: string;
  Title: string;
  PracticeName: string;
};

export type SearchPracticeResponse = {
  Practices: SearchedPracticeDetailsType[];
  Recipients: SearchedPracticeRecipientsType[];
};

export type SendDocumentsRequestData = {
  toPracticeId: string;
  patientTag: string;
  attachments: UploadedDocument[];
  patientDateOfBirth?: string;
  notes?: string;
  secureEmailAddress?: string;
  secureEmailReceiverName?: string;
  approved?: boolean;
};

export interface Thumbnail extends File {
  originalName: string;
}

export type SendDocumentRequiredFields = {
  practice: PracticeOptionUI | null;
  patient: {
    name: string;
    dateOfBirth?: string;
  };
  notes?: string;
  isAnyDocumentUploaded?: boolean | string | null;
};

export type TableDetailsAttachment = {
  fileName: string;
  attachmentUrl: string;
  thumbnailUrl?: string;
};

export type DocumentStatus = 'Processed' | 'NotProcessed';

export type DocumentPatientFilter = {
  patientTag: string;
  patientDateOfBirth?: string | null;
  isReferral: boolean;
};

export type DocumentsTableDetailsType = {
  id: string;
  toPracticeId: string;
  toPracticeName: string;
  fromUserDisplayName: string;
  fromUserId: string;
  fromPracticeId: string;
  fromPracticeName: string;
  fromUserRole?: string;
  fromUserProfileImage?: string;
  toDoctorDisplayName?: string;
  ccPractices: {
    id: string;
    name: string;
  }[];
  attachments: TableDetailsAttachment[];
  patientTag: string;
  patientDateOfBirth: string | null;
  recievedOn: string;
  documentStatus: DocumentStatus;
  processedBy: string;
  processedDate: string;
  isReferral: boolean;
  referralFileName: string;
  toPracticeIsDeleted: boolean;
  fromPracticeIsDeleted: boolean;
  notes: string;
  secureEmailAddress: string | null;
  secureEmailOpened?: boolean;
  secureEmailReceiverName?: string;
  approved: boolean;
  guestReferralStatus: number | null;
  guestSenderEmailAddress?: string;
  secureEmailToken?: string;
};

export type DocumentsTableDetailsDeleteRecordRequestBody = {
  id: string;
  officeFileType: 0 | 1;
  isReferral: boolean;
};

export type DocumentTableFilters = {
  patient: string | null;
  practice: AutocompleteOption | null;
  sender: string | null;
  documentType: 'Document' | 'Referral' | 'Website Referral' | 'All';
};

export type ReceivedDocumentStatusFilter = 'Processed' | 'Unprocessed' | 'All';

export type PracticeTeamMember = {
  admin: boolean;
  billingAdmin: boolean;
  email: string;
  firstName: string;
  lastName: string;
  personID: string;
  practiceOwner: boolean;
  profileImage: string;
  role: string;
  status: string;
  userType: string;
};

export type ForwardDocumentsRequestBody = {
  toUserId: string;
  toPracticeId: string;
  sharedDocumentId: string;
};

export type RejectedFile = {
  file: File;
  errors: FileError[];
};

export type AddPatientRequestData = {
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  organizationId?: string;
};

export type SendQuickMessageDialogProps = {
  recordSelectedForQuickMessage: DocumentsTableDetailsType;
  setRecordSelectedForQuickMessage: React.Dispatch<
    React.SetStateAction<DocumentsTableDetailsType | null>
  >;
  anchorElement: HTMLAnchorElement | HTMLSpanElement | null;
};

export type AddContactDialogFormProps = {
  isNewContactAddition?: boolean;
  isFormSubmittedOnce: boolean;
  formData?: {
    name?: string;
    email?: string;
  };
};

export type Recipient = {
  id: string;
  name: string;
  email: string;
  isConnectedPractice: boolean;
  isNewEmailRecipient: boolean;
};

export type AddContactDialogProps = {
  isDialogOpen: boolean;
  setIsDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export type AddContactRequestData = {
  PracticeName: string;
  Email: string;
  Type: 'practice' | 'recipient';
};

export type AddContactFormData = {
  PracticeName: string;
  Email: string;
};

export type SendDocumentRecordStatus = SendDocumentsRequestData & {
  name: string;
  status: 'inProgress' | 'done' | 'failed';
};

export type GetRecipientsResponseData = {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  title: string;
  practiceName: string;
  contactDetails: string;
  isGlobal: boolean;
  createdDate: string;
  senderId: string;
  senderDetails: string;
  senderPracticeId: string;
  type: string;
  mobileNumber: string;
};

export type SendDocumentsSelectRecipientsDialogProps = {
  openDialog: boolean;
  setSendDocumentsSelectRecipientsDialog: React.Dispatch<
    React.SetStateAction<boolean>
  >;
};

export type PracticeRecipientData = {
  contactDetails: string;
  createdDate: string;
  email: string;
  firstName: string;
  id: string;
  isGlobal: boolean;
  lastName: string;
  mobileNumber: string;
  practiceName: string;
  senderDetails: string;
  senderId: string;
  senderPracticeId: string;
  title: string;
  type: string;
};

export enum FileTypes {
  IMAGE = 'IMAGE',
  PDF = 'PDF',
  WORD = 'WORD',
  EXCEL = 'EXCEL',
  CSV = 'CSV',
  POWERPOINT = 'POWERPOINT',
  TEXT = 'TEXT',
  JSON = 'JSON',
  COMPRESSED = 'COMPRESSED',
  UNKNOWN = 'UNKNOWN',
}
