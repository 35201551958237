import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useAppSelector } from '../../../../app/hooks';
import { deleteDocumentAction } from '../../../../store/actions/dashboardActions/documentActions';
import { documentStyle } from '../../../../style/dashboardStyles/documentStyle';
import {
  SendDocumentErrors,
  Thumbnail,
  UploadedDocument,
} from '../../../../types/documentsTypes';
import UploadDocuments from '../uploadDocuments/UploadDocuments';

const SendDocumentsDialogRightPart = ({
  setUploadedFiles,
  selectedFiles,
  setSelectedFiles,
  errors,
}: {
  setUploadedFiles: React.Dispatch<React.SetStateAction<UploadedDocument[]>>;
  selectedFiles: Thumbnail[];
  setSelectedFiles: React.Dispatch<React.SetStateAction<Thumbnail[]>>;
  errors: SendDocumentErrors;
}) => {
  const { isUploadDocumentLoaderActive, isSendDocumentsLoaderActive } =
    useAppSelector(
      ({ generalLoaderReducer }: Record<string, any>) =>
        generalLoaderReducer ?? true
    );

  const [totalFilesSize, setTotalFilesSize] = useState<number>(0);
  const [fileSelectedForDeletion, setFileSelectedForDeletion] = useState<
    string | null
  >(null);

  const onClickDeleteFile = (fileName: string) => {
    deleteDocumentAction(fileName, setSelectedFiles, setUploadedFiles);
    setFileSelectedForDeletion(fileName);
  };

  useEffect(() => {
    // Revoke the data uris to avoid memory leaks, will run on unmount
    return () =>
      selectedFiles?.forEach((file) =>
        URL.revokeObjectURL(URL.createObjectURL(file))
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      sx={[
        documentStyle.sendDocumentsDialog.body.rightPart,
        { alignItems: isUploadDocumentLoaderActive ? 'flex-start' : 'center' },
      ]}
    >
      <UploadDocuments
        selectedFiles={selectedFiles}
        setSelectedFiles={setSelectedFiles}
        totalFilesSize={totalFilesSize}
        setTotalFilesSize={setTotalFilesSize}
        setUploadedFiles={setUploadedFiles}
        onClickDeleteFile={
          isSendDocumentsLoaderActive ? undefined : onClickDeleteFile
        }
        fileSelectedForDeletion={fileSelectedForDeletion}
        shouldHideDrapDropBox={isSendDocumentsLoaderActive}
        disclaimerMessage='Note: Each document should not exceed 20mb'
      />
      {!!errors?.attachments && !!!selectedFiles?.length && (
        <Box
          position='absolute'
          bottom={16}
          left={16}
          right={16}
          color='error.main'
          typography='caption'
          textAlign='center'
        >
          {errors?.attachments}
        </Box>
      )}
    </Box>
  );
};

export default SendDocumentsDialogRightPart;
