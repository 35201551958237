import type { LoginResponse } from '../../types/apiResponses/UserApiResponses';
import type { Action } from '../../types/Redux';

// Actions that are not wrapped in redux-thunk
export const UserLocalActions = {
  CLEAR_USER_INFO: '@user/CLEAR_USER_INFO',
  SET_USER_INFO: '@user/SET_USER_INFO',
} as const;

// Actions with API connection
export const UserApiActions = {
  SYNC_USER_BY_ID_START: '@user/SYNC_USER_BY_ID_START',
  SYNC_USER_BY_ID_SUCCESS: '@user/SYNC_USER_BY_ID_SUCCESS',
  SYNC_USER_BY_ID_ERROR: '@user/SYNC_USER_BY_ID_ERROR',
  UPDATE_PERSONAL_INFO_START: '@user/UPDATE_PERSONAL_INFO_START',
  UPDATE_PERSONAL_INFO_SUCCESS: '@user/UPDATE_PERSONAL_INFO_SUCCESS',
  UPDATE_PERSONAL_INFO_ERROR: '@user/UPDATE_PERSONAL_INFO_ERROR',
  UPDATE_PROFILE_IMG_START: '@user/UPDATE_PROFILE_IMG_START',
  UPDATE_PROFILE_IMG_SUCCESS: '@user/UPDATE_PROFILE_IMG_SUCCESS',
  UPDATE_PROFILE_IMG_ERROR: '@user/UPDATE_PROFILE_IMG_ERROR',
} as const;

export const personalInformationFields = [
  'firstName',
  'lastName',
  'email',
  'role',
  'mobile',
] as const;
export type PersonalInformationFields =
  typeof personalInformationFields[number];
export type PersonalInformationForm = Record<PersonalInformationFields, string>;

export type PersonType = 'person';

// This info originally comes from the login response, but may also be retrieved
// from the local storage
export type UserInfo = Pick<
  LoginResponse,
  | 'firstName'
  | 'lastName'
  | 'practiceId'
  | 'practiceName'
  | 'practiceType'
  | 'profileImage'
  | 'role'
  | 'mobile'
> & { email: string; isSmsNotificationTurnedOn?: boolean };

export type UserReducerState = {
  id: string;
  userInfo: UserInfo | undefined;
};

// Local
export type ClearUserInfo = Action<typeof UserLocalActions.CLEAR_USER_INFO>;
export type SetUserInfo = Action<
  typeof UserLocalActions.SET_USER_INFO,
  { id: string; userInfo: UserInfo }
>;

// With API
export type SyncUserByIdStart = Action<
  typeof UserApiActions.SYNC_USER_BY_ID_START
>;
export type SyncUserByIdSuccess = Action<
  typeof UserApiActions.SYNC_USER_BY_ID_SUCCESS,
  UserInfo
>;
export type SyncUserByIdError = Action<
  typeof UserApiActions.SYNC_USER_BY_ID_ERROR
>;
export type UpdatePersonalInfoStart = Action<
  typeof UserApiActions.UPDATE_PERSONAL_INFO_START
>;
export type UpdatePersonalInfoSuccess = Action<
  typeof UserApiActions.UPDATE_PERSONAL_INFO_SUCCESS,
  Pick<UserInfo, 'firstName' | 'lastName' | 'role' | 'email' | 'mobile'>
>;
export type UpdatePersonalInfoError = Action<
  typeof UserApiActions.UPDATE_PERSONAL_INFO_ERROR
>;
export type UpdateProfileImageStart = Action<
  typeof UserApiActions.UPDATE_PROFILE_IMG_START
>;
export type UpdateProfileImageSuccess = Action<
  typeof UserApiActions.UPDATE_PROFILE_IMG_SUCCESS,
  Pick<UserInfo, 'profileImage'>
>;
export type UpdateProfileImageError = Action<
  typeof UserApiActions.UPDATE_PROFILE_IMG_ERROR
>;

export type UserActionTypes =
  | ClearUserInfo
  | SetUserInfo
  | SyncUserByIdStart
  | SyncUserByIdSuccess
  | SyncUserByIdError
  | UpdatePersonalInfoStart
  | UpdatePersonalInfoSuccess
  | UpdatePersonalInfoError
  | UpdateProfileImageStart
  | UpdateProfileImageSuccess
  | UpdateProfileImageError;
