import { createTheme } from '@mui/material';
import { colorPalette } from './colorPalette';

export const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: colorPalette.primaryMain,
      light: colorPalette.primaryLight,
      dark: colorPalette.primaryDark,
    },
    secondary: {
      main: colorPalette.secondary,
    },
    error: {
      main: colorPalette.error,
    },
    success: {
      main: colorPalette.success,
      light: colorPalette.successLight,
      dark: colorPalette.successDark,
    },
    text: {
      primary: colorPalette.textPrimary,
      secondary: colorPalette.textSecondary,
      disabled: colorPalette.textDisabled,
    },
    background: {
      paper: colorPalette.backgroundPaper,
      default: colorPalette.backgroundDefault,
    },
  },
  typography: {
    fontFamily: 'Inter',
  },
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          h1: 'h1',
          h2: 'h2',
          h3: 'h3',
          h4: 'h4',
          h5: 'h5',
          h6: 'h6',
          subtitle1: 'div',
          subtitle2: 'div',
          body1: 'div',
          body2: 'div',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          minHeight: 40,
          fontSize: 16,
          borderRadius: 100,
          boxShadow: '2px 2px 6px rgba(0, 0, 0, 0.25)',
          textTransform: 'inherit',
          whiteSpace: 'nowrap',
          '&:hover': {
            boxShadow: 'none',
          },
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        root: {
          '& > .MuiPaper-root': {
            backgroundColor: colorPalette.backgroundDefault,
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiFormHelperText-root': {
            position: 'absolute',
            top: '100%',
            color: colorPalette.error,
          },
          '&.subvariant-flat': {
            '& fieldset': {
              border: 'none',
            },
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        paper: {
          fontSize: 14,
          backgroundColor: colorPalette.backgroundDefault,
        },
        groupLabel: {
          backgroundColor: colorPalette.primaryMain,
          color: colorPalette.backgroundDefault,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          lineHeight: '1.7rem',
        },
        // Set the font size for the MuiInputLabel's &.MuiInputLabel-shrink class to match (This sets the shrunken text size):
        shrink: {
          fontSize: '.875rem',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        // Set the font size for the OutlinedInput's notchedOutline class (This sets the width of the notch):
        notchedOutline: {
          fontSize: '.875em',
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: colorPalette.primaryLight,
          },
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        container: {
          display: 'flex',
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          minWidth: '27.5rem',
          padding: 0,
          borderRadius: '1.5rem',
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        asterisk: {
          color: '#BA1B1B',
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          color: colorPalette.primaryLight,
          backgroundColor: colorPalette.primaryMain,
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          paddingTop: '2rem',
          paddingLeft: '2rem',
          paddingRight: '2rem',
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          paddingLeft: '2rem',
          paddingRight: '2rem',
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          '>:not(:first-of-type)': {
            marginLeft: '1rem',
          },
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          color: colorPalette.sliderForeground,
        },
        thumb: {
          backgroundColor: colorPalette.sliderForeground,
        },
        track: {
          backgroundColor: colorPalette.sliderForeground,
        },
        rail: {
          backgroundColor: colorPalette.sliderBackground,
          opacity: 1,
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          fontWeight: 700,
        },
        root: {
          verticalAlign: 'top',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: colorPalette.backgroundPaper,
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: '.875rem',
          margin: '0 .5rem',
          borderRadius: '0.5rem',
          '&:hover': {
            backgroundColor: colorPalette.primaryLight,
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '0.8rem',
          padding: '0.5rem',
        },
      },
    },
  },
});

theme.typography = {
  ...theme.typography,
  h1: {
    fontSize: 96,
    lineHeight: '7rem',
    letterSpacing: '-0.094rem',
  },
  h2: {
    fontSize: 60,
    lineHeight: '4.5rem',
    letterSpacing: '-0.031rem',
  },
  h3: {
    fontSize: 48,
    lineHeight: '3.5rem',
  },
  h4: {
    fontSize: 34,
    lineHeight: '2.25rem',
  },
  h5: {
    fontSize: 24,
    lineHeight: '1.5rem',
    letterSpacing: '0.011rem',
  },
  h6: {
    fontSize: 20,
    lineHeight: '1.5rem',
    letterSpacing: '0.009rem',
  },
  subtitle1: {
    fontSize: 16,
    lineHeight: '1.5rem',
    letterSpacing: '0.009rem',
  },
  subtitle2: {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: '1.5rem',
    letterSpacing: '0.006rem',
  },
  body1: {
    fontSize: 16,
    lineHeight: '1.5rem',
    letterSpacing: '0.031rem',
  },
  body2: {
    fontSize: 14,
    lineHeight: '1.125rem',
    letterSpacing: '0.016rem',
  },
  caption: {
    fontSize: 12,
    lineHeight: '1rem',
    letterSpacing: '0.025rem',
  },
  overline: {
    fontSize: 10,
    fontWeight: 500,
    lineHeight: '1rem',
    letterSpacing: '0.025rem',
  },
};
