import {
  Autocomplete,
  Box,
  ButtonBase,
  Divider,
  TextField,
} from '@mui/material';
import { useGetPatientsQuery } from 'services/patientService';
import { REFERRAL_PAGE_TEST_CONSTANTS } from 'constants/testConstants';
import { Patient } from 'types/referralTypes/referralTypes';
import { commonClasses } from 'style/commonClasses';
import { HTMLAttributes } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { REFERRAL_STEPS } from 'constants/referralConstants';
import { setSelectedExistingPatient } from 'store/slices/referralSlice';
import { patientSelectBoxStyle } from 'style/dashboardStyles/referralStyle';
import PatientListItem from './PatientListItem';

export type PatientSelectBoxProps = {
  onClickAddNewPatientBtn: () => void;
};

const PatientSelectBox: React.FC<PatientSelectBoxProps> = ({
  onClickAddNewPatientBtn,
}) => {
  const dispatch = useAppDispatch();

  const {
    currentStep,
    selectedExistingPatient,
    patientStepFormErrors: formErrors,
  } = useAppSelector((state) => state.referralReducer);

  const { data: relatedPatients, isFetching: isFetchingRelatedPatients } =
    useGetPatientsQuery(undefined, {
      skip: currentStep === REFERRAL_STEPS.PRACTICE_DETAILS,
    });

  /**
   * Gets the label to be displayed for the selected patient option
   */
  const getPatientOptionLabel = (patient: Patient | string) => {
    return typeof patient === 'string'
      ? patient
      : `${patient?.firstName} ${patient?.lastName} ${new Date(
          patient?.dateOfBirth
        ).toLocaleDateString()}`;
  };

  /**
   * Returns an element to be rendered as the patient option in select box
   */
  const renderPatientOptions = (
    props: HTMLAttributes<HTMLLIElement>,
    patient: Patient
  ) => {
    return patient.firstName === 'new' ? (
      <Box key={uuidv4()} textAlign='center'>
        <Divider />
        <ButtonBase
          sx={patientSelectBoxStyle.addNewPatientDropdownOption}
          onClick={onClickAddNewPatientBtn}
        >
          ADD NEW PATIENT
        </ButtonBase>
      </Box>
    ) : (
      <PatientListItem props={props} patient={patient} />
    );
  };

  return (
    <Autocomplete
      data-testid={
        REFERRAL_PAGE_TEST_CONSTANTS.REFERRAL_PATIENT_DETAILS_PATIENT_SELECT_BOX
      }
      clearOnBlur
      value={selectedExistingPatient as Patient}
      fullWidth
      options={
        [...(relatedPatients ?? []), { id: 0, firstName: 'new' }] as Patient[]
      }
      loading={isFetchingRelatedPatients}
      onChange={(_event, value) => dispatch(setSelectedExistingPatient(value!))}
      sx={[patientSelectBoxStyle.patientDetailsSelectBox]}
      getOptionLabel={getPatientOptionLabel}
      renderOption={renderPatientOptions}
      renderInput={(params) => (
        <TextField
          {...params}
          required
          placeholder='Patient'
          label='Patient'
          sx={formErrors?.existingPatient ? commonClasses.fieldError : {}}
          helperText={formErrors?.existingPatient}
        />
      )}
    />
  );
};

export default PatientSelectBox;
