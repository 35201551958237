import { Action } from '../../types/Redux';

export const ONBOARDING_REDUX_CONSTANTS = {
  ON_SEARCH_ADDRESS: 'ON_SEARCH_ADDRESS',
  ON_UPDATE_SPECIALISTS_LIST: 'ON_UPDATE_SPECIALISTS_LIST',
  ONBOARDING_FORM_SUBMIT: 'ONBOARDING_FORM_SUBMIT',
} as const;

// this does not include everything yet
export type OnboardingDoneResponse = {
  id: string;
  firstName: string;
  hasFinishedSetup: boolean;
  lastName: string;
  organizations: ReadonlyArray<Record<'orgID' | 'orgPriority', string>>;
};

export type AddressLookup = {
  City: string;
  FormattedAddress: string;
  State: string;
  Zip: string;
};

export type AddressType = {
  city: string;
  state: string;
  zip: string;
  fullAddress?: string;
};

export type SpecialistType = {
  id: string;
  name: string;
  status: string;
  organizationType: string;
};

export type OnSearchAddress = Action<
  typeof ONBOARDING_REDUX_CONSTANTS.ON_SEARCH_ADDRESS,
  AddressType[]
>;
export type OnboardingFormSubmit = Action<
  typeof ONBOARDING_REDUX_CONSTANTS.ONBOARDING_FORM_SUBMIT,
  Omit<OnboardingDoneResponse, 'hasFinishedSetup'>
>;
export type OnUpdateSpecialistsList = Action<
  typeof ONBOARDING_REDUX_CONSTANTS.ON_UPDATE_SPECIALISTS_LIST,
  SpecialistType[]
>;

export type OnboardingActionTypes =
  | OnSearchAddress
  | OnboardingFormSubmit
  | OnUpdateSpecialistsList;
