import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

function useCurrentRoute() {
  const [currentRoute, setCurrentRoute] = useState<string>('none');
  const { pathname } = useLocation();

  useEffect(() => {
    setCurrentRoute(pathname?.split('/').pop() ?? 'none');
  }, [pathname]);

  return currentRoute;
}

export default useCurrentRoute;
