import { createApi } from '@reduxjs/toolkit/query/react';
import { DASHBOARD_URLS } from 'constants/urlConstants';
import {
  GuestReferralRequest,
  ReferralRequest,
} from 'types/referralTypes/referralRequestType';
import { DocumentsTableDetailsType } from 'types/documentsTypes';
import baseQueryWithReauth from './serviceQuery';

/**
 * OfficeFiles API
 */
const officeFilesService = createApi({
  reducerPath: 'officeFilesApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (build) => ({
    sendReferral: build.mutation<DocumentsTableDetailsType, ReferralRequest>({
      query: (referralData: ReferralRequest) => ({
        url: DASHBOARD_URLS.DOCUMENTS_URL.SEND_REFERRAL,
        method: 'POST',
        body: referralData,
      }),
    }),
    guestSendReferral: build.mutation<
      DocumentsTableDetailsType,
      { data: GuestReferralRequest; token: string | undefined }
    >({
      query: ({ data, token }) => ({
        url: `${DASHBOARD_URLS.DOCUMENTS_URL.GUEST_WEBSITE_SEND_REFERRAL}?token=${token}`,
        method: 'POST',
        body: data,
      }),
    }),
  }),
});

export const { useSendReferralMutation, useGuestSendReferralMutation } =
  officeFilesService;

export default officeFilesService;
