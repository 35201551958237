import { Box } from '@mui/material';
import _ from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { TEST_CONSTANTS } from '../../../constants/testConstants';
import {
  indexedDbFailedMessagesChannelsHelper,
  initIndexedDB,
} from '../../../helpers/indexedDbFailedMessagesHelper';
import usePageVisible from '../../../hooks/usePageVisible';
import {
  getChannel,
  MarkChannel,
  searchUsers,
  useCreateChannelMutation,
  useCreateUploadMutation,
  useDeleteMessageMutation,
  useGetChannelsQuery,
  useGetMessagesQuery,
  useMarkChannelMutation,
  useNewMessageMutation,
  usePostMessagesMutation,
} from '../../../services/chatService';
import { useUserTypingMutation } from '../../../services/notificationService';
import {
  newChannel,
  selectChannel,
  setFailedMessageList,
  setMessagesText,
} from '../../../store/slices/chatSlice';
import { chatStyle } from '../../../style/dashboardStyles/chatStyle';
import {
  ChatChannel,
  ChatMessage,
  ChatUser,
  MessageType,
} from '../../../types/chatTypes';
import ChatListPanel from './ChatListPanel';
import ChatPanel from './ChatPanel';

const Chat = () => {
  const pageVisible = usePageVisible();

  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const [newChatUser, setNewChatUser] = useState<ChatUser>();
  const [skipChannels, setSkipChannels] = useState<boolean>(true);
  const [channelsToken, setChannelsToken] = useState<string>();

  const [skipMessages, setSkipMessages] = useState<boolean>(true);
  const [messagesToken, setMessagesToken] = useState<string>();

  const [userSearchList, setUserSearchList] = useState<ChatUser[]>();
  const [newChatLoading, setNewChatLoading] = useState<boolean>(false);
  const [uploading, setUploading] = useState<string>();

  //get items and continuationToken from the chat reducer rather than the query itself
  const {
    items: channels,
    continuationToken: channelsContinuationToken,
    loaded: channelsLoaded,
    loading: channelsLoading,
    selected,
    created,
  } = useAppSelector((state) => state.chatReducer?.channelList ?? {});

  const { items: searchChatResults, searching } = useAppSelector(
    (state) => state.chatReducer?.searchList ?? {}
  );

  const {
    items: messages,
    continuationToken: messagesContinuationToken,
    loaded: messagesLoaded,
  } = useAppSelector(
    (state) =>
      state.chatReducer?.messageList?.messages?.[selected?.id ?? ''] ?? {}
  );

  const { user } = useAppSelector((state) => state.chatReducer?.auth ?? {});

  const {
    hub: { connected },
    channelList: { updateStamp: updateChannelStamp },
  } = useAppSelector((state) => state.notificationReducer ?? {});

  const { error: channelsError, isFetching: channelsFetching } =
    useGetChannelsQuery(
      { continuationToken: channelsToken },
      { skip: skipChannels, refetchOnMountOrArgChange: true }
    );

  const { error: messagesError, isFetching: messagesFetching } =
    useGetMessagesQuery(
      { channelId: selected?.id, continuationToken: messagesToken },
      { skip: skipMessages, refetchOnMountOrArgChange: true }
    );

  const [newMessage] = useNewMessageMutation();
  const [deleteMessage] = useDeleteMessageMutation();
  const [forwardMessages] = usePostMessagesMutation();
  const [createChannel] = useCreateChannelMutation();
  const [markChannel] = useMarkChannelMutation();
  const [userTyping] = useUserTypingMutation();
  const [createUpload] = useCreateUploadMutation();

  const onSelectChat = useCallback(
    async (channel?: ChatChannel) => {
      let select = channel;
      if (select?.searchTerm) {
        //this is a search result channel, check if channel already loaded
        const foundChannel = channels?.find(
          (c: ChatChannel) => c.id === (select?.id ?? 'unkown')
        );
        if (foundChannel) select = foundChannel;
        else {
          //get channel from API
          const dispatchedPromise = dispatch(
            getChannel.initiate({ channelId: select?.id })
          );
          await dispatchedPromise;
          dispatchedPromise.unsubscribe();
        }
      }
      dispatch(selectChannel({ channel: select }));
      //mark channel as read
      if (select?.id) {
        //send mark channel read request if there are unread messages
        // if ((channel?.unreadMessageCount ?? 0) > 0)
        await markChannel({ mark: MarkChannel.read, channelId: select?.id });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, markChannel]
  );

  const onNewChat = () => {
    dispatch(newChannel({}));
  };

  const throttleUserTyping = useMemo(
    () =>
      _.throttle(
        (
          fromUserId: string,
          fromUserDisplayName: string,
          channelId: string,
          toUserIds: string[]
        ) => {
          userTyping({ fromUserId, fromUserDisplayName, channelId, toUserIds });
        },
        2000
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  //save the text that was typed into the message box
  const onMessageTextChange = (text: string) => {
    dispatch(setMessagesText({ channelId: selected?.id ?? '', text }));
    throttleUserTyping(
      user?.userId ?? '',
      user?.name ?? '',
      selected?.id ?? '',
      selected?.recipients?.map((r: ChatUser) => r.userId) ?? []
    );
  };

  const onSendMessage = async (message: ChatMessage, file?: File) => {
    setNewChatLoading(true);
    //clone the message otherwise setting state locks the object
    const sendMessage = JSON.parse(JSON.stringify(message)) as ChatMessage;
    try {
      // if the message is for new channel, create the channel first
      if (message.channelId === (created?.id ?? 'unknown')) {
        const newChannel = {
          ...created,
          lastMessage: { ...message },
        } as ChatChannel;
        const response = await createChannel({
          channel: newChannel,
          message: message,
        }).unwrap();
        if (response.id) sendMessage.channelId = response.id;
      }
      //upload the file first
      if (file) {
        setUploading(file.name);

        const response = await createUpload({
          file,
          message: { ...message },
        }).unwrap();
        if (response.data) {
          sendMessage.messageType = MessageType.attachment;
          sendMessage.metaDictionary = {
            ...(sendMessage.metaDictionary ?? {}),
            ...response.data,
          };
        }
        setUploading(undefined);
      }
      // send Message
      await newMessage(sendMessage);
    } catch (e) {}
    setNewChatLoading(false);
  };

  const onForwardMessages = async (
    users: ChatUser[],
    messages: ChatMessage[]
  ) => {
    setNewChatLoading(true);
    await forwardMessages({ users, messages });
    setNewChatLoading(false);
  };

  const onSearchTextChange = async (text: string, limit: number = 2) => {
    let list = [] as ChatUser[] | undefined;
    if (text.length >= limit) {
      setNewChatLoading(true);
      const dispatchedPromise = dispatch(
        searchUsers.initiate({ searchTerm: text })
      );
      list = (await dispatchedPromise).data;
      dispatchedPromise.unsubscribe();
      setNewChatLoading(false);
    }
    setUserSearchList(list);
  };

  const onSelectSearchUser = (user: ChatUser) => {
    setUserSearchList([]);
    dispatch(newChannel({ recipients: [user] }));
  };
  const onDeleteSearchUser = (user: ChatUser) => {
    setUserSearchList([]);
    dispatch(newChannel({ recipients: [] }));
  };

  const onDeleteMessage = (message: ChatMessage, all?: boolean) => {
    deleteMessage({ message, all });
  };

  const onLoadMore = () => {
    if (channelsContinuationToken) {
      setChannelsToken(channelsContinuationToken);
      setSkipChannels(false); //set it to false so that the next page can be loaded
    }
  };

  const onLoadMoreMessages = () => {
    if (messagesContinuationToken) {
      setMessagesToken(messagesContinuationToken);
      setSkipMessages(false); //set it to false so that the next page can be loaded
    }
  };

  useEffect(() => {
    //set skip to true if there are channels already in store
    setChannelsToken(channelsContinuationToken);
    setSkipChannels(!!channelsLoaded);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channelsLoaded]);

  useEffect(() => {
    //set skip to true if there are messages already in store for this channel
    setMessagesToken(messagesContinuationToken);
    setSkipMessages(messagesLoaded || !selected?.id);
    setUserSearchList([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messagesLoaded, selected]);

  useEffect(() => {
    //select chat if none selected
    if (!selected && channelsLoaded && connected) {
      onSelectChat(channels?.[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connected, channelsLoaded, selected]);

  useEffect(() => {
    //new chat if newChatUser is set
    if (newChatUser && channelsLoaded && connected) {
      const user = { ...newChatUser };
      setNewChatUser(undefined);
      // console.log('newChatUser', user);
      dispatch(newChannel({ recipients: [user] }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connected, channelsLoaded, newChatUser]);

  const throttleMarkChannelRead = useMemo(
    () =>
      _.throttle((mark: MarkChannel, channelId: string) => {
        markChannel({
          mark,
          channelId,
        });
      }, 1000),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    //send read receipt for an updated channel if selected
    if (updateChannelStamp?.id === (selected?.id ?? 'unknown') && pageVisible) {
      throttleMarkChannelRead(MarkChannel.read, updateChannelStamp.id);
      // markChannel({
      //   mark: MarkChannel.read,
      //   channelId: updateChannelStamp.id,
      // });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateChannelStamp, markChannel, pageVisible]);

  useEffect(() => {
    const user = location.state?.user as ChatUser;
    // console.log('onload', user);
    setNewChatUser(user);
    // if (user) {
    navigate(location.pathname, { replace: true }); //reset location state
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // to get the failed messages for the selected channel
  useEffect(() => {
    const fetchFailedMessageListFromIndexedDB = async () => {
      try {
        await initIndexedDB();
        const data: ChatMessage[] | undefined =
          await indexedDbFailedMessagesChannelsHelper
            .getFailedChatMessages()
            .then((val) => val as ChatMessage[]);
        dispatch(setFailedMessageList({ failedMessages: data }));
      } catch (e) {}
    };
    fetchFailedMessageListFromIndexedDB();
  }, [dispatch, selected]);

  return (
    <Box
      sx={chatStyle.chatContainer}
      data-testid={TEST_CONSTANTS.CHAT_CONTAINER}
    >
      <ChatListPanel
        list={channels}
        searchList={searchChatResults}
        selected={selected}
        onSelectChat={onSelectChat}
        onNewChat={onNewChat}
        onLoadMore={onLoadMore}
        loading={channelsFetching || searching || channelsLoading}
        error={channelsError?.toString()}
      />
      <ChatPanel
        chat={selected}
        messages={messages}
        loading={messagesFetching || newChatLoading}
        uploading={uploading}
        error={messagesError?.toString()}
        searchList={userSearchList}
        onSendMessage={onSendMessage}
        onForwardMessages={onForwardMessages}
        onMessageTextChange={onMessageTextChange}
        onSearchTextChange={onSearchTextChange}
        onSelectUser={onSelectSearchUser}
        onDeleteUser={onDeleteSearchUser}
        onDeleteMessage={onDeleteMessage}
        onLoadMoreMessages={onLoadMoreMessages}
      />
    </Box>
  );
};

export default Chat;
