import React from 'react';
import { PatternFormat } from 'react-number-format';
import { colorPalette } from '../theme/colorPalette';

export interface MaskedStringProps {
  value?: string;
  mask: string;
  customStyle?: React.CSSProperties;
}

const MaskedString: React.FC<MaskedStringProps> = ({
  value,
  mask,
  customStyle,
}) => {
  return (
    <PatternFormat
      data-testid='masked-string'
      format={mask}
      value={value ?? ''}
      patternChar='9'
      type='text'
      disabled
      style={{
        border: 'none',
        background: 'transparent',
        color: colorPalette.textPrimary,
        fontSize: '0.875rem',
        padding: '0',
        ...customStyle,
      }}
    />
  );
};

export default MaskedString;
