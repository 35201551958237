import { ArrowBackIos } from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  Divider,
  Link,
  Paper,
  TextField,
} from '@mui/material';
import { SyntheticEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { ReactComponent as DrtalkLogo } from '../../../assets/images/drTalkLogo.svg';
import FlatButton from '../../../components/FlatButton';
import { onboardingDefaultFormError } from '../../../constants/onboardingConstants';
import { ROUTE_CONSTANTS_VARIABLE } from '../../../constants/routeConstants';
import { TEST_CONSTANTS } from '../../../constants/testConstants';
import { stateList } from '../../../constants/usStateListConstant';
import localStorageHelper from '../../../helpers/localStorageHelper';
import { logoutUser } from '../../../helpers/logoutHelper';
import {
  getOrganizationsBasedOnSelectedStateAction,
  getSpecialistsBasedOnSelectedStateAction,
  onboardingFormSubmitAction,
} from '../../../store/actions/onboardingAction';
import { commonAuthStyle } from '../../../style/commonAuthStyle';
import { commonClasses } from '../../../style/commonClasses';
import { onboardingStyle } from '../../../style/onboardingStyle';
import { AutocompleteOption } from '../../../types/Misc';
import {
  OnboardingDefaultFormErrors,
  OnboardingDefaultFormType,
} from '../../../types/onboardingTypes';

// this page will be displayed to a new user, who's loggging in for the first time
const OnboardingDefaultScreen = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isOnboardingCompleted = localStorageHelper.getOnboardingStatus();
  const isAutoGeneratedPassword =
    localStorageHelper.getAutoGeneratedPasswordStatus();

  const { userInfo } = useAppSelector((state) => ({
    userInfo: state.userReducer.userInfo,
  }));

  const { role, mobile } = userInfo ?? {};

  const [practiceList, setPracticeList] = useState<AutocompleteOption[]>([]);
  const [isFormSubmittedOnce, setIsFormSubmittedOnce] =
    useState<boolean>(false);
  const [form, setForm] = useState<OnboardingDefaultFormType>({
    state: null,
    practiceName: null,
  });
  const [errors, setErrors] = useState<OnboardingDefaultFormErrors>({
    state: undefined,
    practiceName: undefined,
  });

  const {
    isGetOrganizationsBasedOnSelectedStateLoaderActive,
    isOnboardingFormSubmissionLoaderActive,
  } = useAppSelector(
    ({ generalLoaderReducer }: Record<string, any>) =>
      generalLoaderReducer ?? true
  );

  const isOnboardingFormValid = (data: Partial<OnboardingDefaultFormType>) => {
    let isFormValid = true;
    const updatedErrors = errors;
    const { state, practiceName } = data;

    if (state! === null) {
      updatedErrors.state = onboardingDefaultFormError.state;
      isFormValid = false;
    } else {
      updatedErrors.state = undefined;
    }
    if (practiceName! === null) {
      updatedErrors.practiceName = onboardingDefaultFormError.practiceName;
      isFormValid = false;
    } else {
      updatedErrors.practiceName = undefined;
    }
    setErrors(updatedErrors);
    return isFormValid;
  };

  const onChangeStateValue = (_event: SyntheticEvent, value: string | null) => {
    setForm({ ...form, state: value });
    if (isFormSubmittedOnce) {
      isOnboardingFormValid({ state: value });
    }
    if (value !== null) {
      getOrganizationsBasedOnSelectedStateAction(value, setPracticeList);
    }
  };

  const onChangePracticeName = (
    _event: SyntheticEvent,
    value: AutocompleteOption | null
  ) => {
    if (isFormSubmittedOnce) {
      isOnboardingFormValid({ practiceName: value });
    }
    setForm({ ...form, practiceName: value });
  };

  const onClickAddNewPractice = () => {
    navigate(ROUTE_CONSTANTS_VARIABLE.ONBOARDING_NEW_PRACTICE);

    if (!!form.state) {
      dispatch(getSpecialistsBasedOnSelectedStateAction(form.state));
    }
  };

  const onBackButtonClick = () => {
    logoutUser(navigate);
  };

  const onClickSubmit = () => {
    if (!isFormSubmittedOnce) {
      setIsFormSubmittedOnce(true);
    }
    if (!isOnboardingFormSubmissionLoaderActive) {
      if (isOnboardingFormValid(form)) {
        const onOnboardingFormSuccessfullySubmission = () => {
          navigate(ROUTE_CONSTANTS_VARIABLE.ONBOARDING_PENDING_APPROVAL);
        };
        const data = {
          state: form.state!,
          practiceId: form.practiceName?.value!,
        };
        const isExistingPractice = true;
        dispatch(
          onboardingFormSubmitAction(
            data,
            isExistingPractice,
            onOnboardingFormSuccessfullySubmission
          )
        );
      }
    }
  };

  useEffect(() => {
    if (isOnboardingCompleted) {
      if (isAutoGeneratedPassword) {
        navigate(ROUTE_CONSTANTS_VARIABLE.SET_PASSWORD);
      } else if (!(role && mobile)) {
        navigate(ROUTE_CONSTANTS_VARIABLE.ONBOARDING_PERSONAL_INFO);
      } else {
        navigate(ROUTE_CONSTANTS_VARIABLE.DASHBOARD);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOnboardingCompleted, isAutoGeneratedPassword, role, mobile]);

  return (
    <Box sx={commonAuthStyle.authPage}>
      <Box sx={commonAuthStyle.authContainer}>
        <Link
          data-testid={TEST_CONSTANTS.ONBOARDING_DEFAULT_FORM_BACK_BUTTON}
          className='backButton'
          underline='hover'
          sx={commonAuthStyle.authBackButton}
          onClick={onBackButtonClick}
        >
          <ArrowBackIos fontSize='inherit' /> Back
        </Link>
        <DrtalkLogo />
        <Box sx={commonAuthStyle.authTitleSubtitleContainer}>
          <Box sx={commonAuthStyle.authTitle}>
            Tell us about your practice, or company
          </Box>
        </Box>

        <Autocomplete
          fullWidth
          value={form.state}
          onChange={onChangeStateValue}
          renderInput={(params) => (
            <TextField
              {...params}
              required
              label='State'
              placeholder='- Select state -'
              helperText={errors?.state}
            />
          )}
          options={stateList}
          sx={errors?.state ? commonClasses.fieldError : void 0}
        />

        <Autocomplete
          fullWidth
          disabled={
            !(form.state || isGetOrganizationsBasedOnSelectedStateLoaderActive)
          }
          value={form.practiceName}
          onChange={onChangePracticeName}
          PaperComponent={({ children, ...other }) => (
            <Paper {...other}>
              {children}
              <Divider />
              <Box sx={onboardingStyle.practiceDropdownAddNewOption}>
                <Link
                  underline='hover'
                  onMouseDown={(event) => {
                    event.preventDefault();
                  }}
                  onClick={onClickAddNewPractice}
                >
                  Add New Practice
                </Link>
              </Box>
            </Paper>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              required
              label='Practice name'
              placeholder='- Select practice name -'
              helperText={form.state !== null && errors?.practiceName}
            />
          )}
          options={practiceList}
          sx={
            errors?.practiceName && form.state !== null
              ? commonClasses.fieldError
              : void 0
          }
        />

        <FlatButton
          sx={commonAuthStyle.submitButton}
          variant='contained'
          color='primary'
          hasShadow
          isLoading={isOnboardingFormSubmissionLoaderActive}
          onClick={onClickSubmit}
        >
          Continue
        </FlatButton>
      </Box>
    </Box>
  );
};

export default OnboardingDefaultScreen;
