export const PROFILE_TAB_VALUES = [
  'personal-info',
  'password',
  'account',
  'about',
  'preferences',
] as const;

export type ProfileTabValue = typeof PROFILE_TAB_VALUES[number];

export type ProfileTabLabel = {
  label: string;
  value: ProfileTabValue;
};

export const profileTabLabels: ReadonlyArray<ProfileTabLabel> = [
  { label: 'Personal Information', value: 'personal-info' },
  { label: 'Password', value: 'password' },
  { label: 'Account', value: 'account' },
  { label: 'About', value: 'about' },
  { label: 'Preferences', value: 'preferences' },
];
