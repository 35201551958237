import { FileTypes } from 'types/documentsTypes';
import { showSnackbarAction } from '../store/actions/snackbarAction';

export const downloadFile = (response: any, fileName: string) => {
  try {
    const blob = new Blob([response?.data], {
      type: response?.headers?.['content-type'],
    });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');

    link.setAttribute('download', fileName);
    link.setAttribute('target', '__blank');
    link.style.display = 'none';
    document.body.appendChild(link);
    link.setAttribute('href', url);
    link.click();
  } catch (e) {
    showSnackbarAction({
      open: true,
      statusCode: 400,
      message: `Cannot download the file - ${fileName}, try again`,
      severity: 'error',
    });
  }
};

export const getFileType = (fileName?: string): FileTypes => {
  const lowerCaseFileName = fileName?.toLowerCase() || '';

  if (/\.(jpg|jpeg|png|gif|bmp|webp)$/.test(lowerCaseFileName)) {
    return FileTypes.IMAGE;
  } else if (/\.(pdf)$/.test(lowerCaseFileName)) {
    return FileTypes.PDF;
  } else if (/\.(json)$/.test(lowerCaseFileName)) {
    return FileTypes.JSON;
  } else if (/\.(doc|docx)$/.test(lowerCaseFileName)) {
    return FileTypes.WORD;
  } else if (/\.(xls|xlsx|csv)$/.test(lowerCaseFileName)) {
    return FileTypes.EXCEL;
  } else if (/\.(csv)$/.test(lowerCaseFileName)) {
    return FileTypes.CSV;
  } else if (/\.(ppt|pptx)$/.test(lowerCaseFileName)) {
    return FileTypes.POWERPOINT;
  } else if (/\.(txt|rtf)$/.test(lowerCaseFileName)) {
    return FileTypes.TEXT;
  } else if (/\.(zip|rar|tar|gz)$/.test(lowerCaseFileName)) {
    return FileTypes.COMPRESSED;
  } else {
    return FileTypes.UNKNOWN;
  }
};
