import { CloseRounded } from '@mui/icons-material';
import { Box, IconButton, TextField } from '@mui/material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { REFERRAL_PAGE_TEST_CONSTANTS } from 'constants/testConstants';
import { Moment } from 'moment';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import {
  setNewPatientDetails,
  setPatientStepFormErrors,
} from 'store/slices/referralSlice';
import { commonClasses } from 'style/commonClasses';
import { newPatientDetailsFieldStyle } from 'style/dashboardStyles/referralStyle';

export type NewPatientDetailsFieldsProps = {
  onCloseForm: () => void;
};

const NewPatientDetailsFields: React.FC<NewPatientDetailsFieldsProps> = ({
  onCloseForm,
}) => {
  const dispatch = useAppDispatch();
  const { token: guestToken } = useParams();
  const isWebsiteReferral = useMemo(() => guestToken, [guestToken]);

  const { newPatientDetails, patientStepFormErrors: formErrors } =
    useAppSelector((state) => state.referralReducer);

  /**
   * Handles changes in new patient details form fields,
   * resets the error message for the target field when value has been changed.
   */
  const handleChange = (
    key: keyof typeof newPatientDetails,
    value: string | Moment | null
  ) => {
    const errorKey = 'newPatient' + key.charAt(0).toUpperCase() + key.slice(1);

    dispatch(
      setPatientStepFormErrors({
        ...formErrors,
        [errorKey]: '',
      })
    );

    dispatch(
      setNewPatientDetails({
        ...newPatientDetails,
        [key]: value,
      })
    );
  };

  return (
    <Box
      data-testid={
        REFERRAL_PAGE_TEST_CONSTANTS.REFERRAL_PATIENT_DETAILS_NEW_PATIENT_FORM
      }
      sx={newPatientDetailsFieldStyle.root.container}
    >
      <TextField
        value={newPatientDetails.firstName}
        placeholder='First name'
        sx={formErrors?.newPatientFirstName ? commonClasses.fieldError : {}}
        helperText={formErrors?.newPatientFirstName}
        onChange={(e) => handleChange('firstName', e.target.value)}
      />
      <TextField
        value={newPatientDetails.lastName}
        placeholder='Last name'
        sx={formErrors?.newPatientLastName ? commonClasses.fieldError : {}}
        helperText={formErrors?.newPatientLastName}
        onChange={(e) => handleChange('lastName', e.target.value)}
      />
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DesktopDatePicker
          label='MM/DD/YYYY'
          inputFormat='MM/DD/YYYY'
          value={newPatientDetails?.dateOfBirth}
          disableFuture
          onChange={(value) => handleChange('dateOfBirth', value)}
          renderInput={(params) => (
            <TextField
              {...params}
              sx={
                formErrors?.newPatientDateOfBirth
                  ? commonClasses.fieldError
                  : {}
              }
              helperText={formErrors?.newPatientDateOfBirth}
            />
          )}
        />
      </LocalizationProvider>
      {!isWebsiteReferral && (
        <IconButton
          onClick={onCloseForm}
          sx={newPatientDetailsFieldStyle.cancelButton}
        >
          <CloseRounded />
        </IconButton>
      )}
    </Box>
  );
};

export default NewPatientDetailsFields;
