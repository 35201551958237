export const infoPageStyle = {
  mainContainer: {
    display: 'flex',
    alignItems: 'center',
    height: 1,
    width: 1,
    p: '0 5vw',
  },
  backLink: {
    position: 'absolute',
    top: '2rem',
    left: '2rem',
    cursor: 'pointer',
  },
  contentContainer: {
    display: 'flex',
    justifyContent: 'center',
    gap: '2vw',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    m: '1rem 0',
  },
  imageContainer: {
    display: 'flex',
  },
  mobileImage: {
    transform: 'translate(-30%, 30%)',
  },
};
