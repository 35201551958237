import { Box, Tooltip } from '@mui/material';
import { useLayoutEffect, useRef, useState } from 'react';
import { documentStyle } from '../../../../style/dashboardStyles/documentStyle';

const DocumentTableMessage = ({ message }: { message: string }) => {
  const messageRef = useRef<any>([]);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [scrollHeight, setScrollHeight] = useState<number>(0);

  useLayoutEffect(() => {
    setScrollHeight(messageRef.current.scrollHeight);
  }, []);

  return (
    <Box
      ref={messageRef}
      sx={[
        documentStyle.documentTableMessage,
        {
          maxHeight: isExpanded ? '100%' : '2.25rem',
        },
      ]}
    >
      {message}
      {scrollHeight > 36 && (
        <Box sx={documentStyle.documentTableMessageEllipsisContainer}>
          {isExpanded ? (
            <Box
              onClick={() => {
                setIsExpanded(false);
              }}
            >
              Read less
            </Box>
          ) : (
            <Tooltip title='Click to read whole message'>
              <Box onClick={() => setIsExpanded(true)}>...</Box>
            </Tooltip>
          )}
        </Box>
      )}
    </Box>
  );
};

export default DocumentTableMessage;
