export const viewConnectionStyle = {
  commonRoundedContainer: {
    p: '1.5rem 1rem',
    bgcolor: 'background.default',
    borderRadius: '.5rem',
  },
  viewConnectionContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1.5rem',
    height: 1,
    flex: 1,
    mt: '.5rem',
  },
  viewConnnectionsPracticeDetailsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  viewConnectionsPracticeName: {
    typography: 'h6',
    fontWeight: 500,
    color: '#424242',
  },
  viewConnectionsPracticeType: {
    typography: 'subtitle2',
    fontWeight: 400,
    color: '#424242',
  },
  viewConnectionsButtonContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: '.8rem',
  },
  viewConnectionLocationContactContainer: {
    display: 'flex',
    gap: '2rem',
    mt: '.5rem',
    typography: 'caption',

    '& > *': {
      display: 'flex',
      alignItems: 'center',
      gap: '.5rem',
    },
  },
  viewConnectionsMemberCard: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: 1,
    p: '.5rem 0',
    '&:not(:last-of-type)': {
      borderBottom: '.0625rem solid',
      borderColor: '#eeeeee',
    },
  },
  viewConnectionMemberCardDetailsContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '.5rem',
  },
  viewConnectionMemberAvtar: {
    width: '3.5rem',
    height: '3.5rem',
    flexShrink: 0,
  },
};
