import { Autocomplete, Box, TextField } from '@mui/material';
import _ from 'lodash';
import React, { ChangeEvent, SyntheticEvent } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { TEST_CONSTANTS } from '../../../constants/testConstants';
import {
  onEmptySearchAddressValue,
  onSearchAddressAction
} from '../../../store/actions/onboardingAction';
import { AddressType } from '../../../store/reduxConstants/onboardingReduxConstants';
import { commonClasses } from '../../../style/commonClasses';
import { onboardingStyle } from '../../../style/onboardingStyle';
import { isOnboardingFormValid } from './onboardingFormCheckValidation';
import {
  OnboardingFormErrorType,
  OnboardingFormType
} from './OnboardingNewPractice';
import SearchAddressDropdown from './SearchAddressDropdown';

type SearchAdressPropType = {
  onboardingForm: OnboardingFormType;
  setOnboardingForm: (value: OnboardingFormType) => void;
  setIsAddressModalOpen: (value: boolean) => void;
  error: OnboardingFormErrorType;
  setError: React.Dispatch<React.SetStateAction<OnboardingFormErrorType>>;
  isFormSubmittedOnce: boolean;
};

export type AddressResultType = {
  City: string;
  FormattedAddress: string;
  State: string | null;
  Zip: string;
};

const SearchAddress = (props: SearchAdressPropType) => {
  const {
    onboardingForm,
    setOnboardingForm,
    setIsAddressModalOpen,
    error,
    setError,
    isFormSubmittedOnce,
  } = props;
  const { searchAddressResults } = useAppSelector(
    ({ onboardingReducer }: Record<string, any>) => onboardingReducer ?? {}
  );

  const { isOnboardingFormAddressLookUpSearchLoaderActive } = useAppSelector(
    ({ generalLoaderReducer }: Record<string, any>) =>
      generalLoaderReducer ?? true
  );
  const dispatch = useAppDispatch();

  const onSearchAddressInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const data = {
      Input: e.target.value,
    };

    if (e.target.value.length > 2) {
      dispatch(onSearchAddressAction(data));
    } else {
      dispatch(onEmptySearchAddressValue());
    }
  };

  const debounceSearch = _.debounce(onSearchAddressInputChange, 1500);
  const onOptionChange = (
    _event: SyntheticEvent,
    value: AddressType | string | null
  ) => {
    if (isFormSubmittedOnce) {
      isOnboardingFormValid({ fullAddress: value }, error, setError);
    }
    if (typeof value !== 'string')
      setOnboardingForm({
        ...onboardingForm,
        fullAddress: value,
      });
  };

  return (
    <Box sx={onboardingStyle.addressContainer}>
      <Autocomplete
        freeSolo
        fullWidth
        clearOnBlur
        filterOptions={(x) => x}
        value={onboardingForm.fullAddress}
        sx={error.fullAddress ? commonClasses.fieldError : {}}
        loading={isOnboardingFormAddressLookUpSearchLoaderActive}
        data-testid={TEST_CONSTANTS.ON_BOARDING_FORM_ADDRESS}
        getOptionLabel={(option: AddressType | string) =>
          (typeof option === 'string' ? option : option.fullAddress ?? '') ??
          null
        }
        options={searchAddressResults}
        PaperComponent={(paperComponentProps) => (
          <SearchAddressDropdown
            paperComponentProps={paperComponentProps}
            setIsAddressModalOpen={setIsAddressModalOpen}
          />
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            required
            onChange={debounceSearch}
            onBlur={() => dispatch(onEmptySearchAddressValue())}
            label='Address'
            helperText={error?.fullAddress}
            placeholder='Start typing your practice address...'
            InputLabelProps={{
              shrink: true,
            }}
          />
        )}
        onChange={onOptionChange}
      />
    </Box>
  );
};

export default SearchAddress;
