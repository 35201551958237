import { createApi } from '@reduxjs/toolkit/query/react';
import { DASHBOARD_URLS } from 'constants/urlConstants';
import baseQueryWithReauth from './serviceQuery';

/**
 * Recipient API
 */
const recipientService = createApi({
  reducerPath: 'recipientApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (build) => ({
    deleteRecipient: build.mutation<void, { recipientId: string }>({
      query: ({ recipientId }) => ({
        url: `${DASHBOARD_URLS.DOCUMENTS_URL.DELETE_PRACTICE_RECIPIENT}/${recipientId}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const { useDeleteRecipientMutation } = recipientService;

export default recipientService;
