import _ from 'lodash';
import moment from 'moment';
import { imageFileExtensions } from '../constants/documentsConstants';

export type ChatUser = {
  userId: string;
  profileUrl: string;
  profileImage?: string;
  name: string;
  lastSeenAt: number;
  role?: string;
  practiceName?: string;
  isDeleted?: boolean;
};

export type ChatSearchUser = {
  id: string;
  firstName: string;
  lastName: string;
  role: string;
  practiceId: string;
  practiceName: string;
  profileImage: string;
  displayName: string;
};

export const searchUserToUser = (sU: ChatSearchUser) =>
  ({
    userId: sU.id,
    name: sU.displayName ?? `${sU.firstName ?? ''} ${sU.lastName ?? ''}`,
    role: sU.role,
    practiceName: sU.practiceName,
    profileUrl: sU.profileImage,
  } as ChatUser);

export type ChatSearch = {
  messageId: string;
  channelId: string;
  channelName: string;
  userId: string;
  userFullName: string;
  createdTimeUtc: string;
  messageType: MessageType;
  userProfileImageUrl: string;
  messagePreviewText: string;
  messageCount: number;
  isGroupChat: boolean;
};

export const searchChatToChannel = (
  sC: ChatSearch,
  searchTerm: string | null,
  userId?: string
) => {
  const name = !_.isEmpty(sC.channelName) ? sC.channelName : sC.userFullName;
  let messageText =
    (!_.isEmpty(sC.channelName) &&
      (sC.userId === (userId ?? 'none') ? 'You' : sC.userFullName)) ||
    '';
  messageText +=
    (!_.isEmpty(messageText) && !_.isEmpty(sC.messagePreviewText) && `: `) ||
    '';
  messageText +=
    (!_.isEmpty(sC.messagePreviewText) && sC.messagePreviewText) ?? '';
  return {
    id: sC.channelId,
    name,
    coverImageUrl: sC.userProfileImageUrl,
    searchedUserId: sC.userId,
    lastMessage: {
      id: sC.messageId,
      messageText,
    },
    createdTimeUtc: sC.createdTimeUtc,
    unreadMessageCount: 0,
    searchTerm,
    isGroupChat: sC.isGroupChat,
  } as ChatChannel;
};

export type ChatReceipt = {
  userId: string;
  timestamp: number;
};

//convert receipts to array and filter out userId if set and sort in descending order
export const convertReceipts = (
  receipts: Record<string, number>,
  userId?: string
) =>
  Object.entries(receipts ?? {})
    .map(([userId, timestamp]) => ({ userId, timestamp } as ChatReceipt))
    .filter((r) => r.userId !== (userId ?? 'unknown'))
    .sort((a, b) => b.timestamp - a.timestamp);

export type MarkedReceipts = Record<string, Record<string, number>>;

export type ChatMessageAttachment = {
  name: string;
  file: ChatMessageAttachmentFile;
  thumbnail?: ChatMessageAttachmentFile;
  timestamp: number;
  by: string;
  isImage: boolean;
};

export type ChatMessageAttachmentFile = {
  URI: string;
  fileName: string;
  objectURL?: string;
  loading?: boolean;
  error?: string;
};

type TimeToLive = {
  ticks: number;
  days: number;
  hours: number;
  milliseconds: number;
  minutes: number;
  seconds: number;
  totalDays: number;
  totalHours: number;
  totalMilliseconds: number;
  totalMinutes: number;
  totalSeconds: number;
};

export type ChatMessageMetaDictionary = {
  attachmentUri: string;
  attachmentName: string;
  attachmentThumbnail: string;
  attachmentThumbnailName: string;
  giphyThumbnailUri: string;
  forwardedFrom: string;
};

export type ChatMessage = {
  id: string;
  type: string;
  messageId: string;
  etag: string;
  timeToLive: TimeToLive;
  lastUpdatedTimestampUtc: string;
  lastUpdatedTimestamp: number;
  createdTimeUtc: string;
  isDeleted: boolean;
  messageText: string;
  channelId: string;
  metaDictionary: ChatMessageMetaDictionary;
  messageType: number;
  user: ChatUser;
  createdAt: number;
  updatedAt: number;
  deletedBy: string[];
  //extra fields
  pending?: string;
  deleted?: boolean;
  fromUser?: boolean;
  attachment?: ChatMessageAttachment;
  hide?: boolean;
  failed?: boolean; // if sending message fails
};

export type ChatChannel = {
  id: string;
  type: string;
  etag: string;
  timeToLive: TimeToLive;
  lastUpdatedTimestampUtc: string;
  lastUpdatedTimestamp: number;
  createdTimeUtc: string;
  isDeleted: boolean;
  name: string;
  coverImageUrl: string;
  createdBy: ChatUser;
  members: ChatUser[];
  deliveryReceipts: Record<string, number>;
  readReceipts: Record<string, number>;
  archiveReceipts: Record<string, number>;
  flagReceipts: Record<string, number>;
  lastMessage: ChatMessage;
  frozen: boolean;
  metaDictionary: Record<string, string>;
  unreadMessageCount: number;
  isGroupChat: boolean;
  //extra fields
  timestamp?: number;
  recipients?: ChatUser[];
  typers?: ChatUser[];
  user?: ChatUser;
  new?: boolean;
  text?: string;
  pending?: string;
  searchTerm?: string;
  searchedUserId?: string;
  reads?: ChatReceipt[];
  lastReadAt?: number;
  userLastReadAt?: number;
  delivers?: ChatReceipt[];
  lastDeliveredAt?: number;
};

export type ChatCreateChannel = {
  id: string;
  name: string;
  memberIds: string[];
  members: ChatUser[];
  isGroupChat: boolean;
};

export const channelToCreateChannel = (channel: ChatChannel) => {
  // const isGroupChat = (channel.recipients?.length ?? 0) > 1;
  return {
    id: channel.id,
    // name: channel.name ?? ,
    memberIds: channel.members?.map((m) => m.userId) ?? [],
    members: channel.members,
    // isGroupChat: (channel.recipients?.length ?? 0) > 1,
  } as ChatCreateChannel;
};

export enum MessageType {
  text = 0,
  attachment = 1,
  gif = 2,
}

export const getAttachmentFromMessage = (message: ChatMessage) => {
  if (message.messageType !== MessageType.attachment) return undefined;
  const meta = message.metaDictionary ?? {};
  //if there is no attachment Uri then return undefined
  const fileURI = meta.attachmentUri ?? meta.attachmentThumbnail;
  if (!fileURI) return undefined;

  const attachment = {
    name: meta.attachmentName ?? meta.attachmentThumbnailName ?? '',
    file: { URI: fileURI, fileName: fileURI.split('/').pop() },
    timestamp: message.createdAt ?? moment().unix(),
    by: message.user?.name ?? '',
  } as ChatMessageAttachment;

  //is the attachment an image?
  attachment.isImage = imageFileExtensions.includes(
    (fileURI.split('.').pop() ?? '').toLowerCase()
  );

  //set thumbnail if there is a thumbnail URI or it is an image with no thumbnail
  if (meta.attachmentThumbnail || attachment.isImage) {
    const thumbnailURI = meta.attachmentThumbnail ?? meta.attachmentUri;
    if (thumbnailURI) {
      //this shouldn't be falsey
      attachment.thumbnail = {
        URI: meta.attachmentThumbnail ?? meta.attachmentUri,
        fileName: thumbnailURI.split('/').pop(),
      } as ChatMessageAttachmentFile;
    }
  }

  return attachment;
};

export type LocalStorageFailedMessagesChannel = {
  id?: string;
  channelId: string;
  messages: ChatMessage[];
};
