import localStorageHelper from '../../../helpers/localStorageHelper';
import {
  EfaxReducer,
  EfaxTableDetailsType,
  EfaxTableFilters,
  PracticeDetails,
} from '../../../types/efaxTypes';
import {
  EFAX_REDUX_CONSTANTS,
  EfaxActionTypes,
} from '../../reduxConstants/efaxReduxConstants';

const initialFilters: EfaxTableFilters = {
  sender: null,
  recipient: null,
};

const initialPracticeDetails: PracticeDetails = {
  practiceId: null,
  practiceName: null,
  practicePhone: null,
  eFaxNumber: null,
};

const initialEfaxReducer: EfaxReducer = {
  userPracticeDetails: initialPracticeDetails,
  receivedEfaxList: [],
  sentEfaxList: [],
  paginatedList: [],
  selectedTab: 0,
  appliedFilters: initialFilters,
  receivedEfaxStatusFilter: null,
  isSendEfaxDrawerOpen: false,
  isPreviewEfaxDrawerOpen: false,
  isOpenEfaxDrawerOpen: false,
  targetPreviewFax: undefined,
  contactHistory: [],
};

export const efaxReducer = (
  state = initialEfaxReducer,
  action: EfaxActionTypes
): EfaxReducer => {
  switch (action.type) {
    case EFAX_REDUX_CONSTANTS.ON_CHANGE_SELECTED_TAB: {
      return {
        ...state,
        selectedTab: action.payload,
      };
    }

    case EFAX_REDUX_CONSTANTS.GET_EFAX_LIST:
      const loggedUserPracticeId = localStorageHelper.getPracticeId();

      const receivedFaxes = action.payload
        ?.filter(
          (efax) =>
            efax.direction === 'inbound' &&
            efax.eFaxStatus === 'success' &&
            efax.hasReceiverPracticeArchived === false
        )
        .sort((a, b) => +new Date(b.createdOn) - +new Date(a.createdOn));

      const sentFaxes = action.payload
        ?.filter(
          (efax) =>
            efax.direction === 'outbound' &&
            efax.senderPracticeId === loggedUserPracticeId
        )
        .sort((a, b) => +new Date(b.createdOn) - +new Date(a.createdOn));

      return {
        ...state,
        sentEfaxList: sentFaxes,
        receivedEfaxList: receivedFaxes,
      };

    case EFAX_REDUX_CONSTANTS.ON_RECEIVED_EFAX_STATUS_CHANGE: {
      return {
        ...state,
        receivedEfaxStatusFilter: action.payload,
      };
    }

    case EFAX_REDUX_CONSTANTS.UPDATE_EFAX_CONTACT: {
      const updatedContact = action.payload;
      let contactHistory = state.contactHistory;

      if (!!contactHistory?.length) {
        if (
          contactHistory.some(
            (contact) => contact.EFaxNumber === updatedContact.eFaxNumber
          )
        ) {
          contactHistory = contactHistory.map((contact) => {
            if (contact.EFaxNumber === updatedContact.eFaxNumber) {
              return { ...contact, PracticeName: updatedContact.practiceName };
            } else {
              return contact;
            }
          });
        }
      }

      return {
        ...state,
        contactHistory: contactHistory,
      };
    }

    case EFAX_REDUX_CONSTANTS.ON_CHANGE_OR_APPLY_EFAX_TABLE_FILTER: {
      const { name, value } = action.payload;

      return {
        ...state,
        appliedFilters: {
          ...state.appliedFilters,
          [name]: value,
        },
      };
    }

    case EFAX_REDUX_CONSTANTS.ON_PROCESS_EFAX: {
      const updatedReceivedEfaxList: EfaxTableDetailsType[] =
        state.receivedEfaxList.map((fax: EfaxTableDetailsType) =>
          fax.id === action.payload
            ? { ...fax, documentStatus: 'Processed' }
            : fax
        );

      return {
        ...state,
        receivedEfaxList: updatedReceivedEfaxList,
      };
    }

    case EFAX_REDUX_CONSTANTS.UPDATE_PAGINATED_LIST:
      return { ...state, paginatedList: [...action.payload] };

    case EFAX_REDUX_CONSTANTS.ON_ARCHIVE_UNARCHIVE_EFAX: {
      const targetFax = action.payload;

      let updatedReceivedEfaxList: EfaxTableDetailsType[] =
        state.receivedEfaxList.map((fax: EfaxTableDetailsType) =>
          fax.id === targetFax.id
            ? {
                ...fax,
                hasReceiverPracticeArchived:
                  targetFax.hasReceiverPracticeArchived,
                hasSenderPracticeArchived: targetFax.hasSenderPracticeArchived,
              }
            : fax
        );

      // will filter all the archived faxes by the logged practice user
      updatedReceivedEfaxList = updatedReceivedEfaxList.filter((item) => {
        const userPracticeId = localStorageHelper.getPracticeId();
        const isFaxReceivedByUser = item.recipientPracticeId === userPracticeId;

        if (isFaxReceivedByUser) {
          return !item.hasReceiverPracticeArchived;
        }

        return item;
      });

      let updatedSentEfaxList: EfaxTableDetailsType[] = state.sentEfaxList.map(
        (fax: EfaxTableDetailsType) =>
          fax.id === targetFax.id
            ? {
                ...fax,
                hasReceiverPracticeArchived:
                  targetFax.hasReceiverPracticeArchived,
                hasSenderPracticeArchived: targetFax.hasSenderPracticeArchived,
              }
            : fax
      );

      // will filter all the archived faxes by the logged practice user
      updatedSentEfaxList = updatedSentEfaxList.filter((item) => {
        const userPracticeId = localStorageHelper.getPracticeId();
        const isFaxSentByUser = item.senderPracticeId === userPracticeId;

        if (isFaxSentByUser) {
          return !item.hasSenderPracticeArchived;
        }

        return item;
      });

      return {
        ...state,
        receivedEfaxList: updatedReceivedEfaxList,
        sentEfaxList: updatedSentEfaxList,
      };
    }

    case EFAX_REDUX_CONSTANTS.SET_IS_SEND_EFAX_DRAWER_OPEN: {
      return {
        ...state,
        isSendEfaxDrawerOpen: !state.isSendEfaxDrawerOpen,
      };
    }

    case EFAX_REDUX_CONSTANTS.TOGGLE_OPEN_EFAX_DRAWER: {
      return {
        ...state,
        targetPreviewFax: action.payload || undefined,
        isOpenEfaxDrawerOpen: !state.isOpenEfaxDrawerOpen,
      };
    }

    case EFAX_REDUX_CONSTANTS.SET_USER_PRACTICE_DETAILS: {
      return {
        ...state,
        userPracticeDetails: action.payload,
      };
    }

    case EFAX_REDUX_CONSTANTS.PUSH_NEW_EFAX: {
      const newFax = action.payload;
      const userPracticeId = state.userPracticeDetails.practiceId;
      const isNewFaxSentByUser = newFax.senderPracticeId === userPracticeId;
      const isNewFaxReceivedByUser =
        newFax.recipientPracticeId === userPracticeId;

      let tempEfaxes: EfaxTableDetailsType[] = [];

      if (isNewFaxSentByUser) {
        tempEfaxes = [...state.sentEfaxList];
        tempEfaxes = tempEfaxes?.filter((fax) => fax.id !== newFax.id);
        tempEfaxes.unshift(action.payload);

        return {
          ...state,
          sentEfaxList: tempEfaxes,
        };
      }

      if (isNewFaxReceivedByUser) {
        if (newFax.direction === 'inbound' && newFax.eFaxStatus === 'success') {
          tempEfaxes = [...state.receivedEfaxList];
          tempEfaxes = tempEfaxes?.filter((fax) => fax.id !== newFax.id);
          tempEfaxes.unshift(action.payload);

          return {
            ...state,
            receivedEfaxList: tempEfaxes,
          };
        }
      }

      return {
        ...state,
      };
    }

    case EFAX_REDUX_CONSTANTS.SET_EFAX_CONTACT_HISTORY: {
      return {
        ...state,
        contactHistory: action.payload,
      };
    }

    case EFAX_REDUX_CONSTANTS.RESET_EFAX_FILTERS: {
      const {
        userPracticeDetails,
        contactHistory,
        receivedEfaxList,
        sentEfaxList,
        paginatedList,
      } = state;

      return {
        ...initialEfaxReducer,
        paginatedList,
        receivedEfaxList,
        sentEfaxList,
        userPracticeDetails,
        contactHistory,
      };
    }

    default:
      return state;
  }
};
