import {
  ArrowBackIos,
  VisibilityOffOutlined,
  VisibilityOutlined,
} from '@mui/icons-material';
import {
  Box,
  IconButton,
  InputAdornment,
  Link,
  TextField,
  Typography,
} from '@mui/material';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../app/hooks';
import { ReactComponent as DrtalkLogo } from '../../assets/images/drTalkLogo.svg';
import FlatButton from '../../components/FlatButton';
import { NewPasswordCriteria } from '../../components/NewPasswordCriteria';
import { ROUTE_CONSTANTS_VARIABLE } from '../../constants/routeConstants';
import { TEST_CONSTANTS } from '../../constants/testConstants';
import localStorageHelper from '../../helpers/localStorageHelper';
import { logoutUser } from '../../helpers/logoutHelper';
import { passwordValidation } from '../../helpers/validationHelper';
import { setNewPasswordAction } from '../../store/actions/authActions';
import { commonAuthStyle } from '../../style/commonAuthStyle';
import { commonClasses } from '../../style/commonClasses';
import { SetNewPasswordForm } from '../../types/authTypes';

const SetPassword = () => {
  const navigate = useNavigate();
  const isOnboardingCompleted = localStorageHelper.getOnboardingStatus();
  const isAutoGeneratedPassword =
    localStorageHelper.getAutoGeneratedPasswordStatus();
  const [showNewPassword, setShowNewPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] =
    useState<boolean>(false);
  const [form, setForm] = useState<SetNewPasswordForm>({
    password: '',
    confirmPassword: '',
  });
  const [errors, setErrors] = useState<{
    password: string | null | ReturnType<typeof NewPasswordCriteria>;
    confirmPassword: string | null;
  }>({ password: null, confirmPassword: null });
  const [isFormSubmittedOnce, setIsFormSubmittedOnce] =
    useState<boolean>(false);
  const [isFormValid, setIsFormValid] = useState<boolean>(true);
  const { isSetNewPasswordLoaderActive } = useAppSelector(
    ({ generalLoaderReducer }: Record<string, any>) =>
      generalLoaderReducer ?? true
  );

  const onBackButtonClick = () => {
    logoutUser(navigate);
  };

  // once password is set successfully this user should be redirected to
  // onboarding page and, isAutoGeneratedPassword variable should be removed
  // from local storage
  const onSuccessfullyPasswordSet = () => {
    navigate(ROUTE_CONSTANTS_VARIABLE.ONBOARDING);
    localStorageHelper.clearAutoGeneratedPasswordStatus();
  };

  const onInputValueChange = (name: string, value: string) => {
    setForm({
      ...form,
      [name]: value,
    });
  };

  /**
   * Password criteria :
   * - 8 - 20 characters
   * - At least one uppercase
   * - At least one lowercase
   * - At least one number
   * - At least one special character
   */
  function isPasswordValid(value: string): boolean {
    const { length, uppercase, lowercase, number, special } =
      passwordValidation(value);

    return length && uppercase && lowercase && number && special;
  }

  const checkValidity = useCallback(
    (data: Partial<SetNewPasswordForm>) => {
      let isDataValid: boolean = true;
      let updatedErrors = errors;

      if (!isPasswordValid(data.password!)) {
        isDataValid = false;
        updatedErrors = {
          ...updatedErrors,
          password: <NewPasswordCriteria password={data.password!} />,
        };
      } else {
        updatedErrors = {
          ...updatedErrors,
          password: '',
        };
      }

      if (data.password !== data.confirmPassword) {
        isDataValid = false;
        updatedErrors = {
          ...updatedErrors,
          confirmPassword: 'Password does not match',
        };
      } else {
        updatedErrors = {
          ...updatedErrors,
          confirmPassword: '',
        };
      }

      setErrors(updatedErrors);
      setIsFormValid(isDataValid);
    },
    [errors]
  );

  useEffect(() => {
    if (
      !!!form.password.toString().trim().length ||
      !!!form.confirmPassword.toString().trim.length
    ) {
      setIsFormValid(false);
    } else {
      setIsFormValid(true);
    }
  }, [form]);

  useEffect(() => {
    if (isFormSubmittedOnce) {
      checkValidity(form);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, isFormSubmittedOnce]);
  const onClickSetNewPassword = () => {
    if (isFormValid) setNewPasswordAction(form, onSuccessfullyPasswordSet);
    if (!isFormSubmittedOnce) {
      setIsFormSubmittedOnce(true);
    }
  };

  useEffect(() => {
    if (!isAutoGeneratedPassword) {
      if (isOnboardingCompleted) {
        navigate(ROUTE_CONSTANTS_VARIABLE.DASHBOARD);
      } else {
        navigate(ROUTE_CONSTANTS_VARIABLE.ONBOARDING);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOnboardingCompleted, isAutoGeneratedPassword]);

  return (
    <Box sx={commonAuthStyle.authPage}>
      <Box sx={commonAuthStyle.authContainer}>
        <Link
          className='backButton'
          underline='hover'
          data-testid={TEST_CONSTANTS.SET_NEW_PASSWORD_BACK_LINK}
          sx={commonAuthStyle.authBackButton}
          onClick={onBackButtonClick}
        >
          <ArrowBackIos fontSize='inherit' /> Back
        </Link>
        <DrtalkLogo />
        <Box>
          <Box sx={[commonAuthStyle.authTitle, { textAlign: 'center' }]}>
            Create a new password
          </Box>
          <Typography variant='body2' textAlign='center'>
            Password should be a minimum of 8 characters, with upper and
            lowercase letters, one number and one symbol.
          </Typography>
        </Box>
        <TextField
          fullWidth
          data-testid={TEST_CONSTANTS.SET_NEW_PASSWORD_INPUT}
          type={showNewPassword ? 'text' : 'password'}
          label='New password'
          sx={
            errors?.password
              ? [
                  commonClasses.fieldError,
                  commonAuthStyle.setNewPasswordTextField,
                ]
              : {}
          }
          placeholder='New password'
          value={form.password}
          helperText={<>{errors?.password}</>}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            onInputValueChange('password', event.target.value)
          }
          InputProps={{
            autoComplete: 'off',
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton
                  aria-label='toggle new password visibility'
                  edge='end'
                  onClick={() => setShowNewPassword((e: boolean) => !e)}
                >
                  {showNewPassword ? (
                    <VisibilityOffOutlined />
                  ) : (
                    <VisibilityOutlined />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <TextField
          fullWidth
          data-testid={TEST_CONSTANTS.SET_NEW_PASSWORD_CONFIRM_INPUT}
          type={showConfirmPassword ? 'text' : 'password'}
          label='Confirm password'
          sx={errors?.confirmPassword ? commonClasses.fieldError : {}}
          placeholder=' '
          value={form.confirmPassword}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            onInputValueChange('confirmPassword', event.target.value)
          }
          helperText={errors.confirmPassword}
          InputProps={{
            autoComplete: 'off',
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton
                  aria-label='toggle confirm password visibility'
                  edge='end'
                  onClick={() => setShowConfirmPassword((e: boolean) => !e)}
                >
                  {showConfirmPassword ? (
                    <VisibilityOffOutlined />
                  ) : (
                    <VisibilityOutlined />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <FlatButton
          sx={commonAuthStyle.submitButton}
          variant='contained'
          color='primary'
          onClick={onClickSetNewPassword}
          data-testid={TEST_CONSTANTS.SET_NEW_PASSWORD_SUBMIT_BUTTON}
          isLoading={isSetNewPasswordLoaderActive}
          hasShadow
        >
          Next
        </FlatButton>
      </Box>
    </Box>
  );
};

export default SetPassword;
