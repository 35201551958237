import {
  ChatBubbleOutline,
  CloseRounded,
  InboxOutlined,
  LanguageOutlined,
  PrintOutlined,
} from '@mui/icons-material';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import {
  Badge,
  Box,
  Divider,
  Grow,
  IconButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import moment from 'moment';
import { SyntheticEvent, useEffect, useState } from 'react';
import Moment from 'react-moment';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { ROUTE_CONSTANTS_VARIABLE } from '../../../constants/routeConstants';
import { TEST_CONSTANTS } from '../../../constants/testConstants';
import localStorageHelper from '../../../helpers/localStorageHelper';
import { EFAX_REDUX_CONSTANTS } from '../../../store/reduxConstants/efaxReduxConstants';
import {
  NewEfaxDocumentsListType,
  removeNewFax,
} from '../../../store/slices/notificationSlice';
import { headerStyle } from '../../../style/headerStyle';

const HeaderNotification = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const token = localStorageHelper.getAuthToken();

  type menuOptionType = {
    icon: React.ReactNode;
    route: string;
    count: number;
    preText: string;
    sufText: string;
    timestamp: number;
  };

  const [anchor, setAnchor] = useState<Element | null>(null);
  const [menuOptions, setMenuOptions] = useState<menuOptionType[]>([]);
  const {
    totalCount,
    channelList: {
      count: unreadMessagesCount,
      updateCountStamp: unreadMessagesCountTimestamp,
      updateStamp: updateChannelStamp,
    },
    invitationList: {
      count: pendingInvitationsCount,
      updateCountStamp: pendingInvitationsCountTimestamp,
      updateStamp: updateInvitationStamp,
    },
    documentList: {
      count: unprocessedDocumentsCount,
      updateCountStamp: unprocessedDocumentsTimestamp,
      updateStamp: updateDocumentStamp,
    },
    newEfaxDocumentsList,
  } = useAppSelector((state) => state.notificationReducer ?? {});
  const hasNotifications = totalCount + newEfaxDocumentsList.length > 0;

  const onClick = (event: SyntheticEvent) => {
    setAnchor(event.currentTarget);
  };
  const closeMenu = () => {
    setAnchor(null);
  };
  const onNavigate = (route: string) => {
    setAnchor(null);
    navigate(route);
  };

  useEffect(() => {
    setMenuOptions([
      {
        icon: <ChatBubbleOutline />,
        route: 'chat',
        count: unreadMessagesCount ?? 0,
        preText: 'You have ',
        sufText: 'unread message',
        timestamp:
          updateChannelStamp?.timestamp ??
          unreadMessagesCountTimestamp ??
          moment().unix(),
      },
      {
        icon: <InboxOutlined />,
        route: 'documents',
        count: unprocessedDocumentsCount ?? 0,
        preText: 'Your practice has ',
        sufText: 'unprocessed document',
        timestamp:
          updateDocumentStamp?.timestamp ??
          unprocessedDocumentsTimestamp ??
          moment().unix(),
      },
      {
        icon: <LanguageOutlined />,
        route: 'my-network',
        count: pendingInvitationsCount ?? 0,
        preText: 'Your practice has ',
        sufText: 'connection request',
        timestamp:
          updateInvitationStamp?.timestamp ??
          pendingInvitationsCountTimestamp ??
          moment().unix(),
      },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pendingInvitationsCount, unprocessedDocumentsCount, unreadMessagesCount]);

  return (
    <Box
      data-testid={TEST_CONSTANTS.HEADER_NOTIFICATION}
      sx={headerStyle.headerNotificationContainer}
    >
      <IconButton
        disabled={!token}
        aria-label='notifications'
        size='large'
        onClick={onClick}
        sx={headerStyle.headerNotificationButton}
      >
        <Badge
          color='error'
          variant='dot'
          overlap='circular'
          invisible={!hasNotifications}
        >
          <NotificationsOutlinedIcon />
        </Badge>
      </IconButton>
      <Menu
        open={Boolean(anchor)}
        anchorEl={anchor}
        onClose={closeMenu}
        TransitionComponent={Grow}
        PaperProps={{ sx: headerStyle.headerNotificationMenu }}
        keepMounted
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box sx={headerStyle.headerNotificationMenuTitle}>
          <Typography variant='h6'>{'Notifications'}</Typography>
          <IconButton onClick={closeMenu}>
            <CloseRounded />
          </IconButton>
        </Box>
        {hasNotifications ? (
          <Box>
            {newEfaxDocumentsList?.map(
              (newEfax: NewEfaxDocumentsListType, index: number) => {
                const userPracticeId = localStorageHelper.getPracticeId();
                const isNewFaxSentByUser =
                  newEfax.senderPracticeId === userPracticeId;
                const isNewFaxReceivedByUser =
                  newEfax.recipientPracticeId === userPracticeId;

                return (
                  <Box
                    key={`faxNotification-${index}`}
                    sx={{ borderRadius: '1rem' }}
                  >
                    <Divider />
                    <MenuItem
                      onClick={() => {
                        onNavigate(ROUTE_CONSTANTS_VARIABLE.EFAX);

                        dispatch({
                          type: EFAX_REDUX_CONSTANTS.ON_CHANGE_SELECTED_TAB,
                          payload: isNewFaxReceivedByUser ? 0 : 1,
                        });
                        dispatch(removeNewFax({ faxId: newEfax.id }));
                      }}
                      sx={headerStyle.headerNotificationMenuItem}
                    >
                      <ListItemIcon>
                        <Badge
                          color={
                            newEfax.eFaxStatus === 'success' &&
                            isNewFaxSentByUser
                              ? 'success'
                              : newEfax.eFaxStatus === 'failed'
                              ? 'warning'
                              : 'info'
                          }
                          overlap='circular'
                          badgeContent={' '}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                          }}
                        >
                          <PrintOutlined />
                        </Badge>
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <Typography variant='body2' fontWeight={600}>
                            {newEfax.eFaxStatus === 'failed'
                              ? 'e-Fax delivery failed, bad phone number'
                              : isNewFaxSentByUser
                              ? 'e-Fax sent successfully'
                              : isNewFaxReceivedByUser
                              ? 'New e-Fax received'
                              : ''}
                          </Typography>
                        }
                        secondary={
                          <Typography variant='caption'>
                            <Moment
                              calendar={{
                                sameElse: 'L LTS',
                              }}
                              unix
                              local
                              locale='en_US'
                            >
                              {newEfax.receivedTime}
                            </Moment>
                          </Typography>
                        }
                      />
                      <ListItemSecondaryAction>
                        <Badge color='error' variant='dot' overlap='circular' />
                      </ListItemSecondaryAction>
                    </MenuItem>
                  </Box>
                );
              }
            )}
            {menuOptions
              .filter((option) => option.count > 0)
              .map((option, index) => (
                <Box key={index} sx={{ borderRadius: '1rem' }}>
                  <Divider />
                  <MenuItem
                    onClick={() => onNavigate(option.route)}
                    sx={headerStyle.headerNotificationMenuItem}
                  >
                    <ListItemIcon>
                      <Badge
                        color='error'
                        overlap='circular'
                        badgeContent={option.count}
                        invisible={option.count < 1}
                      >
                        {option.icon}
                      </Badge>
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Typography variant='body2'>
                          {option.preText}
                          <b>
                            {option.count} {option.sufText}
                            {option.count === 1 ? '' : 's'}
                          </b>
                        </Typography>
                      }
                      secondary={
                        <Typography variant='caption'>
                          <Moment
                            calendar={{
                              sameElse: 'L LTS',
                            }}
                            unix
                            local
                            locale='en_US'
                          >
                            {option.timestamp}
                          </Moment>
                        </Typography>
                      }
                    />
                    <ListItemSecondaryAction>
                      <Badge
                        color='error'
                        variant='dot'
                        overlap='circular'
                        invisible={option.count < 1}
                      ></Badge>
                    </ListItemSecondaryAction>
                  </MenuItem>
                </Box>
              ))}
          </Box>
        ) : (
          <Box sx={{ borderRadius: '1rem' }}>
            <Divider />
            <Box style={{ padding: '1.5rem 0.5rem' }}>
              No notifications yet.
            </Box>
          </Box>
        )}
      </Menu>
    </Box>
  );
};

export default HeaderNotification;
