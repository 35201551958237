import type { UserInfo } from '../store/reduxConstants/userReduxConstants';
import type { GeneralResponse } from '../types/Misc';
import { AxiosError } from 'axios';

// Type guards are runtime-safe custom type checkers

export function isUserInfo(userInfo: unknown): userInfo is UserInfo {
  if (!userInfo) return false;
  // We'll check properties that exist for both onboarded and new users.
  // New users while onboarding will have no practice information.
  return (
    typeof (userInfo as UserInfo)?.firstName !== 'undefined' &&
    typeof (userInfo as UserInfo)?.lastName !== 'undefined'
  );
}

export function isGeneralResponse<T = unknown>(
  axiosResponseData: unknown
): axiosResponseData is GeneralResponse<T> {
  return (
    typeof (axiosResponseData as GeneralResponse<T>)?.statusCode === 'number' &&
    typeof (axiosResponseData as GeneralResponse<T>)?.method === 'string'
  );
}

export function isErrorMessageResponse(
  axiosErrorResponse: unknown
): axiosErrorResponse is Required<AxiosError<{ Message: string }>> {
  return (
    typeof (axiosErrorResponse as AxiosError<{ Message: string }>)?.response
      ?.data?.Message === 'string'
  );
}
