import { showSnackbarAction } from '../store/actions/snackbarAction';

export const generalErrorHandler = (e: any) => {
  return showSnackbarAction({
    open: true,
    statusCode: e?.response?.status ?? e?.status,
    message:
      e?.ExceptionMessage ??
      e?.response?.data?.Message ??
      e?.response?.data?.message ??
      e?.data?.Message ??
      e?.data?.message ??
      e?.Message ??
      e.message,
    severity: 'error',
  });
};
