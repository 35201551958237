import moment from 'moment';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { TEST_CONSTANTS } from '../../../../constants/testConstants';
import { commonClasses } from '../../../../style/commonClasses';
import { documentStyle } from '../../../../style/dashboardStyles/documentStyle';
import { setSendDocumentFormDataAction } from '../../../../store/actions/dashboardActions/documentActions';
import { RootState } from '../../../../store/store';
import { Box, TextField, Typography } from '@mui/material';
import {
  DocumentReducer,
  SendDocumentErrors,
} from '../../../../types/documentsTypes';
import { CalendarMonthOutlined } from '@mui/icons-material';

const SendDocumentPatientDropdown = ({
  errors,
}: {
  errors: SendDocumentErrors;
}) => {
  const dispatch = useAppDispatch();
  const { sendDocumentFormData } = useAppSelector(
    (state: RootState) => state.documentReducer as DocumentReducer
  );
  const { dateOfBirth, firstName, lastName } = sendDocumentFormData.patient;

  const { isSendDocumentsLoaderActive } = useAppSelector(
    ({ generalLoaderReducer }: Record<string, any>) =>
      generalLoaderReducer ?? true
  );

  return (
    <Box sx={documentStyle.newPatientDetailsContainer}>
      <Box sx={documentStyle.newPatientDetailsFields}>
        <Box>
          <Typography variant='h6' fontWeight={500}>
            Patient details
          </Typography>
          <Typography variant='body2'>
            Please enter the details of the patient this document belongs to
          </Typography>
        </Box>
        <Box sx={documentStyle.newPatientNameDetails}>
          <TextField
            data-testid={
              TEST_CONSTANTS.SEND_DOCUMENTS_DIALOG_LEFT_PART_NEW_PATIENT_FIRST_NAME_FIELD
            }
            required
            disabled={isSendDocumentsLoaderActive}
            label='First name'
            value={firstName}
            sx={errors.patientFirstName ? commonClasses.fieldError : {}}
            onChange={(event) =>
              dispatch(
                setSendDocumentFormDataAction({
                  ...sendDocumentFormData,
                  patient: {
                    ...sendDocumentFormData.patient,
                    firstName: event.target.value,
                  },
                })
              )
            }
            helperText={errors?.patientFirstName}
          />
          <TextField
            data-testid={
              TEST_CONSTANTS.SEND_DOCUMENTS_DIALOG_LEFT_PART_NEW_PATIENT_LAST_NAME_FIELD
            }
            required
            disabled={isSendDocumentsLoaderActive}
            label='Last name'
            value={lastName}
            sx={errors.patientLastName ? commonClasses.fieldError : {}}
            onChange={(event) =>
              dispatch(
                setSendDocumentFormDataAction({
                  ...sendDocumentFormData,
                  patient: {
                    ...sendDocumentFormData.patient,
                    lastName: event.target.value,
                  },
                })
              )
            }
            helperText={errors?.patientLastName}
          />
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              data-testid={
                TEST_CONSTANTS.SEND_DOCUMENTS_DIALOG_LEFT_PART_PATIENT_DOB_FIELD
              }
              components={{
                OpenPickerIcon: CalendarMonthOutlined,
              }}
              label='Date of birth'
              inputFormat='MM/DD/yyyy'
              disabled={isSendDocumentsLoaderActive}
              value={dateOfBirth}
              onChange={(value) =>
                dispatch(
                  setSendDocumentFormDataAction({
                    ...sendDocumentFormData,
                    patient: {
                      ...sendDocumentFormData.patient,
                      dateOfBirth: moment(value).toISOString() || '',
                    },
                  })
                )
              }
              renderInput={(params) => (
                <TextField
                  required
                  {...params}
                  error={!!errors.patientDateOfBirth}
                  sx={errors.patientDateOfBirth ? commonClasses.fieldError : {}}
                  helperText={errors?.patientDateOfBirth}
                  placeholder='Select birth dates'
                />
              )}
            />
          </LocalizationProvider>
        </Box>
      </Box>
    </Box>
  );
};

export default SendDocumentPatientDropdown;
