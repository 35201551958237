import { AddNewPatientRequest } from './../../types/referralTypes/addNewPatientRequest';
import { ReferralRequest } from '../../types/referralTypes/referralRequestType';
import { DASHBOARD_URLS } from '../../constants/urlConstants';
import {
  AddContactRequestData,
  AddPatientRequestData,
  DocumentsTableDetailsDeleteRecordRequestBody,
  FileConfig,
  ForwardDocumentsRequestBody,
  SendDocumentsRequestData,
} from '../../types/documentsTypes';
import { RecommendedPracticeInviteType } from '../../types/myNetworkTypes';
import { MyOfficeAddMemberRequestBodyType } from '../../types/myOfficeType';
import ApiService from './apiService';
import { SaveContactPayload, SendFaxDetails } from '../../types/efaxTypes';

export const dashboardApiServices = {
  // efax api service starts here
  efax: {
    efaxTable: {
      getEfaxMessages: (timeStamp: number) =>
        ApiService.get(
          `${DASHBOARD_URLS.EFAX_URL.GET_FAX_MESSAGES}/${timeStamp}`
        ),
      getEfaxTableDetails: () =>
        ApiService.get(DASHBOARD_URLS.EFAX_URL.GET_EFAX_TABLE_DETAILS),
      processEfax: (messageId: string) =>
        ApiService.put(`${DASHBOARD_URLS.EFAX_URL.PROCESS_EFAX}/${messageId}`),
      sendEfax: (data: SendFaxDetails) =>
        ApiService.post(DASHBOARD_URLS.EFAX_URL.SEND_EFAX, data),
    },
    saveContact: (data: SaveContactPayload) =>
      ApiService.post(DASHBOARD_URLS.EFAX_URL.SAVE_CONTACT, data),
    getEfaxContactHistory: () =>
      ApiService.get(DASHBOARD_URLS.EFAX_URL.GET_EFAX_CONTACT_HISTORY),
  },
  // documents api service starts here
  document: {
    getPatientList: () =>
      ApiService.get(DASHBOARD_URLS.DOCUMENTS_URL.GET_PATIENT_LIST),
    uploadDocument: (blobType: string, file: FormData, config: FileConfig) =>
      ApiService.post(
        `${DASHBOARD_URLS.DOCUMENTS_URL.UPLOAD_DOCUMENT}/${blobType}`,
        file,
        config
      ),
    downloadDocument: (fileName: string, blobType: string) =>
      ApiService.get(
        `${DASHBOARD_URLS.DOCUMENTS_URL.DOWNLOAD_DOCUMENT}/${fileName}?blobType=${blobType}`,
        {
          responseType: 'arraybuffer',
        }
      ),
    deleteDocument: (data: { fileName: string; blobType: 'Private' }) =>
      ApiService.post(DASHBOARD_URLS.DOCUMENTS_URL.DELETE_DOCUMENT, data),

    sendDocument: (data: SendDocumentsRequestData) =>
      ApiService.post(DASHBOARD_URLS.DOCUMENTS_URL.SEND_DOCUMENT, data),
    sendReferral: (data: SendDocumentsRequestData) =>
      ApiService.post(DASHBOARD_URLS.DOCUMENTS_URL.SEND_REFERRAL, data),
    addContact: (data: AddContactRequestData) =>
      ApiService.post(DASHBOARD_URLS.DOCUMENTS_URL.ADD_CONTACT, data),
    addressBook: {
      addContact: (data: AddContactRequestData) =>
        ApiService.post(DASHBOARD_URLS.DOCUMENTS_URL.ADD_CONTACT, data),
      getRecipients: () =>
        ApiService.get(`${DASHBOARD_URLS.DOCUMENTS_URL.GET_RECIPIENTS}/0`),
      getRecipientDetail: (id: string) =>
        ApiService.get(
          `${DASHBOARD_URLS.DOCUMENTS_URL.GET_RECIPIENT_DETAIL}/${id}`
        ),
      updateRecipient: (newRecipientData: any) =>
        ApiService.put(
          DASHBOARD_URLS.DOCUMENTS_URL.UPDATE_PRACTICE_RECIPIENT,
          newRecipientData
        ),
    },
    // for guest user
    getDocumentsByToken: (token: string) =>
      ApiService.get(
        `${DASHBOARD_URLS.DOCUMENTS_URL.GET_DOCUMENTS_BY_TOKEN}/${token}`
      ),
    previewOrDownloadDocumentByToken: (
      token: string,
      fileName: string,
      blobType?: number | null
    ) =>
      ApiService.request({
        method: 'GET',
        url: `${DASHBOARD_URLS.DOCUMENTS_URL.GUEST_USER_DOCUMENT_PREVIEW}/${token}/${fileName}`,
        responseType: 'blob',
        params: blobType ? { blobType } : {},
      }),
    documentsTable: {
      getDocumentsTableDetails: () =>
        ApiService.get(DASHBOARD_URLS.DOCUMENTS_URL.GET_DOCUMENT_TABLE_DETAILS),
      processDocument: (officeFileId: string) =>
        ApiService.put(
          `${DASHBOARD_URLS.DOCUMENTS_URL.PROCESS_DOCUMENT}/${officeFileId}/mark-as-processed`
        ),
      acceptDocument: (officeFileId: string) =>
        ApiService.post(
          `${DASHBOARD_URLS.DOCUMENTS_URL.PROCESS_DOCUMENT}/${officeFileId}/accept`
        ),
      rejectDocument: (officeFileId: string) =>
        ApiService.post(
          `${DASHBOARD_URLS.DOCUMENTS_URL.PROCESS_DOCUMENT}/${officeFileId}/reject`
        ),
      // when user adds new patient, used when user adds new patient via send documentes dialog
      addPatient: (data: AddPatientRequestData) =>
        ApiService.post(DASHBOARD_URLS.DOCUMENTS_URL.ADD_PATIENT, data),
      getPracticeDirectory: () =>
        ApiService.get(DASHBOARD_URLS.DOCUMENTS_URL.GET_PRACTICE_DIRECTORY),
      actions: {
        downloadAttachment: (fileName: string, params: { blobType: string }) =>
          ApiService.request({
            method: 'GET',
            url: `${DASHBOARD_URLS.DOCUMENTS_URL.DOCUMENTS_TABLE_ACTIONS.DOWNLOAD_ATTACHMENTS}/${fileName}`,
            responseType: 'blob',
            params,
          }),
        forwardDocuments: (data: ForwardDocumentsRequestBody) =>
          ApiService.post(
            DASHBOARD_URLS.DOCUMENTS_URL.DOCUMENTS_TABLE_ACTIONS
              .FORWARD_DOCUMENTS,
            data
          ),
        changeDocType: (sharedDocId: string, data: { isReferral: boolean }) =>
          ApiService.patch(
            `${DASHBOARD_URLS.DOCUMENTS_URL.DOCUMENTS_TABLE_ACTIONS.CHANGE_DOC_TYPE}/${sharedDocId}`,
            data
          ),
        deleteRow: (data: DocumentsTableDetailsDeleteRecordRequestBody) =>
          ApiService.delete(
            DASHBOARD_URLS.DOCUMENTS_URL.DOCUMENTS_TABLE_ACTIONS.DELETE_ROW,
            { data }
          ),
      },
    },
  },
  // my-network api service starts here
  myNetwork: {
    getConnectedPracticeList: () =>
      ApiService.get(DASHBOARD_URLS.MY_NETWORK_URL.MY_NETWORK_PRACTICE_LIST),
    getPendingConnectionInvites: () =>
      ApiService.get(
        DASHBOARD_URLS.MY_NETWORK_URL
          .MY_NETWORK_PENDING_CONNECTION_PRACTICE_INVITES
      ),
    getPendingRegistrationInvites: () =>
      ApiService.get(
        DASHBOARD_URLS.MY_NETWORK_URL.MY_NETWORK_PENDING_REGISTRATION_INVITES
      ),
    getPracticeDetails: (practiceId: string) =>
      ApiService.get(
        `${DASHBOARD_URLS.MY_NETWORK_URL.VIEW_CONNECTION}/${practiceId}`
      ),
    invitePractice: (data: Record<string, string | number>) =>
      ApiService.post(DASHBOARD_URLS.MY_NETWORK_URL.INVITE_PRACTICE, data),
    deleteSentInvitation: (inviteId: string) =>
      ApiService.post(
        `${DASHBOARD_URLS.MY_NETWORK_URL.DELETE_SENT_INVITATION}/${inviteId}/ignore`
      ),
    deleteRegistrationInvitation: (id: string) =>
      ApiService.delete(
        `${DASHBOARD_URLS.MY_NETWORK_URL.DELETE_REGISTRATION_INVITATION}/${id}`
      ),
    getInvitationList: (practiceId: string) =>
      ApiService.get(
        `${DASHBOARD_URLS.MY_NETWORK_URL.GET_INVITATION_LIST}/${practiceId}`
      ),
    acceptPracticeInvite: (inviteId: string) =>
      ApiService.post(
        `${DASHBOARD_URLS.MY_NETWORK_URL.ACCEPT_OR_IGNORE_PRACTICE_INVITE}/${inviteId}/accept`
      ),
    ignorePracticeInvite: (inviteId: string) =>
      ApiService.post(
        `${DASHBOARD_URLS.MY_NETWORK_URL.ACCEPT_OR_IGNORE_PRACTICE_INVITE}/${inviteId}/ignore`
      ),
    getRecommendedList: () =>
      ApiService.get(
        DASHBOARD_URLS.MY_NETWORK_URL.GET_RECOMMENDED_PRACTICE_LIST
      ),
    hideRecommendedPracticeItem: (id: string) =>
      ApiService.post(
        `${DASHBOARD_URLS.MY_NETWORK_URL.HIDE_RECOMMENDED_PRACTICE_LIST}/${id}`
      ),
    inviteRecommendedPractice: (data: RecommendedPracticeInviteType) =>
      ApiService.post(
        DASHBOARD_URLS.MY_NETWORK_URL.INVITE_RECOMMENDED_PRACTICE,
        data
      ),
    GetConnectedPractices: (params: { withCustomFormsOnly: boolean }) =>
      ApiService.get(DASHBOARD_URLS.MY_NETWORK_URL.GET_CONNECTED_PRACTICES, {
        params,
      }),

    GetRelatedPatients: () =>
      ApiService.get(DASHBOARD_URLS.MY_NETWORK_URL.GET_RELATED_PATIENTS),

    sendReferral: (params: ReferralRequest) =>
      ApiService.post(DASHBOARD_URLS.DOCUMENTS_URL.SEND_REFERRAL, params),
    addNewPatient: (params: AddNewPatientRequest) =>
      ApiService.post(DASHBOARD_URLS.MY_NETWORK_URL.ADD_NEW_PATIENT, params),
    searchPracticeByNameOrEmail: (params: { searchTerm: string }) =>
      ApiService.get(
        DASHBOARD_URLS.MY_NETWORK_URL.SEARCH_PRACTICE_BY_NAME_OR_EMAIL,
        {
          params,
        }
      ),
  },
  // my-office api service starts here
  myOffice: {
    getOranizationDetails: (id: string) =>
      ApiService.get(
        `${DASHBOARD_URLS.MY_OFFICE_URL.GET_ORGANIZATION_DETAILS}/${id}`
      ),
    getTeamMemberInvitations: () =>
      ApiService.get(DASHBOARD_URLS.MY_OFFICE_URL.GET_TEAM_MEMBER_INVITATIONS),
    addMember: (data: Partial<MyOfficeAddMemberRequestBodyType>) =>
      ApiService.post(DASHBOARD_URLS.MY_OFFICE_URL.ADD_MEMBER, data),

    getRolesOfLoggedInUserPractice: (organizationId: string) =>
      ApiService.get(
        `${DASHBOARD_URLS.MY_OFFICE_URL.GET_ROLES_OF_LOGGED_IN_USER_PRACTICE}/${organizationId}/roles`
      ),
  },
};
