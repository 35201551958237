import { CloseRounded, SearchOutlined } from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  TextField,
  Typography,
} from '@mui/material';
import _ from 'lodash';
import { useState } from 'react';
import { chatStyle } from '../../../../style/dashboardStyles/chatStyle';
import { ChatUser } from '../../../../types/chatTypes';
import ChatUserItem from './ChatUserItem';

const ChatForwardMessageDialog = ({
  open,
  loading,
  searchList,
  onSearchTextChange,
  onSend,
  onClose,
}: {
  open?: boolean;
  loading?: boolean;
  searchList?: ChatUser[];
  onSearchTextChange?: (text: string, limit?: number) => void;
  onSend?: (users: ChatUser[]) => void;
  onClose?: () => void;
}) => {
  const [selectedUsers, setSelectedUsers] = useState<ChatUser[] | undefined>();

  const handleSelectUser = (user: ChatUser, selected?: boolean) => {
    const users = [...(selectedUsers ?? [])];
    const removed = _.remove(users, (u) => u === user);
    if (removed.length === 0 || selected) users.push(user);
    setSelectedUsers(users);
  };

  const handleChange = (text: string) => {
    setSelectedUsers([]);
    onSearchTextChange && onSearchTextChange(text, 0);
  };
  const debounceChange = _.debounce(handleChange, 1000);

  return (
    <Dialog open={!!open} sx={chatStyle.chatDialog} disableEscapeKeyDown>
      <DialogTitle sx={{ p: '1rem 1.5rem' }}>
        <Box sx={chatStyle.chatDialogHeader}>
          <Box sx={chatStyle.chatDialogHeaderIcon}></Box>
          <IconButton
            onClick={() => {
              setSelectedUsers(undefined);
              onClose && onClose();
            }}
          >
            <CloseRounded />
          </IconButton>
        </Box>
        <Typography sx={chatStyle.chatDialogTitle}>Forward message</Typography>
      </DialogTitle>
      <DialogContent>
        <TextField
          placeholder='Search'
          sx={chatStyle.chatForwardSelectUserTextField}
          onChange={(e) => debounceChange(e.currentTarget.value)}
          InputProps={{
            startAdornment: <SearchOutlined sx={{ width: '3.3rem' }} />,
            ...(loading && {
              endAdornment: <CircularProgress />,
            }),
          }}
        />
        <List sx={chatStyle.chatForwardUserList}>
          {searchList?.map((user: ChatUser, index: number) => {
            return (
              <ChatUserItem
                key={index}
                user={user}
                onSelect={handleSelectUser}
                onCheck={handleSelectUser}
                selected={selectedUsers?.includes(user)}
                compact={true}
                large={true}
                divider={true}
              />
            );
          })}
        </List>
      </DialogContent>
      <DialogActions sx={chatStyle.chatDialogActions}>
        <Box width={'5rem'} />
        {!selectedUsers && (
          <Button variant='text' onClick={() => onClose && onClose()}>
            Cancel
          </Button>
        )}
        <Button
          variant='contained'
          onClick={() => {
            onSend && onSend([...(selectedUsers ?? [])]);
            setSelectedUsers(undefined);
          }}
          disabled={(selectedUsers?.length ?? 0) === 0}
        >
          Send
        </Button>
        <Box width={'5rem'} />
      </DialogActions>
    </Dialog>
  );
};

export default ChatForwardMessageDialog;
