import { ArrowBackIos, CloseRounded } from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  Checkbox,
  Chip,
  Divider,
  FormControlLabel,
  Link,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import { ChangeEvent, SyntheticEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { ReactComponent as DrtalkLogo } from '../../../assets/images/drTalkLogo.svg';
import FlatButton from '../../../components/FlatButton';
import { ROUTE_CONSTANTS_VARIABLE } from '../../../constants/routeConstants';
import { TEST_CONSTANTS } from '../../../constants/testConstants';
import localStorageHelper from '../../../helpers/localStorageHelper';
import {
  getPracticeTypesAction,
  onboardingFormSubmitAction,
} from '../../../store/actions/onboardingAction';
import { commonAuthStyle } from '../../../style/commonAuthStyle';
import { commonClasses } from '../../../style/commonClasses';
import ManualAddressDialog from './ManualAddressDialog';
import { isOnboardingFormValid } from './onboardingFormCheckValidation';
import SearchAddress from './SearchAddress';
import { AddressType } from '../../../store/reduxConstants/onboardingReduxConstants';
import { colorPalette } from '../../../theme/colorPalette';

export type OnboardingFormType = {
  practiceName: string;
  doctorName?: string;
  contactPerson?: string;
  fullAddress: AddressType | null;
  practiceType: string | null;
  specialists: string[];
};

export type OnboardingFormErrorType = {
  practiceName?: string;
  doctorName?: string;
  contactPerson?: string;
  fullAddress?: string | null;
  practiceType?: string | null;
  specialists?: string | null;
};

const initialOnboardingForm: OnboardingFormType = {
  practiceName: '',
  doctorName: '',
  contactPerson: '',
  fullAddress: null,
  practiceType: null,
  specialists: [],
};

const OnboardingNewPractice = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isOnboardingFormSubmissionLoaderActive } = useAppSelector(
    ({ generalLoaderReducer }: Record<string, any>) =>
      generalLoaderReducer ?? true
  );
  const [practiceTypes, setPracticeTypes] = useState<string[]>([]);
  const { userInfo, stateSpecialists } = useAppSelector((state) => ({
    userInfo: state.userReducer.userInfo,
    stateSpecialists: state.onboardingReducer.stateSpecialists,
  }));
  const [isFormSubmittedOnce, setIsFormSubmittedOnce] =
    useState<boolean>(false);
  const isOnboardingCompleted = localStorageHelper.getOnboardingStatus();
  const isAutoGeneratedPassword =
    localStorageHelper.getAutoGeneratedPasswordStatus();
  const [onboardingForm, setOnboardingForm] = useState<OnboardingFormType>(
    initialOnboardingForm
  );
  const [error, setError] = useState<OnboardingFormErrorType>({});

  const {
    practiceName,
    fullAddress,
    practiceType,
    doctorName,
    contactPerson,
    specialists,
  } = onboardingForm;
  const [isAddressModalOpen, setIsAddressModalOpen] = useState<boolean>(false);

  const onChangeOnboardingFormField = (name: string, value: string | null) => {
    if (isFormSubmittedOnce) {
      isOnboardingFormValid({ [name]: value }, error, setError);
    }
    setOnboardingForm({
      ...onboardingForm,
      [name]: value,
    });
  };

  const onOnboardingFormSubmit = () => {
    if (!isFormSubmittedOnce) {
      setIsFormSubmittedOnce(true);
    }
    if (!isOnboardingFormSubmissionLoaderActive) {
      if (
        isOnboardingFormValid(
          {
            practiceName,
            fullAddress: fullAddress?.fullAddress ?? fullAddress,
            practiceType,
          },
          error,
          setError
        )
      ) {
        const onOnboardingFormSuccessfullySubmission = () => {
          localStorageHelper.saveOnboardingStatus('true');

          if (!(userInfo?.role && userInfo?.mobile)) {
            navigate(ROUTE_CONSTANTS_VARIABLE.ONBOARDING_PERSONAL_INFO);
          } else {
            localStorageHelper.saveOrganizationApprovalStatus('true');
            navigate(ROUTE_CONSTANTS_VARIABLE.ONBOARDING_PENDING_APPROVAL);
          }
        };

        if (
          fullAddress !== null &&
          practiceType !== null &&
          fullAddress.state !== null
        ) {
          let data = {
            practiceName,
            practiceType,
            fullAddress: fullAddress.fullAddress,
            city: fullAddress.city,
            state: fullAddress.state,
            zip: fullAddress.zip,
            doctorName,
            contactName: contactPerson,
            connectToPracticeIds: specialists,
          };
          dispatch(
            onboardingFormSubmitAction(
              data,
              false,
              onOnboardingFormSuccessfullySubmission
            )
          );
        }
      }
    }
  };

  const onBackButtonClick = () => {
    navigate(ROUTE_CONSTANTS_VARIABLE.ONBOARDING);
  };

  useEffect(() => {
    if (isOnboardingCompleted) {
      if (isAutoGeneratedPassword) {
        navigate(ROUTE_CONSTANTS_VARIABLE.SET_PASSWORD);
      }
      // if (!(userInfo?.role && userInfo?.mobile)) {
      //   navigate(ROUTE_CONSTANTS_VARIABLE.ONBOARDING_PERSONAL_INFO, {
      //     replace: true,
      //   });
      // } else {
      localStorageHelper.saveOrganizationApprovalStatus('true');
      navigate(ROUTE_CONSTANTS_VARIABLE.ONBOARDING_PENDING_APPROVAL);
      // }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOnboardingCompleted, isAutoGeneratedPassword, userInfo]);

  useEffect(() => {
    getPracticeTypesAction(setPracticeTypes);
  }, []);

  const [selectedSpecialists, setSelectedSpecialists] = useState<string[]>([]);
  const [isSpecialistPopupOpen, setIsSpecialistPopupOpen] =
    useState<boolean>(false);

  useEffect(() => {
    setSelectedSpecialists(onboardingForm.specialists);
  }, [onboardingForm.specialists]);

  return (
    <>
      <Box
        data-testid={TEST_CONSTANTS.ON_BOARDING_FORM}
        sx={commonAuthStyle.authPage}
      >
        <Box sx={commonAuthStyle.authContainer}>
          <Link
            data-testid={TEST_CONSTANTS.ON_BOARDING_FORM_BACK_LINK}
            className='backButton'
            underline='hover'
            sx={commonAuthStyle.authBackButton}
            onClick={onBackButtonClick}
          >
            <ArrowBackIos fontSize='inherit' /> Back
          </Link>

          <DrtalkLogo />
          <Box sx={commonAuthStyle.authTitleSubtitleContainer}>
            <Box
              sx={commonAuthStyle.authTitle}
              data-testid={TEST_CONSTANTS.ON_BOARDING_FORM_LABEL}
            >
              Tell us about your practice
            </Box>
            <Typography variant='body2'>
              Enter your practice name and address to finish setup.
            </Typography>
          </Box>

          <TextField
            fullWidth
            inputProps={{
              'data-testid': TEST_CONSTANTS.ON_BOARDING_FORM_PRACTICE_NAME,
            }}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              onChangeOnboardingFormField('practiceName', e.target.value)
            }
            variant='outlined'
            value={onboardingForm.practiceName}
            label='Practice name'
            placeholder='Enter your practice name (not your personal name)'
            sx={error?.practiceName ? commonClasses.fieldError : {}}
            helperText={error?.practiceName}
            InputLabelProps={{
              shrink: true,
            }}
            required
          />

          <TextField
            fullWidth
            inputProps={{
              'data-testid': TEST_CONSTANTS.ON_BOARDING_FORM_DOCTOR_NAME,
            }}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              onChangeOnboardingFormField('doctorName', e.target.value)
            }
            variant='outlined'
            value={onboardingForm.doctorName}
            label={`Doctor's name`}
            placeholder={`Enter the doctor’s name`}
            sx={error?.doctorName ? commonClasses.fieldError : {}}
            helperText={error?.doctorName}
            InputLabelProps={{
              shrink: true,
            }}
          />

          <TextField
            fullWidth
            inputProps={{
              'data-testid': TEST_CONSTANTS.ON_BOARDING_FORM_CONTACT_PERSON,
            }}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              onChangeOnboardingFormField('contactPerson', e.target.value)
            }
            variant='outlined'
            value={onboardingForm.contactPerson}
            label='Contact Person'
            placeholder='Enter the name of someone to contact within your practice'
            sx={error?.contactPerson ? commonClasses.fieldError : {}}
            helperText={error?.contactPerson}
            InputLabelProps={{
              shrink: true,
            }}
          />

          <Typography width='100%' component='div'>
            <SearchAddress
              onboardingForm={onboardingForm}
              setOnboardingForm={setOnboardingForm}
              error={error}
              setError={setError}
              setIsAddressModalOpen={setIsAddressModalOpen}
              isFormSubmittedOnce={isFormSubmittedOnce}
            />
          </Typography>

          <Autocomplete
            fullWidth
            data-testid={TEST_CONSTANTS.ON_BOARDING_FORM_PRACTICE_TYPE}
            value={onboardingForm.practiceType}
            disableClearable={onboardingForm.practiceType === null}
            onChange={(_e: SyntheticEvent, value: string | null) => {
              onChangeOnboardingFormField('practiceType', value);
            }}
            sx={error?.practiceType ? commonClasses.fieldError : {}}
            options={practiceTypes}
            renderInput={(params) => (
              <TextField
                {...params}
                label='Practice type'
                placeholder='Make a selection'
                helperText={error?.practiceType}
                InputLabelProps={{
                  shrink: true,
                }}
                required
              />
            )}
          />

          <Autocomplete
            fullWidth
            multiple
            value={onboardingForm.specialists}
            disableClearable
            onChange={(_event, newValue) => {
              setOnboardingForm({
                ...onboardingForm,
                specialists: newValue,
              });
            }}
            onOpen={() => setIsSpecialistPopupOpen(true)}
            open={isSpecialistPopupOpen}
            sx={error?.specialists ? commonClasses.fieldError : {}}
            options={stateSpecialists.map((specialist) => specialist.id)}
            renderTags={(tagValue, getTagProps) =>
              tagValue.map((option, index) => {
                const targetSpecialist = stateSpecialists.find(
                  (specialist) => specialist.id === option
                );

                return (
                  <Chip
                    {...getTagProps({ index })}
                    label={targetSpecialist?.name}
                    deleteIcon={<CloseRounded />}
                    sx={{
                      background: '#ECE7F5',
                      color: colorPalette.primaryMain,
                      '.MuiSvgIcon-root': {
                        color: colorPalette.primary[300],
                        background: colorPalette.primary[100],
                        borderRadius: '50%',
                        padding: '0.15rem',
                      },
                    }}
                  />
                );
              })
            }
            renderOption={(option, specialistOption) => {
              const targetSpecialist = stateSpecialists.find(
                (specialist) => specialist.id === specialistOption
              );

              const isChecked = selectedSpecialists.includes(
                targetSpecialist!.id
              );

              return (
                <FormControlLabel
                  key={option.id}
                  sx={{
                    display: 'block',
                    paddingX: '1rem',
                  }}
                  control={
                    <Checkbox
                      checked={isChecked}
                      onChange={() => {
                        let newSelectedSpecialists = selectedSpecialists;

                        if (isChecked) {
                          newSelectedSpecialists = selectedSpecialists.filter(
                            (specialist) => specialist !== targetSpecialist!.id
                          );
                        } else {
                          newSelectedSpecialists = [
                            ...newSelectedSpecialists,
                            targetSpecialist!.id,
                          ];
                        }

                        setSelectedSpecialists(newSelectedSpecialists);
                      }}
                    />
                  }
                  label={targetSpecialist?.name}
                />
              );
            }}
            PaperComponent={({ children, ...other }) => (
              <Paper {...other}>
                {children}
                <Divider />
                <Box display='flex' gap='0.5rem' p='1rem'>
                  <FlatButton
                    onClick={() => {
                      setSelectedSpecialists(onboardingForm.specialists);
                      setIsSpecialistPopupOpen(false);
                    }}
                    variant='outlined'
                    sx={commonAuthStyle.submitButton}
                    isLoading={isOnboardingFormSubmissionLoaderActive}
                    fullWidth
                  >
                    Cancel
                  </FlatButton>
                  <FlatButton
                    onClick={() => {
                      setOnboardingForm({
                        ...onboardingForm,
                        specialists: selectedSpecialists,
                      });

                      setIsSpecialistPopupOpen(false);
                    }}
                    variant='contained'
                    sx={commonAuthStyle.submitButton}
                    isLoading={isOnboardingFormSubmissionLoaderActive}
                    fullWidth
                  >
                    Done
                  </FlatButton>
                </Box>
              </Paper>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label='Connect to a specialist'
                placeholder='Make a selection'
                helperText={error?.specialists}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />

          <FlatButton
            data-testid={TEST_CONSTANTS.ON_BOARDING_FORM_SUBMIT_BUTTON}
            onClick={onOnboardingFormSubmit}
            variant='contained'
            sx={commonAuthStyle.submitButton}
            isLoading={isOnboardingFormSubmissionLoaderActive}
            hasShadow
          >
            Done
          </FlatButton>
        </Box>
      </Box>
      {isAddressModalOpen && (
        <ManualAddressDialog
          isAddressModalOpen={isAddressModalOpen}
          setIsAddressModalOpen={setIsAddressModalOpen}
          onboardingForm={onboardingForm}
          setOnboardingForm={setOnboardingForm}
        />
      )}
    </>
  );
};

export default OnboardingNewPractice;
