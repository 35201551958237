export const ROUTE_CONSTANTS_VARIABLE = {
  INTRODUCTION_SCREEN: '/introduction',
  INFO_PAGE: '/info',
  LOGIN: '/login',
  DASHBOARD: '/dashboard',
  GUEST_DOCUMENT_SCREEN: '/doc/:token',
  GUEST_REFERRAL_SCREEN: '/referral/:token',
  ONBOARDING: '/onboarding',
  ONBOARDING_NEW_PRACTICE: '/onboarding/new-practice',
  ONBOARDING_PERSONAL_INFO: '/onboarding/personal-info',
  ONBOARDING_PENDING_APPROVAL: '/onboarding/pending-approval',
  SET_PASSWORD: '/set-password',
  CHAT: 'chat',
  DOCUMENTS: 'documents',
  MY_NETWORK: 'my-network',
  MY_OFFICE: 'my-office',
  PROFILE: 'profile',
  REFERRAL: '/dashboard/documents/referral',
  EFAX: 'efax',
  REFER_A_PATIENT: 'refer-a-patient',
  MY_NETWORK_VIEW_CONNECTION: 'my-network/:practiceId',
};
