import { Alert, Snackbar } from '@mui/material';
import { useState } from 'react';
import { useAppSelector } from '../app/hooks';
import { hideSnackbarAction } from '../store/actions/snackbarAction';

const SnackbarHelper = () => {
  const { open, statusCode, message, severity } = useAppSelector(
    ({ snackbarReducer }) => snackbarReducer ?? {}
  );
  const [isDefaultCase, setIsDefaultCase] = useState<boolean>(true);
  const handleClose = () => {
    setIsDefaultCase(false);
    hideSnackbarAction();
  };

  switch (statusCode) {
    case 500:
    case 401:
    case 403:
    case 200:
    case 201:
    case 400:
    case 404:
      return (
        <Snackbar
          open={open}
          autoHideDuration={3000}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert severity={severity}>
            {message ?? 'Something went wrong, please try again.'}
          </Alert>
        </Snackbar>
      );

    default:
      return isDefaultCase ? (
        <Snackbar
          open={open}
          autoHideDuration={3000}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert severity='error'>
            Something went wrong, please try again.
          </Alert>
        </Snackbar>
      ) : (
        <></>
      );
  }
};

export default SnackbarHelper;
