import { Box } from '@mui/material';
import { TEST_CONSTANTS } from '../../../constants/testConstants';
import { headerStyle } from '../../../style/headerStyle';
import HeaderNotification from './HeaderNotification';
import HeaderProfile from './headerProfile/HeaderProfile';
import { useAppSelector } from '../../../app/hooks';
import HeaderSearchBar from './headerSearchbar/HeaderSearchBar';
import { useLocation } from 'react-router-dom';
import { ROUTE_CONSTANTS_VARIABLE } from '../../../constants/routeConstants';

const DashboardHeader = () => {
  const location = useLocation();
  const userInfo = useAppSelector((state) => state.userReducer.userInfo);
  const shouldHideSearchBar = location.pathname.includes(
    ROUTE_CONSTANTS_VARIABLE.REFER_A_PATIENT
  );

  if (!userInfo) return null;

  return (
    <Box
      data-testid={TEST_CONSTANTS.DASHBOARD_HEADER_CONTAINER}
      sx={headerStyle.headerContainer}
    >
      {!shouldHideSearchBar && <HeaderSearchBar />}
      <HeaderNotification />
      <HeaderProfile userInfo={userInfo} />
    </Box>
  );
};

export default DashboardHeader;
