import { Menu, MenuItem } from '@mui/material';
import React from 'react';
import { downloadEfaxAction } from '../../../../store/actions/dashboardActions/efaxActions';
import { EfaxTableDetailsType } from '../../../../types/efaxTypes';

type DocumentsTableActionMenuProps = {
  index: number;
  selectedFaxIndex: number | null;
  setSelectedFaxIndex: React.Dispatch<React.SetStateAction<number | null>>;
  actionMenuElement: null | HTMLElement;
  isActionMenuOpen: boolean;
  targetFax: EfaxTableDetailsType;
  setActionMenuElement: React.Dispatch<
    React.SetStateAction<null | HTMLElement>
  >;
};

const EfaxTableActionMenu = (props: DocumentsTableActionMenuProps) => {
  const {
    index,
    selectedFaxIndex,
    setSelectedFaxIndex,
    actionMenuElement,
    setActionMenuElement,
    isActionMenuOpen,
    targetFax,
  } = props;

  const handleActionMenuClose = () => {
    setActionMenuElement(null);
    setSelectedFaxIndex(null);
  };

  const onClickDownload = () => {
    const fileName = targetFax?.previewUrl?.split('/')?.pop();

    if (!!fileName) {
      downloadEfaxAction(fileName).then(() => {
        handleActionMenuClose();
      });
    }
  };

  return (
    <Menu
      id={`action-menu-${index}`}
      open={isActionMenuOpen && index === selectedFaxIndex}
      onClose={handleActionMenuClose}
      anchorEl={actionMenuElement}
    >
      <MenuItem onClick={onClickDownload} disabled={!targetFax?.previewUrl}>
        Download
      </MenuItem>
    </Menu>
  );
};

export default EfaxTableActionMenu;
