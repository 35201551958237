import { createApi } from '@reduxjs/toolkit/query/react';
import { DASHBOARD_URLS } from 'constants/urlConstants';
import _ from 'lodash';
import {
  ReferralPractice,
  PracticeByToken,
} from 'types/referralTypes/referralTypes';
import baseQueryWithReauth from './serviceQuery';

/**
 * Practice API
 */
const practiceService = createApi({
  reducerPath: 'practiceApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (build) => ({
    getConnectedReferralPractices: build.query<ReferralPractice[], void>({
      query: () => ({
        url: DASHBOARD_URLS.MY_NETWORK_URL
          .GET_CONNECTED_PRACTICES_FOR_REFERRALS,
        method: 'GET',
      }),
      transformResponse: (data: ReferralPractice[]) => {
        let list: ReferralPractice[] = data ?? [];

        // Sorts the practice by name alphabetically
        if (list.length > 1) {
          list = _.sortBy(list, [(practice) => practice.Name]);

          // Sorts the practice' by name alphabetically
          _.map(list, function (practice) {
            practice.MyTeam = _.sortBy(practice.MyTeam, [
              (team) => team.firstName,
            ]);
          });
        }

        return list;
      },
    }),
    getPracticeByToken: build.query<PracticeByToken, string | undefined>({
      query: (token) => ({
        url: `${DASHBOARD_URLS.DOCUMENTS_URL.GET_PRACTICE_BY_TOKEN}/${token}`,
        method: 'GET',
      }),
    }),
    submitStepOneWithEmail: build.mutation<any, { email: string }>({
      query: ({ email }) => ({
        url: DASHBOARD_URLS.DOCUMENTS_URL
          .WEBSITE_REFERRAL_STEP_ONE_SUBMIT_EMAIL,
        method: 'POST',
        body: { email },
      }),
    }),
  }),
});

export const {
  useGetConnectedReferralPracticesQuery,
  useGetPracticeByTokenQuery,
  useSubmitStepOneWithEmailMutation,
} = practiceService;

export const { getPracticeByToken } = practiceService.endpoints;

export default practiceService;
