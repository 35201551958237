import moment from 'moment';
import React from 'react';
import { downloadFile } from '../../../helpers/downloadHelper';
import { generalErrorHandler } from '../../../helpers/generalErrorHandlerHelper';
import { dashboardApiServices } from '../../../services/apiServices/dashboardApiServices';
import {
  AddContactFormData,
  AddContactRequestData,
  AddPatientRequestData,
  DocumentsTableDetailsDeleteRecordRequestBody,
  DocumentsTableDetailsType,
  FileConfig,
  ForwardDocumentsRequestBody,
  GetRecipientsResponseData,
  PracticeOptionUI,
  PracticeRecipientData,
  ReceivedDocumentStatusFilter,
  SendDocumentFormDataType,
  SendDocumentRecordStatus,
  SendDocumentsRequestData,
  Thumbnail,
  UploadedDocument,
} from '../../../types/documentsTypes';
import { AutocompleteOption } from '../../../types/Misc';
import { DOCUMENT_REDUX_CONSTANTS } from '../../reduxConstants/documentsReduxConstants';
import { AppDispatch } from '../../store';
import {
  startGeneralLoaderOnRequest,
  stopGeneralLoaderOnSuccessOrFail,
} from '../generalLoaderAction';
import { setHeaderSearchTextAction } from '../headerActions';
import { showSnackbarAction } from '../snackbarAction';
import { PracticeListResponseData } from '../../../types/myNetworkTypes';
import localStorageHelper from '../../../helpers/localStorageHelper';
import _ from 'lodash';
import { dentistPracticeType } from 'constants/userConstants';
import { FILE_EXTENSION_REGEX } from 'constants/regexConstants';

export const getConnectedPracticesAction = () => {
  return async (dispatch: AppDispatch) => {
    try {
      const response =
        await dashboardApiServices.myNetwork.getConnectedPracticeList();
      if (response?.status === 200) {
        dispatch({
          type: DOCUMENT_REDUX_CONSTANTS.GET_CONNECTED_PRACTICES,
          payload: response.data,
        });
      }
    } catch (e: any) {
      generalErrorHandler(e);
    }
  };
};

export const setSendDocumentFormDataAction = (
  formData: SendDocumentFormDataType
) => {
  return (dispatch: AppDispatch) => {
    dispatch({
      type: DOCUMENT_REDUX_CONSTANTS.SET_SEND_DOCUMENT_FORMDATA,
      payload: formData,
    });
  };
};

export const onChangeDocumentsTabAction = (
  selectedTab: 0 | 1,
  guestToken?: string | undefined
) => {
  return (dispatch: AppDispatch) => {
    dispatch({
      type: DOCUMENT_REDUX_CONSTANTS.ON_CHANGE_SELECTED_TAB,
      payload: { selectedTab, guestToken },
    });
    dispatch({
      type: DOCUMENT_REDUX_CONSTANTS.SET_DOCUMENT_TABLE_FILTER_OPTIONS,
    });
  };
};

export const getPatientListAction = () => {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await dashboardApiServices.document.getPatientList();
      if (response?.status === 200) {
        dispatch({
          type: DOCUMENT_REDUX_CONSTANTS.GET_PATIENT_LIST,
          payload: response.data,
        });
      }
    } catch (e: any) {
      generalErrorHandler(e);
    }
  };
};

export const uploadImageDocumentAction = async (
  file: FormData,
  thumbnailFile: FormData,
  callbackFunc: (dataUrl: string, dataThumbnailUrl: string) => void,
  blobType: string = 'Private'
): Promise<any> => {
  try {
    startGeneralLoaderOnRequest('isUploadDocumentLoaderActive');
    const config: FileConfig = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };
    const response = await Promise.all([
      dashboardApiServices.document.uploadDocument(blobType, file, config),
      dashboardApiServices.document.uploadDocument(
        blobType,
        thumbnailFile,
        config
      ),
    ]);

    if (response[0]?.status === 200 && response[1]?.status === 200) {
      // if success response, this function will be called
      callbackFunc(response[0].data.url, response[1]?.data.url);
      return response[0].data;
    }
  } catch (e: any) {
    generalErrorHandler(e);
  } finally {
    stopGeneralLoaderOnSuccessOrFail('isUploadDocumentLoaderActive');
  }
};

export const uploadDocumentAction = async (
  file: FormData,
  callbackFunc: (dataUrl: string) => void,
  blobType: string = 'Private'
): Promise<any> => {
  try {
    startGeneralLoaderOnRequest('isUploadDocumentLoaderActive');
    const config: FileConfig = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };
    const response = await dashboardApiServices.document.uploadDocument(
      blobType,
      file,
      config
    );
    if (response.status === 200) {
      // if success response, this function will be called
      callbackFunc(response.data.url);
      return response.data;
    }
  } catch (e: any) {
    generalErrorHandler(e);
  } finally {
    stopGeneralLoaderOnSuccessOrFail('isUploadDocumentLoaderActive');
  }
};

export const downloadDocumentAction = async (
  fileName: string,
  blobType: string = 'Private'
): Promise<any> => {
  try {
    const response = await dashboardApiServices.document.downloadDocument(
      fileName,
      blobType
    );

    if (response?.status === 200) {
      return response;
    }
  } catch (e: any) {
    generalErrorHandler(e);
  }
};

export const deleteDocumentAction = async (
  fileName: string,
  setSelectedFiles: React.Dispatch<React.SetStateAction<Thumbnail[]>>,
  setUploadedFiles: React.Dispatch<React.SetStateAction<UploadedDocument[]>>
) => {
  try {
    startGeneralLoaderOnRequest('isDeleteFileLoaderActive');
    const blobType: 'Private' = 'Private';
    const response = await dashboardApiServices.document.deleteDocument({
      fileName,
      blobType,
    });
    if (response?.status === 200) {
      setSelectedFiles(
        (prevSelectedFiles: Thumbnail[]) =>
          prevSelectedFiles?.filter(
            (file: Thumbnail) => file?.name !== fileName
          ) ?? []
      );

      // updated uploaded file list by removing that deleted record - which will reflect in sent documents in request data
      setUploadedFiles(
        (prevUploadedFiles: UploadedDocument[]) =>
          prevUploadedFiles?.filter(
            (file: UploadedDocument) =>
              file?.attachmentUrl.split('/').pop() !== fileName
          ) ?? []
      );
    }
  } catch (e: any) {
    generalErrorHandler(e);
  } finally {
    stopGeneralLoaderOnSuccessOrFail('isDeleteFileLoaderActive');
  }
};

export const sendDocumentsAction = (formData: SendDocumentFormDataType) => {
  return async (dispatch: AppDispatch) => {
    const { recipients, patient, message, attachments } = formData;
    try {
      startGeneralLoaderOnRequest('isSendDocumentsLoaderActive');

      const sendDocumentPromises = recipients.map(async (practice) => {
        const patientName = `${patient?.firstName} ${patient?.lastName}`;
        const isFromRecipients = practice.type === 'recipient';
        const isExternalUser = !_.isEmpty(practice.email);

        const requestData: SendDocumentsRequestData = {
          toPracticeId: isExternalUser ? '' : practice.id,
          secureEmailAddress: isExternalUser ? practice.email! : '',
          secureEmailReceiverName: isFromRecipients ? practice.name : '',
          patientTag: patientName,
          patientDateOfBirth: moment(patient?.dateOfBirth).format('MM/DD/YYYY'),
          attachments: attachments,
          notes: message,
          approved: practice.isConnectedPractice,
        };

        dispatch({
          type: DOCUMENT_REDUX_CONSTANTS.UPDATE_SEND_DOCUMENT_STATUS_LIST,
          payload: {
            ...requestData,
            name: !!practice?.searchedEmailPracticeName?.length
              ? practice?.searchedEmailPracticeName
              : practice.name,
            status: 'inProgress',
          },
        });

        try {
          const response = await dashboardApiServices.document.sendDocument(
            requestData
          );

          if (response?.status === 200) {
            dispatch({
              type: DOCUMENT_REDUX_CONSTANTS.UPDATE_SEND_DOCUMENT_STATUS_LIST,
              payload: {
                ...requestData,
                name: !!practice?.searchedEmailPracticeName?.trim()?.length
                  ? practice?.searchedEmailPracticeName
                  : practice.name,
                status: 'done',
              },
            });

            dispatch({
              type: DOCUMENT_REDUX_CONSTANTS.UPDATE_DOCUMENT_LIST,
              payload: response.data,
            });
          } else {
            dispatch({
              type: DOCUMENT_REDUX_CONSTANTS.UPDATE_SEND_DOCUMENT_STATUS_LIST,
              payload: {
                ...requestData,
                name: !!practice?.searchedEmailPracticeName?.trim()?.length
                  ? practice?.searchedEmailPracticeName
                  : practice.name,
                status: 'failed',
              },
            });
          }
        } catch (error) {
          dispatch({
            type: DOCUMENT_REDUX_CONSTANTS.UPDATE_SEND_DOCUMENT_STATUS_LIST,
            payload: {
              ...requestData,
              name: !!practice?.searchedEmailPracticeName?.trim()?.length
                ? practice?.searchedEmailPracticeName
                : practice.name,
              status: 'failed',
            },
          });
        }
      });

      await Promise.all(sendDocumentPromises);
    } catch (e: any) {
      generalErrorHandler(e);
    } finally {
      stopGeneralLoaderOnSuccessOrFail('isSendDocumentsLoaderActive');
    }
  };
};

export const resendDocumentItemAction = (
  document: DocumentsTableDetailsType
) => {
  return async (dispatch: AppDispatch) => {
    try {
      startGeneralLoaderOnRequest('isSendDocumentsLoaderActive');

      const requestData: SendDocumentsRequestData = {
        toPracticeId: document.toPracticeId,
        secureEmailAddress: document.secureEmailAddress ?? '',
        patientTag: document.patientTag,
        patientDateOfBirth: document.patientDateOfBirth ?? '',
        attachments: document.attachments,
        notes: document.notes,
        approved: document.approved,
      };

      const recipientPracticeName =
        document.toPracticeName !== '' &&
        document.toPracticeName !== null &&
        document.toPracticeName !== undefined
          ? document.toPracticeName
          : document.secureEmailReceiverName !== '' &&
            document.secureEmailReceiverName !== null &&
            document.secureEmailReceiverName !== undefined
          ? document.secureEmailReceiverName
          : document.secureEmailAddress;
      dispatch({
        type: DOCUMENT_REDUX_CONSTANTS.UPDATE_SEND_DOCUMENT_STATUS_LIST,
        payload: {
          ...requestData,
          name: recipientPracticeName,
          status: 'inProgress',
        },
      });

      try {
        const response = await dashboardApiServices.document.sendDocument(
          requestData
        );

        if (response?.status === 200) {
          dispatch({
            type: DOCUMENT_REDUX_CONSTANTS.UPDATE_SEND_DOCUMENT_STATUS_LIST,
            payload: {
              ...requestData,
              name: recipientPracticeName,
              status: 'done',
            },
          });

          dispatch({
            type: DOCUMENT_REDUX_CONSTANTS.UPDATE_DOCUMENT_LIST,
            payload: response.data,
          });
        } else {
          dispatch({
            type: DOCUMENT_REDUX_CONSTANTS.UPDATE_SEND_DOCUMENT_STATUS_LIST,
            payload: {
              ...requestData,
              name: recipientPracticeName,
              status: 'failed',
            },
          });
        }
      } catch (error) {
        dispatch({
          type: DOCUMENT_REDUX_CONSTANTS.UPDATE_SEND_DOCUMENT_STATUS_LIST,
          payload: {
            ...requestData,
            name: recipientPracticeName,
            status: 'failed',
          },
        });
      }
    } catch (e: any) {
      generalErrorHandler(e);
    } finally {
      stopGeneralLoaderOnSuccessOrFail('isSendDocumentsLoaderActive');
    }
  };
};

export const retrySendDocumentsAction = (
  sendDocumentRecord: SendDocumentRecordStatus
) => {
  const {
    toPracticeId,
    secureEmailAddress,
    patientTag,
    patientDateOfBirth,
    attachments,
    notes,
    approved,
  } = sendDocumentRecord;
  return async (dispatch: AppDispatch) => {
    try {
      startGeneralLoaderOnRequest('isSendDocumentsLoaderActive');
      dispatch({
        type: DOCUMENT_REDUX_CONSTANTS.UPDATE_SEND_DOCUMENT_STATUS_LIST,
        payload: { ...sendDocumentRecord, status: 'inProgress' },
      });
      const requestData = {
        toPracticeId,
        secureEmailAddress,
        patientTag,
        patientDateOfBirth,
        attachments,
        notes,
        approved,
      };

      const { data, status } = await dashboardApiServices.document.sendDocument(
        requestData
      );

      if (status === 200) {
        dispatch({
          type: DOCUMENT_REDUX_CONSTANTS.UPDATE_SEND_DOCUMENT_STATUS_LIST,
          payload: { ...sendDocumentRecord, status: 'done' },
        });
        dispatch({
          type: DOCUMENT_REDUX_CONSTANTS.UPDATE_DOCUMENT_LIST,
          payload: data,
        });
      }
    } catch (e) {
      dispatch({
        type: DOCUMENT_REDUX_CONSTANTS.UPDATE_SEND_DOCUMENT_STATUS_LIST,
        payload: { ...sendDocumentRecord, status: 'failed' },
      });
    } finally {
      stopGeneralLoaderOnSuccessOrFail('isSendDocumentsLoaderActive');
    }
  };
};

export const getDocumentsTableDetailsAction = (
  setSentDocumentRecordCount?: React.Dispatch<React.SetStateAction<boolean>>
) => {
  return async (dispatch: AppDispatch) => {
    try {
      if (setSentDocumentRecordCount) {
        setSentDocumentRecordCount(false);
      }
      startGeneralLoaderOnRequest('isDocumentTableDetailsLoaderActive');
      const response =
        await dashboardApiServices.document.documentsTable.getDocumentsTableDetails();

      if (response?.status === 200) {
        if (setSentDocumentRecordCount) {
          const isUserDentist = dentistPracticeType.includes(
            localStorageHelper.getUserInfo()?.practiceType!
          );
          setSentDocumentRecordCount(
            response.data?.filter(
              (document: DocumentsTableDetailsType) =>
                document.fromPracticeId ===
                  localStorageHelper.getPracticeId() && document.isReferral
            ).length < 5 && isUserDentist
          );
        }
        dispatch({
          type: DOCUMENT_REDUX_CONSTANTS.GET_DOCUMENT_LIST,
          payload: response.data,
        });
        dispatch({
          type: DOCUMENT_REDUX_CONSTANTS.SET_DOCUMENT_TABLE_FILTER_OPTIONS,
        });
      }
    } catch (e: any) {
      generalErrorHandler(e);
    } finally {
      stopGeneralLoaderOnSuccessOrFail('isDocumentTableDetailsLoaderActive');
    }
  };
};

export const getAddressBookContactsAction = () => {
  return async (dispatch: AppDispatch) => {
    try {
      startGeneralLoaderOnRequest('isGetAddressBookContactsActive');

      const response = await Promise.all([
        dashboardApiServices.document.addressBook.getRecipients(),
        dashboardApiServices.myNetwork.getConnectedPracticeList(),
      ]);

      const isAllSuccess = response.every((res) => res.status === 200);

      if (isAllSuccess) {
        let addressBookContacts: PracticeOptionUI[] = [];
        const getRecipientsResponseData = (response?.[0]?.data.data ??
          []) as GetRecipientsResponseData[];
        const targetRecipients = getRecipientsResponseData
          .filter((recipient) => recipient.type === 'recipient')
          .sort((a, b) => {
            const dateA = new Date(a.createdDate);
            const dateB = new Date(b.createdDate);

            return dateB.getTime() - dateA.getTime();
          });

        targetRecipients.forEach((recipient) => {
          const contact = {
            id: recipient.id,
            name: recipient.practiceName,
            email: recipient.email,
            isConnectedPractice: true,
            isNewEmailRecipient: false,
            type: recipient.type,
          } as PracticeOptionUI;

          addressBookContacts.push(contact);
        });

        const getConnectedPracticesResponseData = (response?.[1]?.data ??
          []) as PracticeListResponseData[];

        getConnectedPracticesResponseData.forEach((practice) => {
          const contact = {
            id: practice.id,
            name: practice.name,
            category: practice.category,
            orgType: practice.organizationType,
            isConnectedPractice: true,
            isNewEmailRecipient: false,
          } as PracticeOptionUI;

          addressBookContacts.push(contact);
        });

        dispatch({
          type: DOCUMENT_REDUX_CONSTANTS.SET_ADDRESS_BOOK_CONTACTS,
          payload: addressBookContacts,
        });
      }
    } catch (e: any) {
      generalErrorHandler(e);
    } finally {
      stopGeneralLoaderOnSuccessOrFail('isGetAddressBookContactsActive');
    }
  };
};

export const downloadAttachmentAction = async (
  fileName: string,
  attachmentName: string
) => {
  try {
    const params = {
      blobType: '0',
    };
    const response =
      await dashboardApiServices.document.documentsTable.actions.downloadAttachment(
        fileName,
        params
      );
    if (response?.status === 200) {
      downloadFile(response, attachmentName);
    }
  } catch (e: any) {
    showSnackbarAction({
      open: true,
      statusCode: 404,
      message:
        e?.status === 404
          ? 'Document not found, please contact the sender'
          : e?.ExceptionMessage ?? e?.Message,
      severity: 'error',
    });
  }
};

export const downloadDocumentByGuestTokenAction = async (
  token: string,
  fileName: string,
  attachmentName: string,
  blobType?: number | null
) => {
  try {
    const response =
      await dashboardApiServices.document.previewOrDownloadDocumentByToken(
        token,
        fileName,
        blobType
      );
    if (response?.status === 200) {
      downloadFile(response, attachmentName);
    }
  } catch (e) {
    generalErrorHandler(e);
  }
};

export const deleteDocumentsTableRowAction = async (
  data: DocumentsTableDetailsDeleteRecordRequestBody,
  callbackFun: () => void
) => {
  try {
    startGeneralLoaderOnRequest('isDeleteDocumentRowLoaderActive');

    const response =
      await dashboardApiServices.document.documentsTable.actions.deleteRow(
        data
      );
    if (response?.status === 200) {
      callbackFun();
    }
  } catch (e: any) {
    generalErrorHandler(e);
  } finally {
    stopGeneralLoaderOnSuccessOrFail('isDeleteDocumentRowLoaderActive');
  }
};

export const removeDocumentTableRecordAction = (
  documentRecord: DocumentsTableDetailsType
) => {
  return (dispatch: AppDispatch) => {
    try {
      dispatch({
        type: DOCUMENT_REDUX_CONSTANTS.DELETE_DOCUMENT_TABLE_ROW,
        payload: documentRecord,
      });
    } catch (e: any) {
      generalErrorHandler(e);
    }
  };
};

export const onDocumentTableFilterChangeAction = (filterData: {
  name: string;
  value: string | AutocompleteOption | ReceivedDocumentStatusFilter | null;
}) => {
  return (dispatch: AppDispatch) => {
    dispatch({
      type: DOCUMENT_REDUX_CONSTANTS.ON_CHANGE_OR_APPLY_DOCUMENT_TABLE_FILTER,
      payload: filterData,
    });
    dispatch(setHeaderSearchTextAction(''));
  };
};

// received document status filter change
export const onDocumentStatusFilterChangeAction = (
  value: ReceivedDocumentStatusFilter
) => {
  return (dispatch: AppDispatch) => {
    dispatch({
      type: DOCUMENT_REDUX_CONSTANTS.ON_RECEIVED_DOCUMENT_STATUS_CHANGE,
      payload: value,
    });
    dispatch(setHeaderSearchTextAction(''));
  };
};

export const createDocPreviewAction = async (
  fileName: string,
  setData: React.Dispatch<React.SetStateAction<any>>,
  setIsFetchingDocument: React.Dispatch<React.SetStateAction<boolean>>
) => {
  try {
    setIsFetchingDocument(true);

    const params = {
      blobType: '0',
    };
    const response =
      await dashboardApiServices.document.documentsTable.actions.downloadAttachment(
        fileName,
        params
      );

    if (response?.status === 200) {
      const docType = () => {
        /**
         * Retrieves the file extension from the provided file name.
         *
         * e.g
         * fileName.pdf -> pdf
         * fileName.pdf?param1=xxx&param2=xxx -> pdf
         * fileName.12345.pdf -> pdf
         * fileName.12345.pdf?param1=xxx&param2=xxx -> pdf
         */
        const fileExtension = fileName.match(FILE_EXTENSION_REGEX)?.[1] || '';

        switch (fileExtension.toLowerCase()) {
          case 'pdf':
            return 'application/pdf';

          case 'xlsx':
            return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

          default:
            return 'application/vnd.ms-excel';
        }
      };

      const blob = new Blob([response.data], {
        type: docType(),
      });

      setData(window.URL.createObjectURL(blob));
    }
  } catch (e: any) {
    generalErrorHandler(e);
  } finally {
    setIsFetchingDocument(false);
  }
};

export const previewDocumentByGuestTokenAction = async (
  token: string,
  fileName: string,
  setData: React.Dispatch<React.SetStateAction<any>>,
  setIsFetchingDocument: React.Dispatch<React.SetStateAction<boolean>>,
  blobType?: number | null
) => {
  try {
    setIsFetchingDocument(true);
    const { status, data } =
      await dashboardApiServices.document.previewOrDownloadDocumentByToken(
        token,
        fileName,
        blobType
      );
    if (status === 200) {
      const docType = () => {
        switch (fileName.split('.').pop()?.toLowerCase()) {
          case 'pdf':
            return 'application/pdf';

          case 'xlsx':
            return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

          default:
            return 'application/vnd.ms-excel';
        }
      };

      const blob = new Blob([data], {
        type: docType(),
      });

      setData(window.URL.createObjectURL(blob));
    }
  } catch (e) {
    generalErrorHandler(e);
  } finally {
    setIsFetchingDocument(false);
  }
};

export const onChangeDocTypeAction = (
  sharedDocId: string,
  data: { isReferral: boolean },
  callbackFunc: () => void
) => {
  return async (dispatch: AppDispatch) => {
    try {
      startGeneralLoaderOnRequest('isDocTypeChangeLoaderActive');
      const response =
        await dashboardApiServices.document.documentsTable.actions.changeDocType(
          sharedDocId,
          data
        );

      if (response?.status === 200) {
        callbackFunc();
        dispatch({
          type: DOCUMENT_REDUX_CONSTANTS.ON_CHANGE_DOCUMENT_TYPE,
          payload: response.data,
        });
      }
    } catch (e: any) {
      generalErrorHandler(e);
    } finally {
      stopGeneralLoaderOnSuccessOrFail('isDocTypeChangeLoaderActive');
    }
  };
};

export const processDocumentRecordAction = (officeFileId: string) => {
  return async (dispatch: AppDispatch) => {
    try {
      startGeneralLoaderOnRequest('isDocumentProcessLoaderActive');
      const response =
        await dashboardApiServices.document.documentsTable.processDocument(
          officeFileId
        );
      if (response?.status === 200) {
        dispatch({
          type: DOCUMENT_REDUX_CONSTANTS.ON_PROCESS_DOCUMENT_RECORD,
          payload: officeFileId,
        });
        showSnackbarAction({
          open: true,
          statusCode: 200,
          message: 'Document(s) processed successfully',
          severity: 'success',
        });
      }
    } catch (e: any) {
      generalErrorHandler(e);
    } finally {
      stopGeneralLoaderOnSuccessOrFail('isDocumentProcessLoaderActive');
    }
  };
};

export const forwardDocumentsAction = (
  data: ForwardDocumentsRequestBody,
  callbackFunc: () => void
) => {
  return async (dispatch: AppDispatch) => {
    try {
      startGeneralLoaderOnRequest('isForwardDocumentsLoaderActive');

      const response =
        await dashboardApiServices.document.documentsTable.actions.forwardDocuments(
          data
        );
      if (response?.status === 200) {
        dispatch({
          type: DOCUMENT_REDUX_CONSTANTS.UPDATE_DOCUMENT_LIST,
          payload: response.data,
        });
        callbackFunc();
      }
    } catch (e: any) {
      generalErrorHandler(e);
    } finally {
      stopGeneralLoaderOnSuccessOrFail('isForwardDocumentsLoaderActive');
    }
  };
};

// after successfully document sent and new patient added, instead of choosing from the existing patient list
export const addPatientAction = async (data: AddPatientRequestData) => {
  try {
    await dashboardApiServices.document.documentsTable.addPatient(data);
  } catch (e) {
    generalErrorHandler(e);
  }
};

export const onChangeRecipientDataAction =
  (data: AddContactFormData) => (dispatch: AppDispatch) => {
    dispatch({
      type: DOCUMENT_REDUX_CONSTANTS.ON_CHANGE_RECIPIENT_DATA,
      payload: data,
    });
  };

export const addContactsAction = (selectedRecipients: AddContactFormData[]) => {
  return async (dispatch: AppDispatch) => {
    try {
      startGeneralLoaderOnRequest('isAddContactLoaderActive');

      const addContactsPromises = selectedRecipients.map(async (recipient) => {
        const data: AddContactRequestData = { ...recipient, Type: 'recipient' };
        const response = await dashboardApiServices.document.addContact(data);

        if (response.status === 200) {
          showSnackbarAction({
            open: true,
            statusCode: 200,
            message: `${recipient.Email} added successfully`,
            severity: 'success',
          });

          const newRecipient = response.data.data as GetRecipientsResponseData;

          dispatch({
            type: DOCUMENT_REDUX_CONSTANTS.ON_ADD_NEW_RECIPIENT_TO_FORM_DATA,
            payload: newRecipient,
          });
        }
      });

      await Promise.all(addContactsPromises);
    } catch (e: any) {
      generalErrorHandler(e);
    } finally {
      stopGeneralLoaderOnSuccessOrFail('isAddContactLoaderActive');
    }
  };
};

export const clearRecipientsListAction = () => (dispatch: AppDispatch) => {
  dispatch({
    type: DOCUMENT_REDUX_CONSTANTS.CLEAR_RECIPIENT_LIST,
  });
};

export const deleteRecipientDataAction = () => (dispatch: AppDispatch) => {
  dispatch({
    type: DOCUMENT_REDUX_CONSTANTS.DELETE_RECIPIENT_DATA,
  });
};

export const acceptPendingDocumentAction = (officeFileId: string) => {
  return async (dispatch: AppDispatch) => {
    try {
      startGeneralLoaderOnRequest('isAcceptPendingDocumentLoaderActive');
      const response =
        await dashboardApiServices.document.documentsTable.acceptDocument(
          officeFileId
        );

      if (response?.status === 200) {
        dispatch({
          type: DOCUMENT_REDUX_CONSTANTS.ON_ACCEPT_PENDING_DOCUMENT,
          payload: officeFileId,
        });
      }
    } catch (e: any) {
      generalErrorHandler(e);
    } finally {
      stopGeneralLoaderOnSuccessOrFail('isAcceptPendingDocumentLoaderActive');
    }
  };
};

export const getDocumentListByTokenAction =
  (token: string) => async (dispatch: AppDispatch) => {
    try {
      const response = await dashboardApiServices.document.getDocumentsByToken(
        token
      );

      if (response.status === 200) {
        dispatch({
          type: DOCUMENT_REDUX_CONSTANTS.GET_DOCUMENT_LIST_BY_TOKEN,
          payload: { data: response.data, guestToken: token },
        });
      }
    } catch (e) {
      generalErrorHandler(e);
    }
  };

export const declinePendingDocumentAction = (officeFileId: string) => {
  return async (dispatch: AppDispatch) => {
    try {
      startGeneralLoaderOnRequest('isDeclinePendingDocumentLoaderActive');
      const response =
        await dashboardApiServices.document.documentsTable.rejectDocument(
          officeFileId
        );

      if (response?.status === 200) {
        dispatch({
          type: DOCUMENT_REDUX_CONSTANTS.ON_REJECT_PENDING_DOCUMENT,
          payload: officeFileId,
        });
      }
    } catch (e: any) {
      generalErrorHandler(e);
    } finally {
      stopGeneralLoaderOnSuccessOrFail('isDeclinePendingDocumentLoaderActive');
    }
  };
};

export const removeRejectedDocumentAction = (officeFileId: string) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch({
        type: DOCUMENT_REDUX_CONSTANTS.ON_REJECT_PENDING_DOCUMENT,
        payload: officeFileId,
      });
    } catch (e: any) {
      generalErrorHandler(e);
    }
  };
};

export const updateAcceptedDocumentAction = (officeFileId: string) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch({
        type: DOCUMENT_REDUX_CONSTANTS.ON_ACCEPT_PENDING_DOCUMENT,
        payload: officeFileId,
      });
    } catch (e: any) {
      generalErrorHandler(e);
    }
  };
};

export const resetSendDocumentStatusListAction = () => {
  return (dispatch: AppDispatch) => {
    dispatch({
      type: DOCUMENT_REDUX_CONSTANTS.RESET_SEND_DOCUMENT_STATUS_LIST,
    });
  };
};

export const setNewRecipientFormDataAction =
  (data: AddContactFormData) => (dispatch: AppDispatch) => {
    dispatch({
      type: DOCUMENT_REDUX_CONSTANTS.SET_NEW_RECIPIENT_FORM_DATA,
      payload: data,
    });
  };

export const getPracticeDirectoryAction = () => {
  return async (dispatch: AppDispatch) => {
    try {
      startGeneralLoaderOnRequest('isGetPracticeDirectoryLoaderActive');
      const { data, status } =
        await dashboardApiServices.document.documentsTable.getPracticeDirectory();
      if (status === 200) {
        dispatch({
          type: DOCUMENT_REDUX_CONSTANTS.GET_PRACTICE_DIRECTORY_DATA,
          payload: { practices: data.Practices, recipients: data.Recipients },
        });
      }
    } catch (e) {
      generalErrorHandler(e);
    } finally {
      stopGeneralLoaderOnSuccessOrFail('isGetPracticeDirectoryLoaderActive');
    }
  };
};

export const setSendDocumentDialogStepAction =
  (step: 1 | 2) => (dispatch: AppDispatch) => {
    dispatch({
      type: DOCUMENT_REDUX_CONSTANTS.SET_SEND_DOCUMENT_DIALOG_STEP,
      payload: step,
    });
  };

export const onUpdatePracticeRecipient = (data: {
  id: string;
  newName: string;
  newEmail: string;
}) => {
  return async (dispatch: AppDispatch) => {
    try {
      startGeneralLoaderOnRequest('isUpdatingPracticeRecipient');

      const response =
        await dashboardApiServices.document.addressBook.getRecipientDetail(
          data.id
        );
      if (response?.status === 200) {
        const practiceRecipientData = response.data
          ?.data as PracticeRecipientData;

        const updatedRecipientData = {
          Email: data.newEmail,
          PracticeName: data.newName,
          Id: practiceRecipientData.id,
          IsGlobal: practiceRecipientData.isGlobal,
          MobileNumber: practiceRecipientData.mobileNumber,
          DisplayMobileNumber: practiceRecipientData.mobileNumber,
          FirstName: practiceRecipientData.firstName,
          LastName: practiceRecipientData.lastName,
          Title: practiceRecipientData.title,
          ContactDetails: practiceRecipientData.contactDetails,
          Type: practiceRecipientData.type,
          SenderPracticeId: practiceRecipientData.senderPracticeId,
        };

        const updateRecipientResponse =
          await dashboardApiServices.document.addressBook.updateRecipient(
            updatedRecipientData
          );

        if (updateRecipientResponse.status === 200) {
          showSnackbarAction({
            open: true,
            statusCode: 200,
            message: `Recipient details updated successfully`,
            severity: 'success',
          });

          dispatch({
            type: DOCUMENT_REDUX_CONSTANTS.ON_UPDATE_PRACTICE_RECIPIENT,
            payload: data,
          });
        }
      }
    } catch (e: any) {
      generalErrorHandler(e);
    } finally {
      stopGeneralLoaderOnSuccessOrFail('isUpdatingPracticeRecipient');
    }
  };
};
