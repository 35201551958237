export const BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const PORTAL_BASE_URL = process.env.REACT_APP_PORTAL_BASE_URL;
export const SIGN_UP_BASE_URL = process.env.REACT_APP_SIGN_UP_BASE_URL;
export const CHATAPI_BASE_URL = process.env.REACT_APP_CHATAPI_BASE_URL;
export const UPLOAD_DOCUMENT_BASE_URL =
  process.env.REACT_APP_UPLOAD_DOCUMENT_BASE_URL;
export const PRIVATE_BLOB_BASE_URL =
  process.env.REACT_APP_PRIVATE_BLOB_BASE_URL;
export const PUBLIC_BLOB_BASE_URL = process.env.REACT_APP_PUBLIC_BLOB_BASE_URL;
export const ENV_NAME = process.env.REACT_APP_ENV_NAME;
export const IS_INTRO_SCREEN_VISIBLE = process.env.IS_INTRO_SCREEN_VISIBLE;

export const AUTH_URLS = {
  CHANGE_PASSWORD_URL: `${BASE_URL}/ChangePasswordAD`,
  LOGIN_URL: `${BASE_URL}/v4/authentication/login`,
  LOGOUT_URL: `${BASE_URL}/authLogout`,
  REGISTER_URL: `${SIGN_UP_BASE_URL}/webregister`,
  GUEST_REGISTER_URL: `${SIGN_UP_BASE_URL}/email/EMAIL_ADDRESS/register`,
  FORGOT_PASSWORD_LINK: `${PORTAL_BASE_URL}/forgot-password`,
  PRIVACY_POLICY_URL: 'https://www.drtalk.com/privacy-policy/',
  REFRESH_TOKEN: `${BASE_URL}/refreshToken`,
  TERMS_AND_CONDITIONS_URL: 'https://www.drtalk.com/terms-and-conditions/',
  VERIFY_TOKEN: `${BASE_URL}/v4/authentication/verify-token`,
  DR_TALK_WEBSITE: 'https://www.drtalk.com',
  SET_NEW_PASSWORD: `${BASE_URL}/user/v2/change-password`, // this end point will be used for changing password after receiving temp password (before onboarding)
  VALIDATE_EMAIL: `${BASE_URL}/person/v1/validateEmail` // validate email while guest user tries to send referral to a practice
};

export const HEADER_URLS = {
  MY_NETWORK_SEARCH_RESULTS: `${BASE_URL}/v2/search-my-network`,
  DOCUMENTS_SEARCH_RESULTS: `${BASE_URL}/office-files/v2/search`,
};

export const ONBOARDING_URLS = {
  SEARCH_ADDRESS_URL: `${BASE_URL}/address-lookup/v1`,
  ONBOARDING_OPEN_STREET_MAP_COPYRIGHT_LINK:
    'https://www.openstreetmap.org/copyright',
  ONBOARDING_FORM_SUBMIT: `${BASE_URL}/onboarding/v1/complete`,
  ONBOARDING_PERSONAL_INFO_FORM: `${BASE_URL}/person/v1/update-person-info`,
  ONBOARDING_GET_ALL_STATES: `${BASE_URL}/organizations/get-all-organizations-states`,
  ONBOARDING_GET_ORGANIZATIONS_BASED_ON_SELECTED_STATE: `${BASE_URL}/organizations/get-by-state`, // end point is - GET /organizations/get-by-state/{state}
  ONBOARDING_GET_SPECIALISTS_BASED_ON_SELECTED_STATE: `${BASE_URL}/practices/withSpecializations`,
  GET_PRACTICE_TYPES: `${BASE_URL}/practices/v1/metadata`, // to get the practice types
};

export const USER_URLS = {
  SYNC_USER_BY_ID: `${BASE_URL}/sync/user`,
  UPDATE_PERSONAL_INFO: `${BASE_URL}/person/v1`,
  UPDATE_NOTIFICATION_SETTINGS: `${BASE_URL}/person/v1/update-notification-settings`,
  SET_PROFILE_IMAGE: `${BASE_URL}/person/v2/profile-image`, // after uploading the image(PUBLIC_FILE_URLS.UPDATE_PUBLIC_FILE_URL with this), need to update the profile image
  USER_WORK_STATUS_HUB: `${CHATAPI_BASE_URL}/userWorkStatusHub/`,
};

export const PUBLIC_FILE_URLS = {
  UPDATE_PUBLIC_FILE_URL: `${UPLOAD_DOCUMENT_BASE_URL}/FileWriter/v2/upload/Public/allow-overwrite`, // it is being used for updating profile image
  UPLOAD_PUBLIC_FILE_URL: `${UPLOAD_DOCUMENT_BASE_URL}/FileWriter/v2/upload/Public`,
  DELETE_PUBLIC_FILE_URL: `${UPLOAD_DOCUMENT_BASE_URL}/FileDeleter/v2/delete`,
};

export const CHAT_URLS = {
  CHANNELS_URL: `${CHATAPI_BASE_URL}/api/v1/channels`,
  MESSAGES_URL: `${CHATAPI_BASE_URL}/api/v1/messages`,
  POST_MESSAGES_URL: `${CHATAPI_BASE_URL}/api/v1/messages/forward`,
  DELETE_MESSAGES_URL: `${CHATAPI_BASE_URL}/api/v1/messages/channel/CHANNELID/message/MESSAGEID`,
  SEARCH_USER_URL: `${BASE_URL}/person/v1/search`,
  SEARCH_CHAT_URL: `${CHATAPI_BASE_URL}/api/v1/search`,
  MARK_CHANNEL_URL: `${CHATAPI_BASE_URL}/api/v1/channels/CHANNELID/messages/mark_as_MARK`,
  GENERATE_THUMBNAIL_URL: `${UPLOAD_DOCUMENT_BASE_URL}/thumbnailmanager/v1/generate-thumbnail`,
  UPLOAD_FILE_URL: `${UPLOAD_DOCUMENT_BASE_URL}/FileWriter/v2/upload/Private`,
  DOWNLOAD_FILE_URL: `${UPLOAD_DOCUMENT_BASE_URL}/FileReader/v1/download`,
  API_URL: `${CHATAPI_BASE_URL}/api/v1`,
};

export const NOTIFICATION_URLS = {
  UNREAD_MESSAGES_COUNT_URL: `${CHATAPI_BASE_URL}/api/v1/messages/unread_count`,
  PRACTICE_INVITES_COUNT_URL: `${BASE_URL}/practice-invites/v1/pending/count`,
  UNPROCESSED_DOCUMENTS_COUNT_URL: `${BASE_URL}/office-files/V1/unprocessed/count`,
  HUB_URL: `${CHATAPI_BASE_URL}/notificationhub`,
};

export const DASHBOARD_URLS = {
  EFAX_URL: {
    GET_FAX_MESSAGES: `${BASE_URL}/efax/getfaxmessages`,
    GET_EFAX_TABLE_DETAILS: `${BASE_URL}/office-files/V2`,
    GET_EFAX_CONTACT_HISTORY: `${BASE_URL}/efax/contacts/0`,
    GET_EFAX_LIST: `${BASE_URL}/office-files/sync`,
    SEND_EFAX: `${BASE_URL}/efax/sendfax`,
    SAVE_CONTACT: `${BASE_URL}/efax/efaxcontact`,
    RESEND_EFAX: `${BASE_URL}/efax/resendfax`,
    PROCESS_EFAX: `${BASE_URL}/efax/mark-as-processed`,
    EFAX_TABLE_ACTIONS: {
      DOWNLOAD_EFAX: `${BASE_URL}/efax/download`,
      DOWNLOAD_ATTACHMENTS: `${UPLOAD_DOCUMENT_BASE_URL}/FileReader/v1/download`,
    },
  },
  DOCUMENTS_URL: {
    GET_DOCUMENTS: `${BASE_URL}/office-files/sync`,
    GET_PATIENT_LIST: `${BASE_URL}/patient/v1`,
    UPLOAD_DOCUMENT: `${UPLOAD_DOCUMENT_BASE_URL}/FileWriter/v2/upload`,
    DELETE_DOCUMENT: `${UPLOAD_DOCUMENT_BASE_URL}/FileDeleter/v2/delete`,
    DOWNLOAD_DOCUMENT: `${UPLOAD_DOCUMENT_BASE_URL}/FileReader/v1/download`,
    SEND_DOCUMENT: `${BASE_URL}/office-files/v2`,
    SEND_REFERRAL: `${BASE_URL}/office-files/referral/v2`,
    GET_DOCUMENT_TABLE_DETAILS: `${BASE_URL}/office-files/V2`,
    PROCESS_DOCUMENT: `${BASE_URL}/office-files`,
    ADD_PATIENT: `${BASE_URL}/patient/v1`,
    // add recipient
    ADD_CONTACT: `${BASE_URL}/Recipient`,
    GET_RECIPIENTS: `${BASE_URL}/Recipient/sync`,
    GET_RECIPIENT_DETAIL: `${BASE_URL}/Recipient`,
    UPDATE_PRACTICE_RECIPIENT: `${BASE_URL}/Recipient`,
    DELETE_PRACTICE_RECIPIENT: `${BASE_URL}/Recipient`,
    // for guest user(who's not part of drtalk and tries to access documents shared via email link)
    GET_DOCUMENTS_BY_TOKEN: `${BASE_URL}/office-files/get-by-token`,
    // for website referral - get practice by token
    GET_PRACTICE_BY_TOKEN: `${BASE_URL}/practices/v1/get-by-token`,
    WEBSITE_REFERRAL_STEP_ONE_SUBMIT_EMAIL: `${BASE_URL}/user/v1/guest`,
    GUEST_USER_DOCUMENT_PREVIEW: `${UPLOAD_DOCUMENT_BASE_URL}/FileReader/v1/download`,
    DOCUMENTS_TABLE_ACTIONS: {
      DOWNLOAD_ATTACHMENTS: `${UPLOAD_DOCUMENT_BASE_URL}/FileReader/v1/download`,
      DELETE_ROW: `${BASE_URL}/office-files`,
      CHANGE_DOC_TYPE: `${BASE_URL}/office-files`,
      FORWARD_DOCUMENTS: `${BASE_URL}/office-files/v1/forward`,
    },
    GET_PRACTICE_DIRECTORY: `${BASE_URL}/practices/v1/practice-directory`,
    GUEST_WEBSITE_REFERRAL_DOWNLOAD_CUSTOM_FORM: `${UPLOAD_DOCUMENT_BASE_URL}/FileReader/v1/download/guest`,
    GUEST_WEBSITE_REFERRAL_UPLOAD_CUSTOM_FORM: `${UPLOAD_DOCUMENT_BASE_URL}/FileWriter/v1/guest/upload`,
    GUEST_WEBSITE_REFERRAL_ADD_PATIENT: `${BASE_URL}/patient/v1/guest`,
    GUEST_WEBSITE_SEND_REFERRAL: `${BASE_URL}/office-files/v1/guest`,
  },
  MY_NETWORK_URL: {
    MY_NETWORK_PRACTICE_LIST: `${BASE_URL}/practices/v2/connected`,
    MY_NETWORK_PENDING_CONNECTION_PRACTICE_INVITES: `${BASE_URL}/v3/practice-invite/sent`,
    MY_NETWORK_PENDING_REGISTRATION_INVITES: `${BASE_URL}/invitation/v1/organizations`,
    VIEW_CONNECTION: `${BASE_URL}/practices/v1`,
    INVITE_PRACTICE_COPY_LINK: `${SIGN_UP_BASE_URL}/webinvite`,
    INVITE_PRACTICE: `${BASE_URL}/invitation/send`,
    DELETE_SENT_INVITATION: `${BASE_URL}/v1/practice-invite`,
    DELETE_REGISTRATION_INVITATION: `${BASE_URL}/invitation/v1/delete`,
    GET_INVITATION_LIST: `${BASE_URL}/v1/practice-invites`,
    ACCEPT_OR_IGNORE_PRACTICE_INVITE: `${BASE_URL}/v1/practice-invite`,
    GET_RECOMMENDED_PRACTICE_LIST: `${BASE_URL}/v1/practices-you-may-know`,
    HIDE_RECOMMENDED_PRACTICE_LIST: `${BASE_URL}/v1/ignore-practice`,
    INVITE_RECOMMENDED_PRACTICE: `${BASE_URL}/v2/practice-invite`,
    GET_CONNECTED_PRACTICES: `${BASE_URL}/practices/v1/connected/search`,
    GET_RELATED_PATIENTS: `${BASE_URL}/patient/v1`,
    SEND_REFERRAL: `${BASE_URL}/office-files/referral/v2`,
    ADD_NEW_PATIENT: `${BASE_URL}/patient/v1`,
    SEARCH_PRACTICE_BY_NAME_OR_EMAIL: `${BASE_URL}/practices/v1/search-by-name-or-email`,
    GET_CONNECTED_PRACTICES_FOR_REFERRALS: `${BASE_URL}/practices/v1/connected/for-referrals`,
  },
  MY_OFFICE_URL: {
    GET_ORGANIZATION_DETAILS: `${BASE_URL}/practices/v1`,
    GET_TEAM_MEMBER_INVITATIONS: `${BASE_URL}/invitation/v1/get-team-member-invitations`,
    ADD_MEMBER: `${BASE_URL}/invitation/send`,
    GET_ROLES_OF_LOGGED_IN_USER_PRACTICE: `${BASE_URL}/practices/v1`,
  },
};
