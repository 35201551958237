import {
  Avatar,
  Box,
  Button,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from '@mui/material';
import { SyntheticEvent } from 'react';
import { v4 as uuidv4 } from 'uuid';
import Highlighter from '../../../../components/Highlighter';
import { practiceConnectionStatus } from '../../../../constants/practiceConnnectionStatusEnum';
import { TEST_CONSTANTS } from '../../../../constants/testConstants';
import { commonClasses } from '../../../../style/commonClasses';
import { headerStyle } from '../../../../style/headerStyle';
import { colorPalette } from '../../../../theme/colorPalette';
import { HeaderSearchOptionType } from '../../../../types/headerTypes';

export const SearchResultListItem = (
  props: object,
  option: HeaderSearchOptionType | null,
  searchedText: string,
  onClickConnect: (option: HeaderSearchOptionType) => void,
  invitationSentToPractices: string[]
) => {
  return (
    <ListItem
      data-testid={TEST_CONSTANTS.HEADER_SEARCH_BAR_RESULT_LABEL}
      key={uuidv4()}
      {...props}
      onClick={(event: SyntheticEvent) => event?.preventDefault()}
      sx={headerStyle.searchResultItem}
      secondaryAction={
        option?.ConnectionStatus === 0 &&
        !invitationSentToPractices.includes(option?.PracticeId) ? (
          <Button
            size='small'
            variant='contained'
            sx={headerStyle.optionActionButton}
            onClick={(e) => {
              e.stopPropagation();
              onClickConnect(option);
            }}
          >
            {practiceConnectionStatus[option?.ConnectionStatus]}
          </Button>
        ) : (
          <Box sx={commonClasses.cardTag}>
            {invitationSentToPractices.includes(option?.PracticeId!)
              ? 'Request Sent'
              : practiceConnectionStatus[option?.ConnectionStatus!]}
          </Box>
        )
      }
    >
      <ListItemAvatar>
        <Avatar
          alt={option?.IsPracticeResult! ? option.PracticeName : ''}
          src={!option?.IsPracticeResult ? option?.UserProfilePicture : ''}
          sx={headerStyle.searchResultAvatar}
        />
      </ListItemAvatar>
      <ListItemText
        primary={
          <Highlighter highlight={searchedText}>
            {option?.UserName!!}
          </Highlighter>
        }
        secondary={
          <Typography
            fontSize='.75rem'
            color={colorPalette.surfaceColor[900]}
            lineHeight='1rem'
          >
            {option?.IsPracticeResult ? (
              <>
                {option?.PracticeType}{' '}
                {(option?.PracticeCity?.length ||
                  option?.PracticeState?.length ||
                  option?.PracticeZip?.length) &&
                  `- 
          ${option?.PracticeCity?.length && option?.PracticeCity}${
                    option?.PracticeState?.length &&
                    `, ${option?.PracticeState}`
                  }${
                    option?.PracticeZip?.length && `, ${option?.PracticeZip}`
                  }`}
              </>
            ) : (
              `${option?.Role} at ${option?.PracticeName}`
            )}
          </Typography>
        }
      />
    </ListItem>
  );
};
