import { Close, Shortcut } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';
import { chatStyle } from '../../../../style/dashboardStyles/chatStyle';

const ChatForwardingBox = ({
  selected,
  onForward,
  onCancel,
}: {
  selected?: number;
  onForward?: () => void;
  onCancel?: () => void;
}) => {
  return (
    <Box sx={chatStyle.chatForwardingBox}>
      <Typography sx={chatStyle.chatForwardingBoxText}>
        {(selected ?? 0) > 0 ? `${selected} selected` : ''}
      </Typography>
      <Button
        variant='outlined'
        onClick={() => onCancel && onCancel()}
        startIcon={<Close />}
      >
        Cancel
      </Button>
      <Button
        variant='contained'
        onClick={() => {
          if (onForward && (selected ?? 0) > 0) onForward();
        }}
        startIcon={<Shortcut />}
        disabled={(selected ?? 0) <= 0}
      >
        Forward
      </Button>
    </Box>
  );
};

export default ChatForwardingBox;
