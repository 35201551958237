import { NavigateFunction } from 'react-router-dom';
import { indexedDbFailedMessagesChannelsHelper } from './indexedDbFailedMessagesHelper';
import { navigateToLogin } from './navigateToLoginHelper';

export const logoutUser = (navigate: NavigateFunction) => {
  navigate(navigateToLogin());
  localStorage.clear();
  indexedDbFailedMessagesChannelsHelper.deleteAllFailedChatMessages();
  // for getting/ updating PWA notification badges
  // @ts-ignore
  if (navigator.clearAppBadge) {
    // @ts-ignore
    navigator.clearAppBadge();
  }
};
