import { CloseRounded } from '@mui/icons-material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import { generalErrorHandler } from 'helpers/generalErrorHandlerHelper';
import React, { useState } from 'react';
import { useInviteRecommendedPracticeMutation } from 'services/myNetworkService';
import { showSnackbarAction } from 'store/actions/snackbarAction';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import FlatButton from '../../../../components/FlatButton';
import { TEST_CONSTANTS } from '../../../../constants/testConstants';
import { myNetworkStyle } from '../../../../style/dashboardStyles/myNetworkStyle';
import { InvitePracticePopUpData } from '../../../../types/myNetworkTypes';

const InviteRecommendedPracticeDialog = (props: {
  recordSelectedToInvite: InvitePracticePopUpData;
  setRecordSelectedToInvite: React.Dispatch<
    React.SetStateAction<InvitePracticePopUpData | null>
  >;
  invitationSentToPractices: string[];
  setInvitationSentToPractices: React.Dispatch<React.SetStateAction<string[]>>;
  invitedFromHeaderSearchBar?: boolean;
}) => {
  const dispatch = useAppDispatch();
  const {
    recordSelectedToInvite,
    setRecordSelectedToInvite,
    setInvitationSentToPractices,
    invitedFromHeaderSearchBar,
  } = props;
  const { userInfo } = useAppSelector((state) => ({
    id: state.userReducer.id,
    userInfo: state.userReducer.userInfo,
  }));
  const [inviteMessage, setInviteMessage] = useState<string>(
    `Hi there, it’s ${userInfo?.firstName} ${userInfo?.lastName}${
      userInfo?.practiceName?.trim()?.length
        ? ` from ${userInfo.practiceName}`
        : ''
    }. I’d like to invite you to drtalk. It will help us securely discuss our shared patients, and makes day to day tasks in the office a whole lot easier.`
  );

  const [
    inviteRecommendedPractice,
    { isLoading: isInvitePracticeLoaderActive },
  ] = useInviteRecommendedPracticeMutation();
  const onClickInvitePractice = async () => {
    await inviteRecommendedPractice({
      data: {
        toPracticeId: recordSelectedToInvite.PracticeId,
        message: inviteMessage,
      },
      invitedFromHeaderSearchBar,
    })
      .unwrap()
      .then(() => {
        showSnackbarAction({
          open: true,
          statusCode: 200,
          message: `Invite sent successfully to ${recordSelectedToInvite.PracticeName}`,
          severity: 'success',
        });
        setInvitationSentToPractices((prevValue: string[]) => [
          ...prevValue,
          recordSelectedToInvite.PracticeId,
        ]);
      })
      .catch((e) => generalErrorHandler(e));

    setRecordSelectedToInvite(null);
  };

  const onMessageChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setInviteMessage(event.target.value);
  };

  return (
    <Dialog
      data-testid={TEST_CONSTANTS.RECOMMENDED_LIST_INVITE_DIALOG}
      open={!!recordSelectedToInvite}
      sx={myNetworkStyle.inviteDialog}
      onClose={() => setRecordSelectedToInvite(null)}
      aria-labelledby='invite practice'
      aria-describedby='invite practice'
    >
      <DialogTitle sx={{ pb: 0 }}>
        <Box
          sx={[
            myNetworkStyle.invitePracticeModalHeader,
            { justifyContent: 'flex-end' },
          ]}
        >
          <IconButton onClick={() => setRecordSelectedToInvite(null)}>
            <CloseRounded />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Typography component='div' variant='h6' fontWeight={500}>
          {recordSelectedToInvite.PracticeName}
        </Typography>
        <Typography component='div' variant='subtitle2' fontWeight={400}>
          {recordSelectedToInvite.PracticeType}
        </Typography>

        <Box sx={myNetworkStyle.recommendedPracticeInfoContainer}>
          <InfoOutlinedIcon fontSize='small' />
          <Box>
            When {recordSelectedToInvite.PracticeName} accepts your invitation,
            you'll be able to chat, share patient data and send documents to
            each other. All with bank level encryption.
          </Box>
        </Box>

        <TextField
          fullWidth
          multiline
          rows={5}
          variant='outlined'
          InputProps={{
            style: { fontSize: 14 },
          }}
          label='Message'
          value={inviteMessage}
          onChange={onMessageChange}
        />
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center', pb: '1.5rem' }}>
        <Button variant='text' onClick={() => setRecordSelectedToInvite(null)}>
          Cancel
        </Button>
        <FlatButton
          variant='contained'
          onClick={onClickInvitePractice}
          sx={myNetworkStyle.recommendedPracticeInviteDialogInviteButton}
          isLoading={isInvitePracticeLoaderActive}
        >
          Invite
        </FlatButton>
      </DialogActions>
    </Dialog>
  );
};

export default InviteRecommendedPracticeDialog;
