export const uppercaseInitials = (str: string) => {
  let firstTwoWords = str.split(' ').slice(0, 2);

  const isAndExist = firstTwoWords.filter(
    (word: string) => word === 'and' || word === '&'
  );

  if (isAndExist?.length) {
    firstTwoWords = str.split(' ').slice(0, 3);

    const indexOfAnd =
      firstTwoWords.indexOf('and') ?? firstTwoWords.indexOf('&');
    if (indexOfAnd > -1) {
      firstTwoWords.splice(indexOfAnd, 1);
    }
  }

  let initials = firstTwoWords
    .join(' ')
    .match(/\b(\w)/g)
    ?.join('')
    .toUpperCase();

  return initials?.substring(0,2);
};
