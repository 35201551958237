import { Action } from '../../types/Redux';

export const PracticeLocalActions = {} as const;

export const PracticeApiActions = {
  GET_ROLES_START: '@practice/GET_ROLES_START',
  GET_ROLES_SUCCESS: '@practice/GET_ROLES_SUCCESS',
  GET_ROLES_ERROR: '@practice/GET_ROLES_ERROR',
} as const;

export type PracticeReducerState = {
  practiceIds: readonly string[];
  practiceRoles: Record<string, readonly string[]>;
};

export type GetRolesStart = Action<typeof PracticeApiActions.GET_ROLES_START>;
export type GetRolesSuccess = Action<
  typeof PracticeApiActions.GET_ROLES_SUCCESS,
  { id: string; roles: readonly string[] }
>;
export type GetRolesError = Action<typeof PracticeApiActions.GET_ROLES_ERROR>;

export type PracticeActionTypes =
  | GetRolesStart
  | GetRolesSuccess
  | GetRolesError;
