import { combineReducers } from 'redux';

import { authReducer } from './reducers/authReducer';
import myNetworkReducer from './slices/myNetworkSlice';
import myOfficeReducer from './slices/myOfficeSlice';
import { generalLoaderReducer } from './reducers/generalLoaderReducer';
import { onboardingReducer } from './reducers/onboardingReducer';
import { headerReducer } from './reducers/headerReducer';
import { practiceReducer } from './reducers/practiceReducer';
import { snackbarReducer } from './reducers/snackbarReducer';
import { userReducer } from './reducers/userReducer';
import { efaxReducer } from './reducers/dashboardReducers/efaxReducer';
import { documentReducer } from './reducers/dashboardReducers/documentsReducer';
import { paginationReducer } from './reducers/paginationReducer';
import { AUTH_REDUX_CONSTANTS } from './reduxConstants/authReduxConstants';
import notificationService from 'services/notificationService';
import notificationReducer from './slices/notificationSlice';
import chatService from 'services/chatService';
import chatReducer from './slices/chatSlice';
import referralReducer from './slices/referralSlice';
import practiceService from 'services/practiceService';
import fileManagerService from 'services/fileManagerService';
import patientService from 'services/patientService';
import officeFilesService from 'services/officeFilesService';
import recipientService from 'services/recipientService';
import { indexedDbFailedMessagesChannelsHelper } from 'helpers/indexedDbFailedMessagesHelper';
import myNetworkService from 'services/myNetworkService';
import myOfficeService from 'services/myOfficeService';
import personService from 'services/personService';
import userWorkStatusService from '../services/userWorkStatusService';
import userWorkStatusReducer from './slices/userWorkStatusSlice';

const appReducer = combineReducers({
  authReducer,
  onboardingReducer,
  headerReducer,
  generalLoaderReducer,
  efaxReducer,
  documentReducer,
  myNetworkReducer,
  myOfficeReducer,
  practiceReducer,
  snackbarReducer,
  userReducer,
  chatReducer,
  notificationReducer,
  paginationReducer,
  referralReducer,
  userWorkStatusReducer,
  [patientService.reducerPath]: patientService.reducer,
  [fileManagerService.reducerPath]: fileManagerService.reducer,
  [officeFilesService.reducerPath]: officeFilesService.reducer,
  [chatService.reducerPath]: chatService.reducer,
  [notificationService.reducerPath]: notificationService.reducer,
  [practiceService.reducerPath]: practiceService.reducer,
  [recipientService.reducerPath]: recipientService.reducer,
  [myOfficeService.reducerPath]: myOfficeService.reducer,
  [myNetworkService.reducerPath]: myNetworkService.reducer,
  [personService.reducerPath]: personService.reducer,
  [userWorkStatusService.reducerPath]: userWorkStatusService.reducer,
});

const rootReducer = (state: any, action: any) => {
  if (action.type === AUTH_REDUX_CONSTANTS.LOGOUT_USER_ACTION) {
    // clear all storages/data in redux, localstorage and indexedDb
    localStorage.clear();
    indexedDbFailedMessagesChannelsHelper.deleteAllFailedChatMessages();

    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export default rootReducer;
