import _ from 'lodash';
import localStorageHelper from '../helpers/localStorageHelper';
import {
  DefaultHttpClient,
  HttpError,
  HubConnection,
  HubConnectionBuilder,
  HubConnectionState,
} from '@microsoft/signalr';
import { AppDispatch } from '../store/store';
import { setLoggedUserWorkStatus } from '../store/slices/userWorkStatusSlice';
import { UserWorkStatusCode } from '../constants/userConstants';
import { memoRefreshToken } from '../helpers/refreshTokenHelper';
import { getHubAuthToken, setHubAuthToken } from './token';

enum UserWorkStatusEvent {
  Connect = 'Connect',
  Disconnect = 'Disconnect',
  Heartbeat = 'Heartbeat',
  Echo = 'Echo',
}

enum UserWorkStatusAction {
  Heartbeat = 'Heartbeat',
  Echo = 'Echo',
}

interface UserStatus {
  lastConnected: number;
  status: UserWorkStatusCode;
  userId: string;
}

let hubConnection: HubConnection;

class httpClient extends DefaultHttpClient {
  constructor() {
    super(console);
  }
  public async send(
    request: signalR.HttpRequest
  ): Promise<signalR.HttpResponse> {
    try {
      const response = await super.send(request);
      return response;
    } catch (e) {
      if (e instanceof HttpError) {
        if ((e as HttpError).statusCode === 401) {
          const token = await memoRefreshToken();
          request.headers = {
            ...request.headers,
            ...{ Authorization: `Bearer ${token}` },
          };
        }
      } else {
        throw e;
      }
    }
    //re try the request
    const response = await super.send(request);
    return response;
  }
}

const getHubConnection = (url: string, token: string) => {
  setHubAuthToken(token);
  if (!hubConnection) {
    hubConnection = new HubConnectionBuilder()
      .withUrl(url, {
        httpClient: new httpClient(),
        accessTokenFactory: getHubAuthToken,
      })
      .withAutomaticReconnect()
      .build();
  }
};

export const startUserWorkStatusHub = (
  url: string,
  token: string,
  dispatch: AppDispatch
) => {
  //if no token set then just return false
  if (_.isEmpty(token)) return Promise.resolve(false);

  getHubConnection(url, token);

  if (
    hubConnection.state &&
    hubConnection.state !== HubConnectionState.Disconnected
  ) {
    return Promise.resolve(
      hubConnection.state === HubConnectionState.Connected
    );
  }

  hubConnection.on(UserWorkStatusEvent.Connect, () => {
    console.log('userWorkStatusHub', UserWorkStatusEvent.Connect, 'connected');
  });

  hubConnection.on(UserWorkStatusEvent.Disconnect, (e) => {
    console.log(
      'userWorkStatusHub',
      UserWorkStatusEvent.Disconnect,
      'disconnected'
    );
  });

  hubConnection.on(UserWorkStatusEvent.Heartbeat, (statuses: UserStatus[]) => {
    const loggedUserStatus = statuses?.find(
      (status) => status.userId === localStorageHelper.getUser()
    );

    if (!!loggedUserStatus) {
      dispatch(setLoggedUserWorkStatus(loggedUserStatus?.status));
    }
  });

  hubConnection.on(UserWorkStatusEvent.Echo, (status: UserStatus) => {
      if (status.userId === localStorageHelper.getUser()) {
      dispatch(setLoggedUserWorkStatus(status?.status));
    }
  });

  return new Promise((resolve, reject) => {
    hubConnection
      .start()
      .then((r) => resolve(true))
      .catch((e) => reject(e));
  });
};

export const hubActions = {
  changeStatus: (userWorkStatus: UserWorkStatusCode) => {
    return (
      hubConnection?.invoke(UserWorkStatusAction.Heartbeat, userWorkStatus, [
        localStorageHelper.getUser(),
      ]) ?? Promise.resolve(false)
    );
  },
  echoStatus: () => {
    return (
      hubConnection?.invoke(UserWorkStatusAction.Echo) ?? Promise.resolve(false)
    );
  },
};

export const stopUserWorkStatusHub = () => {
  if (
    !hubConnection ||
    hubConnection?.state === HubConnectionState.Disconnected
  ) {
    return Promise.resolve(false);
  }
  return new Promise((resolve, reject) => {
    hubConnection
      .stop()
      .then((r) => resolve(false))
      .catch((e) => reject(e));
  });
};
