import { Box, Typography } from '@mui/material';
import { type ComponentProps, useState } from 'react';
import { useAppSelector } from '../../app/hooks';

import { ProfileImageAvatar } from 'components/ProfileImageAvatar';
import { profileStyle } from '../../style/profileStyle';
import { ProfilePictureCreator } from './ProfilePictureCreator';

type UserInfoProps = {
  canEditPhoto?: boolean;
} & ComponentProps<typeof Box>;

export const UserInfo = ({ canEditPhoto = true, ...other }: UserInfoProps) => {
  const { userInfo } = useAppSelector((state) => ({
    userInfo: state.userReducer.userInfo,
  }));
  const { firstName, lastName, practiceName, role } = userInfo ?? {};

  const displayName =
    firstName || lastName ? `${firstName} ${lastName}` : 'Enter your name';
  const [isPictureCreatorOpen, setIsPictureCreatorOpen] = useState(false);

  return (
    <Box sx={profileStyle.userInfo.container} {...other}>
      <Box sx={profileStyle.userInfo.profilePicture}>
        <ProfileImageAvatar
          showEditBtn
          onClickEdit={() => setIsPictureCreatorOpen(true)}
          src={userInfo?.profileImage ?? ''}
          fallBackString={`${firstName} ${lastName}`}
        />
      </Box>
      <Typography variant='h6' fontWeight={500}>
        {displayName}
      </Typography>
      <Typography variant='body2'>
        {role && `${role} at `}
        {practiceName}
      </Typography>
      <ProfilePictureCreator
        isOpen={isPictureCreatorOpen}
        onClose={() => setIsPictureCreatorOpen(false)}
      />
    </Box>
  );
};
