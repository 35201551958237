import { useEffect, useState } from 'react';

// a hack to force focus on input element
const useFocusInputRef = (inputRef: React.RefObject<HTMLInputElement>) => {
  const [doFocus, setDoFocus] = useState(false);
  useEffect(() => {
    inputRef.current?.focus();
    const timeout = setTimeout(() => {
      inputRef.current?.focus();
    }, 100);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doFocus]);
  return () => setDoFocus((n) => !n);
};

export default useFocusInputRef;
