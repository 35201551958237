import FlatButton from '../../../../components/FlatButton';
import SendEfaxDrawer from './SendEfaxDrawer';
import { Box, Tab, Tabs } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { efaxStyle } from '../../../../style/dashboardStyles/efaxStyle';
import { efaxTabs } from '../../../../constants/efaxConstants';
import {
  PrintOutlined,
  MoveToInboxOutlined,
  SendRounded,
} from '@mui/icons-material';
import {
  onChangeEfaxTabAction,
  toggleSendEfaxDrawer,
} from '../../../../store/actions/dashboardActions/efaxActions';
import { resetSelectedPageAction } from '../../../../store/actions/paginationAction';

const EfaxTabs = () => {
  const dispatch = useAppDispatch();
  const { selectedTab } = useAppSelector((state) => state.efaxReducer);

  return (
    <>
      <Box sx={efaxStyle.headerContainer}>
        <Tabs
          value={selectedTab}
          onChange={(_event: React.SyntheticEvent, newValue: 0 | 1) => {
            dispatch(onChangeEfaxTabAction(newValue));
            dispatch(resetSelectedPageAction());
          }}
        >
          <Tab
            label={
              <Box sx={efaxStyle.tabLabel}>
                <MoveToInboxOutlined />
                {efaxTabs.received.name}
              </Box>
            }
            value={efaxTabs.received.value}
          />
          <Tab
            label={
              <Box sx={efaxStyle.tabLabel}>
                <SendRounded />
                {efaxTabs.sent.name}
              </Box>
            }
            value={efaxTabs.sent.value}
          />
        </Tabs>
        <Box>
          <FlatButton
            variant='contained'
            startIcon={<PrintOutlined />}
            onClick={() => dispatch(toggleSendEfaxDrawer())}
          >
            Send e-Fax
          </FlatButton>
        </Box>
      </Box>
      <SendEfaxDrawer />
    </>
  );
};

export default EfaxTabs;
