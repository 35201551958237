export const paginationStyle = {
  paginationContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: 1,
    gap: '1rem',
  },
  limitDropdown: { '.MuiSelect-select': { width: '1.5rem', p: '.3rem ' } },
  pageButton: {
    minHeight: 'unset',
    height: '2rem',
    minWidth: '2rem',
    p: '.5rem',
    borderRadius: '1.5rem',
  },
};
