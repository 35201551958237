import { styled } from '@mui/system';
import { Button } from '@mui/material';

export const ProfilePictureActionButton = styled(Button)`
  color: #ffffff;
  border-color: #ffffff;
  background-color: transparent;
  font-weight: 500;
  font-size: 0.875rem;
  letter-spacing: 0.078125rem;
  width: 5.125rem;
  height: 2rem;
  min-height: auto;
  padding: 0.5rem 1rem;
`;
