import { Box, Typography } from '@mui/material';
import { profileStyle } from '../../../style/profileStyle';
import { ReactComponent as DrTalkLogo } from '../../../assets/images/drTalkTextLogo.svg';
import { ReactComponent as CheckIcon } from '../../../assets/images/check.svg';

type AboutProps = {
  version: string;
};

export const About = ({ version }: AboutProps) => {
  function generateCheckedText(content: string) {
    return (
      <Box sx={profileStyle.about.checkedText}>
        <CheckIcon />
        <Typography component='span' variant='body2'>
          {content}
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={profileStyle.about.mainContainer}>
      <DrTalkLogo height={36} width={152} />
      <Typography
        component='span'
        sx={profileStyle.about.description}
        textAlign='center'
        variant='body2'
      >
        drtalk is a HIPPA compliant communication platform and business tools
        for the dental office.
      </Typography>
      <Box sx={profileStyle.about.checkContainer}>
        {generateCheckedText('Encrypted')}
        {generateCheckedText('Secure')}
        {generateCheckedText('HIPPA compliant')}
      </Box>
      <Typography sx={profileStyle.about.footer} variant='body2'>
        V {version} | © {new Date().getFullYear()}
      </Typography>
    </Box>
  );
};
