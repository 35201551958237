export const passwordValidationMessages = {
  CHANGE_PASSWORD_GENERAL_ERROR: 'Unable to change password',
  INCORRECT_PASSWORD: 'Incorrect password entered',
  INVALID_PASSWORD: 'New password/confirm password must have the following:',
  PASSWORD_CONSTRAINT_LENGTH: 'Between 8 and 20 characters long.',
  PASSWORD_CONSTRAINT_LOWERCASE: 'At least one Lowercase (a-z)',
  PASSWORD_CONSTRAINT_NUMBER: 'At least one number (0-9)',
  PASSWORD_CONSTRAINT_SPECIAL: 'At least one special character',
  PASSWORD_CONSTRAINT_UPPERCASE: 'At least one Uppercase (A-Z)',
  PASSWORDS_NOT_MATCH: 'New Password and Confirm Password do not match',
  REQUIRED_FIELD: 'Required field',
};

export const personalInfoValidationMessages = {
  EMPTY_EMAIL: 'Please enter an email address',
  EMPTY_FIRST_NAME: 'Please enter a first name',
  EMPTY_LAST_NAME: 'Please enter a last name',
  EMPTY_ROLE: 'Please select a role',
  INVALID_EMAIL: 'Email address not valid',
  INVALID_MOBILE: 'Mobile number not valid',
};

export const sendDocumentsDialogValidationMessages = {
  PRACTICE_ERROR: 'Please select practice(s)',
  PATIENT_ERROR: 'Please select patient',
  DOCUMENT_TYPE_ERROR: 'Please select document type',
  PATIENT_FIRST_NAME_ERROR: 'Please enter the first name of the patient',
  PATIENT_LAST_NAME_ERROR: 'Please enter the last name of the patient',
  DATE_OF_BIRTH_REQUIRED_ERROR: 'Please select date of birth',
  DATE_OF_BIRTH_INVALID_ERROR: 'Please enter a valid date of birth in the past',
  IS_ANY_DOCUMENTS_UPLOADED: 'Please select at least one document to proceed',
};
