import { Box, DialogContent } from '@mui/material';
import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import FlatButton from '../../../../components/FlatButton';
import { setSendDocumentFormDataAction } from '../../../../store/actions/dashboardActions/documentActions';
import { documentStyle } from '../../../../style/dashboardStyles/documentStyle';
import {
  DocumentReducer,
  Thumbnail,
  UploadedDocument,
} from '../../../../types/documentsTypes';
import { sendDocumentsValidation } from './helpers/sendDocumentsValidation';
import SendDocumentsDialogLeftPart from './SendDocumentsDialogLeftPart';
import SendDocumentsDialogRightPart from './SendDocumentsDialogRightPart';

export type SendDocumentsDialogProps = {
  onSendDocument: (
    setIsFormSubmittedOnce: React.Dispatch<React.SetStateAction<boolean>>
  ) => void;
  selectedFiles: Thumbnail[];
  setSelectedFiles: React.Dispatch<React.SetStateAction<Thumbnail[]>>;
  uploadedFiles: UploadedDocument[];
  setUploadedFiles: React.Dispatch<React.SetStateAction<UploadedDocument[]>>;
};

const SendDocumentsStepTwo = (props: SendDocumentsDialogProps) => {
  const {
    onSendDocument,
    selectedFiles,
    setSelectedFiles,
    uploadedFiles,
    setUploadedFiles,
  } = props;
  const dispatch = useAppDispatch();

  const { isSendDocumentsLoaderActive } = useAppSelector(
    ({ generalLoaderReducer }: Record<string, any>) =>
      generalLoaderReducer ?? true
  );

  const { sendDocumentFormData } = useAppSelector(
    (state) => state.documentReducer as DocumentReducer
  );

  const { patient, recipients, attachments } = sendDocumentFormData;
  const [isFormSubmittedOnce, setIsFormSubmittedOnce] =
    useState<boolean>(false);
  const formValidationErrors = useMemo(
    () => sendDocumentsValidation(sendDocumentFormData),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [patient, attachments, recipients]
  );

  const onClickSendButton = () => {
    if (!isSendDocumentsLoaderActive) {
      if (!isFormSubmittedOnce) {
        setIsFormSubmittedOnce(true);
      }

      if (_.isEmpty(formValidationErrors)) {
        onSendDocument(setIsFormSubmittedOnce);
        setIsFormSubmittedOnce(false);
      }
    }
  };

  useEffect(() => {
    dispatch(
      setSendDocumentFormDataAction({
        ...sendDocumentFormData,
        attachments: uploadedFiles,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadedFiles, dispatch]);

  return (
    <>
      <DialogContent sx={documentStyle.sendDocumentsDialog.content}>
        <Box sx={documentStyle.sendDocumentsDialog.body}>
          <SendDocumentsDialogLeftPart
            isFormSubmittedOnce={isFormSubmittedOnce}
            errors={formValidationErrors}
          />
          <SendDocumentsDialogRightPart
            setUploadedFiles={setUploadedFiles}
            selectedFiles={selectedFiles}
            setSelectedFiles={setSelectedFiles}
            errors={isFormSubmittedOnce ? formValidationErrors : {}}
          />
        </Box>
        <Box
          sx={{
            paddingTop: '1rem',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <FlatButton
            variant='contained'
            disabled={!!!sendDocumentFormData.recipients.length}
            onClick={onClickSendButton}
          >
            {`Send to ${recipients.length} recipients`}
          </FlatButton>
        </Box>
      </DialogContent>
    </>
  );
};

export default SendDocumentsStepTwo;
