import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import localStorageHelper from '../../../helpers/localStorageHelper';
import { introductionScreenStyle } from '../../../style/authStyle/introductionScreenStyle';
import IntroductionScreenContent from './IntroductionScreenContent';
import IntroductionScreenHeader from './IntroductionScreenHeader';
import { useEffect } from 'react';
import { ROUTE_CONSTANTS_VARIABLE } from '../../../constants/routeConstants';

const IntroductionScreen = () => {
  const navigate = useNavigate();
  const token = localStorageHelper.getAuthToken();
  const isOnboardingCompleted = localStorageHelper.getOnboardingStatus();
  const isAutoGeneratedPassword =
    localStorageHelper.getAutoGeneratedPasswordStatus();
  const isUserPendingApproval = localStorageHelper.getUserIsPendingApproval();
  const isOrganizationPendingApproval =
    localStorageHelper.getOrganizationApprovalStatus();
  const userInfo = localStorageHelper.getUserInfo();

  useEffect(() => {
    if (token) {
      if (isUserPendingApproval || isOrganizationPendingApproval) {
        navigate(ROUTE_CONSTANTS_VARIABLE.ONBOARDING_PENDING_APPROVAL);
      } else {
        if (isOnboardingCompleted) {
          if (isAutoGeneratedPassword) {
            navigate(ROUTE_CONSTANTS_VARIABLE.SET_PASSWORD, { replace: true });
          } else if (!(userInfo?.role && userInfo?.mobile)) {
            navigate(ROUTE_CONSTANTS_VARIABLE.ONBOARDING_PERSONAL_INFO, {
              replace: true,
            });
          } else {
            navigate(ROUTE_CONSTANTS_VARIABLE.DASHBOARD, { replace: true });
          }
        } else {
          navigate(ROUTE_CONSTANTS_VARIABLE.ONBOARDING, { replace: true });
        }
      }
    }
  }, [
    navigate,
    token,
    isOnboardingCompleted,
    isUserPendingApproval,
    isOrganizationPendingApproval,
    isAutoGeneratedPassword,
    userInfo,
  ]);
  
  return (
    <Box sx={introductionScreenStyle.introductionScreenContainer}>
      <IntroductionScreenHeader />
      <IntroductionScreenContent />
      <Box sx={introductionScreenStyle.greyBottomBackground} />
    </Box>
  );
};

export default IntroductionScreen;
