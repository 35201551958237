import {
  Box,
  Menu,
  MenuItem,
  MenuList,
  Paper,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TEST_CONSTANTS } from 'constants/testConstants';
import { headerStyle } from 'style/headerStyle';
import { StatusIndicator } from 'components/StatusIndicator';
import type { UserInfo } from 'store/reduxConstants/userReduxConstants';
import { logout } from 'helpers/authHelper';
import { ProfileImageAvatar } from 'components/ProfileImageAvatar';
import { useResetServiceMutation as useResetChatServiceMutation } from 'services/chatService';
import { useResetServiceMutation as useResetNotificationServiceMutation } from 'services/notificationService';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { navigateToLogin } from 'helpers/navigateToLoginHelper';
import { resetReduxAction } from 'store/actions/authActions';
import { USER_STATUS, UserWorkStatusCode } from 'constants/userConstants';
import {
  useChangeUserWorkStatusMutation,
  useEchoUserWorkStatusMutation,
} from 'services/userWorkStatusService';

export type HeaderProfileProps = {
  userInfo: UserInfo;
};

const HeaderProfile = ({ userInfo }: HeaderProfileProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [profileMenuElement, setProfileMenuElement] =
    useState<null | HTMLElement>(null);
  const openProfileMenu = !!profileMenuElement;
  const [userStatusElement, setUserStatusElement] =
    useState<null | HTMLElement>(null);
  const openUserStatusMenu = !!userStatusElement;

  const { loggedUserWorkStatus, userWorkStatusHubConnected } = useAppSelector(
    (state) => ({
      loggedUserWorkStatus: state.userWorkStatusReducer.loggedUserWorkStatus,
      userWorkStatusHubConnected: state.userWorkStatusReducer.hub.connected,
    })
  );

  const [changeUserWorkStatusMutation] = useChangeUserWorkStatusMutation();
  const [echoUserWorkStatusMutation] = useEchoUserWorkStatusMutation();

  const [isUserStatusFetched, setIsUserStatusFetched] =
    useState<boolean>(false);

  const handleProfileMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setProfileMenuElement(event.currentTarget);
  };

  const onUserStatusChange = async (status: UserWorkStatusCode) => {
    await changeUserWorkStatusMutation(status);
    setUserStatusElement(null);
  };

  const handleGetUserWorkStatus = async () => {
    await echoUserWorkStatusMutation(() => {
      setIsUserStatusFetched(true);
    });
  };

  const handleUserMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setUserStatusElement(event.currentTarget);
  };

  const handleProfileMenuClose = () => {
    setProfileMenuElement(null);
  };

  const handleUserStatusMenuClose = () => {
    setUserStatusElement(null);
  };

  const [resetChatService] = useResetChatServiceMutation();
  const [resetNotificationService] = useResetNotificationServiceMutation();

  const onClickSignOut = () => {
    resetChatService();
    resetNotificationService();
    logout(() => {
      navigate(navigateToLogin());
      dispatch(resetReduxAction());
    });

    // for getting/ updating PWA notification badges
    // @ts-ignore
    if (navigator.clearAppBadge) {
      // @ts-ignore
      navigator.clearAppBadge();
    }
  };

  const renderProfilePicture = () => {
    const { firstName, lastName } = userInfo;
    const fullName = `${firstName ?? ''} ${lastName ?? ''}`.trim();

    return (
      <Box sx={headerStyle.profileContainer}>
        <ProfileImageAvatar
          status={loggedUserWorkStatus}
          src={userInfo?.profileImage ?? ''}
          fallBackString={fullName}
          alt={`${fullName}-dp`}
        />
      </Box>
    );
  };

  const onClickManageProfile = () => {
    navigate('profile');
    handleProfileMenuClose();
  };

  // will get initial work status
  useEffect(() => {
    if (
      userWorkStatusHubConnected &&
      loggedUserWorkStatus === USER_STATUS.UNSET
    ) {
      handleGetUserWorkStatus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedUserWorkStatus, userWorkStatusHubConnected]);

  // will set status to 'Active' if fetched initial work status was 'Unknown'
  useEffect(() => {
    if (
      userWorkStatusHubConnected &&
      isUserStatusFetched &&
      loggedUserWorkStatus === USER_STATUS.UNKNOWN
    ) {
      onUserStatusChange(UserWorkStatusCode.Active);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userWorkStatusHubConnected, loggedUserWorkStatus, isUserStatusFetched]);

  return (
    <>
      <Box
        data-testid={TEST_CONSTANTS.HEADER_PROFILE_CONTAINER}
        aria-label='profile'
        sx={headerStyle.headerProfileMenuButton}
        id='profile-menu-icon'
        aria-controls={openProfileMenu ? 'profile-menu' : undefined}
        aria-expanded={openProfileMenu ? 'true' : undefined}
        aria-haspopup='true'
        onClick={handleProfileMenuClick}
      >
        {renderProfilePicture()}
      </Box>
      <Menu
        id='profile-menu'
        MenuListProps={{
          'aria-labelledby': 'profile-menu-icon',
        }}
        sx={headerStyle.profileMenu}
        anchorEl={profileMenuElement}
        open={openProfileMenu}
        onClose={handleProfileMenuClose}
      >
        <Paper
          data-testid={TEST_CONSTANTS.HEADER_PROFILE_MENU}
          elevation={0}
          sx={headerStyle.profileMenuContainer}
        >
          <Box sx={headerStyle.userInfoContainer}>
            {renderProfilePicture()}
            <Box sx={headerStyle.userInfo}>
              <Typography variant='body2' fontWeight={600}>
                {userInfo.firstName} {userInfo.lastName}
              </Typography>
              <Typography variant='caption'>
                {userInfo?.role && `${userInfo?.role} at `}
                {userInfo.practiceName}
              </Typography>
            </Box>
          </Box>
          <Box>
            <Box
              data-testid={
                TEST_CONSTANTS.HEADER_PROFILE_USER_STATUS_MENU_BUTTON
              }
              aria-label='user-status'
              sx={headerStyle.userStatusButton}
              id='user-status'
              aria-controls={
                openUserStatusMenu ? 'user-status-menu' : undefined
              }
              aria-expanded={openUserStatusMenu ? 'true' : undefined}
              aria-haspopup='true'
              onClick={handleUserMenuClick}
            >
              <StatusIndicator status={loggedUserWorkStatus} />
              <Typography variant='body2' sx={headerStyle.userStatusMenu}>
                {loggedUserWorkStatus === USER_STATUS.UNSET
                  ? 'Loading status...'
                  : loggedUserWorkStatus}
              </Typography>
            </Box>
            <Menu
              data-testid={TEST_CONSTANTS.HEADER_PROFILE_USER_STATUS_MENU}
              id='user-status-menu'
              MenuListProps={{
                'aria-labelledby': 'user-status',
              }}
              sx={headerStyle.userStatusMenuContainer}
              open={openUserStatusMenu}
              onClose={handleUserStatusMenuClose}
              anchorEl={userStatusElement}
              PaperProps={{
                style: {
                  width: 125,
                },
              }}
            >
              <MenuItem
                data-testid={
                  TEST_CONSTANTS.HEADER_PROFILE_USER_STATUS_AVAILABLE
                }
                onClick={() => onUserStatusChange(UserWorkStatusCode.Active)}
              >
                <StatusIndicator status='Available' />
                <Typography variant='body2' sx={headerStyle.userStatusMenu}>
                  Available
                </Typography>
              </MenuItem>
              <MenuItem
                data-testid={TEST_CONSTANTS.HEADER_PROFILE_USER_STATUS_BUSY}
                onClick={() => onUserStatusChange(UserWorkStatusCode.Busy)}
              >
                <StatusIndicator status='Busy' />
                <Typography variant='body2' sx={headerStyle.userStatusMenu}>
                  Busy
                </Typography>
              </MenuItem>
              <MenuItem
                data-testid={TEST_CONSTANTS.HEADER_PROFILE_USER_STATUS_OFFLINE}
                onClick={() => onUserStatusChange(UserWorkStatusCode.Offline)}
              >
                <StatusIndicator status='Offline' />
                <Typography variant='body2' sx={headerStyle.userStatusMenu}>
                  Offline
                </Typography>
              </MenuItem>
            </Menu>
          </Box>
          <MenuList>
            <MenuItem
              sx={{ typography: 'body2', color: 'text.primary' }}
              onClick={onClickManageProfile}
            >
              Manage profile
            </MenuItem>
            <MenuItem
              data-testid={TEST_CONSTANTS.SIGN_OUT_OPTION}
              sx={{ typography: 'body2', color: 'error.main' }}
              onClick={onClickSignOut}
            >
              Sign out
            </MenuItem>
          </MenuList>
        </Paper>
      </Menu>
    </>
  );
};

export default HeaderProfile;
