import { createSlice } from '@reduxjs/toolkit';
import {
  addTeamMember,
  getMyOfficeConnectedList,
  getMyOfficeInvitationList,
  getRolesofLoggedInUserPractice,
} from '../../services/myOfficeService';
import { MyOfficeReducerInitialState } from '../../types/myOfficeType';

const initialMyOfficeReducer: MyOfficeReducerInitialState = {
  isMyOfficeListLoaderActive: false,
  teamMembersList: [],
  teamMemberInvitationList: [],
  roles: [],
};

const myOfficeSlice = createSlice({
  name: 'myOffice',
  initialState: initialMyOfficeReducer,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      getMyOfficeConnectedList.matchFulfilled,
      (state, { payload }) => {
        state.teamMembersList = payload;
      }
    );
    builder.addMatcher(
      getMyOfficeInvitationList.matchFulfilled,
      (state, { payload }) => {
        state.teamMemberInvitationList = payload;
      }
    );
    builder.addMatcher(
      getRolesofLoggedInUserPractice.matchFulfilled,
      (state, { payload }) => {
        state.roles = payload;
      }
    );
    builder.addMatcher(addTeamMember.matchFulfilled, (state, { payload }) => {
      state.teamMemberInvitationList = [
        ...state.teamMemberInvitationList,
        payload,
      ];
    });
  },
});

export default myOfficeSlice.reducer;
