import { type SyntheticEvent, useState } from 'react';
import { Box, Tab, Tabs } from '@mui/material';

import { type ProfileTabValue, profileTabLabels } from './profileTabLabels';
import { profileStyle } from '../../style/profileStyle';
import { PersonalInformation } from './contents/PersonalInformation';
import { Password } from './contents/Password';
import { About } from './contents/About';
import { Account } from './contents/Account';
import { Preferences } from './contents/Preferences';
import { UserInfo } from './UserInfo';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { setUserInfo } from '../../store/actions/userActions';
import localStorageHelper from '../../helpers/localStorageHelper';
import { UserInfo as UserInfoType } from '../../store/reduxConstants/userReduxConstants';

type ProfileTabsProps = {
  defaultSelected?: ProfileTabValue;
};

export const ProfileTabs = ({
  defaultSelected = 'personal-info',
}: ProfileTabsProps) => {
  const dispatch = useAppDispatch();
  const { userInfo, id } = useAppSelector((state) => ({
    id: state.userReducer.id,
    userInfo: state.userReducer.userInfo,
  }));
  const localUserInfo = localStorageHelper.getUserInfo();
  const [selected, setSelected] = useState(defaultSelected);

  function handleTabChange(_: SyntheticEvent, value: ProfileTabValue): void {
    setSelected(value);

    if (localUserInfo?.profileImage !== userInfo?.profileImage) {
      dispatch(
        setUserInfo(id, {
          ...userInfo,
          profileImage: localUserInfo?.profileImage ?? null,
        } as UserInfoType)
      );
    }
  }

  function renderTabs(): ReturnType<typeof Tab>[] {
    return profileTabLabels.map((tab) => (
      <Tab
        sx={profileStyle.tab}
        key={tab.value}
        label={tab.label}
        value={tab.value}
      />
    ));
  }

  function renderContent() {
    switch (selected) {
      case 'about':
        return <About version='3.10.0.0-TEMP' />;
      case 'account':
        return <Account />;
      case 'password':
        return <Password />;
      case 'personal-info':
        return <PersonalInformation />;
      case 'preferences':
        return <Preferences />;
    }
  }

  return (
    <>
      <UserInfo />
      <Box sx={profileStyle.modal.container}>
        <Tabs
          onChange={handleTabChange}
          sx={profileStyle.tabsContainer}
          value={selected}
        >
          {renderTabs()}
        </Tabs>
        <Box sx={profileStyle.modal.content}>{renderContent()}</Box>
      </Box>
    </>
  );
};
