export const TEST_CONSTANTS = {
  INTRODUCTION_SCREEN_CONTENT_CONTAINER:
    'INTRODUCTION_SCREEN_CONTENT_CONTAINER',
  INTRODUCTION_SCREEN_HEADING: 'INTRODUCTION_SCREEN_HEADING',
  LOGIN_FORM: 'LOGIN_FORM',
  SIGN_IN_LABEL: 'SIGN_IN_LABEL',
  EMAIL_INPUT: 'EMAIL_INPUT',
  PASSWORD_INPUT: 'PASSWORD_INPUT',
  SIGN_IN_BUTTON: 'SIGN_IN_BUTTON',
  FORGOT_PASSWORD_LINK: 'FORGOT_PASSWORD_LINK',
  REGISTER_LINK: 'REGISTER_LINK',
  PRIVACY_POLICY_LINK: 'PRIVACY_POLICY_LINK',
  TERMS_CONDITIONS_LINK: 'TERMS_CONDITIONS_LINK',
  ON_BOARDING_FORM: 'ON_BOARDING_FORM',
  ON_BOARDING_FORM_LABEL: 'ON_BOARDING_FORM_LABEL',
  ON_BOARDING_FORM_BACK_LINK: 'ON_BOARDING_FORM_BACK_LINK',
  ON_BOARDING_FORM_PRACTICE_NAME: 'ON_BOARDING_FORM_PRACTICE_NAME',
  ON_BOARDING_FORM_DOCTOR_NAME: 'ON_BOARDING_FORM_DOCTOR_NAME',
  ON_BOARDING_FORM_CONTACT_PERSON: 'ON_BOARDING_FORM_CONTACT_PERSON',
  ON_BOARDING_FORM_ADDRESS: 'ON_BOARDING_FORM_ADDRESS',
  ON_BOARDING_FORM_PRACTICE_TYPE: 'ON_BOARDING_FORM_PRACTICE_TYPE',
  ON_BOARDING_FORM_ENTER_ADDRESS_MANUALLY_LINK:
    'ON_BOARDING_FORM_ENTER_ADDRESS_MANUALLY_LINK',
  ONBOARDING_PERSONAL_INFO_ROLE: 'ONBOARDING_PERSONAL_INFO_ROLE',
  ONBOARDING_PERSONAL_INFO_MOBILE_NUMBER:
    'ONBOARDING_PERSONAL_INFO_MOBILE_NUMBER',
  ONBOARDING_PERSONAL_INFO_BACK_BUTTON: 'ONBOARDING_PERSONAL_INFO_BACK_BUTTON',
  ONBOARDING_DEFAULT_FORM_BACK_BUTTON: 'ONBOARDING_DEFAULT_FORM_BACK_BUTTON',
  MANUAL_ADDRESS_DIALOG: 'MANUAL_ADDRESS_DIALOG',
  MANUAL_ADDRESS_CITY: 'MANUAL_ADDRESS_CITY',
  MANUAL_ADDRESS_STATE: 'MANUAL_ADDRESS_STATE',
  MANUAL_ADDRESS_ZIP: 'MANUAL_ADDRESS_ZIP',
  ON_BOARDING_FORM_SUBMIT_BUTTON: 'ON_BOARDING_FORM_SUBMIT_BUTTON',
  DASHBOARD_CONTAINER: 'DASHBOARD_CONTAINER',
  DASHBOARD_CONTAINER_CONTENT: 'DASHBOARD_CONTAINER_CONTENT',
  NAVIGATION_CONTAINER: 'NAVIGATION_CONTAINER',
  NOTIFICATION_BANNER: 'NOTIFICATION_BANNER',
  DR_TALK_LOGO: 'DR_TALK_LOGO',
  LEFT_NAVIGATION_DRAWER_CONTAINER: 'LEFT_NAVIGATION_DRAWER_CONTAINER',
  LEFT_NAVIGATION_EXPAND_BUTTON: 'LEFT_NAVIGATION_EXPAND_BUTTON',
  CHAT_MENU: 'CHAT_MENU',
  DOCUMENTS_MENU: 'DOCUMENTS_MENU',
  MY_NETWORK_MENU: 'MY_NETWORK_MENU',
  MY_OFFICE_MENU: 'MY_OFFICE_MENU',
  EFAX_MENU: 'EFAX_MENU',
  REFER_A_PATIENT_MENU: 'REFER_A_PATIENT_MENU',
  CHAT_CONTAINER: 'CHAT_CONTAINER',
  CHAT_LIST_PANEL: 'CHAT_LIST_PANEL',
  CHAT_LIST_PANEL_NEW_BUTTON: 'CHAT_LIST_PANEL_NEW_BUTTON',
  CHAT_LIST_ITEM: 'CHAT_LIST_ITEM',
  CHAT_AVATAR: 'CHAT_AVATAR',
  CHAT_USER_ITEM: 'CHAT_LIST_ITEM',
  CHAT_USER_AVATAR: 'CHAT_USER_AVATAR',
  CHAT_PANEL: 'CHAT_PANEL',
  CHAT_PANEL_DEFAULT_IMAGE: 'CHAT_PANEL_DEFAULT_IMAGE',
  CHAT_PANEL_NEWCHAT_IMAGE: 'CHAT_PANEL_NEWCHAT_IMAGE',
  CHAT_PANEL_HEADER: 'CHAT_PANEL_HEADER',
  CHAT_SELECT_USER_BOX: 'CHAT_SELECT_USER_BOX',
  CHAT_SELECT_USER_BOX_SEARCH_RESULT: 'CHAT_SELECT_USER_BOX_SEARCH_RESULT',
  CHAT_MESSAGE_LIST: 'CHAT_MESSAGE_LIST',
  CHAT_MESSAGE_LIST_DELETED_USER_MESSAGE:
    'CHAT_MESSAGE_LIST_DELETED_USER_MESSAGE',
  CHAT_MESSAGE_BOX: 'CHAT_MESSAGE_BOX',
  CHAT_MESSAGE_BOX_BUTTON: 'CHAT_MESSAGE_BOX_BUTTON',
  CHAT_MESSAGE_BOX_EMOJI_PICKER: 'CHAT_MESSAGE_BOX_EMOJI_PICKER',
  EFAX_CONTAINER: 'EFAX_CONTAINER',
  DOCUMENTS_CONTAINER: 'DOCUMENTS_CONTAINER',
  MY_OFFICE_CONTAINER: 'MY_OFFICE_CONTAINER',
  MY_OFFICE_TEAM_MEMBER_LIST_LOADER: 'MY_OFFICE_TEAM_MEMBER_LIST_LOADER',
  DASHBOARD_HEADER_CONTAINER: 'DASHBOARD_HEADER_CONTAINER',
  HEADER_SEARCH_BAR: 'HEADER_SEARCH_BAR',
  HEADER_SEARCH_BAR_NO_SEARCH_RESULTS_CONTAINER:
    'HEADER_SEARCH_BAR_NO_SEARCH_RESULTS_CONTAINER',
  HEADER_SEARCH_BAR_NO_SEARCH_TEXT: 'HEADER_SEARCH_BAR_NO_SEARCH_TEXT',
  HEADER_SEARCH_BAR_ADD_PRACTICE_BUTTON:
    'HEADER_SEARCH_BAR_ADD_PRACTICE_BUTTON',
  HEADER_NOTIFICATION: 'HEADER_NOTIFICATION',
  HEADER_PROFILE_CONTAINER: 'HEADER_PROFILE_CONTAINER',
  HEADER_PROFILE_MENU: 'HEADER_PROFILE_MENU',
  SIGN_OUT_OPTION: 'SIGN_OUT_OPTION',
  HEADER_SEARCH_BAR_RESULT_LABEL: 'HEADER_SEARCH_BAR_RESULT_LABEL',
  HEADER_PROFILE_USER_STATUS_MENU_BUTTON:
    'HEADER_PROFILE_USER_STATUS_MENU_BUTTON',
  HEADER_PROFILE_USER_STATUS_MENU: 'HEADER_PROFILE_USER_STATUS_MENU',
  HEADER_PROFILE_USER_STATUS_AVAILABLE: 'HEADER_PROFILE_USER_STATUS_AVAILABLE',
  HEADER_PROFILE_USER_STATUS_BUSY: 'HEADER_PROFILE_USER_STATUS_BUSY',
  HEADER_PROFILE_USER_STATUS_OFFLINE: 'HEADER_PROFILE_USER_STATUS_OFFLINE',
  HEADER_SEARCH_BAR_RESULT: 'HEADER_SEARCH_BAR_RESULT',
  GET_HIGHLIGHTED_SEARCHED_TEXT: 'GET_HIGHLIGHTED_SEARCHED_TEXT',
  MY_NETWORK_CONTAINER: 'MY_NETWORK_CONTAINER',
  MY_NETWORK_PRACTICE_LIST_CONTAINER: 'MY_NETWORK_PRACTICE_LIST_CONTAINER',
  MY_NETWORK_PRACTICE_LIST_LOADER: 'MY_NETWORK_PRACTICE_LIST_LOADER',
  MY_NETWORK_CREATE_INVITE_EMAIL_FIELD: 'MY_NETWORK_CREATE_INVITE_EMAIL_FIELD',
  MY_NETWORK_INVITE_PRACTICE_BUTTON: 'MY_NETWORK_INVITE_PRACTICE_BUTTON',
  MY_NETWORK_SEND_INVITE_DIALOG: 'MY_NETWORK_SEND_INVITE_DIALOG',
  SEND_INVITE_CLOSE_BUTTON: 'SEND_INVITE_CLOSE_BUTTON',
  MY_NETWORK_DELETE_ICON_BUTTON: 'MY_NETWORK_DELETE_ICON_BUTTON',
  MY_NETWORK_DELETE_INVITATION_DIALOG: 'MY_NETWORK_DELETE_INVITATION_DIALOG',
  MY_NETWORK_DELETE_DIALOG_CLOSE_BUTTON:
    'MY_NETWORK_DELETE_DIALOG_CLOSE_BUTTON',
  DELETE_SENT_INVITE_DIALOG_DELETE_BUTTON:
    'DELETE_SENT_INVITE_DIALOG_DELETE_BUTTON',
  MY_NETWORK_INVITE_DIALOG_SEND_INVITE_BUTTON:
    'MY_NETWORK_INVITE_DIALOG_SEND_INVITE_BUTTON',
  INVITATION_LIST_PRACTICE_ITEM_ACCEPT_BUTTON:
    'INVITATION_LIST_PRACTICE_ITEM_ACCEPT_BUTTON',
  INVITATION_LIST_PRACTICE_ITEM_IGNORE_BUTTON:
    'INVITATION_LIST_PRACTICE_ITEM_IGNORE_BUTTON',
  PRACTICE_LIST_VIEW_CONNECTION_BUTTON: 'PRACTICE_LIST_VIEW_CONNECTION_BUTTON',
  VIEW_CONNECTION_CONTAINER: 'VIEW_CONNECTION_CONTAINER',
  MY_OFFICE_ADD_MEMBER_DIALOG: 'MY_OFFICE_ADD_MEMBER_DIALOG',
  MY_OFFICE_ADD_MEMBER_EMAIL_TEXT_FIELD:
    'MY_OFFICE_ADD_MEMBER_EMAIL_TEXT_FIELD',
  MY_OFFICE_ADD_MEMBER_ROLE_SELECT: 'MY_OFFICE_ADD_MEMBER_ROLE_SELECT',
  RECOMMENDED_LIST_PRACTICE_ITEM_INVITE_BUTTON:
    'RECOMMENDED_LIST_PRACTICE_ITEM_INVITE_BUTTON',
  RECOMMENDED_LIST_PRACTICE_ITEM_HIDE_BUTTON:
    'RECOMMENDED_LIST_PRACTICE_ITEM_HIDE_BUTTON',
  RECOMMENDED_LIST_INVITE_DIALOG: 'RECOMMENDED_LIST_INVITE_DIALOG',
  UPLOAD_DOCUMENTS_MAX_LIMIT_TEXT: 'UPLOAD_DOCUMENTS_MAX_LIMIT_TEXT',
  UPLOAD_DOCUMENT_THUMBNAIL: 'UPLOAD_DOCUMENT_THUMBNAIL',
  UPLOAD_DOCUMENT_INPUT: 'UPLOAD_DOCUMENT_INPUT',
  UPLOAD_DOCUMENT_DROP_BOX: 'UPLOAD_DOCUMENT_DROP_BOX',
  UPLOAD_DOCUMENT_ADD_ADDITIONAL_DOC_LINK:
    'UPLOAD_DOCUMENT_ADD_ADDITIONAL_DOC_LINK',
  UPLOAD_DOCUMENT_DELETE_BUTTON: 'UPLOAD_DOCUMENT_DELETE_BUTTON',
  UPLOAD_DOCUMENT_THUMBNAIL_CONTAINER: 'UPLOAD_DOCUMENT_THUMBNAIL_CONTAINER',
  SEND_DOCUMENTS_DIALOG: 'SEND_DOCUMENTS_DIALOG',
  SEND_DOCUMENTS_DIALOG_LEFT_PART: 'SEND_DOCUMENTS_DIALOG_LEFT_PART',
  SEND_DOCUMENTS_DIALOG_LEFT_PART_PRACTICE_FIELD:
    'SEND_DOCUMENTS_DIALOG_LEFT_PART_PRACTICE_FIELD',
  SEND_DOCUMENTS_DIALOG_LEFT_PART_PATIENT_FIELD:
    'SEND_DOCUMENTS_DIALOG_LEFT_PART_PATIENT_FIELD',
  SEND_DOCUMENTS_DIALOG_LEFT_PART_NEW_PATIENT_FIELD:
    'SEND_DOCUMENTS_DIALOG_LEFT_PART_NEW_PATIENT_FIELD',
  SEND_DOCUMENTS_DIALOG_LEFT_PART_NEW_PATIENT_FIRST_NAME_FIELD:
    'SEND_DOCUMENTS_DIALOG_LEFT_PART_NEW_PATIENT_FIRST_NAME_FIELD',
  SEND_DOCUMENTS_DIALOG_LEFT_PART_NEW_PATIENT_LAST_NAME_FIELD:
    'SEND_DOCUMENTS_DIALOG_LEFT_PART_NEW_PATIENT_LAST_NAME_FIELD',
  SEND_DOCUMENTS_DIALOG_LEFT_PART_PATIENT_DOB_FIELD:
    'SEND_DOCUMENTS_DIALOG_LEFT_PART_PATIENT_DOB_FIELD',
  SEND_DOCUMENTS_DIALOG_LEFT_PART_MESSAGE_FIELD:
    'SEND_DOCUMENTS_DIALOG_LEFT_PART_MESSAGE_FIELD',
  SEND_DOCUMENTS_ADD_NEW_PATIENT_LINK: 'SEND_DOCUMENTS_ADD_NEW_PATIENT_LINK',
  SEND_DOCUMENTS_RIGHT_PART_TABS: 'SEND_DOCUMENTS_RIGHT_PART_TABS',
  SEND_DOCUMENTS_DIALOG_CLOSE_ICON_BUTTON:
    'SEND_DOCUMENTS_DIALOG_CLOSE_ICON_BUTTON',
  DOCUMENT_TABLE_ROW: 'DOCUMENT_TABLE_ROW',
  DOCUMENT_TABLE_ACTION_ICON_BUTTON: 'DOCUMENT_TABLE_ACTION_ICON_BUTTON',
  DOCUMENT_TABLE_ACTION_MENU: 'DOCUMENT_TABLE_ACTION_MENU',
  DOCUMENT_TABLE_ACTION_DOWNLOAD_MENU: 'DOCUMENT_TABLE_ACTION_DOWNLOAD_MENU',
  DOCUMENT_TABLE_ACTION_FORWARD_MENU: 'DOCUMENT_TABLE_ACTION_FORWARD_MENU',
  DOCUMENT_TABLE_ACTION_CHANGE_DOCUMENT_TYPE_MENU:
    'DOCUMENT_TABLE_ACTION_CHANGE_DOCUMENT_TYPE_MENU',
  DOCUMENT_TABLE_ACTION_DELETE_MENU: 'DOCUMENT_TABLE_ACTION_DELETE_MENU',
  DOCUMENT_TABLE_DELETE_CONFIRMATION_DIALOG:
    'DOCUMENT_TABLE_DELETE_CONFIRMATION_DIALOG',
  DOCUMENT_TABLE_FILTER_PATIENT: 'DOCUMENT_TABLE_FILTER_PATIENT',
  DOCUMENT_TABLE_FILTER_SENDER: 'DOCUMENT_TABLE_FILTER_SENDER',
  DOCUMENT_TABLE_FILTER_PRACTICE: 'DOCUMENT_TABLE_FILTER_PRACTICE',
  DOCUMENT_TABLE_FILTER_DOCUMENT_TYPE: 'DOCUMENT_TABLE_FILTER_DOCUMENT_TYPE',
  DOCUMENT_TABLE_FILTER_DOCUMENT_STATUS:
    'DOCUMENT_TABLE_FILTER_DOCUMENT_STATUS',
  COMMON_SUCCESS_DIALOG: 'COMMON_SUCCESS_DIALOG',
  NEW_PATIENT_CLOSE_ICON_BUTTON: 'NEW_PATIENT_CLOSE_ICON_BUTTON',
  DOCUMENTS_TABLE_PRACTICE_LINK: 'DOCUMENTS_TABLE_PRACTICE_LINK',
  DOCUMENTS_TABLE_ATTACHMENT_THUMBNAIL: 'DOCUMENTS_TABLE_ATTACHMENT_THUMBNAIL',
  CHANGE_DOC_TYPE_DIALOG: 'CHANGE_DOC_TYPE_DIALOG',
  DOCUMENT_TYPE_CHANGE_AUTO_COMPLETE: 'DOCUMENT_TYPE_CHANGE_AUTO_COMPLETE',
  DOCUMENT_PREVIEW_CONTAINER: 'DOCUMENT_PREVIEW_CONTAINER',
  DOCUMENTS_PREVIEW_CLOSE_BUTTON: 'DOCUMENTS_PREVIEW_CLOSE_BUTTON',
  DOCUMENTS_PREVIEW_BACK_ARROW: 'DOCUMENTS_PREVIEW_BACK_ARROW',
  DOCUMENTS_PREVIEW_NEXT_ARROW: 'DOCUMENTS_PREVIEW_NEXT_ARROW',
  DOCUMENT_PREVIEW_DOWNLOAD_BUTTON: 'DOCUMENT_PREVIEW_DOWNLOAD_BUTTON',
  DOCUMENT_TABLE_PROCESS_BUTTON: 'DOCUMENT_TABLE_PROCESS_BUTTON',
  DOCUMENT_TABLE_FORWARD_DIALOG: 'DOCUMENT_TABLE_FORWARD_DIALOG',
  DOCUMENT_FORWARD_DIALOG_PRACTICE_AUTOCOMPLETE:
    'DOCUMENT_FORWARD_DIALOG_PRACTICE_AUTOCOMPLETE',
  DOCUMENT_FORWARD_DIALOG_USER_AUTOCOMPLETE:
    'DOCUMENT_FORWARD_DIALOG_USER_AUTOCOMPLETE',
  DOCUMENTS_TABLE_SEND_QUICK_MSG_LINK: 'DOCUMENTS_TABLE_SEND_QUICK_MSG_LINK',
  DOCUMENTS_SEND_QUICK_MESSAGE_DIALOG: 'DOCUMENTS_SEND_QUICK_MESSAGE_DIALOG',
  DOCUMENTS_SEND_QUICK_MESSAGE_BUTTON: 'DOCUMENTS_SEND_QUICK_MESSAGE_BUTTON',
  ADD_CONTACTS_DIALOG_CLOSE_ICON_BUTTON:
    'ADD_CONTACTS_DIALOG_CLOSE_ICON_BUTTON',
  SET_NEW_PASSWORD_INPUT: 'SET_NEW_PASSWORD_INPUT',
  SET_NEW_PASSWORD_CONFIRM_INPUT: 'SET_NEW_PASSWORD_CONFIRM_INPUT',
  SET_NEW_PASSWORD_SUBMIT_BUTTON: 'SET_NEW_PASSWORD_SUBMIT_BUTTON',
  SET_NEW_PASSWORD_BACK_LINK: 'SET_NEW_PASSWORD_BACK_LINK',

  GUEST_USER_DOCUMENTS_ASK_FOR_SIGN_UP_TITLE:
    'GUEST_USER_DOCUMENTS_ASK_FOR_SIGN_UP_TITLE',
  SEND_DOCUMENTS_STATUS_DIALOG: 'SEND_DOCUMENTS_STATUS_DIALOG',
  SEND_DOCUMENTS_STATUS_CHECK_ICON: 'SEND_DOCUMENTS_STATUS_CHECK_ICON',
  GUEST_SCREEN_REGISTER_BUTTON: 'GUEST_SCREEN_REGISTER_BUTTON',
  PAGINATION_RECORDS_PER_PAGE_SELECT: 'PAGINATION_RECORDS_PER_PAGE_SELECT',
  SEND_DOCUMENTS_STEP_ONE_DIALOG: 'SEND_DOCUMENTS_STEP_ONE_DIALOG',
  GUEST_USER_SCREEN_REGISTER_NUDGE: 'GUEST_USER_SCREEN_REGISTER_NUDGE',
  PROFILE_IMG_AVATAR_CONTAINER: 'PROFILE_IMG_AVATAR_CONTAINER',
  PROFILE_IMG_AVATAR: 'PROFILE_IMG_AVATAR',
  PROFILE_IMG_AVATAR_ABBR: 'PROFILE_IMG_AVATAR_ABBR',
  PROFILE_IMG_AVATAR_PERSON: 'PROFILE_IMG_AVATAR_PERSON',
  PROFILE_IMG_AVATAR_EDIT_BTN: 'PROFILE_IMG_AVATAR_EDIT_BTN',
  PROFILE_IMG_AVATAR_STATUS: 'PROFILE_IMG_AVATAR_STATUS',
};

/**
 * Referral Page related test ids
 */
export const REFERRAL_PAGE_TEST_CONSTANTS = {
  REFERRAL_PAGE_ROOT_CONTAINER: 'REFERRAL_PAGE_ROOT_CONTAINER',
  REFERRAL_STEP_ITEM_CONTAINER: 'REFERRAL_STEP_ITEM_CONTAINER',
  REFERRAL_STEP_ITEM_HEADER: 'REFERRAL_STEP_ITEM_HEADER',
  REFERRAL_STEP_ITEM_HEADER_CHECK_ICON: 'REFERRAL_STEP_ITEM_HEADER_CHECK_ICON',
  REFERRAL_STEP_ITEM_HEADER_EDIT_BUTTON:
    'REFERRAL_STEP_ITEM_HEADER_EDIT_BUTTON',
  REFERRAL_PRACTICE_SELECTION_LOADING: 'REFERRAL_PRACTICE_SELECTION_LOADING',
  REFERRAL_PRACTICE_SELECTION_FORM_CONTAINER:
    'REFERRAL_PRACTICE_SELECTION_FORM_CONTAINER',
  REFERRAL_PRACTICE_NO_PRACTICE_MSG: 'REFERRAL_PRACTICE_NO_PRACTICE_MSG',
  REFERRAL_PRACTICE_SPECIALISTS_SELECT_BOX:
    'REFERRAL_PRACTICE_SPECIALISTS_SELECT_BOX',
  REFERRAL_PRACTICE_DOCTORS_SELECT_BOX: 'REFERRAL_PRACTICE_DOCTORS_SELECT_BOX',
  REFERRAL_SELECT_PRACTICE_NEXT_BUTTON: 'REFERRAL_SELECT_PRACTICE_NEXT_BUTTON',
  REFERRAL_SELECT_PRACTICE_FREE_SPECIALIST_ERROR_MESSAGE:
    'REFERRAL_SELECT_PRACTICE_FREE_SPECIALIST_ERROR_MESSAGE',
  REFERRAL_DOCUMENT_CONTAINER: 'REFERRAL_DOCUMENT_CONTAINER',
  REFERRAL_PATIENT_DETAILS_CONTAINER: 'REFERRAL_PATIENT_DETAILS_CONTAINER',
  REFERRAL_PATIENT_DETAILS_PATIENT_SELECT_BOX:
    'REFERRAL_PATIENT_DETAILS_PATIENT_SELECT_BOX',
  REFERRAL_PATIENT_DETAILS_NEW_PATIENT_FORM:
    'REFERRAL_PATIENT_DETAILS_NEW_PATIENT_FORM',
  REFERRAL_PATIENT_DETAILS_SEND_REFERRAL_BUTTON:
    'REFERRAL_PATIENT_DETAILS_SEND_REFERRAL_BUTTON',
};
