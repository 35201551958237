import {
  ContactHistory,
  EfaxContact,
  EfaxTableDetailsType,
  PracticeDetails,
  ReceivedEfaxStatusFilter,
} from '../../types/efaxTypes';
import type { Action } from '../../types/Redux';

export const EFAX_REDUX_CONSTANTS = {
  GET_EFAX_LIST: 'GET_EFAX_LIST',
  PUSH_NEW_EFAX: 'PUSH_NEW_EFAX',
  SET_EFAX_TABLE_FILTER_OPTIONS: 'SET_EFAX_TABLE_FILTER_OPTIONS',
  SET_USER_PRACTICE_DETAILS: 'SET_USER_PRACTICE_DETAILS',
  SET_EFAX_CONTACT_HISTORY: 'SET_EFAX_CONTACT_HISTORY',
  UPDATE_EFAX_CONTACT: 'UPDATE_EFAX_CONTACT',
  ON_CHANGE_SELECTED_TAB: 'ON_CHANGE_SELECTED_TAB',
  ON_CHANGE_OR_APPLY_EFAX_TABLE_FILTER: 'ON_CHANGE_OR_APPLY_EFAX_TABLE_FILTER',
  ON_RECEIVED_EFAX_STATUS_CHANGE: 'ON_RECEIVED_EFAX_STATUS_CHANGE',
  ON_PROCESS_EFAX: 'ON_PROCESS_EFAX',
  ON_ARCHIVE_UNARCHIVE_EFAX: 'ON_ARCHIVE_UNARCHIVE_EFAX',
  SET_IS_SEND_EFAX_DRAWER_OPEN: 'SET_IS_SEND_EFAX_DRAWER_OPEN',
  TOGGLE_OPEN_EFAX_DRAWER: 'TOGGLE_OPEN_EFAX_DRAWER',
  RESET_EFAX_FILTERS: 'RESET_EFAX_FILTERS',
  UPDATE_PAGINATED_LIST: 'UPDATE_PAGINATED_LIST',
} as const;

export type PutNewEfaxAction = Action<
  typeof EFAX_REDUX_CONSTANTS.PUSH_NEW_EFAX,
  EfaxTableDetailsType
>;

export type SetEfaxContactHistoryAction = Action<
  typeof EFAX_REDUX_CONSTANTS.SET_EFAX_CONTACT_HISTORY,
  ContactHistory[]
>;

export type GetEfaxListAction = Action<
  typeof EFAX_REDUX_CONSTANTS.GET_EFAX_LIST,
  EfaxTableDetailsType[]
>;

export type UpdateEfaxContactAction = Action<
  typeof EFAX_REDUX_CONSTANTS.UPDATE_EFAX_CONTACT,
  EfaxContact
>;

export type SetUserPracticeDetailsAction = Action<
  typeof EFAX_REDUX_CONSTANTS.SET_USER_PRACTICE_DETAILS,
  PracticeDetails
>;

export type OnChangeDocumentsTabAction = Action<
  typeof EFAX_REDUX_CONSTANTS.ON_CHANGE_SELECTED_TAB,
  0 | 1
>;

export type SetEfaxTableFilterOptions = Action<
  typeof EFAX_REDUX_CONSTANTS.SET_EFAX_TABLE_FILTER_OPTIONS
>;

export type OnChangeEfaxTableFilterAction = Action<
  typeof EFAX_REDUX_CONSTANTS.ON_CHANGE_OR_APPLY_EFAX_TABLE_FILTER,
  { name: string; value: string | null }
>;

export type OnReceivedEfaxStatusChangeAction = Action<
  typeof EFAX_REDUX_CONSTANTS.ON_RECEIVED_EFAX_STATUS_CHANGE,
  ReceivedEfaxStatusFilter
>;

export type OnEfaxProcessedAction = Action<
  typeof EFAX_REDUX_CONSTANTS.ON_PROCESS_EFAX,
  string
>;

export type OnEfaxArchivedUnarchivedAction = Action<
  typeof EFAX_REDUX_CONSTANTS.ON_ARCHIVE_UNARCHIVE_EFAX,
  EfaxTableDetailsType
>;

export type SetIsSendEfaxDrawerOpenAction = Action<
  typeof EFAX_REDUX_CONSTANTS.SET_IS_SEND_EFAX_DRAWER_OPEN
>;

export type ToggleOpenEfaxDrawerAction = Action<
  typeof EFAX_REDUX_CONSTANTS.TOGGLE_OPEN_EFAX_DRAWER,
  EfaxTableDetailsType
>;

export type ResetEfaxFiltersAction = Action<
  typeof EFAX_REDUX_CONSTANTS.RESET_EFAX_FILTERS
>;

export type UpdateEfaxPaginatedListAction = Action<
  typeof EFAX_REDUX_CONSTANTS.UPDATE_PAGINATED_LIST,
  EfaxTableDetailsType[]
>;

export type EfaxActionTypes =
  | GetEfaxListAction
  | UpdateEfaxContactAction
  | SetEfaxTableFilterOptions
  | OnChangeDocumentsTabAction
  | OnChangeEfaxTableFilterAction
  | OnReceivedEfaxStatusChangeAction
  | OnEfaxProcessedAction
  | OnEfaxArchivedUnarchivedAction
  | SetIsSendEfaxDrawerOpenAction
  | SetUserPracticeDetailsAction
  | PutNewEfaxAction
  | ToggleOpenEfaxDrawerAction
  | SetEfaxContactHistoryAction
  | ResetEfaxFiltersAction
  | UpdateEfaxPaginatedListAction;
