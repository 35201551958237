import { colorPalette } from '../../theme/colorPalette';

export const dashboardStyle = {
  dashboardContainer: {
    display: 'flex',
    flex: 1,
    height: 1,
    flexDirection: 'column',
    overflowY: 'hidden',
  },
  dashboardContainerContent: {
    display: 'flex',
    flex: 1,
    height: 1,
    flexDirection: 'row',
    bgcolor: 'background.paper',
    minHeight: 0,
  },
  notificationBanner: {
    backgroundColor: colorPalette.primaryMain,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '2.75rem',
    '& *': { color: colorPalette.surfaceColor[10] },
    div: {
      display: 'flex',
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
      span: {
        ml: '0.25rem',
        fontWeight: 'bold',
        textDecoration: 'underline',
        cursor: 'pointer',
      },
    },
  },
  rightContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    height: 1,
    p: '1rem 1rem 1rem 0.5rem',
    transition: 'all 500ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    // overflowY: 'hidden',
  },
  childrenContainer: {
    height: 1,
  },
  outletRender: {
    display: 'flex',
    flex: '1',
    height: '1',
    p: '1rem 0',
    minHeight: 0,
  },
};
