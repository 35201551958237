import { Avatar, Box, Button, Typography } from '@mui/material';
import { AttachMoneySharp } from '@mui/icons-material';
import { profileStyle } from '../../../style/profileStyle';
import { PORTAL_BASE_URL } from '../../../constants/urlConstants';

export const Account = () => {
  return (
    <Box sx={profileStyle.account.container}>
      <Avatar sx={profileStyle.account.dollarIcon}>
        <AttachMoneySharp color='primary' />
      </Avatar>
      <Box sx={profileStyle.account.message}>
        <Typography variant='h6' fontWeight={500}>
          Manage your account
        </Typography>
        <Typography variant='body2'>
          Visit the drtalk portal to update your account & billing information
        </Typography>
      </Box>
      <Button
        component='a'
        color='primary'
        href={PORTAL_BASE_URL}
        sx={profileStyle.account.settingsButton}
        target='_blank'
        variant='contained'
      >
        Manage account settings
      </Button>
    </Box>
  );
};
