import { Action } from '../../types/Redux';

export const HEADER_REDUX_CONSTANTS = {
  SET_HEADER_SEARCH_TEXT: 'SET_HEADER_SEARCH_TEXT',
  GET_MY_NETWORK_SEARCH_RESULTS: 'GET_MY_NETWORK_SEARCH_RESULTS',
};

export type SetHeaderSearchTextAction = Action<
  typeof HEADER_REDUX_CONSTANTS.SET_HEADER_SEARCH_TEXT,
  string
>;

export type HeaderReducerActions = SetHeaderSearchTextAction;
