import {
  AU_COUNTRY_CODE,
  MOBILE_NUMBER_MASK_AU,
  MOBILE_NUMBER_MASK_US,
  US_COUNTRY_CODE,
} from '../constants/inputMaskConstants';
import { ENV_NAME } from '../constants/urlConstants';

// helper to get cropped ratio of selected profile image by the user
export const getRoundedCanvas = (
  sourceCanvas: HTMLCanvasElement
): HTMLCanvasElement | null => {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  if (!ctx) return null;
  const width = sourceCanvas.width;
  const height = sourceCanvas.height;

  canvas.width = width;
  canvas.height = height;
  ctx.imageSmoothingEnabled = true;
  ctx.drawImage(sourceCanvas, 0, 0, width, height);
  ctx.globalCompositeOperation = 'destination-in';
  ctx.beginPath();
  ctx.arc(
    width / 2,
    height / 2,
    Math.min(width, height) / 2,
    0,
    2 * Math.PI,
    true
  );
  ctx.fill();

  return canvas;
};

// helper to remove country code from a mobile no
export function removeCountryCode(mobileNo?: string) {
  let newMobileNo = mobileNo;

  if (!!mobileNo) {
    const phoneCountryCode =
      ENV_NAME === 'test' || ENV_NAME === 'development'
        ? AU_COUNTRY_CODE
        : US_COUNTRY_CODE;
    const slicedCountryCode = mobileNo?.slice(0, phoneCountryCode.length);

    if (slicedCountryCode === phoneCountryCode) {
      newMobileNo = mobileNo.slice(phoneCountryCode.length);
    }
  }

  return newMobileNo;
}

// helper to get mobile country code and mask format based on running environment
export function getEnvMobileConfig() {
  const shouldSetAU = ENV_NAME === 'test' || ENV_NAME === 'development';
  const mobileCountryCode = shouldSetAU ? AU_COUNTRY_CODE : US_COUNTRY_CODE;
  const mobileMaskFormat = shouldSetAU
    ? MOBILE_NUMBER_MASK_AU
    : MOBILE_NUMBER_MASK_US;

  return {
    mobileCountryCode,
    mobileMaskFormat,
  };
}
