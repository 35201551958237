import { Autocomplete, Box, TextField, Typography } from '@mui/material';
import { SyntheticEvent } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import {
  documentFilterOptions,
  DOCUMENT_TYPES_FILTER,
  statusFilterOptions
} from '../../../../constants/documentsConstants';
import { TEST_CONSTANTS } from '../../../../constants/testConstants';
import localStorageHelper from '../../../../helpers/localStorageHelper';
import {
  onDocumentStatusFilterChangeAction,
  onDocumentTableFilterChangeAction
} from '../../../../store/actions/dashboardActions/documentActions';
import { resetSelectedPageAction } from '../../../../store/actions/paginationAction';
import { documentStyle } from '../../../../style/dashboardStyles/documentStyle';
import {
  DocumentPatientFilter,
  ReceivedDocumentStatusFilter
} from '../../../../types/documentsTypes';
import { AutocompleteOption } from '../../../../types/Misc';

const DocumentFilters = () => {
  const dispatch = useAppDispatch();
  const token = localStorageHelper.getAuthToken();

  const {
    patientFilterOptions,
    senderOptions,
    practiceOptions,
    appliedFilters,
    selectedTab,
    receivedDocumentStatusFilter,
  } = useAppSelector((state) => state.documentReducer);

  interface FilterOptions {
    inputValue: string;
  }

  const onFilterChange = (
    name: string,
    value: string | AutocompleteOption | null
  ) => {
    dispatch(resetSelectedPageAction());
    dispatch(onDocumentTableFilterChangeAction({ name, value }));
  };

  const filterPatientOptions = (
    options: DocumentPatientFilter[],
    { inputValue }: FilterOptions
  ) => {
    return options.filter((option) =>
      option.patientTag.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  return (
    <Box sx={documentStyle.filterContainer}>
      <Autocomplete
        data-testid={TEST_CONSTANTS.DOCUMENT_TABLE_FILTER_PATIENT}
        clearOnBlur
        disabled={!token}
        sx={documentStyle.filterAutocomplete}
        options={patientFilterOptions}
        onChange={(_e: SyntheticEvent, value: DocumentPatientFilter | null) =>
          onFilterChange('patient', value?.patientTag || null)
        }
        filterOptions={filterPatientOptions}
        getOptionLabel={(patient) =>
          appliedFilters?.patient ? patient?.patientTag ?? patient : ''
        }
        renderInput={(params) => (
          <TextField
            {...params}
            size='small'
            label='Patient'
            placeholder='- Select patient -'
          />
        )}
        renderOption={(props, option: DocumentPatientFilter) => (
          <li {...props} key={option.patientTag}>
            <Box>
              {option.patientTag}
              <br />
              <Typography variant='caption' color={'textSecondary'}>
                {option.isReferral ? 'Referral' : 'Doc'}
                {!!option.patientDateOfBirth &&
                  `・DOB: ${option.patientDateOfBirth}`}
              </Typography>
            </Box>
          </li>
        )}
      />
      <Autocomplete
        data-testid={TEST_CONSTANTS.DOCUMENT_TABLE_FILTER_SENDER}
        clearOnBlur
        disabled={!token}
        sx={documentStyle.filterAutocomplete}
        options={senderOptions}
        onChange={(_e: SyntheticEvent, value: string | null) =>
          onFilterChange('sender', value)
        }
        value={appliedFilters.sender}
        renderInput={(params) => (
          <TextField
            {...params}
            size='small'
            label='Sender'
            placeholder='- Select sender -'
          />
        )}
      />
      <Autocomplete
        data-testid={TEST_CONSTANTS.DOCUMENT_TABLE_FILTER_PRACTICE}
        clearOnBlur
        disabled={!token}
        sx={documentStyle.filterAutocomplete}
        options={practiceOptions}
        onChange={(_e: SyntheticEvent, value: AutocompleteOption | null) =>
          onFilterChange('practice', value)
        }
        value={appliedFilters.practice}
        renderInput={(params) => (
          <TextField
            {...params}
            size='small'
            label='Practice'
            placeholder='- Select practice -'
          />
        )}
      />
      <Autocomplete
        data-testid={TEST_CONSTANTS.DOCUMENT_TABLE_FILTER_DOCUMENT_TYPE}
        clearOnBlur
        disabled={!token}
        sx={documentStyle.filterAutocomplete}
        options={documentFilterOptions}
        onChange={(_e: SyntheticEvent, value: string | null) =>
          onFilterChange('documentType', value ?? DOCUMENT_TYPES_FILTER.ALL)
        }
        value={appliedFilters.documentType}
        renderInput={(params) => (
          <TextField
            {...params}
            size='small'
            label='Document type'
            placeholder='- Select document type -'
          />
        )}
      />
      {selectedTab === 0 && (
        <Autocomplete
          data-testid={TEST_CONSTANTS.DOCUMENT_TABLE_FILTER_DOCUMENT_STATUS}
          clearOnBlur
          disabled={!token}
          sx={documentStyle.filterAutocomplete}
          options={statusFilterOptions}
          onChange={(_e: SyntheticEvent, value) => {
            dispatch(resetSelectedPageAction());
            dispatch(
              onDocumentStatusFilterChangeAction(
                (value ?? 'Unprocessed') as ReceivedDocumentStatusFilter
              )
            );
          }}
          value={receivedDocumentStatusFilter}
          renderInput={(params) => (
            <TextField
              {...params}
              size='small'
              label='Status'
              placeholder='- Select status -'
            />
          )}
        />
      )}
    </Box>
  );
};

export default DocumentFilters;
