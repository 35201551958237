import { CloseRounded } from '@mui/icons-material';
import ChangeCircleOutlinedIcon from '@mui/icons-material/ChangeCircleOutlined';
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import { SyntheticEvent, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import FlatButton from '../../../../components/FlatButton';
import { DOCUMENT_TYPE } from '../../../../constants/documentsConstants';
import { TEST_CONSTANTS } from '../../../../constants/testConstants';
import { onChangeDocTypeAction } from '../../../../store/actions/dashboardActions/documentActions';
import { commonClasses } from '../../../../style/commonClasses';
import { documentStyle } from '../../../../style/dashboardStyles/documentStyle';
import { DocumentsTableDetailsType } from '../../../../types/documentsTypes';

type DocumentTypeChangeDialogProps = {
  selectedRecord: DocumentsTableDetailsType;
  isDocTypeChangeDiaogOpen: boolean;
  setIsDocTypeChangeDiaogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleActionMenuClose: () => void;
};

const DocumentTypeChangeDialog = (props: DocumentTypeChangeDialogProps) => {
  const {
    selectedRecord,
    isDocTypeChangeDiaogOpen,
    setIsDocTypeChangeDiaogOpen,
    handleActionMenuClose,
  } = props;
  const dispatch = useAppDispatch();
  const { isDocTypeChangeLoaderActive } = useAppSelector(
    ({ generalLoaderReducer }: Record<string, any>) =>
      generalLoaderReducer ?? true
  );
  const [docType, setDocType] = useState<string | null>(
    selectedRecord?.isReferral ? DOCUMENT_TYPE.REFERRAL : DOCUMENT_TYPE.DOCUMENT
  );
  const [error, setError] = useState<string | null>(null);
  const errorMessage = 'Please select document type';
  const [isFormSubmittedOnce, setIsFormSubmittedOnce] =
    useState<boolean>(false);
  const onChangeDocType = (_event: SyntheticEvent, value: string | null) => {
    setDocType(value);
    if (isFormSubmittedOnce && value === null) {
      setError(errorMessage);
    } else {
      setError(null);
    }
  };

  const onSuccessfullyChangingDocType = () => {
    handleActionMenuClose();
    setIsDocTypeChangeDiaogOpen(false);
  };

  const onClickChangeDocType = () => {
    if (!isFormSubmittedOnce) {
      setIsFormSubmittedOnce(true);
    }
    if (docType === null) {
      setError(errorMessage);
    } else {
      dispatch(
        onChangeDocTypeAction(
          selectedRecord.id,
          { isReferral: docType === 'Document' ? false : true },
          onSuccessfullyChangingDocType
        )
      );
    }
  };

  return (
    <Dialog
      data-testid={TEST_CONSTANTS.CHANGE_DOC_TYPE_DIALOG}
      sx={documentStyle.docTypeChangeDialog}
      aria-labelledby='change document type'
      aria-describedby='change document type'
      open={isDocTypeChangeDiaogOpen}
      onClose={() => setIsDocTypeChangeDiaogOpen(false)}
    >
      <DialogTitle sx={{ display: 'flex', p: '1rem' }}>
        <Box sx={documentStyle.docTypeChangeDialog.headerIcon}>
          <ChangeCircleOutlinedIcon color='primary' />
        </Box>
        <IconButton onClick={() => setIsDocTypeChangeDiaogOpen(false)}>
          <CloseRounded />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography component='div' variant='h5' textAlign='center'>
          Change document type
        </Typography>

        <Typography
          component='div'
          variant='body2'
          textAlign='center'
          margin='1.5rem'
        >
          Changing the document type will update the document type for the
          receiving party as well
        </Typography>
        <form noValidate>
          <Autocomplete
            fullWidth
            data-testid={TEST_CONSTANTS.DOCUMENT_TYPE_CHANGE_AUTO_COMPLETE}
            onChange={onChangeDocType}
            sx={error ? commonClasses.fieldError : {}}
            options={['Document', 'Referral']}
            value={docType}
            renderInput={(params) => (
              <TextField
                {...params}
                label='Document type'
                required
                placeholder='- Select document type -'
                helperText={error}
              />
            )}
          />
        </form>
      </DialogContent>
      <DialogActions sx={documentStyle.docTypeChangeDialog.actions}>
        <Button
          variant='text'
          onClick={() => setIsDocTypeChangeDiaogOpen(false)}
        >
          Cancel
        </Button>
        <FlatButton
          variant='contained'
          sx={documentStyle.docTypeChangeDialog.actions.changeButton}
          onClick={onClickChangeDocType}
          isLoading={isDocTypeChangeLoaderActive}
        >
          Change
        </FlatButton>
      </DialogActions>
    </Dialog>
  );
};

export default DocumentTypeChangeDialog;
