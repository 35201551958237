import { styled } from '@mui/system';
import { Attachment } from '@mui/icons-material';

// rotates the standard attachment icon

const AttachmentIcon = styled(Attachment)({
  transform: 'rotate(-45deg)',
});

export default AttachmentIcon;
