export const leftNavigationStyle = {
  leftNavigationContainer: {
    position: 'absolute',
  },
  leftNavigationDrawer: {
    display: 'flex',
    flexDirection: 'column',
    height: 1,
    p: '1rem',

    button: {
      margin: '0.312rem 0',
      boxShadow: 'none',
    },

    '> *': {
      display: 'flex',
      alignItems: 'center',
      width: '3.5rem',
      minWidth: 'unset',
      typography: 'subtitle2',
      fontWeight: 700,
      color: 'primary.dark',
      cursor: 'pointer',
      whiteSpace: 'nowrap',

      '&:not(:first-of-type)': {
        p: '1rem',
        borderRadius: '6.25rem',
        '&.active:hover': {
          bgcolor: 'primary.light',
        },
        '&:hover:not(.active)': {
          '&:not(.active)': {
            color: 'primary.main',
            bgcolor: 'background.default',
          },
        },
      },
    },
  },
  drTalkLogoContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  drTalkLogo: {
    minWidth: '3.5rem',
  },
  drTalkTextLogo: {
    marginLeft: '.625rem',
    transition: 'opacity 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  },
  menu: {
    justifyContent: 'flex-start',
    minWidth: 'unset',
    borderRadius: '6.25rem',
    overflow: 'hidden',
    span: {
      marginLeft: '0.625rem',
      typography: 'subtitle2',
    },
  },
  expandIcon: {
    minWidth: 'unset',
    height: '3.5rem',
    justifyContent: 'center',
    color: 'text.primary',
    '&:hover': {
      color: 'primary.main',
      borderRadius: '6.25rem',
    },
  },
  menuIcon: {
    position: 'relative',
    lineHeight: '0.75rem',
  },
  menuName: {
    typography: 'body2',
    fontWeight: 500,
    marginLeft: '.875rem',
    transition: 'opacity 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  },
  menuIconNameContainer: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
  },
  menuNotificationBadge: {
    right: '-0.25rem',
    top: '-0.25rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'background.default',
    bgcolor: 'error.main',
    borderRadius: '3vmin',
    p: '0 0.25rem',
  },
  activeTab: {
    bgcolor: 'primary.light',
  },
  referralNudgeContainerClass: {
    top: '29.5rem',
    transition: 'left 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  },
  referralNudgeClass: {
    '&:after': {
      borderWidth: '12px 12px 12px 0',
      top: '24%',
      left: '-12px',
      marginTop: '-12px',
    },
  },
};
