import AddIcon from '@mui/icons-material/Add';
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Typography,
} from '@mui/material';
import moment from 'moment';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../../app/hooks';
import { TEST_CONSTANTS } from '../../../constants/testConstants';
import {
  useGetMyOfficeConnectedListQuery,
  useGetMyOfficeInvitationListQuery,
} from '../../../services/myOfficeService';
import { commonClasses } from '../../../style/commonClasses';
import { myOfficeStyle } from '../../../style/dashboardStyles/myOfficeStyle';
import { ChatUser } from '../../../types/chatTypes';
import {
  MyOfficeTeamMemberInvitationListResponseType,
  MyOfficeTeamMemberType,
} from '../../../types/myOfficeType';
import { joinStrings } from '../../../utilities/joinStrings';
import AddTeamMember from './AddTeamMember';

const MyOffice = () => {
  const navigate = useNavigate();
  const { id: loggedInUserId, userInfo } = useAppSelector(
    (state) => state.userReducer
  );

  const [isAddMemberDialogOpen, setIsAddMemberDialogOpen] = useState(false);

  const { teamMemberInvitationList } = useAppSelector(
    ({ myOfficeReducer }: Record<string, any>) => myOfficeReducer ?? {}
  );

  const { data: teamMembersList, isLoading: isMyOfficeListLoaderActive } =
    useGetMyOfficeConnectedListQuery(
      { id: userInfo?.practiceId },
      { skip: !userInfo?.practiceId }
    );

  const { isLoading: isMyOfficeInvitationListLoaderActive } =
    useGetMyOfficeInvitationListQuery();

  const memberToChatUser = (member?: MyOfficeTeamMemberType) => {
    return {
      userId: member?.id,
      profileUrl: member?.profileImage,
      name:
        member?.displayName ??
        joinStrings([member?.firstName, member?.lastName]),
      role: member?.role,
    } as ChatUser;
  };

  return (
    <Box
      data-testid={TEST_CONSTANTS.MY_OFFICE_CONTAINER}
      sx={myOfficeStyle.mainContainer}
    >
      <Box sx={myOfficeStyle.headerContainer}>
        <Box>
          <Typography variant='h6' sx={{ fontWeight: 500 }}>
            My Office
          </Typography>
          <Typography variant='body2'>Manage your office members</Typography>
        </Box>
        <Button
          variant='contained'
          sx={[commonClasses.flatSlimButton, { minWidth: '10.125rem' }]}
          startIcon={<AddIcon />}
          onClick={() => setIsAddMemberDialogOpen(true)}
        >
          Add Member
        </Button>
      </Box>
      <Box sx={myOfficeStyle.teamMemberContainer}>
        {isMyOfficeListLoaderActive || isMyOfficeInvitationListLoaderActive ? (
          <Box
            sx={commonClasses.loaderContainer}
            data-testid={TEST_CONSTANTS.MY_OFFICE_TEAM_MEMBER_LIST_LOADER}
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            {teamMemberInvitationList?.map(
              (invite: MyOfficeTeamMemberInvitationListResponseType) => (
                <Box key={invite?.id} sx={commonClasses.practiceCard.card}>
                  <Box sx={commonClasses.practiceCard.practiceCardDetails}>
                    <Avatar sx={myOfficeStyle.teamMemberInviteAvatar} />

                    <Box>
                      <Box sx={commonClasses.cardTitle}>
                        {invite?.toEmail}{' '}
                        <Box sx={commonClasses.cardTag}>pending</Box>
                      </Box>
                      <Box sx={commonClasses.cardSubtitle}>
                        Invited:{' '}
                        {moment(invite?.invitedDate).format(
                          'MM/DD/YYYY - HH:mm A'
                        )}
                      </Box>
                    </Box>
                  </Box>
                </Box>
              )
            )}
            {teamMembersList?.map((member: MyOfficeTeamMemberType) => (
              <Box key={member?.id} sx={commonClasses.practiceCard.card}>
                <Box sx={commonClasses.practiceCard.practiceCardDetails}>
                  <Avatar
                    src={member?.profileImage}
                    sx={[
                      commonClasses.initialsCircle,
                      { bgcolor: member?.bgcolor },
                    ]}
                  >
                    {member?.firstName.charAt(0) + member?.lastName.charAt(0)}
                  </Avatar>

                  <Box>
                    <Box sx={commonClasses.cardTitle}>
                      {member?.firstName} {member?.lastName}
                    </Box>
                    <Box sx={commonClasses.cardSubtitle}>{member?.role}</Box>
                  </Box>
                </Box>
                {loggedInUserId !== member.id && (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '1.5rem',
                    }}
                  >
                    <Button
                      variant='outlined'
                      sx={commonClasses.commonListChatButton}
                      onClick={() => {
                        navigate('/dashboard/chat', {
                          state: { user: memberToChatUser(member) },
                        });
                      }}
                    >
                      Chat
                    </Button>
                  </Box>
                )}
              </Box>
            ))}
          </>
        )}
      </Box>
      {isAddMemberDialogOpen && (
        <AddTeamMember
          isAddMemberDialogOpen={isAddMemberDialogOpen}
          setIsAddMemberDialogOpen={setIsAddMemberDialogOpen}
          isRequestedFromMyOffice={true}
        />
      )}
    </Box>
  );
};

export default MyOffice;
