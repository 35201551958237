import { Close } from '@mui/icons-material';
import {
  Box,
  Checkbox,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from '@mui/material';
import Highlighter from 'react-highlight-words';
import { TEST_CONSTANTS } from '../../../../constants/testConstants';
import { chatStyle } from '../../../../style/dashboardStyles/chatStyle';
import { ChatUser } from '../../../../types/chatTypes';
import { joinStrings } from '../../../../utilities/joinStrings';
import { ProfileImageAvatar } from 'components/ProfileImageAvatar';

const ChatUserItem = ({
  props,
  user,
  selected,
  highlight = '',
  divider,
  compact,
  large,
  onSelect,
  onDelete,
  onCheck,
}: {
  props?: object;
  user: ChatUser;
  selected?: boolean;
  highlight?: string;
  divider?: boolean;
  compact?: boolean;
  large?: boolean;
  onSelect?: (user: ChatUser, selected?: boolean) => void;
  onDelete?: (user: ChatUser) => void;
  onCheck?: (user: ChatUser, selected?: boolean) => void;
}) => {
  const description = joinStrings([user.role, user.practiceName], ' - ');

  return (
    <ListItem
      onClick={() => onSelect && onSelect(user)}
      {...props}
      sx={{
        ...chatStyle.chatListItem,
        ...chatStyle.recepientListItem,
        ...(onSelect && { cursor: 'pointer' }),
        ...(compact && chatStyle.chatListItemCompact),
        ...(large && chatStyle.chatListItemLarge),
      }}
      selected={selected}
      data-testid={TEST_CONSTANTS.CHAT_USER_ITEM}
      key={user.userId}
      divider={divider}
    >
      <ListItemAvatar>
        <Box height='40px' width='40px'>
          <ProfileImageAvatar
            src={user?.profileUrl || ''}
            alt={`${user?.name ?? ''}-profileImg`}
            data-testid={TEST_CONSTANTS.CHAT_AVATAR}
          />
        </Box>
      </ListItemAvatar>
      <ListItemText
        disableTypography
        sx={chatStyle.chatListItemText}
        primary={
          <Typography
            variant='body2'
            noWrap
            fontWeight={!highlight ? '600' : undefined}
          >
            <Highlighter
              searchWords={[highlight]}
              textToHighlight={user?.name ?? ''}
            />
          </Typography>
        }
        secondary={
          <Typography variant='caption' noWrap>
            <Highlighter
              searchWords={[highlight]}
              textToHighlight={description}
            />
          </Typography>
        }
      />
      {onCheck && (
        <ListItemSecondaryAction sx={chatStyle.chatListItemSecondary}>
          <Checkbox
            checked={!!selected}
            sx={chatStyle.chatListItemSelector}
            onChange={(e) => {
              onCheck && onCheck(user, e.target.checked);
            }}
            inputProps={{
              'aria-label': 'Select User',
            }}
          />
        </ListItemSecondaryAction>
      )}
      {onDelete && (
        <ListItemSecondaryAction sx={chatStyle.chatListItemSecondary}>
          <IconButton aria-label='delete user' onClick={() => onDelete(user)}>
            <Close />
          </IconButton>
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );
};

export default ChatUserItem;
