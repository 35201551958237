import {
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { uppercaseInitials } from 'helpers/getFirstTwoLetters';
import { commonReferralStyle } from 'style/dashboardStyles/referralStyle';
import { ReferralPractice } from 'types/referralTypes/referralTypes';
import { useAppSelector } from 'app/hooks';
import Highlighter from 'components/Highlighter';

export type PracticeListItemProps = {
  props: object;
  option: ReferralPractice;
};

const PracticeListItem = ({ props, option }: PracticeListItemProps) => {
  const { selectedPractice, practiceSearchKeyword } = useAppSelector(
    (state) => state.referralReducer
  );

  return (
    <ListItem key={uuidv4()} {...props} sx={commonReferralStyle.listItem}>
      <ListItemAvatar>
        <Avatar sx={commonReferralStyle.listItemAvatar}>
          {uppercaseInitials(option?.Name!)}
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={
          <Highlighter
            highlight={practiceSearchKeyword || selectedPractice?.Name || ''}
          >
            {option?.Name!}
          </Highlighter>
        }
        secondary={
          <Typography sx={commonReferralStyle.listItemSecondaryText}>
            {option?.OrganizationType}
            {option?.Address[0]?.fullAddress && (
              <>
                {' \u2022 '}
                {option?.Address[0]?.fullAddress}
              </>
            )}
          </Typography>
        }
      />
    </ListItem>
  );
};

export default PracticeListItem;
