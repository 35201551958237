import { AddContactRequestData, DocumentTabs } from '../types/documentsTypes';

export const documentTabs: DocumentTabs = {
  received: {
    value: 0,
    name: 'Received',
  },
  sent: {
    value: 1,
    name: 'Sent',
  },
};

export const documentFilterOptions = [
  'All',
  'Document',
  'Referral',
  'Website Referral',
];

export const statusFilterOptions = ['All', 'Unprocessed', 'Processed'];

export const documentOptions = ['Document', 'Referral'];

export const newPatientIntialValue = {
  firstName: '',
  lastName: '',
  dateOfBirth: null,
} as const;

export enum AcceptedDocumentType {
  AllImages = 'image/*',
  Doc = '.doc',
  Pdf = '.pdf',
}

// type of documents (sent or received)
export enum OfficeFileType {
  SENT = 0,
  RECEIVED = 1,
}

export const maxDocsUploadSize = 20_971_520;

export const allowedFileExtensions = [
  'png',
  'jpg',
  'jpeg',
  'webp',
  'gif',
  'tif',
  'doc',
  'docx',
  'xls',
  'xlsx',
  'xlsm',
  'xlsb',
  'xltx',
  'xltm',
  'xlt',
  'xml',
  'xlam',
  'xla',
  'xlr',
  'csv',
  'pdf',
  'txt'
];
export const imageFileExtensions = [
  'png',
  'jpg',
  'jpeg',
  'webp',
  'gif',
  'tif',
  'svg',
];

export const extensionsNotForPreview = [
  'xlsx',
  'xls',
  'csv',
  'doc',
  'docx',
  'txt',
];

export const allowedFileExtensionsList = allowedFileExtensions
  .map((e) => '.' + e)
  .join(',');

export const DOCUMENT_STATUS_DATABASE_VALUES: {
  NOT_PROCESSED: 'NotProcessed';
  PROCESSED: 'Processed';
} = {
  NOT_PROCESSED: 'NotProcessed',
  PROCESSED: 'Processed',
};

export const DOCUMENT_STATUS_DROPDOWN_VALUES: {
  UNPROCESSED: 'Unprocessed';
  PROCESSED: 'Processed';
} = {
  UNPROCESSED: 'Unprocessed',
  PROCESSED: 'Processed',
};

export const DOCUMENT_TYPE: {
  DOCUMENT: 'Document';
  REFERRAL: 'Referral';
  WEBSITE_REFERRAL: 'Website Referral';
} = {
  DOCUMENT: 'Document',
  REFERRAL: 'Referral',
  WEBSITE_REFERRAL: 'Website Referral',
};

export const ADD_CONTACT_INITIAL_STATE: Partial<AddContactRequestData> = {
  PracticeName: '',
};

export const DOCUMENT_TYPES_FILTER: Record<
  string,
  'All' | 'Document' | 'Referral' | 'Website Referral'
> = {
  DOCUMENT: 'Document',
  REFERRAL: 'Referral',
  WEBSITE_REFERRAL: 'Website Referral',
  ALL: 'All',
};

export const DOCUMENT_STATUS_FILTER: Record<
  string,
  'All' | 'Processed' | 'Unprocessed'
> = {
  ALL: 'All',
  PROCESSED: 'Processed',
  UNPROCESSED: 'Unprocessed',
};
