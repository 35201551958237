import {
  Action,
  configureStore,
  Middleware,
  PreloadedState,
  ThunkAction,
} from '@reduxjs/toolkit';
import chatService from 'services/chatService';
import notificationService from 'services/notificationService';
import practiceService from 'services/practiceService';
import fileManagerService from 'services/fileManagerService';
import patientService from 'services/patientService';
import officeFilesService from 'services/officeFilesService';
import recipientService from 'services/recipientService';
import personService from 'services/personService';
import userWorkStatusService from '../services/userWorkStatusService';
import rootReducer from './rootReducer';
import myNetworkService from 'services/myNetworkService';

export const setupStore = (preloadedState?: PreloadedState<RootState>) => {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware()
        .concat(notificationService.middleware as Middleware)
        .concat(chatService.middleware as Middleware)
        .concat(patientService.middleware as Middleware)
        .concat(fileManagerService.middleware as Middleware)
        .concat(officeFilesService.middleware as Middleware)
        .concat(practiceService.middleware as Middleware)
        .concat(recipientService.middleware as Middleware)
        .concat(myNetworkService.middleware as Middleware)
        .concat(personService.middleware as Middleware)
        .concat(userWorkStatusService.middleware as Middleware),
  });
};

const preloadedState = {};

export const store = configureStore({
  reducer: rootReducer,
  preloadedState,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(notificationService.middleware as Middleware)
      .concat(chatService.middleware as Middleware)
      .concat(patientService.middleware as Middleware)
      .concat(fileManagerService.middleware as Middleware)
      .concat(officeFilesService.middleware as Middleware)
      .concat(practiceService.middleware as Middleware)
      .concat(recipientService.middleware as Middleware)
      .concat(personService.middleware as Middleware)
      .concat(recipientService.middleware as Middleware)
      .concat(userWorkStatusService.middleware as Middleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
