import { Menu, MenuItem } from '@mui/material';
import React, { useState } from 'react';
import { TEST_CONSTANTS } from '../../../../constants/testConstants';
import { downloadAttachmentAction } from '../../../../store/actions/dashboardActions/documentActions';
import {
  DocumentsTableDetailsType,
  TableDetailsAttachment,
} from '../../../../types/documentsTypes';
import ForwardDocumentDialog from '../ForwardDocumentDialog';
import DeleteDocumentConfirmationDialog from './DeleteDocumentConfirmationDialog';
import DocumentTypeChangeDialog from './DocumentTypeChangeDialog';

type DocumentsTableActionMenuProps = {
  index: number;
  selectedRecordIndex: number | null;
  setSelectedRecordIndex: React.Dispatch<React.SetStateAction<number | null>>;
  actionMenuElement: null | HTMLElement;
  setActionMenuElement: React.Dispatch<
    React.SetStateAction<null | HTMLElement>
  >;
  isActionMenuOpen: boolean;
  selectedRecord: DocumentsTableDetailsType;
};

const DocumentsTableActionMenu = (props: DocumentsTableActionMenuProps) => {
  const {
    index,
    selectedRecordIndex,
    setSelectedRecordIndex,
    actionMenuElement,
    setActionMenuElement,
    isActionMenuOpen,
    selectedRecord,
  } = props;
  const [isDeleteDiaogOpen, setIsDeleteDiaogOpen] = useState<boolean>(false);
  const [isDocTypeChangeDiaogOpen, setIsDocTypeChangeDiaogOpen] =
    useState<boolean>(false);
  const isWebsiteReferral =
    !!selectedRecord.guestSenderEmailAddress?.length &&
    selectedRecord.isReferral;
  const [forwardDocRecordId, setForwardDocRecordId] = useState<string | null>(
    null
  );
  const handleActionMenuClose = () => {
    setActionMenuElement(null);
    setSelectedRecordIndex(null);
  };

  const onClickDownloadAllAttachments = () => {
    if (selectedRecord.attachments?.length) {
      selectedRecord.attachments?.forEach(
        (attachment: TableDetailsAttachment) => {
          const fileName = attachment.attachmentUrl.substring(
            attachment.attachmentUrl.lastIndexOf('/') + 1
          );
          if (!!fileName?.toString()?.length)
            downloadAttachmentAction(fileName, attachment.fileName);
        }
      );
    }
    handleActionMenuClose();
  };

  return (
    <>
      <Menu
        data-testid={`${TEST_CONSTANTS.DOCUMENT_TABLE_ACTION_MENU}-${index}`}
        id={`action-menu-${index}`}
        MenuListProps={{
          'aria-labelledby': `action-menu-icon-${index}`,
        }}
        open={isActionMenuOpen && index === selectedRecordIndex}
        onClose={handleActionMenuClose}
        anchorEl={actionMenuElement}
      >
        <MenuItem
          data-testid={TEST_CONSTANTS.DOCUMENT_TABLE_ACTION_DOWNLOAD_MENU}
          onClick={onClickDownloadAllAttachments}
        >
          Download all attachments
        </MenuItem>
        <MenuItem
          data-testid={TEST_CONSTANTS.DOCUMENT_TABLE_ACTION_FORWARD_MENU}
          onClick={() => setForwardDocRecordId(selectedRecord.id)}
        >
          Forward
        </MenuItem>
        {/* Do not show this menu item if the record is Webiste Referral */}
        {!isWebsiteReferral && (
          <MenuItem
            data-testid={
              TEST_CONSTANTS.DOCUMENT_TABLE_ACTION_CHANGE_DOCUMENT_TYPE_MENU
            }
            onClick={() => setIsDocTypeChangeDiaogOpen(true)}
          >
            Change document type
          </MenuItem>
        )}
        <MenuItem
          data-testid={TEST_CONSTANTS.DOCUMENT_TABLE_ACTION_DELETE_MENU}
          onClick={() => setIsDeleteDiaogOpen(true)}
        >
          Delete
        </MenuItem>
      </Menu>
      {isDeleteDiaogOpen && (
        <DeleteDocumentConfirmationDialog
          selectedRecord={selectedRecord}
          handleActionMenuClose={handleActionMenuClose}
          isDeleteDiaogOpen={isDeleteDiaogOpen}
          setIsDeleteDiaogOpen={setIsDeleteDiaogOpen}
        />
      )}
      {isDocTypeChangeDiaogOpen && (
        <DocumentTypeChangeDialog
          selectedRecord={selectedRecord}
          isDocTypeChangeDiaogOpen={isDocTypeChangeDiaogOpen}
          setIsDocTypeChangeDiaogOpen={setIsDocTypeChangeDiaogOpen}
          handleActionMenuClose={handleActionMenuClose}
        />
      )}
      {forwardDocRecordId && (
        <ForwardDocumentDialog
          forwardDocRecordId={forwardDocRecordId}
          setForwardDocRecordId={setForwardDocRecordId}
          handleActionMenuClose={handleActionMenuClose}
        />
      )}
    </>
  );
};

export default DocumentsTableActionMenu;
