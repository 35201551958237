import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Button,
  Stack,
  Typography,
} from '@mui/material';
import { Check } from '@mui/icons-material';
import { ComponentProps, type ReactNode } from 'react';
import { referralStepItemStyle } from 'style/dashboardStyles/referralStyle';
import { setCurrentStep } from 'store/slices/referralSlice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { REFERRAL_PAGE_TEST_CONSTANTS } from 'constants/testConstants';

export type ReferralStepItemProps = {
  children: ReactNode;
  stepNumber: number;
  title: ReactNode;
  titleOnComplete?: ReactNode;
} & ComponentProps<typeof Accordion>;

const ReferralStepItem: React.FC<ReferralStepItemProps> = ({
  children,
  stepNumber,
  title,
  titleOnComplete = '',
  ...rest
}) => {
  const dispatch = useAppDispatch();

  const { currentStep, completedSteps } = useAppSelector(
    (state) => state.referralReducer
  );

  const isStepOpen = currentStep === stepNumber;
  const isStepCompleted = completedSteps.includes(stepNumber);
  const isStepClosed = !isStepOpen && !isStepCompleted;
  const isShowEditButton = !isStepOpen && isStepCompleted;
  const isShowCompletedTitle = isShowEditButton && titleOnComplete;

  const additionalSxProps = {
    step: isStepClosed ? referralStepItemStyle.header.stepUnOpened : {},
    stepNumber: isStepClosed ? referralStepItemStyle.header.stepUnOpened : {},
    label: isStepClosed ? referralStepItemStyle.header.labelUnopened : {},
  };

  return (
    <Accordion
      data-testid={REFERRAL_PAGE_TEST_CONSTANTS.REFERRAL_STEP_ITEM_CONTAINER}
      expanded={isStepOpen}
      sx={referralStepItemStyle.root.container}
      {...rest}
    >
      <AccordionSummary
        data-testid={REFERRAL_PAGE_TEST_CONSTANTS.REFERRAL_STEP_ITEM_HEADER}
        sx={[referralStepItemStyle.header.container, additionalSxProps.step]}
        expandIcon={
          isShowEditButton ? (
            <Button
              data-testid={
                REFERRAL_PAGE_TEST_CONSTANTS.REFERRAL_STEP_ITEM_HEADER_EDIT_BUTTON
              }
              variant='text'
              sx={referralStepItemStyle.header.editButton}
              onClick={() => dispatch(setCurrentStep(stepNumber))}
            >
              <Typography variant='body2'>Edit</Typography>
            </Button>
          ) : null
        }
      >
        <Stack direction='row' alignItems='center'>
          <Avatar
            color='primary'
            sx={[
              referralStepItemStyle.header.stepNumber.container,
              additionalSxProps.stepNumber,
            ]}
          >
            {isStepCompleted ? (
              <Check
                data-testid={
                  REFERRAL_PAGE_TEST_CONSTANTS.REFERRAL_STEP_ITEM_HEADER_CHECK_ICON
                }
                sx={referralStepItemStyle.header.stepNumber.checkIcon}
              />
            ) : (
              stepNumber
            )}
          </Avatar>
          <Typography
            sx={[referralStepItemStyle.header.label, additionalSxProps.label]}
          >
            {isShowCompletedTitle ? titleOnComplete : title}
          </Typography>
        </Stack>
      </AccordionSummary>
      <AccordionDetails sx={referralStepItemStyle.content.container}>
        {children}
      </AccordionDetails>
    </Accordion>
  );
};

export default ReferralStepItem;
