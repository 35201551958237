import { Box, Divider, Typography } from '@mui/material';
import iMac from '../../../assets/images/intro_screen/iMac_pro_front_view_mockup.svg';
import { introductionScreenStyle } from '../../../style/authStyle/introductionScreenStyle';
import backgroundSwirls from '../../../assets/images/intro_screen/intro_screen_background_swirls.svg';
import { ReactComponent as ChatIcon } from '../../../assets/images/intro_screen/Chat.svg';
import { ReactComponent as ReferIcon } from '../../../assets/images/intro_screen/Refer.svg';
import { ReactComponent as TransferIcon } from '../../../assets/images/intro_screen/Transfer.svg';
import { ReactComponent as MoreIcon } from '../../../assets/images/intro_screen/More.svg';
import { colorPalette } from '../../../theme/colorPalette';
import FlatButton from '../../../components/FlatButton';
import { TEST_CONSTANTS } from '../../../constants/testConstants';

const IntroductionScreenContent = () => {
  return (
    <Box
      data-testid={TEST_CONSTANTS.INTRODUCTION_SCREEN_CONTENT_CONTAINER}
      sx={introductionScreenStyle.introductionScreenContentContainer}
    >
      <img
        className='introduction-screen-background-swirls'
        src={backgroundSwirls}
        alt='background-swirls'
      />
      <Box sx={introductionScreenStyle.leftContentContainer}>
        <Box>
          <Typography
            data-testid={TEST_CONSTANTS.INTRODUCTION_SCREEN_HEADING}
            variant='h4'
            marginBottom={2}
          >
            Power up your <br />
            practice with Drtalk
          </Typography>
          <Typography variant='h6' fontWeight={400} marginTop={0.8}>
            HIPAA compliant communication platform <br />
            and business tools for the dental office.
          </Typography>
          <FlatButton
            variant='contained'
            color='secondary'
            sx={introductionScreenStyle.tryForFreeButton}
          >
            Try for free
          </FlatButton>
        </Box>
        <Box sx={introductionScreenStyle.demoModuleContainer}>
          <Box>
            <ChatIcon />
            <Box>CHAT</Box>
          </Box>
          <Divider
            orientation='vertical'
            flexItem
            sx={{ bgcolor: colorPalette.primary[300] }}
          />
          <Box>
            <TransferIcon /> <Box>TRANSFER</Box>
          </Box>
          <Divider
            orientation='vertical'
            flexItem
            sx={{ bgcolor: colorPalette.primary[300] }}
          />
          <Box>
            <ReferIcon />
            <Box>REFER</Box>
          </Box>
          <Divider
            orientation='vertical'
            flexItem
            sx={{ bgcolor: colorPalette.primary[300] }}
          />
          <Box>
            <MoreIcon /> <Box>MORE</Box>
          </Box>
        </Box>
      </Box>

      <img src={iMac} alt='iMac' className='introduction-screen-iMac' />
    </Box>
  );
};

export default IntroductionScreenContent;
