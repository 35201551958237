export async function generateThumbnailFromFormData(
  formData: FormData
): Promise<FormData> {
  const thumbnailFormData = new FormData();

  const keys = formData.keys();
  let nextKey = keys.next();

  while (!nextKey.done) {
    const key = nextKey.value;
    const value = formData.get(key);

    if (value instanceof File) {
      const file = value as File;
      const thumbnailFile = await generateThumbnailFromFile(file);
      thumbnailFormData.append(key, thumbnailFile);
    } else {
      thumbnailFormData.append(key, value!);
    }

    nextKey = keys.next();
  }

  return thumbnailFormData;
}

async function generateThumbnailFromFile(file: File): Promise<File> {
  return new Promise((resolve) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      const image = new Image();
      image.src = e.target?.result as string;

      image.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        if (ctx) {
          const { width, height } = image;
          const aspectRatio = width / height;
          const maxDimension = 210; // Maximum dimension for the thumbnail

          let thumbnailWidth = width;
          let thumbnailHeight = height;

          if (width > height) {
            thumbnailWidth = maxDimension;
            thumbnailHeight = maxDimension / aspectRatio;
          } else {
            thumbnailWidth = maxDimension * aspectRatio;
            thumbnailHeight = maxDimension;
          }

          canvas.width = thumbnailWidth;
          canvas.height = thumbnailHeight;

          ctx.drawImage(image, 0, 0, thumbnailWidth, thumbnailHeight);

          canvas.toBlob((blob) => {
            if (blob) {
              const thumbnailFile = new File([blob], file.name, {
                type: file.type,
                lastModified: Date.now(),
              });

              resolve(thumbnailFile);
            }
          }, file.type);
        }
      };
    };

    reader.readAsDataURL(file);
  });
}
