import type { Action } from '../../types/Redux';
import type { passwordValidationMessages } from '../../constants/validationMessageConstants';

export const AUTH_REDUX_CONSTANTS = {
  CHANGE_AUTH_STATUS_ACTION: '@auth/CHANGE_AUTH_STATUS_ACTION',
  CHANGE_PASSWORD_ERROR: '@auth/CHANGE_PASSWORD_ERROR',
  CHANGE_PASSWORD_START: '@auth/CHANGE_PASSWORD_START',
  CHANGE_PASSWORD_SUCCESS: '@auth/CHANGE_PASSWORD_SUCCESS',
  CLEAR_ERROR_MESSAGE: '@auth/CLEAR_ERROR_MESSAGE',
  LOGIN_USER_ACTION: '@auth/LOGIN_USER_ACTION',
  LOGOUT_USER_ACTION: '@auth/LOGOUT_USER_ACTION',
  SET_AUTH_TOKEN: '@auth/SET_AUTH_TOKEN',
  VERIFY_TOKEN: '@auth/VERIFY_TOKEN',
  APP_UPDATE_AVAILABLE: '@auth/APP_UPDATE_AVAILABLE',
} as const;

// Can add other types here
export type ErrorMessage = {
  type: 'changePassword';
  message: keyof typeof passwordValidationMessages;
};

export type AuthReducerState = {
  authStatus: boolean;
  errorMessage: ErrorMessage | null;
  token: string;
  isUserAuthenticated: boolean;
  isAppUpdateAvailable: boolean;
};

export type ClearErrorMessage = Action<
  typeof AUTH_REDUX_CONSTANTS.CLEAR_ERROR_MESSAGE
>;
export type ChangeAuthStatusAction = Action<
  typeof AUTH_REDUX_CONSTANTS.CHANGE_AUTH_STATUS_ACTION,
  boolean
>;
export type SetAuthToken = Action<
  typeof AUTH_REDUX_CONSTANTS.SET_AUTH_TOKEN,
  string | null
>;

export type ChangePasswordStart = Action<
  typeof AUTH_REDUX_CONSTANTS.CHANGE_PASSWORD_START
>;
export type ChangePasswordSuccess = Action<
  typeof AUTH_REDUX_CONSTANTS.CHANGE_PASSWORD_SUCCESS
>;
export type ChangePasswordError = Action<
  typeof AUTH_REDUX_CONSTANTS.CHANGE_PASSWORD_ERROR,
  ErrorMessage | null
>;
export type LoginUserAction = Action<
  typeof AUTH_REDUX_CONSTANTS.LOGIN_USER_ACTION
>;
export type LogoutUserAction = Action<
  typeof AUTH_REDUX_CONSTANTS.LOGOUT_USER_ACTION
>;

export type VerifyTokenAction = Action<
  typeof AUTH_REDUX_CONSTANTS.VERIFY_TOKEN,
  boolean
>;

export type IsAppUpdateAvailableAction = Action<
  typeof AUTH_REDUX_CONSTANTS.APP_UPDATE_AVAILABLE,
  boolean
>;

export type AuthActionTypes =
  | ChangeAuthStatusAction
  | ClearErrorMessage
  | SetAuthToken
  | ChangePasswordStart
  | ChangePasswordSuccess
  | ChangePasswordError
  | LoginUserAction
  | LogoutUserAction
  | VerifyTokenAction
  | IsAppUpdateAvailableAction;
