import { ArrowBackIos, CloseRounded } from '@mui/icons-material';
import { Box, Dialog, DialogTitle, IconButton } from '@mui/material';
import { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { TEST_CONSTANTS } from '../../../../constants/testConstants';
import {
  setSendDocumentDialogStepAction,
  setSendDocumentFormDataAction,
} from '../../../../store/actions/dashboardActions/documentActions';
import { initialSendDocumentFormData } from '../../../../store/reducers/dashboardReducers/documentsReducer';
import { RootState } from '../../../../store/store';
import { sendDocumentsDialogsStyle } from '../../../../style/dashboardStyles/documents/sendDocumentsDialogsStyle';
import { documentStyle } from '../../../../style/dashboardStyles/documentStyle';
import {
  DocumentReducer,
  SendDocumentsDialogProps,
  Thumbnail,
  UploadedDocument,
} from '../../../../types/documentsTypes';
import SendDocumentsStepOne from './SendDocumentsStepOne';
import SendDocumentsStepTwo from './SendDocumentsStepTwo';

const SendDocumentsDialog = (props: SendDocumentsDialogProps) => {
  const dispatch = useAppDispatch();

  const [uploadedFiles, setUploadedFiles] = useState<UploadedDocument[]>([]);
  const [selectedFiles, setSelectedFiles] = useState<Thumbnail[]>([]);
  const { sendDocumentStep } = useAppSelector(
    (state: RootState) => state.documentReducer as DocumentReducer
  );

  const {
    isSendDocumentsDialogOpen,
    setIsSendDocumentsDialogOpen,
    onSendDocument,
  } = props;

  const handleCloseDialog = () => {
    setIsSendDocumentsDialogOpen(false);
    dispatch(setSendDocumentFormDataAction(initialSendDocumentFormData));
    dispatch(setSendDocumentDialogStepAction(1));
  };

  return (
    <Dialog
      data-testid={TEST_CONSTANTS.SEND_DOCUMENTS_DIALOG}
      sx={
        sendDocumentStep === 1
          ? sendDocumentsDialogsStyle.selectRecipientsDialogContainer
          : documentStyle.sendDocumentsDialog
      }
      aria-labelledby='send documents'
      aria-describedby='send documents'
      open={isSendDocumentsDialogOpen}
    >
      <DialogTitle sx={documentStyle.sendDocumentsDialog.title}>
        {sendDocumentStep === 2 && (
          <IconButton
            sx={documentStyle.sendDocumentsDialog.title.closeButton}
            onClick={() => {
              dispatch(setSendDocumentDialogStepAction(1));
            }}
          >
            <ArrowBackIos />
          </IconButton>
        )}
        <Box sx={documentStyle.sendDocumentsDialog.title.text}>
          {sendDocumentStep === 1
            ? 'Step 1/2: Select recipients'
            : 'Step 2/2: Upload documents and add details'}
        </Box>
        <IconButton
          sx={documentStyle.sendDocumentsDialog.title.closeButton}
          onClick={handleCloseDialog}
        >
          <CloseRounded />
        </IconButton>
      </DialogTitle>
      {sendDocumentStep === 1 ? (
        <SendDocumentsStepOne />
      ) : (
        <SendDocumentsStepTwo
          onSendDocument={onSendDocument}
          selectedFiles={selectedFiles}
          setSelectedFiles={setSelectedFiles}
          uploadedFiles={uploadedFiles}
          setUploadedFiles={setUploadedFiles}
        />
      )}
    </Dialog>
  );
};

export default SendDocumentsDialog;
