import {
  Patient,
  PatientStepFormErrors,
  ReferralNewPatientDetails,
  ReferralPractice,
  ReferralState,
} from 'types/referralTypes/referralTypes';
import { REFERRAL_STEPS } from 'constants/referralConstants';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getPracticeByToken } from '../../services/practiceService';

export const initialReferralState: ReferralState = {
  currentStep: REFERRAL_STEPS.PRACTICE_DETAILS,
  completedSteps: [],
  selectedPractice: null,
  selectedDoctorId: '',
  selectedExistingPatient: null,
  loadedReferralFormFileName: '',
  doctorSearchKeyword: '',
  practiceSearchKeyword: '',
  newPatientDetails: {
    firstName: '',
    lastName: '',
    dateOfBirth: null,
  },
  patientStepFormErrors: {
    existingPatient: '',
    newPatientFirstName: '',
    newPatientLastName: '',
    newPatientDateOfBirth: '',
    patientEmail: '',
  },
  practiceByToken: {
    customReferralFormUrl: null,
    id: '',
    name: '',
    teamMembers: [],
  },
  guestUserStepOneData: {
    email: '',
    practiceName: '',
  },
  guestRecaptchaToken: '',
};

const referralSlice = createSlice({
  name: 'referral',
  initialState: initialReferralState,
  reducers: {
    setCurrentStep: (state, action: PayloadAction<number>) => {
      state.currentStep = action.payload;
    },
    setSelectedPractice: (
      state,
      action: PayloadAction<ReferralPractice | null>
    ) => {
      state.selectedPractice = action.payload;

      if (!!state.practiceSearchKeyword) {
        state.practiceSearchKeyword = '';
      }

      if (!!state.selectedDoctorId) {
        state.selectedDoctorId = '';
      }
    },
    setSelectedDoctorId: (state, action: PayloadAction<string | undefined>) => {
      state.selectedDoctorId = action.payload ?? '';

      if (!!state.doctorSearchKeyword) {
        state.doctorSearchKeyword = '';
      }
    },
    setSelectedExistingPatient: (
      state,
      action: PayloadAction<Patient | null>
    ) => {
      if (state.patientStepFormErrors.existingPatient) {
        state.patientStepFormErrors.existingPatient = '';
      }

      state.selectedExistingPatient = action.payload;
    },
    setGuestUserStepOneData: (state, { payload }) => {
      state.guestUserStepOneData = {
        ...state.guestUserStepOneData,
        ...payload,
      };
    },
    setDoctorSearchKeyword: (state, action: PayloadAction<string>) => {
      state.doctorSearchKeyword = action.payload;
    },
    setPracticeSearchKeyword: (state, action: PayloadAction<string>) => {
      state.practiceSearchKeyword = action.payload;
    },
    goToNextStep: (state) => {
      const currentStep = state.currentStep;

      if (!state.completedSteps.includes(currentStep)) {
        state.completedSteps = [...state.completedSteps, currentStep];
      }

      state.currentStep = currentStep + 1;
    },
    setLoadedReferralFormFileName: (state, action: PayloadAction<string>) => {
      state.loadedReferralFormFileName = action.payload;
    },
    setNewPatientDetails: (
      state,
      action: PayloadAction<ReferralNewPatientDetails>
    ) => {
      state.newPatientDetails = action.payload;
    },
    setPatientStepFormErrors: (
      state,
      action: PayloadAction<PatientStepFormErrors>
    ) => {
      state.patientStepFormErrors = action.payload;
    },
    setGuestRecaptchaToken: (state, action: PayloadAction<string>) => {
      state.guestRecaptchaToken = action.payload;
    },
    resetReferralStateForPracticeByToken: (state) => ({
      ...initialReferralState,
      practiceByToken: state.practiceByToken,
    }),
    resetReferralState: () => initialReferralState,
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      getPracticeByToken.matchFulfilled,
      (state, { payload }) => {
        state.practiceByToken = payload;
      }
    );
  },
});

export const {
  setCurrentStep,
  setSelectedPractice,
  setSelectedDoctorId,
  setSelectedExistingPatient,
  setDoctorSearchKeyword,
  setPracticeSearchKeyword,
  setLoadedReferralFormFileName,
  setNewPatientDetails,
  setPatientStepFormErrors,
  setGuestRecaptchaToken,
  goToNextStep,
  resetReferralState,
  resetReferralStateForPracticeByToken,
  setGuestUserStepOneData,
} = referralSlice.actions;

export default referralSlice.reducer;
