import { useEffect } from 'react';
import { Route, Routes, useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { ROUTE_CONSTANTS_VARIABLE } from 'constants/routeConstants';
import { IS_INTRO_SCREEN_VISIBLE } from 'constants/urlConstants';
import { verifyTokenAction } from 'store/actions/authActions';
import { navigateToLogin } from 'helpers/navigateToLoginHelper';
import localStorageHelper from 'helpers/localStorageHelper';
import IntroductionScreen from 'pages/auth/introductionScreen/IntroductionScreen';
import Login from 'pages/auth/login/Login';
import OnboardingDefaultScreen from 'pages/auth/onboarding/OnboardingDefaultScreen';
import OnboardingNewPractice from 'pages/auth/onboarding/OnboardingNewPractice';
import OnboardingPendingApprovalDialog from 'pages/auth/onboarding/OnboardingPendingApprovalDialog';
import OnboardingPersoalInfoForm from 'pages/auth/onboarding/OnboardingPersonalInfoForm';
import SetPassword from 'pages/auth/SetPassword';
import Chat from 'pages/dashboard/chat/Chat';
import Dashboard from 'pages/dashboard/Dashboard';
import Documents from 'pages/dashboard/documents/Documents';
import GuestUserDocumentsTable from 'pages/dashboard/documents/GuestUserDocumentsTable';
import ReferralPage from 'pages/dashboard/referral/ReferralPage';
import Efax from 'pages/dashboard/efax/Efax';
import MyNetwork from 'pages/dashboard/myNetwork/MyNetwork';
import ViewConnection from 'pages/dashboard/myNetwork/ViewConnection';
import MyOffice from 'pages/dashboard/myOffice/MyOffice';
import InfoPage from 'pages/InfoPage';
import Profile from 'pages/profile/Profile';
import ProtectedRoute from './ProtectedRoute';
import GuestReferralPage from '../pages/dashboard/referral/GuestReferralPage';

const CustomRoutes = () => {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const { role, mobile } = useAppSelector((state) => ({
    role: state.userReducer.userInfo?.role,
    mobile: state.userReducer.userInfo?.mobile,
  }));

  useEffect(() => {
    if (localStorageHelper.getUserInfo()?.email) {
      dispatch(verifyTokenAction());
    }
    const cb = () => {
      const token = localStorageHelper.getAuthToken();
      const isOnboardingCompleted = localStorageHelper.getOnboardingStatus();
      const isAutoGeneratedPassword =
        localStorageHelper.getAutoGeneratedPasswordStatus();
      const isUserPendingApproval =
        localStorageHelper.getUserIsPendingApproval();
      const isOrganizationPendingApproval =
        localStorageHelper.getOrganizationApprovalStatus();

      if (token) {
        if (isUserPendingApproval || isOrganizationPendingApproval) {
          navigate(ROUTE_CONSTANTS_VARIABLE.ONBOARDING_PENDING_APPROVAL);
        } else {
          if (isOnboardingCompleted) {
            if (isAutoGeneratedPassword) {
              navigate(ROUTE_CONSTANTS_VARIABLE.SET_PASSWORD);
            } else if (!(role && mobile)) {
              navigate(ROUTE_CONSTANTS_VARIABLE.ONBOARDING_PERSONAL_INFO);
            } else {
              navigate(ROUTE_CONSTANTS_VARIABLE.DASHBOARD);
            }
          } else {
            navigate(ROUTE_CONSTANTS_VARIABLE.ONBOARDING);
          }
        }
      } else {
        navigate(navigateToLogin());
      }
    };

    window.addEventListener('storage', cb);

    return () => {
      window.removeEventListener('storage', cb);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const RedirectToV3 = () => {
    useEffect(() => {
      window.location.replace(String(process.env.REACT_APP_URL_V3));
    });

    return <></>;
  };

  const RedirectReferralToV3 = () => {
    /**
     * Router
     */
    const { token } = useParams();

    /**
     * Effects
     */
    useEffect(() => {
      if (!token) {
        window.location.replace(String(process.env.REACT_APP_URL_V3));
        return;
      }
      const urlParams = new URLSearchParams();
      urlParams.append('token', token);
      window.location.replace(`${process.env.REACT_APP_URL_V3}/referral?${urlParams.toString()}`);
    }, [token]);

    return <></>;
  }

  const RedirectGuestDocsToV3 = () => {
    /**
     * Router
     */
    const { token } = useParams();

    /**
     * Effects
     */
    useEffect(() => {
      if (!token) {
        window.location.replace(String(process.env.REACT_APP_URL_V3));
        return;
      }
      const urlParams = new URLSearchParams();
      urlParams.append('token', token);
      window.location.replace(`${process.env.REACT_APP_URL_V3}/dashboard/docs?${urlParams.toString()}`);
    }, [token]);

    return <></>;
  }

  return (
    <Routes>
      <Route
        path={ROUTE_CONSTANTS_VARIABLE.INTRODUCTION_SCREEN}
        element={<IntroductionScreen />}
      />

      <Route
        path={ROUTE_CONSTANTS_VARIABLE.GUEST_REFERRAL_SCREEN}
        element={<RedirectReferralToV3 />}
      />

      <Route
        path={ROUTE_CONSTANTS_VARIABLE.GUEST_DOCUMENT_SCREEN}
        element={<RedirectGuestDocsToV3 />}
      />

      <Route path={ROUTE_CONSTANTS_VARIABLE.LOGIN} element={<RedirectToV3 />} />

      {/* this route is not being used anywhere right now,
 InfoPage component was created for Guest user document page */}
      <Route path={ROUTE_CONSTANTS_VARIABLE.INFO_PAGE} element={<InfoPage />} />

      <Route
        path={ROUTE_CONSTANTS_VARIABLE.DASHBOARD}
        element={
          <ProtectedRoute>
            <Dashboard />
          </ProtectedRoute>
        }
      >
        <Route path={ROUTE_CONSTANTS_VARIABLE.CHAT} element={<Chat />} />
        <Route
          path={ROUTE_CONSTANTS_VARIABLE.DOCUMENTS}
          element={<Documents />}
        />
        <Route
          path={ROUTE_CONSTANTS_VARIABLE.MY_NETWORK}
          element={<MyNetwork />}
        />
        <Route
          path={ROUTE_CONSTANTS_VARIABLE.MY_NETWORK_VIEW_CONNECTION}
          element={<ViewConnection />}
        />

        <Route
          path={ROUTE_CONSTANTS_VARIABLE.MY_OFFICE}
          element={<MyOffice />}
        />
        <Route path={ROUTE_CONSTANTS_VARIABLE.PROFILE} element={<Profile />} />
        <Route
          path={ROUTE_CONSTANTS_VARIABLE.REFERRAL}
          element={<ReferralPage />}
        />
        <Route path={ROUTE_CONSTANTS_VARIABLE.EFAX} element={<Efax />} />
        <Route
          path={ROUTE_CONSTANTS_VARIABLE.REFER_A_PATIENT}
          element={<ReferralPage />}
        />
      </Route>
      <Route
        path={ROUTE_CONSTANTS_VARIABLE.SET_PASSWORD}
        element={
          <ProtectedRoute>
            <SetPassword />
          </ProtectedRoute>
        }
      />
      <Route
        path={ROUTE_CONSTANTS_VARIABLE.ONBOARDING}
        element={
          <ProtectedRoute>
            <OnboardingDefaultScreen />
          </ProtectedRoute>
        }
      />

      {/* when user wants to add new practice, instead of choosing from existing ones */}
      <Route
        path={ROUTE_CONSTANTS_VARIABLE.ONBOARDING_NEW_PRACTICE}
        element={
          <ProtectedRoute>
            <OnboardingNewPractice />
          </ProtectedRoute>
        }
      />

      <Route
        path={ROUTE_CONSTANTS_VARIABLE.ONBOARDING_PERSONAL_INFO}
        element={
          <ProtectedRoute>
            <OnboardingPersoalInfoForm />
          </ProtectedRoute>
        }
      />

      {/* if existing practice is selected in onboarding process, user should
            be redirected to this page stating pending approval, until admin approves the request for the
            selected practice */}
      <Route
        path={ROUTE_CONSTANTS_VARIABLE.ONBOARDING_PENDING_APPROVAL}
        element={
          <ProtectedRoute>
            <OnboardingPendingApprovalDialog />
          </ProtectedRoute>
        }
      />

      {/* temporary condition, will remove this once new onboarding flow will be implemented */}
      <Route
        path='*'
        element={
          IS_INTRO_SCREEN_VISIBLE ? <IntroductionScreen /> : <RedirectToV3 />
        }
      />
    </Routes>
  );
};

export default CustomRoutes;
