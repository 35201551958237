import {
  Close,
  ErrorOutlineRounded,
  FileDownloadOutlined,
} from '@mui/icons-material';
import {
  AppBar,
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  Stack,
  Toolbar,
  Typography,
} from '@mui/material';
import { SyntheticEvent } from 'react';
import Moment from 'react-moment';
import { chatStyle } from '../../../../style/dashboardStyles/chatStyle';
import { ChatMessageAttachment } from '../../../../types/chatTypes';

const ChatAttachmentDialog = ({
  attachment,
  onDownload,
  onClose,
}: {
  attachment?: ChatMessageAttachment;
  onDownload?: (attachment: ChatMessageAttachment) => void;
  onClose?: (event: SyntheticEvent) => void;
}) => {
  return (
    <Dialog fullScreen open={!!attachment} sx={chatStyle.chatDialogFull}>
      <AppBar>
        <Stack sx={chatStyle.chatDialogFull.Caption}>
          <Typography variant='body2' noWrap fontWeight={'700'}>
            {attachment?.name ?? ''}
          </Typography>
          <Typography variant='caption' noWrap>
            {'Uploaded: '}
            {attachment?.timestamp && (
              <Moment local unix locale='en_US' format='L LT'>
                {attachment.timestamp}
              </Moment>
            )}
            {attachment?.by && (
              <>
                {' by '}
                {attachment.by}
              </>
            )}
          </Typography>
        </Stack>
        <Toolbar>
          <IconButton
            aria-label='download'
            onClick={() => onDownload && attachment && onDownload(attachment)}
          >
            <FileDownloadOutlined fontSize={'large'} />
          </IconButton>
          <Divider orientation='vertical' flexItem />
          <IconButton onClick={onClose && onClose} aria-label='close'>
            <Close fontSize={'large'} fontWeight={'light'} />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent>
        {attachment?.file?.objectURL ? (
          <img src={attachment?.file?.objectURL} alt={attachment?.name} />
        ) : (
          <Box sx={chatStyle.chatMessageAttachment.loader}>
            {attachment?.file?.error ? (
              <ErrorOutlineRounded fontSize='large' />
            ) : (
              <CircularProgress size={'4rem'} color={'inherit'} />
            )}
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ChatAttachmentDialog;
