import localStorageHelper from 'helpers/localStorageHelper';
import {
  type UserActionTypes,
  UserApiActions,
  UserLocalActions,
  type UserReducerState,
} from '../reduxConstants/userReduxConstants';

export const initialUserReducer: UserReducerState = {
  id: '',
  userInfo: {
    firstName: '',
    lastName: '',
    practiceId: '',
    practiceName: '',
    practiceType: '',
    profileImage: '',
    role: '',
    mobile: '',
    email: '',
    isSmsNotificationTurnedOn: undefined,
  },
};

export const userReducer = (
  state = initialUserReducer,
  action: UserActionTypes
): UserReducerState => {
  switch (action.type) {
    case UserLocalActions.CLEAR_USER_INFO:
      return {
        ...state,
        id: initialUserReducer.id,
        userInfo: initialUserReducer.userInfo,
      };
    case UserLocalActions.SET_USER_INFO: {
      const { id, userInfo } = action.payload;
      return { ...state, id, userInfo };
    }
    case UserApiActions.SYNC_USER_BY_ID_SUCCESS:
      return { ...state, userInfo: action.payload };
    case UserApiActions.UPDATE_PERSONAL_INFO_SUCCESS: {
      if (!state.userInfo) return state;
      const { email, lastName, firstName, role, mobile } = action.payload;
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          email,
          lastName,
          firstName,
          role,
          mobile,
        },
      };
    }
    case UserApiActions.UPDATE_PROFILE_IMG_SUCCESS: {
      if (!state.userInfo) return state;

      const { profileImage } = action.payload;
      let profileImageFileName = profileImage;

      if (!!profileImageFileName) {
        const lastSlashIndex = profileImageFileName.lastIndexOf('/');

        profileImageFileName =
          lastSlashIndex !== -1
            ? profileImageFileName.substring(lastSlashIndex + 1)
            : profileImageFileName;
      }

      localStorageHelper.updateUserInfo({
        profileImage: profileImageFileName,
      });

      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          profileImage: profileImageFileName,
        },
      };
    }
    default:
      return state;
  }
};
