import { SNACKBAR_REDUX_CONSTANTS } from '../reduxConstants/snackbarReduxConstants';
import { store } from '../store';

export interface SnackbarProps {
  open: boolean;
  statusCode: number;
  message?: string;
  severity: 'error' | 'warning' | 'success' | 'info';
}

export const showSnackbarAction = (snackbarProps: SnackbarProps) => {
  store.dispatch({
    type: SNACKBAR_REDUX_CONSTANTS.SHOW_SNACKBAR,
    data: snackbarProps,
  });
};

export const hideSnackbarAction = () => {
  store.dispatch({
    type: SNACKBAR_REDUX_CONSTANTS.HIDE_SNACKBAR,
  });
};
