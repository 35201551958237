import {
  AUTH_REDUX_CONSTANTS,
  type AuthActionTypes,
  type AuthReducerState
} from '../reduxConstants/authReduxConstants';

export const initialAuthReducer: AuthReducerState = {
  authStatus: false,
  errorMessage: null,
  token: '',
  isUserAuthenticated: false,
  isAppUpdateAvailable: false,
};

export const authReducer = (
  state = initialAuthReducer,
  action: AuthActionTypes
): AuthReducerState => {
  switch (action.type) {
    case AUTH_REDUX_CONSTANTS.CHANGE_AUTH_STATUS_ACTION:
      return {
        ...state,
        authStatus: action.payload,
        isUserAuthenticated: action.payload,
      };

    case AUTH_REDUX_CONSTANTS.LOGOUT_USER_ACTION:
      localStorage.clear();
      return {
        ...state,
        authStatus: false,
        isUserAuthenticated: false,
      };

    case AUTH_REDUX_CONSTANTS.SET_AUTH_TOKEN:
      return {
        ...state,
        token: action.payload ?? '',
      };

    case AUTH_REDUX_CONSTANTS.CLEAR_ERROR_MESSAGE:
      return { ...state, errorMessage: initialAuthReducer.errorMessage };

    case AUTH_REDUX_CONSTANTS.CHANGE_PASSWORD_ERROR:
      return { ...state, errorMessage: action.payload };

    case AUTH_REDUX_CONSTANTS.VERIFY_TOKEN:
      return {
        ...state,
        isUserAuthenticated: action.payload,
      };

    case AUTH_REDUX_CONSTANTS.APP_UPDATE_AVAILABLE:
      return { ...state, isAppUpdateAvailable: action.payload };

    default:
      return state;
  }
};
