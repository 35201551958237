import {
  Avatar,
  Divider,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from '@mui/material';
import { useAppSelector } from 'app/hooks';
import Highlighter from 'components/Highlighter';
import {
  FIRST_AVAILABLE_DOCTOR_OPTION,
  FIRST_AVAILABLE_OPTION_LABEL,
} from 'constants/referralConstants';
import { useParams } from 'react-router-dom';
import { commonReferralStyle } from 'style/dashboardStyles/referralStyle';
import { Person, GuestUserReferralDoctor } from 'types/referralTypes/referralTypes';
import { v4 as uuidv4 } from 'uuid';

export type DoctorListItemProps = {
  props: object;
  option: string;
};

const DoctorListItem = ({ props, option }: DoctorListItemProps) => {
  const { token: guestToken } = useParams();
  const {
    selectedPractice,
    selectedDoctorId,
    doctorSearchKeyword,
    practiceByToken,
  } = useAppSelector((state) => state.referralReducer);

  const selectedDoctor = guestToken
    ? practiceByToken?.teamMembers.find(
        (person) => person.id === selectedDoctorId
      )
    : selectedPractice?.MyTeam.find(
        (person) => person.personID === selectedDoctorId
      );

  const currentOptionDoctor = guestToken
    ? practiceByToken?.teamMembers.find((person) => person.id === option)
    : selectedPractice?.MyTeam.find((person) => person.personID === option);

  const isFirstAvailableDoctorOption = option === FIRST_AVAILABLE_DOCTOR_OPTION;
  const hasSelectedFirstAvailable =
    selectedDoctorId === FIRST_AVAILABLE_DOCTOR_OPTION;

  /**
   * Returns the full name of a desired doctor.
   */
  const getDoctorFullName = (doctor: Person | GuestUserReferralDoctor) => {
    return `Dr. ${doctor?.firstName} ${doctor?.lastName}`;
  };

  const selectedDoctorName = !!selectedDoctor
    ? getDoctorFullName(selectedDoctor)
    : undefined;

  const highlightedText = hasSelectedFirstAvailable
    ? FIRST_AVAILABLE_OPTION_LABEL
    : doctorSearchKeyword || selectedDoctorName || '';

  return (
    <div key={uuidv4()}>
      <ListItem
        {...props}
        sx={commonReferralStyle.listItem}
        aria-selected={selectedDoctorId === option}
      >
        <ListItemAvatar>
          <Avatar
            src={currentOptionDoctor?.profileImage}
            sx={commonReferralStyle.listItemAvatar}
          />
        </ListItemAvatar>
        <ListItemText
          primary={
            <Highlighter highlight={highlightedText}>
              {isFirstAvailableDoctorOption
                ? FIRST_AVAILABLE_OPTION_LABEL
                : getDoctorFullName(currentOptionDoctor!)}
            </Highlighter>
          }
          secondary={
            !isFirstAvailableDoctorOption ? (
              <Typography sx={commonReferralStyle.listItemSecondaryText}>
                {guestToken ? practiceByToken?.name : selectedPractice?.Name}
              </Typography>
            ) : undefined
          }
        />
      </ListItem>
      {isFirstAvailableDoctorOption && (
        <Divider sx={commonReferralStyle.listItemsDivider} />
      )}
    </div>
  );
};

export default DoctorListItem;
