import { Button, Dialog, DialogContent, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { commonClasses } from '../style/commonClasses';
import { ErrorOutline } from '@mui/icons-material';

type NoEfaxNumberDialogProps = {
  isOpen: boolean;
  onToggleDialog: () => void;
};

const NoEfaxNumberDialog = (props: NoEfaxNumberDialogProps) => {
  const { isOpen, onToggleDialog } = props;

  return (
    <Dialog
      disableEscapeKeyDown
      sx={commonClasses.noEfaxNumberDialog}
      open={isOpen}
    >
      <DialogContent>
        <Box>
          <ErrorOutline color='warning' />
        </Box>
        <Typography mt={2} variant='h5' fontWeight={700}>
          Ok
        </Typography>
        <Typography
          mt={2}
          mb={3}
          variant='body1'
          fontWeight={500}
          sx={commonClasses.noEfaxNumberDialog.message}
        >
          Please configure an e-Fax number in your drtalk portal before using
          e-Fax
        </Typography>
        <Button variant='contained' color='primary' onClick={onToggleDialog}>
          OK
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default NoEfaxNumberDialog;
