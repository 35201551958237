import { Box, Divider, Stack } from '@mui/material';
import _ from 'lodash';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';
import { ReactComponent as DefaultImage } from '../../../assets/images/drTalkChatPlaceholder.svg';
import { ReactComponent as DefaultNewChatImage } from '../../../assets/images/drTalkNewChatPlaceholder.svg';
import { TEST_CONSTANTS } from '../../../constants/testConstants';
import { showSnackbarAction } from '../../../store/actions/snackbarAction';
import { chatStyle } from '../../../style/dashboardStyles/chatStyle';
import {
  ChatChannel,
  ChatMessage,
  ChatUser,
  MessageType,
} from '../../../types/chatTypes';
import ChatForwardingBox from './components/ChatForwardingBox';
import ChatForwardMessageDialog from './components/ChatForwardMessageDialog';
import ChatMessageBox from './components/ChatMessageBox';
import ChatMessageList from './components/ChatMessageList';
import ChatPanelHeader from './components/ChatPanelHeader';
import ChatSelectUserBox from './components/ChatSelectUserBox';
import FileNotSupportedDialog from './components/FileNotSupportedDialog';

const ChatPanel = ({
  chat,
  messages,
  loading,
  uploading,
  error,
  searchList,
  onSendMessage,
  onMessageTextChange,
  onSearchTextChange,
  onForwardMessages,
  onSelectUser,
  onDeleteUser,
  onDeleteMessage,
  onLoadMoreMessages,
}: {
  chat?: ChatChannel;
  messages?: ChatMessage[];
  loading?: boolean;
  uploading?: string;
  error?: string;
  searchList?: ChatUser[];
  onSendMessage?: (message: ChatMessage, file?: File) => void;
  onMessageTextChange?: (text: string) => void;
  onSearchTextChange?: (text: string, limit?: number) => void;
  onForwardMessages?: (users: ChatUser[], messages: ChatMessage[]) => void;
  onSelectUser?: (user: ChatUser) => void;
  onDeleteUser?: (user: ChatUser) => void;
  onDeleteMessage?: (message: ChatMessage, all?: boolean) => void;
  onLoadMoreMessages?: () => void;
}) => {
  //set up some flags to make the logic more readable
  const [hasChat, setHasChat] = useState<boolean>(false);
  const [newChat, setNewChat] = useState<boolean>(true);
  const [showNewMessageDefault, setShowNewMessageDefault] =
    useState<boolean>(false);
  const [canType, setCanType] = useState<boolean>(false);
  const [selectedFileNotSupported, setSelectedFileNotSupported] = useState<
    string | null
  >(null);

  // disable typing when user is deleted
  // to check if the member is deleted from drtalk
  const isIndividualChatMemberDeleted = useMemo(
    () =>
      !chat?.isGroupChat &&
      !!chat?.members?.filter((member) => member.isDeleted)?.length,
    [chat]
  );

  const [forwardingList, setForwardingList] = useState<
    ChatMessage[] | undefined
  >();
  const [forwardDialogOpen, setForwardDialogOpen] = useState<boolean>(false);
  const [filteredSearchList, setFilteredSearchList] = useState<
    ChatUser[] | undefined
  >();

  const handleSend = (text: string, file?: File) => {
    if (chat?.id && (!_.isEmpty(text) || file) && onSendMessage) {
      onSendMessage(
        {
          channelId: chat.id,
          messageText: text,
          user: chat.user,
          createdAt: moment().unix(),
          messageType: MessageType.text,
          ...(file && {
            messageType: MessageType.attachment,
            metaDictionary: {
              attachmentName: file.name,
            },
          }),
        } as ChatMessage,
        file
      );
    }
  };
  const handleForward = (users: ChatUser[]) => {
    if (onForwardMessages && !_.isEmpty(users) && !_.isEmpty(forwardingList)) {
      onForwardMessages(users, forwardingList!);
      setForwardDialogOpen(false);
      setForwardingList(undefined);
    }
  };

  const onRetrySendMessage = (message: ChatMessage, file?: File) => {
    if (onSendMessage) onSendMessage(message, file);
  };

  const handleInitiateForward = (message: ChatMessage) => {
    setForwardingList([message]);
  };
  const handleSelectMessage = (message: ChatMessage, selected?: boolean) => {
    if (!!forwardingList) {
      const removed = _.remove(forwardingList, (m) => m === message);
      //toggle if selected is undefined, or add if selected is true
      if (removed.length === 0 || selected) forwardingList.push(message);
      forwardingList.sort((a, b) => a.createdAt - b.createdAt);
      setForwardingList([...forwardingList]);
    }
  };

  const handleFowardSelectUsers = () => {
    setForwardDialogOpen(true);
    //get initial user list
    onSearchTextChange && onSearchTextChange('', 0);
  };

  const closeForwardMenu = () => {
    setForwardDialogOpen(false);
  };

  // drag and drop feature
  const onDrop = (acceptedFiles: File[], fileRejections: FileRejection[]) => {
    if (acceptedFiles?.length < 2) {
      if (acceptedFiles[0]?.size > 20971520)
        showSnackbarAction({
          message: 'file size exceeds maximum (20MB)',
          statusCode: 400,
          severity: 'error',
          open: true,
        });
      else {
        handleSend('', acceptedFiles[0]);
      }
      if (fileRejections?.length) {
        setSelectedFileNotSupported(fileRejections[0].file.name);
      }
    } else {
      showSnackbarAction({
        message: 'You can send one attachment at a time',
        statusCode: 400,
        severity: 'error',
        open: true,
      });
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    noClick: true,
    accept: {
      'image/*': ['.png', 'jpg', 'jpeg'],
      'application/pdf': ['pdf'],
      'application/msword': ['doc', 'docx'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        ['doc', 'docx'],
      'text/csv': ['csv'],
      'text/plain': ['txt'],
      'application/csv': ['csv1'],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
        'xlsx',
      ],
      'application/vnd.ms-excel': ['xls'],
    },
  });

  useEffect(() => {
    setHasChat(!!chat);
    setNewChat(!!chat?.new);
    setShowNewMessageDefault(!!chat?.new && _.isEmpty(messages));
    setCanType(
      (chat?.recipients?.length ?? 0) > 0 && !isIndividualChatMemberDeleted
    );
  }, [chat, messages, isIndividualChatMemberDeleted]);

  useEffect(() => {
    setForwardingList(undefined);
  }, [chat?.id]);

  useEffect(() => {
    //filter out recipients
    const recipientIds = chat?.recipients?.map((r) => r.userId) ?? [];
    setFilteredSearchList(
      searchList?.filter((u) => !recipientIds.includes(u.userId))
    );
  }, [chat?.recipients, searchList]);

  return (
    <Box sx={chatStyle.chatPanel} data-testid={TEST_CONSTANTS.CHAT_PANEL}>
      {hasChat ? (
        <Stack sx={chatStyle.chatPanel}>
          {newChat ? (
            <ChatSelectUserBox
              list={searchList}
              recipients={chat?.recipients}
              loading={loading}
              onChange={onSearchTextChange}
              onSelect={onSelectUser}
              onDelete={onDeleteUser}
            />
          ) : (
            <ChatPanelHeader chat={chat!} loading={loading} />
          )}
          <Divider />
          {showNewMessageDefault ? (
            <Box sx={chatStyle.chatPanelDefaultNewChatImage}>
              <DefaultNewChatImage
                data-testid={TEST_CONSTANTS.CHAT_PANEL_NEWCHAT_IMAGE}
              />
            </Box>
          ) : (
            <ChatMessageList
              chat={chat}
              list={messages}
              selectedList={forwardingList}
              onDeleteMessage={onDeleteMessage}
              onForwardMessage={handleInitiateForward}
              onSelectMessage={handleSelectMessage}
              onLoadMore={onLoadMoreMessages}
              uploading={uploading}
              getRootProps={getRootProps}
              getInputProps={getInputProps}
              isDragActive={isDragActive}
              onRetrySendMessage={onRetrySendMessage}
            />
          )}
          {forwardingList ? (
            <ChatForwardingBox
              onCancel={() => setForwardingList(undefined)}
              onForward={() => handleFowardSelectUsers()}
              selected={forwardingList?.length}
            />
          ) : (
            canType && <ChatMessageBox
              onChange={onMessageTextChange}
              onSend={handleSend}
              value={chat?.text}
              disabled={!canType}
            />
          )}
          <ChatForwardMessageDialog
            open={forwardDialogOpen}
            loading={loading}
            searchList={filteredSearchList}
            onSearchTextChange={onSearchTextChange}
            onClose={closeForwardMenu}
            onSend={handleForward}
          />
        </Stack>
      ) : (
        <Box sx={chatStyle.chatPanelDefaultImage}>
          <DefaultImage data-testid={TEST_CONSTANTS.CHAT_PANEL_DEFAULT_IMAGE} />
        </Box>
      )}
      {selectedFileNotSupported && (
        <FileNotSupportedDialog
          selectedFileNotSupported={selectedFileNotSupported}
          setSelectedFileNotSupported={setSelectedFileNotSupported}
        />
      )}
    </Box>
  );
};

export default ChatPanel;
