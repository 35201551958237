import type { AxiosResponse } from 'axios';

import { generalErrorHandler } from '../../helpers/generalErrorHandlerHelper';
import localStorageHelper from '../../helpers/localStorageHelper';
import authApiService from '../../services/apiServices/authApiService';
import { LoginResponse } from '../../types/apiResponses/UserApiResponses';
import { SetNewPasswordForm } from '../../types/authTypes';
import { ChatUser } from '../../types/chatTypes';
import { GeneralResponse } from '../../types/Misc';
import { isGeneralResponse } from '../../utilities/typeGuards';
import {
  AUTH_REDUX_CONSTANTS,
  ChangeAuthStatusAction,
  ChangePasswordError,
  ChangePasswordSuccess,
  ClearErrorMessage,
  LogoutUserAction,
  SetAuthToken,
} from '../reduxConstants/authReduxConstants';
import { setChatAuth } from '../slices/chatSlice';
import { AppDispatch } from '../store';
import {
  startGeneralLoaderOnRequest,
  stopGeneralLoaderOnSuccessOrFail,
} from './generalLoaderAction';
import { showSnackbarAction } from './snackbarAction';
import { setUserInfo } from './userActions';

export const loginAction = (
  { email, password }: Record<string, string>,
  cb: (
    token: string,
    givenName: string,
    practiceId: string,
    practiceInviteCode: string,
    isOnboardingProcessCompleted: boolean,
    isAutoGeneratedPassword: boolean,
    role: string | null,
    mobile: string | null,
    isUserPendingApproval: boolean,
    isOrganizationPendingApproval: boolean
  ) => void
) => {
  return async (dispatch: AppDispatch) => {
    try {
      startGeneralLoaderOnRequest('isLoginLoaderActive');
      const response: AxiosResponse<LoginResponse> =
        await authApiService.loginUser({ email, password });
      if (response?.status === 200) {
        dispatch<ChangeAuthStatusAction>({
          type: AUTH_REDUX_CONSTANTS.CHANGE_AUTH_STATUS_ACTION,
          payload: true,
        });
        const {
          token,
          refreshToken,
          refreshTokenExpiryTime,
          givenName,
          practiceId,
          practiceInviteCode,
          isOnboardingProcessCompleted,
          isAutoGeneratedPassword,
          displayName,
          role,
          mobile,
          isUserPendingApproval,
          isOrganizationPendingApproval,
        } = response.data;

        cb(
          token,
          givenName,
          practiceId,
          practiceInviteCode,
          isOnboardingProcessCompleted,
          isAutoGeneratedPassword,
          role,
          mobile,
          isUserPendingApproval,
          isOrganizationPendingApproval
        ); //callback function, after successful login
        storeUserInfo(dispatch, givenName, email, response.data);

        // Store both access and refresh tokens
        dispatch(setAuthToken(token));
        dispatch(
          setChatAuth({
            user: { userId: givenName, name: displayName } as ChatUser,
          })
        );
        localStorageHelper.saveAuthToken(token);
        localStorageHelper.saveRefreshToken(
          refreshToken,
          refreshTokenExpiryTime
        );

        showSnackbarAction({
          open: true,
          statusCode: 200,
          message: 'You have logged in successfully',
          severity: 'success',
        });
      }
    } catch (e: any) {
      showSnackbarAction({
        open: true,
        statusCode: e?.response?.status,
        message:
          e?.response?.status === 400
            ? 'Invalid username or password'
            : e?.ExceptionMessage ?? e?.Message,
        severity: 'error',
      });
    } finally {
      stopGeneralLoaderOnSuccessOrFail('isLoginLoaderActive');
    }
  };
};

function storeUserInfo(
  dispatch: AppDispatch,
  userId: string,
  email: string,
  loginResponse: LoginResponse
): void {
  const {
    isSmsNotificationTurnedOn,
    firstName,
    lastName,
    mobile,
    practiceId,
    practiceName,
    practiceType,
    profileImage,
    role,
    isUserPendingApproval,
  } = loginResponse;
  const userInfo = {
    email,
    isSmsNotificationTurnedOn,
    firstName,
    lastName,
    mobile,
    practiceId,
    practiceName,
    practiceType,
    profileImage,
    role,
    isUserPendingApproval,
  };

  dispatch(setUserInfo(userId, userInfo));
  localStorageHelper.saveUserInfo(userInfo);
}

export const setAuthToken = (token: string | null): SetAuthToken => ({
  type: AUTH_REDUX_CONSTANTS.SET_AUTH_TOKEN,
  payload: token,
});

export const resetReduxAction = (): LogoutUserAction => ({
  type: AUTH_REDUX_CONSTANTS.LOGOUT_USER_ACTION,
});

export const changePassword =
  (email: string, currentPassword: string, newPassword: string) =>
  async (dispatch: AppDispatch) => {
    try {
      startGeneralLoaderOnRequest('changePassword');
      const { data }: AxiosResponse<GeneralResponse> =
        await authApiService.changePassword(
          email,
          currentPassword,
          newPassword
        );

      if (isGeneralResponse(data)) {
        if (data.statusCode === 200) {
          dispatch<ChangePasswordSuccess>({
            type: AUTH_REDUX_CONSTANTS.CHANGE_PASSWORD_SUCCESS,
          });

          showSnackbarAction({
            open: true,
            statusCode: 200,
            message: 'Password changed successfully',
            severity: 'success',
          });
          return;
        }

        const isIncorrectPassword = data.errors[0]?.message?.includes(
          'Incorrect current password'
        );
        const errorMessage = {
          type: 'changePassword',
          message: isIncorrectPassword
            ? 'INCORRECT_PASSWORD'
            : 'CHANGE_PASSWORD_GENERAL_ERROR',
        } as const;
        dispatch<ChangePasswordError>({
          type: AUTH_REDUX_CONSTANTS.CHANGE_PASSWORD_ERROR,
          payload: errorMessage,
        });
        return;
      }
    } catch (e) {
      dispatch<ChangePasswordError>({
        type: AUTH_REDUX_CONSTANTS.CHANGE_PASSWORD_ERROR,
        payload: {
          type: 'changePassword',
          message: 'CHANGE_PASSWORD_GENERAL_ERROR',
        },
      });
    } finally {
      stopGeneralLoaderOnSuccessOrFail('changePassword');
    }
  };

export const clearErrorMessage = (): ClearErrorMessage => ({
  type: AUTH_REDUX_CONSTANTS.CLEAR_ERROR_MESSAGE,
});

export const verifyTokenAction = () => {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await authApiService.verifyToken(
        localStorageHelper.getUserInfo()?.email!
      );
      if (response?.status === 200) {
        dispatch({
          type: AUTH_REDUX_CONSTANTS.VERIFY_TOKEN,
          payload: response.data,
        });
      }
    } catch (e: any) {
      generalErrorHandler(e);
    }
  };
};

// set new password after receiving temp password in email
export const setNewPasswordAction = async (
  form: SetNewPasswordForm,
  callbacFunc: () => void
) => {
  try {
    startGeneralLoaderOnRequest('isSetNewPasswordLoaderActive');
    const response = await authApiService.setNewPassword(form);
    if (response?.status === 200) {
      showSnackbarAction({
        open: true,
        statusCode: 200,
        message: 'Password set successfully',
        severity: 'success',
      });
      callbacFunc();
    }
    return;
  } catch (e: any) {
    generalErrorHandler(e);
  } finally {
    stopGeneralLoaderOnSuccessOrFail('isSetNewPasswordLoaderActive');
  }
};

export const validateEmailAction = async (
  email: string,
  captchaToken: string
) => {
  try {
    startGeneralLoaderOnRequest('isEmailValidLoaderActive');
    const response = await authApiService.validateEmail(email, captchaToken);
    if (response?.status === 200) {
      return response.data;
    }
  } catch (e: any) {
    generalErrorHandler(e);
  } finally {
    stopGeneralLoaderOnSuccessOrFail('isEmailValidLoaderActive');
  }
};
