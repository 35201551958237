import _ from 'lodash';
import { AddOutlined, CloseOutlined, MoreVert } from '@mui/icons-material';
import FolderSharedOutlinedIcon from '@mui/icons-material/FolderSharedOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import {
  Avatar,
  Box,
  CircularProgress,
  DialogContent,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import FlatButton from '../../../../components/FlatButton';
import Highlighter from '../../../../components/Highlighter';
import { uppercaseInitials } from '../../../../helpers/getFirstTwoLetters';
import {
  getPracticeDirectoryAction,
  setNewRecipientFormDataAction,
  setSendDocumentDialogStepAction,
  setSendDocumentFormDataAction,
} from '../../../../store/actions/dashboardActions/documentActions';
import { initialDocumentReducer } from '../../../../store/reducers/dashboardReducers/documentsReducer';
import { sendDocumentsDialogsStyle } from '../../../../style/dashboardStyles/documents/sendDocumentsDialogsStyle';
import {
  DocumentReducer,
  PracticeDirectoryPractice,
  PracticeDirectoryRecipient,
  PracticeOptionUI,
  SortedPracticeDirectory,
} from '../../../../types/documentsTypes';
import AddEditRecipientDialog from './AddEditRecipientDialog';
import AddNewEmailsToRecipientsDialog from './AddNewEmailsToRecipientsDialog';
import SendDocumentsSelectRecipientsDropdown from './SendDocumentsSelectRecipientsDropdown';
import localStorageHelper from '../../../../helpers/localStorageHelper';
import DeleteRecipientProgressDialog from './DeleteRecipientProgressDialog';
import { useDeleteRecipientMutation } from 'services/recipientService';
import { showSnackbarAction } from 'store/actions/snackbarAction';
import { DOCUMENT_REDUX_CONSTANTS } from 'store/reduxConstants/documentsReduxConstants';
import { generalErrorHandler } from 'helpers/generalErrorHandlerHelper';

const SendDocumentsStepOne = () => {
  const dispatch = useAppDispatch();
  const [directorySearchText, setDirectorySearchText] = useState<string>('');
  const [
    isAddNewEmailsToDirectoryDialogOpen,
    setIsAddNewEmailsToDirectoryDialogOpen,
  ] = useState<boolean>(false);

  const [recipientMenuAnchorEl, setRecipientMenuAnchorEl] =
    useState<null | HTMLElement>(null);
  const [recipientMenuIndex, setRecipientMenuIndex] = useState<string>('');

  const { isGetPracticeDirectoryLoaderActive } = useAppSelector(
    ({ generalLoaderReducer }: Record<string, any>) =>
      generalLoaderReducer ?? true
  );

  const [deleteRecipientMutation, { isLoading: isDeletingRecipient }] =
    useDeleteRecipientMutation();

  const { sortedPracticeDirectory, sendDocumentFormData } = useAppSelector(
    (state) => state.documentReducer as DocumentReducer
  );

  const searchedDirectoryResults: SortedPracticeDirectory[] = useMemo(() => {
    let directory = sortedPracticeDirectory;

    if (!!directorySearchText?.trim().length) {
      directory = sortedPracticeDirectory.filter(
        (record: SortedPracticeDirectory) => {
          const doctorNames =
            'DoctorsNames' in record
              ? record?.DoctorsNames.join(',').toLowerCase()
              : '';
          return (
            ('Name' in record &&
              record?.Name.toLowerCase().includes(directorySearchText)) ||
            doctorNames.includes(directorySearchText) ||
            ('PracticeName' in record &&
              record.PracticeName.toLowerCase().includes(
                directorySearchText
              )) ||
            ('Email' in record &&
              record.Email.toLowerCase().includes(directorySearchText))
          );
        }
      );
    }

    const loggedUserPracticeId = localStorageHelper.getPracticeId();

    directory = directory.filter(
      (practice) => practice.id !== loggedUserPracticeId
    );

    // In case there are contacts with multiple Email, only keep 1 and filter the remaining in the list
    const dataWithEmail = _.filter(directory, 'Email');
    const uniqueDataWithEmail = _.uniqBy(dataWithEmail, 'Email');

    // Combine filtered contacts list with duplicated email and Practices
    const dataWithoutEmail = _.reject(directory, 'Email');
    let finalDirectoryList = _.concat(dataWithoutEmail, uniqueDataWithEmail);

    // Sort the combined list by Name (Practice Name or Contact Name) alphabetically
    finalDirectoryList = _.sortBy(
      finalDirectoryList,
      (obj) =>
        ('PracticeName' in obj && obj.PracticeName.toLowerCase()) ||
        ('Name' in obj && obj.Name.toLowerCase())
    );

    return finalDirectoryList;
  }, [sortedPracticeDirectory, directorySearchText]);

  const onDirectorySearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setDirectorySearchText(event.target.value.toLowerCase());
  };

  const onClickAdd = (
    record: PracticeDirectoryPractice | PracticeDirectoryRecipient,
    isConnectedPractice: boolean
  ) => {
    let name: string;

    if ('Name' in record) {
      name = record.Name ?? '';
    } else if ('PracticeName' in record) {
      name = record.PracticeName ?? '';
    } else {
      name = '';
    }

    let newRecipient: PracticeOptionUI = {
      id: record.id,
      name,
      isConnectedPractice: isConnectedPractice,
    };

    if (!isConnectedPractice && 'Email' in record) {
      newRecipient = {
        id: record.id,
        email: record.Email,
        name: record.PracticeName,
        type: 'recipient',
        isConnectedPractice: false,
      };
    }

    dispatch(
      setSendDocumentFormDataAction({
        ...sendDocumentFormData,
        recipients: [
          ...sendDocumentFormData.recipients,
          newRecipient,
        ] as PracticeOptionUI[],
      })
    );
  };

  const isRecordAdded = (id: string) =>
    sendDocumentFormData.recipients.some(
      (recipient: PracticeOptionUI) => recipient.id === id
    );

  const [isAddContactsDialogOpen, setIsAddContactsDialogOpen] = useState(false);
  const [targetRecipientToEdit, setTargetRecipientToEdit] = useState<
    PracticeDirectoryRecipient | undefined
  >(undefined);

  const hasNewEmailRecipients = sendDocumentFormData.recipients?.some(
    (recipient) => recipient.isNewEmailRecipient
  );

  useEffect(() => {
    dispatch(getPracticeDirectoryAction());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <DialogContent
        sx={sendDocumentsDialogsStyle.stepOneDialogContentContainer}
      >
        <Box sx={sendDocumentsDialogsStyle.recipientContainer}>
          <Box>
            <Typography variant='body1' marginTop={0.25}>
              Who do you want to send your document(s) to?
            </Typography>
            <Typography variant='body2' marginTop={0.25}>
              Use the recipient box to quickly add an email address or contact.
              You can also use the list below to add recipients from your
              directory
            </Typography>
          </Box>
          <SendDocumentsSelectRecipientsDropdown />
        </Box>
        <Box sx={sendDocumentsDialogsStyle.practiceDirectoryContainer}>
          <Box sx={sendDocumentsDialogsStyle.practiceDirectoryContainer.title}>
            <FolderSharedOutlinedIcon />
            BROWSE DIRECTORY
          </Box>
          <Box sx={sendDocumentsDialogsStyle.recipientDirectorySearchContainer}>
            <TextField
              value={directorySearchText}
              placeholder='Search practices'
              onChange={onDirectorySearchChange}
              sx={sendDocumentsDialogsStyle.practiceDirectorySearch}
              size='small'
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <SearchOutlinedIcon
                      fontSize='small'
                      sx={sendDocumentsDialogsStyle.practiceDirectorySearchIcon}
                    />
                  </InputAdornment>
                ),
                endAdornment: !!directorySearchText?.length && (
                  <InputAdornment position='end'>
                    <IconButton onClick={() => setDirectorySearchText('')}>
                      <CloseOutlined fontSize='small' />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <IconButton
              sx={
                sendDocumentsDialogsStyle.practiceDirectoryContainer.addButton
              }
              onClick={() => setIsAddContactsDialogOpen(true)}
            >
              <AddOutlined />
            </IconButton>
          </Box>
          {isGetPracticeDirectoryLoaderActive ? (
            <Box
              sx={
                sendDocumentsDialogsStyle.practiceDirectoryContainer
                  .loaderContainer
              }
            >
              <CircularProgress />
            </Box>
          ) : (
            <Box
              sx={
                sendDocumentsDialogsStyle.practiceDirectoryContainer
                  .practiceDirectory
              }
            >
              {!!searchedDirectoryResults?.length &&
                searchedDirectoryResults.map(
                  (record: SortedPracticeDirectory) => {
                    const isRecipientItem = 'PracticeName' in record;
                    const isRecipientItemMenuOpen =
                      !!recipientMenuAnchorEl &&
                      recipientMenuIndex === record.id;

                    const handleDismissRecipientMenu = () => {
                      setRecipientMenuAnchorEl(null);
                      setRecipientMenuIndex('');
                    };

                    const handleDeleteRecipient = async () => {
                      handleDismissRecipientMenu();

                      await deleteRecipientMutation({
                        recipientId: record.id,
                      })
                        .unwrap()
                        .then(() => {
                          showSnackbarAction({
                            open: true,
                            statusCode: 200,
                            message: 'Recipient has been deleted successfully',
                            severity: 'success',
                          });

                          // TODO: this should be handled in rtk query's onQueryStarted once document module has been refactored
                          dispatch({
                            type: DOCUMENT_REDUX_CONSTANTS.ON_DELETE_PRACTICE_RECIPIENT,
                            payload: record.id,
                          });
                        })
                        .catch((e) => generalErrorHandler(e));
                    };

                    return (
                      <Box
                        sx={
                          sendDocumentsDialogsStyle.practiceDirectoryContainer
                            .record
                        }
                        key={record.id}
                      >
                        <Box
                          sx={
                            sendDocumentsDialogsStyle.practiceDirectoryContainer
                              .recordContent
                          }
                        >
                          <Avatar sx={sendDocumentsDialogsStyle.avatar}>
                            {uppercaseInitials(
                              'Name' in record
                                ? record?.Name
                                : record?.PracticeName
                            )}
                          </Avatar>
                          <Box>
                            <Box fontWeight={600}>
                              <Highlighter
                                highlight={directorySearchText}
                                fontSize='body2'
                              >
                                {'Name' in record
                                  ? record?.Name
                                  : record?.PracticeName}
                              </Highlighter>
                            </Box>
                            <Typography variant='caption'>
                              <Highlighter
                                highlight={directorySearchText}
                                fontSize='caption'
                              >
                                {'DoctorsNames' in record
                                  ? record.DoctorsNames?.map(
                                      (doctorName: string) => doctorName
                                    ).join(', ')
                                  : record?.Email}
                              </Highlighter>
                            </Typography>
                          </Box>
                        </Box>
                        <Box
                          sx={
                            sendDocumentsDialogsStyle.practiceDirectoryContainer
                              .actionButtonsContainer
                          }
                        >
                          <FlatButton
                            size='small'
                            variant='contained'
                            disabled={isRecordAdded(record.id)}
                            onClick={() =>
                              onClickAdd(
                                record,
                                'Name' in record ? true : false
                              )
                            }
                          >
                            Add
                          </FlatButton>
                          <Box width='2rem' height='2rem'>
                            {isRecipientItem && (
                              <>
                                <IconButton
                                  id={`recipient-menu-icon-${record.id}`}
                                  aria-label='recipient-action'
                                  aria-controls={
                                    isRecipientItemMenuOpen
                                      ? `recipient-menu-${record.id}`
                                      : undefined
                                  }
                                  aria-expanded={
                                    isRecipientItemMenuOpen ? 'true' : undefined
                                  }
                                  aria-haspopup='true'
                                  onClick={(e) => {
                                    setRecipientMenuAnchorEl(e.currentTarget);
                                    setRecipientMenuIndex(record.id);
                                  }}
                                  sx={
                                    sendDocumentsDialogsStyle.recipientActionMenuBtn
                                  }
                                >
                                  <MoreVert />
                                </IconButton>
                                <Menu
                                  id={`recipient-menu-${record.id}`}
                                  MenuListProps={{
                                    'aria-labelledby': `action-menu-icon-${record.id}`,
                                  }}
                                  anchorEl={recipientMenuAnchorEl}
                                  open={isRecipientItemMenuOpen}
                                  onClose={handleDismissRecipientMenu}
                                >
                                  <MenuItem
                                    onClick={() => {
                                      dispatch(
                                        setNewRecipientFormDataAction({
                                          PracticeName: record.PracticeName,
                                          Email: record.Email,
                                        })
                                      );
                                      setTargetRecipientToEdit(record);
                                      setIsAddContactsDialogOpen(true);

                                      handleDismissRecipientMenu();
                                    }}
                                  >
                                    Edit
                                  </MenuItem>
                                  <MenuItem onClick={handleDeleteRecipient}>
                                    Delete
                                  </MenuItem>
                                </Menu>
                              </>
                            )}
                          </Box>
                        </Box>
                      </Box>
                    );
                  }
                )}
              <DeleteRecipientProgressDialog isOpen={isDeletingRecipient} />
            </Box>
          )}
        </Box>
        <Box sx={sendDocumentsDialogsStyle.submitButtonContainer}>
          <FlatButton
            variant='contained'
            disabled={!!!sendDocumentFormData.recipients.length}
            onClick={() => {
              if (hasNewEmailRecipients) {
                setIsAddNewEmailsToDirectoryDialogOpen(true);
              } else {
                dispatch(setSendDocumentDialogStepAction(2));
              }
            }}
          >
            Next: upload documents
          </FlatButton>
        </Box>
      </DialogContent>
      <AddEditRecipientDialog
        targetRecipient={targetRecipientToEdit}
        isOpen={isAddContactsDialogOpen}
        onClose={() => {
          setIsAddContactsDialogOpen(false);
          setTargetRecipientToEdit(undefined);
          dispatch(
            setNewRecipientFormDataAction(
              initialDocumentReducer.newRecipientFormData
            )
          );
        }}
      />
      <AddNewEmailsToRecipientsDialog
        isDialogOpen={isAddNewEmailsToDirectoryDialogOpen}
        setIsDialogOpen={setIsAddNewEmailsToDirectoryDialogOpen}
      />
    </>
  );
};

export default SendDocumentsStepOne;
