import { DraftsOutlined, Lock, MailOutline } from '@mui/icons-material';
import CheckIcon from '@mui/icons-material/Check';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Box,
  Chip,
  IconButton,
  Link,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import { isValidEmail } from 'helpers/emailValidationHelper';
import { MouseEvent, useEffect, useState } from 'react';
import Moment from 'react-moment';
import { useNavigate } from 'react-router-dom';
import { acceptPracticeInviteFromReceivedDocuments } from 'store/slices/myNetworkSlice';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import FlatButton from '../../../../components/FlatButton';
import { DOCUMENT_STATUS_DATABASE_VALUES } from '../../../../constants/documentsConstants';
import { TEST_CONSTANTS } from '../../../../constants/testConstants';
import localStorageHelper from '../../../../helpers/localStorageHelper';
import usePrevious from '../../../../hooks/usePrevious';
import {
  acceptPendingDocumentAction,
  declinePendingDocumentAction,
  processDocumentRecordAction,
  resendDocumentItemAction,
} from '../../../../store/actions/dashboardActions/documentActions';
import { newChannel } from '../../../../store/slices/chatSlice';
import { updateDocumentCount } from '../../../../store/slices/notificationSlice';
import { commonClasses } from '../../../../style/commonClasses';
import { documentStyle } from '../../../../style/dashboardStyles/documentStyle';
import {
  DocumentsTableDetailsType,
  TableDetailsAttachment,
} from '../../../../types/documentsTypes';
import DocumentsTableActionMenu from './DocumentsTableActionMenu';
import DocumentTableMessage from './DocumentTableMessage';
import DocumentTableThumbnailCarousel from './DocumentTableThumbnailCarousel';
import SendQuickMessageDialog from './SendQuickMessageDialog';

type DocumentTableRowProps = {
  setSelectedPreviewAttachments: React.Dispatch<
    React.SetStateAction<TableDetailsAttachment[]>
  >;
  setSelectedPreviewRecordIndex: React.Dispatch<
    React.SetStateAction<number | null>
  >;
  setSelectedPreviewRecordBlobType: React.Dispatch<
    React.SetStateAction<number | null>
  >;
};

const DocumentsTableRow = (props: DocumentTableRowProps) => {
  const {
    setSelectedPreviewAttachments,
    setSelectedPreviewRecordIndex,
    setSelectedPreviewRecordBlobType,
  } = props;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  // get token to check if user is logged in or not, if not, show guest user document list
  const token = localStorageHelper.getAuthToken();
  const [sendQuickMsgRecord, setSendQuickMsgRecord] = useState<
    HTMLAnchorElement | HTMLSpanElement | null
  >(null);
  const { selectedTab, paginatedList } = useAppSelector(
    ({ documentReducer }: Record<string, any>) => documentReducer ?? {}
  );
  const { id: loggedInUserId } = useAppSelector((state) => state.userReducer);
  const {
    isDocumentProcessLoaderActive,
    isAcceptPendingDocumentLoaderActive,
    isDeclinePendingDocumentLoaderActive,
  } = useAppSelector(
    ({ generalLoaderReducer }: Record<string, any>) =>
      generalLoaderReducer ?? true
  );
  const [actionMenuElement, setActionMenuElement] =
    useState<null | HTMLElement>(null);
  const [selectedRecordIndex, setSelectedRecordIndex] = useState<null | number>(
    null
  );
  const [processRecordIndex, setProcessRecordIndex] = useState<string | null>(
    null
  );
  const [acceptedDocumentIndex, setAcceptedDocumentIndex] = useState<
    string | null
  >(null);
  const [declinedDocumentIndex, setDeclinedDocumentIndex] = useState<
    string | null
  >(null);
  const [recordSelectedForQuickMessage, setRecordSelectedForQuickMessage] =
    useState<DocumentsTableDetailsType | null>(null);

  const isReceivedTabSelected = selectedTab === 0;
  const [documentForResend, setDocumentForResend] = useState('');

  const isActionMenuOpen = Boolean(actionMenuElement);
  const onClickPracticeName = (practiceId: string) =>
    navigate(`/dashboard/my-network/${practiceId}`);

  const handleActionMenuClick = (
    event: React.MouseEvent<HTMLElement>,
    index: number
  ) => {
    setSelectedRecordIndex(index);
    setActionMenuElement(event.currentTarget);
  };

  const onClickProcess = (id: string) => {
    setProcessRecordIndex(id);
    dispatch(processDocumentRecordAction(id)).then(() => {
      setProcessRecordIndex(null);
    });
  };

  const onClickAccept = (record: DocumentsTableDetailsType) => {
    setAcceptedDocumentIndex(record.id);
    dispatch(acceptPendingDocumentAction(record.id)).then(() => {
      setAcceptedDocumentIndex(null);
    });
    dispatch(acceptPracticeInviteFromReceivedDocuments(record.fromPracticeId));
  };

  const onClickDecline = (id: string) => {
    setDeclinedDocumentIndex(id);
    dispatch(declinePendingDocumentAction(id)).then(() => {
      setDeclinedDocumentIndex(null);
    });
  };

  const prevProcessRecordIndexValue = usePrevious(processRecordIndex);

  const onClickSendQuickChat = (
    event: React.MouseEvent<HTMLAnchorElement> | MouseEvent<HTMLSpanElement>,
    row: DocumentsTableDetailsType
  ) => {
    setRecordSelectedForQuickMessage(row);
    setSendQuickMsgRecord(event.currentTarget);

    dispatch(
      newChannel({
        recipients: [
          {
            userId: row.fromUserId,
            profileUrl: row?.fromUserProfileImage ?? '',
            name: row.fromUserDisplayName,
            lastSeenAt: 0,
            role: row.fromUserRole,
            practiceName: row.fromPracticeName,
            isDeleted: row.fromPracticeIsDeleted,
          },
        ],
      })
    );
  };

  useEffect(() => {
    if (recordSelectedForQuickMessage) {
      dispatch(newChannel({}));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recordSelectedForQuickMessage]);

  useEffect(() => {
    // catch the trailing edge when processRecordIndex is reset to null on process
    // document action and tell dashboard to update the document count
    if (prevProcessRecordIndexValue && !processRecordIndex)
      dispatch(updateDocumentCount());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [processRecordIndex]);

  return (
    <>
      {!!paginatedList?.length &&
        paginatedList?.map((row: DocumentsTableDetailsType, index: number) => {
          const isOutsideEmailOrNetwork =
            ((!row.approved && row.fromPracticeId != null) ||
              (row.guestReferralStatus === 0 &&
                row.guestSenderEmailAddress != null)) &&
            isReceivedTabSelected;

          const isWebsiteReferralTagVisible =
            !!row.guestSenderEmailAddress?.length && row.isReferral;

          // condition for checking pending document -
          // (1) flag approved - false
          // (2) selected tab should be 'Sent',
          // (3) toPracticeId should have value
          const isPendingSentDocument =
            !(
              row.approved ||
              isReceivedTabSelected ||
              !row.toPracticeId?.length
            ) && token;
          const pendingDocumentRecipientName = isReceivedTabSelected
            ? row.fromPracticeName
            : row.toPracticeName ||
              row.secureEmailReceiverName ||
              row.secureEmailAddress;
          const pendingDocumentRecipientAddress = isReceivedTabSelected
            ? row.fromPracticeName
            : row.toPracticeName || row.secureEmailAddress || '';

          const onClickShowPreview = (
            attachments?: TableDetailsAttachment[],
            attachmentIndex?: number
          ) => {
            !!attachments && setSelectedPreviewAttachments(attachments);
            attachmentIndex !== undefined &&
              setSelectedPreviewRecordIndex(attachmentIndex);
            setSelectedPreviewRecordBlobType(
              row.guestReferralStatus === 0 ? 2 : null
            );
          };

          return (
            <TableRow
              data-testid={`${TEST_CONSTANTS.DOCUMENT_TABLE_ROW}-${index}`}
              key={index}
              style={{
                background:
                  isOutsideEmailOrNetwork && token ? '#E8DDFF' : 'inherit',
              }}
            >
              <TableCell width='40%'>
                <Box sx={documentStyle.documentsTablePatientNameTagContainer}>
                  <Box sx={documentStyle.documentsTablePatientName}>
                    {row.patientTag}
                  </Box>

                  {isWebsiteReferralTagVisible ? (
                    <Box
                      sx={[
                        commonClasses.cardTag,
                        documentStyle.documentsTableDocumentTag,
                      ]}
                    >
                      Website Referral
                    </Box>
                  ) : (
                    <Box
                      sx={[
                        commonClasses.cardTag,
                        !row.isReferral
                          ? documentStyle.documentsTableDocumentTag
                          : {},
                      ]}
                    >
                      {row.isReferral ? 'Referral' : 'Doc'}
                    </Box>
                  )}
                  {isPendingSentDocument && (
                    <Chip
                      sx={
                        documentStyle.documentsTablePatientNameTagContainer
                          .pendingDocumentChip
                      }
                      label='PENDING'
                    />
                  )}
                </Box>
                <Typography
                  component='div'
                  variant='caption'
                  sx={documentStyle.documentTableSentReceiveData}
                >
                  <Moment
                    calendar={{
                      lastDay: '[Yesterday] LT',
                      sameDay: '[Today] LT',
                      lastWeek: 'MM/DD/YYYY hh:mm A',
                      sameElse: 'MM/DD/YYYY hh:mm A',
                    }}
                  >
                    {row.documentStatus === 'Processed'
                      ? row.processedDate
                      : row.recievedOn}
                  </Moment>
                </Typography>
                <Box
                  sx={documentStyle.documentTableFirstColumnDetails}
                  marginTop={1.5}
                >
                  <Box>Sender: </Box>
                  {/* quick send message link should not appear if logged in user and sender is same */}
                  {loggedInUserId === row?.fromUserId ||
                  !token ||
                  isValidEmail(row.fromUserDisplayName) ? (
                    row?.fromUserDisplayName
                  ) : (
                    <Link
                      data-testid={
                        TEST_CONSTANTS.DOCUMENTS_TABLE_SEND_QUICK_MSG_LINK
                      }
                      aria-label='send-quick-msg'
                      aria-controls={
                        !!recordSelectedForQuickMessage
                          ? `quick-message-${row.id}`
                          : undefined
                      }
                      aria-expanded={
                        !!recordSelectedForQuickMessage ? 'true' : undefined
                      }
                      aria-haspopup='true'
                      sx={documentStyle.documentsTableLink}
                      id={`quick-msg-action-${row.id}`}
                      onClick={(event) => onClickSendQuickChat(event, row)}
                    >
                      <Typography
                        component='span'
                        variant='subtitle2'
                        fontWeight={400}
                      >
                        {row?.fromUserDisplayName}
                      </Typography>
                    </Link>
                  )}
                </Box>
                <Box
                  sx={documentStyle.documentTableFirstColumnDetails}
                  marginTop={0.5}
                >
                  <Box>Practice: </Box>
                  {token && !isWebsiteReferralTagVisible ? (
                    <Link
                      data-testid={TEST_CONSTANTS.DOCUMENTS_TABLE_PRACTICE_LINK}
                      sx={documentStyle.documentsTableLink}
                      onClick={() =>
                        onClickPracticeName(
                          isReceivedTabSelected
                            ? row.fromPracticeId
                            : row.toPracticeId
                        )
                      }
                    >
                      {pendingDocumentRecipientName}
                    </Link>
                  ) : (
                    row.fromPracticeName
                  )}
                </Box>
                {!!!row.toPracticeId && !isReceivedTabSelected && (
                  <Box
                    sx={documentStyle.documentTableFirstColumnDetails}
                    marginTop={0.5}
                  >
                    <Box
                      sx={documentStyle.secureEmailSentMessage}
                      whiteSpace='nowrap'
                    >
                      <Lock fontSize='inherit' color='primary' /> Secure Email
                      Sent To:{' '}
                    </Box>
                    {token && !isWebsiteReferralTagVisible ? (
                      <Link
                        data-testid={
                          TEST_CONSTANTS.DOCUMENTS_TABLE_PRACTICE_LINK
                        }
                        sx={documentStyle.documentsTableLink}
                        onClick={() =>
                          onClickPracticeName(
                            isReceivedTabSelected
                              ? row.fromPracticeId
                              : row.toPracticeId
                          )
                        }
                      >
                        {pendingDocumentRecipientAddress}
                      </Link>
                    ) : (
                      row.fromPracticeName
                    )}
                  </Box>
                )}
                {!isReceivedTabSelected && !!!row.toPracticeId && (
                  <Box
                    sx={documentStyle.documentTableFirstColumnDetails}
                    marginTop={0.5}
                  >
                    <Box
                      sx={documentStyle.secureEmailSentMessage}
                      whiteSpace='nowrap'
                    >
                      {row.secureEmailOpened ? (
                        <>
                          <DraftsOutlined fontSize='inherit' color='primary' />
                          Email Opened
                        </>
                      ) : (
                        <>
                          <MailOutline fontSize='inherit' color='primary' />
                          Delivered
                        </>
                      )}
                    </Box>
                    <Link
                      data-testid={TEST_CONSTANTS.DOCUMENTS_TABLE_PRACTICE_LINK}
                      sx={documentStyle.documentsTableLink}
                      onClick={() => {
                        setDocumentForResend(row.id);
                        dispatch(resendDocumentItemAction(row)).then(() => {
                          setDocumentForResend('');
                        });
                      }}
                    >
                      {documentForResend === row.id ? 'Resending...' : 'Resend'}
                    </Link>
                  </Box>
                )}
              </TableCell>
              <TableCell width='50%'>
                <Box>
                  {token && isOutsideEmailOrNetwork ? (
                    <Typography
                      fontWeight={500}
                      color={'primary'}
                      letterSpacing={0}
                      lineHeight={1.2}
                    >
                      In order to view the information attached, you'll have to
                      accept the request. <br />
                      Declining will delete this document item.
                    </Typography>
                  ) : (
                    !!row?.notes && <DocumentTableMessage message={row.notes} />
                  )}
                  {!!row.attachments?.length && (
                    <DocumentTableThumbnailCarousel
                      attachments={row.attachments}
                      index={index}
                      onClickShowPreview={
                        isOutsideEmailOrNetwork && token
                          ? undefined
                          : onClickShowPreview
                      }
                      shouldSecurePreview={!!token && isOutsideEmailOrNetwork}
                      isDisableTooltip={isOutsideEmailOrNetwork}
                    />
                  )}
                </Box>
              </TableCell>
              {token && (
                <TableCell
                  width='10%'
                  valign='top'
                  sx={
                    isOutsideEmailOrNetwork
                      ? documentStyle.approveButtonsCell
                      : undefined
                  }
                >
                  {isOutsideEmailOrNetwork ? (
                    <Box>
                      <Box sx={documentStyle.approveButtonsWrapper} style={{}}>
                        <FlatButton
                          sx={documentStyle.processButton}
                          variant='contained'
                          isLoading={
                            isAcceptPendingDocumentLoaderActive &&
                            acceptedDocumentIndex === row.id
                          }
                          onClick={() => onClickAccept(row)}
                        >
                          Approve
                        </FlatButton>
                        <FlatButton
                          sx={documentStyle.processButton}
                          variant='outlined'
                          isLoading={
                            isDeclinePendingDocumentLoaderActive &&
                            declinedDocumentIndex === row.id
                          }
                          onClick={() => onClickDecline(row.id)}
                        >
                          Decline
                        </FlatButton>
                      </Box>
                    </Box>
                  ) : (
                    <Box sx={documentStyle.documentsTableAction}>
                      {isReceivedTabSelected &&
                        (row.documentStatus ===
                        DOCUMENT_STATUS_DATABASE_VALUES.NOT_PROCESSED ? (
                          <>
                            <FlatButton
                              data-testid={`${TEST_CONSTANTS.DOCUMENT_TABLE_PROCESS_BUTTON}-${index}`}
                              variant='outlined'
                              sx={documentStyle.processButton}
                              startIcon={
                                processRecordIndex !== row.id && (
                                  <DriveFolderUploadIcon />
                                )
                              }
                              onClick={() => onClickProcess(row.id)}
                              isLoading={
                                isDocumentProcessLoaderActive &&
                                processRecordIndex === row.id
                              }
                              customLoadingElement={'Processing...'}
                            >
                              Process
                            </FlatButton>
                          </>
                        ) : (
                          <Box sx={commonClasses.successTag}>
                            <CheckIcon /> Processed
                          </Box>
                        ))}
                      <IconButton
                        data-testid={`${TEST_CONSTANTS.DOCUMENT_TABLE_ACTION_ICON_BUTTON}-${index}`}
                        aria-label='action'
                        id={`action-menu-icon-${index}`}
                        aria-controls={
                          isActionMenuOpen ? `action-menu-${index}` : undefined
                        }
                        aria-expanded={isActionMenuOpen ? 'true' : undefined}
                        aria-haspopup='true'
                        onClick={(event) => handleActionMenuClick(event, index)}
                      >
                        <MoreVertIcon />
                      </IconButton>
                      <DocumentsTableActionMenu
                        index={index}
                        selectedRecordIndex={selectedRecordIndex}
                        setSelectedRecordIndex={setSelectedRecordIndex}
                        actionMenuElement={actionMenuElement}
                        setActionMenuElement={setActionMenuElement}
                        isActionMenuOpen={isActionMenuOpen}
                        selectedRecord={row}
                      />
                    </Box>
                  )}
                </TableCell>
              )}
            </TableRow>
          );
        })}
      {recordSelectedForQuickMessage && (
        <SendQuickMessageDialog
          recordSelectedForQuickMessage={recordSelectedForQuickMessage}
          setRecordSelectedForQuickMessage={setRecordSelectedForQuickMessage}
          anchorElement={sendQuickMsgRecord}
        />
      )}
    </>
  );
};

export default DocumentsTableRow;
