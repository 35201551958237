import {
  Box,
  CircularProgress,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { TEST_CONSTANTS } from '../../../../constants/testConstants';
import { chatStyle } from '../../../../style/dashboardStyles/chatStyle';
import { ChatChannel } from '../../../../types/chatTypes';
import ChatAvatar from './ChatAvatar';

const ChatPanelHeader = ({
  chat,
  loading,
}: {
  chat: ChatChannel;
  loading?: boolean;
}) => {
  const [recipientDescription, setRecipientDescription] = useState<string>();

  useEffect(() => {
    let description;
    if (chat?.isGroupChat) {
      description = (chat?.members ?? []).map((m) => m.name).join(', ') ?? '';
    } else {
      const recipient = chat?.recipients?.[0];
      if (recipient) {
        description =
          ((recipient.role && recipient.role) ?? '') +
          ((recipient.role && recipient.practiceName && ` at `) ?? '') +
          ((recipient.practiceName && recipient.practiceName) ?? '');
      }
    }
    setRecipientDescription(description);
  }, [chat]);

  return (
    <ListItem
      sx={chatStyle.chatPanelHeader}
      data-testid={TEST_CONSTANTS.CHAT_PANEL_HEADER}
    >
      <ListItemAvatar>
        <ChatAvatar chat={chat} />
      </ListItemAvatar>
      <ListItemText
        disableTypography
        sx={chatStyle.chatListItemText}
        primary={
          <Typography variant='body2' fontWeight={'600'}>
            {chat?.name ?? ''}
          </Typography>
        }
        secondary={
          <Typography variant='caption' noWrap>
            {recipientDescription}
          </Typography>
        }
      />
      <ListItemSecondaryAction sx={chatStyle.chatListItemSecondary}>
        <Box
          paddingRight={'1rem'}
          sx={chatStyle.chatListItemSecondaryContainer}
        >
          {loading && <CircularProgress size='1.5rem' />}
        </Box>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export default ChatPanelHeader;
