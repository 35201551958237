import { TextField } from '@mui/material';
import { ChangeEvent, useMemo, useState } from 'react';
import { useAppDispatch } from '../../../../app/hooks';
import {
  onChangeRecipientDataAction,
  setNewRecipientFormDataAction,
} from '../../../../store/actions/dashboardActions/documentActions';
import { addContactsDialogStyle } from '../../../../style/dashboardStyles/addContactsStyle';
import {
  AddContactDialogFormProps,
  AddContactFormData,
} from '../../../../types/documentsTypes';
import { addContactsValidation } from './helpers/addContactsValidation';

const AddContactForm = ({
  isFormSubmittedOnce,
  formData,
  isNewContactAddition = false,
}: AddContactDialogFormProps) => {
  const dispatch = useAppDispatch();
  const [contactForm, setContactForm] = useState<AddContactFormData>({
    PracticeName: formData?.name ?? '',
    Email: formData?.email ?? '',
  });

  const formValidationErrors = useMemo(
    () => addContactsValidation(contactForm, isNewContactAddition),
    [contactForm, isNewContactAddition]
  );

  const onChangeValue = (
    event: ChangeEvent<HTMLInputElement>,
    name: string
  ) => {
    const updatedFormValue = { ...contactForm, [name]: event.target.value };

    setContactForm(updatedFormValue);

    if (isNewContactAddition) {
      dispatch(setNewRecipientFormDataAction(updatedFormValue));
    } else {
      dispatch(onChangeRecipientDataAction(updatedFormValue));
    }
  };

  return (
    <>
      <TextField
        required
        placeholder='Type business name here'
        label='Business name'
        sx={
          isFormSubmittedOnce && formValidationErrors?.PracticeName
            ? {
                ...addContactsDialogStyle.editableTextField,
                marginBottom: '1rem',
              }
            : addContactsDialogStyle.editableTextField
        }
        value={contactForm.PracticeName}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          onChangeValue(e, 'PracticeName')
        }
        helperText={isFormSubmittedOnce && formValidationErrors?.PracticeName}
        InputLabelProps={{
          shrink: true,
        }}
      />

      <TextField
        required
        placeholder='Type business email here'
        InputProps={{ readOnly: !isNewContactAddition }}
        InputLabelProps={{
          shrink: true,
        }}
        sx={
          isNewContactAddition
            ? addContactsDialogStyle.editableTextField
            : isFormSubmittedOnce && formValidationErrors?.Email
            ? {
                marginBottom: '1rem',
              }
            : undefined
        }
        label='Business email'
        value={contactForm.Email}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          isNewContactAddition && onChangeValue(e, 'Email')
        }
        helperText={isFormSubmittedOnce && formValidationErrors?.Email}
      />
    </>
  );
};

export default AddContactForm;
