import { Link, Paper, Typography } from '@mui/material';
import { TEST_CONSTANTS } from '../../../constants/testConstants';
import { onboardingStyle } from '../../../style/onboardingStyle';

type manualAddressPropTypes = {
  setIsAddressModalOpen: (value: boolean) => void;
  paperComponentProps: React.HTMLAttributes<HTMLElement>;
};

const SearchAddressDropdown = (props: manualAddressPropTypes) => {
  const { paperComponentProps, setIsAddressModalOpen } = props;
  const { children, ...other } = paperComponentProps;

  const onClickAddressManuallyLink = () => {
    setIsAddressModalOpen(true);
  };

  return (
    <Paper sx={onboardingStyle.addressLookupDropdown} {...other}>
      {children}
      <Typography
        component='div'
        sx={onboardingStyle.manualAddressContentContainer}
      >
        <Typography component='div' sx={onboardingStyle.orTextContainer}>
          <span>or</span>
        </Typography>
        <Link
          data-testid={
            TEST_CONSTANTS.ON_BOARDING_FORM_ENTER_ADDRESS_MANUALLY_LINK
          }
          underline='hover'
          onMouseDown={(event) => {
            event.preventDefault();
          }}
          sx={onboardingStyle.enterAddressManuallyLink}
          onClick={onClickAddressManuallyLink}
        >
          Enter address manually
        </Link>
      </Typography>
    </Paper>
  );
};

export default SearchAddressDropdown;
