import { Box, Typography } from '@mui/material';
import { useAppDispatch } from 'app/hooks';
import { RECAPTCHA_SITE_KEY } from 'constants/referralConstants';
import { ROUTE_CONSTANTS_VARIABLE } from 'constants/routeConstants';
import { REFERRAL_PAGE_TEST_CONSTANTS } from 'constants/testConstants';
import { useEffect, useMemo } from 'react';
import {
  GoogleReCaptchaProvider
} from 'react-google-recaptcha-v3';
import { useLocation, useParams } from 'react-router-dom';
import {
  resetReferralState
} from 'store/slices/referralSlice';
import { referralPageStyle } from 'style/dashboardStyles/referralStyle';
import { LoaderOverlay } from '../../../components/LoaderOverlay';
import {
  getPracticeByToken,
  useGetPracticeByTokenQuery
} from '../../../services/practiceService';
import DocumentSelectionStep from './components/DocumentSelectionStep/DocumentSelectionStep';
import PatientDetailsStep from './components/PatientDetailsStep/PatientDetailsStep';
import GuestPracticeSelectionStep from './components/PracticeSelectionStep/GuestPracticeSelectionStep';
import PracticeSelectionStep from './components/PracticeSelectionStep/PracticeSelectionStep';

const ReferralPage = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { token: guestToken } = useParams();
  const isReferAPatientPage = location.pathname.includes(
    ROUTE_CONSTANTS_VARIABLE.REFER_A_PATIENT
  );

  const { isFetching: isFetchingPracticeDataByToken } =
    useGetPracticeByTokenQuery(guestToken);

  useEffect(() => {
    if (guestToken) {
      dispatch(getPracticeByToken.initiate(guestToken));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [guestToken]);
  const isWebsiteReferral = useMemo(() => guestToken, [guestToken]);

  /**
   * NOTE: since this component is being used by 2 pages,
   *
   * We need to pass a 'key' prop which is unique to each page,
   * to re-mount the component each time the route changes
   */
  const componentKey = isReferAPatientPage
    ? 'refer-a-patient'
    : 'document-referral';

  /**
   * Will reset referral redux state in 2 conditions
   *
   * 1. when navigated from 'Document - referral' page to 'Refer a Patient' page.
   * 2. when component unmounts
   *
   * Since both pages uses the same ui component, needs to re-initialize the
   * redux state for better user experience
   */
  useEffect(() => {
    dispatch(resetReferralState());

    return () => {
      dispatch(resetReferralState());
    };
  }, [dispatch, isReferAPatientPage]);

  const renderReferralSteps = () => {
    return (
      <Box
        sx={[
          referralPageStyle.steps.container,
          { width: isWebsiteReferral ? 1 : 'inherit' },
        ]}
      >
        {/* Step One - practice information input */}
        {isWebsiteReferral ? (
          <>
            {isFetchingPracticeDataByToken && <LoaderOverlay />}
            <GuestPracticeSelectionStep />
          </>
        ) : (
          <PracticeSelectionStep />
        )}

        {/* Step Two - referral document selection*/}
        <DocumentSelectionStep />

        {/* Step Three - patient information input */}
        <PatientDetailsStep />
      </Box>
    );
  };

  return (
    <Box
      data-testid={REFERRAL_PAGE_TEST_CONSTANTS.REFERRAL_PAGE_ROOT_CONTAINER}
      sx={referralPageStyle.root.container}
      key={componentKey}
    >
      <Box sx={referralPageStyle.header.container}>
        <Typography variant='h5' sx={referralPageStyle.header.title}>
          Refer a patient
        </Typography>
        {!isWebsiteReferral && (
          <Typography variant='body2'>
            Refer a patient to a practice within your network
          </Typography>
        )}
      </Box>
      {/* If website referral, will generate a captcha token to be used on api request */}
      {isWebsiteReferral ? (
        <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_SITE_KEY ?? ''}>
          {renderReferralSteps()}
        </GoogleReCaptchaProvider>
      ) : (
        renderReferralSteps()
      )}
    </Box>
  );
};

export default ReferralPage;
