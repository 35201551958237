/* eslint-disable react-hooks/exhaustive-deps */
import { Box, CircularProgress } from '@mui/material';
import {
  FormDesigner,
  FormFields,
  Inject,
  Magnification,
  Navigation,
  PdfViewerComponent,
  TextSearch,
  TextSelection,
  Toolbar,
} from '@syncfusion/ej2-react-pdfviewer';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import FlatButton from 'components/FlatButton';
import {
  PDF_VIEWER_SERVICE_URL,
  REFERRAL_STEPS,
} from 'constants/referralConstants';
import { REFERRAL_PAGE_TEST_CONSTANTS } from 'constants/testConstants';
import { generalErrorHandler } from 'helpers/generalErrorHandlerHelper';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useParams } from 'react-router-dom';
import {
  useDownloadDocumentQuery,
  useGuestDownloadDocumentQuery,
  useGuestUploadDocumentMutation,
  useUploadDocumentMutation,
} from 'services/fileManagerService';
import {
  goToNextStep,
  setLoadedReferralFormFileName,
} from 'store/slices/referralSlice';
import { commonReferralStyle } from 'style/dashboardStyles/referralStyle';
import ReferralStepItem from '../ReferralStepItem/ReferralStepItem';

const DocumentSelectionStep: React.FC = () => {
  const dispatch = useAppDispatch();
  const { token: guestToken } = useParams();
  const isWebsiteReferral: boolean = useMemo(() => !!guestToken, [guestToken]);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const {
    selectedPractice,
    currentStep,
    practiceByToken,
    guestRecaptchaToken,
  } = useAppSelector((state) => state.referralReducer);

  const pdfViewerComponentRef = useRef<PdfViewerComponent>(null);
  const [isShowNextBtn, setIsShowNextBtn] = useState<boolean>(false);
  const [isTriggeredUpload, setIsTriggeredUpload] = useState<boolean>(false);

  const customReferralFormUrl = useMemo(
    () =>
      guestToken
        ? practiceByToken.customReferralFormUrl
        : selectedPractice?.CustomReferralFormUrl,
    [guestToken, practiceByToken, selectedPractice]
  );

  const customReferralFileName = useMemo(
    () =>
      customReferralFormUrl?.substring(
        customReferralFormUrl?.lastIndexOf('/') + 1
      ),
    [customReferralFormUrl]
  );

  const [uploadDocumentMutation] = useUploadDocumentMutation();
  const [guestUploadDocumentMutation] = useGuestUploadDocumentMutation();
  const { data: selectedPracticeDocument } = useDownloadDocumentQuery(
    {
      fileName: customReferralFileName ?? '',
    },
    {
      skip: (!customReferralFormUrl && currentStep !== 2) || isWebsiteReferral,
    }
  );

  const { data: selectedPracticeDocumentForGuestUser } =
    useGuestDownloadDocumentQuery(
      {
        fileName: customReferralFileName ?? '',
        captchaToken: guestRecaptchaToken,
      },
      {
        skip: !customReferralFormUrl || currentStep !== 2 || !isWebsiteReferral,
      }
    );

  const selectedDocument = useMemo(
    () =>
      isWebsiteReferral
        ? selectedPracticeDocumentForGuestUser
        : selectedPracticeDocument,
    [selectedPracticeDocument, selectedPracticeDocumentForGuestUser]
  );
  /**
   * Triggers upload process, will hide the PDF viewer first,
   * save the updated document state as a Blob,
   * and use the blob to upload the updated document to the file storage.
   */
  const handleClickNextBtn = useCallback(async () => {
    if (isWebsiteReferral) {
      if (!executeRecaptcha) {
        return;
      } else {
        const formActionName = () => {
          let string = `upload_document_practiceReferralDocument_${
            practiceByToken.id
          }_${Math.random()}.pdf`;
          return string.replace(/[^A-Za-z/]/g, '');
        };

        const token = await executeRecaptcha(formActionName());

        setIsTriggeredUpload(true);
        togglePDFViewer(false);
        const buffer = await pdfViewerComponentRef.current?.saveAsBlob();
        const formData = new FormData();

        const fileName = `PracticeReferralDocument_${
          practiceByToken.id
        }_${Math.random()}.pdf`;

        const updatedFile = new File([buffer!], fileName, {
          type: 'application/pdf',
        });

        formData.append('file', updatedFile);
        formData.append('fileName', fileName);

        await guestUploadDocumentMutation({
          formData,
          captchaToken: token,
        })
          .unwrap()
          .then((response) => {
            dispatch(setLoadedReferralFormFileName(response.url));
            dispatch(goToNextStep());
          })
          .catch((e) => {
            setIsTriggeredUpload(false);
            generalErrorHandler(e);
          });
      }
    } else {
      setIsTriggeredUpload(true);
      togglePDFViewer(false);
      const buffer = await pdfViewerComponentRef.current?.saveAsBlob();
      const formData = new FormData();

      const fileName = `PracticeReferralDocument_${
        selectedPractice?.id
      }_${Math.random()}.pdf`;

      const updatedFile = new File([buffer!], fileName, {
        type: 'application/pdf',
      });

      formData.append('file', updatedFile);
      formData.append('fileName', fileName);

      await uploadDocumentMutation({
        formData,
      })
        .unwrap()
        .then(() => {
          dispatch(setLoadedReferralFormFileName(fileName));
          dispatch(goToNextStep());
        })
        .catch((e) => {
          setIsTriggeredUpload(false);
          generalErrorHandler(e);
        });
    }
  }, [executeRecaptcha]);

  /**
   * Toggles visibility of the PDF viewer by
   * setting the minimum height of its container.
   */
  const togglePDFViewer = (isShow: boolean): void => {
    const minHeight = isShow ? '1075px' : '0px';

    if (document.getElementById('container_mainContainer')) {
      document.getElementById('container_mainContainer')!.style.minHeight =
        minHeight;
    }

    if (document.getElementById('container')) {
      document.getElementById('container')!.style.minHeight = minHeight;
    }
  };

  /**
   * If selected practice has a custom referral form,
   * will converts the form into a base64 string, and load it into the PDF viewer
   * (will hide the viewer's toolbar and sidebar to cater design requirements)
   */
  useEffect(() => {
    if (!!selectedDocument) {
      const base64 = btoa(
        new Uint8Array(selectedDocument).reduce(
          (data, byte) => data + String.fromCharCode(byte),
          ''
        )
      );

      pdfViewerComponentRef.current?.load(
        'data:application/pdf;base64,' + base64,
        ''
      );

      if (document.getElementById('container_toolbarContainer')) {
        document.getElementById('container_toolbarContainer')!.style.display =
          'none';
      }

      if (document.getElementById('container_sideBarToolbar')) {
        document.getElementById('container_sideBarToolbar')!.style.display =
          'none';
      }
    }
  }, [selectedDocument]);

  /**
   * Each time Step 2 has been opened,
   * will show the PDF viewer and
   * resets 'isTriggeredUpload' to false if it's true.
   */
  useEffect(() => {
    if (currentStep === REFERRAL_STEPS.REFERRAL_DOCUMENT) {
      togglePDFViewer(true);

      if (isTriggeredUpload) {
        setIsTriggeredUpload(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep]);

  return (
    <ReferralStepItem
      stepNumber={REFERRAL_STEPS.REFERRAL_DOCUMENT}
      title='Practice referral document'
    >
      <Box
        data-testid={REFERRAL_PAGE_TEST_CONSTANTS.REFERRAL_DOCUMENT_CONTAINER}
      >
        <PdfViewerComponent
          documentLoad={() => {
            setIsShowNextBtn(true);
          }}
          ref={pdfViewerComponentRef}
          id='container'
          serviceUrl={PDF_VIEWER_SERVICE_URL}
        >
          <Inject
            services={[
              Toolbar,
              Magnification,
              Navigation,
              TextSelection,
              TextSearch,
              FormFields,
              FormDesigner,
            ]}
          />
        </PdfViewerComponent>
        {isTriggeredUpload ? (
          <Box
            p='0.5rem'
            display='flex'
            alignItems='center'
            justifyContent='center'
            gap='1em'
          >
            <CircularProgress size='2rem' />
            <Box>Uploading your document to server, please wait...</Box>
          </Box>
        ) : (
          isShowNextBtn && (
            <Box>
              <FlatButton
                sx={commonReferralStyle.nextButton}
                onClick={handleClickNextBtn}
                hasShadow
              >
                Next
              </FlatButton>
            </Box>
          )
        )}
      </Box>
    </ReferralStepItem>
  );
};

export default DocumentSelectionStep;
