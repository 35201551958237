import {
  DocumentsTableDetailsType,
  DocumentTableFilters,
  ReceivedDocumentStatusFilter,
} from '../../types/documentsTypes';
import localStorageHelper from '../localStorageHelper';
import _ from 'lodash';
import {
  DOCUMENT_STATUS_DATABASE_VALUES,
  DOCUMENT_STATUS_DROPDOWN_VALUES,
  DOCUMENT_STATUS_FILTER,
  DOCUMENT_TYPE,
} from '../../constants/documentsConstants';

export const documentApplyFilter = (
  selectedTab = 0 | 1,
  updatedFilters: DocumentTableFilters,
  allDocumentList: DocumentsTableDetailsType[],
  receivedDocumentStatus: ReceivedDocumentStatusFilter
) => {
  // filter method
  type FilterMethod =
    | ((record: DocumentsTableDetailsType) => boolean)
    | undefined;

  let filters: Record<string, FilterMethod> = {
    patient: updatedFilters?.patient
      ? (record: DocumentsTableDetailsType) =>
          record.patientTag === updatedFilters.patient
      : undefined,
    sender: updatedFilters?.sender
      ? (record: DocumentsTableDetailsType) =>
          record.fromUserDisplayName === updatedFilters.sender
      : undefined,
    practice: updatedFilters?.practice
      ? (record: DocumentsTableDetailsType) =>
          selectedTab === 0
            ? record.fromPracticeId === updatedFilters.practice?.value
            : record.toPracticeId === updatedFilters.practice?.value
      : undefined,
    documentType: updatedFilters?.documentType
      ? (record: DocumentsTableDetailsType) =>
          (record?.isReferral &&
            !!!record?.guestSenderEmailAddress?.length &&
            updatedFilters.documentType === DOCUMENT_TYPE.REFERRAL) ||
          (!record?.isReferral &&
            updatedFilters.documentType === DOCUMENT_TYPE.DOCUMENT) ||
          (record?.isReferral &&
            !!record?.guestSenderEmailAddress?.length &&
            updatedFilters.documentType === DOCUMENT_TYPE.WEBSITE_REFERRAL)
      : undefined,
  };

  if (
    selectedTab === 0 &&
    receivedDocumentStatus !== DOCUMENT_STATUS_FILTER.ALL
  ) {
    filters = {
      ...filters,
      documentStatus: receivedDocumentStatus
        ? (record: DocumentsTableDetailsType) =>
            (record.documentStatus ===
              DOCUMENT_STATUS_DATABASE_VALUES.NOT_PROCESSED &&
              receivedDocumentStatus ===
                DOCUMENT_STATUS_DROPDOWN_VALUES.UNPROCESSED) ||
            record.documentStatus === receivedDocumentStatus
        : undefined,
    };
  }

  const selectedFilters: FilterMethod[] = [];
  for (const key in filters) {
    if (filters[key as keyof DocumentTableFilters] !== undefined) {
      selectedFilters.push(filters[key]);
    }
  }
  const updatedFilteredAllDocList = allDocumentList?.length
    ? allDocumentList.filter((record: DocumentsTableDetailsType) =>
        selectedFilters.every((filter: any) => filter(record))
      )
    : [];

  let updatedDocumentList = allDocumentList?.length
    ? allDocumentList
        .filter((record: DocumentsTableDetailsType) =>
          selectedFilters.every((filter: any) => filter(record))
        )
        .filter((record: DocumentsTableDetailsType) =>
          selectedTab === 0
            ? record.toPracticeId === localStorageHelper.getPracticeId()
            : record.toPracticeId !== localStorageHelper.getPracticeId()
        )
    : [];
  updatedDocumentList = _.orderBy(
    updatedDocumentList,
    (record: DocumentsTableDetailsType) => {
      return new Date(record.recievedOn).getTime();
    },
    ['desc']
  );

  return { updatedFilteredAllDocList, updatedDocumentList };
};
