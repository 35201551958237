import { Box, Typography } from '@mui/material';
import { useAppSelector } from '../app/hooks';
import { useServiceWorker } from '../hooks/useServiceWorker';
import { AuthReducerState } from '../store/reduxConstants/authReduxConstants';
import { RootState } from '../store/store';
import { commonClasses } from '../style/commonClasses';
import FlatButton from './FlatButton';

const UpdateAppBanner = () => {
  const { promptUserToRefresh } = useServiceWorker();
  const { isAppUpdateAvailable } = useAppSelector(
    (state: RootState) => state.authReducer as AuthReducerState
  );
  const handleUpdateClick = () => {
    promptUserToRefresh();
  };
  return (
    <Box
      sx={[
        commonClasses.updateAppBanner,
        {
          height: isAppUpdateAvailable ? '2.75rem' : '0rem',
          opacity: isAppUpdateAvailable ? 1 : 0,
          transition: 'height 0.3s ease-out, opacity 0.5s ease-out',
        },
      ]}
    >
      <Typography>
        <b>Update required: </b> The latest version of drtalk required,
      </Typography>
      <FlatButton variant='outlined' onClick={handleUpdateClick}>
        click here to update
      </FlatButton>
    </Box>
  );
};

export default UpdateAppBanner;
