import { createSlice, isAnyOf, SerializedError } from '@reduxjs/toolkit';
import _ from 'lodash';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { indexedDbFailedMessagesChannelsHelper } from '../../helpers/indexedDbFailedMessagesHelper';
import chatService, {
  CHANNELS_PAGE_SIZE,
  MarkChannel,
} from '../../services/chatService';
import {
  ChatChannel,
  ChatMessage,
  ChatMessageAttachmentFile,
  ChatUser,
  convertReceipts,
  getAttachmentFromMessage,
  MarkedReceipts,
} from '../../types/chatTypes';
import { SyncUserInfo } from 'types/apiResponses/UserApiResponses';

export type chatStateType = {
  auth: { user?: ChatUser; token?: string | null };
  channelList: {
    items?: ChatChannel[];
    continuationToken?: string;
    loaded?: boolean;
    error?: SerializedError;
    selected?: ChatChannel;
    created?: ChatChannel;
    page?: number;
    loading?: boolean;
    lookup: Record<string, { index: number; unread: number }>;
    recipientLookup: Record<string, { index: number }>;
  };
  searchList: {
    searching?: boolean;
    items?: ChatChannel[];
    searchTerm?: string;
  };
  messageList: {
    messages: Record<
      string,
      {
        items: ChatMessage[];
        continuationToken?: string;
        selected?: ChatMessage;
        loaded?: boolean;
        page?: number;
        updated?: number;
        lookup?: Record<string, { index: number }>;
      }
    >;
    error?: SerializedError;
  };
  fileList: Record<string, ChatMessageAttachmentFile>;
  failedMessageList: ChatMessage[] | undefined;
};

const initialChatState: chatStateType = {
  auth: {},
  channelList: {
    page: 0,
    lookup: {},
    recipientLookup: {},
  },
  searchList: {},
  //messageList.messages is a map with key of channelId
  messageList: {
    messages: {},
  },
  fileList: {},
  failedMessageList: [],
};

// use createSlice instead of boilerplate constants
// also allows mutating of state directly

const usersToIndex = (users?: ChatUser[], sort: boolean = true) => {
  let ids = users?.map((r) => r.userId);
  if (sort) ids?.sort();
  return ids?.join('--');
};

const buildChannelLookups = (state: chatStateType) => {
  //build lookup tables
  state.channelList.lookup = {};
  state.channelList.recipientLookup = {};
  state.channelList.items?.forEach((c, i) => {
    state.channelList.lookup[c.id] = {
      index: i,
      unread: c.unreadMessageCount ?? 0,
    };
    const li = usersToIndex(c.recipients);
    if (!_.isEmpty(li) && !c.isGroupChat)
      state.channelList.recipientLookup[li!] = { index: i };
  });
};

//merge and sort
const addChannels = (
  state: chatStateType,
  items: ChatChannel[],
  newItems?: ChatChannel[]
) => {
  //merge on id and sort according to lastUpdatedTimestamp descending
  state.channelList.items = _.unionBy(
    items.map((channel) => augmentChannel(channel, state.auth.user)),
    state.channelList.items ?? [],
    newItems?.map((channel) => augmentChannel(channel, state.auth.user)) ?? [], //these items won't overwrite existing items
    'id'
  ).sort((a: ChatChannel, b: ChatChannel) => b.timestamp! - a.timestamp!);

  //build lookup tables
  buildChannelLookups(state);

  //reselect selected channel in case it has been updated by the added channels
  if (state.channelList.selected)
    selectChannelReducer(state, {
      payload: { channel: state.channelList.selected, fromId: true },
    });

  state.channelList.page = Math.ceil(
    state.channelList.items.length / CHANNELS_PAGE_SIZE
  );
};

const augmentChannel = (channel: ChatChannel, user?: ChatUser) => {
  const recipients =
    channel.members?.filter((m) => {
      if (m.userId === user?.userId) {
        user = m;
        return false;
      }
      return true;
    }) ?? [];
  const name = !_.isEmpty(channel.name)
    ? channel.name
    : recipients.map((r) => r.name).join(', ');
  const delivers = convertReceipts(channel.deliveryReceipts, user?.userId);
  const lastDeliveredAt = delivers[0]?.timestamp ?? 0;
  const reads = convertReceipts(channel.readReceipts, user?.userId);
  const lastReadAt = reads[0]?.timestamp ?? 0;
  const timestamp =
    channel.lastMessage?.createdAt ??
    channel.lastUpdatedTimestamp ??
    moment().unix(); //seconds
  const userLastReadAt = channel.readReceipts?.[user?.userId ?? 'unknown'] ?? 0;

  return {
    ...channel,
    user,
    recipients,
    name,
    delivers,
    reads,
    timestamp,
    lastDeliveredAt,
    lastReadAt,
    userLastReadAt,
  };
};

//remove any pending channels with requestId
const removePendingChannels = (state: chatStateType, requestId: string) => {
  //remove any matching pending channels from the list
  _.remove(
    state.channelList.items ?? [],
    (m) => m.pending === (requestId ?? 'unknown')
  );
  //rebuild lookup tables
  buildChannelLookups(state);
};

const getChannelFailedMessages = (
  failedMessageList: ChatMessage[] | undefined,
  channelId: string
): ChatMessage[] | undefined => {
  return failedMessageList?.filter(
    (message: ChatMessage) => message.channelId === channelId
  );
};

// remove message from indexedDB failed messages channel list, when message is successfully sent or on delete action
const removeMessageFromFailedMessagesChannelList = (message: ChatMessage) => {
  indexedDbFailedMessagesChannelsHelper.deleteFailedChatMessage(message.id);
};

const addMessages = async (
  state: chatStateType,
  channelId: string,
  items: ChatMessage[],
  newItems?: ChatMessage[]
) => {
  state.messageList.messages[channelId] ??= {
    items: [],
    lookup: {},
  };

  const failedMessageList: ChatMessage[] | undefined = getChannelFailedMessages(
    state.failedMessageList,
    channelId
  );

  //merge on id and sort according to createdAt timestamp in descending order
  state.messageList.messages[channelId].items = _.unionBy(
    items.map((m) => augmentMessage(m, state.auth?.user)),
    state.messageList.messages[channelId]?.items ?? [],
    failedMessageList?.map((m) => augmentMessage(m, state.auth?.user)) ?? [],
    newItems?.map((m) => augmentMessage(m, state.auth?.user)) ?? [],
    'id'
  ).sort((a: ChatMessage, b: ChatMessage) => b.createdAt - a.createdAt);
  //build lookup table
  state.messageList.messages[channelId].items.forEach((m, i) => {
    state.messageList.messages[channelId].lookup![m.id] = {
      index: i,
    };
  });
  //add the latest message to the channel
  const lastMessage = _.first(state.messageList.messages[channelId].items);

  const findChannel =
    state.channelList.items?.[state.channelList.lookup[channelId]?.index];
  if (lastMessage && findChannel) findChannel.lastMessage = lastMessage;
  state.messageList.messages[channelId].page = Math.ceil(
    (state.messageList.messages[channelId].items?.length ?? 0) /
      CHANNELS_PAGE_SIZE
  );
};

const augmentMessage = (message: ChatMessage, user?: ChatUser) => {
  const fromUser = message.user.userId === user?.userId;
  const deleted =
    message.isDeleted || message.deletedBy?.includes(user?.userId ?? '');
  const attachment = getAttachmentFromMessage(message);
  const hide = deleted || !(attachment || message.messageText);

  return {
    ...message,
    fromUser,
    deleted,
    attachment,
    hide,
  };
};

//remove any pending message with requestId
const removePendingMessages = (
  state: chatStateType,
  channelId: string,
  requestId: string
) => {
  if (state.messageList.messages[channelId]) {
    //remove any matching pending messages from the list
    _.remove(
      state.messageList.messages[channelId].items,
      (m) => m.pending === (requestId ?? 'unknown')
    );
    //rebuild the lookup
    state.messageList.messages[channelId].items.forEach((m, i) => {
      state.messageList.messages[channelId].lookup![m.id] = {
        index: i,
      };
    });
  }
};

const addChannelReducer = (
  state: chatStateType,
  { payload: { channel } }: { payload: { channel: ChatChannel } }
) => {
  addChannels(state, [], [channel]);
};

const updateChannelReducer = (
  state: chatStateType,
  { payload: { channel } }: { payload: { channel: ChatChannel } }
) => {
  //workaround to set this to zero if the channel is already selected
  if (channel?.id === (state.channelList.selected?.id ?? 'unknown'))
    channel.unreadMessageCount = 0;
  addChannels(state, [channel]);
};

// when member is removed from a channel
const removeMembersReducer = (
  state: chatStateType,
  { payload: { channel } }: { payload: { channel: ChatChannel } }
) => {
  state.channelList.items = state.channelList.items?.filter(
    (item) => item.id !== channel.id
  );

  // if that particular channel is selected(group)
  if (state.channelList.selected?.id === channel.id)
    selectChannelReducer(state, {
      payload: { channel: state.channelList.selected, fromId: true },
    });
};

const selectChannelReducer = (
  state: chatStateType,
  {
    payload: { channel, fromId = false },
  }: { payload: { channel?: ChatChannel; fromId?: boolean } }
) => {
  //store any text already in the message box for current selected
  const selected = state.channelList.selected;
  if (selected) {
    //lookup the channel
    const selectedChannel =
      state.channelList.items?.[state.channelList.lookup[selected.id]?.index];
    if (selectedChannel) selectedChannel.text = selected.text;
  }
  let selectChannel = channel;
  if (fromId) {
    //lookup the channel
    selectChannel =
      state.channelList.items?.[
        state.channelList.lookup[channel?.id ?? 'unknown']?.index
      ];
  }
  state.channelList.selected = selectChannel;
  state.searchList = {};
};

const newChannelReducer = (
  state: chatStateType,
  { payload: { recipients } }: { payload: { recipients?: ChatUser[] } }
) => {
  //new chat and select it (maintains state of new channel until created with BE)
  let user = state.auth?.user;
  if (user) {
    //is there an existing channel?
    let channel =
      state.channelList.items?.[
        state.channelList.recipientLookup[usersToIndex(recipients) ?? 'unknown']
          ?.index
      ];
    //no existing channel so retrieve state or create a new channel
    if (!channel) {
      channel =
        state.channelList.created ??
        ({
          id: uuidv4(),
          createdBy: user,
          members: [user],
          new: true,
          user,
        } as ChatChannel);
      channel.lastUpdatedTimestamp = moment().unix();
      //can merge here instead for group chat
      if (recipients) {
        channel.recipients = recipients;
        channel.members = [user, ...recipients];
        channel.id =
          usersToIndex(channel.members.slice(0, 2)) ?? channel.id ?? uuidv4();
      }
      state.channelList.created = channel;
    } else state.channelList.created = undefined;
    state.channelList.selected = channel;
  }
};

const updateMarkedReducer = (
  state: chatStateType,
  {
    payload: { mark, markedList },
  }: { payload: { mark: MarkChannel; markedList: MarkedReceipts } }
) => {
  const user = state.auth?.user;
  Object.entries(markedList).forEach(([channelId, receipts]) => {
    //iterate the receipts and update them
    const findChannel =
      state.channelList.items?.[state.channelList.lookup[channelId]?.index];

    if (findChannel) {
      // need to keep latest timestamp in case updates are out of order
      switch (mark) {
        case MarkChannel.delivered:
          findChannel.deliveryReceipts = _.mergeWith(
            receipts,
            findChannel.deliveryReceipts ?? {},
            (a, b) => Math.max(a, b)
          );
          findChannel.delivers = convertReceipts(
            findChannel.deliveryReceipts,
            user?.userId
          );
          findChannel.lastDeliveredAt = findChannel.delivers[0]?.timestamp ?? 0;
          break;
        case MarkChannel.read:
          findChannel.readReceipts = _.mergeWith(
            receipts,
            findChannel.readReceipts ?? {},
            (a, b) => Math.max(a ?? 0, b ?? 0)
          );
          findChannel.reads = convertReceipts(
            findChannel.readReceipts,
            user?.userId
          );
          findChannel.lastReadAt = findChannel.reads[0]?.timestamp ?? 0;
          break;
      }
      //update selected channel if same channel
      const selected = state.channelList.selected;
      if (findChannel.id === selected?.id) {
        switch (mark) {
          case MarkChannel.delivered:
            selected.deliveryReceipts = findChannel.deliveryReceipts;
            selected.delivers = findChannel.delivers;
            selected.lastDeliveredAt = findChannel.lastDeliveredAt;
            break;
          case MarkChannel.read:
            selected.readReceipts = findChannel.readReceipts;
            selected.reads = findChannel.reads;
            selected.lastReadAt = findChannel.lastReadAt;
            break;
        }
      }
    }
  });
};

const markChannelReducer = (
  state: chatStateType,
  {
    payload: { timestamp, mark, channelId },
  }: { payload: { timestamp: number; mark: MarkChannel; channelId: string } }
) => {
  const user = state.auth?.user;
  const findChannel =
    state.channelList.items?.[state.channelList.lookup[channelId]?.index];
  if (user && findChannel) {
    let receipts;
    switch (mark) {
      case MarkChannel.delivered:
        receipts = findChannel.deliveryReceipts ?? {};
        break;
      case MarkChannel.read:
        receipts = findChannel.readReceipts ?? {};
        findChannel.unreadMessageCount = 0;
        break;
    }
    if (receipts) receipts[user.userId] = timestamp;
  }
};

// set failed message list
const setFailedMessageListReducer = (
  state: chatStateType,
  { payload }: { payload: { failedMessages: ChatMessage[] | undefined } }
) => {
  state.failedMessageList = payload.failedMessages;
};

const addMessageReducer = (
  state: chatStateType,
  {
    payload: { message },
  }: {
    payload: {
      message: ChatMessage;
    };
  }
) => {
  addMessages(state, message.channelId, [], [message]);
};

//not currently used
const updateMessageReducer = (
  state: chatStateType,
  {
    payload: { message },
  }: {
    payload: {
      message: ChatMessage;
    };
  }
) => {
  //todo - use the lookup to update
  addMessages(state, message.channelId, [message]);
};

const userTypingReducer = (
  state: chatStateType,
  {
    payload: { channelId, userId, fromUserDisplayName, typing },
  }: {
    payload: {
      channelId: string;
      userId: string;
      fromUserDisplayName: string;
      typing?: boolean;
    };
  }
) => {
  if (state.channelList.selected?.id === channelId) {
    let typers = state.channelList.selected.typers ?? [];
    // remove the user from the typers list
    typers = typers.filter((t) => t.userId !== userId);
    // check if user is in selected recipients
    const user = state.channelList.selected.recipients?.find(
      (m) => m.userId === (userId ?? 'unknown')
    );
    if (user && typing) {
      typers.push(user);
    }
    state.channelList.selected.typers = typers;
  }
};

const selectMessageReducer = (
  state: chatStateType,
  {
    payload: { channelId, message },
  }: { payload: { channelId: string; message: ChatMessage } }
) => {
  if (state.messageList.messages[channelId])
    state.messageList.messages[channelId].selected = message;
};

const setChatSearchReducer = (
  state: chatStateType,
  {
    payload: { items, searchTerm },
  }: {
    payload: {
      items?: ChatChannel[];
      searchTerm?: string;
    };
  }
) => {
  if (!items) {
    state.searchList = {};
    return;
  }
  state.searchList.items = [...(items ?? [])];
  state.searchList.searchTerm = searchTerm ?? undefined;
};

const setMessagesTextReducer = (
  state: chatStateType,
  {
    payload: { channelId, text },
  }: { payload: { channelId: string; text: string } }
) => {
  if (
    state.channelList.selected &&
    channelId === (state.channelList.selected.id ?? 'unknown')
  )
    state.channelList.selected.text = text;
  if (
    state.channelList.created &&
    channelId === (state.channelList.created?.id ?? 'unknown')
  )
    state.channelList.created.text = text;
};

const setChatAuthReducer = (
  state: chatStateType,
  {
    payload: { user, token },
  }: { payload: { user?: ChatUser; token?: string | null } }
) => {
  if (user) state.auth.user = user;
  state.auth.token = token;
};

const setAttachmentFile = (
  state: chatStateType,
  file: ChatMessageAttachmentFile
) => {
  //update the file lookup
  if (!file.fileName) return;
  state.fileList[file.fileName] = file;
};

const updateContactProfilePhotoReducer = (
  state: chatStateType,
  {
    payload: { contactInfo },
  }: {
    payload: {
      contactInfo: SyncUserInfo;
    };
  }
) => {
  // Updates the channel.recipients state used for displaying the chat thread list
  // Looks for the target contact in the thread to update the profile image
  state.channelList.items = state.channelList.items?.map((channel) => {
    channel.recipients = channel.recipients?.map((recipient) => {
      if (recipient.userId === contactInfo.personId) {
        return {
          ...recipient,
          profileUrl: contactInfo.profileImage,
        };
      } else {
        return recipient;
      }
    });

    return channel;
  });

  // If chat thread search list is active,
  // Updates the searchList state used for displaying the searched chat threads
  // Looks for the target contact in the thread to update the profile image
  if (!!state.searchList.items) {
    state.searchList.items = state.searchList.items?.map((channel) => {
      if (channel.searchedUserId === contactInfo.personId) {
        return {
          ...channel,
          coverImageUrl: contactInfo.profileImage,
        };
      }

      return channel;
    });
  }

  // Updates the channelList.selected state used for displaying the selected chat thread
  // Looks for the target contact in the thread to update the profile image
  if (!!state.channelList.selected?.recipients) {
    state.channelList.selected.recipients =
      state.channelList.selected.recipients?.map((recipient) => {
        if (recipient.userId === contactInfo.personId) {
          return {
            ...recipient,
            profileUrl: contactInfo.profileImage,
          };
        } else {
          return recipient;
        }
      });
  }
};

const chatSlice = createSlice({
  name: 'chat',
  initialState: initialChatState,
  reducers: {
    addChannel: addChannelReducer,
    updateChannel: updateChannelReducer,
    selectChannel: selectChannelReducer,
    newChannel: newChannelReducer,
    updateMarked: updateMarkedReducer,
    removeMembers: removeMembersReducer,
    addMessage: addMessageReducer,
    updateMessage: updateMessageReducer,
    selectMessage: selectMessageReducer,
    setChatSearch: setChatSearchReducer,
    userTyping: userTypingReducer,
    setMessagesText: setMessagesTextReducer,
    setChatAuth: setChatAuthReducer,
    setFailedMessageList: setFailedMessageListReducer,
    updateContactProfilePhoto: updateContactProfilePhotoReducer,
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      chatService.endpoints.getChannels.matchFulfilled,
      (state, { payload: { items, continuationToken } }) => {
        addChannels(state, items);
        state.channelList.continuationToken = continuationToken;
        state.channelList.loaded = true;
        state.channelList.loading = false;
      }
    );
    builder.addMatcher(
      chatService.endpoints.getMessages.matchFulfilled,
      (state, { payload: { items, continuationToken } }) => {
        //first get the channelId
        const channelId = items[0]?.channelId;
        if (channelId) {
          addMessages(state, channelId, items);
          state.messageList.messages[channelId].continuationToken =
            continuationToken;
          state.messageList.messages[channelId].loaded = true;
        }
      }
    );
    builder.addMatcher(
      isAnyOf(
        chatService.endpoints.getChannels.matchPending,
        chatService.endpoints.getChannel.matchPending
      ),
      (state) => {
        state.channelList.loading = true;
      }
    );
    builder.addMatcher(
      chatService.endpoints.getChannel.matchFulfilled,
      (state, { payload: { channel } }) => {
        addChannels(state, [channel]);
        state.channelList.loading = false;
      }
    );
    builder.addMatcher(
      isAnyOf(
        chatService.endpoints.getChannels.matchRejected,
        chatService.endpoints.getChannel.matchRejected
      ),
      (state) => {
        state.channelList.loading = false;
      }
    );
    builder.addMatcher(
      chatService.endpoints.createChannel.matchPending,
      (
        state,
        {
          meta: {
            requestId,
            arg: {
              originalArgs: { channel, message },
            },
          },
        }
      ) => {
        if (message)
          addMessages(state, channel.id, [
            { ...message, ...{ pending: requestId } },
          ]);
        addChannels(state, [{ ...channel, ...{ pending: requestId } }]);
      }
    );
    builder.addMatcher(
      chatService.endpoints.createChannel.matchFulfilled,
      (
        state,
        {
          payload: channel,
          meta: {
            requestId,
            arg: {
              originalArgs: { channel: newchannel, message },
            },
          },
        }
      ) => {
        //remove created channel
        state.channelList.created = undefined;
        if (message) {
          removePendingMessages(state, message.channelId, requestId);
        }
        removePendingChannels(state, requestId);
        channel.members = [...newchannel.members];
        if (message && !channel.lastMessage) channel.lastMessage = message;
        addChannels(state, [channel]);
        selectChannelReducer(state, { payload: { channel, fromId: true } });
      }
    );
    builder.addMatcher(
      chatService.endpoints.createChannel.matchRejected,
      (
        state,
        {
          meta: {
            requestId,
            arg: {
              originalArgs: { message },
            },
          },
        }
      ) => {
        if (message) {
          removePendingMessages(state, message.channelId, requestId);
        }
        removePendingChannels(state, requestId);
      }
    );
    builder.addMatcher(
      chatService.endpoints.markChannel.matchFulfilled,
      (
        state,
        {
          payload: { timestamp },
          meta: {
            arg: {
              originalArgs: { mark, channelId },
            },
          },
        }
      ) => {
        markChannelReducer(state, { payload: { timestamp, mark, channelId } });
      }
    );
    builder.addMatcher(
      chatService.endpoints.newMessage.matchPending,
      (
        state,
        {
          meta: {
            requestId,
            arg: { originalArgs: message },
          },
        }
      ) => {
        addMessages(state, message.channelId, [
          { ...message, ...{ pending: requestId } },
        ]);
      }
    );
    builder.addMatcher(
      chatService.endpoints.newMessage.matchFulfilled,
      (state, { payload: message, meta: { requestId } }) => {
        removePendingMessages(state, message.channelId, requestId);
        addMessages(state, message.channelId, [message]);

        // if message sent successfully, remove the message from indexedDB failed messages channel list
        if (message?.failed) {
          removeMessageFromFailedMessagesChannelList(message);
        }
      }
    );

    builder.addMatcher(
      chatService.endpoints.newMessage.matchRejected,
      (
        state,
        {
          meta: {
            requestId,
            arg: { originalArgs: message },
          },
        }
      ) => {
        // if sending message fails, remove that message from pending status
        removePendingMessages(state, message.channelId, requestId);

        const failedMessage = {
          ...message,
          ...{ failed: true, pending: undefined, id: uuidv4() },
        };

        // add that message with failed status in message list
        addMessages(state, message.channelId, [failedMessage]);

        indexedDbFailedMessagesChannelsHelper.saveFailedMessages(failedMessage);
      }
    );
    builder.addMatcher(
      chatService.endpoints.postMessages.matchFulfilled,
      (state, { payload: { channels, messages } }) => {
        addChannels(state, channels);
        messages?.forEach((m) => addMessages(state, m.channelId, [m]));
      }
    );
    builder.addMatcher(
      chatService.endpoints.deleteMessage.matchPending,
      (
        state,
        {
          meta: {
            requestId,
            arg: {
              originalArgs: { message },
            },
          },
        }
      ) => {
        addMessages(state, message.channelId, [
          { ...message, ...{ pending: requestId } },
        ]);
      }
    );
    builder.addMatcher(
      chatService.endpoints.deleteMessage.matchFulfilled,
      (
        state,
        {
          meta: {
            requestId,
            arg: {
              originalArgs: { message },
            },
          },
        }
      ) => {
        removePendingMessages(state, message.channelId, requestId);
        // remove message from indexedDB failed messages channel list, when message is successfully sent or on delete action
        if (message?.failed) {
          removeMessageFromFailedMessagesChannelList(message);
        }
      }
    );
    builder.addMatcher(
      chatService.endpoints.deleteMessage.matchRejected,
      (
        state,
        {
          meta: {
            requestId,
            arg: {
              originalArgs: { message },
            },
          },
        }
      ) => {
        //remove pending status
        addMessages(state, message.channelId, [
          { ...message, pending: undefined },
        ]);
      }
    );
    builder.addMatcher(
      chatService.endpoints.searchChat.matchPending,
      (state) => {
        state.searchList.searching = true;
        state.searchList.items ??= [];
      }
    );
    builder.addMatcher(
      chatService.endpoints.searchChat.matchFulfilled,
      (state) => {
        state.searchList.searching = false;
      }
    );
    builder.addMatcher(
      chatService.endpoints.searchChat.matchRejected,
      (state, { meta: { condition } }) => {
        //if condition is false then it is an API error
        if (!condition) {
          state.searchList.searching = false;
          state.searchList.items = undefined;
        }
      }
    );
    builder.addMatcher(
      chatService.endpoints.createUpload.matchPending,
      (
        state,
        {
          meta: {
            requestId,
            arg: {
              originalArgs: { message },
            },
          },
        }
      ) => {
        addMessages(state, message.channelId, [
          { ...message, pending: requestId },
        ]);
      }
    );
    builder.addMatcher(
      chatService.endpoints.createUpload.matchRejected,
      (
        state,
        {
          meta: {
            requestId,
            arg: {
              originalArgs: { message },
            },
          },
        }
      ) => {
        addMessages(state, message.channelId, [
          {
            ...message,
            ...{ failed: true, pending: undefined, id: uuidv4() },
          },
        ]);
        removePendingMessages(state, message.channelId, requestId);
      }
    );
    builder.addMatcher(
      chatService.endpoints.createUpload.matchFulfilled,
      (
        state,
        {
          meta: {
            requestId,
            arg: {
              originalArgs: { message },
            },
          },
        }
      ) => {
        removePendingMessages(state, message.channelId, requestId);
      }
    );
    builder.addMatcher(
      chatService.endpoints.getAttachmentFile.matchPending,
      (
        state,
        {
          meta: {
            arg: {
              originalArgs: { file },
            },
          },
        }
      ) => {
        //set the attachment file loading
        setAttachmentFile(state, { ...file, loading: true, error: undefined });
      }
    );
    builder.addMatcher(
      chatService.endpoints.getAttachmentFile.matchFulfilled,
      (
        state,
        {
          payload: { objectURL },
          meta: {
            arg: {
              originalArgs: { file },
            },
          },
        }
      ) => {
        //set the attachment file objectURL
        setAttachmentFile(state, { ...file, objectURL, loading: false });
      }
    );
    builder.addMatcher(
      chatService.endpoints.getAttachmentFile.matchRejected,
      (
        state,
        {
          meta: {
            arg: {
              originalArgs: { file },
            },
          },
          error,
        }
      ) => {
        //set the attachment file error
        if (!error.name)
          setAttachmentFile(state, {
            ...file,
            loading: false,
            error: error.message,
          });
      }
    );
    builder.addMatcher(
      chatService.endpoints.resetService.matchFulfilled,
      () => initialChatState
    );
  },
});

export const {
  addChannel,
  updateChannel,
  selectChannel,
  newChannel,
  updateMarked,
  removeMembers,
  addMessage,
  updateMessage,
  userTyping,
  selectMessage,
  setChatSearch,
  setMessagesText,
  setChatAuth,
  setFailedMessageList,
  updateContactProfilePhoto,
} = chatSlice.actions;

export default chatSlice.reducer;
