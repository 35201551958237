import { SearchOutlined } from '@mui/icons-material';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { Autocomplete, Box, IconButton, Paper, TextField } from '@mui/material';
import _ from 'lodash';
import moment from 'moment';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { ROUTE_CONSTANTS_VARIABLE } from '../../../../constants/routeConstants';
import { TEST_CONSTANTS } from '../../../../constants/testConstants';
import localStorageHelper from '../../../../helpers/localStorageHelper';
import { searchChat } from '../../../../services/chatService';
import {
  getDocumentSearchResultsAction,
  getMyNetworkSearchResultsAction,
  setHeaderSearchTextAction,
} from '../../../../store/actions/headerActions';
import { DOCUMENT_REDUX_CONSTANTS } from '../../../../store/reduxConstants/documentsReduxConstants';
import { PAGINATION_REDUX_CONSTANTS } from '../../../../store/reduxConstants/paginationReduxConstants';
import { setChatSearch } from '../../../../store/slices/chatSlice';
import { headerStyle } from '../../../../style/headerStyle';
import { HeaderSearchOptionType } from '../../../../types/headerTypes';
import { InvitePracticePopUpData } from '../../../../types/myNetworkTypes';
import CreateInviteDialog from '../../myNetwork/practiceList/CreateInviteDialog';
import InviteRecommendedPracticeDialog from '../../myNetwork/recommendedList/InviteRecommendedPracticeDialog';
import AddTeamMember from '../../myOffice/AddTeamMember';
import NoSearchResults from './NoSearchResults';
import { SearchResultListItem } from './SearchResultListItem';

const HeaderSearchBar = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const token = localStorageHelper.getAuthToken();
  const modules = ['chat', 'documents', 'my-network', 'my-office'];
  const [isInvitePracticeDialogOpen, setIsInvitePracticeDialogOpen] =
    useState<boolean>(false);
  const [isAddMemberDialogOpen, setIsAddMemberDialogOpen] =
    useState<boolean>(false);
  const navigate = useNavigate();
  const { isHeaderSearchResultsLoaderActive } = useAppSelector(
    ({ generalLoaderReducer }: Record<string, any>) =>
      generalLoaderReducer ?? true
  );

  const { userId, searchedText, searchTerm, selectedTab } = useAppSelector(
    (state) => ({
      userId: state.chatReducer.auth?.user?.userId,
      searchedText: state.headerReducer?.searchedText ?? '',
      searchTerm: state.chatReducer?.searchList?.searchTerm ?? '',
      selectedTab: state.documentReducer?.selectedTab,
    })
  );

  const chatSearch =
    (
      searchTerm: string | null,
      setSearchResults: React.Dispatch<
        React.SetStateAction<Record<string, any>>
      >
    ) =>
    async () => {
      const dispatchedPromise = dispatch(
        searchChat.initiate({ searchTerm, userId })
      );
      dispatch(setChatSearch((await dispatchedPromise).data ?? {}));
      dispatchedPromise.unsubscribe();
    };

  const documentSearchAction =
    (
      searchTerm: string | null,
      _setSearchResults: React.Dispatch<
        React.SetStateAction<Record<string, any>>
      >
    ) =>
    () => {
      dispatch({
        type: PAGINATION_REDUX_CONSTANTS.RESET_CURRENT_PAGE,
      });
      dispatch(getDocumentSearchResultsAction(searchTerm));
    };
  const isSearchbarVisible =
    location.pathname !== ROUTE_CONSTANTS_VARIABLE.REFERRAL;

  const activeTab = useMemo(
    () => location.pathname.split('/')[2] ?? 'chat',
    [location]
  );
  const searchBarModule: Record<string, any> = useMemo(() => {
    return {
      chat: {
        placeholder: 'Search in chat',
        action: chatSearch,
        loader: isHeaderSearchResultsLoaderActive,
      },
      documents: {
        placeholder: 'Search practices and patients',
        action: documentSearchAction,
        loader: false,
      },
      'my-network': {
        placeholder: 'Search practices and people',
        action: getMyNetworkSearchResultsAction,
        loader: isHeaderSearchResultsLoaderActive,
      },
      'my-office': {
        placeholder: 'Search practices and people',
        action: getMyNetworkSearchResultsAction,
        loader: isHeaderSearchResultsLoaderActive,
      },
      efax: {
        placeholder: 'Search practices and patients',
      },
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isHeaderSearchResultsLoaderActive]);

  const [searchResults, setSearchResults] = useState([]);
  const [placeholder, setPlaceholder] = useState<string>(
    searchBarModule?.[activeTab]?.placeholder
  );

  const [recordSelectedToInvite, setRecordSelectedToInvite] =
    useState<InvitePracticePopUpData | null>(null);
  const [invitationSentToPractices, setInvitationSentToPractices] = useState<
    string[]
  >([]);
  //use key to reset the text in the input
  const [key, setKey] = useState<number>(0);

  const isSearchVisible = useMemo(() => {
    const paths = location.pathname.split('/');
    return !paths.includes('profile');
  }, [location]);

  const onClickConnect = (record: HeaderSearchOptionType) => {
    const { PracticeId, PracticeName, PracticeType } = record;
    setRecordSelectedToInvite({ PracticeId, PracticeName, PracticeType });
  };

  const onSearch = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(setHeaderSearchTextAction(event.target.value));
    if (event.target.value.length > 2 && searchBarModule?.[activeTab]?.action) {
      searchBarModule?.[activeTab]?.action(
        event.target.value,
        setSearchResults
      )();
    } else {
      setSearchResults([]);
    }
  };
  const debounceSearch = _.debounce(onSearch, 1500);

  useEffect(() => {
    setPlaceholder(searchBarModule?.[activeTab]?.placeholder);
    setKey(moment().unix());
    dispatch(setChatSearch({}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  useEffect(() => {
    if (activeTab === 'documents') {
      dispatch(setHeaderSearchTextAction(''));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab, activeTab]);

  useEffect(() => {
    if (!searchTerm) setKey(moment().unix());
  }, [searchTerm]);

  return (
    <Box sx={headerStyle.headerSearchBarContainer}>
      {modules.includes(location.pathname.split('/')[2]) &&
        !modules.includes(location.pathname.split('/').pop() ?? '') && (
          <>
            <IconButton
              size='large'
              sx={headerStyle.headerBackButton}
              onClick={() => navigate(-1)}
            >
              <ArrowBackOutlinedIcon fontSize='inherit' />
            </IconButton>
            {isSearchbarVisible && (
              <Box sx={headerStyle.arrowAndSearchbarVerticalLine} />
            )}
          </>
        )}
      {isSearchbarVisible ? (
        <Autocomplete<HeaderSearchOptionType, true, true, true>
          key={key}
          disabled={!token}
          sx={headerStyle.headerSearchbar}
          data-testid={TEST_CONSTANTS.HEADER_SEARCH_BAR}
          freeSolo
          fullWidth
          disableCloseOnSelect
          loading={searchBarModule?.[activeTab]?.loader}
          groupBy={(option: Record<string, any>) => option?.recordType}
          clearOnBlur={
            activeTab !== 'chat' &&
            activeTab !== 'documents' &&
            activeTab !== 'efax'
          }
          onBlur={() =>
            activeTab !== 'chat' &&
            activeTab !== 'documents' &&
            activeTab !== 'efax' &&
            dispatch(setHeaderSearchTextAction(''))
          }
          options={searchResults}
          filterOptions={(x) => x}
          getOptionLabel={(option: HeaderSearchOptionType | string) =>
            typeof option === 'string'
              ? option
              : `${option?.UserName}${
                  option?.IsPracticeResult
                    ? `${
                        option?.PracticeCity ? `- ${option?.PracticeCity}` : ''
                      }${
                        option?.PracticeState
                          ? `, ${option?.PracticeState}`
                          : ''
                      }${option?.PracticeZip ? `, ${option?.PracticeZip}` : ''}`
                    : option?.Role
                    ? `, ${option?.Role} at ${option.PracticeName}`
                    : ''
                }`
          }
          renderOption={(props, option: HeaderSearchOptionType | null) =>
            SearchResultListItem(
              props,
              option,
              searchedText,
              onClickConnect,
              invitationSentToPractices
            )
          }
          onInputChange={(
            _event: React.SyntheticEvent,
            value: string,
            reason: 'input' | 'reset' | 'clear'
          ) => {
            !value?.length && setSearchResults([]);
            // set searched text in reducer
            dispatch(setHeaderSearchTextAction(value));
            // to clear the search textr and close the dropdown this condition is added, by checking reason of input change
            if (reason === 'clear') {
              dispatch(setHeaderSearchTextAction(''));
            }
            if (activeTab === 'chat' && !value?.length) {
              dispatch(setChatSearch({}));
            }

            if (
              value?.toString()?.trim()?.length < 2 &&
              activeTab === 'documents'
            ) {
              dispatch({
                type: DOCUMENT_REDUX_CONSTANTS.RESET_SELECTED_TAB_DOCUMENT_LIST,
              });
            }
          }}
          inputValue={searchedText}
          PaperComponent={(paperComponentProps) => (
            <Paper
              elevation={3}
              data-testid={TEST_CONSTANTS.HEADER_SEARCH_BAR_RESULT}
              sx={headerStyle.headerSearchDropdown}
              {...paperComponentProps}
            >
              {paperComponentProps.children}
              {searchedText?.length > 2 &&
                !searchBarModule?.[activeTab]?.loader &&
                activeTab === 'my-network' &&
                !searchResults?.length && (
                  <NoSearchResults
                    setIsInvitePracticeDialogOpen={
                      setIsInvitePracticeDialogOpen
                    }
                    setIsAddMemberDialogOpen={setIsAddMemberDialogOpen}
                  />
                )}
            </Paper>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              variant='standard'
              placeholder={placeholder ?? 'Search practices and patients'}
              sx={{
                ...headerStyle.headerSearchTextField,
                display: isSearchVisible ? 'block' : 'none',
              }}
              onChange={debounceSearch}
              InputProps={{
                ...params.InputProps,
                startAdornment: <SearchOutlined sx={{ width: '3.3rem' }} />,
                disableUnderline: true,
                onKeyDown: (event) => {
                  if (event.key === 'Enter') {
                    event.stopPropagation();
                  }
                },
              }}
            />
          )}
        />
      ) : null}
      {isInvitePracticeDialogOpen && (
        <CreateInviteDialog
          isInvitePracticeDialogOpen={isInvitePracticeDialogOpen}
          setIsInvitePracticeDialogOpen={setIsInvitePracticeDialogOpen}
        />
      )}
      {isAddMemberDialogOpen && (
        <AddTeamMember
          isAddMemberDialogOpen={isAddMemberDialogOpen}
          setIsAddMemberDialogOpen={setIsAddMemberDialogOpen}
          isRequestedFromMyOffice={false}
        />
      )}
      {recordSelectedToInvite && (
        <InviteRecommendedPracticeDialog
          recordSelectedToInvite={recordSelectedToInvite}
          setRecordSelectedToInvite={setRecordSelectedToInvite}
          invitationSentToPractices={invitationSentToPractices}
          setInvitationSentToPractices={setInvitationSentToPractices}
          invitedFromHeaderSearchBar={true}
        />
      )}
    </Box>
  );
};

export default HeaderSearchBar;
