import { createApi } from '@reduxjs/toolkit/query/react';
import _ from 'lodash';
import { DASHBOARD_URLS } from '../constants/urlConstants';
import { initialCircleColorArray } from '../theme/colorPalette';
import {
  MyOfficeAddMemberFormType,
  MyOfficeListResponse,
  MyOfficeTeamMemberInvitationListResponseType,
  MyOfficeTeamMemberType,
} from '../types/myOfficeType';
import baseQueryWithReauth from './serviceQuery';

const myOfficeService = createApi({
  reducerPath: 'myOfficeApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (build) => ({
    getMyOfficeConnectedList: build.query<
      MyOfficeTeamMemberType[],
      { id: string | undefined }
    >({
      query: ({ id }) => ({
        url: `${DASHBOARD_URLS.MY_OFFICE_URL.GET_ORGANIZATION_DETAILS}/${id}`,
        method: 'GET',
      }),
      transformResponse: (response: MyOfficeListResponse) => {
        let data = response.teamMembers?.map(
          (member: MyOfficeTeamMemberType) => ({
            ...member,
            bgcolor:
              initialCircleColorArray[
                Math.floor(Math.random() * initialCircleColorArray.length)
              ],
          })
        );
        data = _.sortBy(data, [(member) => member.firstName]);
        return data;
      },
    }),
    getMyOfficeInvitationList: build.query<
      MyOfficeTeamMemberInvitationListResponseType[],
      void
    >({
      query: () => ({
        url: DASHBOARD_URLS.MY_OFFICE_URL.GET_TEAM_MEMBER_INVITATIONS,
        method: 'GET',
      }),
      transformResponse: (
        response: MyOfficeTeamMemberInvitationListResponseType[]
      ) =>
        response?.map(
          (member: MyOfficeTeamMemberInvitationListResponseType) => ({
            ...member,
            bgcolor:
              initialCircleColorArray[
                Math.floor(Math.random() * initialCircleColorArray.length)
              ],
          })
        ),
    }),
    getRolesofLoggedInUserPractice: build.query<string[], string | undefined>({
      query: (organizationId) => ({
        url: `${DASHBOARD_URLS.MY_OFFICE_URL.GET_ROLES_OF_LOGGED_IN_USER_PRACTICE}/${organizationId}/roles`,
        method: 'GET',
      }),
    }),
    addTeamMember: build.mutation<any, MyOfficeAddMemberFormType>({
      query: (form) => ({
        url: DASHBOARD_URLS.MY_OFFICE_URL.ADD_MEMBER,
        method: 'POST',
        body: {
          InvitationMode: 1,
          ToEmail: form.email,
          Role: form.role!,
        },
      }),
    }),
  }),
});

export const {
  useGetMyOfficeConnectedListQuery,
  useGetMyOfficeInvitationListQuery,
  useAddTeamMemberMutation,
  useGetRolesofLoggedInUserPracticeQuery,
} = myOfficeService;

export const {
  getMyOfficeConnectedList,
  getMyOfficeInvitationList,
  getRolesofLoggedInUserPractice,
  addTeamMember,
} = myOfficeService.endpoints;

export default myOfficeService;
