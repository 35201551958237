import { ArrowBackIos } from '@mui/icons-material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ClearIcon from '@mui/icons-material/Clear';
import {
  Box,
  IconButton,
  InputAdornment,
  Link,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import _ from 'lodash';
import { ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { ReactComponent as DrtalkLogo } from '../../../assets/images/drTalkLogo.svg';
import FlatButton from '../../../components/FlatButton';
import MaskedInput from '../../../components/MaskedInput';
import { onboardingPersonalInfoFormError } from '../../../constants/authConstants';
import {
  MAX_CUSTOM_ROLE_CHAR,
  MOBILE_INPUT_LABEL,
  MOBILE_INPUT_PLACEHOLDER,
  ROLE_OTHER,
} from '../../../constants/profileConstants';
import { NON_NUMERIC_REGEX } from '../../../constants/regexConstants';
import { ROUTE_CONSTANTS_VARIABLE } from '../../../constants/routeConstants';
import { TEST_CONSTANTS } from '../../../constants/testConstants';
import localStorageHelper from '../../../helpers/localStorageHelper';
import { logoutUser } from '../../../helpers/logoutHelper';
import { getEnvMobileConfig } from '../../../helpers/profileHelper';
import { submitOnboardingPersonalInfoFormAction } from '../../../store/actions/onboardingAction';
import { getPracticeRoleList } from '../../../store/actions/practiceActions';
import { setUserInfo } from '../../../store/actions/userActions';
import { commonAuthStyle } from '../../../style/commonAuthStyle';
import { onboardingStyle } from '../../../style/onboardingStyle';
import {
  OnboardingPersonalInfoForm,
  OnboardingPersonalInfoFormError,
} from '../../../types/onboardingTypes';
import { UserInfo } from '../../../store/reduxConstants/userReduxConstants';

// when user logs in, if role and mobile number is not available,
// user will be redirected to this form will to update role and mobile number.

const OnboardingPersoalInfoForm = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { mobileMaskFormat } = getEnvMobileConfig();
  const { isOnboardingPersonalInfoFormSubmissionLoaderActive } = useAppSelector(
    ({ generalLoaderReducer }: Record<string, any>) =>
      generalLoaderReducer ?? true
  );
  const { practiceIds, practiceRoles, userId, userInfo, userPracticeId } =
    useAppSelector((state) => ({
      practiceIds: state.practiceReducer.practiceIds,
      practiceRoles: state.practiceReducer.practiceRoles,
      userId: state.userReducer.id,
      userInfo: state.userReducer.userInfo,
      userPracticeId: state.userReducer.userInfo?.practiceId ?? '',
    }));

  const customRoleInputRef = useRef<HTMLElement>(null);
  const customRolesMenuAnchor = useRef<HTMLDivElement>(null);
  const [isCustomRole, setIsCustomRole] = useState<boolean>(false);
  const [isCustomRolesMenuOpen, setIsCustomRolesMenuOpen] =
    useState<boolean>(false);

  const roleList = useMemo<readonly string[]>(() => {
    if (!practiceIds.includes(userPracticeId)) return [];

    return isCustomRole
      ? practiceRoles[userPracticeId]
      : [...practiceRoles[userPracticeId], ROLE_OTHER];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [practiceIds, practiceRoles, userInfo?.practiceId, isCustomRole]);

  const [form, setForm] = useState<{ role: string | null; mobile: string }>({
    role: userInfo?.role ?? null,
    mobile: userInfo?.mobile ?? '',
  });
  const [error, setError] = useState<OnboardingPersonalInfoFormError>({
    role: undefined,
    mobile: undefined,
  });
  const [isFormSubmittedOnce, setIsFormSubmittedOnce] =
    useState<boolean>(false);

  const isFormValid = (data: Partial<OnboardingPersonalInfoForm>) => {
    let isValid: boolean = true;
    let formError = error;
    const { role, mobile } = data;

    const enteredValueLength = mobile?.replace(NON_NUMERIC_REGEX, '').length;
    const expectedLength = mobileMaskFormat.replace(
      NON_NUMERIC_REGEX,
      ''
    ).length;
    for (const key in data) {
      switch (key) {
        case 'role':
          if (!role) {
            formError.role = onboardingPersonalInfoFormError.role;
            isValid = false;
          } else {
            formError.role = undefined;
          }
          break;

        case 'mobile':
          if (
            !(
              mobile?.toString()?.trim()?.length &&
              enteredValueLength === expectedLength
            )
          ) {
            formError.mobile = onboardingPersonalInfoFormError.mobile;
            isValid = false;
          } else {
            formError.mobile = undefined;
          }
          break;
      }
    }
    setError(formError);
    return isValid;
  };

  function onSuccessfullyFormSubmission() {
    navigate(ROUTE_CONSTANTS_VARIABLE.DASHBOARD);

    const newUserInfo = {
      ...userInfo,
      mobile: form.mobile,
      role: form.role,
    } as UserInfo;

    localStorageHelper.updateUserInfo(newUserInfo);
    dispatch(setUserInfo(userId, newUserInfo));
  }

  const onClickFormSubmit = () => {
    if (!isOnboardingPersonalInfoFormSubmissionLoaderActive) {
      if (!isFormSubmittedOnce) {
        setIsFormSubmittedOnce(true);
      }

      if (isFormValid(form)) {
        submitOnboardingPersonalInfoFormAction(
          form,
          onSuccessfullyFormSubmission
        );
      }
    }
  };

  const onChangeFormField = (name: string, value: string | null) => {
    if (isFormSubmittedOnce) {
      isFormValid({ [name]: value });
    }
    setForm({ ...form, [name]: value });
  };

  // to fill in form with the user info, if already avaiable
  useEffect(() => {
    if (!(userInfo?.mobile || userInfo?.role)) {
      navigate(ROUTE_CONSTANTS_VARIABLE.DASHBOARD);
    } else {
      setForm({ mobile: userInfo?.mobile ?? '', role: userInfo?.role });
    }
    if (userInfo?.role && userInfo?.mobile) {
      navigate(ROUTE_CONSTANTS_VARIABLE.DASHBOARD);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);

  useEffect(() => {
    if (userInfo?.practiceId) {
      if (practiceIds.includes(userInfo.practiceId)) return;

      // Get practice information if it does not exist
      dispatch(getPracticeRoleList(userInfo.practiceId));
    }
  }, [dispatch, practiceIds, userInfo]);

  useEffect(() => {
    if (isCustomRole && _.isEmpty(form.role)) {
      customRoleInputRef.current?.focus();
    }
  }, [isCustomRole, form.role]);

  const onBackButtonClick = () => {
    logoutUser(navigate);
  };

  function handleToggleCustomRoleMenu(): void {
    setIsCustomRolesMenuOpen(!isCustomRolesMenuOpen);
  }

  function renderRoleList(): ReturnType<typeof MenuItem>[] {
    return roleList.map((role: string) => (
      <MenuItem
        key={role}
        value={role}
        onClick={
          isCustomRole
            ? () => {
                setForm({ ...form, role });
                setIsCustomRole(false);
                handleToggleCustomRoleMenu();
              }
            : undefined
        }
      >
        {role}
      </MenuItem>
    ));
  }

  function handleRoleChange(selectedRole: string) {
    if (selectedRole === ROLE_OTHER) {
      setForm((prevState) => ({
        ...prevState,
        role: '',
      }));

      setIsCustomRole(true);

      return;
    }

    if (isFormSubmittedOnce) {
      isFormValid({ role: selectedRole });
    }

    setForm((prevState) => ({
      ...prevState,
      role: selectedRole,
    }));
  }

  function onDeleteCustomRole(): void {
    handleRoleChange('');
  }

  function renderCustomRoleInputAdornment(): ReactNode {
    return (
      <InputAdornment position='end'>
        {isCustomRole ? (
          <IconButton
            onClick={
              _.isEmpty(form.role)
                ? handleToggleCustomRoleMenu
                : onDeleteCustomRole
            }
            disableRipple
          >
            {!_.isEmpty(form.role) ? (
              <ClearIcon />
            ) : isCustomRolesMenuOpen ? (
              <ArrowDropUpIcon />
            ) : (
              <ArrowDropDownIcon />
            )}
          </IconButton>
        ) : null}
      </InputAdornment>
    );
  }

  function onClickDoItLater() {
    navigate(ROUTE_CONSTANTS_VARIABLE.DASHBOARD);
  }

  return (
    <Box sx={commonAuthStyle.authPage}>
      <Box sx={commonAuthStyle.authContainer}>
        <Link
          data-testid={TEST_CONSTANTS.ONBOARDING_PERSONAL_INFO_BACK_BUTTON}
          className='backButton'
          underline='hover'
          sx={commonAuthStyle.authBackButton}
          onClick={onBackButtonClick}
        >
          <ArrowBackIos fontSize='inherit' /> Back
        </Link>
        <DrtalkLogo />
        <Box sx={commonAuthStyle.authTitleSubtitleContainer}>
          <Box sx={commonAuthStyle.authTitle}>
            Your account is missing a few details
          </Box>
          <Typography variant='body2'>
            Help us secure your account by providing us with the following data
          </Typography>
        </Box>

        <MaskedInput
          label={MOBILE_INPUT_LABEL}
          value={form.mobile}
          mask={mobileMaskFormat}
          onChange={(e) => onChangeFormField('mobile', e.target.value)}
          helperText={error?.mobile}
          placeHolder={MOBILE_INPUT_PLACEHOLDER}
          isLabelShrinked={!!form.mobile}
        />

        <TextField
          data-testid={TEST_CONSTANTS.ONBOARDING_PERSONAL_INFO_ROLE}
          helperText={error.role}
          label='Role'
          fullWidth
          ref={customRolesMenuAnchor}
          inputRef={customRoleInputRef}
          value={roleList.length ? form.role : ''}
          onChange={(e) => handleRoleChange(e.target.value)}
          select={!isCustomRole}
          placeholder={isCustomRole ? ROLE_OTHER : 'Role'}
          inputProps={{ maxLength: MAX_CUSTOM_ROLE_CHAR }}
          InputLabelProps={{
            shrink: (!isCustomRole && !!form.role) || isCustomRole,
          }}
          InputProps={{
            style: {
              paddingRight: 0,
            },
            endAdornment: renderCustomRoleInputAdornment(),
          }}
        >
          {renderRoleList()}
        </TextField>
        {isCustomRole && (
          <Menu
            anchorEl={customRolesMenuAnchor.current}
            open={isCustomRolesMenuOpen}
            onClose={handleToggleCustomRoleMenu}
            PaperProps={{
              style: {
                minWidth: '486px',
              },
            }}
          >
            {renderRoleList()}
          </Menu>
        )}

        <Box sx={onboardingStyle.personalInfoFormButtonsContainer}>
          <FlatButton variant='outlined' onClick={onClickDoItLater}>
            Do it later
          </FlatButton>
          <FlatButton
            variant='contained'
            sx={commonAuthStyle.submitButton}
            onClick={onClickFormSubmit}
            isLoading={isOnboardingPersonalInfoFormSubmissionLoaderActive}
          >
            Done
          </FlatButton>
        </Box>
      </Box>
    </Box>
  );
};

export default OnboardingPersoalInfoForm;
