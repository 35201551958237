import { DocumentsTableDetailsType } from '../../types/documentsTypes';
import _ from 'lodash';

const documentListSortByDate = (list: DocumentsTableDetailsType[]) => {
  return _.orderBy(
    list,
    (record: DocumentsTableDetailsType) => {
      return new Date(
        record.documentStatus === 'Processed'
          ? record.processedDate
          : record.recievedOn
      ).getTime();
    },
    ['desc']
  );
};

export default documentListSortByDate;
