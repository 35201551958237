import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import {
  Avatar,
  Box,
  IconButton,
  Link,
  Menu,
  TextField,
  Typography,
} from '@mui/material';
import moment from 'moment';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../../../app/hooks';
import { TEST_CONSTANTS } from '../../../../constants/testConstants';
import {
  useCreateChannelMutation,
  useNewMessageMutation,
} from '../../../../services/chatService';
import { documentStyle } from '../../../../style/dashboardStyles/documentStyle';
import {
  ChatChannel,
  ChatMessage,
  ChatUser,
  MessageType,
} from '../../../../types/chatTypes';
import { SendQuickMessageDialogProps } from '../../../../types/documentsTypes';

const SendQuickMessageDialog = (props: SendQuickMessageDialogProps) => {
  const {
    recordSelectedForQuickMessage: record,
    anchorElement,
    setRecordSelectedForQuickMessage,
  } = props;
  const navigate = useNavigate();
  const [quickMsg, setQuickMsg] = useState<string>('');
  const [newMessage] = useNewMessageMutation();
  const { id: loggedInUserId } = useAppSelector((state) => state.userReducer);
  const { created } = useAppSelector(
    (state) => state.chatReducer?.channelList ?? {}
  );
  const [createChannel] = useCreateChannelMutation();
  const senderToChatUser = () => {
    return {
      userId: record?.fromUserId,
      profileUrl: '',
      name: record?.fromUserDisplayName,
      role: record?.fromUserRole,
      practiceName: record?.fromPracticeName,
    } as ChatUser;
  };

  const sendQuickMessage = async () => {
    const messageToBeSent = {
      channelId: [loggedInUserId, record.fromUserId].sort().join('--'),
      messageText: quickMsg,
      user: {
        userId: record.fromUserId,
        profileUrl: '', // need to change this once it's available from backend
        name: record.fromUserDisplayName,
        lastSeenAt: 0,
        role: record.fromUserRole,
        practiceName: record.fromPracticeName,
        isDeleted: false,
      },
      createdAt: moment().unix(),
      messageType: MessageType.text,
    } as ChatMessage;

    if (
      [loggedInUserId, record.fromUserId].sort().join('--') ===
      (created?.id ?? 'unknown')
    ) {
      const newChannel = {
        ...created,
        lastMessage: { ...messageToBeSent },
      } as ChatChannel;
      const response = await createChannel({
        channel: newChannel,
        message: messageToBeSent,
      }).unwrap();
      if (response.id) messageToBeSent.channelId = response.id;
    }
    await newMessage(messageToBeSent);
    setRecordSelectedForQuickMessage(null);
    setQuickMsg('');
  };

  const removeSelectedRecord = () => {
    setRecordSelectedForQuickMessage(null);
  };

  return (
    <Menu
      data-testid={TEST_CONSTANTS.DOCUMENTS_SEND_QUICK_MESSAGE_DIALOG}
      open={!!record}
      onClose={removeSelectedRecord}
      anchorEl={anchorElement}
      id={` ${record.id}`}
      MenuListProps={{
        'aria-labelledby': `quick-msg-action-${record.id}`,
      }}
      sx={documentStyle.sendQuickMessageDialogContainer}
      PaperProps={{
        style: {
          width: 255,
          borderRadius: '.85rem',
        },
      }}
    >
      <Box sx={documentStyle.sendQuickMessageDialogUserDetails}>
        <Avatar src={record.fromUserProfileImage} />
        <Box>
          <Typography variant='body2' fontWeight={700}>
            {record.fromUserDisplayName}
          </Typography>
          <Typography variant='caption'>{record?.fromUserRole}</Typography>
        </Box>
      </Box>
      <Link
        underline='hover'
        sx={documentStyle.sendQuickMessageDialogChatLink}
        color='primary'
        onClick={() => {
          navigate('/dashboard/chat', {
            state: {
              user: senderToChatUser(),
            },
          });
        }}
      >
        <ChatOutlinedIcon fontSize='small' /> Open chat
      </Link>

      <Box sx={documentStyle.sendQuickMessageDialogTextfieldContainer}>
        <TextField
          sx={documentStyle.sendQuickMessageDialogTextfield}
          placeholder='Send a quick message'
          variant='filled'
          onKeyDown={(e) => e.stopPropagation()}
          onChange={(e) => setQuickMsg(e.target.value)}
          value={quickMsg}
          InputProps={{
            style: {
              padding: 1,
              fontSize: 14,
              width: 195,
            },
            disableUnderline: true,
          }}
        />
        <IconButton
          data-testid={TEST_CONSTANTS.DOCUMENTS_SEND_QUICK_MESSAGE_BUTTON}
          disabled={!quickMsg.trim().length}
          onClick={sendQuickMessage}
        >
          <SendOutlinedIcon />
        </IconButton>
      </Box>
    </Menu>
  );
};

export default SendQuickMessageDialog;
