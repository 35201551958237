import DoneIcon from '@mui/icons-material/Done';
import {
  Box, Dialog,
  DialogActions,
  DialogContent, Typography
} from '@mui/material';
import { TEST_CONSTANTS } from '../constants/testConstants';
import { commonClasses } from '../style/commonClasses';
import FlatButton from './FlatButton';

type SuccessDialogProps = {
  title?: string;
  message?: string;
  closeButtonText?: string;
  closeButtonAction?: () => void;
};

const SuccessDialog = (successDialogProps: SuccessDialogProps) => {

  return (
    <Dialog
      data-testid={TEST_CONSTANTS.COMMON_SUCCESS_DIALOG}
      aria-labelledby={successDialogProps.message}
      aria-describedby={successDialogProps.message}
      open={true}
    >
      <DialogContent sx={{ p: '1.625rem 1.5rem' }}>
        <Box>
          <DoneIcon sx={commonClasses.successModalHeaderIcon} />
        </Box>
        <Box sx={commonClasses.successModalTitle}>
          {successDialogProps.title}
        </Box>

        <Typography sx={commonClasses.successModalContent}>
          {successDialogProps.message}
        </Typography>
      </DialogContent>
      <DialogActions sx={commonClasses.successRowModal}>
        <FlatButton
          variant='contained'
          onClick={successDialogProps.closeButtonAction}
        >
          Close
        </FlatButton>
      </DialogActions>
    </Dialog>
  );
};

export default SuccessDialog;
