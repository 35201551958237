import { colorPalette } from '../../theme/colorPalette';

export const introductionScreenStyle = {
  introductionScreenContainer: {
    position: 'relative',
    p: '2.5rem 3rem',
    height: '100vh',
    width: '100%',
    button: {
      borderRadius: '.5rem',
    },
  },
  introductionHeaderMainContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& > div': {
      display: 'flex',
      alignItems: 'center',
      gap: '1.5rem',
    },
  },
  greyBottomBackground: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    top: '50%',
    bgcolor: colorPalette.surfaceColor[50],
    zIndex: -1,
  },
  introductionScreenContentContainer: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    gap: '3rem',
    height: '70vh',
    m: '2rem 0',
    p: '4.5rem',
    background:
      'linear-gradient(90deg, rgba(90,64,170,1) 0%, rgba(77,51,152,1) 35%, rgba(61,38,133,1) 100%)',
    border: '.5rem solid',
    borderColor: 'primary.light',
    borderRadius: '2.7rem',
  },
  leftContentContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    marginTop: '4%',
    color: 'background.default',
    zIndex: 2,
  },
  demoModuleContainer: {
    display: 'flex',
    gap: '1rem',
    color: 'background.default',
    typography: 'overline',
    '> div': {
      pb: '1rem',
      textAlign: 'center',
    },
  },
  tryForFreeButton: {
    mt: '2rem',
    p: '.5rem 2.35rem',
    color: 'primary.main',
  },
};
