import { useState, useEffect, useRef } from 'react';

function useInterval(callback: () => void, delay: number | null) {
  const savedCallback = useRef<() => void>();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      savedCallback?.current?.();
    }
    if (delay !== null) {
      const interval = setInterval(tick, delay);
      return () => clearInterval(interval);
    }
  }, [delay]);
}

export const useServiceWorker = () => {
  const [updateAvailable, setUpdateAvailable] = useState(false);

  const checkForUpdateRef = useRef(() => {});
  const promptUserToRefreshRef = useRef(() => {});
  const installAppRef = useRef(() => {});
  const updateServiceWorkerRef = useRef(() => {});

  const checkForWaitingServiceWorker = () => {
    if ('serviceWorker' in navigator)
      navigator.serviceWorker.getRegistration().then((registration) => {
        if (registration) {
          registration.onupdatefound = () => {
            const installingWorker = registration.installing;

            if (installingWorker == null) {
              return;
            }
            installingWorker.onstatechange = () => {
              if (installingWorker.state === 'installed') {
                if (navigator.serviceWorker.controller) {
                  setUpdateAvailable(true);
                }
              }
            };
          };
          if (registration.waiting) setUpdateAvailable(true);
        }
        promptUserToRefreshRef.current = () => {
          window.location.reload();
          if (registration)
            if (registration.waiting) {
              registration.waiting.postMessage({ type: 'SKIP_WAITING' });
            }
        };
      });
  };

  useInterval(checkForWaitingServiceWorker, 30 * 60 * 1000);

  useEffect(() => {
    checkForWaitingServiceWorker();
  }, []);

  const checkForUpdate = checkForUpdateRef.current;
  const promptUserToRefresh = promptUserToRefreshRef.current;
  const installApp = installAppRef.current;
  const updateServiceWorker = updateServiceWorkerRef.current;

  return {
    updateAvailable,
    checkForUpdate,
    promptUserToRefresh,
    installApp,
    updateServiceWorker,
  };
};
