import { SNACKBAR_REDUX_CONSTANTS } from '../reduxConstants/snackbarReduxConstants';

const initialSnackBarReducer = {
  open: false,
  statusCode: null,
  message: null,
  severity: 'error',
};

export const snackbarReducer = (
  state = initialSnackBarReducer,
  action: Record<string, any>
) => {
  switch (action?.type) {
    case SNACKBAR_REDUX_CONSTANTS.SHOW_SNACKBAR:
      return { ...state, ...action.data };

    case SNACKBAR_REDUX_CONSTANTS.HIDE_SNACKBAR:
      return { ...initialSnackBarReducer };

    default:
      return { ...state };
  }
};
