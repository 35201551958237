import { ListItem, ListItemText, Typography } from '@mui/material';
import { Patient } from 'types/referralTypes/referralTypes';
import {
  commonReferralStyle,
  patientListItemStyle,
} from 'style/dashboardStyles/referralStyle';

export type PatientListItemProps = {
  props: object;
  patient: Patient;
};

const PatientListItem = ({ props, patient }: PatientListItemProps) => {
  return (
    <ListItem key={patient?.id} {...props} sx={commonReferralStyle.listItem}>
      <ListItemText
        primary={
          <Typography sx={patientListItemStyle.patientItemLabel}>
            {patient?.firstName} {patient?.lastName} -{' '}
            {new Date(patient?.dateOfBirth).toLocaleDateString()}
          </Typography>
        }
      />
    </ListItem>
  );
};

export default PatientListItem;
