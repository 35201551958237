import {
  Box,
  CircularProgress,
  Drawer,
  IconButton,
  Typography,
} from '@mui/material';
import {
  toggleOpenEfaxDrawer,
  toggleSendEfaxDrawer,
} from '../../../../store/actions/dashboardActions/efaxActions';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { efaxStyle } from '../../../../style/dashboardStyles/efaxStyle';
import { Close, Check, WarningAmber, ErrorOutline } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { createDocPreviewAction } from '../../../../store/actions/dashboardActions/documentActions';
import { MOBILE_NUMBER_US_WITH_CODE_MASK } from '../../../../constants/inputMaskConstants';
import Moment from 'react-moment';
import FlatButton from '../../../../components/FlatButton';
import MaskedString from '../../../../components/MaskedString';

const OpenFaxDrawer = () => {
  const dispatch = useAppDispatch();
  const { targetPreviewFax, isOpenEfaxDrawerOpen } = useAppSelector(
    (state) => state.efaxReducer
  );

  const [iframeData, setIframeData] = useState(null);
  const [isFetcingDocument, setIsFetchingDocument] = useState<boolean>(false);

  useEffect(() => {
    if (!!targetPreviewFax) {
      const fileName = targetPreviewFax?.previewUrl?.split('/')?.pop();

      if (!!fileName) {
        createDocPreviewAction(fileName, setIframeData, setIsFetchingDocument);
      }
    }
  }, [targetPreviewFax]);

  return (
    <Drawer
      anchor={'right'}
      open={isOpenEfaxDrawerOpen}
      onClose={() => dispatch(toggleOpenEfaxDrawer())}
      PaperProps={{
        sx: efaxStyle.faxPreviewDrawerContainer,
      }}
    >
      <Box sx={efaxStyle.faxPreviewDrawerContainer.contentWrapper}>
        <Box>
          <Box sx={efaxStyle.sendEfaxDrawerContainer.contentWrapper.header}>
            <Box>
              <Typography
                variant='h5'
                sx={efaxStyle.sendEfaxDrawerContainer.contentWrapper.title}
              >
                {/* Fax Preview */}
                Fax
              </Typography>
            </Box>
            <IconButton onClick={() => dispatch(toggleOpenEfaxDrawer())}>
              <Close />
            </IconButton>
          </Box>
          <Box>
            <Box
              style={{ display: 'flex', alignItems: 'center', marginTop: 8 }}
            >
              <Typography
                component={'div'}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {/* Fax Preview */}
                <b>Status:</b>{' '}
                {targetPreviewFax?.eFaxStatus === 'success' ? (
                  <>
                    <Check
                      style={{
                        fontSize: '1.2rem',
                        margin: '0 4px',
                        color: '#4CAF50',
                      }}
                    />
                    Sent successfully,
                  </>
                ) : (
                  <>
                    <WarningAmber
                      style={{
                        fontSize: '1.2rem',
                        margin: '0 4px',
                        color: '#F57F17',
                      }}
                    />
                    Failed (Bad Fax Number),
                  </>
                )}
                <Moment
                  style={{ marginLeft: 4 }}
                  calendar={{
                    lastDay: '[Yesterday] LT',
                    sameDay: '[Today] LT',
                    lastWeek: 'MMM DD, YYYY hh:mm A',
                    sameElse: 'MMM DD, YYYY hh:mm A',
                  }}
                >
                  {targetPreviewFax?.createdOn}
                </Moment>
              </Typography>
              <Typography style={{ marginLeft: '1rem' }}>
                {/* Fax Preview */}
                <b>Number:</b>
                <MaskedString
                  mask={MOBILE_NUMBER_US_WITH_CODE_MASK}
                  value={targetPreviewFax?.senderFaxNumber}
                  customStyle={{
                    fontSize: '1rem',
                    marginLeft: '0.5rem',
                  }}
                />
              </Typography>
            </Box>
            {!!targetPreviewFax?.previewUrl &&
            targetPreviewFax?.eFaxStatus !== 'failed' ? (
              <Box
                sx={efaxStyle.faxPreviewDrawerContainer.filePreviewContainer}
              >
                {iframeData && !isFetcingDocument ? (
                  <object
                    width='100%'
                    height='100%'
                    type='application/pdf'
                    data={`${iframeData}#zoom=85&scrollbar=0&toolbar=0&navpanes=0`}
                  >
                    <p>Failed to Load PDF Document</p>
                  </object>
                ) : (
                  <CircularProgress size='3rem' />
                )}
              </Box>
            ) : (
              <Box sx={efaxStyle.deliverFailedMsg}>
                <Box>
                  <ErrorOutline
                    color='primary'
                    sx={efaxStyle.deliverFailedMsg.errorIcon}
                  />
                </Box>
                <Box>
                  <Typography sx={efaxStyle.deliverFailedMsg.highlightMsg}>
                    e-Fax delivery failed
                  </Typography>
                </Box>
                <Box>
                  <Typography sx={efaxStyle.deliverFailedMsg.secondaryMsg}>
                    Unfortunately, we were not able to deliver your e-Fax.
                  </Typography>
                </Box>
                <Box>
                  <Typography>
                    There is a technical issue that is preventing this e-Fax
                    from being delivered. Our support team has been notified and
                    we will update you via email when the issue is resolved.
                    This generally takes 24 - 72 hours.
                  </Typography>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
        <Box
          sx={
            efaxStyle.faxPreviewDrawerContainer.contentWrapper.actionBtnsWrapper
          }
        >
          <FlatButton
            variant='outlined'
            onClick={() => {
              dispatch(toggleOpenEfaxDrawer());

              if (!targetPreviewFax) {
                dispatch(toggleSendEfaxDrawer());
              }
            }}
          >
            {!!targetPreviewFax ? 'Close' : 'Close preview'}
          </FlatButton>
        </Box>
      </Box>
    </Drawer>
  );
};

export default OpenFaxDrawer;
