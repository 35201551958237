import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CloseIcon from '@mui/icons-material/Close';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import {
  Box,
  CircularProgress,
  Divider,
  IconButton,
  Tooltip,
} from '@mui/material';
import { extensionsNotForPreview } from 'constants/documentsConstants';
import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { TEST_CONSTANTS } from '../../../../constants/testConstants';
import localStorageHelper from '../../../../helpers/localStorageHelper';
import {
  createDocPreviewAction,
  downloadAttachmentAction,
  downloadDocumentByGuestTokenAction,
  previewDocumentByGuestTokenAction,
} from '../../../../store/actions/dashboardActions/documentActions';
import { documentStyle } from '../../../../style/dashboardStyles/documentStyle';
import {
  FileTypes,
  TableDetailsAttachment,
} from '../../../../types/documentsTypes';
import { getFileType } from 'helpers/downloadHelper';

const DocumentPreview = ({
  attachments,
  selectedRecordIndex,
  setSelectedRecordIndex,
  blobType,
}: {
  attachments: TableDetailsAttachment[];
  selectedRecordIndex: number | null;
  setSelectedRecordIndex: React.Dispatch<React.SetStateAction<number | null>>;
  blobType?: number | null;
}) => {
  const [iframeData, setIframeData] = useState(null);
  const [isFetchingDocument, setIsFetchingDocument] = useState<boolean>(false);
  const [isDocumentLoadedInUI, setIsDocumentLoadedInUI] =
    useState<boolean>(false);
  const token = localStorageHelper.getAuthToken();
  const { token: guestToken } = useParams();
  const fileExtensions = useMemo(
    () =>
      attachments.map((attachment) =>
        attachment.fileName.split('.').pop()!.toLowerCase()
      ),
    [attachments]
  );

  const getFileExtensions = (
    position: 'back' | 'next',
    recordIndex: number
  ): string[] => {
    return recordIndex
      ? position === 'back'
        ? recordIndex < 0 || recordIndex >= fileExtensions.length
          ? []
          : fileExtensions.slice(0, recordIndex)
        : recordIndex < 0 || recordIndex >= fileExtensions.length - 1
        ? []
        : fileExtensions.slice(recordIndex)
      : [];
  };

  // set previous records for checking if previous records from
  // the selected record should have preview or not
  const [remainingPreviousAttachmentsExtensions] = useState<string[]>(
    getFileExtensions('back', selectedRecordIndex!)
  );

  // set next records for checking if next records from
  // the selected record should have preview or not
  const [remainingNextAttachmentsExtensions] = useState<string[]>(
    getFileExtensions('next', selectedRecordIndex!)
  );

  const [isBackButtonDisabled, setIsBackButtonDisabled] = useState<boolean>(
    selectedRecordIndex! === 0
  );
  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState<boolean>(
    selectedRecordIndex! === attachments.length - 1
  );
  const onClickArrow = (requestFor: 'back' | 'next') => {
    setIsDocumentLoadedInUI(false);

    const isSupportedFileType = (file: string) => {
      const fileType = getFileType(file);

      return fileType === FileTypes.IMAGE || fileType === FileTypes.PDF;
    };

    if (requestFor === 'next') {
      let nextIndex = selectedRecordIndex! + 1;

      // Skip unsupported file types
      while (nextIndex < attachments.length) {
        if (isSupportedFileType(attachments[nextIndex].fileName)) {
          setSelectedRecordIndex(nextIndex);
          return;
        }
        nextIndex++;
      }
    } else {
      let previousIndex = selectedRecordIndex! - 1;

      // Skip unsupported file types
      while (previousIndex >= 0) {
        if (isSupportedFileType(attachments[previousIndex].fileName)) {
          setSelectedRecordIndex(previousIndex);
          return;
        }
        previousIndex--;
      }
    }
  };

  useEffect(() => {
    setIsBackButtonDisabled(
      selectedRecordIndex! === 0 ||
        (selectedRecordIndex! === 1 &&
          extensionsNotForPreview.includes(
            remainingPreviousAttachmentsExtensions[selectedRecordIndex! - 1]
          ))
    );

    setIsNextButtonDisabled(
      selectedRecordIndex! === attachments.length - 1 ||
        (selectedRecordIndex! === attachments.length - 2 &&
          extensionsNotForPreview.includes(
            remainingPreviousAttachmentsExtensions[selectedRecordIndex! + 1]
          ))
    );
  }, [
    remainingNextAttachmentsExtensions,
    remainingPreviousAttachmentsExtensions,
    selectedRecordIndex,
    attachments,
  ]);

  const onClickDownloadDocument = () => {
    const fileName = attachments[selectedRecordIndex!].attachmentUrl.substring(
      attachments[selectedRecordIndex!].attachmentUrl.lastIndexOf('/') + 1
    );
    if (token) {
      downloadAttachmentAction(
        fileName,
        attachments[selectedRecordIndex!].fileName
      );
    } else {
      downloadDocumentByGuestTokenAction(
        guestToken!,
        fileName,
        attachments[selectedRecordIndex!].fileName,
        blobType
      );
    }
  };

  useEffect(() => {
    const fileName = attachments[selectedRecordIndex!].attachmentUrl.substring(
      attachments[selectedRecordIndex!].attachmentUrl.lastIndexOf('/') + 1
    );

    if (token) {
      createDocPreviewAction(fileName, setIframeData, setIsFetchingDocument);
    } else {
      previewDocumentByGuestTokenAction(
        guestToken!,
        fileName,
        setIframeData,
        setIsFetchingDocument,
        blobType
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRecordIndex, attachments]);

  const renderPreview = () => {
    if (selectedRecordIndex! < attachments.length) {
      const currentFile = attachments[selectedRecordIndex!];
      const fileExtension = currentFile.fileName
        .split('.')
        .pop()
        ?.toLowerCase();

      if (fileExtension === 'pdf') {
        return (
          <object
            width='100%'
            height='100%'
            type='application/pdf'
            data={`${iframeData}#zoom=85&scrollbar=0&toolbar=0&navpanes=0`}
            onLoad={() => setIsDocumentLoadedInUI(true)}
          >
            <p>Failed to Load PDF Document</p>
          </object>
        );
      } else if (
        fileExtension === 'png' ||
        fileExtension === 'jpg' ||
        fileExtension === 'jpeg' ||
        fileExtension === 'webp'
      ) {
        return (
          <img
            src={iframeData!}
            alt={attachments[selectedRecordIndex!].fileName}
            onLoad={() => setIsDocumentLoadedInUI(true)}
          />
        );
      } else {
        selectedRecordIndex! < attachments.length - 1 && onClickArrow('next');
      }
    } else return null;
  };

  return (
    <Box
      sx={documentStyle.docPreview.docPreviewMainContainer}
      data-testid={TEST_CONSTANTS.DOCUMENT_PREVIEW_CONTAINER}
    >
      {/* doc preview header starts here */}
      <Box sx={documentStyle.docPreview.docPreviewHeader}>
        <Tooltip title='Download this file'>
          <IconButton
            data-testid={TEST_CONSTANTS.DOCUMENT_PREVIEW_DOWNLOAD_BUTTON}
            onClick={onClickDownloadDocument}
          >
            <FileDownloadIcon />
          </IconButton>
        </Tooltip>

        <Divider orientation='vertical' sx={documentStyle.docPreview.divider} />
        <Tooltip title='Close preview'>
          <IconButton
            data-testid={TEST_CONSTANTS.DOCUMENTS_PREVIEW_CLOSE_BUTTON}
            onClick={() => setSelectedRecordIndex(null)}
          >
            <CloseIcon />
          </IconButton>
        </Tooltip>
      </Box>

      {/* doc preview container starts here */}
      <Box sx={documentStyle.docPreview.previewContentContainer}>
        <IconButton
          data-testid={TEST_CONSTANTS.DOCUMENTS_PREVIEW_BACK_ARROW}
          sx={documentStyle.docPreview.navigationArrowButton}
          disabled={isBackButtonDisabled}
          onClick={() => selectedRecordIndex! > 0 && onClickArrow('back')}
        >
          <ArrowBackIosNewIcon />
        </IconButton>

        {iframeData && !isFetchingDocument ? (
          <Box sx={documentStyle.docPreview.docPreviewContainer}>
            {renderPreview()}
            {isDocumentLoadedInUI && attachments[selectedRecordIndex!].fileName}
          </Box>
        ) : (
          <CircularProgress size='2rem' sx={{ color: '#FFF' }} />
        )}

        <IconButton
          data-testid={TEST_CONSTANTS.DOCUMENTS_PREVIEW_NEXT_ARROW}
          sx={documentStyle.docPreview.navigationArrowButton}
          disabled={isNextButtonDisabled}
          onClick={() =>
            selectedRecordIndex! < attachments.length - 1 &&
            onClickArrow('next')
          }
        >
          <ArrowForwardIosIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default DocumentPreview;
