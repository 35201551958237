import { Box, MenuItem, Select, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { paginationLimitOptions } from '../constants/paginationConstants';
import { TEST_CONSTANTS } from '../constants/testConstants';
import { PaginationReducer } from '../store/reducers/paginationReducer';
import { PAGINATION_REDUX_CONSTANTS } from '../store/reduxConstants/paginationReduxConstants';
import { RootState } from '../store/store';
import { paginationStyle } from '../style/paginationStyle';
import { ListPaginatioProps } from '../types/Misc';
import FlatButton from './FlatButton';

const Pagination = <T,>({
  total,
  list,
  setPaginatedList,
}: ListPaginatioProps<T>) => {
  const dispatch = useAppDispatch();
  const { currentPage = 1, itemsPerPage = 10 } = useAppSelector(
    (state: RootState) => state.paginationReducer as PaginationReducer
  );
  const totalPages = Math.ceil(total / itemsPerPage);

  const onPageChange = (page: number) => {
    dispatch({
      type: PAGINATION_REDUX_CONSTANTS.UPDATE_CURRENT_PAGE,
      payload: page,
    });
  };

  useEffect(() => {
    // get paginated records
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    setPaginatedList(list.slice(startIndex, endIndex));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, itemsPerPage, list]);

  const [displayRange] = useState(3);

  // Calculate the range of pages to display
  let startPage = Math.max(1, currentPage - displayRange);
  let endPage = Math.min(totalPages, currentPage + displayRange);

  // Adjust the range if we're near the start or end of the pagination
  if (currentPage - startPage < displayRange) {
    endPage = Math.min(totalPages, startPage + displayRange * 2);
  }
  if (endPage - currentPage < displayRange) {
    startPage = Math.max(1, endPage - displayRange * 2);
  }

  // Generate the pagination links
  const pageLinks = [];
  for (let i = startPage; i <= endPage; i++) {
    pageLinks.push(
      <FlatButton
        sx={paginationStyle.pageButton}
        variant={currentPage === i ? 'contained' : 'outlined'}
        key={i}
        onClick={() => onPageChange(i)}
      >
        {i}
      </FlatButton>
    );
  }
  const onChangeItemsPerPage = (pageLimit: number) => {
    dispatch({
      type: PAGINATION_REDUX_CONSTANTS.UPDATE_ITEMS_PER_PAGE,
      payload: pageLimit,
    });
    // reset current page when page limit changes
    dispatch({ type: PAGINATION_REDUX_CONSTANTS.RESET_CURRENT_PAGE });
  };
  return list?.length ? (
    <Box sx={paginationStyle.paginationContainer}>
      <Typography variant='caption'>Records per page</Typography>
      <Select
        value={itemsPerPage}
        sx={paginationStyle.limitDropdown}
        onChange={(event) => onChangeItemsPerPage(event.target.value as number)}
        inputProps={{
          'data-testid': TEST_CONSTANTS.PAGINATION_RECORDS_PER_PAGE_SELECT,
        }}
      >
        {paginationLimitOptions.map((pageLimit: number) => (
          <MenuItem key={pageLimit} value={pageLimit}>
            {pageLimit}
          </MenuItem>
        ))}
      </Select>
      <FlatButton
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1}
      >
        Prev
      </FlatButton>
      {startPage > 1 && (
        <>
          <FlatButton
            variant={currentPage === startPage ? 'contained' : 'outlined'}
            sx={paginationStyle.pageButton}
            onClick={() => onPageChange(1)}
          >
            1
          </FlatButton>
          {startPage > 2 && <span>...</span>}
        </>
      )}
      {pageLinks}
      {endPage < totalPages && (
        <>
          {endPage < totalPages - 1 && <span>...</span>}
          <FlatButton
            variant={currentPage === endPage ? 'contained' : 'outlined'}
            sx={paginationStyle.pageButton}
            onClick={() => onPageChange(totalPages)}
          >
            {totalPages}
          </FlatButton>
        </>
      )}
      <FlatButton
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
      >
        Next
      </FlatButton>
    </Box>
  ) : (
    <></>
  );
};

export default Pagination;
