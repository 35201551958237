/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Switch, Typography } from '@mui/material';
import { ChangeEvent, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import FlatButton from '../../../components/FlatButton';
import localStorageHelper from '../../../helpers/localStorageHelper';
import {
  setUserInfo,
  updateNotificationSettings,
} from '../../../store/actions/userActions';
import { profileStyle } from '../../../style/profileStyle';
import { UserInfo } from '../../../store/reduxConstants/userReduxConstants';

export const Preferences = () => {
  const dispatch = useAppDispatch();
  const localUserInfo = localStorageHelper.getUserInfo();
  const { userInfo, id, isUpdatingNotificationSettings } = useAppSelector(
    (state) => ({
      id: state.userReducer.id,
      userInfo: state.userReducer.userInfo,
      isUpdatingNotificationSettings:
        state.generalLoaderReducer?.isUpdatingNotificationSettings,
    })
  );

  const handleSMSNotificationsToggle = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(
      setUserInfo(id, {
        ...userInfo,
        isSmsNotificationTurnedOn: event.target.checked,
      } as UserInfo)
    );
  };

  const onSubmitForm = (): void => {
    dispatch(updateNotificationSettings());
  };

  useEffect(() => {
    return () => {
      dispatch(
        setUserInfo(id, {
          ...userInfo,
          isSmsNotificationTurnedOn: localUserInfo?.isSmsNotificationTurnedOn,
        } as UserInfo)
      );
    };
  }, []);

  return (
    <Box sx={profileStyle.form.container}>
      <Box sx={profileStyle.form.textFieldContainer}>
        <Typography variant='body1' sx={{ fontWeight: 800 }}>
          App Notifications
        </Typography>
        {!!userInfo?.mobile && (
          <Box sx={profileStyle.preferences.toggleContainer}>
            <Box sx={profileStyle.preferences.switchContainer}>
              SMS notifications
              {/* TODO: UX Improvement task - 19238 -> disable settings change if no SMS number is registered */}
              <Switch
                onChange={handleSMSNotificationsToggle}
                checked={userInfo?.isSmsNotificationTurnedOn}
              />
            </Box>
            <Typography
              sx={profileStyle.preferences.descriptionText}
              component={'div'}
              variant='body2'
              color={'textSecondary'}
            >
              SMS will be sent to the number saved against your user in the
              drtalk portal. Contact your practice admin to update this.
            </Typography>
          </Box>
        )}
      </Box>
      <FlatButton
        color='primary'
        sx={profileStyle.form.updateButton}
        onClick={onSubmitForm}
        variant='contained'
        isLoading={!!isUpdatingNotificationSettings}
      >
        Update profile
      </FlatButton>
    </Box>
  );
};
