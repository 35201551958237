import {
  ChatBubbleOutline,
  InboxOutlined,
  LanguageOutlined,
  PeopleAltOutlined,
  PortraitOutlined,
  PrintOutlined,
} from '@mui/icons-material';
import { SvgIconTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { TEST_CONSTANTS } from './testConstants';

export type LeftNavigationMenuConstantType = {
  name: string;
  icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & {
    muiName: string;
  };
  route: string;
  testConstant: string;
};

export const leftNavigationMenuConstants: LeftNavigationMenuConstantType[] = [
  {
    name: 'Chat',
    icon: ChatBubbleOutline,
    route: 'chat',
    testConstant: TEST_CONSTANTS.CHAT_MENU,
  },
  {
    name: 'Documents',
    icon: InboxOutlined,
    route: 'documents',
    testConstant: TEST_CONSTANTS.DOCUMENTS_MENU,
  },
  {
    name: 'My Network',
    icon: LanguageOutlined,
    route: 'my-network',
    testConstant: TEST_CONSTANTS.MY_NETWORK_MENU,
  },
  {
    name: 'My Office',
    icon: PeopleAltOutlined,
    route: 'my-office',
    testConstant: TEST_CONSTANTS.MY_OFFICE_MENU,
  },
  {
    name: 'e-Fax',
    icon: PrintOutlined,
    route: 'efax',
    testConstant: TEST_CONSTANTS.EFAX_MENU,
  },
  {
    name: 'Refer a patient',
    icon: PortraitOutlined,
    route: 'refer-a-patient',
    testConstant: TEST_CONSTANTS.REFER_A_PATIENT_MENU,
  },
];

export const LEFT_NAVIGATION_WIDTH_CONSTANTS = {
  MINIMIZED_WIDTH: '5.5rem',
  EXPANDED_WIDTH: '19.5rem',
};
