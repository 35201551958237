import { ExpandMore } from '@mui/icons-material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Typography,
} from '@mui/material';
import { ChangeEvent, SyntheticEvent, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import FlatButton from '../../../../components/FlatButton';
import {
  addContactsAction,
  deleteRecipientDataAction,
  setSendDocumentDialogStepAction,
  setSendDocumentFormDataAction,
} from '../../../../store/actions/dashboardActions/documentActions';
import { showSnackbarAction } from '../../../../store/actions/snackbarAction';
import { RootState } from '../../../../store/store';
import { addContactsDialogStyle } from '../../../../style/dashboardStyles/addContactsStyle';
import {
  AddContactDialogProps,
  AddContactFormData,
  DocumentReducer,
} from '../../../../types/documentsTypes';
import AddContactForm from './AddContactForm';
import { colorPalette } from '../../../../theme/colorPalette';

const AddNewEmailsToRecipientsDialog = (props: AddContactDialogProps) => {
  const { isDialogOpen, setIsDialogOpen } = props;
  const dispatch = useAppDispatch();
  const [expanded, setExpanded] = useState<string | false>(false);
  const { isAddContactLoaderActive } = useAppSelector(
    ({ generalLoaderReducer }: Record<string, any>) =>
      generalLoaderReducer ?? true
  );

  const { recipientsData, sendDocumentFormData } = useAppSelector(
    (state: RootState) => state.documentReducer as DocumentReducer
  );
  const handleChange =
    (panel: string) => (event: SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const [isFormSubmittedOnce, setIsFormSubmittedOnce] =
    useState<boolean>(false);
  const [selectedContacts, setSelectedContacts] = useState<string[]>([]);

  const emailRecipients = sendDocumentFormData?.recipients?.filter(
    (recipient) => recipient.isNewEmailRecipient
  );

  const checkValidation = () => {
    const selectedRecipientsData: AddContactFormData[] = recipientsData.filter(
      (recipient: AddContactFormData) =>
        selectedContacts.includes(recipient.Email)
    );

    return (
      !!selectedContacts.length &&
      selectedRecipientsData.length > 0 &&
      selectedRecipientsData.every((recipient: AddContactFormData) =>
        Object.values(recipient).every((field) => Boolean(field.length))
      )
    );
  };

  const onClickSubmit = () => {
    if (!isFormSubmittedOnce) {
      setIsFormSubmittedOnce(true);
    }

    if (checkValidation()) {
      if (selectedContacts.length) {
        const selectedRecipientsData: AddContactFormData[] =
          recipientsData.filter((recipient: AddContactFormData) =>
            selectedContacts.includes(recipient.Email)
          );

        dispatch(addContactsAction(selectedRecipientsData)).then(() => {
          setIsDialogOpen(false);
          setIsFormSubmittedOnce(false);
          setSelectedContacts([]);
          dispatch(deleteRecipientDataAction());

          const updatedSendDocumentRecipients =
            sendDocumentFormData.recipients.map((recipient) => {
              const isIncludedInSelectedRecipients = selectedContacts.includes(
                recipient.email!
              );

              const targetRecipientData = recipientsData.find(
                (recipientData) => recipientData.Email === recipient.email
              );

              return !!targetRecipientData
                ? {
                    ...recipient,
                    isNewEmailRecipient: isIncludedInSelectedRecipients
                      ? false
                      : recipient.isNewEmailRecipient,
                    type: targetRecipientData ? 'recipient' : '',
                    name: targetRecipientData?.PracticeName,
                  }
                : recipient;
            });

          dispatch(
            setSendDocumentFormDataAction({
              ...sendDocumentFormData,
              recipients: updatedSendDocumentRecipients,
            })
          );

          dispatch(setSendDocumentDialogStepAction(2));
        });
      }
    } else {
      showSnackbarAction({
        message: !selectedContacts.length
          ? 'Please select at least one contact to add'
          : 'Please check the information that you have entered for the selected contact(s)',
        statusCode: 400,
        severity: 'error',
        open: true,
      });
    }
  };

  const onSelectRecipient = (
    event: ChangeEvent<HTMLInputElement>,
    email: string
  ) => {
    event.stopPropagation();
    if (event.target.checked) {
      setSelectedContacts([...selectedContacts, email]);
    } else {
      setSelectedContacts((prev) =>
        prev.filter((selectedEmail) => selectedEmail !== email)
      );
    }
  };

  return (
    <Dialog open={isDialogOpen} sx={addContactsDialogStyle.dialog}>
      <DialogContent>
        <Box
          sx={{
            paddingTop: '2rem',
          }}
        >
          <Typography variant='h6' fontWeight={500} marginBottom={'0.25rem'}>
            Add to your directory?
          </Typography>
          <Typography variant='body2'>
            Would you like to save any of these new email recipients to your
            directory for easy access next time?
          </Typography>
        </Box>
        {emailRecipients.map((recipient, index) => (
          <Accordion
            key={recipient.id}
            sx={addContactsDialogStyle.accordion}
            expanded={expanded === `panel-${index}`}
            onChange={handleChange(`panel-${index}`)}
          >
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls={`panel${index}-content`}
              id={`panel${index}-header`}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    {...{
                      inputProps: {
                        'aria-label': `panel-${index}-checkbox}`,
                      },
                    }}
                    icon={<CircleOutlinedIcon />}
                    checkedIcon={<CheckCircleIcon />}
                    sx={addContactsDialogStyle.checkbox}
                    onChange={(e) => onSelectRecipient(e, recipient.email!)}
                  />
                }
                label={
                  <Typography variant='body2' color='textSecondary'>
                    {recipient.email}
                  </Typography>
                }
              />
            </AccordionSummary>
            <AccordionDetails sx={addContactsDialogStyle.accordionForm}>
              <AddContactForm
                isFormSubmittedOnce={isFormSubmittedOnce}
                formData={{
                  email: recipient.email,
                  name: '',
                }}
              />
            </AccordionDetails>
          </Accordion>
        ))}
      </DialogContent>
      <DialogActions
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          paddingBottom: '2.5rem',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            paddingTop: '0',
            paddingBottom: '1rem',
            gap: '1rem',
          }}
        >
          <FlatButton
            variant='outlined'
            onClick={() => {
              setIsDialogOpen(false);
              setIsFormSubmittedOnce(false);
              setSelectedContacts([]);
            }}
          >
            Back
          </FlatButton>
          <FlatButton
            variant='contained'
            onClick={onClickSubmit}
            isLoading={isAddContactLoaderActive}
          >
            Add to directory
          </FlatButton>
        </Box>
        <Typography
          sx={{
            textDecoration: 'underline',
            cursor: 'pointer',
            color: colorPalette.primaryMain,
          }}
          fontWeight={500}
          onClick={() => {
            setIsDialogOpen(false);
            setIsFormSubmittedOnce(false);
            //   setSelectedContacts([]);
            dispatch(setSendDocumentDialogStepAction(2));
          }}
        >
          Skip adding and proceed to next step
        </Typography>
      </DialogActions>
    </Dialog>
  );
};

export default AddNewEmailsToRecipientsDialog;
