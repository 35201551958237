export const loaderOverlayStyle = {
  container: {
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    height: '100vh',
    width: '100vw',
    position: 'absolute',
    top: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};
