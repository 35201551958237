import { createApi, FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import moment from 'moment';
import { imageFileExtensions } from '../constants/documentsConstants';

import { CHAT_URLS } from '../constants/urlConstants';
import {
  channelToCreateChannel,
  ChatChannel,
  ChatMessage,
  ChatMessageAttachmentFile,
  ChatSearch,
  ChatSearchUser,
  ChatUser,
  ChatMessageMetaDictionary,
  searchChatToChannel,
  searchUserToUser,
} from '../types/chatTypes';
import baseQueryWithReauth from './serviceQuery';

//using RTK query rather than Redux boilerplate to enable caching etc.,
//also handles multiple request collisions out of the box

export enum MarkChannel {
  delivered = 'delivered',
  read = 'read',
}

export const CHANNELS_PAGE_SIZE = 30;
export const MESSAGES_PAGE_SIZE = 30;

type MessagesResponse = {
  items: ChatMessage[];
  continuationToken?: string;
};

const chatService = createApi({
  reducerPath: 'chatApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (build) => ({
    getChannels: build.query<
      {
        items: ChatChannel[];
        continuationToken?: string;
      },
      { continuationToken?: string }
    >({
      query: ({ continuationToken }) => ({
        url: CHAT_URLS.CHANNELS_URL,
        params: {
          //only sets continuationToken if not null or undefined
          ...(continuationToken && { continuationToken }),
          pageSize: CHANNELS_PAGE_SIZE,
        },
      }),
    }),
    getChannel: build.query<
      {
        channel: ChatChannel;
      },
      { channelId: string }
    >({
      query: ({ channelId }) => ({
        url: CHAT_URLS.CHANNELS_URL + '/' + channelId,
      }),
    }),
    createChannel: build.mutation<
      ChatChannel,
      { channel: ChatChannel; message?: ChatMessage }
    >({
      query: ({ channel, message }) => ({
        url: CHAT_URLS.CHANNELS_URL,
        method: 'POST',
        body: channelToCreateChannel(channel),
      }),
    }),
    markChannel: build.mutation<
      { timestamp: number },
      { mark: MarkChannel; channelId: string }
    >({
      query: ({ mark, channelId }) => ({
        url: CHAT_URLS.MARK_CHANNEL_URL.replace('CHANNELID', channelId).replace(
          'MARK',
          mark.valueOf()
        ),
        method: 'PUT',
      }),
    }),
    getMessages: build.query<
      MessagesResponse,
      { continuationToken?: string; channelId?: string }
    >({
      query: ({ continuationToken, channelId }) => ({
        url: CHAT_URLS.MESSAGES_URL,
        params: {
          //only sets continuationToken if not null or undefined
          ...(continuationToken && { continuationToken }),
          channelId,
          pageSize: MESSAGES_PAGE_SIZE,
        },
      }),
    }),
    newMessage: build.mutation<ChatMessage, ChatMessage>({
      query: (payload) => ({
        url: CHAT_URLS.MESSAGES_URL,
        method: 'POST',
        body: payload,
      }),
    }),
    postMessages: build.mutation<
      { channels: ChatChannel[]; messages: ChatMessage[] },
      { users: ChatUser[]; messages: ChatMessage[] }
    >({
      query: ({ users, messages }) => ({
        url: CHAT_URLS.POST_MESSAGES_URL,
        method: 'POST',
        body: {
          userIds: users.map((u) => u.userId),
          messages: messages.map((m) => ({
            messageText: m.messageText,
            messageType: m.messageType,
            metaDictionary: { ...m.metaDictionary },
          })),
        },
      }),
    }),
    deleteMessage: build.mutation<
      { isSuccess: boolean },
      { message: ChatMessage; all?: boolean }
    >({
      query: ({ message, all }) => ({
        url: CHAT_URLS.DELETE_MESSAGES_URL.replace(
          'CHANNELID',
          message.channelId
        ).replace('MESSAGEID', message.id),
        params: { type: all ? 0 : 1 },
        method: 'DELETE',
      }),
    }),
    searchUsers: build.query<
      ChatUser[],
      {
        searchTerm?: string;
        includeTeamMembers?: boolean;
        connectedOnly?: boolean;
      }
    >({
      query: ({
        searchTerm,
        includeTeamMembers = true,
        connectedOnly = true,
      }) => ({
        url: CHAT_URLS.SEARCH_USER_URL,
        params: {
          searchTerm,
          includeTeamMembers,
          connectedOnly,
        },
      }),
      transformResponse: (response: ChatSearchUser[]) =>
        response
          ?.map((su) => searchUserToUser(su))
          .sort((a, b) =>
            (a.name ?? '')
              .toLowerCase()
              .localeCompare((b.name ?? '').toLowerCase())
          ),
    }),
    searchChat: build.query<
      {
        items?: ChatChannel[];
      },
      { searchTerm: string | null; userId?: string }
    >({
      query: ({ searchTerm, userId }) => ({
        url: CHAT_URLS.SEARCH_CHAT_URL,
        params: {
          keyword: searchTerm,
        },
        cache: 'no-cache',
      }),
      transformResponse: (
        response: {
          messagePreviews: ChatSearch[];
        },
        meta,
        arg
      ) => ({
        items: response?.messagePreviews?.map((sc) =>
          searchChatToChannel(sc, arg.searchTerm, arg.userId)
        ),
        searchTerm: arg.searchTerm,
      }),
    }),
    createUpload: build.mutation<
      { data: ChatMessageMetaDictionary },
      { message: ChatMessage; file: File }
    >({
      queryFn: async ({ message, file }, queryApi, extraOptions, baseQuery) => {
        //add a unique identifier to the file name
        const fileNameSegments = file.name.split('.');
        const extension = fileNameSegments.pop() ?? '';
        fileNameSegments.push((moment().unix() % 10000).toString());
        const fileName = [...fileNameSegments, extension].join('.');
        const data = {} as ChatMessageMetaDictionary;

        const isImage = imageFileExtensions.includes(extension.toLowerCase());

        //if this is an image then create a thumbnail
        // following commented as currently not creating separate thumbnail.
        // keep for future implementation.
        // if (isImage) {
        //   const thumbnailFileName = [
        //     ...fileNameSegments,
        //     'thumbnail',
        //     extension,
        //   ].join('.');
        //   let uploadThumbnailFile = new File([file], thumbnailFileName, {
        //     type: file.type,
        //   });
        //   //could use js to create thumbnail instead of generate thumbnail endpoint?
        //   //eg., uploadThumbnailFile = createThumbnailFile(uploadThumbnailFile);
        //   const thumbnailFormData: FormData = new FormData();
        //   thumbnailFormData.append('file', uploadThumbnailFile);
        //   thumbnailFormData.append('fileName', thumbnailFileName);
        //   // use this if the generate thumbnail endpoint is working
        //   // const thumbnailResponse = await baseQuery({
        //   //   url: CHAT_URLS.GENERATE_THUMBNAIL_URL,
        //   //   method: 'POST',
        //   //   body: thumbnailFormData,
        //   // });
        //   const thumbnailResponse = await baseQuery({
        //     url: CHAT_URLS.UPLOAD_FILE_URL,
        //     method: 'POST',
        //     body: thumbnailFormData,
        //   });
        //   if (thumbnailResponse.error)
        //     return { error: thumbnailResponse.error as FetchBaseQueryError };
        //   data.attachmentThumbnailName = thumbnailFileName;
        //   data.attachmentThumbnail = (
        //     thumbnailResponse.data as { url: string }
        //   ).url;
        // }

        const uploadFile = new File([file], fileName, {
          type: file.type,
        });
        const formData: FormData = new FormData();
        formData.append('file', uploadFile);
        formData.append('fileName', fileName);
        const response = await baseQuery({
          url: CHAT_URLS.UPLOAD_FILE_URL,
          method: 'POST',
          body: formData,
        });
        if (response.error)
          return { error: response.error as FetchBaseQueryError };
        data.attachmentName = file.name;
        data.attachmentUri = (response.data as { url: string }).url;

        //for now use same image for thumbnail - remove when thumbnail is working
        if (isImage) {
          data.attachmentThumbnailName = file.name;
          data.attachmentThumbnail = data.attachmentUri;
        }

        return { data: { data } };
      },
    }),
    getAttachmentFile: build.query<
      {
        objectURL: string;
      },
      { file: ChatMessageAttachmentFile; blobType?: string }
    >({
      query: ({ file, blobType }) => {
        const fileName = file.fileName ?? '';
        return {
          url: CHAT_URLS.DOWNLOAD_FILE_URL + '/' + fileName,
          params: {
            blobType: blobType ?? 0,
          },
          responseType: 'blob',
          cache: 'no-cache',
          responseHandler: async (response) => {
            return { objectURL: URL.createObjectURL(await response.blob()) };
          },
        };
      },
    }),
    resetService: build.mutation({
      //reset will reset the store through the slice
      queryFn: (arg: void) => Promise.resolve({ data: {} }),
      async onQueryStarted(arg, { dispatch }) {
        //clear the cache
        dispatch(chatService.util.resetApiState());
        //revoke all file objectURLs - todo
      },
    }),
  }),
});

export const {
  useGetChannelsQuery,
  useCreateChannelMutation,
  useMarkChannelMutation,
  useGetMessagesQuery,
  useNewMessageMutation,
  useDeleteMessageMutation,
  usePostMessagesMutation,
  useSearchUsersQuery,
  useSearchChatQuery,
  useCreateUploadMutation,
  useResetServiceMutation,
  useGetAttachmentFileQuery,
} = chatService;

export const { getChannel, searchUsers, searchChat, getAttachmentFile } =
  chatService.endpoints;

export default chatService;
