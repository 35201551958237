import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import './index.scss';
import reportWebVitals from './reportWebVitals';
// Import react slick css files
import { registerLicense } from '@syncfusion/ej2-base';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const container = document.getElementById('root')!;
const root = createRoot(container);

registerLicense(
  'NRAiBiAaIQQuGjN/V0R+Xk9NfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hSn5bd0NhWHpadHFXRmhU'
);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
serviceWorkerRegistration.register();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
