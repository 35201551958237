import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import {
  USER_STATUS,
  userAvailability,
  UserStatus,
  UserWorkStatusCode,
} from '../../constants/userConstants';
import userWorkStatusService from '../../services/userWorkStatusService';

type userWorkStatusStateType = {
  hub: { connected: boolean };
  loggedUserWorkStatus?: UserStatus;
};

const initialNotificationState: userWorkStatusStateType = {
  hub: { connected: false },
  loggedUserWorkStatus: USER_STATUS.UNSET,
};

const userWorkStatusSlice = createSlice({
  name: 'userWorkStatus',
  initialState: initialNotificationState,
  reducers: {
    setLoggedUserWorkStatus: (
      state: userWorkStatusStateType,
      { payload: userWorkStatus }: { payload: UserWorkStatusCode }
    ) => {
      state.loggedUserWorkStatus = userAvailability[userWorkStatus];
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(
        userWorkStatusService.endpoints.connectToUserWorkStatusHub
          .matchFulfilled,
        userWorkStatusService.endpoints.disconnectHub.matchFulfilled
      ),
      (state, { payload: { connected } }) => {
        state.hub.connected = connected;
      }
    );
  },
});

export const { setLoggedUserWorkStatus } = userWorkStatusSlice.actions;

export default userWorkStatusSlice.reducer;
