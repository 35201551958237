import {
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useMemo, useState } from 'react';
import { ReactComponent as NoDataImage } from '../../../../../src/assets/images/no_document_table_record.svg';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import Pagination from '../../../../components/Pagination';
import localStorageHelper from '../../../../helpers/localStorageHelper';
import { DOCUMENT_REDUX_CONSTANTS } from '../../../../store/reduxConstants/documentsReduxConstants';
import { documentStyle } from '../../../../style/dashboardStyles/documentStyle';
import {
  DocumentsTableDetailsType,
  TableDetailsAttachment,
} from '../../../../types/documentsTypes';
import DocumentPreview from '../documentPreview/DocumentPreview';
import DocumentsTableRow from './DocumentsTableRow';

const DocumentsTableDetails = () => {
  const dispatch = useAppDispatch();
  const token = localStorageHelper.getAuthToken();
  const [selectedPreviewAttachments, setSelectedPreviewAttachments] = useState<
    TableDetailsAttachment[]
  >([]);
  const [selectedPreviewRecordIndex, setSelectedPreviewRecordIndex] = useState<
    number | null
  >(null);
  const [selectedPreviewRecordBlobType, setSelectedPreviewRecordBlobType] =
    useState<number | null>(null);
  const { selectedTab, documentList, paginatedList } = useAppSelector(
    ({ documentReducer }: Record<string, any>) => documentReducer ?? {}
  );
  const { isDocumentTableDetailsLoaderActive } = useAppSelector(
    ({ generalLoaderReducer }: Record<string, any>) =>
      generalLoaderReducer ?? true
  );

  const fileExtension = useMemo(
    () =>
      selectedPreviewAttachments[selectedPreviewRecordIndex!]?.fileName
        ?.split('.')
        ?.pop(),
    [selectedPreviewAttachments, selectedPreviewRecordIndex]
  );

  const setPaginatedList = (list: DocumentsTableDetailsType[]) => {
    dispatch({
      type: DOCUMENT_REDUX_CONSTANTS.UPDATE_PAGINATED_LIST,
      payload: list,
    });
  };

  return (
    <>
      <TableContainer
        component={Paper}
        sx={[
          documentStyle.documentTableContainer,
          {
            height: token ? 'calc(100% - 3.5rem)' : 'unset',
            overflow: token ? 'auto' : 'unset',
          },
        ]}
      >
        <Table
          stickyHeader
          sx={documentStyle.documentTable}
          aria-label='documents-details-table'
        >
          <TableHead>
            <TableRow>
              <TableCell width='35%' component='th' scope='row'>
                PATIENT
              </TableCell>
              <TableCell
                width={token ? '45%' : '65%'}
                component='th'
                scope='row'
              >
                MESSAGE
              </TableCell>
              {token && (
                <TableCell
                  width='20%'
                  component='th'
                  scope='row'
                  align='center'
                >
                  ACTIONS
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {isDocumentTableDetailsLoaderActive ? (
              <TableRow>
                <TableCell
                  colSpan={3}
                  align='center'
                  sx={documentStyle.noDocumentTableImage}
                >
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : !!paginatedList?.length ? (
              <DocumentsTableRow
                setSelectedPreviewAttachments={setSelectedPreviewAttachments}
                setSelectedPreviewRecordIndex={setSelectedPreviewRecordIndex}
                setSelectedPreviewRecordBlobType={
                  setSelectedPreviewRecordBlobType
                }
              />
            ) : (
              <TableRow>
                <TableCell
                  colSpan={3}
                  align='center'
                  sx={documentStyle.noDocumentTableImage}
                >
                  <NoDataImage />
                  <Typography variant='h6' fontWeight={500} marginTop={0.5}>
                    No documents {selectedTab === 0 ? 'received' : 'sent'}
                  </Typography>
                  <Typography component='div' variant='caption' marginTop={0.5}>
                    {selectedTab === 0
                      ? 'Your incoming documents will appear here'
                      : 'Documents you sent will appear here'}
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        setPaginatedList={setPaginatedList}
        total={documentList?.length}
        list={documentList}
      />
      {selectedPreviewRecordIndex !== null && !!fileExtension && (
        <DocumentPreview
          blobType={selectedPreviewRecordBlobType}
          attachments={selectedPreviewAttachments}
          selectedRecordIndex={selectedPreviewRecordIndex}
          setSelectedRecordIndex={setSelectedPreviewRecordIndex}
        />
      )}
    </>
  );
};

export default DocumentsTableDetails;
