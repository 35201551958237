import { PersonOutlined } from '@mui/icons-material';
import {
  Autocomplete,
  Avatar,
  Box,
  Chip,
  ListItem,
  ListItemAvatar,
  ListItemText,
  TextField,
  Typography,
} from '@mui/material';
import { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { EMAIL_ADDRESS_REGEX } from '../../../../constants/regexConstants';
import { TEST_CONSTANTS } from '../../../../constants/testConstants';
import { dashboardApiServices } from '../../../../services/apiServices/dashboardApiServices';
import { setSendDocumentFormDataAction } from '../../../../store/actions/dashboardActions/documentActions';
import { showSnackbarAction } from '../../../../store/actions/snackbarAction';
import { UserReducerState } from '../../../../store/reduxConstants/userReduxConstants';
import { RootState } from '../../../../store/store';
import { sendDocumentsDialogsStyle } from '../../../../style/dashboardStyles/documents/sendDocumentsDialogsStyle';
import { documentStyle } from '../../../../style/dashboardStyles/documentStyle';
import {
  DocumentReducer,
  PracticeOptionUI,
  SearchPracticeResponse,
} from '../../../../types/documentsTypes';

const SendDocumentsSelectRecipientsDropdown = () => {
  const dispatch = useAppDispatch();

  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [searchKeyword, setSearchedKeyword] = useState<string>('');
  const [searchPracticeResults, setSearchPracticeResults] = useState<
    SearchPracticeResponse | undefined
  >(undefined);

  const { isSendDocumentsLoaderActive } = useAppSelector(
    ({ generalLoaderReducer }: Record<string, any>) =>
      generalLoaderReducer ?? true
  );
  const { sendDocumentFormData } = useAppSelector(
    (state: RootState) => state.documentReducer as DocumentReducer
  );
  const { userInfo } = useAppSelector(
    (state: RootState) => state.userReducer as UserReducerState
  );
  const addressBookPopperRef = useRef<Element>(null);

  const transformedPracticeSearchResults = useMemo(() => {
    let result: PracticeOptionUI[] = [];
    const isValidEmailKeyword = searchKeyword
      .toLowerCase()
      .match(EMAIL_ADDRESS_REGEX);
    const hasRecipientsResults = !!searchPracticeResults?.Recipients?.length;
    const hasPracticeResults = !!searchPracticeResults?.Practices?.length;

    if (!!searchKeyword && !!searchPracticeResults) {
      const isUnknownEmail =
        !!isValidEmailKeyword && !hasRecipientsResults && !hasPracticeResults;

      if (isUnknownEmail) {
        result = [
          {
            id: searchKeyword,
            name: searchKeyword,
            email: searchKeyword,
            isConnectedPractice: false,
            isNewEmailRecipient: true,
          },
        ];
      } else {
        const practiceOptions: PracticeOptionUI[] = [];
        const filteredPractices = searchPracticeResults?.Practices?.filter(
          (practice) => practice.id !== userInfo?.practiceId
        );

        filteredPractices?.forEach((practice) => {
          const isConnectedPractice = practice.MyNetwork?.some(
            (network) => network.orgID === userInfo?.practiceId
          );

          const option = {
            id: practice.id,
            name: !!isValidEmailKeyword ? searchKeyword : practice.Name,
            category: practice.Category,
            orgType: practice.OrganizationType,
            isConnectedPractice: isConnectedPractice ?? false,
            isNewEmailRecipient: false,
            searchedEmailPracticeName: !!isValidEmailKeyword
              ? practice.Name
              : '',
          };

          practiceOptions.push(option);
        });

        searchPracticeResults?.Recipients.forEach((recipient) => {
          const option = {
            id: recipient.id,
            name: recipient.PracticeName,
            email: recipient.Email,
            isConnectedPractice: true,
            isNewEmailRecipient: false,
          };

          practiceOptions.push(option);
        });

        result = practiceOptions;
      }
    }

    return result;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchKeyword, searchPracticeResults]);

  useEffect(() => {
    if (searchKeyword.length >= 3) {
      const timeout = setTimeout(async () => {
        try {
          const response =
            await dashboardApiServices.myNetwork.searchPracticeByNameOrEmail({
              searchTerm: searchKeyword,
            });

          if (response.status === 200) {
            setSearchPracticeResults(response.data);
          } else {
            setSearchPracticeResults(undefined);
          }
        } catch (e) {
          showSnackbarAction({
            message: 'Something went wrong. Please try again later.',
            statusCode: 400,
            severity: 'error',
            open: true,
          });
        } finally {
          setIsSearching(false);
        }
      }, 1500);

      return () => clearTimeout(timeout);
    }
  }, [searchKeyword]);
  return (
    <Autocomplete
      ref={addressBookPopperRef}
      data-testid={
        TEST_CONSTANTS.SEND_DOCUMENTS_DIALOG_LEFT_PART_PRACTICE_FIELD
      }
      multiple
      disabled={isSendDocumentsLoaderActive}
      disableClearable
      freeSolo
      limitTags={7}
      getLimitTagsText={(more) => (
        <Typography
          sx={documentStyle.sendDocumentsDialog.body.leftPart.limitTag}
        >{`+${more} recipient(s)`}</Typography>
      )}
      value={sendDocumentFormData.recipients ?? []}
      options={transformedPracticeSearchResults}
      loading={isSearching}
      loadingText={'Searching recipient(s)...'}
      sx={documentStyle.sendDocumentsDialog.body.leftPart.practiceAutocomplete}
      filterSelectedOptions
      filterOptions={(options, params) => {
        const filtered = options.filter((option) => {
          return (
            option.name
              .toLowerCase()
              .includes(params.inputValue.toLowerCase()) ||
            option.email
              ?.toLowerCase()
              ?.includes(params.inputValue.toLowerCase())
          );
        });

        return filtered;
      }}
      onChange={(_event, newValue) => {
        dispatch(
          setSendDocumentFormDataAction({
            ...sendDocumentFormData,
            recipients: newValue as PracticeOptionUI[],
          })
        );
        setSearchPracticeResults(undefined);
      }}
      onBlur={() => {
        setSearchPracticeResults(undefined);
        setSearchedKeyword('');
      }}
      clearOnBlur
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => {
          const isRegisteredPractice =
            option.isConnectedPractice ||
            (!option.isConnectedPractice && !option.isNewEmailRecipient);

          return (
            <Chip
              {...getTagProps({ index })}
              variant={!!option.isNewEmailRecipient ? 'outlined' : 'filled'}
              label={option.searchedEmailPracticeName || option.name}
              sx={
                (documentStyle.sendDocumentsDialog.body.leftPart?.resultChip,
                isRegisteredPractice
                  ? documentStyle.sendDocumentsDialog.body.leftPart
                      ?.registeredPractice
                  : documentStyle.sendDocumentsDialog.body.leftPart
                      ?.unknownEmail)
              }
            />
          );
        })
      }
      getOptionLabel={(option) => {
        if (typeof option !== 'string') {
          return option?.searchedEmailPracticeName || option?.name;
        } else {
          return option;
        }
      }}
      getOptionDisabled={(option) =>
        sendDocumentFormData.recipients.some(
          (recipient) => recipient.id === option.id
        )
      }
      renderOption={(props, practice: PracticeOptionUI) => {
        const generateInitials = () => {
          const names = practice.name.split(' ');
          const firstInitial = names[0].charAt(0).toUpperCase();
          const secondInitial = names?.[1]?.charAt(0)?.toUpperCase() ?? '';

          return firstInitial + secondInitial;
        };

        const isNewMail =
          practice.name.includes('newMail') &&
          !practice.searchedEmailPracticeName;
        const index = !!props?.id
          ? parseInt(props.id!.split('-option-')[1], 10)
          : undefined;

        return (
          <Box key={practice.id}>
            {index === 0 && (
              <span style={{ padding: '.5rem' }}>Suggested People</span>
            )}
            <ListItem {...props}>
              <ListItemAvatar>
                <Avatar sx={sendDocumentsDialogsStyle.avatar}>
                  {isNewMail ? <PersonOutlined /> : generateInitials()}
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={
                  <Typography variant='body2'>
                    {practice.searchedEmailPracticeName || practice.name}
                  </Typography>
                }
                secondary={
                  <Typography variant='body2'>
                    {practice.searchedEmailPracticeName ? practice.name : ''}
                  </Typography>
                }
              />
            </ListItem>
          </Box>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          required
          label='Recipient(s)'
          placeholder='Enter an email address or start typing a practice of doctors name...'
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              event.preventDefault();
              event.stopPropagation();
            }
          }}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            if (e.target.value.length >= 3) {
              setSearchPracticeResults(undefined);
              setSearchedKeyword(e.target.value);
              setIsSearching(true);
            }
          }}
        />
      )}
    />
  );
};

export default SendDocumentsSelectRecipientsDropdown;
