import _ from 'lodash';
import { Autocomplete, Box, TextField } from '@mui/material';
import { AutocompleteOption } from '../../../../types/Misc';
import { SyntheticEvent, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { statusFilterOptions } from '../../../../constants/documentsConstants';
import { efaxStyle } from '../../../../style/dashboardStyles/efaxStyle';
import {
  onEfaxStatusFilterChangeAction,
  onEfaxTableFilterChangeAction,
} from '../../../../store/actions/dashboardActions/efaxActions';
import {
  EfaxTableDetailsType,
  ReceivedEfaxStatusFilter,
} from '../../../../types/efaxTypes';
import { resetSelectedPageAction } from '../../../../store/actions/paginationAction';

const EfaxFilters = () => {
  const dispatch = useAppDispatch();

  const {
    appliedFilters,
    selectedTab,
    receivedEfaxList,
    sentEfaxList,
    receivedEfaxStatusFilter,
    contactHistory,
  } = useAppSelector((state) => state.efaxReducer);

  const isReceivedTabSelected = selectedTab === 0;

  const onFilterChange = (
    name: string,
    value: string | AutocompleteOption | null
  ) => {
    dispatch(resetSelectedPageAction());
    dispatch(onEfaxTableFilterChangeAction({ name, value }));
  };

  const displayedList: EfaxTableDetailsType[] = useMemo(() => {
    let targetList: EfaxTableDetailsType[] =
      selectedTab === 0 ? receivedEfaxList : sentEfaxList;

    return targetList;
  }, [selectedTab, receivedEfaxList, sentEfaxList]);

  const senderOptions = _.uniqBy(
    displayedList,
    function (fax: EfaxTableDetailsType) {
      return fax?.senderPracticeName?.toLocaleLowerCase();
    }
  )
    ?.map((fax: EfaxTableDetailsType) => {
      if (isReceivedTabSelected) {
        const targetContact = contactHistory?.find(
          (contact) => contact.EFaxNumber === fax.senderFaxNumber
        );

        return targetContact?.PracticeName ?? fax?.senderPracticeName;
      } else {
        return fax?.senderPracticeName;
      }
    })
    ?.filter((sender: string) => sender !== null);

  const recipientOptions = _.uniqBy(
    displayedList,
    function (fax: EfaxTableDetailsType) {
      return fax?.recipientPracticeName?.toLocaleLowerCase();
    }
  )
    ?.map((fax: EfaxTableDetailsType) => {
      if (isReceivedTabSelected) {
        return fax?.recipientPracticeName;
      } else {
        const targetContact = contactHistory?.find(
          (contact) => contact.EFaxNumber === fax.recipientFaxNumber
        );

        return targetContact?.PracticeName ?? fax?.recipientPracticeName;
      }
    })
    ?.filter((recipient: string) => recipient !== null);

  return (
    <Box sx={efaxStyle.filterContainer}>
      <Autocomplete
        clearOnBlur
        sx={efaxStyle.filterAutocomplete}
        options={_.uniq(senderOptions)}
        onChange={(_e: SyntheticEvent, value: string | null) =>
          onFilterChange('sender', value)
        }
        value={appliedFilters.sender}
        renderInput={(params) => (
          <TextField
            {...params}
            size='small'
            label='Sender'
            placeholder='- Select sender -'
          />
        )}
      />
      <Autocomplete
        clearOnBlur
        sx={efaxStyle.filterAutocomplete}
        options={_.uniq(recipientOptions)}
        onChange={(_e: SyntheticEvent, value: string | null) =>
          onFilterChange('recipient', value)
        }
        value={appliedFilters.recipient}
        renderInput={(params) => (
          <TextField
            {...params}
            size='small'
            label='Recipient'
            placeholder='- Select recipient -'
          />
        )}
      />
      {isReceivedTabSelected && (
        <Autocomplete<string>
          clearOnBlur
          sx={efaxStyle.filterAutocomplete}
          options={statusFilterOptions}
          onChange={(
            _e: SyntheticEvent,
            value: string | null | ReceivedEfaxStatusFilter
          ) => {
            dispatch(resetSelectedPageAction());
            dispatch(
              onEfaxStatusFilterChangeAction(value as ReceivedEfaxStatusFilter)
            );
          }}
          value={receivedEfaxStatusFilter}
          renderInput={(params) => (
            <TextField
              {...params}
              size='small'
              label='Status'
              placeholder='- Select status -'
            />
          )}
        />
      )}
    </Box>
  );
};

export default EfaxFilters;
