import { colorPalette } from '../../theme/colorPalette';

export const addContactsDialogStyle = {
  dialog: {
    '.MuiPaper-root.MuiDialog-paper': {
      width: '28rem',
      background: '#fff',
    },
  },
  checkbox: {
    cursor: 'pointer',
    '&.MuiCheckbox-root': {
      display: 'flex',
      justifyContent: 'center',

      input: {
        position: 'unset',
        width: 0,
      },
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: 1,
    paddingTop: '1rem',
  },
  centerAlignedContainer: {
    display: 'flex',
    justifyContent: 'center',
    flex: 1,
  },
  messageSentConfirmation: {
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
    width: 'fit-content',
    ml: '2.5rem',
    mb: '2rem',
    p: '.65rem',
    fontWeight: 400,
    bgcolor: 'primary.light',
    borderRadius: '1.5rem',
    typography: 'caption',
    color: 'primary.main',
    textAlign: 'center',
  },
  addNewRecipientTitleWrapper: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
  },
  addNewRecipientWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    marginTop: '1.5rem',
  },
  title: {
    mb: '1rem',
    typography: 'h6',
  },
  accordion: {
    mt: '1rem',
    bgcolor: colorPalette.surfaceColor[200],
    boxShadow: 'none',
    borderRadius: '.5rem',
    '&.MuiAccordion-root:before': { bgcolor: 'background.paper' },
    '.MuiAccordionSummary-content': {
      display: 'flex',
      alignItems: 'center',
      typography: 'body2',
      borderRadius: '.5rem',
    },
    '&:last-of-type': { borderRadius: '.5rem' },
  },
  accordionForm: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1.5rem',
    '> *': {
      width: 1,
    },
  },
  editableTextField: { bgcolor: 'background.default', borderRadius: 1 },
  submitButton: {
    width: '10rem',
    mt: '1rem',
  },
  closeButton: {
    marginLeft: 'auto',
  },
};
