import { Box, Button, Divider, TextField, Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import FlatButton from 'components/FlatButton';
import SuccessDialog from 'components/SuccessDialog';
import {
  FIRST_AVAILABLE_DOCTOR_OPTION,
  REFERRAL_PATIENT_DETAILS_ERRORS,
  REFERRAL_STEPS,
} from 'constants/referralConstants';
import { ROUTE_CONSTANTS_VARIABLE } from 'constants/routeConstants';
import { REFERRAL_PAGE_TEST_CONSTANTS } from 'constants/testConstants';
import { PRIVATE_BLOB_BASE_URL } from 'constants/urlConstants';
import { generalErrorHandler } from 'helpers/generalErrorHandlerHelper';
import localStorageHelper from 'helpers/localStorageHelper';
import { isValidateEmail } from 'helpers/validationHelper';
import _ from 'lodash';
import moment from 'moment';
import UploadDocuments from 'pages/dashboard/documents/uploadDocuments/UploadDocuments';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useNavigate, useParams } from 'react-router-dom';
import {
  useGuestSendReferralMutation,
  useSendReferralMutation,
} from 'services/officeFilesService';
import {
  useAddNewPatientMutation,
  useGuestAddNewPatientMutation,
} from 'services/patientService';
import { onChangeDocumentsTabAction } from 'store/actions/dashboardActions/documentActions';
import {
  resetReferralState,
  resetReferralStateForPracticeByToken,
  setPatientStepFormErrors,
} from 'store/slices/referralSlice';
import { commonClasses } from 'style/commonClasses';
import { patientDetailsStepStyle } from 'style/dashboardStyles/referralStyle';
import { Thumbnail, UploadedDocument } from 'types/documentsTypes';
import {
  GuestReferralRequest,
  ReferralRequest,
} from 'types/referralTypes/referralRequestType';
import {
  Patient,
  PatientStepFormErrors,
} from 'types/referralTypes/referralTypes';
import ReferralStepItem from '../ReferralStepItem/ReferralStepItem';
import NewPatientDetailsFields from './NewPatientDetailsFields';
import PatientSelectBox from './PatientSelectBox';

const PatientDetailsStep: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const {
    selectedDoctorId,
    selectedPractice,
    selectedExistingPatient,
    loadedReferralFormFileName,
    newPatientDetails,
    patientStepFormErrors: formErrors,
    practiceByToken,
    guestUserStepOneData,
  } = useAppSelector((state) => state.referralReducer);
  const { token: guestToken } = useParams();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const isWebsiteReferral = useMemo(() => guestToken, [guestToken]);
  const [addNewPatientMutation, { isLoading: isAddingNewPatient }] =
    useAddNewPatientMutation();
  const [guestAddNewPatient, { isLoading: isGuestAddingNewPatient }] =
    useGuestAddNewPatientMutation();

  const [sendReferralMutation, { isLoading: isSendingReferral }] =
    useSendReferralMutation();

  const [guestSendReferral, { isLoading: isGuestSendingReferral }] =
    useGuestSendReferralMutation();

  const [comment, setComment] = useState<string>('');
  const [patientEmail, setPatientEmail] = useState<string>('');
  const [isAddNewPatient, setIsAddNewPatient] = useState<boolean>(false);
  const [selectedFiles, setSelectedFiles] = useState<Thumbnail[]>([]);
  const [totalFilesSize, setTotalFilesSize] = useState<number>(0);
  const [uploadedFiles, setUploadedFiles] = useState<UploadedDocument[]>([]);
  const [isShowReferralSuccessDialog, setIsShowReferralSuccessDialog] =
    useState<boolean>(false);

  useEffect(() => {
    if (isWebsiteReferral) setIsAddNewPatient(true);
  }, [isWebsiteReferral]);

  /**
   * Handles the cancellation of a referral.
   * Resets the referral redux state,
   *
   * If the current page is under documents referral page,
   * will navigate user to the main 'DOCUMENTS' - sent tab page.
   *
   * If current page is 'Refer a Patient' page,
   * will proceed back to Step 1 - practice selection
   */
  const handleCancelReferral = (): void => {
    if (isWebsiteReferral) {
      dispatch(resetReferralStateForPracticeByToken());
    }

    if (!isWebsiteReferral) {
      dispatch(onChangeDocumentsTabAction(1));
      navigate(`../${ROUTE_CONSTANTS_VARIABLE.DOCUMENTS}`);
      dispatch(resetReferralState());
    }
  };

  /**
   * Closes the referral success dialog component.
   *
   * If the current page is under documents referral page,
   * will navigate user to the main 'DOCUMENTS' - sent tab page.
   *
   * If current page is 'Refer a Patient' page,
   * will proceed back to Step 1 - practice selection
   */
  const handleCloseReferralSuccessDialog = (): void => {
    setIsShowReferralSuccessDialog(false);

    handleCancelReferral();
  };

  /**
   * Validation checker for fields under Step 3 - patient selection panel
   * Returns a boolean value, 'true' if has error, 'false' if not
   */
  const checkFieldErrors = (): boolean => {
    const {
      REQUIRED_FIRST_NAME,
      REQUIRED_LAST_NAME,
      REQUIRED_EXISTING_PATIENT,
      INVALID_DOB,
      INVALID_DOB_FUTURE,
      INVALID_EMAIL,
    } = REFERRAL_PATIENT_DETAILS_ERRORS;

    let existingPatientError = '';
    let newPatientFirstNameError = '';
    let newPatientLastNameError = '';
    let newPatientDateOfBirthError = '';
    let patientEmailError = '';

    if (isAddNewPatient) {
      const { firstName, lastName, dateOfBirth } = newPatientDetails;

      if (!firstName) {
        newPatientFirstNameError = REQUIRED_FIRST_NAME;
      }

      if (!lastName) {
        newPatientLastNameError = REQUIRED_LAST_NAME;
      }

      if (dateOfBirth === undefined || dateOfBirth === null) {
        newPatientDateOfBirthError = INVALID_DOB;
      } else {
        const currentDate = moment();
        const isFutureDate = dateOfBirth?.isAfter(currentDate);

        if (isFutureDate) {
          newPatientDateOfBirthError = INVALID_DOB_FUTURE;
        }
      }
    } else {
      if (!selectedExistingPatient?.id) {
        existingPatientError = REQUIRED_EXISTING_PATIENT;
      }
    }

    if (!!patientEmail && !isValidateEmail(patientEmail)) {
      patientEmailError = INVALID_EMAIL;
    }

    const generatedErrors: PatientStepFormErrors = {
      existingPatient: existingPatientError,
      newPatientFirstName: newPatientFirstNameError,
      newPatientLastName: newPatientLastNameError,
      newPatientDateOfBirth: newPatientDateOfBirthError,
      patientEmail: patientEmailError,
    };

    dispatch(setPatientStepFormErrors(generatedErrors));

    return _.some(generatedErrors, (value) => !_.isEmpty(value));
  };

  /**
   * Checks first if values of input fields are valid.
   * If valid, will post referral to api.
   * If not, will display error message on invalid fields.
   */
  const handleSendReferral = useCallback(async () => {
    const hasFieldErrors = checkFieldErrors();
    const attachments: any = [
      ...(uploadedFiles?.map((file) => ({
        fileName: file.fileName,
        attachmentUrl: file.attachmentUrl,
      })) || []),
      {
        fileName: loadedReferralFormFileName.substring(
          loadedReferralFormFileName.lastIndexOf('/') + 1
        ),
        attachmentUrl: loadedReferralFormFileName,
        thumbnailUrl: '',
      },
    ];

    if (!hasFieldErrors) {
      let patientToRefer: Patient | undefined;

      if (isAddNewPatient) {
        if (isWebsiteReferral) {
          if (!executeRecaptcha) {
            return;
          } else {
            const formActionName = () => {
              let string = `add_new_patient_${newPatientDetails.firstName}_${newPatientDetails.lastName}`;
              return string.replace(/[^A-Za-z/]/g, '');
            };
            const token = await executeRecaptcha(formActionName());
            await guestAddNewPatient({
              data: {
                firstName: newPatientDetails.firstName,
                lastName: newPatientDetails.lastName,
                dateOfBirth: newPatientDetails.dateOfBirth!.toDate(),
                organizationId: practiceByToken.id,
                email: patientEmail,
              },
              captchaToken: token,
              token: guestToken,
            })
              .unwrap()
              .then((createdPatient) => {
                patientToRefer = createdPatient;
              })
              .catch((e) => generalErrorHandler(e));
          }
        } else {
          await addNewPatientMutation({
            firstName: newPatientDetails.firstName,
            lastName: newPatientDetails.lastName,
            dateOfBirth: newPatientDetails.dateOfBirth!.toDate(),
            email: patientEmail,
          })
            .unwrap()
            .then((createdPatient) => {
              patientToRefer = createdPatient;
            })
            .catch((e) => generalErrorHandler(e));
        }
      } else {
        patientToRefer = selectedExistingPatient!;
      }

      const referralRequestData: ReferralRequest = {
        hasCustomForm: true,
        toPracticeId: selectedPractice?.id!,
        fromDoctorId: localStorageHelper.getUser()!,
        toDoctorId:
          selectedDoctorId === FIRST_AVAILABLE_DOCTOR_OPTION
            ? null
            : selectedDoctorId,
        attachments: attachments,
        referralFileName: loadedReferralFormFileName,
        patientId: patientToRefer!.id,
        notes: comment,
        PatientEmail: patientEmail,
      };
      if (isWebsiteReferral) {
        if (!executeRecaptcha) {
          return;
        } else {
          const formActionName = () => {
            let string = `send_referral_to_${practiceByToken.id}_from_${guestUserStepOneData.email}`;
            return string.replace(/[^A-Za-z/]/g, '');
          };
          const token = await executeRecaptcha(formActionName());
          const guestReferralRequestData: GuestReferralRequest = {
            toPracticeId: practiceByToken.id,
            toDoctorId: selectedDoctorId,
            attachments: attachments,
            notes: comment,
            patientId: patientToRefer!.id,
            guestSenderEmailAddress: guestUserStepOneData.email,
            guestSenderPracticeName: guestUserStepOneData.practiceName,
            captchaToken: token,
          };

          await guestSendReferral({
            data: guestReferralRequestData,
            token: guestToken,
          })
            .unwrap()
            .then(() => {
              setIsShowReferralSuccessDialog(true);
            })
            .catch((e) => generalErrorHandler(e));
        }
      } else {
        await sendReferralMutation(referralRequestData)
          .unwrap()
          .then((res) => {
            setIsShowReferralSuccessDialog(true);
          })
          .catch((e) => generalErrorHandler(e));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    checkFieldErrors,
    uploadedFiles,
    loadedReferralFormFileName,
    isAddNewPatient,
    selectedPractice,
    selectedDoctorId,
    patientEmail,
    comment,
    practiceByToken,
    isWebsiteReferral,
    executeRecaptcha,
    guestAddNewPatient,
    newPatientDetails,
    addNewPatientMutation,
    selectedExistingPatient,
    guestSendReferral,
    sendReferralMutation,
  ]);

  return (
    <ReferralStepItem
      stepNumber={REFERRAL_STEPS.PATIENT_DETAILS}
      title='Patient details'
    >
      <Box
        data-testid={
          REFERRAL_PAGE_TEST_CONSTANTS.REFERRAL_PATIENT_DETAILS_CONTAINER
        }
        sx={patientDetailsStepStyle.container}
      >
        {/* Patient Selection/Creation Panel */}
        <Box>
          <Typography sx={patientDetailsStepStyle.title}>
            Patient details
          </Typography>
          <Typography sx={patientDetailsStepStyle.subtitle}>
            {!isWebsiteReferral ? 'Search, or a' : 'A'}dd a new patient
          </Typography>
          {isAddNewPatient ? (
            <NewPatientDetailsFields
              onCloseForm={() => setIsAddNewPatient(false)}
            />
          ) : (
            <PatientSelectBox
              onClickAddNewPatientBtn={() => setIsAddNewPatient(true)}
            />
          )}
        </Box>
        {/* Documents Selection Panel */}
        <Box>
          <Divider sx={patientDetailsStepStyle.divider} />
          <Typography sx={patientDetailsStepStyle.subheading} textAlign='left'>
            Include patient documents in referral
          </Typography>
          <Box sx={patientDetailsStepStyle.dragAndDropBox}>
            <UploadDocuments
              selectedFiles={selectedFiles}
              setSelectedFiles={setSelectedFiles}
              totalFilesSize={totalFilesSize}
              setTotalFilesSize={setTotalFilesSize}
              setUploadedFiles={setUploadedFiles}
              containerStyle={patientDetailsStepStyle.dragAndDropContainer}
              dragAndDropMessage='Upload Local Document(s)'
              customAdditionalFileMessage='Add additional Local Document(s)'
            />
          </Box>
        </Box>
        {/* Comment Panel */}
        <Box>
          <TextField
            value={comment}
            fullWidth
            multiline
            rows={3}
            sx={patientDetailsStepStyle.commentTextBox}
            variant='outlined'
            label='Comment'
            inputProps={{ maxLength: 100 }}
            onChange={(event) => setComment(event.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <Box sx={patientDetailsStepStyle.commentTextBoxlimitationMsg}>
            Limited to 100 characters
          </Box>
        </Box>
        {/* Patient Email Panel */}
        <Box>
          <TextField
            value={patientEmail}
            sx={
              formErrors?.patientEmail
                ? [
                    commonClasses.fieldError,
                    patientDetailsStepStyle.sendCopyToPatientTextBox,
                  ]
                : patientDetailsStepStyle.sendCopyToPatientTextBox
            }
            helperText={formErrors?.patientEmail}
            variant='outlined'
            label='Send copy to patient'
            onChange={(event) => {
              dispatch(
                setPatientStepFormErrors({
                  ...formErrors,
                  patientEmail: '',
                })
              );

              setPatientEmail(event.target.value);
            }}
            placeholder='Patient Email'
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Box>
        <Box sx={patientDetailsStepStyle.actionBtnContainer}>
          <Button
            sx={patientDetailsStepStyle.cancelButton}
            onClick={handleCancelReferral}
          >
            Cancel
          </Button>
          <FlatButton
            data-testid={
              REFERRAL_PAGE_TEST_CONSTANTS.REFERRAL_PATIENT_DETAILS_SEND_REFERRAL_BUTTON
            }
            sx={[patientDetailsStepStyle.sendReferralButton]}
            onClick={handleSendReferral}
            isLoading={
              isAddingNewPatient ||
              isGuestAddingNewPatient ||
              isSendingReferral ||
              isGuestSendingReferral
            }
            hasShadow
          >
            Send referral
          </FlatButton>
        </Box>
        {isShowReferralSuccessDialog && (
          <SuccessDialog
            title='Success'
            closeButtonText='close'
            message='Your referral has been sent successfully'
            closeButtonAction={handleCloseReferralSuccessDialog}
          />
        )}
      </Box>
    </ReferralStepItem>
  );
};

export default PatientDetailsStep;
