import { Close } from '@mui/icons-material';
import { Box, IconButton, Typography } from '@mui/material';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { TEST_CONSTANTS } from '../../constants/testConstants';
import useCurrentRoute from '../../hooks/useCurrentRoute';
import usePageVisible from '../../hooks/usePageVisible';
import {
  removeNotifications,
  setNotificationsGenerated,
  setPermission,
  setUserId,
} from '../../store/slices/notificationSlice';
import { dashboardStyle } from '../../style/dashboardStyles/dashboardStyle';
import {
  NotificationPermission,
  NotificationType,
  SystemNotification,
} from '../../types/notificationTypes';

const NotificationHandler = () => {
  const pageVisible = usePageVisible();
  const currentRoute = useCurrentRoute();
  const navigate = useNavigate();

  const [showBanner, setShowBanner] = useState<boolean>(false);
  const [notifications, setNotifications] = useState<
    Record<string, Notification>
  >({});

  const dispatch = useAppDispatch();

  const LOGO = '/logo.png';

  const {
    hub: { connected },
    permission,
    notificationList,
  } = useAppSelector((state) => state.notificationReducer ?? {});
  const { id: userId } = useAppSelector((state) => state.userReducer ?? {});

  const requestPermission = () => {
    const checkNotificationPromise = () => {
      try {
        Notification.requestPermission().then();
      } catch (e) {
        return false;
      }
      return true;
    };

    // check if the browser supports notifications
    if (!('Notification' in window)) {
      console.log('This browser does not support notifications.');
    } else if (checkNotificationPromise()) {
      Notification.requestPermission().then((permission) => {
        dispatch(setPermission(permission));
      });
    } else {
      Notification.requestPermission((permission) => {
        dispatch(setPermission(permission));
      });
    }
  };

  const createNotification = (n: SystemNotification) => {
    //create a notification
    if (permission === 'granted') {
      const notification = new Notification(n.title, {
        body: n.body,
        icon: LOGO,
        tag: n.id,
      });
      notifications[n.id] = notification;
      setNotifications(notifications);
      notification.onclose = (e) => {
        const _n = e.target as Notification;
        setNotifications(_.omit(notifications, [_n.tag]));
        dispatch(removeNotifications([_n.tag]));
      };
      notification.onclick = (e) => {
        notification.close();
        window.parent.focus();
        navigate(n.type);
      };
    }
  };

  useEffect(() => {
    setShowBanner(permission !== 'granted' && permission !== 'none');
  }, [permission, setShowBanner]);

  useEffect(() => {
    if (connected) {
      dispatch(setPermission(Notification?.permission));
      dispatch(setUserId(userId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connected, userId]);

  useEffect(() => {
    notificationList
      .filter((n: SystemNotification) => !n.generated)
      .forEach((n: SystemNotification) => {
        //generate the notification if page is not visible or route is not current page
        // will generate notification regardless of route if it is related to efax
        if (
          !pageVisible ||
          currentRoute !== n.type ||
          currentRoute === NotificationType.efax
        )
          createNotification(n);
      });
    dispatch(setNotificationsGenerated());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationList]);

  useEffect(() => {
    //clear all notifications for the current tab if the page is visible
    if (pageVisible) {
      const clearList = notificationList
        .filter((sn: SystemNotification) => sn.type === currentRoute)
        .map((sn: SystemNotification) => sn.id);
      if (clearList.length) {
        Object.entries(notifications ?? {}).forEach(([id, n]) => {
          if (clearList.includes(id)) {
            n.onclose = null;
            n.close();
          }
        });
        //clear the notifications from the map
        setNotifications(_.omit(notifications, clearList));
        dispatch(removeNotifications(clearList));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageVisible, currentRoute]);

  useEffect(() => {
    const cleanup = () => {
      //some browsers don't close orphaned notifications so clear them all
      //when the window closes or reloads
      Object.entries(notifications ?? {}).forEach(([id, n]) => {
        n.onclose = null;
        n.close();
      });
    };
    window.addEventListener('beforeunload', cleanup);
    return () => {
      window.removeEventListener('beforeunload', cleanup);
      //also cleanup when unmounting
      cleanup();
      dispatch(removeNotifications());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      data-testid={TEST_CONSTANTS.NOTIFICATION_BANNER}
      sx={[
        dashboardStyle.notificationBanner,
        {
          height: showBanner ? '2.75rem' : '0rem',
          opacity: showBanner ? 1 : 0,
          transition: 'height 0.3s ease-out, opacity 0.5s ease-out',
        },
      ]}
    >
      <Typography variant='body2'>
        Desktop alerts are disabled.
        {permission === NotificationPermission.denied ? (
          ' To receive alerts, enable notifications for drtalk in the browser.'
        ) : (
          <span onClick={() => requestPermission()}>
            Click here to enable desktop alerts
          </span>
        )}
      </Typography>
      <IconButton
        onClick={() => dispatch(setPermission(NotificationPermission.none))}
        aria-label='close'
      >
        <Close />
      </IconButton>
    </Box>
  );
};
export default NotificationHandler;
