import CheckIcon from '@mui/icons-material/Check';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import FlatButton from '../../../../components/FlatButton';
import EfaxTableActionMenu from './EfaxTableActionMenu';
import DocumentTableThumbnailCarousel from '../../documents/documentTableDetails/DocumentTableThumbnailCarousel';
import Moment from 'react-moment';
import MaskedString from '../../../../components/MaskedString';
import pdfLogo from '../../../../assets/images/pdf.png';
import { documentStyle } from '../../../../style/dashboardStyles/documentStyle';
import { MOBILE_NUMBER_US_WITH_CODE_MASK } from '../../../../constants/inputMaskConstants';
import { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { Check, WarningAmber } from '@mui/icons-material';
import { DOCUMENT_STATUS_DATABASE_VALUES } from '../../../../constants/documentsConstants';
import { commonClasses } from '../../../../style/commonClasses';
import { efaxStyle } from '../../../../style/dashboardStyles/efaxStyle';
import { TableDetailsAttachment } from '../../../../types/documentsTypes';
import { EfaxTableDetailsType } from '../../../../types/efaxTypes';
import {
  processEfaxAction,
  toggleOpenEfaxDrawer,
} from '../../../../store/actions/dashboardActions/efaxActions';
import {
  Box,
  IconButton,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';

type EfaxTableRowProps = {
  displayedList: EfaxTableDetailsType[];
};

const EfaxTableRows = (props: EfaxTableRowProps) => {
  const { displayedList } = props;
  const dispatch = useAppDispatch();
  const { selectedTab, contactHistory } = useAppSelector(
    (state) => state.efaxReducer
  );

  const { isEfaxProcessLoaderActive } = useAppSelector(
    ({ generalLoaderReducer }: Record<string, any>) =>
      generalLoaderReducer ?? true
  );

  const isReceivedTabSelected = selectedTab === 0;

  const [actionMenuElement, setActionMenuElement] =
    useState<null | HTMLElement>(null);
  const [selectedFaxIndex, setSelectedFaxIndex] = useState<null | number>(null);
  const [processFaxIndex, setProcessFaxIndex] = useState<string | null>(null);

  const isActionMenuOpen = Boolean(actionMenuElement);

  const handleActionMenuClick = (
    event: React.MouseEvent<HTMLElement>,
    index: number
  ) => {
    setSelectedFaxIndex(index);
    setActionMenuElement(event.currentTarget);
  };

  const onClickProcess = (faxId: string) => {
    setProcessFaxIndex(faxId);
    dispatch(processEfaxAction(faxId, setProcessFaxIndex));
  };

  return (
    <>
      {!!displayedList?.length &&
        displayedList?.map((fax: EfaxTableDetailsType, index: number) => {
          const faxAttachments = fax.attachmentUrlsCsv?.split(',');
          const faxFileNames = fax.fileNamesCsv?.split(',');
          const faxAttachmentDetails: TableDetailsAttachment[] = [];
          const senderPracticeContactName = contactHistory?.find(
            (contact) => contact.EFaxNumber === fax.senderFaxNumber
          )?.PracticeName;
          const recipientPracticeContactName = contactHistory?.find(
            (contact) => contact.EFaxNumber === fax.recipientFaxNumber
          )?.PracticeName;

          faxAttachments?.forEach((attachment: string, idx) => {
            const fileName = faxFileNames[idx];

            faxAttachmentDetails.push({
              fileName: fileName,
              attachmentUrl: attachment,
            });
          });

          return (
            <TableRow key={index}>
              {/* SENDER */}
              <TableCell>
                {isReceivedTabSelected ? (
                  <Box sx={efaxStyle.efaxTableFirstColumnDetails}>
                    <Box sx={efaxStyle.efaxTableRecipientName}>
                      {senderPracticeContactName ?? fax.senderPracticeName}
                    </Box>
                  </Box>
                ) : (
                  <Box sx={efaxStyle.efaxTableFirstColumnDetails}>
                    <Typography
                      component='span'
                      variant='subtitle2'
                      fontWeight={400}
                    >
                      {fax.senderPracticeName}
                    </Typography>
                  </Box>
                )}
                {fax?.senderName && (
                  <Box sx={efaxStyle.efaxTableFirstColumnDetails}>
                    <Typography
                      component='span'
                      variant='subtitle2'
                      fontWeight={400}
                    >
                      {fax.senderName}
                    </Typography>
                  </Box>
                )}
                <Box sx={efaxStyle.efaxTableFirstColumnDetails}>
                  <Typography
                    component='span'
                    variant='subtitle2'
                    fontWeight={400}
                  >
                    <MaskedString
                      mask={MOBILE_NUMBER_US_WITH_CODE_MASK}
                      value={fax.senderFaxNumber?.slice(-10)}
                    />
                  </Typography>
                </Box>
              </TableCell>
              {/* RECIPIENT */}
              <TableCell>
                <Box sx={efaxStyle.efaxTableRecipientNameTagContainer}>
                  {isReceivedTabSelected ? (
                    <Box>
                      {!!fax.recipientPracticeName && (
                        <Box sx={efaxStyle.efaxTableFirstColumnDetails}>
                          <Box sx={efaxStyle.efaxTableRecipientName}>
                            {fax.recipientPracticeName}
                          </Box>
                        </Box>
                      )}
                      <Box sx={efaxStyle.efaxTableFirstColumnDetails}>
                        <Typography
                          component='span'
                          variant='subtitle2'
                          fontWeight={400}
                        >
                          <MaskedString
                            mask={MOBILE_NUMBER_US_WITH_CODE_MASK}
                            value={fax.recipientFaxNumber?.slice(-10)}
                          />
                        </Typography>
                      </Box>
                      <Box sx={efaxStyle.efaxTableFirstColumnDetails}>
                        <Typography
                          component='span'
                          variant='subtitle2'
                          fontWeight={400}
                        >
                          <Moment
                            calendar={{
                              lastDay: '[Yesterday] LT',
                              sameDay: '[Today] LT',
                              lastWeek: 'MMM DD, YYYY hh:mm A',
                              sameElse: 'MMM DD, YYYY hh:mm A',
                            }}
                          >
                            {fax.createdOn}
                          </Moment>
                        </Typography>
                      </Box>
                    </Box>
                  ) : (
                    <Box sx={efaxStyle.efaxTableRecipientName}>
                      {recipientPracticeContactName ??
                        fax.recipientPracticeName}
                    </Box>
                  )}
                </Box>
              </TableCell>
              {/* FAX NUMBER (FOR SENT TAB ONLY) */}
              {!isReceivedTabSelected && (
                <TableCell>
                  <Box sx={efaxStyle.efaxTableFirstColumnDetails}>
                    {fax?.eFaxStatus === 'success' ? (
                      <Check
                        style={{
                          fontSize: '1.2rem',
                          margin: '0 4px',
                          color: '#4CAF50',
                        }}
                      />
                    ) : (
                      fax?.eFaxStatus === 'failed' && (
                        <WarningAmber
                          style={{
                            fontSize: '1.2rem',
                            margin: '0 4px',
                            color: '#F57F17',
                          }}
                        />
                      )
                    )}
                    <Typography
                      component='span'
                      variant='subtitle2'
                      fontWeight={400}
                    >
                      <MaskedString
                        mask={MOBILE_NUMBER_US_WITH_CODE_MASK}
                        value={fax.recipientFaxNumber}
                      />
                    </Typography>
                  </Box>
                  {fax.eFaxStatus === 'failed' && (
                    <Box sx={efaxStyle.efaxTableFirstColumnDetails}>
                      <Typography
                        component='span'
                        variant='subtitle2'
                        fontWeight={400}
                      >
                        Failed (Bad Fax Number)
                      </Typography>
                    </Box>
                  )}
                  {fax.eFaxStatus !== 'processing' && (
                    <Box sx={efaxStyle.efaxTableFirstColumnDetails}>
                      <Typography
                        component='span'
                        variant='subtitle2'
                        fontWeight={400}
                      >
                        <Moment
                          calendar={{
                            lastDay: '[Yesterday] LT',
                            sameDay: '[Today] LT',
                            lastWeek: 'MMM DD, YYYY hh:mm A',
                            sameElse: 'MMM DD, YYYY hh:mm A',
                          }}
                        >
                          {fax.createdOn}
                        </Moment>
                      </Typography>
                    </Box>
                  )}
                </TableCell>
              )}
              {/* DOCUMENT THUMBNAIL */}
              <TableCell>
                {!!faxAttachmentDetails?.length ? (
                  <DocumentTableThumbnailCarousel
                    attachments={faxAttachmentDetails}
                    index={index}
                    isShowOverlay={
                      !isReceivedTabSelected && fax.eFaxStatus === 'processing'
                    }
                    isDisableTooltip
                    onClickShowPreview={() =>
                      dispatch(toggleOpenEfaxDrawer(fax))
                    }
                    isDisablePreview
                  />
                ) : !!fax.previewUrl ? (
                  <Box
                    component='img'
                    alt={fax.previewUrl}
                    sx={documentStyle.docTableThumbnail}
                    height={85}
                    src={pdfLogo}
                    onClick={() => dispatch(toggleOpenEfaxDrawer(fax))}
                  />
                ) : (
                  <Box sx={efaxStyle.efaxTableFirstColumnDetails}>
                    <Typography
                      component='span'
                      variant='subtitle2'
                      fontWeight={400}
                    >
                      No preview available.
                    </Typography>
                  </Box>
                )}
              </TableCell>
              {/* STATUS (FOR RECEIVED TAB ONLY) */}
              {isReceivedTabSelected && (
                <TableCell valign='top'>
                  <Box sx={efaxStyle.faxStatusColumn}>
                    {selectedTab === 0 &&
                      (fax.documentStatus ===
                      DOCUMENT_STATUS_DATABASE_VALUES.NOT_PROCESSED ? (
                        <FlatButton
                          startIcon={
                            processFaxIndex !== fax.id && (
                              <DriveFolderUploadIcon />
                            )
                          }
                          isLoading={
                            isEfaxProcessLoaderActive &&
                            processFaxIndex === fax.id
                          }
                          variant='outlined'
                          sx={efaxStyle.processButton}
                          customLoadingElement={'Processing...'}
                          onClick={() => onClickProcess(fax.id)}
                        >
                          Process
                        </FlatButton>
                      ) : (
                        <Box sx={commonClasses.successTag}>
                          <CheckIcon /> Processed
                        </Box>
                      ))}
                  </Box>
                </TableCell>
              )}
              {/* ACTIONS */}
              <TableCell valign='top'>
                <Box sx={efaxStyle.faxActionColumn}>
                  <IconButton
                    onClick={(event) => handleActionMenuClick(event, index)}
                  >
                    <MoreVertIcon />
                  </IconButton>
                  <EfaxTableActionMenu
                    index={index}
                    selectedFaxIndex={selectedFaxIndex}
                    setSelectedFaxIndex={setSelectedFaxIndex}
                    actionMenuElement={actionMenuElement}
                    setActionMenuElement={setActionMenuElement}
                    isActionMenuOpen={isActionMenuOpen}
                    targetFax={fax}
                  />
                </Box>
              </TableCell>
            </TableRow>
          );
        })}
    </>
  );
};

export default EfaxTableRows;
