import {
  BaseQueryFn,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react';

import { memoRefreshToken } from '../helpers/refreshTokenHelper';
import { setHubAuthToken } from '../hubs/token';
import { RootState } from '../store/store';
import localStorageHelper from '../helpers/localStorageHelper';

const baseQuery = fetchBaseQuery({
  baseUrl: '',
  prepareHeaders: (headers, { getState }) => {
    const token =
      ((getState() as RootState).authReducer?.token ||
        localStorageHelper.getAuthToken()) ??
      'unknown';
    const user =
      ((getState() as RootState).userReducer?.id ||
        localStorageHelper.getUser()) ??
      'unknown';

    headers.set('Authorization', `Bearer ${token}`);
    headers.set('USER', user);

    return headers;
  },
  mode: 'cors',
});

const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  if (result.error?.status === 401) {
    // get a new token
    const token = await memoRefreshToken();
    if (token) {
      //store the token in hub
      setHubAuthToken(token);
      result = await baseQuery(args, api, extraOptions);
    } else {
      // log the user out ?
    }
  }
  return result;
};

export default baseQueryWithReauth;
