import { colorPalette } from '../theme/colorPalette';

export const commonClasses = {
  fieldError: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'error.main',
    },
    '& .MuiInputLabel-root': {
      color: 'error.main',
    },
  },
  initialsCircle: {
    display: 'flex',
    flexShrink: 0,
    height: '3.5rem',
    width: '3.5rem',
    fontWeight: 700,
    color: 'primary.main',
  },
  cardTag: {
    height: 'fit-content',
    p: '.25rem 1rem',
    typography: 'overline',
    fontWeight: 500,
    color: 'primary.main',
    textTransform: 'uppercase',
    bgcolor: 'primary.light',
    borderRadius: '3.125rem',
  },
  successTag: {
    display: 'flex',
    alignItems: 'center',
    height: 'fit-content',
    p: '.25rem 1rem',
    typography: 'overline',
    fontWeight: 500,
    color: 'success.dark',
    textTransform: 'uppercase',
    bgcolor: 'success.light',
    borderRadius: '3.125rem',
    svg: {
      mr: '.25rem',
      fontSize: '1rem',
    },
  },
  transparentTag: {
    display: 'flex',
    alignItems: 'center',
    height: 'fit-content',
    p: '.25rem 1rem',
    typography: 'overline',
    fontWeight: 600,
    fontSize: '0.8rem',
    color: '#FFF',
    textTransform: 'uppercase',
    border: '2px solid #FFF',
    borderRadius: '3.125rem',
    svg: {
      mr: '.25rem',
      fontSize: '1rem',
    },
  },
  cardTitle: {
    display: 'flex',
    gap: '.5rem',
    typography: 'subtitle1',
    fontWeight: 600,
    color: colorPalette.surfaceColor[800],
  },
  cardSubtitle: {
    display: 'flex',
    gap: '.5rem',
    typography: 'body2',
    fontWeight: 400,
    color: colorPalette.surfaceColor[800],
  },
  loaderContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 1,
  },
  closeIconWithoutHeader: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: 1,
    '.MuiIconButton-root': {
      p: 0,
    },
  },
  lineClampedData: {
    position: 'relative',
    '&::after': {
      content: '"..."',
      position: 'absolute',
      bottom: 0,
      right: 0,
      bgcolor: 'background.default',
    },
  },
  noRecordText: {
    width: 1,
    p: '1rem',
    typography: 'subtitle2',
    fontWeight: 400,
    color: 'primary.main',
    textAlign: 'center',
  },
  flatSlimButton: {
    minHeight: 'unset',
    height: '2.25rem',
    typography: 'subtitle2',
    fontWeight: 500,
    boxShadow: 'none',
  },
  practiceCard: {
    card: {
      display: 'flex',
      alignItems: 'center',
      gap: '.5rem',
      width: 1,
      p: '.5rem 0',
      borderBottom: '1px solid',
      borderColor: colorPalette.surfaceColor[200],
      '&:last-of-type': {
        borderBottom: 'none',
      },
    },
    practiceCardDetails: {
      display: 'flex',
      gap: '.5rem',
      alignItems: 'center',
      flex: 1,
      color: 'text.primary',
    },
    practiceListItem: {
      alignItems: 'flex-start',
      gap: '.5rem',
      pl: 0,
      pr: 0,
      '&:not(:last-of-type)': {
        borderBottom: `.0625rem solid ${colorPalette.surfaceColor[200]}`,
      },
      '& > .MuiListItemText-root': { mt: 0 },
      button: {
        minHeight: '2rem',
        minWidth: '5rem',
        typography: 'subtitle2',
        fontWeight: 500,
        lineHeight: '1rem',
        '&:last-of-type': {
          mt: '.25rem',
        },
      },
    },
    practiceCardActionButton: {
      minWidth: '2.5rem',
      boxShadow: 'none',
    },
    bulletPoint: {
      height: '.25rem',
      width: '.25rem',
      minHeight: '.25rem',
      minWidth: '.25rem',
      marginTop: '.438rem',
      borderRadius: '50%',
      bgcolor: colorPalette.surfaceColor[300],
    },
  },
  successModalHeaderIcon: {
    flex: 1,
    ml: '2.5rem',
    color: 'primary.dark',
    textAlign: 'center',
    margin: 'auto',
    display: 'block',
  },
  successModalTitle: {
    typography: 'h5',
    fontWeight: 400,
    textAlign: 'center',
    margin: '1.125rem 0 1rem',
  },
  successModalContent: {
    typography: 'body2',
    textAlign: 'center',
    color: colorPalette.surfaceColor[800],
  },
  successRowModal: {
    justifyContent: 'center',
    pt: '.75rem',
    pb: '2rem',
  },
  centeredContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100%',
    minWidth: '100%',
    textAlign: 'center',
    flexDirection: 'column',
  },
  sessionTimeoutDialog: {
    '.MuiBackdrop-root': {
      bgcolor: colorPalette.surfaceColor[700],
    },
    '.MuiDialog-paper': {
      bgcolor: 'background.default',
    },
    '.MuiDialogContent-root': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexFlow: 'column',
      width: 1,
    },
  },
  upgradeToV3Dialog: {
    '.MuiDialog-paper': {
      bgcolor: 'background.default',
      width: '961px',
      maxWidth: '900px',
      height: '640.67px',
      borderRadius: '16px',
    },
    '.MuiDialogContent-root': {
      display: 'flex',
      flexFlow: 'row',
      padding: 0,
      width: 1,
    },
    '.MuiTypography-root': {
      letterSpacing: 0,
    },
  },
  upgradeToV3DialogItemsBox: {
    display: 'flex',
    flexDirection: 'row',
    gap: '10px',
  },
  commonListChatButton: {
    minHeight: '2.25rem',
    height: '2.25rem',
    width: '4.25rem',
    typography: 'subtitle2',
    fontWeight: 500,
  },
  updateAppBanner: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '.5rem',
    typography: 'body2',
    color: 'background.default',
    bgcolor: 'primary.main',

    button: {
      width: 'fit-content',
      p: 0,
      color: 'background.default',
      textDecoration: 'underline',
    },
  },
  noEfaxNumberDialog: {
    message: {
      textAlign: 'center',
    },

    '.MuiSvgIcon-root': {
      height: '6rem',
      width: '6rem',
    },

    '.MuiDialog-paper': {
      bgcolor: 'background.default',
      width: '18.75rem',
    },

    '.MuiDialogContent-root': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexFlow: 'column',
      width: 1,
    },
  },
};
