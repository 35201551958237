import { EditOutlined, Person } from '@mui/icons-material';
import { Avatar, Box, IconButton } from '@mui/material';
import { UserStatus } from '../constants/userConstants';
import { StatusIndicator } from './StatusIndicator';
import { headerStyle } from '../style/headerStyle';
import { profileStyle } from '../style/profileStyle';
import { stringToRandomColour } from '../utilities/stringToRandomColour';
import { ReactNode, useMemo } from 'react';
import { PUBLIC_BLOB_BASE_URL } from 'constants/urlConstants';
import { TEST_CONSTANTS } from 'constants/testConstants';

type ProfileImageAvatarProps = {
  status?: UserStatus;
  showEditBtn?: boolean;
  onClickEdit?: () => void;
  src?: string;
  alt?: string;
  fallBackString?: string;
};

export const ProfileImageAvatar = ({
  status,
  showEditBtn = false,
  onClickEdit,
  src,
  alt = 'Profile Image',
  fallBackString,
  ...rest
}: ProfileImageAvatarProps) => {
  const profilePhotoUrl = useMemo(() => {
    let userPhotoUrl = src || '';

    if (!!userPhotoUrl) {
      // If image url returned by api does not include the drtalk file manager prefix url,
      // will manually include it to be used as an image src
      if (
        !!PUBLIC_BLOB_BASE_URL &&
        !userPhotoUrl.includes(PUBLIC_BLOB_BASE_URL)
      ) {
        userPhotoUrl = `${PUBLIC_BLOB_BASE_URL}/${userPhotoUrl}`;
      }
    }

    return userPhotoUrl;
  }, [src]);

  function renderAvatar() {
    let avatarElement: ReactNode;

    // if has existing photo url, will display the image
    if (!!profilePhotoUrl) {
      avatarElement = (
        <Avatar
          alt={alt}
          src={profilePhotoUrl}
          sx={profileStyle.profilePicture.avatar}
          data-testid={TEST_CONSTANTS.PROFILE_IMG_AVATAR}
        />
      );
    } else {
      // if no passed image and has fallback string, will display the abbreviation of the provided string
      if (!!fallBackString) {
        const getAbbr = () => {
          const splittedName = fallBackString.split(' ');

          if (splittedName.length === 1) {
            return splittedName[0].slice(0, 2).toUpperCase();
          }

          const abbr = splittedName
            .map((word) => word[0])
            .join('')
            .toUpperCase();

          return abbr;
        };

        avatarElement = (
          <Avatar
            sx={{
              ...profileStyle.profilePicture.avatar,
              backgroundColor: stringToRandomColour(fallBackString),
            }}
            data-testid={TEST_CONSTANTS.PROFILE_IMG_AVATAR_ABBR}
          >
            {getAbbr()}
          </Avatar>
        );
      } else {
        // if no passed image and fallback string, will display a Person icon
        avatarElement = (
          <Avatar
            sx={profileStyle.profilePicture.avatar}
            data-testid={TEST_CONSTANTS.PROFILE_IMG_AVATAR_PERSON}
          >
            <Person />
          </Avatar>
        );
      }
    }

    return avatarElement;
  }

  function renderEditButton() {
    return (
      <IconButton
        sx={profileStyle.profilePicture.editIcon}
        color='primary'
        aria-label='edit'
        onClick={onClickEdit}
        data-testid={TEST_CONSTANTS.PROFILE_IMG_AVATAR_EDIT_BTN}
      >
        <EditOutlined />
      </IconButton>
    );
  }

  return (
    <Box
      sx={profileStyle.profilePicture.container}
      data-testid={TEST_CONSTANTS.PROFILE_IMG_AVATAR_CONTAINER}
      {...rest}
    >
      {renderAvatar()}
      {showEditBtn && renderEditButton()}
      {!!status && (
        <StatusIndicator
          status={status}
          sx={headerStyle.profilePictureUserIndicator}
        />
      )}
    </Box>
  );
};
