export const colorPalette = {
  primary: {
    50: '#E8DDFF',
    100: '#CAC0F4',
    200: '#A594E9',
    300: '#846DDB',
    400: '#674EC8',
    500: '#4E35B0',
    600: '#3B2399',
    700: '#2A167B',
    800: '#1B0C56',
    900: '#0D052C',
  },
  primaryMain: '#4E35B0',
  primaryDark: '#2A167B',
  primaryLight: '#E8DDFF',
  secondary: '#FFF',
  error: '#BA1B1B',
  errorLight: '#F3D0D6',
  success: '#2BCE62',
  successLight: '#E8F5E9',
  successDark: '#43A047',
  backgroundPaper: '#F4F3F8',
  backgroundDefault: '#FFFFFF',
  outline: '#79757F',
  textPrimary: 'rgba(0, 0, 0, 0.87)',
  textSecondary: 'rgba(0, 0, 0, 0.6)',
  textDisabled: 'rgba(0, 0, 0, 0.38)',
  sliderBackground: '#47ea44',
  sliderForeground: '#1976d2',
  listItemBackground: '#E8DDFF',
  surfaceColor: {
    1000: '#48454F',
    900: '#212121',
    800: '#424242',
    700: '#616161',
    600: '#757575',
    500: '#9E9E9E',
    400: '#BDBDBD',
    300: '#E0E0E0',
    200: '#EEEEEE',
    100: '#F5F5F5',
    50: '#FAFAFA',
    10: '#FCFCFC',
  },
};

export const initialCircleColorArray = [
  '#DED9FF',
  '#BCF8FF',
  '#FFF9C4',
  '#FFC0D6',
];
