import { AddReactionOutlined, Close, SendOutlined } from '@mui/icons-material';
import {
  Box,
  Button,
  Grow,
  IconButton,
  Menu,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import EmojiPicker, { EmojiClickData, EmojiStyle } from 'emoji-picker-react';
import _ from 'lodash';
import { ChangeEvent, KeyboardEvent, useEffect, useRef, useState } from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';
import { allowedFileExtensionsList } from '../../../../constants/documentsConstants';
import { TEST_CONSTANTS } from '../../../../constants/testConstants';
import useFocusInputRef from '../../../../hooks/useFocusInputRef';
import { showSnackbarAction } from '../../../../store/actions/snackbarAction';
import { chatStyle } from '../../../../style/dashboardStyles/chatStyle';
import AttachmentIcon from './AttachmentIcon';

const ChatMessageBox = ({
  value,
  onChange,
  onSend,
  disabled = false,
}: {
  value?: string;
  onChange?: (value: string) => void;
  onSend?: (text: string, file?: File) => void;
  disabled?: boolean;
}) => {
  const [text, setText] = useState<string>('');
  const [fileError, setFileError] = useState<string | undefined>();
  const [anchor, setAnchor] = useState<Element | null>(null);
  const [selection, setSelection] = useState<{
    start?: number | null;
    end?: number | null;
  }>({ start: 0, end: 0 });

  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const focusInput = useFocusInputRef(inputRef);

  const onKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (disabled) return;
    if (e.code === 'Enter') handleSend();
    if (e.code === 'Escape') changeText('');
  };

  const changeText = (t: string) => {
    if (disabled) return;
    setText(t);
    onChange && onChange(t);
  };

  useEffect(() => setText(value ?? ''), [value]);

  const handleSend = () => {
    if (!_.isEmpty(text)) {
      setFileError(undefined);
      onSend && onSend(text);
      changeText('');
    }
  };

  const onDrop = (acceptedFiles: File[], fileRejections: FileRejection[]) => {
    if (acceptedFiles?.length < 2) {
      if (acceptedFiles[0].size > 20971520)
        setFileError('file size exceeds maximum (20MB)');
      else {
        onSend && onSend('', acceptedFiles[0]);
      }
      if (fileRejections?.length) {
        showSnackbarAction({
          message: `${fileRejections[0].file.name} - File cannot be sent. Please try again later.`,
          statusCode: 400,
          severity: 'error',
          open: true,
        });
      }
    } else {
      showSnackbarAction({
        message: 'You can send one attachment at a time',
        statusCode: 400,
        severity: 'error',
        open: true,
      });
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    noClick: true,
    accept: {
      'image/*': ['.png', 'jpg', 'jpeg'],
      'application/pdf': ['pdf'],
      'application/msword': ['doc', 'docx'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        ['doc', 'docx'],
      'text/csv': ['csv'],
      'text/plain': ['txt'],
      'application/csv': ['csv1'],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
        'xlsx',
      ],
      'application/vnd.ms-excel': ['xls'],
    },
  });
  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFileError(undefined);
    if (e.target.files) {
      const file = e.target.files[0];
      if (file.size > 20971520)
        setFileError('file size exceeds maximum (20MB)');
      else {
        onSend && onSend('', file);
      }
    }
    e.target.value = '';
    setTimeout(() => focusInput());
  };

  const selectEmoji = (emojiData: EmojiClickData, event: MouseEvent) => {
    const _start = selection.start ?? selection.end ?? text.length;
    const _end = selection.end ?? text.length;
    const _text = text.slice(0, _start) + emojiData.emoji + text.slice(_end);
    changeText(_text);
    //update these values after text changes
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.selectionStart = _start + 2;
        inputRef.current.selectionEnd = _start + 2;
        setSelection({ start: _start + 2, end: _end + 2 });
      }
    });
    setAnchor(null);
    focusInput();
  };

  return (
    <Box
      sx={chatStyle.chatMessageBox}
      data-testid={TEST_CONSTANTS.CHAT_MESSAGE_BOX}
    >
      <Box sx={{ flex: 1 }} {...getRootProps()}>
        <TextField
          fullWidth
          variant='outlined'
          inputRef={inputRef}
          placeholder='Start typing...'
          className='subvariant-flat'
          onKeyDown={onKeyPress}
          onChange={(e) => changeText(e.target.value)}
          value={text}
          sx={chatStyle.chatMessageBoxText}
          InputProps={{
            endAdornment: (
              <Box sx={chatStyle.chatMessageBoxActions}>
                {/* comment for now - needed for future implementation */}
                {/* {!fileError && (
                <IconButton>
                  <Gif />
                </IconButton>
              )} */}
                {fileError && (
                  <>
                    <Typography variant={'caption'} noWrap color={'red'}>
                      {fileError}
                    </Typography>
                    <IconButton
                      aria-label='Close Error Message'
                      onClick={() => setFileError(undefined)}
                    >
                      <Close fontSize={'small'} color='error' />
                    </IconButton>{' '}
                  </>
                )}
                <Tooltip title='select attachment (maximum 20MB)' arrow>
                  <IconButton
                    aria-label='Select Attachment'
                    onClick={(e) => fileInputRef.current?.click()}
                    color={fileError ? 'error' : 'default'}
                    disabled={disabled}
                  >
                    <AttachmentIcon fontSize={'small'} />
                  </IconButton>
                </Tooltip>
                {!fileError && (
                  <IconButton
                    aria-label='Use Emoji'
                    onClick={(e) => {
                      if (inputRef.current) {
                        setSelection({
                          start: inputRef.current?.selectionStart ?? 0,
                          end: inputRef.current?.selectionEnd ?? 0,
                        });
                      }
                      setAnchor(e.currentTarget);
                    }}
                    disabled={disabled}
                  >
                    <AddReactionOutlined fontSize={'small'} />
                  </IconButton>
                )}
              </Box>
            ),
          }}
          {...getInputProps}
        />
      </Box>
      <Button
        aria-label='Send Message'
        variant='contained'
        sx={chatStyle.chatMessageBoxButton}
        data-testid={TEST_CONSTANTS.CHAT_MESSAGE_BOX_BUTTON}
        onClick={() => {
          if (disabled || anchor) return;
          handleSend();
        }}
      >
        <SendOutlined />
      </Button>
      <input
        type='file'
        ref={fileInputRef}
        onChange={handleFileChange}
        style={{ display: 'none' }}
        accept={allowedFileExtensionsList}
      />
      <Menu
        data-testid={TEST_CONSTANTS.CHAT_MESSAGE_BOX_EMOJI_PICKER}
        open={Boolean(anchor)}
        anchorEl={anchor}
        anchorOrigin={{ vertical: -10, horizontal: 'right' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        onClose={() => {
          setAnchor(null);
          focusInput();
        }}
        TransitionComponent={Grow}
        PaperProps={{ sx: chatStyle.chatMessageBoxPopupMenu }}
        keepMounted
      >
        <EmojiPicker
          onEmojiClick={selectEmoji}
          previewConfig={{ showPreview: false }}
          emojiStyle={EmojiStyle.NATIVE}
        />
      </Menu>
    </Box>
  );
};

export default ChatMessageBox;
