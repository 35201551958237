/* eslint-disable react-hooks/exhaustive-deps */
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  TextField,
} from '@mui/material';
import {
  FIRST_AVAILABLE_DOCTOR_OPTION,
  FIRST_AVAILABLE_OPTION_LABEL,
} from 'constants/referralConstants';
import { useCallback, useMemo, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import {
  practiceSelectionStepStyle,
  commonReferralStyle,
  guestReferralPageStyle,
} from 'style/dashboardStyles/referralStyle';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { REFERRAL_STEPS } from 'constants/referralConstants';
import {
  goToNextStep,
  setDoctorSearchKeyword,
  setGuestRecaptchaToken,
  setGuestUserStepOneData,
  setSelectedDoctorId,
} from 'store/slices/referralSlice';
import { commonClasses } from 'style/commonClasses';
import ReferralStepItem from '../ReferralStepItem/ReferralStepItem';
import DoctorListItem from './DoctorListItem';
import { validateEmailAction } from 'store/actions/authActions';
import { showSnackbarAction } from 'store/actions/snackbarAction';
import { isEmailValid } from 'helpers/validationHelper';

const GuestPracticeSelectionStep = () => {
  const dispatch = useAppDispatch();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const {
    practiceByToken,
    doctorSearchKeyword,
    guestUserStepOneData,
    selectedDoctorId,
  } = useAppSelector((state) => state.referralReducer);
  const [emailError, setEmailError] = useState<string>('');

  // when email validation is checked
  const { isEmailValidLoaderActive } = useAppSelector(
    ({ generalLoaderReducer }: Record<string, any>) =>
      generalLoaderReducer ?? true
  );

  const getDoctorOptionLabel = (option: string) => {
    if (!!option) {
      if (option === FIRST_AVAILABLE_DOCTOR_OPTION) {
        return FIRST_AVAILABLE_OPTION_LABEL;
      } else {
        const selectedDoctor = practiceByToken?.teamMembers.find(
          (person) => person.id === option
        );
        return `Dr. ${selectedDoctor?.firstName} ${selectedDoctor?.lastName}`;
      }
    } else {
      return '';
    }
  };

  const isSubmitButtonDisabled = useMemo(
    () =>
      !(
        guestUserStepOneData.email.trim().length &&
        guestUserStepOneData.practiceName.trim().length &&
        selectedDoctorId &&
        practiceByToken.customReferralFormUrl
      ),
    [guestUserStepOneData, selectedDoctorId, practiceByToken]
  );

  const onChangeValue = (name: string, value: string) => {
    dispatch(setGuestUserStepOneData({ [name]: value }));
  };

  const displayedDoctorsList: string[] = useMemo(() => {
    let doctorsList: string[] =
      /**
       * NOTE:
       *
       * Requested b.e guys to include this filtering int the api side.
       * Should remove below filtering once backend has completed the changes.
       */
      practiceByToken?.teamMembers
        ?.filter((person) => person.role === 'Doctor')
        .map((doctor) => doctor.id) ?? [];

    doctorsList?.unshift(FIRST_AVAILABLE_DOCTOR_OPTION);

    // will remove "First Available" in search results when users searches
    if (!!doctorSearchKeyword) {
      doctorsList = doctorsList?.filter(
        (doctor) => doctor !== FIRST_AVAILABLE_DOCTOR_OPTION
      );
    }

    return doctorsList;
  }, [doctorSearchKeyword, practiceByToken]);

  // Create an event handler so you can call the verification on button click event or form submit
  const handleClickNextBtn = useCallback(async () => {
    if (!executeRecaptcha) {
      return;
    } else {
      const formActionNameForValidateEmail = () => {
        let string = `ckecking_if_email_address_is_valid_${guestUserStepOneData.email}`;
        return string.replace(/[^A-Za-z/]/g, '');
      };
      const captchaToken = await executeRecaptcha(
        formActionNameForValidateEmail()
      );

      // [WIP] for validate email
      const isUserEmailValid = await validateEmailAction(
        guestUserStepOneData.email,
        captchaToken
      );

      if (isUserEmailValid) {
        const formActionName = () => {
          let string = `submit_email_address_${guestUserStepOneData.email}`;
          return string.replace(/[^A-Za-z/]/g, '');
        };
        const token = await executeRecaptcha(formActionName());
        dispatch(setGuestRecaptchaToken(token));
        const isEmailSyntaxValid = isEmailValid(
          'email address',
          guestUserStepOneData.email
        ).status;
        if (isEmailSyntaxValid) {
          setEmailError('');
          if (practiceByToken?.customReferralFormUrl) {
            dispatch(goToNextStep());
          }
        } else {
          setEmailError('Email address is not valid');
        }
      } else {
        showSnackbarAction({
          message:
            'You are a user of drtalk, please login to drtalk to send referral.',
          statusCode: 400,
          severity: 'error',
          open: true,
        });
      }
    }
  }, [executeRecaptcha, guestUserStepOneData]);

  return (
    <ReferralStepItem
      stepNumber={REFERRAL_STEPS.PRACTICE_DETAILS}
      title={`Refer to: ${practiceByToken.name}`}
    >
      <Box sx={guestReferralPageStyle.fieldContainer}>
        <Box sx={practiceSelectionStepStyle.title} paddingTop={3}>
          Enter your information
        </Box>
        <TextField
          sx={[
            guestReferralPageStyle.fieldContainer.field,
            emailError ? commonClasses.fieldError : {},
          ]}
          placeholder='Enter your email address'
          label='Email'
          value={guestUserStepOneData.email}
          helperText={emailError}
          onChange={(e) => onChangeValue('email', e.target.value)}
          required
        />
        <TextField
          sx={guestReferralPageStyle.fieldContainer.field}
          placeholder='Enter your practice name(not your personal name)'
          value={guestUserStepOneData.practiceName}
          label='Practice name'
          onChange={(e) => onChangeValue('practiceName', e.target.value)}
          required
        />
        <Autocomplete
          disableClearable
          fullWidth
          clearOnBlur
          sx={practiceSelectionStepStyle.autoComplete}
          value={selectedDoctorId}
          onChange={(_e, value) => dispatch(setSelectedDoctorId(value))}
          options={displayedDoctorsList}
          getOptionLabel={getDoctorOptionLabel}
          onBlur={() => dispatch(setDoctorSearchKeyword(''))}
          renderOption={(props, option) => {
            return <DoctorListItem props={props} option={option} />;
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label='Referring to'
              required
              onChange={(event) =>
                dispatch(setDoctorSearchKeyword(event.target.value))
              }
            />
          )}
        />
      </Box>
      <Button
        sx={commonReferralStyle.nextButton}
        disabled={isSubmitButtonDisabled}
        onClick={handleClickNextBtn}
      >
        {isEmailValidLoaderActive ? (
          <CircularProgress size='1rem' color='inherit' />
        ) : (
          'Next'
        )}
      </Button>
    </ReferralStepItem>
  );
};

export default GuestPracticeSelectionStep;
