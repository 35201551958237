import jwtDecode from 'jwt-decode';
import moment from 'moment';
import {
  authConstants,
  isLeftNavigationDrawerOpen,
} from '../constants/authConstants';
import { userStorageKeys } from '../constants/userConstants';
import { UserInfo } from '../store/reduxConstants/userReduxConstants';
import { isUserInfo } from '../utilities/typeGuards';

type AccessToken = {
  aud: string;
  exp: number;
  iat: number;
  iss: string;
  nbf: number;
  unique_name: string;
};

//moved her to resolve cyclic dependency with authHelper.ts
function getTokenExpiration(token: string): number | null {
  const decoded = jwtDecode<AccessToken>(token);
  return decoded?.exp;
}

const localStorageHelper = {
  saveAuthToken: (token: string) => {
    localStorage.setItem(authConstants.authToken, token);
    // Also store the token's expiration to simplify checks
    const exp = getTokenExpiration(token);
    if (exp)
      localStorage.setItem(authConstants.authTokenExpiration, exp.toString());
  },

  getAuthToken: () => {
    return localStorage.getItem(authConstants.authToken);
  },

  getAuthTokenExpiration: (): number | null => {
    const exp = localStorage.getItem(authConstants.authTokenExpiration);
    if (!exp) return null;
    return parseInt(exp);
  },

  clearAuthToken: () => {
    localStorage.removeItem(authConstants.authToken);
    localStorage.removeItem(authConstants.authTokenExpiration);
  },

  saveRefreshToken: (token: string, expiration: string): void => {
    localStorage.setItem(authConstants.refreshToken, token);
    // We'll convert the expiration to Unix timestamp for consistency
    const date = moment(expiration).unix();
    if (date)
      localStorage.setItem(
        authConstants.refreshTokenExpiration,
        date.toString()
      );
  },

  getRefreshToken: (): string | null => {
    return localStorage.getItem(authConstants.refreshToken);
  },

  getRefreshTokenExpiration: (): number | null => {
    const exp = localStorage.getItem(authConstants.refreshTokenExpiration);
    if (!exp) return null;
    return parseInt(exp);
  },

  clearRefreshToken: (): void => {
    localStorage.removeItem(authConstants.refreshToken);
    localStorage.removeItem(authConstants.refreshTokenExpiration);
  },

  saveUser: (user: string) => {
    localStorage.setItem(authConstants.user, user);
  },

  getUser: () => {
    return localStorage.getItem(authConstants.user);
  },

  clearUser: () => {
    localStorage.removeItem(authConstants.user);
  },

  savePracticeId: (practiceId: string) => {
    localStorage.setItem(authConstants.practiceId, practiceId);
  },

  getPracticeId: () => {
    return localStorage.getItem(authConstants.practiceId);
  },

  clearPracticeId: () => {
    localStorage.removeItem(authConstants.practiceId);
  },

  saveAutoGeneratedPasswordStatus: (autoGeneratedPasswordStatus: string) => {
    localStorage.setItem(
      authConstants.isAutoGeneratedPassword,
      autoGeneratedPasswordStatus
    );
  },

  getAutoGeneratedPasswordStatus: () => {
    return localStorage.getItem(authConstants.isAutoGeneratedPassword);
  },

  clearAutoGeneratedPasswordStatus: () => {
    localStorage.removeItem(authConstants.isAutoGeneratedPassword);
  },

  saveOnboardingStatus: (onboardingStatus: string) => {
    localStorage.setItem(
      authConstants.isOnboardingProcessCompleted,
      onboardingStatus
    );
  },
  getOrganizationApprovalStatus: () => {
    return localStorage.getItem(authConstants.isOrganizationPendingApproval);
  },

  clearOrganizationApprovalStatus: () => {
    localStorage.removeItem(authConstants.isOrganizationPendingApproval);
  },

  saveOrganizationApprovalStatus: (isOrganizationPendingApproval: string) => {
    localStorage.setItem(
      authConstants.isOrganizationPendingApproval,
      isOrganizationPendingApproval
    );
  },

  getOnboardingStatus: () => {
    return localStorage.getItem(authConstants.isOnboardingProcessCompleted);
  },

  clearOnboardingStatus: () => {
    localStorage.removeItem(authConstants.isOnboardingProcessCompleted);
  },

  saveLeftNavigationDrawerStatus: (drawerStatus: string) => {
    localStorage.setItem(isLeftNavigationDrawerOpen, drawerStatus);
  },

  getLeftNavigationDrawerStatus: () => {
    return localStorage.getItem(isLeftNavigationDrawerOpen);
  },

  clearLeftNavigationDrawerStatus: () => {
    localStorage.removeItem(isLeftNavigationDrawerOpen);
  },

  saveUserInfo: (userInfo: Partial<UserInfo>) => {
    const emptyUserInfo: UserInfo = {
      email: '',
      firstName: '',
      lastName: '',
      practiceId: '',
      practiceName: '',
      practiceType: '',
      profileImage: null,
      role: '',
      mobile: '',
    };
    const infoToSave = { ...emptyUserInfo, ...userInfo };
    const serialisedUserInfo = JSON.stringify(infoToSave);
    localStorage.setItem(userStorageKeys.userInfo, serialisedUserInfo);
  },

  getUserInfo: (): UserInfo | null => {
    const userInfo = localStorage.getItem(userStorageKeys.userInfo);
    if (!userInfo) return null;
    const deserialisedUserInfo = JSON.parse(userInfo);
    return isUserInfo(deserialisedUserInfo) ? deserialisedUserInfo : null;
  },

  updateUserInfo: (userInfo: Partial<UserInfo>): void => {
    const currentUserInfo = localStorageHelper.getUserInfo();
    if (!currentUserInfo) {
      localStorageHelper.saveUserInfo(userInfo);
      return;
    }
    const infoToSave = { ...currentUserInfo, ...userInfo };
    localStorage.setItem(userStorageKeys.userInfo, JSON.stringify(infoToSave));
  },

  clearUserInfo: (): void => {
    localStorage.removeItem(userStorageKeys.userInfo);
  },

  savePracticeInviteCode: (inviteCode: string) => {
    localStorage.setItem(authConstants.practiceInviteCode, inviteCode);
  },

  getPracticeInviteCode: () => {
    return localStorage.getItem(authConstants.practiceInviteCode);
  },

  clearPracticeInviteCode: () => {
    localStorage.removeItem(authConstants.practiceInviteCode);
  },

  setLastInteractionTime: (timer: string) =>
    localStorage.setItem(authConstants.lastInteractionTime, timer),

  getLastInteractionTime: () => {
    const counter = localStorage.getItem(authConstants.lastInteractionTime);
    return counter as string;
  },

  clearLastInteractionTime: () =>
    localStorage.removeItem(authConstants.lastInteractionTime),

  saveUserIsPendingApproval: (isUserPendingApproval: string) =>
    localStorage.setItem(
      authConstants.isUserPendingApproval,
      isUserPendingApproval
    ),

  getUserIsPendingApproval: () =>
    localStorage.getItem(authConstants.isUserPendingApproval),

  getIsGuestUserVisitFirstTime: () =>
    localStorage.getItem(authConstants.isGuestUserVisitFirstTime),

  saveIsGuestUserVisitFirstTime: (isGuestUserVisitFirstTime: string) =>
    localStorage.setItem(
      authConstants.isGuestUserVisitFirstTime,
      isGuestUserVisitFirstTime
    ),

  saveIsReferralNudgeVisible: (showReferralStatus: string) =>
    localStorage.setItem(
      authConstants.isReferralNudgeVisible,
      showReferralStatus
    ),

  getIsReferralNudgeVisible: () =>
    localStorage.getItem(authConstants.isReferralNudgeVisible),

  clearIsReferralNudgeVisible: () =>
    localStorage.removeItem(authConstants.isReferralNudgeVisible),
};

export default localStorageHelper;
