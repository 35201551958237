//can't use 'Notification' as type name

export enum NotificationPermission {
  none = 'none',
  default = 'default',
  granted = 'granted',
  denied = 'denied',
}

//these need to match the route names
export enum NotificationType {
  chat = 'chat',
  network = 'my-network',
  document = 'documents',
  efax = 'efax',
}

export type SystemNotification = {
  id: string;
  title: string;
  body: string;
  type: NotificationType;
  generated?: number;
};
