import { Box, Typography } from '@mui/material';
import { useAppSelector } from 'app/hooks';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { RootState } from 'store/store';
import { DocumentReducer } from 'types/documentsTypes';
import { ReactComponent as BottomImage } from '../../../assets/images/guestUserAskForSignUp.svg';
import FlatButton from '../../../components/FlatButton';
import { TEST_CONSTANTS } from '../../../constants/testConstants';
import { AUTH_URLS } from '../../../constants/urlConstants';
import { guestUserDocumentsAskForSignUpStyle } from '../../../style/dashboardStyles/guestUserDocumentsAskForSignUpStyle';

const GuestUserDocumentsAskForSignUp = () => {
  const { token: guestToken } = useParams();
  const { selectedTab, documentList } = useAppSelector(
    (state: RootState) => state.documentReducer as DocumentReducer
  );

  // when user clicks on the `Register` button, need to set URL by fetching email address from the document list
  const registerEmailAddress = useMemo(() => {
    if (guestToken)
      return selectedTab === 0
        ? documentList?.[0]?.secureEmailAddress
        : documentList?.[0]?.guestSenderEmailAddress;
  }, [guestToken, documentList, selectedTab]);
  const onClickRegister = () => {
    window.open(
      AUTH_URLS.GUEST_REGISTER_URL.replace(
        'EMAIL_ADDRESS',
        registerEmailAddress!
      ),
      '_blank'
    );
  };
  return (
    <Box sx={guestUserDocumentsAskForSignUpStyle.mainContainer}>
      <Box sx={guestUserDocumentsAskForSignUpStyle.contentContainer}>
        <Typography
          variant='h5'
          sx={guestUserDocumentsAskForSignUpStyle.mainTitle}
          data-testid={
            TEST_CONSTANTS.GUEST_USER_DOCUMENTS_ASK_FOR_SIGN_UP_TITLE
          }
        >
          Smart Dental Practices are ditching their phones and fax machines for
          practice to practice communication!
        </Typography>
        <Typography
          variant='body2'
          sx={guestUserDocumentsAskForSignUpStyle.subTitle}
        >
          Save time and money. Connect with your specialists and dental
          laboratories.
        </Typography>
        <FlatButton variant='contained' onClick={onClickRegister}>
          Sign up for drtalk, it’s free!
        </FlatButton>
        <Typography variant='body2' sx={{ fontStyle: 'italic', mb: '2rem' }}>
          Drtalk is HIPAA compliant
        </Typography>
        <BottomImage />
      </Box>
    </Box>
  );
};

export default GuestUserDocumentsAskForSignUp;
