export const userStorageKeys = {
  userInfo: 'userInfo',
};

export const USER_STATUS = {
  UNKNOWN: 'Unknown',
  AVAILABLE: 'Available',
  BUSY: 'Busy',
  OFFLINE: 'Offline',
  UNSET: 'Unset',
};

export const userAvailability = [
  USER_STATUS.UNKNOWN,
  USER_STATUS.AVAILABLE,
  USER_STATUS.BUSY,
  USER_STATUS.OFFLINE,
  USER_STATUS.UNSET,
] as const;

export enum UserWorkStatusCode {
  Unknown = 0,
  Active = 1,
  Busy = 2,
  Offline = 3,
  Unset = 4,
}

export type UserStatus = typeof userAvailability[number];

export const dentistPracticeType = [
  'Dentist',
  'Dental Laboratory',
  'Dental Implant company',
];

export const rolesFromClaimsByJwtToken = { efax: 'eFax' };
