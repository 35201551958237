import { Box, Link, Typography } from '@mui/material';
import { infoPageStyle } from '../style/infoPageStyle';
import desktop from '../assets/images/info_page_monitor.png';
import mobile from '../assets/images/info_page_mobile.png';
import { ReactComponent as DrTalkTextLogo } from '../assets/images/drTalkTextLogo.svg';
import { AUTH_URLS } from '../constants/urlConstants';
import { useNavigate } from 'react-router-dom';

const InfoPage = () => {
  const navigate = useNavigate();
  return (
    <Box sx={infoPageStyle.mainContainer}>
      <Link onClick={() => navigate(-1)} sx={infoPageStyle.backLink}>
        Back
      </Link>
      <Box sx={infoPageStyle.contentContainer}>
        <Box sx={infoPageStyle.imageContainer}>
          <Box width={0.7} component='img' src={desktop} />
          <Box
            width={0.35}
            sx={infoPageStyle.mobileImage}
            component='img'
            src={mobile}
          />
        </Box>
        <Box>
          <DrTalkTextLogo />
          <Box sx={infoPageStyle.content}>
            <Typography>
              drtalk is HIPPA compliant communication platform and business
              tools for the dental office
            </Typography>
            <Typography>
              Everything you need in one location with EMR integrated.
            </Typography>
            <Link href={AUTH_URLS.DR_TALK_WEBSITE} target='_blank'>
              www.drtalk.com
            </Link>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default InfoPage;
