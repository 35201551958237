import { type AxiosRequestConfig } from 'axios';
import mem from 'mem';

import { setHubAuthToken } from '../hubs/token';
import { setAuthToken } from '../store/actions/authActions';
import { store } from '../store/store';
import localStorageHelper from './localStorageHelper';
import { memoRefreshToken } from './refreshTokenHelper';

export function logout(callback?: () => void): void {
  if (callback) callback();
  localStorageHelper.clearAuthToken();
  localStorageHelper.clearRefreshToken();
  localStorageHelper.clearUser();
  localStorageHelper.clearOnboardingStatus();
  localStorageHelper.clearAutoGeneratedPasswordStatus();
  localStorageHelper.clearLeftNavigationDrawerStatus();
  localStorageHelper.clearUserInfo();
  localStorageHelper.clearPracticeId();
  localStorageHelper.clearPracticeInviteCode();
  localStorageHelper.clearLastInteractionTime();
  localStorageHelper.clearOrganizationApprovalStatus();
}

export async function renewToken(
  config?: AxiosRequestConfig
): Promise<string | null> {
  const token = await memoRefreshToken(config);
  if (token) {
    store.dispatch(setAuthToken(token));
    //store the auth info in hub
    setHubAuthToken(token);
  }
  return token;
}

// This memoised function prevents multiple calls to the refresh token API
export const memoRenewToken = mem(renewToken, { maxAge: 10_000 });
