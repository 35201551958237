import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { efaxStyle } from '../../../style/dashboardStyles/efaxStyle';
import { resetEfaxFiltersAction } from '../../../store/actions/dashboardActions/efaxActions';
import EfaxFilters from './components/EfaxFilters';
import EfaxTableDetails from './components/EfaxTableDetails';
import EfaxTabs from './components/EfaxTabs';
import NoEfaxNumberDialog from '../../../components/NoEfaxNumberDialog';
import { useNavigate } from 'react-router';
import { ROUTE_CONSTANTS_VARIABLE } from '../../../constants/routeConstants';
import { setHeaderSearchTextAction } from '../../../store/actions/headerActions';

const Efax = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { userPracticeDetails } = useAppSelector((state) => state.efaxReducer);
  const [isNoEfaxDialogOpen, setIsNoEfaxDialogOpen] = useState(false);

  useEffect(() => {
    // will reset applied efax filters to initial state on first load
    dispatch(resetEfaxFiltersAction());
    dispatch(setHeaderSearchTextAction(''));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!!userPracticeDetails?.practiceId && !userPracticeDetails?.eFaxNumber) {
      setIsNoEfaxDialogOpen(true);
    }
  }, [userPracticeDetails]);

  return (
    <Box sx={efaxStyle.mainContainer}>
      <EfaxTabs />
      <EfaxFilters />
      <EfaxTableDetails />

      {/* shown if user forced to navigate to e-Fax page w/o e-Fax no. */}
      <NoEfaxNumberDialog
        isOpen={isNoEfaxDialogOpen}
        onToggleDialog={() => {
          // will navigate user to profile page after closing modal
          setIsNoEfaxDialogOpen(!isNoEfaxDialogOpen);
          navigate(`${ROUTE_CONSTANTS_VARIABLE.DASHBOARD}/profile`);
        }}
      />
    </Box>
  );
};

export default Efax;
