import { EfaxTabs } from '../types/efaxTypes';

export const efaxTabs: EfaxTabs = {
  received: {
    value: 0,
    name: 'Received',
  },
  sent: {
    value: 1,
    name: 'Sent',
  },
};
