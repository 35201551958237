import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  Divider,
  Typography,
} from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import FlatButton from '../../../../components/FlatButton';
import { TEST_CONSTANTS } from '../../../../constants/testConstants';
import {
  resetSendDocumentStatusListAction,
  retrySendDocumentsAction,
} from '../../../../store/actions/dashboardActions/documentActions';
import { RootState } from '../../../../store/store';
import { documentStyle } from '../../../../style/dashboardStyles/documentStyle';
import { colorPalette } from '../../../../theme/colorPalette';
import {
  DocumentReducer,
  SendDocumentRecordStatus,
} from '../../../../types/documentsTypes';

const SendDocumentsStatus = ({ isDialogOpen }: { isDialogOpen: boolean }) => {
  const dispatch = useAppDispatch();
  const { sendDocumentStatusList } = useAppSelector(
    (state: RootState) => state.documentReducer as DocumentReducer
  );
  const { isSendDocumentsLoaderActive } = useAppSelector(
    ({ generalLoaderReducer }: Record<string, any>) =>
      generalLoaderReducer ?? true
  );

  const statusIcon = {
    inProgress: <CircularProgress size='1.2rem' />,
    done: <CheckCircleIcon color='success' />,
    failed: <HighlightOffIcon color='error' />,
  };

  const onClickRetry = (recipient: SendDocumentRecordStatus) => {
    dispatch(retrySendDocumentsAction(recipient));
  };

  const onClickContinue = () => {
    dispatch(resetSendDocumentStatusListAction());
  };

  return (
    <Dialog
      sx={documentStyle.sendDocumentStatusDialog}
      data-testid={TEST_CONSTANTS.SEND_DOCUMENTS_STATUS_DIALOG}
      open={isDialogOpen}
    >
      <DialogContent>
        <Box sx={documentStyle.sendDocumentStatusListContentContainer}>
          <Typography variant='h6' fontWeight={500} textAlign='center'>
            {isSendDocumentsLoaderActive
              ? 'Your documents are sending..'
              : 'Your documents has been sent.'}
          </Typography>
          {sendDocumentStatusList?.map(
            (recipient: SendDocumentRecordStatus) => (
              <Box
                key={recipient.toPracticeId}
                sx={[
                  documentStyle.sendDocumentStatusContainer,
                  {
                    borderColor:
                      recipient.status === 'failed'
                        ? 'error.main'
                        : colorPalette.surfaceColor[400],
                  },
                ]}
              >
                <Box sx={documentStyle.sendDocumentStatusIndicator}>
                  {statusIcon[recipient.status]}
                </Box>
                <Divider orientation='vertical' />
                <Box sx={documentStyle.sendDocumentStatusRecipientName}>
                  {recipient.name}
                </Box>
                {recipient.status === 'failed' && (
                  <FlatButton
                    variant='text'
                    sx={documentStyle.sentDocumentStatusListRetryButton}
                    onClick={() => onClickRetry(recipient)}
                  >
                    Retry
                  </FlatButton>
                )}
              </Box>
            )
          )}
          <FlatButton
            variant='contained'
            sx={documentStyle.sendDocumentStatusListSubmitButton}
            disabled={isSendDocumentsLoaderActive}
            onClick={onClickContinue}
          >
            Continue
          </FlatButton>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default SendDocumentsStatus;
