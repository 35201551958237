import { createSlice } from '@reduxjs/toolkit';
import { initialCircleColorArray } from 'theme/colorPalette';
import {
  acceptPracticeInvite,
  deleteRegistationInvite,
  deleteSentInvite,
  getConnectedPractice,
  getInvitationList,
  getPendingConnectionInvites,
  getPracticeDetails,
  getRecommendedList,
  getRegistrationPendingInvites,
  hideRecommendedPractice,
  ignorePracticeInvite,
  invitePractice,
  inviteRecommendedPractice,
} from '../../services/myNetworkService';
import {
  InvitationListResponseType,
  MyNetworkReducer,
  PracticeListResponseData,
} from '../../types/myNetworkTypes';
import _ from 'lodash';

const myNetworkInitialState: MyNetworkReducer = {
  connectedPracticeList: [],
  connectedPracticeListWithCustomForm: [],
  pendingConnectionInvites: [],
  pendingRegistrationInvites: [],
  isInvitationListLoaderActive: false,
  updateInvitationListStamp: { count: 0, timestamp: 0 },
  invitationList: [],
  recommendedList: [],
  selectedPracticeDetails: {
    Name: '',
    practiceType: '',
    teamMembers: [],
    addresses: [],
    telephoneNumber: '',
  },
};

const pendingConnectionRequestAcceptedReducer = (
  state: MyNetworkReducer,
  { payload: practice }: { payload: PracticeListResponseData }
) => {
  let updatedConnectedPracticeList = [
    {
      ...practice,
      bgcolor:
        initialCircleColorArray[
          Math.floor(Math.random() * initialCircleColorArray.length)
        ],
    },
    ...state.connectedPracticeList,
  ];

  state.connectedPracticeList = _.sortBy(
    updatedConnectedPracticeList,
    (practice) => practice.name
  );

  state.pendingConnectionInvites = state.pendingConnectionInvites.filter(
    (pendingConnectionInvite: InvitationListResponseType) =>
      pendingConnectionInvite.toPracticeId !== practice.id
  );
};

const acceptPracticeInviteFromReceivedDocumentsReducer = (
  state: MyNetworkReducer,
  { payload: id }: { payload: string }
) => {
  state.invitationList = state.invitationList.filter(
    (invite) => invite.fromPracticeId !== id
  );
};

const myNetworkSlice = createSlice({
  name: 'myNetwork',
  initialState: myNetworkInitialState,
  reducers: {
    pendingConnectionRequestAccepted: pendingConnectionRequestAcceptedReducer,
    acceptPracticeInviteFromReceivedDocuments:
      acceptPracticeInviteFromReceivedDocumentsReducer,
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(getConnectedPractice.matchFulfilled, (state, { payload }) => {
        state.connectedPracticeList = payload;
      })
      .addMatcher(
        getPendingConnectionInvites.matchFulfilled,
        (state, { payload }) => {
          state.pendingConnectionInvites = payload;
        }
      )
      .addMatcher(
        getRegistrationPendingInvites.matchFulfilled,
        (state, { payload }) => {
          state.pendingRegistrationInvites = payload;
        }
      );
    builder.addMatcher(invitePractice.matchFulfilled, (state, { payload }) => {
      state.pendingRegistrationInvites = [
        ...state.pendingRegistrationInvites,
        { ...payload },
      ];
    });
    builder.addMatcher(
      deleteSentInvite.matchFulfilled,
      (
        state,
        {
          meta: {
            arg: { originalArgs: id },
          },
        }
      ) => {
        state.pendingConnectionInvites = state.pendingConnectionInvites.filter(
          (invite: any) => invite.id !== id
        );
      }
    );
    builder.addMatcher(
      deleteRegistationInvite.matchFulfilled,
      (
        state,
        {
          meta: {
            arg: { originalArgs: id },
          },
        }
      ) => {
        state.pendingRegistrationInvites =
          state.pendingRegistrationInvites.filter(
            (invite: any) => invite.id !== id
          );
      }
    );
    builder.addMatcher(
      getInvitationList.matchFulfilled,
      (state, { payload }) => {
        state.invitationList = payload;
        state.updateInvitationListStamp.count = payload.length;
      }
    );
    builder.addMatcher(
      acceptPracticeInvite.matchFulfilled,
      (
        state,
        {
          payload: { data },
          meta: {
            arg: { originalArgs: id },
          },
        }
      ) => {
        state.pendingConnectionInvites = [
          ...state.pendingConnectionInvites,
          { ...data },
        ];

        state.invitationList = state.invitationList.filter(
          (invite) => invite.id !== id
        );
      }
    );
    builder.addMatcher(
      ignorePracticeInvite.matchFulfilled,
      (
        state,
        {
          meta: {
            arg: { originalArgs: id },
          },
        }
      ) => {
        state.invitationList = state.invitationList.filter(
          (invite) => invite.id !== id
        );
      }
    );
    builder.addMatcher(
      getRecommendedList.matchFulfilled,
      (state, { payload }) => {
        state.recommendedList = payload;
      }
    );
    builder.addMatcher(
      inviteRecommendedPractice.matchFulfilled,
      (
        state,
        {
          payload: { data: response },
          meta: {
            arg: {
              originalArgs: { data, invitedFromHeaderSearchBar },
            },
          },
        }
      ) => {
        if (invitedFromHeaderSearchBar) {
          let updatedPendingInvites = [
            {
              ...response,
              bgcolor:
                initialCircleColorArray[
                  Math.floor(Math.random() * initialCircleColorArray.length)
                ],
            },
            ...state.pendingConnectionInvites,
          ];

          state.pendingConnectionInvites = _.sortBy(
            updatedPendingInvites,
            (invite) => invite.toPracticeName
          );
        }
      }
    );
    builder.addMatcher(
      hideRecommendedPractice.matchFulfilled,
      (
        state,
        {
          meta: {
            arg: { originalArgs: id },
          },
        }
      ) => {
        state.recommendedList = state.recommendedList.filter(
          (recommendation) => recommendation.PracticeId !== id
        );
      }
    );
    builder.addMatcher(
      getPracticeDetails.matchFulfilled,
      (state, { payload }) => {
        state.selectedPracticeDetails = payload;
      }
    );
  },
});

export const {
  pendingConnectionRequestAccepted,
  acceptPracticeInviteFromReceivedDocuments,
} = myNetworkSlice.actions;

export default myNetworkSlice.reducer;
