import {
  CloseRounded,
  ContentCopy,
  DeleteOutline,
  Shortcut,
  VisibilityOffOutlined,
} from '@mui/icons-material';
import DownloadForOfflineRoundedIcon from '@mui/icons-material/DownloadForOfflineRounded';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  Grow,
  IconButton,
  LinearProgress,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Snackbar,
  Stack,
  Typography,
} from '@mui/material';
import _ from 'lodash';
import { SyntheticEvent, useEffect, useMemo, useRef, useState } from 'react';
import { DropzoneInputProps, DropzoneRootProps } from 'react-dropzone';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useAppSelector } from '../../../../app/hooks';
import { TEST_CONSTANTS } from '../../../../constants/testConstants';
import { chatStyle } from '../../../../style/dashboardStyles/chatStyle';
import {
  ChatChannel,
  ChatMessage,
  ChatMessageAttachment,
} from '../../../../types/chatTypes';
import ChatAttachmentDialog from './ChatAttachmentDialog';
import ChatMessageListItem from './ChatMessageListItem';

const ChatMessageList = ({
  chat,
  list,
  selectedList,
  select,
  uploading,
  onDeleteMessage,
  onForwarding,
  onForwardMessage,
  onSelectMessage,
  onLoadMore,
  scrollPosition,
  onRetrySendMessage,
  getRootProps,
  getInputProps,
  isDragActive,
}: {
  chat?: ChatChannel;
  list?: ChatMessage[];
  selectedList?: ChatMessage[];
  select?: boolean;
  uploading?: string;
  onDeleteMessage?: (message: ChatMessage, all?: boolean) => void;
  onForwardMessage?: (message: ChatMessage) => void;
  onForwarding?: (forwarding: boolean) => void;
  onSelectMessage?: (messages: ChatMessage, selected?: boolean) => void;
  onLoadMore?: () => void;
  scrollPosition?: number;
  onRetrySendMessage?: (message: ChatMessage, file?: File) => void;
  getRootProps?: <T extends DropzoneRootProps>(props?: T | undefined) => T;
  getInputProps?: <T extends DropzoneInputProps>(props?: T | undefined) => T;
  isDragActive?: boolean;
}) => {
  const scrollRef = useRef<null | HTMLDivElement>(null);
  const downloadRef = useRef<HTMLAnchorElement | null>(null);

  const [hasMore, setHasMore] = useState<boolean>(false);
  const { selected } = useAppSelector(
    (state) => state.chatReducer?.channelList ?? {}
  );
  //get items and continuationToken from the chat reducer rather than the query itself
  const { continuationToken: messagesContinuationToken } = useAppSelector(
    (state) =>
      state.chatReducer?.messageList?.messages?.[selected?.id ?? ''] ?? {}
  );
  // keep in case useful later
  // useEffect(() => {
  //   if (scrollRef?.current) {
  //     scrollRef.current.scrollIntoView({
  //       behavior: 'auto',
  //       block: 'nearest',
  //       inline: 'nearest',
  //     });
  //   }
  // }, [list]);

  useEffect(() => {
    setHasMore(!!messagesContinuationToken && !!list);
  }, [messagesContinuationToken, list]);

  const [anchor, setAnchor] = useState<Element | null>(null);
  const [selectedMessage, setSelectedMessage] = useState<ChatMessage | null>(
    null
  );
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const [deleteAll, setDeleteAll] = useState<boolean>(false);
  const [showSnackbar, setShowSnackbar] = useState<boolean>(false);
  const [attachment, setAttachment] = useState<ChatMessageAttachment>();

  const onCopy = async () => {
    if (selectedMessage) {
      let text = !_.isEmpty(selectedMessage?.messageText)
        ? selectedMessage?.messageText
        : '';
      if (
        !_.isEmpty(selectedMessage?.messageText) &&
        !_.isEmpty(selectedMessage?.metaDictionary?.attachmentName)
      )
        text += ' - ';
      if (!_.isEmpty(selectedMessage?.metaDictionary?.attachmentName))
        text += selectedMessage?.metaDictionary?.attachmentName;

      try {
        await navigator.clipboard.writeText(text);
        if (!showSnackbar) setShowSnackbar(true);
      } catch (err) {}
    }
    closeContextMenu();
  };

  const onForward = () => {
    if (selectedMessage && onForwardMessage) {
      onForwardMessage(selectedMessage);
    }
  };

  const onDelete = (all?: boolean) => {
    if (selectedMessage && onDeleteMessage) {
      setDeleteAll(all ?? false);
      setDeleteDialogOpen(true);
    }
  };

  const menuOptions = [
    { text: 'Copy', icon: <ContentCopy fontSize='small' />, action: onCopy },
    {
      text: 'Forward',
      icon: <Shortcut fontSize='small' />,
      action: onForward,
    },
    {
      text: 'Delete for you',
      icon: <VisibilityOffOutlined fontSize='small' />,
      action: () => onDelete(),
    },
    {
      text: 'Delete for everyone',
      icon: <DeleteOutline fontSize='small' />,
      action: () => onDelete(true),
      userOnly: true,
    },
  ];

  const onContextMenu = (event: SyntheticEvent, message: ChatMessage) => {
    event.preventDefault();
    if (selectedList) return; //don't display context menu if selecting
    setAnchor(event.currentTarget);
    setSelectedMessage(message);
  };

  const onDownloadAttachment = (attachment: ChatMessageAttachment) => {
    const link = downloadRef.current;
    if (link && attachment.file.objectURL) {
      link.download = attachment.name;
      link.href = attachment.file.objectURL;
      link.click();
    }
  };

  const onViewAttachment = (attachment: ChatMessageAttachment) => {
    if (!attachment.isImage) return;
    setAttachment(attachment);
  };

  const closeContextMenu = () => {
    setAnchor(null);
  };
  const closeDeleteDialog = () => {
    setSelectedMessage(null);
    setDeleteDialogOpen(false);
  };

  // get the deleted users from drtalk, to get the message at bottom of the
  // chat(these users are no longer available on drtalk)
  const deletedUsers = useMemo(
    () => chat?.members?.filter((member) => member.isDeleted),
    [chat]
  );

  return (
    <Box
      sx={chatStyle.chatMessageListContainer}
      onContextMenu={(e) => e.preventDefault()}
      data-testid={TEST_CONSTANTS.CHAT_MESSAGE_LIST}
      {...(getRootProps && getRootProps())}
    >
      {isDragActive && (
        <Box sx={chatStyle.chatDropFileHereText}>
          <DownloadForOfflineRoundedIcon sx={{ fontSize: 32 }} />
          <Typography
            component='div'
            variant='h6'
            fontWeight={400}
            color='primary.main'
            mt={2}
          >
            Drop your file here
          </Typography>
        </Box>
      )}
      <input
        style={{ display: 'none' }}
        {...(getInputProps && getInputProps())}
      />
      {!!deletedUsers?.length && (
        <Box
          sx={chatStyle.noMoreDrTalkUser}
          data-testid={TEST_CONSTANTS.CHAT_MESSAGE_LIST_DELETED_USER_MESSAGE}
        >
          {deletedUsers?.map((deletedUser) => deletedUser.name).join(', ')}{' '}
          {deletedUsers?.length === 1 ? 'is' : 'are'} no longer on drtalk
        </Box>
      )}
      <Box
        sx={[chatStyle.chatMessageList, { opacity: isDragActive ? 0.5 : 1 }]}
        id='scrollableMessageList'
      >
        <InfiniteScroll
          dataLength={(list ?? []).length + 1}
          next={() => onLoadMore && onLoadMore()}
          style={{
            display: 'flex',
            flexDirection: 'column-reverse',
            paddingTop: '1rem',
            paddingBottom: !!deletedUsers?.length ? '5rem' : void 0,
          }}
          inverse={true}
          hasMore={hasMore}
          loader={
            <Box sx={chatStyle.chatListPanelInfiniteLoader}>
              <LinearProgress sx={{ width: '3rem' }} />
            </Box>
          }
          scrollableTarget='scrollableMessageList'
        >
          {/* shown in reverse order as scrolling is at the top */}
          <Stack
            key={list?.length ?? 0}
            sx={chatStyle.chatMessageContainer}
            ref={scrollRef}
          >
            <Typography
              variant='caption'
              noWrap
              sx={chatStyle.chatMessageCaptionStyle}
              minHeight='1rem'
            >
              {chat?.typers?.map((t) => `${t.name} is typing...`).join(', ')}
            </Typography>
          </Stack>
          {uploading && (
            // <ChatMessageListItem chat={chat} message={uploadMessage} />
            <Stack
              sx={{
                ...chatStyle.chatMessageContainer,
                ...chatStyle.chatMessageContainerUser,
              }}
            >
              <Typography
                variant='body2'
                sx={[
                  chatStyle.chatMessageMessage,
                  chatStyle.chatMessageMessageUser,
                  chatStyle.chatMessageMessagePending,
                  {
                    flexDirection: 'row',
                    gap: '0.5rem',
                    justifyContent: 'center',
                  },
                ]}
              >
                <CircularProgress size={'1.2rem'} color={'inherit'} />
                {`uploading ${uploading}`}
              </Typography>
            </Stack>
          )}
          {list?.map((message: ChatMessage, index: number) => (
            <Box key={index} sx={chatStyle.chatMessageSelectorContainer}>
              {selectedList && !message.hide && (
                <Checkbox
                  checked={selectedList.includes(message)}
                  sx={chatStyle.chatMessageSelector}
                  onChange={(e) => {
                    onSelectMessage &&
                      onSelectMessage(message, e.target.checked);
                  }}
                  inputProps={{
                    'aria-label': 'Select Message',
                  }}
                />
              )}
              {!message.hide && (
                <ChatMessageListItem
                  chat={chat}
                  message={message}
                  onRightClick={onContextMenu}
                  onClick={selectedList && onSelectMessage}
                  onDownload={onDownloadAttachment}
                  onView={onViewAttachment}
                  onRetrySendMessage={onRetrySendMessage}
                />
              )}
            </Box>
          ))}
        </InfiniteScroll>
      </Box>
      <Menu
        open={Boolean(anchor)}
        anchorEl={anchor}
        onClose={closeContextMenu}
        onBlur={closeContextMenu}
        TransitionComponent={Grow}
        MenuListProps={{ onMouseLeave: closeContextMenu }}
        PaperProps={{ sx: chatStyle.chatMessageContextMenu }}
        keepMounted
      >
        {menuOptions
          .filter((option) => selectedMessage?.fromUser || !option.userOnly)
          .map((option, index) => (
            <MenuItem
              key={index}
              onClick={option.action}
              sx={chatStyle.chatMessageContextMenuItem}
            >
              <ListItemIcon>{option.icon}</ListItemIcon>
              <ListItemText>
                <Typography variant='body2'>{option.text}</Typography>
              </ListItemText>
            </MenuItem>
          ))}
      </Menu>
      <Dialog
        onClose={closeDeleteDialog}
        open={deleteDialogOpen}
        sx={chatStyle.chatDialog}
      >
        <DialogTitle sx={{ p: '1rem 1.5rem' }}>
          <Box sx={chatStyle.chatDialogHeader}>
            <Box sx={chatStyle.chatDialogHeaderIcon}>
              <DeleteOutline color='error' />
            </Box>
            <IconButton onClick={closeDeleteDialog}>
              <CloseRounded />
            </IconButton>
          </Box>
          <Typography sx={chatStyle.chatDialogTitle}>
            {deleteAll ? 'Delete for everyone?' : 'Delete for you?'}
          </Typography>
          <Typography variant='subtitle2' sx={chatStyle.chatDialogSubtitle}>
            {deleteAll
              ? 'This message will no longer appear in the chat history for anyone.'
              : 'This message will still be visible to others in the chat.'}
          </Typography>
        </DialogTitle>
        <DialogActions sx={chatStyle.chatDialogActions}>
          <Button variant='outlined' onClick={closeDeleteDialog}>
            Cancel
          </Button>
          <Button
            variant='contained'
            onClick={() => {
              selectedMessage &&
                onDeleteMessage &&
                onDeleteMessage(selectedMessage, deleteAll);
              closeDeleteDialog();
            }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <ChatAttachmentDialog
        attachment={attachment}
        onDownload={onDownloadAttachment}
        onClose={() => setAttachment(undefined)}
      />
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={showSnackbar}
        message='Message text copied to clipboard'
        autoHideDuration={4000}
        onClose={() => {
          if (showSnackbar) setShowSnackbar(false);
        }}
        sx={chatStyle.chatMessageListSnackbar}
      />
      <a download hidden ref={downloadRef} href='/' style={{ display: 'none' }}>
        download
      </a>
    </Box>
  );
};

export default ChatMessageList;
