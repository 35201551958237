import {
  Autocomplete,
  Box,
  CircularProgress,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import _ from 'lodash';
import { SyntheticEvent, useState } from 'react';
import { TEST_CONSTANTS } from '../../../../constants/testConstants';
import { chatStyle } from '../../../../style/dashboardStyles/chatStyle';
import { ChatUser } from '../../../../types/chatTypes';
import ChatUserItem from './ChatUserItem';

const ChatSelectUserBox = ({
  list,
  recipients,
  onChange,
  onSelect,
  onDelete,
  loading,
}: {
  list?: ChatUser[];
  recipients?: ChatUser[];
  onChange?: (value: string) => void;
  onSelect?: (user: ChatUser) => void;
  onDelete?: (user: ChatUser) => void;
  loading?: boolean;
}) => {
  const [highlight, setHighlight] = useState<string>('');

  const onOptionChange = (
    e: SyntheticEvent,
    user: ChatUser | string | null
  ) => {
    if (typeof user !== 'string' && onSelect && user) onSelect(user);
  };

  const handleChange = (text: string) => {
    onChange && onChange(text);
    setHighlight(text);
  };
  const debounceChange = _.debounce(handleChange, 1500);

  return (
    <Box
      sx={chatStyle.chatSelectUserBox}
      data-testid={TEST_CONSTANTS.CHAT_SELECT_USER_BOX}
    >
      {_.isEmpty(recipients) ? (
        <Autocomplete
          sx={chatStyle.chatSelectUserBoxSearch}
          freeSolo
          fullWidth
          clearOnBlur
          onBlur={() => handleChange('')}
          loading={loading}
          options={list || []}
          filterOptions={(x) => x}
          getOptionLabel={(option: ChatUser | string) =>
            (typeof option === 'string' ? option : option.name) ?? null
          }
          renderOption={(props, user: ChatUser) =>
            ChatUserItem({ props, user, highlight })
          }
          PaperComponent={(paperComponentProps) =>
            list?.length || loading ? (
              <Paper
                elevation={3}
                data-testid={TEST_CONSTANTS.CHAT_SELECT_USER_BOX_SEARCH_RESULT}
                sx={chatStyle.chatSelectUserBoxSearchDropdown}
                {...paperComponentProps}
              >
                {paperComponentProps.children}
              </Paper>
            ) : null
          }
          renderInput={(params) => (
            <TextField
              {...params}
              variant='standard'
              placeholder='Enter a name'
              sx={chatStyle.chatSelectUserBoxTextField}
              onChange={(e) => debounceChange(e.currentTarget.value)}
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <Typography
                    variant='subtitle2'
                    paddingRight={'0.25rem'}
                    paddingLeft={'0.5rem'}
                  >
                    To:
                  </Typography>
                ),
                disableUnderline: true,
              }}
            />
          )}
          onChange={onOptionChange}
        />
      ) : (
        //can change this to list all recipients when group chat is supported
        <>
          <ChatUserItem
            user={recipients![0]}
            onDelete={!loading ? onDelete : undefined}
          />
          {loading && (
            <Box
              paddingRight={'1rem'}
              sx={chatStyle.chatListItemSecondaryContainer}
            >
              {<CircularProgress size='1.5rem' />}
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default ChatSelectUserBox;
