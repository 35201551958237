import axios, { AxiosRequestConfig } from 'axios';
import mem from 'mem';

import { AUTH_URLS } from '../constants/urlConstants';
import localStorageHelper from './localStorageHelper';

// can't share this functionality with renewToken in authHelper due to cyclic dependency issue...
export const ACCESS_TOKEN_EXPIRATION = 900;

export async function refreshToken(
  config?: AxiosRequestConfig
): Promise<string | null> {
  // Check if the access token is due for renewal to prevent unnecessary token refreshes
  const currentDate = Date.now() / 1000;
  const accessToken = localStorageHelper.getAuthToken();
  const refreshToken = localStorageHelper.getRefreshToken();
  const accessTokenExp = localStorageHelper.getAuthTokenExpiration();

  if (
    !accessTokenExp ||
    !accessToken ||
    !refreshToken ||
    // We'll also want to renew the token even before it expires for a smoother experience.
    accessTokenExp - currentDate >
      ACCESS_TOKEN_EXPIRATION - ACCESS_TOKEN_EXPIRATION / 10
  )
    return null;

  // use a different axios instance, so it won't trigger an interceptor recursion.
  const axiosRef = axios.create({ timeout: 25_000 });

  try {
    const { data } = await axiosRef.post<
      Record<'AccessToken' | 'AccessTokenExpiryTime', string>
    >(
      AUTH_URLS.REFRESH_TOKEN,
      {
        accessToken,
        refreshToken,
      },
      config
    );

    const { AccessToken } = data;
    localStorageHelper.saveAuthToken(AccessToken);
    return AccessToken;
  } catch (e) {
    //log the user out?
    return null;
  }
}

// This memoised function prevents multiple calls to the refresh token API
export const memoRefreshToken = mem(refreshToken, { maxAge: 10_000 });
