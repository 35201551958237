import { type ComponentProps } from 'react';
import { Box, CircularProgress } from '@mui/material';
import { loaderOverlayStyle } from '../style/loaderOverlayStyle';

// Use this to show a loader that spans the entire page
export const LoaderOverlay = (props: ComponentProps<typeof Box>) => {
  return (
    <Box sx={loaderOverlayStyle.container} {...props}>
      <CircularProgress />
    </Box>
  );
};
