import GifIcon from '@mui/icons-material/Gif';
import {
  Box,
  ListItemAvatar,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';
import { MutableRefObject } from 'react';
import Highlighter from 'react-highlight-words';
import TodayTime from '../../../../components/TodayTime';
import { TEST_CONSTANTS } from '../../../../constants/testConstants';
import { chatStyle } from '../../../../style/dashboardStyles/chatStyle';
import { ChatChannel, MessageType } from '../../../../types/chatTypes';
import AttachmentIcon from './AttachmentIcon';
import ChatAvatar from './ChatAvatar';

const ChatListItem = ({
  chat,
  selected,
  onSelect,
  refObj,
}: {
  chat?: ChatChannel;
  selected?: boolean;
  onSelect?: (chat?: ChatChannel) => void;
  refObj?: MutableRefObject<HTMLElement | null>;
}) => {
  const unread = chat?.unreadMessageCount ?? 0;
  const hasUnread = unread > 0;
  const hasUnreadStyle = hasUnread ? chatStyle.chatListItemHasUnread : {};
  const pendingStyle = chat?.pending ? chatStyle.chatListItemPending : {};
  let caption = chat?.lastMessage?.messageText
    ? !!chat?.lastMessage?.metaDictionary?.giphyThumbnailUri
      ? 'GIF'
      : chat?.lastMessage?.messageText
    : chat?.lastMessage?.metaDictionary?.attachmentName;

  let attachmentIcon = <></>;

  switch (chat?.lastMessage?.messageType) {
    case MessageType.attachment:
      attachmentIcon = <AttachmentIcon sx={{ typography: 'subtitle2' }} />;
      break;

    case MessageType.gif:
      attachmentIcon = (
        <Box sx={chatStyle.gifIcon}>
          <GifIcon />
        </Box>
      );
      break;

    default:
  }
  return (
    <Box ref={refObj} sx={pendingStyle}>
      <ListItemButton
        selected={selected}
        onClick={
          selected && !chat?.searchTerm
            ? () => {}
            : () => onSelect && onSelect(chat)
        }
        sx={{ ...chatStyle.chatListItem, ...hasUnreadStyle }}
        data-testid={TEST_CONSTANTS.CHAT_LIST_ITEM}
      >
        <ListItemAvatar>
          <ChatAvatar chat={chat} />
        </ListItemAvatar>
        <ListItemText
          disableTypography
          sx={chatStyle.chatListItemTextHighlight}
          primary={
            <Typography variant='body2'>
              <Highlighter
                searchWords={[chat?.searchTerm ?? '']}
                textToHighlight={chat?.name ?? ''}
              />
            </Typography>
          }
          secondary={
            <Box sx={chatStyle.chatListItemSecondaryText}>
              {attachmentIcon}
              <Highlighter
                searchWords={[chat?.searchTerm ?? '']}
                textToHighlight={caption ?? ''}
              />
            </Box>
          }
        />
        <ListItemSecondaryAction sx={chatStyle.chatListItemSecondary}>
          <Stack spacing={'0.5rem'}>
            <TodayTime>
              {chat?.timestamp ??
                chat?.lastUpdatedTimestampUtc ??
                chat?.createdTimeUtc}
            </TodayTime>
            <Box sx={chatStyle.chatListItemSecondaryContainer}>
              {hasUnread && (
                <Box sx={chatStyle.chatListItemSecondaryNotification}>
                  {chat?.unreadMessageCount}
                </Box>
              )}
            </Box>
          </Stack>
        </ListItemSecondaryAction>
      </ListItemButton>
    </Box>
  );
};

export default ChatListItem;
