import { createApi } from '@reduxjs/toolkit/query/react';
import { USER_URLS } from 'constants/urlConstants';
import baseQueryWithReauth from './serviceQuery';

/**
 * Person API
 */
const personService = createApi({
  reducerPath: 'personApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (build) => ({
    postProfileImg: build.mutation<void, { imgUrl: string }>({
      query: ({ imgUrl }) => ({
        url: USER_URLS.SET_PROFILE_IMAGE,
        method: 'POST',
        body: {
          profileImage: imgUrl,
        },
      }),
    }),
  }),
});

export const { usePostProfileImgMutation } = personService;

export default personService;
