import { CloseRounded } from '@mui/icons-material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ClearIcon from '@mui/icons-material/Clear';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  TextField,
} from '@mui/material';
import _ from 'lodash';
import {
  ChangeEvent,
  ReactNode,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { showSnackbarAction } from 'store/actions/snackbarAction';
import { useAppSelector } from '../../../app/hooks';
import FlatButton from '../../../components/FlatButton';
import {
  MAX_CUSTOM_ROLE_CHAR,
  ROLE_OTHER,
} from '../../../constants/profileConstants';
import { ROUTE_CONSTANTS_VARIABLE } from '../../../constants/routeConstants';
import { TEST_CONSTANTS } from '../../../constants/testConstants';
import {
  isEmailValid,
  requireValidate,
} from '../../../helpers/validationHelper';
import {
  useAddTeamMemberMutation,
  useGetRolesofLoggedInUserPracticeQuery,
} from '../../../services/myOfficeService';
import { commonClasses } from '../../../style/commonClasses';
import { myOfficeStyle } from '../../../style/dashboardStyles/myOfficeStyle';
import {
  AddTeamMemberPropsType,
  MyOfficeAddMemberFormType,
} from '../../../types/myOfficeType';

const AddTeamMember = (props: AddTeamMemberPropsType) => {
  const navigate = useNavigate();
  const {
    isAddMemberDialogOpen,
    setIsAddMemberDialogOpen,
    isRequestedFromMyOffice,
  } = props;
  const userInfo = useAppSelector((state) => state.userReducer.userInfo);
  const { roles } = useAppSelector(
    ({ myOfficeReducer }: Record<string, any>) => myOfficeReducer ?? {}
  );
  const [addTeamMember, { isLoading: isFormSubmitLoaderActive }] =
    useAddTeamMemberMutation();
  const customRolesMenuAnchor = useRef<HTMLDivElement>(null);
  const customRoleInputRef = useRef<HTMLElement>(null);
  const [isCustomRole, setIsCustomRole] = useState<boolean>(false);
  const [isCustomRolesMenuOpen, setIsCustomRolesMenuOpen] =
    useState<boolean>(false);
  useGetRolesofLoggedInUserPracticeQuery(userInfo?.practiceId, {
    skip: !userInfo?.practiceId,
  });
  const roleList = useMemo<readonly string[]>(() => {
    return _.isEmpty(roles)
      ? [ROLE_OTHER]
      : isCustomRole
      ? roles
      : [...roles, ROLE_OTHER];
  }, [roles, isCustomRole]);

  const [addMemberForm, setAddMemberForm] = useState<MyOfficeAddMemberFormType>(
    { email: '', role: '' }
  );
  const [addMemberFormErrors, setAddMemberFormErrors] = useState<
    Partial<MyOfficeAddMemberFormType>
  >({});
  const [isFormSubmittedOnce, setIsFormSubmittedOnce] =
    useState<boolean>(false);

  const isAddMemberFormValid = (form: Partial<MyOfficeAddMemberFormType>) => {
    let isFormValid = true;
    let updatedErrors = addMemberFormErrors;

    for (const key in form) {
      switch (key) {
        case 'email':
          {
            const emailValidation = isEmailValid('email', form.email!);
            if (!emailValidation.status) {
              isFormValid = false;
              updatedErrors[key] = emailValidation.message;
            } else {
              delete updatedErrors[key];
            }
          }
          break;

        case 'role':
          {
            const roleValidation = requireValidate(
              'Role must be selected',
              form.role!
            );

            if (!roleValidation.status) {
              isFormValid = false;
              updatedErrors[key] = roleValidation.message;
            } else {
              delete updatedErrors[key];
            }
          }
          break;
      }
    }
    setAddMemberFormErrors(updatedErrors);
    return isFormValid;
  };

  const onClickInviteMember = async () => {
    if (!isFormSubmittedOnce) {
      setIsFormSubmittedOnce(true);
    }
    if (isAddMemberFormValid(addMemberForm)) {
      await addTeamMember(addMemberForm)
        .unwrap()
        .then(() => {
          setAddMemberForm({ email: '', role: '' });
          setIsAddMemberDialogOpen(false);
          if (!isRequestedFromMyOffice) {
            navigate(ROUTE_CONSTANTS_VARIABLE.MY_OFFICE);
          }
        })
        .catch((e: any) => {
          showSnackbarAction({
            open: true,
            statusCode: e?.status,
            message:
              e?.status === 400
                ? e?.data?.Message ?? 'Email is already used in another invite.'
                : e?.ExceptionMessage ?? e?.Message,
            severity: 'error',
          });
        });
    }
  };

  const onEmailValueChange = (event: ChangeEvent<HTMLInputElement>) => {
    setAddMemberForm({ ...addMemberForm, email: event.target.value });
    if (isFormSubmittedOnce) {
      isAddMemberFormValid({ email: event.target.value });
    }
  };

  function handleRoleChange(selectedRole: string) {
    if (selectedRole === ROLE_OTHER) {
      setAddMemberForm({ ...addMemberForm, role: '' });
      setIsCustomRole(true);

      return;
    }

    setAddMemberForm({ ...addMemberForm, role: selectedRole });

    if (isFormSubmittedOnce) {
      isAddMemberFormValid({ role: selectedRole });
    }
  }

  function handleToggleCustomRoleMenu(): void {
    setIsCustomRolesMenuOpen(!isCustomRolesMenuOpen);
  }

  function renderRoleList(): ReturnType<typeof MenuItem>[] {
    return roleList?.map((role: string) => (
      <MenuItem
        key={role}
        value={role}
        onClick={
          isCustomRole
            ? () => {
                setAddMemberForm({ ...addMemberForm, role: role });
                setIsCustomRole(false);
                handleToggleCustomRoleMenu();
              }
            : undefined
        }
      >
        {role}
      </MenuItem>
    ));
  }

  function onDeleteCustomRole(): void {
    handleRoleChange('');
  }

  function renderCustomRoleInputAdornment(): ReactNode {
    return (
      <InputAdornment position='end'>
        {isCustomRole ? (
          <IconButton
            onClick={
              _.isEmpty(addMemberForm.role)
                ? handleToggleCustomRoleMenu
                : onDeleteCustomRole
            }
            disableRipple
          >
            {!_.isEmpty(addMemberForm.role) ? (
              <ClearIcon />
            ) : isCustomRolesMenuOpen ? (
              <ArrowDropUpIcon />
            ) : (
              <ArrowDropDownIcon />
            )}
          </IconButton>
        ) : null}
      </InputAdornment>
    );
  }

  useEffect(() => {
    if (isCustomRole && _.isEmpty(addMemberForm.role)) {
      customRoleInputRef.current?.focus();
    }
  }, [isCustomRole, addMemberForm.role]);

  return (
    <Dialog
      open={isAddMemberDialogOpen}
      data-testid={TEST_CONSTANTS.MY_OFFICE_ADD_MEMBER_DIALOG}
      onClose={() => setIsAddMemberDialogOpen(false)}
      aria-labelledby='add team member'
      aria-describedby='add team member'
    >
      <DialogTitle sx={{ p: '.75rem 1rem .5rem' }}>
        <Box sx={myOfficeStyle.addMemberModalHeader}>
          <GroupAddOutlinedIcon
            sx={myOfficeStyle.addMemberModalHeaderIcon}
            color='primary'
          />
          <IconButton onClick={() => setIsAddMemberDialogOpen(false)}>
            <CloseRounded />
          </IconButton>
        </Box>
        <Box sx={myOfficeStyle.addMemberTitle}>Add team member</Box>
        <Box sx={myOfficeStyle.addMemberSubtitle}>
          Invite a team member to your practice
        </Box>
      </DialogTitle>

      <DialogContent sx={myOfficeStyle.addMemberDialogContent}>
        <form noValidate>
          <TextField
            fullWidth
            required
            type='email'
            inputProps={{
              'data-testid':
                TEST_CONSTANTS.MY_OFFICE_ADD_MEMBER_EMAIL_TEXT_FIELD,
            }}
            sx={addMemberFormErrors.email ? commonClasses.fieldError : {}}
            value={addMemberForm.email}
            onChange={onEmailValueChange}
            variant='outlined'
            label='Email'
            placeholder='Enter an email'
            helperText={addMemberFormErrors.email}
          />

          <TextField
            fullWidth
            data-testid={TEST_CONSTANTS.MY_OFFICE_ADD_MEMBER_ROLE_SELECT}
            required
            label='Role'
            value={addMemberForm.role}
            ref={customRolesMenuAnchor}
            inputRef={customRoleInputRef}
            onChange={(event) => handleRoleChange(event.target.value)}
            select={!isCustomRole}
            placeholder={isCustomRole ? ROLE_OTHER : ''}
            helperText={addMemberFormErrors.role}
            inputProps={{ maxLength: MAX_CUSTOM_ROLE_CHAR }}
            InputLabelProps={{
              shrink: (!isCustomRole && !!addMemberForm.role) || isCustomRole,
            }}
            sx={[
              addMemberFormErrors.role ? commonClasses.fieldError : {},
              { mt: '3rem' },
            ]}
            InputProps={{
              style: {
                paddingRight: 0,
              },
              endAdornment: isCustomRole
                ? renderCustomRoleInputAdornment()
                : null,
            }}
          >
            {!_.isEmpty(roleList) && renderRoleList()}
          </TextField>
        </form>
        {isCustomRole && (
          <Menu
            anchorEl={customRolesMenuAnchor.current}
            open={isCustomRolesMenuOpen}
            onClose={handleToggleCustomRoleMenu}
            PaperProps={{
              style: {
                minWidth: '416px',
              },
            }}
          >
            {renderRoleList()}
          </Menu>
        )}
      </DialogContent>
      <DialogActions sx={myOfficeStyle.addMemberActionButtonContainer}>
        <FlatButton
          variant='contained'
          onClick={onClickInviteMember}
          isLoading={isFormSubmitLoaderActive}
        >
          Invite
        </FlatButton>
      </DialogActions>
    </Dialog>
  );
};

export default AddTeamMember;
