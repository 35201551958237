import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as DrtalkLogo } from '../../../assets/images/drTalkLogo.svg';
import FlatButton from '../../../components/FlatButton';
import localStorageHelper from '../../../helpers/localStorageHelper';
import { logoutUser } from '../../../helpers/logoutHelper';
import { commonAuthStyle } from '../../../style/commonAuthStyle';
import { onboardingStyle } from '../../../style/onboardingStyle';

const OnboardingPendingApprovalDialog = () => {
  const isOrganizationPendingApproval =
    localStorageHelper.getOrganizationApprovalStatus();
  const navigate = useNavigate();
  const onClickBackToLogin = () => {
    logoutUser(navigate);
  };

  return (
    <Box sx={commonAuthStyle.authPage}>
      <Box sx={commonAuthStyle.authContainer}>
        <DrtalkLogo />
        <Box sx={onboardingStyle.backToHomepageDialog.title}>
          {isOrganizationPendingApproval
            ? 'Organization Pending Approval'
            : 'Activation Pending Approval'}
        </Box>
        <Box sx={onboardingStyle.backToHomepageDialog.content}>
          <Box>Your account activation is pending approval.</Box>
          <Box>
            An email will be sent to you once your account is activated. After
            your account is activated you'll be able to use the same credentials
            to login to your account.
          </Box>
          <Box>Thank you!</Box>
        </Box>
        <FlatButton
          variant='contained'
          sx={commonAuthStyle.submitButton}
          onClick={onClickBackToLogin}
        >
          Back to Login Page
        </FlatButton>
      </Box>
    </Box>
  );
};

export default OnboardingPendingApprovalDialog;
