import { Box, Button, Link, Tab, Tabs } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as DrTalkTextLogo } from '../../../assets/images/drTalkTextLogo.svg';
import FlatButton from '../../../components/FlatButton';
import { introductionScreenTabs } from '../../../constants/introductionScreenConstants';
import { ROUTE_CONSTANTS_VARIABLE } from '../../../constants/routeConstants';
import { introductionScreenStyle } from '../../../style/authStyle/introductionScreenStyle';
import { IntroductionScreenNavgationTab } from '../../../types/authTypes';

const IntroductionScreenHeader = () => {
  const navigate = useNavigate();

  const onClickLogin = () => {
    navigate(ROUTE_CONSTANTS_VARIABLE.LOGIN);
  };

  return (
    <Box sx={introductionScreenStyle.introductionHeaderMainContainer}>
      <Box>
        <DrTalkTextLogo />
        <Tabs value={0}>
          {introductionScreenTabs.map(
            ({ name, navigateTo }: IntroductionScreenNavgationTab) => (
              <Tab
                key={name}
                label={name}
                component={Link}
                href={navigateTo ?? ''}
                target='_blank'
              />
            )
          )}
        </Tabs>
      </Box>
      <Box>
        <FlatButton variant='text' onClick={onClickLogin}>
          Log In
        </FlatButton>
        <Button variant='contained'>Try for free</Button>
      </Box>
    </Box>
  );
};

export default IntroductionScreenHeader;
