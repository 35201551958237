import { TableCell, TableRow, Typography } from '@mui/material';
import { ReactComponent as NoDataImage } from '../../../../../src/assets/images/no_fax_table_record.svg';
import { useAppSelector } from '../../../../app/hooks';
import { efaxStyle } from '../../../../style/dashboardStyles/efaxStyle';

const NoEfaxMessage = () => {
  const { selectedTab } = useAppSelector((state) => state.efaxReducer);
  const isReceivedTabSelected = selectedTab === 0;

  return (
    <TableRow>
      <TableCell colSpan={5} align='center' sx={efaxStyle.noEfaxTableImage}>
        <NoDataImage />
        <Typography variant='h6' fontWeight={500} marginTop={0.5}>
          No Fax {isReceivedTabSelected ? 'received' : 'sent'}
        </Typography>
        <Typography component='div' variant='caption' marginTop={0.5}>
          {isReceivedTabSelected
            ? 'Your incoming faxes will appear here'
            : 'Faxes you sent will appear here'}
        </Typography>
      </TableCell>
    </TableRow>
  );
};

export default NoEfaxMessage;
