import { Box, TextField } from '@mui/material';
import { useCallback, useEffect, useState, type ChangeEvent } from 'react';

import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import FlatButton from '../../../components/FlatButton';
import { NewPasswordCriteria } from '../../../components/NewPasswordCriteria';
import { passwordValidationMessages } from '../../../constants/validationMessageConstants';
import { passwordValidation } from '../../../helpers/validationHelper';
import {
  changePassword,
  clearErrorMessage
} from '../../../store/actions/authActions';
import { profileStyle } from '../../../style/profileStyle';

type FormFields = Record<
  'currentPassword' | 'newPassword' | 'newPasswordConfirm',
  string
>;
type ValidationMessageList = Record<
  keyof FormFields,
  keyof typeof passwordValidationMessages | null
>;

export const Password = () => {
  const dispatch = useAppDispatch();

  const { changePasswordLoader, errorMessage, userInfo } = useAppSelector(
    (state) => ({
      changePasswordLoader: !!state.generalLoaderReducer?.changePassword,
      errorMessage: state.authReducer?.errorMessage,
      userInfo: state.userReducer?.userInfo,
    })
  );

  const [submissionStatus, setSubmissionStatus] = useState<
    Record<'canSubmit' | 'hasClickedSubmit', boolean>
  >({
    canSubmit: false,
    hasClickedSubmit: false,
  });
  const [formFields, setFormFields] = useState<FormFields>({
    currentPassword: '',
    newPassword: '',
    newPasswordConfirm: '',
  });
  const [validationMessageKeys, setValidationMessageKeys] =
    useState<ValidationMessageList>({
      currentPassword: null,
      newPassword: null,
      newPasswordConfirm: null,
    });

  useEffect(() => {
    function isFormComplete(): boolean {
      const keys = Object.keys(validationMessageKeys) as ReadonlyArray<
        keyof ValidationMessageList
      >;
      for (const field of keys) {
        if (formFields[field] === '' || validationMessageKeys[field] !== null)
          return false;
      }

      return true;
    }

    const canSubmit = isFormComplete();
    setSubmissionStatus((prevState) => ({ ...prevState, canSubmit }));
  }, [formFields, validationMessageKeys]);

  const comparePassword = useCallback(
    (
      key: Exclude<keyof FormFields, 'currentPassword'>,
      value: string
    ): boolean => {
      const passwordToCompare =
        key === 'newPassword'
          ? formFields.newPasswordConfirm
          : formFields.newPassword;
      return value === passwordToCompare;
    },
    [formFields]
  );

  const getValidationKey = useCallback(
    <K extends keyof FormFields, V extends FormFields[K]>(
      key: K,
      value: V
    ): keyof typeof passwordValidationMessages | null => {
      if (!formFields[key]) return 'REQUIRED_FIELD';
      if (key === 'currentPassword') return null;
      if (!isPasswordValid(value)) return 'INVALID_PASSWORD';
      if (!comparePassword(key, value))
        return key === 'newPasswordConfirm' ? 'PASSWORDS_NOT_MATCH' : null;
      return null;
    },
    [comparePassword, formFields]
  );

  useEffect(() => {
    if (!errorMessage || errorMessage?.type !== 'changePassword') return;

    setValidationMessageKeys((prevState) => ({
      ...prevState,
      currentPassword: errorMessage.message,
    }));

    return () => {
      dispatch(clearErrorMessage());
    };
  }, [dispatch, errorMessage]);

  useEffect(() => {
    const { currentPassword, newPassword, newPasswordConfirm } = formFields;
    setValidationMessageKeys({
      currentPassword: getValidationKey('currentPassword', currentPassword),
      newPassword: getValidationKey('newPassword', newPassword),
      newPasswordConfirm: getValidationKey(
        'newPasswordConfirm',
        newPasswordConfirm
      ),
    });
  }, [formFields, getValidationKey]);

  /**
   * Password criteria :
   * - 8 - 20 characters
   * - At least one uppercase
   * - At least one lowercase
   * - At least one number
   * - At least one special character
   */
  function isPasswordValid(value: string): boolean {
    const { length, uppercase, lowercase, number, special } =
      passwordValidation(value);
    return length && uppercase && lowercase && number && special;
  }

  function onChangeState(
    formKey: keyof FormFields
  ): (e: ChangeEvent<HTMLInputElement>) => void {
    return (e) => {
      const fieldValue = e.target.value;
      setFormFields((prevState) => ({ ...prevState, [formKey]: fieldValue }));
    };
  }

  function initialiseSubmit(): void {
    setSubmissionStatus((prevState) => ({
      ...prevState,
      hasClickedSubmit: true,
    }));
  }

  function onSubmitForm(): void {
    if (!submissionStatus.hasClickedSubmit) initialiseSubmit();

    if (!submissionStatus.canSubmit) return;

    if (!userInfo) return;

    dispatch(
      changePassword(
        userInfo.email,
        formFields.currentPassword,
        formFields.newPassword
      )
    );
  }

  function getErrorMessage(formKey: keyof ValidationMessageList): string {
    const validationKey = validationMessageKeys[formKey];
    if (!submissionStatus.hasClickedSubmit || !validationKey) return '';
    return passwordValidationMessages[validationKey];
  }

  function getNewPasswordMessage(
    type: Exclude<keyof FormFields, 'currentPassword'>
  ): string | ReturnType<typeof NewPasswordCriteria> {
    const validationKey = validationMessageKeys[type];

    if (validationKey === 'INVALID_PASSWORD') {
      return <NewPasswordCriteria password={formFields[type]} />;
    }

    if (!submissionStatus.hasClickedSubmit || !validationKey) return '';

    return getErrorMessage(type);
  }

  function getClassName(
    type: Exclude<keyof FormFields, 'currentPassword'>
  ): string {
    if (
      !submissionStatus.hasClickedSubmit ||
      validationMessageKeys[type] !== 'INVALID_PASSWORD'
    )
      return 'new-password';
    return 'new-password invalid-password';
  }

  return (
    <Box
      sx={{
        ...profileStyle.form.container,
        paddingTop: '0.25rem',
        overflowY: 'auto',
      }}
    >
      <Box sx={profileStyle.form.textFieldContainer}>
        <TextField
          helperText={getErrorMessage('currentPassword')}
          label='Current password'
          onChange={onChangeState('currentPassword')}
          type='password'
          value={formFields.currentPassword}
        />
        <TextField
          className={getClassName('newPassword')}
          helperText={getNewPasswordMessage('newPassword')}
          label='New password'
          onChange={onChangeState('newPassword')}
          placeholder='Enter a new password'
          type='password'
          value={formFields.newPassword}
        />
        <TextField
          className={getClassName('newPasswordConfirm')}
          helperText={getNewPasswordMessage('newPasswordConfirm')}
          label='Confirm password'
          onChange={onChangeState('newPasswordConfirm')}
          placeholder='Confirm new password'
          type='password'
          value={formFields.newPasswordConfirm}
        />
      </Box>
      <FlatButton
        color='primary'
        onClick={onSubmitForm}
        sx={profileStyle.form.updateButton}
        variant='contained'
        isLoading={changePasswordLoader}
      >
        Update profile
      </FlatButton>
    </Box>
  );
};
