import {
  DefaultHttpClient,
  HttpError,
  HubConnection,
  HubConnectionBuilder,
  HubConnectionState,
} from '@microsoft/signalr';
import _ from 'lodash';
import {
  UpdateProfileImageSuccess,
  UserApiActions,
  UserInfo,
} from 'store/reduxConstants/userReduxConstants';
import { memoRefreshToken } from '../helpers/refreshTokenHelper';
import { MarkChannel } from '../services/chatService';
import { AUTH_REDUX_CONSTANTS } from '../store/reduxConstants/authReduxConstants';
import { DOCUMENT_REDUX_CONSTANTS } from '../store/reduxConstants/documentsReduxConstants';
import { EFAX_REDUX_CONSTANTS } from '../store/reduxConstants/efaxReduxConstants';
import {
  addChannel,
  addMessage,
  removeMembers,
  updateChannel,
  updateMarked,
  updateMessage,
  userTyping,
  updateContactProfilePhoto,
} from '../store/slices/chatSlice';
import {
  newAcceptedDocument as notifyAcceptedDocument,
  newDocument as notifyNewDocument,
  newFax as notifyNewFax,
  newInvitation as notifyNewInvitation,
  newMessage as notifyNewMessage,
  newOpenedDocument as notifyOpenedDocument,
  newRejectedDocument as notifyRejectedDocument,
  updateChannelCount as notifyUpdateChannelCount,
} from '../store/slices/notificationSlice';
import { AppDispatch, RootState } from '../store/store';
import { ChatChannel, ChatMessage, MarkedReceipts } from '../types/chatTypes';
import { DocumentsTableDetailsType } from '../types/documentsTypes';
import { ContactHistory, EfaxContact, EfaxTableDetailsType } from '../types/efaxTypes';
import {
  InvitationListResponseType,
  PracticeListResponseData,
} from '../types/myNetworkTypes';
import { getHubAuthToken, setHubAuthToken } from './token';
import { SyncUserInfo } from 'types/apiResponses/UserApiResponses';
import { pendingConnectionRequestAccepted } from 'store/slices/myNetworkSlice';

enum HubEvent {
  Connect = 'connect',
  Error = 'error',
}

enum ChatEvent {
  ChannelCreated = 'ChannelCreated',
  ChannelUpdated = 'ChannelUpdated',
  MessageCreated = 'MessageCreated',
  MessageUpdated = 'MessageUpdated',
  MessageDeleted = 'MessageDeleted',
  MembersRemoved = 'MembersRemoved',
  MarkedAsDelivered = 'MarkedAsDelivered',
  MarkedAsRead = 'MarkedAsRead',
  MarkedAsArchived = 'MarkedAsArchived',
  MarkedAsFlagged = 'MarkedAsFlagged',
  UserTyping = 'UserTyping',
}

enum ChatAction {
  UserTyping = 'UserTyping',
}

enum DocumentEvent {
  SharedDocumentCreated = 'SharedDocumentCreated',
  SharedDocumentRejected = 'SharedDocumentRejected',
  SharedDocumentAccepted = 'SharedDocumentAccepted',
  SecureEmailOpened = 'SecureEmailOpened',
  SharedDocumentUpdated = 'SharedDocumentUpdated',
}

enum FaxEvent {
  EfaxSent = 'EfaxSent',
  eFaxMessageMarkedAsProcessed = 'eFaxMessageMarkedAsProcessed',
  eFaxArchiveUnarchive = 'eFaxArchiveUnarchive',
  EFaxContactSaved = 'EFaxContactSaved',
}

enum NetworkEvent {
  PracticeInviteReceived = 'PracticeInviteReceived',
  PracticeInviteAccepted = 'PracticeInviteAccepted',
}

enum ForceLogoutEvent {
  UserForceLogout = 'UserForceLogout',
}

enum PersonsEvent {
  ContactUpdated = 'ContactUpdated',
  UpdateProfileImage = 'UpdateProfileImage',
}

let hubConnection: HubConnection;

class httpClient extends DefaultHttpClient {
  constructor() {
    super(console);
  }
  public async send(
    request: signalR.HttpRequest
  ): Promise<signalR.HttpResponse> {
    try {
      const response = await super.send(request);
      return response;
    } catch (e) {
      if (e instanceof HttpError) {
        if ((e as HttpError).statusCode === 401) {
          const token = await memoRefreshToken();
          request.headers = {
            ...request.headers,
            ...{ Authorization: `Bearer ${token}` },
          };
        }
      } else {
        throw e;
      }
    }
    //re try the request
    const response = await super.send(request);
    return response;
  }
}

const getHubConnection = (url: string, token: string) => {
  setHubAuthToken(token);
  if (!hubConnection) {
    hubConnection = new HubConnectionBuilder()
      .withUrl(url, {
        httpClient: new httpClient(),
        accessTokenFactory: getHubAuthToken,
      })
      .withAutomaticReconnect()
      .build();
  }
};

let timers: Record<string, number> = {};
export const startHub = (
  url: string,
  token: string,
  dispatch: AppDispatch,
  getState: () => RootState
) => {
  //if no token set then just return false
  if (_.isEmpty(token)) return Promise.resolve(false);

  getHubConnection(url, token);

  //detect collisions, shouldn't happen in prod
  if (
    hubConnection.state &&
    hubConnection.state !== HubConnectionState.Disconnected
  ) {
    return Promise.resolve(
      hubConnection.state === HubConnectionState.Connected
    );
  }

  //add the events
  hubConnection.on(HubEvent.Connect, () => {
    console.log('notificationHub', HubEvent.Connect, 'connected');
  });

  hubConnection.on(HubEvent.Error, (e) => {
    console.log('notificationHub', HubEvent.Error, e);
  });

  hubConnection.on(ChatEvent.ChannelCreated, (channel: ChatChannel) => {
    dispatch(addChannel({ channel }));
  });

  hubConnection.on(ChatEvent.ChannelUpdated, (data) => {
    const { channel } = data;

    dispatch(updateChannel({ channel }));
    dispatch(notifyUpdateChannelCount());
  });

  hubConnection.on(
    ChatEvent.MarkedAsDelivered,
    (markedList: MarkedReceipts) => {
      dispatch(updateMarked({ mark: MarkChannel.delivered, markedList }));
    }
  );

  hubConnection.on(ChatEvent.MarkedAsRead, (markedList: MarkedReceipts) => {
    dispatch(updateMarked({ mark: MarkChannel.read, markedList }));
  });

  hubConnection.on(ChatEvent.MembersRemoved, (channel: ChatChannel) => {
    dispatch(removeMembers({ channel }));
  });

  hubConnection.on(ChatEvent.MessageCreated, (message: ChatMessage) => {
    dispatch(addMessage({ message }));
    dispatch(notifyNewMessage({ message }));
  });

  hubConnection.on(ChatEvent.MessageUpdated, (message: ChatMessage) => {
    dispatch(updateMessage({ message }));
  });

  hubConnection.on(ChatEvent.MessageDeleted, (message: ChatMessage) => {
    dispatch(updateMessage({ message }));
  });

  hubConnection.on(
    ChatEvent.UserTyping,
    (channelId: string, userId: string, fromUserDisplayName: string) => {
      dispatch(
        userTyping({ channelId, userId, fromUserDisplayName, typing: true })
      );
      if (timers[userId]) clearTimeout(timers[userId]);
      timers[userId] = setTimeout(() => {
        dispatch(
          userTyping({ channelId, userId, fromUserDisplayName, typing: false })
        );
      }, 3000) as unknown as number;
    }
  );

  hubConnection.on(
    DocumentEvent.SharedDocumentCreated,
    (document: DocumentsTableDetailsType | string) => {
      const doc =
        typeof document === 'string' ? JSON.parse(document) : document;

      dispatch(notifyNewDocument({ document: doc }));
    }
  );

  hubConnection.on(
    DocumentEvent.SharedDocumentAccepted,
    (document: DocumentsTableDetailsType | string) => {
      const doc =
        typeof document === 'string' ? JSON.parse(document) : document;

      dispatch(notifyAcceptedDocument({ document: doc }));
    }
  );

  hubConnection.on(
    DocumentEvent.SharedDocumentRejected,
    (document: DocumentsTableDetailsType | string) => {
      const doc =
        typeof document === 'string' ? JSON.parse(document) : document;

      if (!!dispatch) {
        dispatch(notifyRejectedDocument({ document: doc }));
      }
    }
  );

  hubConnection.on(
    DocumentEvent.SharedDocumentUpdated,
    (document: DocumentsTableDetailsType | string) => {
      const doc =
        typeof document === 'string' ? JSON.parse(document) : document;
      dispatch({
        type: DOCUMENT_REDUX_CONSTANTS.UPDATE_RECORD_IN_DOCUMENT_LIST,
        payload: doc,
      });
    }
  );

  hubConnection.on(
    DocumentEvent.SecureEmailOpened,
    (document: DocumentsTableDetailsType | string) => {
      const doc =
        typeof document === 'string' ? JSON.parse(document) : document;

      dispatch(notifyOpenedDocument({ document: doc }));
    }
  );

  hubConnection.on(
    NetworkEvent.PracticeInviteReceived,
    (invitation: InvitationListResponseType | string) => {
      const inv =
        typeof invitation === 'string' ? JSON.parse(invitation) : invitation;
      dispatch(notifyNewInvitation({ invitation: inv }));
    }
  );

  hubConnection.on(
    NetworkEvent.PracticeInviteAccepted,
    (practice: PracticeListResponseData) =>
      dispatch(pendingConnectionRequestAccepted(practice))
  );

  hubConnection.on(ForceLogoutEvent.UserForceLogout, () => {
    dispatch({ type: AUTH_REDUX_CONSTANTS.LOGOUT_USER_ACTION });
  });

  hubConnection.on(FaxEvent.EfaxSent, (newFax: EfaxTableDetailsType) => {
    const contactHistory = getState()?.efaxReducer?.contactHistory || [];

    dispatch(
      notifyNewFax({
        newFax,
        contactHistory: contactHistory as ContactHistory[],
      })
    );

    dispatch({
      type: EFAX_REDUX_CONSTANTS.PUSH_NEW_EFAX,
      payload: newFax,
    });
  });

  hubConnection.on(
    FaxEvent.eFaxMessageMarkedAsProcessed,
    (newFax: EfaxTableDetailsType) => {
      dispatch({
        type: EFAX_REDUX_CONSTANTS.ON_PROCESS_EFAX,
        payload: newFax.id,
      });
    }
  );

  hubConnection.on(
    FaxEvent.eFaxArchiveUnarchive,
    (newFax: EfaxTableDetailsType) => {
      dispatch({
        type: EFAX_REDUX_CONSTANTS.ON_ARCHIVE_UNARCHIVE_EFAX,
        payload: newFax,
      });
    }
  );

  hubConnection.on(FaxEvent.EFaxContactSaved, (contact: EfaxContact) => {
    dispatch({
      type: EFAX_REDUX_CONSTANTS.UPDATE_EFAX_CONTACT,
      payload: contact,
    });
  });

  hubConnection.on(
    PersonsEvent.ContactUpdated,
    (newPersonInfo: SyncUserInfo) => {
      dispatch(
        updateContactProfilePhoto({
          contactInfo: newPersonInfo,
        })
      );
    }
  );

  hubConnection.on(
    PersonsEvent.UpdateProfileImage,
    (newPersonInfo: SyncUserInfo) => {
      const currentProfileImg =
        getState()?.userReducer?.userInfo?.profileImage ?? '';

      // since redux object is being updated once user successfully updates the image,
      // will only execute below block once the update was done from other platform/devices
      if (currentProfileImg !== newPersonInfo.profileImage) {
        dispatch<UpdateProfileImageSuccess>({
          type: UserApiActions.UPDATE_PROFILE_IMG_SUCCESS,
          payload: { profileImage: newPersonInfo.profileImage } as Pick<
            UserInfo,
            'profileImage'
          >,
        });
      }
    }
  );

  return new Promise((resolve, reject) => {
    hubConnection
      .start()
      .then((r) => resolve(true))
      .catch((e) => reject(e));
  });
};

export const hubActions = {
  userTyping: (
    fromUserId: string,
    fromUserDisplayName: string,
    channelId: string,
    toUserIds: string[]
  ) =>
    hubConnection?.send(
      ChatAction.UserTyping,
      fromUserId,
      fromUserDisplayName,
      channelId,
      toUserIds
    ) ?? Promise.resolve(false),
};

export const stopHub = () => {
  if (
    !hubConnection ||
    hubConnection?.state === HubConnectionState.Disconnected
  ) {
    return Promise.resolve(false);
  }
  return new Promise((resolve, reject) => {
    hubConnection
      .stop()
      .then((r) => resolve(false))
      .catch((e) => reject(e));
  });
};
