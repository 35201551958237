import { colorPalette } from '../../theme/colorPalette';

export const documentStyle = {
  mainContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    height: 1,
    p: '1rem',
    bgcolor: 'background.default',
    borderRadius: '.5rem',
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    mb: '2rem',
  },
  tabLabel: {
    display: 'flex',
    alignItems: 'center',
    gap: '.5rem',
    '.MuiSvgIcon-root': {
      typography: 'h6',
      fontWeight: 400,
    },
  },
  filterContainer: { display: 'flex', gap: '.75rem', '*': { flexGrow: 1 } },
  filterAutocomplete: {
    '.MuiInputLabel-root': {
      typography: 'subtitle2',
      fontWeight: 400,
      lineHeight: '1.4375em',
    },
    '.MuiAutocomplete-input': {
      typography: 'subtitle2',
      fontWeight: 400,
    },
  },
  sendDocumentsDialog: {
    '.MuiPaper-root.MuiDialog-paper': {
      width: '67.375rem',
      minWidth: '67.375rem',
      background: '#fff',
    },
    title: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      p: '1.5rem',
      borderBottom: '.0625rem solid',
      borderColor: colorPalette.surfaceColor[300],
      text: {
        flex: 1,
        typography: 'h5',
        fontWeight: 400,
        textAlign: 'center',
      },
      closeButton: {
        p: 0,
      },
    },
    content: {
      p: '1.5rem',

      '.MuiDivider-root': {
        m: '1.5rem 0',
      },
    },
    body: {
      display: 'flex',
      gap: '2rem',

      '> *': {
        flex: 1,
        mt: '1rem',
      },
      leftPart: {
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',

        sendAlertMsg: {
          '.MuiAlert-icon': {
            color: '#1B0C56',
          },

          '.MuiAlert-message': {
            color: '#1B0C56',
          },

          background: '#ECE7F5',
          marginBottom: '.5rem',
        },
        practiceAutocomplete: {
          '.MuiOutlinedInput-root': { pr: '2.5rem' },
        },
        '.MuiFormHelperText-root': {
          mt: '0.5rem',
          position: 'relative',
        },
        limitTag: {
          color: '#1B0C56',
          fontWeight: 700,
        },
        resultChip: {
          whiteSpace: 'pre-wrap',
          color: colorPalette.primary[800],
        },
        registeredPractice: {
          background: '#ECE7F5',
        },
        unknownEmail: {
          borderColor: colorPalette.primaryMain,
        },
        practiceField: {
          '.MuiInputBase-root': {
            paddingRight: '3.125rem',
          },

          addressBookButton: {
            position: 'absolute',
            right: '1.25rem',
            top: '0.5rem',
            // backgroundColor: isAddressBookOpen ? '#CAC0F4' : 'inherit',
          },
        },
      },
      rightPart: {
        maxWidth: '350px',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        bgcolor: colorPalette.surfaceColor[200],
        borderRadius: '.25rem',
        tabContent: {
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          flex: 1,
          gap: '1rem',
        },
        uploadContainer: {
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          gap: '.5rem',
          p: '1rem',
          color: colorPalette.surfaceColor[700],
          border: '.0625rem dashed',
          borderColor: 'primary.main',
          borderRadius: '.5rem',
          cursor: 'pointer',
          '&:hover': {
            bgcolor: colorPalette.surfaceColor[300],
          },
        },
        disclaimer: {
          color: colorPalette.surfaceColor[700],
        },
        actionbuttonContainer: {
          p: '0 1rem 1.5rem',
          justifyContent: 'center',
          button: { width: '6.25rem' },
        },
      },
    },
  },
  documentTableAllowedExtensionHeader: {
    mt: '.5rem',
    typography: 'caption',
    fontWeight: 500,
    color: 'primary.main',
  },
  documentTableFirstColumnDetails: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: '.25rem',
    typography: 'subtitle2',
    fontWeight: 400,
  },
  sendDocumentsRejectedFiles: {
    p: '.5rem',
    typography: 'caption',

    '& > *': {
      display: 'flex',
    },
  },
  secureEmailSentMessage: {
    display: 'flex',
    alignItems: 'center',
    gap: '.25rem',
  },
  dropdownAddOptionLink: {
    width: 1,
    p: '.5rem',
    textAlign: 'center',
    fontWeight: 600,
  },
  newPatientDetailsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  newPatientDetailsFields: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    width: '100%',
  },
  newPatientNameDetails: {
    display: 'flex',
    gap: '1rem',
    width: 1,
    alignItems: 'center',

    '.MuiFormControl-root': {
      width: '50%',
    },
  },
  newPatientCancelIconButtonContainer: {
    paddingLeft: '1rem',
  },
  newPatientCancelIconButton: {
    height: '1.25rem',
    width: '1.25rem',
    bgcolor: 'error.main',
    color: 'background.default',
    svg: {
      fontSize: '1rem',
    },
    '&:hover': {
      bgcolor: 'error.main',
      opacity: 0.75,
    },
  },
  thumbNailsContainer: {
    flex: 1,
    width: 1,
    p: '1.5rem',
    overflowY: 'auto',
    '> div': {
      display: 'flex',
      flexWrap: 'wrap',
      gap: '1.5rem',
    },
  },
  thumbNailDetailsContainer: {
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'center',
  },
  thumbNail: {
    position: 'relative',
    height: '5.313rem',
    img: {
      height: 1,
    },
  },
  deleteFileIcon: {
    position: 'absolute',
    right: '-0.6rem',
    top: '-0.6rem',
    height: '1.25rem',
    width: '1.25rem',
    p: '.13rem',
    color: 'background.default',
    border: '.0625rem solid',
    borderColor: 'background.default',
    bgcolor: 'error.main',
    borderRadius: '50%',
    svg: {
      fontSize: '1rem',
    },
    '&:hover': {
      bgcolor: 'error.main',
      opacity: 0.8,
    },
  },
  thumbNailLoader: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  thumbNailFileName: {
    display: 'flex',
    maxWidth: '8rem',
    mt: '.325rem',
    textAlign: 'center',
    typography: 'caption',
    letterSpacing: '0.016rem',
    color: colorPalette.surfaceColor[700],
    name: {
      typography: 'caption',
      letterSpacing: '0.016rem',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
  },
  documentsTabsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 1,
    mt: '1rem',
  },
  documentTableContainer: {
    overflowY: 'auto',
    backgroundColor: 'background.default',
    thumbnailContainer: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cloudIcon: {
        position: 'absolute',
        top: 'calc(50% - .75rem)',
        left: 'calc(50% - .75rem)',
        color: colorPalette.surfaceColor[800],
        cursor: 'pointer',
      },
      '&:hover': {
        cloudIcon: {
          display: 'block',
        },
      },
    },
  },
  documentTable: {
    tableLayout: 'fixed',
    '& .MuiTableCell-sizeSmall': {
      padding: '0 1rem',
    },
  },
  processButton: {
    minHeight: 'unset',
    height: '2rem',
    width: '7.625rem',
    flexShrink: 0,
    typography: 'subtitle2',
    fontWeight: 500,
    boxShadow: 'none',
  },
  documentsTableAction: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '.5rem',
  },
  documentThumbnailSliderArrows: {
    display: 'flex',
    alignItems: 'center',
    height: '5.313rem',
    p: '.25rem',
    color: 'background.default',
    bgcolor: 'primary.main',
    borderRadius: 0,
    zIndex: 1,
    '&.Mui-disabled': {
      bgcolor: colorPalette.surfaceColor[200],
    },
    '&:hover': {
      bgcolor: 'primary.main',
      color: 'background.default',
      opacity: 0.9,
    },
    svg: {
      height: '.85rem',
      width: '.85rem',
    },
  },
  documentTableThumbnailCarouselContainer: {
    mt: '.5rem',
    position: 'relative',
  },
  sendingOverlay: {
    position: 'absolute',
    zIndex: 2,
    background: 'black',
    color: 'white',
    opacity: '0.5',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  documentsTablePatientName: {
    color: 'primary.main',
    fontWeight: 700,
  },
  documentsTablePatientNameTagContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '.5rem',

    pendingDocumentChip: {
      padding: '0 4px',
      fontSize: '10px',
      bgcolor: '#6c757d',
      color: '#fff',
      fontWeight: '600',
      height: '27px',
    },
  },
  documentTableSentReceiveData: {
    padding: '0.5rem 0',
  },
  documentsTableDocumentTag: {
    color: 'primary.main',
    bgcolor: 'background.default',
    border: '.0625rem solid',
    borderColor: 'primary.main',
  },
  documentsTableLink: {
    cursor: 'pointer',
  },
  deleteConfirmationDialog: {
    headerIcon: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flex: 1,
      svg: {
        ml: '1rem',
      },
    },
    actions: {
      justifyContent: 'center',
      pb: '2rem',
      deleteButton: {
        width: '8.438rem',
      },
    },
  },
  docTableThumbnail: {
    zIndex: 1,
    cursor: 'pointer',
  },
  docPreview: {
    docPreviewMainContainer: {
      position: 'fixed',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      display: 'flex',
      flexDirection: 'column',
      height: 1,
      width: 1,
      bgcolor: 'rgba(0,0,0,0.8)',
      zIndex: 1202,
    },
    docPreviewHeader: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      width: 1,
      height: '4.5rem',
      p: '1rem',
      bgcolor: 'black',
      svg: {
        color: 'background.default',
        cursor: 'pointer',
      },
      '> *': {
        m: '0 .5rem',
      },
    },
    docPreviewContainer: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '.5rem',
      flex: 1,
      height: 'calc(100% - 5rem)',
      img: {
        maxWidth: 1,
        maxHeight: '75vh',
      },
    },
    previewContentContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-around',
      flex: 1,
      gap: '2rem',
      p: '0 5vw',
      color: 'background.default',
      overflowY: 'auto',
    },
    divider: {
      borderColor: colorPalette.surfaceColor[700],
    },
    navigationArrowButton: {
      color: 'background.default',
      bgcolor: colorPalette.surfaceColor[700],
      '&.Mui-disabled': {
        bgcolor: colorPalette.surfaceColor[700],
      },
    },
  },
  docTypeChangeDialog: {
    '.MuiDialog-paper': {
      maxWidth: '27.5rem',
    },
    headerIcon: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flex: 1,
      svg: {
        ml: '3rem',
      },
    },
    actions: {
      justifyContent: 'center',
      pb: '2rem',
      changeButton: {
        width: '7rem',
      },
    },
  },
  forwardDocDialog: {
    '.MuiDialog-paper': {
      width: '37.5rem',
    },
    title: {
      pb: 0,
    },
    '.MuiDialogContent-root': {
      pt: '1rem',
    },
    userAutocomplete: {
      mt: '2rem',
    },
  },
  noDocumentTableImage: {
    p: '5vh 0',
    borderBottom: 'none',
    color: colorPalette.surfaceColor[1000],
  },
  documentTableMessage: {
    position: 'relative',
    overflow: 'hidden',
  },
  documentTableMessageEllipsisContainer: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    pl: '.25rem',
    bgcolor: 'background.default',
    '> *': {
      color: 'primary.main',
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  },
  uploadInProgressContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexFlow: 'column',
    gap: '.5rem',
    height: '5.313rem',
    color: 'primary.main',
  },
  sendQuickMessageDialogContainer: {
    minWidth: '16rem',
    borderRadius: '.85rem',
  },
  sendQuickMessageDialogUserDetails: {
    display: 'flex',
    gap: 1.5,
    p: 1.5,
  },
  sendQuickMessageDialogChatLink: {
    display: 'flex',
    alignItems: 'center',
    gap: 0.5,
    p: 1.5,
    typography: 'caption',
    cursor: 'pointer',
  },
  sendQuickMessageDialogTextfieldContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 0.5,
    p: 1,
    borderTop: '.0625rem solid',
    borderColor: colorPalette.surfaceColor[400],
    input: {
      p: 1,
      '&::placeholder': {
        typography: 'body2',
      },
    },
  },
  sendQuickMessageDialogTextfield: {
    flex: 1,
    border: 'none',
  },
  approveButtonsCell: {
    verticalAlign: 'middle',
  },
  approveButtonsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: '0.75rem',

    '.MuiButtonBase-root': {
      maxWidth: '7.5rem',
      width: '7.5rem',
      padding: '1.25rem',
      margin: 'auto',
      border: '0.090rem solid',
    },
  },
  securedImgCard: {
    card: {
      position: 'relative',
      backgroundColor: 'primary',
      width: 140,
      height: 85,
      borderRadius: 0,
    },
    overlay: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: colorPalette.primaryMain,
      cursor: 'pointer',

      '&:hover': {
        backgroundColor: colorPalette.primaryDark,
      },
    },
    icon: {
      fontSize: '4rem',
      color: '#fff',
    },
  },
  sendDocumentStatusListContentContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '1rem',
  },
  sendDocumentStatusContainer: {
    display: 'flex',
    alignItems: 'center',
    width: 0.7,
    minHeight: '3rem',
    borderRadius: '.5rem',
    border: '.0625rem solid',
  },
  sendDocumentStatusIndicator: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    p: '0 1rem',
  },
  sendDocumentStatusRecipientName: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    p: '0.25rem 1rem',
    typography: 'body2',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  sentDocumentStatusListRetryButton: {
    height: '2rem',
    m: '0 .5rem',
    typography: 'body2',
    color: 'primary.main',
    textDecoration: 'underline',
  },
  sendDocumentStatusListSubmitButton: { marginTop: '1rem' },
  sendDocumentStatusDialog: {
    '.MuiPaper-root.MuiDialog-paper': {
      width: '37.5rem',
      padding: '2.5rem 0',
    },
  },
  practiceDirectoryPanel: {
    popoverPaper: {
      width: '31.688rem',
      maxWidth: '31.688rem',
      boxShadow: '0 0.125rem 0.25rem rgba(0, 0, 0, 0.16)',
      marginTop: '0.5rem',
    },

    contentWrapper: {
      padding: '1rem',
      display: 'flex',
      flexDirection: 'column',
      gap: '0.5rem',
    },

    header: {
      display: 'flex',
      width: '100%',
      gap: '1rem',

      searchPanel: {
        width: '100%',
      },

      textField: {
        border: 'none',
        borderRadius: '1.875rem',
        background: '#EEEEEE',
        padding: '0.5rem',

        '.MuiSvgIcon-root': {
          width: '2rem',
        },
      },

      addIconBtn: {
        background: colorPalette.primaryMain,
        color: '#FFFFFF',

        '&:hover': {
          bgcolor: colorPalette.primaryDark,
        },
      },
    },

    resultList: {
      maxHeight: '15.375rem',
      overflow: 'auto',

      loader: {
        height: '9.125rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },

      listItem: {
        padding: '0.25rem 0 0.25rem 0',
      },
    },

    cta: {
      display: 'flex',
      gap: '0.5rem',
      justifyContent: 'center',
    },
  },
};
