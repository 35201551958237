import { Action } from '../../types/Redux';

export const PAGINATION_REDUX_CONSTANTS = {
  UPDATE_CURRENT_PAGE: 'UPDATE_CURRENT_PAGE',
  RESET_CURRENT_PAGE: 'RESET_CURRENT_PAGE',
  UPDATE_ITEMS_PER_PAGE: 'UPDATE_ITEMS_PER_PAGE',
} as const;

export type UpdateCurrentPageAction = Action<
  typeof PAGINATION_REDUX_CONSTANTS.UPDATE_CURRENT_PAGE,
  number
>;

export type ResetPaginationAction = Action<
  typeof PAGINATION_REDUX_CONSTANTS.RESET_CURRENT_PAGE
>;

export type UpdateItemsPerPageAction = Action<
  typeof PAGINATION_REDUX_CONSTANTS.UPDATE_ITEMS_PER_PAGE,
  number
>;

export type PaginationActionTypes =
  | UpdateCurrentPageAction
  | ResetPaginationAction
  | UpdateItemsPerPageAction;
