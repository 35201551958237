import {
  Box,
  CircularProgress,
  LinearProgress,
  Stack,
  Typography,
} from '@mui/material';
import _ from 'lodash';
import { useEffect, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useAppSelector } from '../../../app/hooks';
import FlatButton from '../../../components/FlatButton';
import { TEST_CONSTANTS } from '../../../constants/testConstants';
import { chatStyle } from '../../../style/dashboardStyles/chatStyle';
import { ChatChannel } from '../../../types/chatTypes';
import ChatListItem from './components/ChatListItem';
import NewChatIcon from './components/NewChatIcon';

const ChatListPanel = ({
  list,
  searchList,
  selected,
  onSelectChat,
  onNewChat,
  onLoadMore,
  error,
  loading,
}: {
  list?: ChatChannel[];
  searchList?: ChatChannel[];
  selected?: ChatChannel;
  onSelectChat?: (chat?: ChatChannel) => void;
  onNewChat?: () => void;
  onLoadMore?: () => void;
  error?: string;
  loading?: boolean;
}) => {
  //get continuationToken from the chat reducer rather than the query itself
  const { continuationToken: channelsContinuationToken } = useAppSelector(
    (state) => state.chatReducer?.channelList ?? {}
  );
  const [hasMore, setHasMore] = useState<boolean>(false);
  const scrollRef = useRef<HTMLElement | null>(null);

  //comment for now, might use later
  // useEffect(() => {
  //   if (scrollRef?.current) {
  //     scrollRef.current.scrollIntoView({
  //       behavior: 'auto',
  //       block: 'nearest',
  //       inline: 'nearest',
  //     });
  //   }
  // }, [list, searchList]);

  useEffect(
    () => setHasMore(!!channelsContinuationToken && !searchList),
    [channelsContinuationToken, searchList]
  );

  return (
    <Stack
      data-testid={TEST_CONSTANTS.CHAT_LIST_PANEL}
      sx={chatStyle.chatListPanel}
    >
      {loading ? (
        <Box sx={chatStyle.chatListPanelLoader}>
          <CircularProgress size='1.5rem' />
        </Box>
      ) : searchList ? (
        <Typography
          component='span'
          textAlign='center'
          sx={chatStyle.chatListPanelDefaultText}
        >
          {searchList.length} {searchList.length === 1 ? 'result' : 'results'}
        </Typography>
      ) : (
        <FlatButton
          startIcon={<NewChatIcon />}
          variant='outlined'
          onClick={() => onNewChat && onNewChat()}
          data-testid={TEST_CONSTANTS.CHAT_LIST_PANEL_NEW_BUTTON}
        >
          <Typography
            component='span'
            textAlign='center'
            sx={chatStyle.chatNewChatButtonText}
          >
            New chat
          </Typography>
        </FlatButton>
      )}
      {error && (
        <Typography
          component='span'
          textAlign='center'
          sx={chatStyle.chatListPanelErrorText}
        >
          Loading failed
        </Typography>
      )}
      {_.isEmpty(list) ? (
        <Typography
          component='span'
          textAlign='center'
          sx={chatStyle.chatListPanelDefaultText}
        >
          Your chat messages will appear here
        </Typography>
      ) : (
        <Box sx={chatStyle.chatListPanelList} id='scrollableChatList'>
          <InfiniteScroll
            dataLength={(list ?? []).length}
            next={() => onLoadMore && onLoadMore()}
            hasMore={hasMore}
            loader={
              <Box sx={chatStyle.chatListPanelInfiniteLoader}>
                <LinearProgress sx={{ width: '3rem' }} />
              </Box>
            }
            scrollableTarget='scrollableChatList'
          >
            {(searchList ?? list)?.map((chat: ChatChannel, index: number) => {
              const isSelected = chat?.id === selected?.id;
              return (
                <ChatListItem
                  key={index}
                  chat={chat}
                  onSelect={onSelectChat}
                  selected={isSelected}
                  refObj={isSelected ? scrollRef : undefined}
                />
              );
            })}
          </InfiniteScroll>
        </Box>
      )}
    </Stack>
  );
};

export default ChatListPanel;
