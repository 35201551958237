import { createApi } from '@reduxjs/toolkit/query/react';
import _ from 'lodash';
import { InvitePracticeFormDataType } from 'pages/dashboard/myNetwork/practiceList/CreateInviteDialog';
import { ViewConnectionPracticeType } from 'pages/dashboard/myNetwork/ViewConnection';
import { DASHBOARD_URLS } from '../constants/urlConstants';
import { initialCircleColorArray } from '../theme/colorPalette';
import {
  InvitationListResponseType,
  PracticeListResponseData,
  PracticeListWithBgcolor,
  RecommendedListResponseType,
  RecommendedPracticeInviteType,
} from '../types/myNetworkTypes';
import baseQueryWithReauth from './serviceQuery';

const myNetworkService = createApi({
  reducerPath: 'myNetworkApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (build) => ({
    getConnectedPractice: build.query<PracticeListResponseData[], void>({
      query: () => ({
        url: DASHBOARD_URLS.MY_NETWORK_URL.MY_NETWORK_PRACTICE_LIST,
        method: 'GET',
      }),
      transformResponse: (response: PracticeListResponseData[]) => {
        const connectedPracticeList: PracticeListWithBgcolor[] = response.map(
          (item: PracticeListWithBgcolor) => ({
            ...item,
            bgcolor:
              initialCircleColorArray[
                Math.floor(Math.random() * initialCircleColorArray.length)
              ],
          })
        );
        return _.sortBy(
          connectedPracticeList,
          (practice: PracticeListWithBgcolor) => practice.name.toUpperCase()
        );
      },
    }),
    getPendingConnectionInvites: build.query<any, void>({
      query: () => ({
        url: DASHBOARD_URLS.MY_NETWORK_URL
          .MY_NETWORK_PENDING_CONNECTION_PRACTICE_INVITES,
        method: 'GET',
      }),
      transformResponse: (response: Record<string, string>[]) => {
        const pendingConnectionInvites = response.map(
          (item: Record<string, string>) => ({
            ...item,
            bgcolor:
              initialCircleColorArray[
                Math.floor(Math.random() * initialCircleColorArray.length)
              ],
          })
        );
        return _.sortBy(
          pendingConnectionInvites,
          (invite: Record<string, string>) => invite.toPracticeName
        );
      },
    }),
    getRegistrationPendingInvites: build.query<any, void>({
      query: () => ({
        url: DASHBOARD_URLS.MY_NETWORK_URL
          .MY_NETWORK_PENDING_REGISTRATION_INVITES,
        method: 'GET',
      }),
    }),
    invitePractice: build.mutation<
      InvitePracticeFormDataType,
      {
        toEmail: string;
        invitationMode: number;
        message: string;
      }
    >({
      query: (invitation) => ({
        url: DASHBOARD_URLS.MY_NETWORK_URL.INVITE_PRACTICE,
        method: 'POST',
        body: invitation,
      }),
    }),
    deleteSentInvite: build.mutation<any, string>({
      query: (invitationId) => ({
        url: `${DASHBOARD_URLS.MY_NETWORK_URL.DELETE_SENT_INVITATION}/${invitationId}/ignore`,
        method: 'POST',
      }),
    }),
    deleteRegistationInvite: build.mutation<any, string>({
      query: (id) => ({
        url: `${DASHBOARD_URLS.MY_NETWORK_URL.DELETE_REGISTRATION_INVITATION}/${id}`,
        method: 'DELETE',
      }),
    }),
    getInvitationList: build.query<InvitationListResponseType[], string>({
      query: (practiceId) => ({
        url: `${DASHBOARD_URLS.MY_NETWORK_URL.GET_INVITATION_LIST}/${practiceId}`,
        method: 'GET',
      }),
      transformResponse: ({ data }) => {
        return data;
      },
    }),
    acceptPracticeInvite: build.mutation<
      { data: InvitationListResponseType[] },
      string
    >({
      query: (id) => ({
        url: `${DASHBOARD_URLS.MY_NETWORK_URL.ACCEPT_OR_IGNORE_PRACTICE_INVITE}/${id}/accept`,
        method: 'POST',
      }),
    }),
    ignorePracticeInvite: build.mutation<InvitationListResponseType[], string>({
      query: (id) => ({
        url: `${DASHBOARD_URLS.MY_NETWORK_URL.DELETE_SENT_INVITATION}/${id}/ignore`,
        method: 'POST',
      }),
    }),
    getRecommendedList: build.query<RecommendedListResponseType[], void>({
      query: () => ({
        url: DASHBOARD_URLS.MY_NETWORK_URL.GET_RECOMMENDED_PRACTICE_LIST,
        method: 'GET',
      }),
      transformResponse: ({ data }) => {
        return data.PracticesYouMayKnow;
      },
    }),
    inviteRecommendedPractice: build.mutation<
      { data: RecommendedPracticeInviteType },
      {
        data: RecommendedPracticeInviteType;
        invitedFromHeaderSearchBar: boolean | undefined;
      }
    >({
      query: ({ data }) => ({
        url: DASHBOARD_URLS.MY_NETWORK_URL.INVITE_RECOMMENDED_PRACTICE,
        method: 'POST',
        body: data,
      }),
    }),
    hideRecommendedPractice: build.mutation<
      RecommendedPracticeInviteType,
      string
    >({
      query: (id) => ({
        url: `${DASHBOARD_URLS.MY_NETWORK_URL.HIDE_RECOMMENDED_PRACTICE_LIST}/${id}`,
        method: 'POST',
      }),
    }),
    getPracticeDetails: build.query<any, { practiceId?: string }>({
      query: ({ practiceId }) => ({
        url: `${DASHBOARD_URLS.MY_NETWORK_URL.VIEW_CONNECTION}/${practiceId}`,
        method: 'GET',
      }),
      transformResponse: (response: ViewConnectionPracticeType) => {
        const { Name, practiceType, addresses, telephoneNumber, teamMembers } =
          response;
        const sortedTeamMemberList = _.sortBy(
          teamMembers,
          (member) =>
            `${member.firstName.toUpperCase()} ${member.lastName.toUpperCase()}`
        );

        return {
          Name,
          practiceType,
          addresses,
          telephoneNumber,
          teamMembers: sortedTeamMemberList,
        };
      },
    }),
  }),
});

export const {
  useGetConnectedPracticeQuery,
  useGetPendingConnectionInvitesQuery,
  useGetRegistrationPendingInvitesQuery,
  useInvitePracticeMutation,
  useDeleteSentInviteMutation,
  useDeleteRegistationInviteMutation,
  useGetInvitationListQuery,
  useAcceptPracticeInviteMutation,
  useIgnorePracticeInviteMutation,
  useGetRecommendedListQuery,
  useInviteRecommendedPracticeMutation,
  useHideRecommendedPracticeMutation,
  useGetPracticeDetailsQuery,
} = myNetworkService;

export const {
  getConnectedPractice,
  getPendingConnectionInvites,
  getRegistrationPendingInvites,
  invitePractice,
  deleteSentInvite,
  deleteRegistationInvite,
  getInvitationList,
  acceptPracticeInvite,
  ignorePracticeInvite,
  getRecommendedList,
  inviteRecommendedPractice,
  hideRecommendedPractice,
  getPracticeDetails,
} = myNetworkService.endpoints;

export default myNetworkService;
