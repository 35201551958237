import { Box } from '@mui/material';
import { TEST_CONSTANTS } from '../../../constants/testConstants';
import { myNetworkStyle } from '../../../style/dashboardStyles/myNetworkStyle';
import InvitationList from './invitationList/InvitationList';
import PracticeList from './practiceList/PracticeList';
import RecommendedList from './recommendedList/RecommendedList';

const MyNetwork = () => {
  return (
    <Box
      sx={myNetworkStyle.myNetworkContainer}
      data-testid={TEST_CONSTANTS.MY_NETWORK_CONTAINER}
    >
      <PracticeList />

      <Box sx={myNetworkStyle.myNetworkRightContainer}>
        <InvitationList />
        <RecommendedList />
      </Box>
    </Box>
  );
};

export default MyNetwork;
