import { CloseRounded } from '@mui/icons-material';
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';
import { ChangeEvent, SyntheticEvent, useMemo, useState } from 'react';
import { TEST_CONSTANTS } from '../../../constants/testConstants';
import { stateList } from '../../../constants/usStateListConstant';
import {
  isZipCodeValid,
  requireValidate,
} from '../../../helpers/validationHelper';
import { commonClasses } from '../../../style/commonClasses';
import { onboardingStyle } from '../../../style/onboardingStyle';
import { OnboardingFormType } from './OnboardingNewPractice';

type ManualAddressType = {
  city: string;
  state: string;
  zip: string;
};

type ManualAddressErrorType = {
  city?: string;
  state?: string;
  zip?: string;
};

const initialManualAddress = {
  city: '',
  state: '',
  zip: '',
};

type ManualAddressDialogProps = {
  isAddressModalOpen: boolean;
  setIsAddressModalOpen: (value: boolean) => void;
  onboardingForm: OnboardingFormType;
  setOnboardingForm: (value: OnboardingFormType) => void;
};

const ManualAddressDialog = (props: ManualAddressDialogProps) => {
  const {
    isAddressModalOpen,
    setIsAddressModalOpen,
    onboardingForm,
    setOnboardingForm,
  } = props;
  const onCloseAddressModal = () => {
    setIsAddressModalOpen(false);
  };
  const [manualAddressForm, setManualAddressForm] =
    useState<ManualAddressType>(initialManualAddress);
  const [manualAddressFormError, setManualAddressFormError] =
    useState<ManualAddressErrorType>({});
  const { city, state, zip } = useMemo(
    () => manualAddressForm,
    [manualAddressForm]
  );

  const [isFormSubmittedOnce, setIsFormSubmittedOnce] =
    useState<boolean>(false);

  const isManualAddressFormValid = (record: {
    city?: string;
    state?: string | null;
    zip?: string;
  }) => {
    let isFormValid = true;
    let updatedErrors: ManualAddressErrorType = manualAddressFormError;

    for (const key in record) {
      switch (key) {
        case 'city':
          {
            const cityValidation = requireValidate(
              'Field must not be blank',
              record.city!
            );
            if (cityValidation.status) {
              delete updatedErrors[key];
            } else {
              isFormValid = false;
              updatedErrors[key] = cityValidation.message;
            }
          }
          break;

        case 'state':
          {
            const stateValidation = requireValidate(
              'Please make a selection',
              record.state!
            );
            if (stateValidation.status) {
              delete updatedErrors[key];
            } else {
              isFormValid = false;
              updatedErrors[key] = stateValidation.message;
            }
          }
          break;

        case 'zip':
          {
            const zipValidation = isZipCodeValid('ZIP', record.zip!);

            if (zipValidation.status) {
              delete updatedErrors[key];
            } else {
              isFormValid = false;
              updatedErrors[key] = zipValidation.message;
            }
          }
          break;
      }
    }

    setManualAddressFormError(updatedErrors);
    return isFormValid;
  };

  const onManualAddressTextFieldChange = (name: string, value: string) => {
    if (isFormSubmittedOnce) {
      isManualAddressFormValid({ [name]: value });
    }
    setManualAddressForm({ ...manualAddressForm, [name]: value });
  };

  const onClickSaveManualAddress = () => {
    if (!isFormSubmittedOnce) {
      setIsFormSubmittedOnce(true);
    }

    if (isManualAddressFormValid({ city, state, zip })) {
      setOnboardingForm({
        ...onboardingForm,
        fullAddress: {
          city,
          state,
          zip,
          fullAddress: `${city}, ${state}, ${zip}`,
        },
      });
      setIsAddressModalOpen(false);
      setManualAddressForm(initialManualAddress);
    }
  };

  return (
    <Dialog
      data-testid={TEST_CONSTANTS.MANUAL_ADDRESS_DIALOG}
      open={isAddressModalOpen}
      sx={onboardingStyle.manualAddressPopUp}
      onClose={onCloseAddressModal}
      aria-labelledby='address'
      aria-describedby='enter you address manually'
    >
      <DialogTitle sx={onboardingStyle.manualAddressPopUpTitle}>
        Enter Manual Address
        <CloseRounded
          sx={onboardingStyle.closePopUpIcon}
          onClick={onCloseAddressModal}
        />
      </DialogTitle>
      <DialogContent sx={onboardingStyle.manualAddressPopUpContent}>
        <TextField
          fullWidth
          data-testid={TEST_CONSTANTS.MANUAL_ADDRESS_CITY}
          variant='outlined'
          label='City'
          sx={manualAddressFormError?.city ? commonClasses.fieldError : {}}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            onManualAddressTextFieldChange('city', e.target.value)
          }
          value={city}
          helperText={manualAddressFormError?.city}
        />
        <Autocomplete
          fullWidth
          data-testid={TEST_CONSTANTS.MANUAL_ADDRESS_STATE}
          value={state}
          onChange={(_e: SyntheticEvent, value: string | null) =>
            onManualAddressTextFieldChange('state', value!)
          }
          options={stateList}
          sx={manualAddressFormError?.state ? commonClasses.fieldError : {}}
          renderInput={(params) => (
            <TextField
              {...params}
              label='State'
              placeholder='- Please select state -'
              helperText={manualAddressFormError?.state}
            />
          )}
        />
        <TextField
          data-testid={TEST_CONSTANTS.MANUAL_ADDRESS_ZIP}
          fullWidth
          variant='outlined'
          label='ZIP'
          value={zip}
          sx={manualAddressFormError?.zip ? commonClasses.fieldError : {}}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            onManualAddressTextFieldChange('zip', e.target.value)
          }
          helperText={manualAddressFormError?.zip}
        />
      </DialogContent>
      <DialogActions sx={onboardingStyle.manualAddressPopUpAction}>
        <Button
          variant='contained'
          color='primary'
          onClick={onClickSaveManualAddress}
        >
          SAVE
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ManualAddressDialog;
