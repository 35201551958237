import { IntroductionScreenNavgationTab } from '../types/authTypes';

export const introductionScreenTabs: IntroductionScreenNavgationTab[] = [
  { name: 'Features' },
  {
    name: 'Request Demo',
    navigateTo:
      'https://calendly.com/drtalksupport/demonstration?month=2023-03',
  },
  { name: "What's New", navigateTo: 'https://www.drtalk.com/resources/' },
  { name: 'Download', navigateTo: 'https://signup.drtalk.com/download/' },
];
